import React, { useEffect, useState } from "react";
import "./utility.css";
import { FiSettings } from "react-icons/fi";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loading";
import service from "../../../../api/service";

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};

function Utilities() {
  const [utilityData, setUtilityData] = useState(false);
  const utilitydata = () => {
    try {
      trackPromise(
        service.utilityData().then((res) => {
          setUtilityData(res.data.utilityUserAccessDetail);
        })
      );
    } catch (err) {
  
    }
  };
  const redirectUrl = (name,url) => {
    let  baseAPI_URL = process.env.REACT_APP_BASE_URL;
    let subbaseUrl = baseAPI_URL.toString().split("/");
    let hostName = subbaseUrl[2].toString().split(".");
    let path = baseAPI_URL.split(hostName[0]);
    if(name === "Website #datafree Visualiser"){
      window.open(url.replace(".pub.bi.nu",path[1]));
    }
    if(name === "Media Collection Management"){
      window.open(url.replace(".bi.nu",path[1]));
    }
  }
  useEffect(() => {
    utilitydata();
  }, []);

  return (
    <div className="utilityContent">
      <div className="utilityView">
        <div className="utilityhead">
          <div>
            <FiSettings className="utilityheadIcon" />
          </div>
          <div className="utilToptext">Utilities</div>
        </div>
        <div className="utilitytable">
        <LoadingIndicator />
        {
            utilityData?.length > 0 ? 
          <TableContainer component={Paper}>
            <Table className="tablestyle" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Utility Name</TableCell>
                  <TableCell>Utility Description</TableCell>
                </TableRow>
              </TableHead>
              {utilityData && utilityData.length > 0 ? (
                utilityData.map((item, index) => (
                  <TableBody key={index}>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ color: "#337ab7" , cursor:"pointer"}}
                        onClick={()=>redirectUrl(item.utility_name,item?.utility_url)}
                      >
                        {item.utility_name}
                      </TableCell>
                      <TableCell>{item.utility_desc}</TableCell>
                    </TableRow>
                  </TableBody>
                ))
              ) : (
                <TableRow>
                  <TableCell component="th" scope="row">
                    No Data
                  </TableCell>
                  <TableCell component="th" scope="row">
                    No Data
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </TableContainer>: <div style={{textAlign:'center'}}><p style={{fontWeight:'bold'}}></p></div>
          }
        </div>
      </div>
    </div>
  );
}
export default Utilities;
