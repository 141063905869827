import React, { useState, useEffect,useContext } from "react";
import { Tabs, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, withStyles, Grid } from "@material-ui/core";
import { trackPromise } from "react-promise-tracker";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useHistory, useParams } from "react-router-dom";
import accountIcon from "../../../assets/img/account-icon-black.png";
import moment from "moment";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import licenseeService from "../../../api/licenseeService";
import Service from "../../../api/adminService";
import jwt from "jwt-decode";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { MdWarning } from "react-icons/md";
import alpha from "../../../assets/img/blank1.png";
import Loader from "react-loading";
import {
  ToggleContext
} from "../../context/toggleContext"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div>
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AntTabs = Tabs;

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    borderBottom: "1px solid #ddd ",
    minWidth: "80px",

    textShadow: "none",
    color: "#0059b6",

    "&$selected": {
      color: "#555",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderBottomColor: "transparent",
      borderBottom: "none",
      cursor: "default",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
const useStyles = makeStyles((theme) => ({
  scrollPaper: {
    alignItems: "baseline",
  },
}));

function LicenseeChannelDetailView(props) {
  let file = null;
  let history = useHistory();
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const { channelId } = useParams();
  let API_URL = process.env.REACT_APP_API_URL;
  const [scroll, setScroll] = useState("paper");
  const [userOpen, setUserOpen] = useState(false);
  const [editInfo, setEditInfo] = useState(false);
  const [userDetails, setuserDetails] = useState();
  const [invitedSuccess, setInvitedSuccess] = useState(false);
  const [userid, setUserId] = useState({
    userId: "",

  });
  const toggleContextVal = useContext(ToggleContext)
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [inviteUser, setinviteUser] = useState(false);
  const [channelData, setChannelData] = useState();
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [erroropens, seterrorOpens] = useState(false);
  const [user_id, setUser_id] = useState();
  const [lodervisible, setlodervisible] = useState(false);
  const [channelCompanyId, setChannelCompanyId] = useState();
  const [toastMsg, setToastMsg] = useState();
  const [editdata, seteditdata] = useState({
    company_name: "",
    logo_icon_image: "",
    credit_control_email: "",
    status: "",
    company_type: "",
    company_id: "",
    removeCompanyLogo: "",
  });
  const [invite, setInvite] = useState({
    name: "",
    email_address: "",
    companyId: "",
    user_role: "",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setEditInfo(false);
    setUserOpen(false);
    setinviteUser(false);
    setUserNameError({
      username: false,
      msg: ""
    });
    setInvite({
      ...invite,
      name: "",
      email_address: "",
      user_role: ""
    });
    setError({
      company_name: false,
      logo_icon_image: false,
      credit_control_email: false,
      emailMsg: "",
      nameMsg: "",
      imgMsg: ""

    });
    setInviteError({
      name: false,
      email_address: false,
      user_role: false,
      companyId: false,

      nameErMsg: "",
      emailErrMsg: "",
      roleErrMsg: "",
    });
  };

  const edit = () => {
    setEditInfo(true);
    localStorage.setItem("EditOpen", true)
  };
  const inviteNew = () => {
    setinviteUser(true);
    setScroll('body');
  };
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
    seterrorOpens(false);
  };
  const handleAlertCloses = () => {
    setsuccessRequest(false);
    setUserOpen(false);
  }
  const [creditLimit, setCreditLimit] = useState();
  const [removeDialogue, setRemoveDialogue] = useState(false);
  const [ImgRemoved, setImgRemoved] = useState(false);

  const removeLogo = () => {

    setImgRemoved(true);

    setRemoveDialogue(false);

    seteditdata({
      company_name: channelData?.publisherDetail.name,
      logo_icon_image: null,
      credit_control_email: channelData?.publisherDetail?.credit_control_email,
      status: channelData?.publisherDetail.status,
      company_type: channelData?.publisherDetail.channel_type,
      company_id: channelData?.publisherDetail.company_id,
      removeCompanyLogo: "yes",
    });

    setImage({ preview: "", raw: "" });

  };
  const DialogClose = () => {

    setImgRemoved(false);
    setRemoveDialogue(false);
  }
  const ChannelDetail = () => {
    setlodervisible(true);
    try {
      trackPromise(
        licenseeService.licenseeChannelDetail(channelId).then((res) => {
          setlodervisible(false);

          setChannelData(res.data);
          setChannelCompanyId(res.data?.publisherDetail.company_id);
          seteditdata({
            company_name: res.data?.publisherDetail.name ? res.data?.publisherDetail.name : "",
            logo_icon_image: res.data?.publisherDetail.logoImage ? res.data?.publisherDetail.logoImage : "",
            credit_control_email: res.data?.publisherDetail?.credit_control_email ? res.data?.publisherDetail?.credit_control_email : "",
            status: res.data?.publisherDetail.status ? res.data?.publisherDetail.status : "",
            company_type: res.data?.publisherDetail.channel_type ? res.data?.publisherDetail.channel_type : "",
            company_id: res.data?.publisherDetail.company_id ? res.data?.publisherDetail.company_id : "",
            removeCompanyLogo: "",
          });
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpens(true);
    }
  }
  //edit Image
  async function EditImageData() {

    if (error.company_name) {
      setError({
        ...error,
        company_name: true,
      });
    }
    else if (error.logo_icon_image) {
      setError({
        ...error,
        logo_icon_image: true,
      });
    }
    else if (error.credit_control_email) {
      setError({
        ...error,
        credit_control_email: true,
      });
    }
    else {
      let file = document.getElementById("logo_icon_image").files[0];

      let values = file !== undefined ? new FormData() : "";
      if (file !== undefined) {
        values.append("logo_icon_image", file ? file : "");
      }
      try {

        if (values.toString().trim().length > 0) {

          licenseeService.editImage(values).then((res) => {

            if (res.status === 200) {

              editdata["logo_icon_image"] = res.data;
              setImgRemoved(false);
              EditChannelData(editdata);
              setToastMsg("Channel Details Edited Successfully!");
              setsuccessOpen(true);
            }

          })

        } else {
          EditChannelData(editdata);
        }
      } catch (err) {
        setErrMsg("Something went wrong. Please reload the page!");
        seterrorOpens(true);
      }
    }
  }

  const getCustomer = (companyId, companyType) => {
    if (companyType === "CUSTOMER") {
      history.push(`/licensee/customer/${companyId}/detail/view`);
    }
    if (companyType === "CHANNEL") {
      history.push(`/licensee/channel/${companyId}/detail/view`);
    }
    if (companyType === "RESELLER") {
      history.push(`/licensee/customer/${companyId}/detail/view`);
    }
  };
  //edit Channel
  async function EditChannelData() {
    try {

      licenseeService.licenseeChannelDetailViewEdit(editdata).then((res) => {

        if (res.status === 200) {
          setEditInfo(false);
          setsuccessOpen(true);
        } else {
          seterrorOpen(true);
          setEditInfo(true);
        }

        ChannelDetail();
      })

    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpens(true);
    }
  }

  const [clickInvite, setClickInvite] = useState(false);
  async function InviteNew() {
    if (invite.name.length === 0 && invite.email_address.length == 0 && invite.user_role.length === 0) {
      setInviteError({
        name: true,
        email_address: true,
        user_role: true,
      });
    } else if (inviteError.name) {
      setInviteError({
        ...inviteError,
        name: true,
      });
    } else if (inviteError.email_address) {
      setInviteError({
        ...inviteError,
        email_address: true,
      });
    } else if (inviteError.user_role) {
      setInviteError({
        ...inviteError,
        user_role: true,
      });
    } else {
      setlodervisible(true);
      setClickInvite(true);
      trackPromise(
        licenseeService
          .licenseeInviteNewUser(invite)
          .then((res) => {

            setlodervisible(true);

            setinviteUser(false);
            setInvitedSuccess(true);
            setInvite({
              ...invite,
              name: "",
              email_address: "",
              user_role: ""
            })
            setClickInvite(false);
            ChannelDetail();
          })
          .catch((err) => {

            setError(JSON.stringify(err?.response?.data).replace(/"\[/g, '['));
            seterrorOpen(true);
            setEditInfo(true);
            setInvitedSuccess(false);
          })
      );
    }
  }
  const [emailId, setEmailId] = useState({
    email: "",
    role: "",
    companyId: ""
  });
  const impersonate = (emailid, role, Id,type,name) => {
    emailId["email"] = emailid;
    emailId["role"] = role;
    emailId["companyId"] = Id;
    try {
      trackPromise(
        licenseeService.impersonateUser(emailId).then((res) => {

          if (res.status === 200 && res.statusText === "OK") {
            localStorage.setItem("token", res.data);
            localStorage.setItem("Impersonation", true);
            localStorage.setItem("companyname", name)
            localStorage.setItem("companytype", type)
            localStorage.setItem("companyrole", role)
            var decode1 = jwt(res.data);

            if (decode1.roles[0] === "ROLE_USER") props.history.push("/user/datafree");
            else if (decode1.roles[0] === "ROLE_RESELLER") props.history.push("/user/datafree");
            else if (decode1.roles[0] === "ROLE_CUSTOMER") props.history.push("/user/datafree");
            else if (decode1.roles[0] === "ROLE_ADMIN") props.history.push("/admin/company/index");

            else if (decode1.roles[0] === "ROLE_CHANNEL") props.history.push("/channel/publisher/index");
          } else {
            alert("!please try again");
          }
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpens(true);
    }
  };

  const [error, setError] = useState({
    company_name: false,
    logo_icon_image: false,
    credit_control_email: false,
    emailMsg: "",
    nameMsg: "",
    imgMsg: ""

  });

  const [resVal, setResVal] = useState();
  async function CheckCompany(name, id) {

    try {
      if (name.includes(",")) {
        setError({
          ...error,
          company_name: true,
          nameMsg: "Please enter a name without comma",
        });
      }
      else if (nameReg.test(name) === false) {
        setError({
          ...error,
          company_name: true,
          nameMsg: "Field must have at least 1 character a-z or A-Z",
        });
      }
      else {
        trackPromise(
          Service.CheckCompany({ companyname: name, companyid: id }).then((res) => {

            setResVal(res.data);
            if (res.data === true) {
              setError({
                ...error,
                company_name: false,
                nameMsg: ""
              })
            }

            else {
              setError({
                ...error,
                company_name: true,
                nameMsg: "This​ Company ​name ​already​ exist"
              })
            }

          })
        );
      }

    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpens(true);
    }
  }
  const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
  const nameReg = /^.*[A-Za-z].*/i;
  const emailReg = /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/;
  const dataChange = (e) => {
    e.preventDefault();
    const newdata = { ...editdata };
    newdata[e.target.id] = e.target.value;
    /*newdata["company_id"] = channelId;
    newdata["companyId"] = channelCompanyId;*/

    if (e.target.id === "company_name") {
      CheckCompany(e.target.value, channelCompanyId)
      if (e.target.value) {
        if ((e.target.value).includes(",")) {
          setError({
            ...error,
            company_name: true,
            nameMsg: "Please enter a name without comma",
          });
        }
        else if (nameReg.test(e.target.value) === false) {
          setError({
            ...error,
            company_name: true,
            nameMsg: "Field must have at least 1 character a-z or A-Z",
          });
        }
        else {
          setError({
            ...error,
            company_name: false,
            nameMsg: ""
          })
        }

      }

      else {
        setError({
          ...error,
          company_name: true,
          nameMsg: "This field is required."
        })
      }

    }

    if (e.target.id === "logo_icon_image") {
      if (e.target.files.length !== 0) {
        var img = document.createElement("img");
        img.src = URL.createObjectURL(e.target.files[0]);
        var filePath = e.target.files[0].name;

        if (!allowedExtensions.exec(filePath)) {
          setImage({
            preview: "",
            raw: "",
          });
          setError({
            ...error,
            logo_icon_image: true,
            imgMsg: "Selected file is not an image"
          });
        }
        else {
          img.onload = function () {
            if (img.width >= 65 && img.height >= 180) {

              setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
              });
              setError({
                ...error,
                logo_icon_image: false,
                imgMsg: ""
              });
            }
            else if (img.width <= 65) {
              setImage({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                logo_icon_image: true,
                imgMsg: "Minimum width must be 65px"
              });
            }
            else if (img.height <= 180) {
              setImage({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                logo_icon_image: true,
                imgMsg: "Minimum height must be 180px"
              });
            }
          };
        }
      }
      else {
        setImage({
          preview: "",
          raw: "",
        });
        setError({
          ...error,
          logo_icon_image: false,
          imgMsg: ""
        });

      }
    }


    if (e.target.id === "credit_control_email") {
      if (e.target.value) {

        if (emailReg.test(e.target.value) === false) {
          setError
            ({
              ...error,
              credit_control_email: true,
              emailMsg: "Please enter a valid email address."
            })
        }
        else {
          setError({
            ...error,
            credit_control_email: false,
            emailMsg: ""
          })
        }
      }
      else {
        setError({
          ...error,
          credit_control_email: true,
          emailMsg: "This field is required."
        })
      }
    }

    seteditdata(newdata);
  };


  const [userdata, setUserdata] = useState({
    username: "",
    user_role: "",
    user_type: "",
    user_verified: "",
    user_status: "",
    user_id: "",
    company_id: "",
  });


  ////Check Email
  async function EmailCheck(value) {
    trackPromise(
      licenseeService.checkMails({ email_address: value })
        .then((res) => {
          if (res.data === false) {
            setInviteError({
              ...inviteError,
              email_address: true,
              emailErrMsg: "A User exist with this email.Contact Datafree Support if you wish this user to be linked to this account",
            });
          } else if (value && reg.test(value) === false) {
            setInviteError({
              ...inviteError,
              email_address: true,
              emailErrMsg: "Please enter a valid email address.",
            });
          } else {
            setInviteError({
              ...inviteError,
              email_address: false,
            });
          }
        })
        .catch((error) => {
          setErrMsg("Something went wrong. Please reload the page!");
          seterrorOpens(true);
        })
    );
  }


  const [inviteError, setInviteError] = useState({
    name: false,
    email_address: false,
    user_role: false,
    companyId: false,

    nameErMsg: "",
    emailErrMsg: "",
    roleErrMsg: "",
  });
  const reg = /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/;

  const handleUser = (e) => {
    e.preventDefault();
    const invitedata = { ...invite };
    invitedata[e.target.id] = e.target.value;
    invitedata["companyId"] = channelId;
    if (e.target.id === "name") {
      setInviteError({
        ...inviteError,
        name: false,
      });
    }
    if (e.target.id === "email_address") {
      if (e.target.value) {
        EmailCheck(e.target.value);
      } else {
        setInviteError({
          ...inviteError,
          email_address: true,
          emailErrMsg: "This field is required",
        });
      }
    }

    if (e.target.id === "user_role") {
      setInviteError({
        ...inviteError,
        user_role: false,
      });
    }
    setInvite(invitedata);
  };

  const redirectRoute = () => {
    history.push("/licensee/index/action");
  };

  //getUser
  async function getUserDetail(Ids,companyIds) {

    setuserDetails({});
    try {
      trackPromise(
        licenseeService.licenseegetUserRequest({'userId':Ids, 'companyId':companyIds}).then((res) => {

          setuserDetails(res.data);

          setUser_id(res.data.userId);

          setUserdata({
            username: res.data?.userName,
            user_role: res.data?.role,
            user_type: res.data?.type,
            user_verified: res.data?.verified && res.data?.verified == 1 ? "Yes" : "No",
            user_status: res.data?.status,
            user_id: res.data?.userId,
            company_id: res.data?.companyId,
          });
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpens(true);
    }
  }
  const [loadings, setLoadings] = useState(false)
  const [errMsg, setErrMsg] = useState();
  //editCompanyPublisher Detail
  async function EditUserData() {
    if (userNameError.username) {
      setUserNameError({
        username: true,
      });
    } else {
      setLoadings(true);
      try {
        trackPromise(
          licenseeService.licenseeEditUserRequest(userdata).then((res) => {
            setLoadings(false);
            if (res.status === 200) {
              setToastMsg("User Details Updated Successfully!")
              setEditInfo(false);

              setUserOpen(false);
              setsuccessOpen(true);
              handleClose();
            } else {
              seterrorOpen(true);
              setUserOpen(true);
              setEditInfo(true);
            }
            ChannelDetail();
          })
        );
      } catch (err) {
        setErrMsg("Something went wrong. Please reload the page!");
        seterrorOpens(true);
      }
    }
  }

  const OpenUserTab = (scrollType, Id, companyIds) => {

    userid["userId"] = Id;
    setUserId(userid);

    getUserDetail(Id,companyIds);
    setUserOpen(true);
    setScroll(scrollType);
  };
  const [userNameError, setUserNameError] = useState({
    username: false,
    msg: "",
  });
  const EditUser = (e) => {
    const newdata = { ...userdata };
    newdata[e.target.id] = e.target.value;
    if (e.target.id === "username") {
      if (e.target.value) {
        setUserNameError({
          username: false,

        });
      } else {
        setUserNameError({
          username: true,
          msg: "This field is required",
        });
      }
    }
    setUserdata(newdata);
  };

  const [openPopup, setOpenPopup] = useState(false);

  document.addEventListener("click", e => {

    let editNot = localStorage.getItem("EditOpen");
    if (editNot) {
      if (e.target.id === "Edit" || e.target.id === "credit_control_email" || e.target.id === "Cancel" || e.target.id === "Save" || e.target.id === "Return" || e.target.name === "logo_icon_image" || e.target.id === "logo_icon_image"  || 
        e.target.type === "file" || e.target.name === "company_name" || e.target.name === "popup" || e.target.id === "status" || e.target.id === "Remove") {

        if (e.target.id === "Cancel" || e.target.id === "Save" || e.target.id === "Return") {
          localStorage.removeItem("EditOpen")

        }
        setOpenPopup(false);
      }
      else {
        setOpenPopup(true);
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();

      }
    }
    else {
      setOpenPopup(false);

    }

  }, true);

  const [successRequest, setsuccessRequest] = useState(false);
  const [message, setMessage] = useState();
  //passwordReset
  async function resetPassword(EMAIL) {

    try {
      trackPromise(
        licenseeService.passwordRequest({ email: EMAIL, admin: "licensee" }).then((res) => {
          if (res.data.status === "success") {
            setsuccessRequest(true);
            setMessage(res.data.message);
            handleClose();
          }
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpens(true);
    }
  }

  useEffect(() => {
    setlodervisible(true);
    ChannelDetail();
    localStorage.removeItem("EditOpen")
  }, []);

  return (
    <div className="accountContent">
      <div className={toggleContextVal.event ?"accountMenuView" :"accountView"}>
        <div className="accounthead">
          <div>
            <img src={accountIcon} alt=""></img>
          </div>
          <div className="accountToptext">Channel Details - Licensee View</div>
        </div>
        <AntTabs
          value={value}
          className="tabViewMenu"
          onChange={handleChange}
          aria-label="ant example"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          <AntTab label="Company" title="Click here to view Company Tab" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
          <AntTab label="Users" title="Click here to view Users Tab" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
          <AntTab label="Publishers" title="Click here to view Publishers Tab" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
        </AntTabs>
        <Typography className={classes.padding} />

        <div>
          <TabPanel value={value} index={0}>
            {
              lodervisible &&
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: '0px',
                right: '0px',
                margin: 'auto',
                position: 'absolute',
                left: '0px',
                bottom: '0px',
                zIndex: '3000',
                backgroundColor: "#ffffff",

                paddingTop: '8px',
                paddingBottom: '8px',
                borderRadius: '5px',
                flexDirection: 'column',
                width: '70px',
                height: '60px'
              }} >
                <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
              </div>
            }
            {channelData?.publisherDetail ? <Grid container spacing={1}>
              <Grid item xs={3}>
                <div className="companyLabel">Company Name</div>

                <div>
                  {!editInfo ? (
                    <input type="text"
                      className="companyListinputField"
                      disabled="disabled"
                      name="popup"
                      style={{ cursor: 'not-allowed' }}
                      value={channelData.publisherDetail.name ? channelData.publisherDetail.name : ""} />
                  ) : (
                    <input type="text"
                      className={!error.company_name ? "editcompanyListField" : "editcompanyListFielderr"}
                      name="popup" id="company_name"
                      onChange={(e) => dataChange(e)}
                      onBlur={(e) => dataChange(e)}

                      defaultValue={channelData?.publisherDetail?.name} />
                  )}
                  {error.company_name ? <a className="errorClass">{error.nameMsg}</a> : null}
                </div>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <div className="cmpnylogoflex">
                  {!editInfo ? <div className="companyLabel">Company Logo</div> : ""}
                  {editInfo || channelData?.publisherDetail.logoImage !== null ?
                    <div className="fileIcon">
                      <img src={
                        image.preview
                          ? image.preview :
                          !ImgRemoved && channelData?.publisherDetail.logoImage !== null ?
                            API_URL + channelData?.publisherDetail?.logo_file + channelData?.publisherDetail.logoImage
                            : alpha}
                        onError={(event) => event.target.src = alpha}
                        alt=""
                        className="imgStyle"
                      ></img>
                    </div> : ""}

                  {editInfo ? (
                    <div className="imgFileRow">
                      <div className="companyLabel">Upload Company Logo </div>
                      <div className="filesubText">(Min. dimensions of 180px X 65px)</div>
                      {error.logo_icon_image ? <a className="errorClass">{error.imgMsg}</a> : null}
                      <input id="logo_icon_image" type="file" name="logo_icon_image" onChange={(e) => dataChange(e)} />
                      <label htmlFor="file">{file}</label>
                    </div>
                  ) : null}
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="ivitediv">
                  {!editInfo ? (
                    <button className="inviteButton" onClick={edit} id="Edit">
                      Edit Details
                    </button>
                  ) : null}
                </div>
              </Grid>
              {editInfo && !ImgRemoved && channelData?.publisherDetail?.logoImage ? (
                <Grid item xs={12}>
                  <div className="ivitediv">
                    <button className="changeOwnButton spaceTop" id="Remove"
                      onClick={() => setRemoveDialogue(true)}>
                      Remove Company Logo
                    </button>
                  </div>
                </Grid>
              ) : null}
              <Grid item xs={3}>
                <div className="companyLabel">Licensee Owner</div>

                <div>
                  <input
                    type="text"
                    className="companyListinputField"
                    disabled="disabled"
                    name="popup"
                    style={{ cursor: 'not-allowed' }}
                    value={channelData.publisherDetail.parent_comp_name ? channelData.publisherDetail.parent_comp_name : "NULL"}
                  />
                </div>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <div className="companyLabel">Credit Controller Email</div>

                <div>
                  {!editInfo ? (
                    <input
                      type="text"
                      className="companyListinputField"
                      disabled="disabled"
                      name="popup"
                      style={{ cursor: 'not-allowed' }}
                      value={channelData.publisherDetail?.credit_control_email ? channelData.publisherDetail.credit_control_email : ""}
                    />
                  ) : (
                    <input type="text"
                      className={!error.credit_control_email ? "editcompanyListField" : "editcompanyListFielderr"}
                      name="popup"
                      id="credit_control_email"
                      defaultValue={channelData?.publisherDetail?.credit_control_email}
                      onChange={(e) => dataChange(e)}
                      onBlur={(e) => dataChange(e)} />
                  )}
                  {error.credit_control_email ? <a className="errorClass">{error.emailMsg}</a> : null}
                </div>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={3}>
                <div className="companyLabel">Company Id</div>

                <div>
                  <input type="text" className="companyListinputField" style={{ cursor: 'not-allowed' }} name="popup" disabled="disabled" value={channelData.publisherDetail.company_id ? channelData.publisherDetail.company_id : "NULL"} />
                </div>
              </Grid>

              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <div className="companyLabel">Status</div>

                <div>
                  {!editInfo ? (
                    <input type="text" className="companyListinputField" style={{ cursor: 'not-allowed' }} disabled="disabled" name="popup" value={channelData.publisherDetail.status} />
                  ) : (
                    <select type="text" className="dialogSelectinput" style={{ width: '110%' }} name="popup" id="status" onChange={(e) => dataChange(e)} onBlur={(e) => dataChange(e)}>

                      {channelData?.publisherDetail?.status === "Approved" ? (<>
                        <option value="Approved" selected>Approved</option>
                        <option value="Suspended">Suspended</option>
                        <option value="Archived">Archived</option>

                      </>) :

                        channelData?.publisherDetail?.status === "New" ? (<>
                          <option value="New" selected>New</option>
                          <option value="Approved">Approved</option>
                          <option value="NoAgreement">NoAgreement</option>

                        </>) :
                          channelData?.publisherDetail?.status === "NoAgreement" ? (<>
                            <option value="NoAgreement" selected>NoAgreement</option>
                            <option value="Approved">Approved</option>
                            <option value="Archived">Archived</option>

                          </>) :
                            channelData?.publisherDetail?.status === "Suspended" ? (<>
                              <option value="Suspended" selected>Suspended</option>
                              <option value="Approved">Approved</option>
                              <option value="Archived">Archived</option>

                            </>) :
                              channelData?.publisherDetail?.status === "Archived" ? (<>
                                <option value="Archived" selected>Archived</option>

                              </>) :
                                null}
                    </select>
                  )}
                </div>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={3}>
                <div className="companyLabel">Company Type</div>

                <div>
                  <input type="text" className="companyListinputField" style={{ cursor: 'not-allowed' }} disabled="disabled" name="popup" value={channelData.publisherDetail.channel_type} />
                </div>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <div className="companyLabel">Created</div>

                <div>
                  <input type="text" className="companyListinputField" style={{ cursor: 'not-allowed' }} disabled="disabled" name="popup" value={moment(channelData.publisherDetail.created).format("YYYY-MM-DD") + " GMT"} />
                </div>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid> : null}

            <div className="ivitediv">
              {editInfo ? (
                <button className="inviteButton" onClick={handleClose} color="primary" id="Cancel">
                  Cancel
                </button>
              ) : null}
              {editInfo ? (
                <button className="inviteButton" color="primary" onClick={EditImageData} id="Save">
                  Save
                </button>
              ) : null}
            </div>
            <div className="ivitediv">
              {" "}
              <button className="inviteButton" onClick={redirectRoute}>
                Return to Channel List
              </button>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="ivitediv">
              <button className="inviteButton" onClick={inviteNew}>
                + Invite New Users
              </button>
            </div>
            <TableContainer>
              <Table className="accouttablestyle" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="tableHead">User’s Name</TableCell>
                    <TableCell className="tableHead">Email Address</TableCell>
                    <TableCell className="tableHead">Role</TableCell>
                    <TableCell className="tableHead">Type</TableCell>
                    <TableCell className="tableHead">Verified</TableCell>
                    <TableCell className="tableHead">Status</TableCell>
                    <TableCell className="tableHead">Created</TableCell>
                    <TableCell className="tableHead">Impersonate</TableCell>
                  </TableRow>
                </TableHead>
                {channelData && channelData.usersListDetail.length > 0 ? (
                  channelData.usersListDetail.map((row, index) => (
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row" style={{ color: "#337ab7", cursor: "pointer" }} onClick={() => OpenUserTab("body", row.userId, row.companyId)}>
                          {row?.userIdObj?.name}
                        </TableCell>
                        <TableCell>{row?.userIdObj?.email}</TableCell>

                        <TableCell>{row.role}</TableCell>
                        <TableCell>{row?.userIdObj?.userType}</TableCell>
                        <TableCell>{row?.userIdObj?.verified == 1 ? "Yes" : "No"}</TableCell>
                        <TableCell>{row.status}</TableCell>
                        <TableCell>{moment(row.userIdObj?.created).format("Do MMM YYYY")} GMT</TableCell>
                        <TableCell>
                          {/* {row.status == "Active" && row.roles[0] !== "ROLE_LICENSEE" ? (
                              <button className="impesonatebtn" onClick={() => impersonate(row.email)}>
                                Impersonate User
                              </button>
                            ) : (
                              <div></div>
                            )} */}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                ) : (
                  <TableBody>
                    <TableRow >
                      No Users Found
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <div className="ivitediv">
              <button className="inviteButton" onClick={redirectRoute}>
                Return To Channel List
              </button>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TableContainer>
              <Table className="accouttablestyle" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="tableHead">Publisher Id</TableCell>
                    <TableCell className="tableHead">Publisher Name</TableCell>
                    <TableCell className="tableHead">Company Type</TableCell>
                    <TableCell className="tableHead">Account Level</TableCell>
                    <TableCell className="tableHead">#datafree Data Credit Limit</TableCell>
                    <TableCell className="tableHead">Account #datafree Usage Alert</TableCell>
                    <TableCell className="tableHead">Status</TableCell>
                    <TableCell className="tableHead">Created</TableCell>
                  </TableRow>
                </TableHead>
                {channelData && channelData.publisherListResult.length > 0 ? (
                  <TableBody>
                    {channelData.publisherListResult.map((row, index) => (
                      <TableRow key={row.biNuId}>
                        <TableCell component="th" scope="row">
                          {row.companyId}
                        </TableCell>
                        <TableCell onClick={() => getCustomer(row?.companyId, row?.companyType)}><a style={{ cursor: 'pointer', textDecoration: 'none' }}
                          title="Click here to view publisher details">{row.name ? row.name : ""}</a></TableCell>
                        <TableCell>{row.companyType ? row.companyType : ""}</TableCell>
                        <TableCell>{row.accountLevel}</TableCell>
                        <TableCell>{row?.accDataCreditLimitId ? row.accDataCreditLimitId.creditLimit : ""}</TableCell>
                        <TableCell>{row.pubDataUsageAlert ? row.pubDataUsageAlert : ""}</TableCell>

                        <TableCell>{row.status ? row.status : ""}</TableCell>
                        <TableCell>{moment(row.created).format("Do MMM YYYY")} GMT</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow style={{ justifyContent: "center", margin: "2px", fontSize: "15px", display: "flex" }}>No Publishers Found </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <div className="ivitediv">
              <button className="inviteButton" onClick={redirectRoute}> Return To Channel List</button>
            </div>
          </TabPanel>
        </div>

      </div>
      {/* //UserEDit */}
      <Dialog open={userOpen} classes={{ scrollPaper: classes.scrollPaper }} fullWidth scroll={scroll} maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">User Details - Licensee View
            <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} /></div>

        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Company Name</div>
              <input type="text" className="dialoginputValue" disabled="disabled" style={{ cursor: 'not-allowed' }} value={userDetails ? userDetails.companyName : ""} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Company Type</div>
              <input type="text" disabled="disabled" value={userDetails ? userDetails.companyType : ""} style={{ cursor: 'not-allowed' }} className="dialoginputValue" fullWidth onChange={(e) => EditUser(e)} />
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">User’s Name</div>
              <input type="text" id="username" name="username"
                className={userNameError.username ? "errdialoginput" : "dialoginput"}
                defaultValue={userDetails ? userDetails.userName : ""}
                fullWidth
                // onChange={(e) => EditUser(e)} 
                onInput={(e) => EditUser(e)}
              />
              {userNameError.username ? <a className="errorClass">{userNameError.msg ? userNameError.msg : "This field is required"}</a> : null}
            </Grid>

            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Email Address</div>
              <input type="text" value={userDetails ? userDetails.email : ""} style={{ cursor: 'not-allowed' }} disabled="disabled" className="dialoginputValue" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Role</div>
              <select type="text" id="user_role" name="user_role" className="dialoginput" fullWidth
                style={{ width: '101%', height: '34px' }}
                onChange={(e) => EditUser(e)}>
                <option value="channel" selected={userDetails?.role === "channel" && true}>channel</option>
                <option value="accountmanager" selected={userDetails?.role === "accountmanager" && true}>account manager</option>
              </select>
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Type</div>
              <select type="text" id="user_type" name="user_type"
                style={{ width: '101%', height: '34px' }}
                label="email" className="dialoginput" fullWidth onChange={(e) => EditUser(e)}>
                <option value={userDetails ? userDetails.type : ""} selected="true">{userDetails ? userDetails.type : ""}</option>
              </select>
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Verified</div>
              <select type="text" id="user_verified" name="user_verified"
                style={{ width: '101%', height: '34px' }}
                label="email" className="dialoginput" fullWidth onChange={(e) => EditUser(e)}>
                <option value="Yes" selected={userDetails?.verified === 1 && true}>Yes</option>
                <option value="No" selected={userDetails?.verified === 0 && true}>No</option>
              </select>
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Status</div>
              <select type="text" id="user_status" name="user_status"
                style={{ width: '101%', height: '34px' }}
                label="email" className="dialoginput" fullWidth onChange={(e) => EditUser(e)}>
                <option value="Active" selected={userDetails?.status === "Active" && true}>Active</option>
                <option value="Deleted" selected={userDetails?.status === "Deleted" && true}>Deleted</option>
              </select>
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Created</div>
              <input type="text" label="email" style={{ cursor: 'not-allowed' }} value={moment(userDetails ? userDetails.created : "").format("YYYY-MM-DD HH:mm") + " GMT"} disabled="disabled" className="dialoginputValue" fullWidth />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
        {loadings?  <div className="loaderContainer">
            <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} /></div>:null}
          <div className="buttonRow">
            <div>
              <button className="inviteButton" onClick={() => resetPassword(userDetails?.email)} color="primary">
                Request Password Reset
              </button>
            </div>
            <div>
              <button className="inviteButton" onClick={handleClose} color="primary">
                Cancel
              </button>

              <button className="inviteButton" color="primary" onClick={EditUserData}>
                Update User Details
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
      {/* //INVITE uSER */}
      <Dialog open={inviteUser} classes={{ scrollPaper: classes.scrollPaper }}
        scroll={scroll}
        fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle">
          <div className="FlexRow">
            <div className="cmpdialogtitle">  Invite Publisher User </div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline style={{ cursor: "pointer" }} onClick={handleClose} />
            </div>
          </div>

        </DialogTitle>
        <DialogContent>
          <div className="dialoginputtext">Name:</div>
          <input type="text" id="name" label="name"
            className={inviteError.name ? "errdialoginput" : "dialoginput"}
            onChange={(e) => handleUser(e)} fullWidth />
          {inviteError.name ? <a className="errorClass">{inviteError.nameErMsg ? inviteError.nameErMsg : "This field is required"}</a> : null}

          <div className="dialoginputtext">Email:</div>
          <input type="text" id="email_address" name="email_address"
            label="email"
            className={inviteError.email_address ? "errdialoginput" : "dialoginput"}
            onChange={(e) => handleUser(e)} fullWidth />
          {inviteError.email_address ? <a className="errorClass">{inviteError.emailErrMsg ? inviteError.emailErrMsg : "This field is required"}</a> : null}

          <div className="dialoginputtext">Role:</div>
          <select type="text" id="user_role" name="user_role"
            className={inviteError.user_role ? "errdialoginput" : "dialoginput"}
            style={{ width: '101%', height: '34px' }}
            onChange={(e) => handleUser(e)} fullWidth>
            <option></option>
            <option value="channel">channel</option>
            <option value="accountmanager">account manager</option>
          </select>
          {inviteError.user_role ? <a className="errorClass">{inviteError.roleErrMsg ? inviteError.roleErrMsg : "This field is required"}</a> : null}
        </DialogContent>
        <DialogActions>
          {lodervisible ? <div className="loaderContainer">
            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}

          <div className="ivitediv">
            <button className="inviteButton" onClick={handleClose} color="primary">
              Cancel
            </button>
            <button className="inviteButton" onClick={InviteNew}
              disabled={clickInvite ? "disabled" : false}
              color="primary">
              Invite User
            </button>
          </div>
        </DialogActions>
      </Dialog>

      {/* ////////////////// Remove Company Logo ///////////////// */}
      <Dialog open={removeDialogue} classes={{ scrollPaper: classes.scrollPaper }} name="popup" fullWidth maxWidth="sm" onClose={DialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle  border">
          <div className="FlexRow border" >
            <div className="cmpdialogtitle"> </div>
            <div className="cmpdialogtitle" name="popup">
              <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={DialogClose} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="ContentHead">
            <div className="warningIcon"><MdWarning /></div>
            <div className="warningText">WARNING !</div>

          </div>
          <div className="logoConfirmtext">Are you sure, do you want to delete Company Logo?</div>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv">
            <button className="continueBtn" name="popup"
              style={{ marginRight: '10px' }}
              onClick={DialogClose} color="primary">
              Cancel
            </button>

            <button className="continueBtn" name="popup" onClick={removeLogo} color="primary">
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>
      {/*///////////////////////Popup//////////////////////////////*/}
      <Dialog open={openPopup} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }} aria-labelledby="form-dialog-title">

        <DialogContent className="diaContent">
          <div className="logoConfirmtext">Please Save or Cancel the changes before proceeding</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" name="popup" color="primary">
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>


      <Dialog open={invitedSuccess} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Success Message</div>

        </DialogTitle>
        <DialogContent className="diaContent">
          <div className="logoConfirmtext" style={{ fontSize: '17px' }}>Successfully added new user. Invitation link sent to user's E-mail id.</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" style={{ width: '11%' }} name="popup" color="primary" onClick={() => setInvitedSuccess(false)}>
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <Snackbar open={successopen} autoHideDuration={1500} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
          {toastMsg ? toastMsg : "Updated Successfully!"}
        </Alert>
      </Snackbar>

      <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={erroropens} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {errMsg ? errMsg : "An Error Occured!"}
        </Alert>
      </Snackbar>
      <Snackbar open={successRequest} autoHideDuration={1500} onClose={handleAlertCloses}>
        <Alert onClose={handleAlertCloses} severity="success">
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default LicenseeChannelDetailView;
