import React, { useEffect, useState, useContext } from "react";
import applicationIcon from "../../../assets/img/application-icon.png";
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Grid } from "@material-ui/core";
import AccountManagerService from "../../../api/accountService";
import VMCTable from "../../../Recomponent/Table";
import EmptyTable from "../../../Recomponent/EmptyTable";
import { useHistory } from "react-router-dom";
import Loader from "react-loading";
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from "react-promise-tracker";
import Service from "../../../api/accountService";
import moment from "moment";
import { IoMdCloseCircleOutline } from "react-icons/io";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {
  ToggleContext
} from "../../context/toggleContext"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};

const useStyles = makeStyles((theme) => ({
  scrollPaper: {
    alignItems: "baseline",

    // default center
  },
}));
function UserList() {
  let history = useHistory();
  const classes = useStyles();
  const [changedropdown, setChangedropdown] = useState(false);
  const [search, setSearch] = useState(false);
  const [searchDetails, setSearchDetails] = useState();
  const [empty, setEmpty] = useState(false);
  const [userData, setUserData] = useState();
  const [userDetails, setuserDetails] = useState();
  const [rset, setRst] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [message, setMessage] = useState();
  const [userOpen, setUserOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [resMessage, setResMessage] = useState();
  const toggleContextVal = useContext(ToggleContext)
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const [data, setData] = useState({
    searchPublisherName: "",
    searchUserName: "",
    searchEmail: "",
    searchVerified: "",
    searchStatus: "",
    channelId: "",
    accountManagerEmail: ""
  });
  const [userdata, setUserdata] = useState({
    username: "",
    user_role: "User",
    user_type: "Internal",
    user_verified: "No",
    user_status: "Active",
    user_id: "",
    company_id: "",
  });

  async function publisherListdata() {
    setChangedropdown(true);
    setSearch(true);
    setSearchDetails({});
    setEmpty(false);

    data['channelId'] = userData?.channelId;
    AccountManagerService.userListSearch(data).then((res) => {
      setSearchDetails(res?.data);
      if (res.status === 200 && res.data.publisherListResult.length > 0) {
        setChangedropdown(false);
      } else if (res.status === 200 && res.data.publisherListResult.length === 0) {
        setEmpty(true);
        setChangedropdown(false);
      } else {
        setChangedropdown(true);
        setEmpty(false);
      }

    }).catch((err) => {
      setResMessage("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    })
  }

  const lifecycleChange = (e) => {
    e.preventDefault();
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    setEmpty(false);
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  };


  const getData = () => {
    setLoading(true);
    AccountManagerService.AccountIndex().then((res) => {

      if (res.status === 200) {
        setUserData(res.data);
        setData({
          ...data,
          accountManagerEmail: res?.data?.email,
          channelId: res?.data?.channelId,
        });
      }
      setLoading(false);

    }).catch((err) => {
      setResMessage("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    })
  };

  const handleRouteLink = (companyId) => {
    history.push(`/accountmanager/publisher/${companyId}/detail/view`);
  };

  const [tablecols, setTableCols] = useState([
    {
      colName: 'User’s Name',
      colMap: 'name',
      linkName: true,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: true
    },
    {
      colName: 'Email Address',
      colMap: 'email',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Role',
      colMap: 'role',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Type',
      colMap: 'userType',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Verified',
      colMap: 'verified',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Status',
      colMap: 'status',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: true,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Publisher',
      colMap: 'companyIdname',
      linkName: true,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Company Type',
      colMap: 'companyIdcompanyType',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    }
  ])

  const [loading, setLoading] = useState(false);
  async function publisherListdata() {
    setChangedropdown(true);
    setSearch(true);
    setLoading(true);
    setSearchDetails({});
    //data['channelId'] = userData?.channelId;
    AccountManagerService.userListSearch(data).then((res) => {
      setSearchDetails(res?.data);
      setLoading(false);
      if (res.status == 200 && res?.data?.userListResult?.length > 0) {

        setChangedropdown(false);
      } else if (res.status == 200 && res?.data?.userListResult?.length == 0) {
        setEmpty(true);
        setChangedropdown(false);
      } else {
        setChangedropdown(true);
        setEmpty(false);
      }

    }).catch((err) => {
      setResMessage("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    })
  }

  const handleRoute = (cType, cId) => {
    if (cType === "CUSTOMER") {
      history.push(`/accountmanager/publisher/${cId}/detail/view`)
    }
  }

  //passwordReset
  const resetPassword = (EMAIL) => {
    trackPromise(
      Service.resetPassword({ email: EMAIL, admin: "admin" }).then((res) => {
        if (res.status === 200) {
          setUserOpen(false);
          setRst(true);
          setMessage(res.data.message);
        }
      }).catch((err) => {
        setResMessage("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      })
    );

  }
  const closeResetPop = () => {
    setRst(false);

  }

  const OpenUserTab = (scrollType, Id, companyIds) => {
    getUserDetail(Id, companyIds);

    setUserOpen(true);
    setScroll(scrollType);
  };
  const EditUser = (e) => {
    e.preventDefault();

    const newdata = { ...userdata };
    newdata[e.target.id] = e.target.value;
    if (e.target.id === "username") {
      if (e.target.value) {
        setUserUpdate({
          username: false,
        });
      } else {
        setUserUpdate({
          username: true,
          msg: "This field is required",
        });
      }
    }

    setUserdata(newdata);
  };
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setUserOpen(false);
    }

    setSearchDetails({});
    setuserDetails({
      username: "",
      user_role: "User",
      user_type: "Internal",
      user_verified: "Yes",
      user_status: "Active",
      user_id: "",
      company_id: "",
    });
    setUserUpdate({
      username: false,
    });
  };
  const [userUpdate, setUserUpdate] = useState({
    username: false,
    msg: "",
  });
  //getUser
  async function getUserDetail(value, compyIds) {
    setuserDetails({});
    trackPromise(
      Service.getUserDetails({ userId: value, companyId: compyIds })
        .then((res) => {
          setuserDetails(res.data);
          setUserdata({
            username: res.data.userName,
            user_role: res.data.role,
            user_type: res.data.type,
            user_verified: res.data.verified && res.data.verified == 1 ? "Yes" : "No",
            user_status: res.data.status,
            user_id: res.data.userId,
            company_id: res.data.companyId,
          });
        })
        .catch((err) => {
          setResMessage("Something went wrong. Please reload the page!");
          seterrorOpen(true);
        })
    );
  }
  const [loadings, setLoadings] = useState(false);
  //editUser Detail
  async function EditUserData() {

    if (userUpdate.username) {
      setUserUpdate({
        username: true,
      });
    } else {
      setLoadings(true);
      trackPromise(
        Service.EditUserDetails(userdata)
          .then((res) => {
            setLoadings(false);
            if (res.status === 200) {

              publisherListdata();
              handleClose();
              setUserOpen(false);

            } else {
              setUserOpen(true);
            }
          })
          .catch((err) => {
            setResMessage("Something went wrong. Please reload the page!");
            seterrorOpen(true);
          })
      );
      publisherListdata();
    }
  }
  useEffect(() => {
    setLoading(true);
    getData();
    document.title = "Datafree Portal";
  }, [])

  return (
    <div className={toggleContextVal.event ? "mainMobView" : "mainBoxView"}>
      <div className="NavBoxDiv">
        <div className="NavBoxRow col-lg-6 col-md-6 col-sm-6 col-xs-5">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">User List - Account Manager View</div>
        </div>
        <div className="NavBoxRowFilter table-responsive">
          <table className="table-sm">
            <tbody>
              <tr>
                <td className="col-md-2">

                </td>
                <td className="col-md-2">
                  Publisher
                </td>
                <td className="col-md-2">
                  User's Name
                </td>
                <td className="col-md-3">
                  Email Address
                </td>
                <td className="col-md-1">
                  Verified
                </td>
                <td className="col-md-2">
                  Status
                </td>
                <td className="col-md-2">

                </td>
              </tr>
              <tr>
                <td className="col-md-2">

                </td>
                <td className="col-md-2">
                  <input
                    type="text"
                    className="form-control"
                    id="searchPublisherName"
                    name="searchPublisherName"

                    onChange={(e) => lifecycleChange(e)}
                  ></input>
                </td>
                <td className="col-md-2">
                  <input
                    type="text"
                    className="form-control"
                    id="searchUserName"
                    name="searchUserName"

                    onChange={(e) => lifecycleChange(e)}
                  ></input>
                </td>
                <td className="col-md-3">
                  <input
                    type="text"
                    className="form-control"
                    id="searchEmail"
                    name="searchEmail"

                    onChange={(e) => lifecycleChange(e)}
                  ></input>

                </td>
                <td className="col-md-1">
                  <select
                    type="text"
                    className="form-control form-select"
                    id="searchVerified"
                    name="searchVerified"

                    onChange={(e) => lifecycleChange(e)}
                  >
                    {" "}
                    <option value=""></option>
                    <option value="no">No</option>
                    <option value="yes">Yes</option>

                  </select>
                </td>
                <td className="col-md-2">
                  <select
                    type="text"
                    className="form-control form-select"
                    id="searchStatus"
                    name="searchStatus"

                    onChange={(e) => lifecycleChange(e)}
                  >

                    <option value=""></option>
                    <option value="Active">Active</option>

                  </select>
                </td>
                <td className="col-md-2">
                  <button className={
                    changedropdown ? "defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"} onClick={publisherListdata}

                  >
                    Search
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

        </div>

        <div><hr className="applications-list-hr"></hr></div>
        
        {loading ? <div className="loaderContainer">
          <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}

        <div>
          {search ? (
            <div>
              {searchDetails && searchDetails?.userListResult?.length > 0 ? (
                <VMCTable key={searchDetails?.userListResult} data={searchDetails?.userListResult}
                  col={tablecols} tableName="AccountManager User List"
                  tabNavigation={OpenUserTab} serviceNav={handleRouteLink} navigateFunction={handleRoute} />
              ) : (
                <div>
                  <LoadingIndicator />
                  {empty ? <EmptyTable data="No Publishers Found" col={tablecols} tableName="AccountManager User List" /> : null}
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
      {/* /////////////////////////////////////// UPDATE USERDETAILS ////////////////////////// */}
      <Dialog open={userOpen} classes={{ scrollPaper: classes.scrollPaper }} fullWidth scroll={scroll} maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <div className="FlexRow">
            <div className="cmpdialogtitle">User Details - Account Manager View</div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline style={{ cursor: "pointer" }} className="modalClose" onClick={handleClose} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container>

            <Grid item xs={12}>
              <div className="cmpdialoginputtext">User’s Name</div>
              <input
                type="text"
                id="username"
                name="username"
                defaultValue={userDetails ? userDetails.userName : ""}
                onInput={(e) => EditUser(e)}

                className={userUpdate.username ? "form-control errorField" : "form-control"}
                fullWidth
              />
              {userUpdate.username ? <a className="errorClass">{userUpdate.msg ? userUpdate.msg : "This field is required"}</a> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Email Address</div>
              <input
                type="text"
                id="reg_address"
                name="reg_address"
                label="email"
                defaultValue={userDetails ? userDetails.email : ""}
                disabled="disabled"

                className="form-control"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Role</div>
              <input
                type="text"
                id="user_role"
                name="user_role"
                className="form-control"
                defaultValue={userDetails ? userDetails.role : ""}
                fullWidth
                disabled="disabled"
              >
              </input>
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Type</div>
              <input id="user_type" name="user_type"
                defaultValue={userDetails ? userDetails.type : ""}
                className="form-control" fullWidth disabled="disabled">

              </input>
            </Grid>

            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Verified</div>
              <select type="text" id="user_verified" name="user_verified" label="email" className="form-control form-select" fullWidth onChange={(e) => EditUser(e)}>
                <option selected={userDetails?.verified == 1 && true} value="1">
                  YES
                </option>
                <option selected={userDetails?.verified == 0 && true} value="0">
                  NO
                </option>
              </select>
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Status</div>
              <select type="text" id="user_status" name="user_status" label="email" className="form-control form-select" fullWidth onChange={(e) => EditUser(e)}>
                <option selected={userDetails?.status == "Active" && true} value="Active">
                  Active
                </option>
                <option selected={userDetails?.status == "Deleted" && true} value="Deleted">
                  Deleted
                </option>
              </select>
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Created</div>
              <input type="text" label="email"
                className="dialoginputValue" value={moment(userDetails ? userDetails.created : "").format("YYYY-MM-DD HH:mm") + " GMT"} fullWidth />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {loadings ? <div className="loaderContainer">
            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}

          <div className="buttonRow">
            <div>
              <button className="inviteButton" onClick={() => resetPassword(userDetails.email)} color="primary">
                Request Password Reset
              </button>
            </div>
            <div>
              <button className="inviteButton" onClick={handleClose} color="primary">
                Cancel
              </button>

              <button className="inviteButton" color="primary" onClick={EditUserData}>
                Update User Details
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
      {/* Password Reset */}
      <Dialog open={rset} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <div className="FlexRow">
            <div className="cmpdialogtitle">Request Password Reset</div>

          </div>
        </DialogTitle>
        <DialogContent className="diaContents">
          <div >{message ? message : null}</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" color="primary" onClick={closeResetPop}>
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {resMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default UserList;
