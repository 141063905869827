import { Grid } from "@material-ui/core";
import applicationIcon from "../../../assets/img/application-icon.png";
import React, { useState, useEffect,useContext } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { IoMdCloseCircleOutline } from "react-icons/io";
import EmptyTable from "../../../Recomponent/EmptyTable";
import VMCTable from "../../../Recomponent/Table";
import AdminService from "../../../api/adminService";
import { useHistory } from "react-router-dom";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loading";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {
  ToggleContext
} from "../../context/toggleContext"

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};

function MultipleRoleDetails() {
  const classes = useStyles();
  let history = useHistory();
  const [manageList, setManageList] = useState();
  const { id } = useParams();
  const [empty, setEmpty] = useState(false);
  const [changedropdown, setChangedropdown] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [successopen, setsuccessOpen] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const toggleContextVal = useContext(ToggleContext)
  const [mnoDetail, setMnoDetail] = useState(
    {
      userId: "",
      taskList: [],

    }

  )
  const [data, setData] = useState({
    searchParentCompany: "",
    searchCompanyType: "",
    searchPublisherId: "",
    searchPublisherName: "",
    searchStatus: "",
  });
  const [tablecol, setTableCol] = useState([
    {
      colName: "Managed By",
      colMap: "parentCompanyIdname",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Company Id",
      colMap: "companyId",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Company Name",
      colMap: "names",
      linkName: true,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Company Type",
      colMap: "companyType",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Status",
      colMap: "status",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Contract Term",
      colMap: "contractTerm",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Created",
      colMap: "created",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: true,
      imprRoute: false,
      userTab: false,

    },
  ]);

  const [taskNewDetail, setTaskNewDetail] = useState(
    {

      userId: "",
      newtaskList: [],

    }

  )

  const deleteRow = () => {
    setRowClick(false);

    setTaskNewDetail({
      newtaskList: []
    });
    //manageListdata()
  }
  const handleClick = (accid) => {
    document.getElementById("accountid").innerText = accid;
    setAccountOpen(true);

  }
  const handleClose = () => {
    setAccountOpen(false)
    setScroll('body');
  }
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const manageListdata = () => {
    setLoaderVisible(true);
    AdminService.getUserRoleById(id).then((res) => {
      setLoaderVisible(false);
      setManageList(res.data);
      localStorage.setItem("roleUserId", res?.data?.userDetails?.userId)
      mnoDetail['userId'] = res?.data?.userDetails?.userId
      res.data?.UserCompaniesDetail.forEach(function (user) {
        mnoDetail?.taskList.push({
          companyId: user.companyId, companyType: user.companyIdObj.companyType,
          role: user.role, status: user.status, isDefault: user.isDefault
        });
      });
      deleteRow();

    })

  }
  const [rowClick, setRowClick] = useState(false)
  const addRow = e => {

    e.preventDefault();
    setRowClick(true);
    setTaskNewDetail((prevState) => ({
      userId: "",
      newtaskList: [...prevState.newtaskList, {
        companyId: "",
        companyType: "",
        role: "",
        status: "Active",
        isDefault: "1"
      }]
    }))
    setErrors((prevState) => (
      [...prevState, {
        companyname: false,
        errormsg: ""
      }]
    ))

  };

  //managed By List
  const [accountOpen, setAccountOpen] = useState(false);
  const [manageAccount, setManageAccount] = useState();
  async function manageAccountdata() {
    AdminService.ManagedListData().then((res) => {
      setManageAccount(res.data.publisherListResult);
    }).catch((err) => {

    });
  }

  const [datas, setDatas] = useState({
    searchParentCompany: "",
    searchCompanyType: "",
    searchPublisherId: "",
    searchPublisherName: "",
    searchStatus: "",
  });

  const lifecycleChanges = (e) => {
    e.preventDefault();
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    setEmpty(false);
    const newdata = { ...datas };
    newdata[e.target.id] = e.target.value;
    setDatas(newdata);
  };
  const [emptys, setEmptys] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searchs, setSearchs] = useState(false);
  const [accountLoading, setAccountLoading] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [message, setMessage] = useState();
  const handleAlertClose = () => {
    seterrorOpen(false);
  }
  //search
  async function companyListdatas() {
    setSearchs(true);
    setChangedropdown(true);
    setEmptys(false);
    setAccountLoading(true);
    setSearchData({});
    AdminService.listSearch(datas).then((res) => {
      setSearchData(res.data.publisherListResult);
      setAccountLoading(false);
      if (res.status == 200 && res.data.publisherListResult.length > 0) {
        setChangedropdown(false);
      } else if (res.status == 200 && res.data.publisherListResult.length == 0) {
        setEmptys(true);
        setChangedropdown(false);
      } else {
        setChangedropdown(true);
        setEmptys(false);
      }
    }).catch((err) => {
      setMessage("Something went wrong. Please reload the page");
      seterrorOpen(true);
    })
  }
  const handleRouteLinks = (companyNames, companyId, type) => {

    const accoutvals = document.getElementById("accountid").innerText;
    document.getElementById('name_' + accoutvals).innerText = "";
    var select = document.getElementById('name_' + accoutvals);
    if (type == "BINU") {
      document.getElementById('name_' + accoutvals).innerText = "";
    } else {
      select.options[select.options.length] = new Option(companyNames, companyId, true);

      checkCompanys(companyNames, companyId, type, accoutvals);
    }
    setAccountOpen(false);

  };
  const [errors, setErrors] = useState([{
    index: Math.random(),
    companyname: false,
    errormsg: ""
  }])

  const checkCompanys = (companyNames, companyId, type, accoutvals) => {

    AdminService.companyExist({ "companyId": companyId, "userId": id }).then((res) => {

      let NewtaskList = {};
      NewtaskList['companyId'] = companyId;
      NewtaskList['companyType'] = type;
      NewtaskList['status'] = "Active";
      NewtaskList['isDefault'] = 1;
      if (res.data == true) {

        let newError = [...errors]

        newError[accoutvals].companyname = true
        newError[accoutvals].errormsg = "Company already exits"
        setErrors(newError)

      }
      else {
        let newError = [...errors]
        newError[accoutvals].companyname = false
        newError[accoutvals].errormsg = ""
        setErrors(newError)
        if (res.data === "CUSTOMER") {
          document.getElementById('role_' + accoutvals).innerText = "";
          var select = document.getElementById('role_' + accoutvals);
          select.options[select.options.length] = new Option('user', 'user', true);
          NewtaskList['role'] = 'user';
        }

        if (res.data === "RESLR CLNT") {
          document.getElementById('role_' + accoutvals).innerText = "";
          var select = document.getElementById('role_' + accoutvals);
          select.options[select.options.length] = new Option('reseller', 'reseller', true);
          NewtaskList['role'] = 'reseller';

        }
        if (res.data === "LICENSEE") {

          document.getElementById('role_' + accoutvals).innerText = "";
          var select = document.getElementById('role_' + accoutvals);
          select.options[select.options.length] = new Option('licensee', 'licensee', true);

          NewtaskList['role'] = 'licensee';

        }

        if (res.data === "CHANNEL") {

          document.getElementById('role_' + accoutvals).innerText = "";
          var select = document.getElementById('role_' + accoutvals);
          select.options[select.options.length] = new Option('channel', 'channel', true);
          select.options[select.options.length] = new Option('accountmanager', 'accountmanager');
          NewtaskList['role'] = 'channel';

        }

        setMnoDetail((prevState) => ({
          userId: localStorage.getItem("roleUserId"),
          taskList: [...prevState.taskList, NewtaskList]
        }))

      }
    }).catch((err) => {

    });
  }


  const addDetails = () => {
    mnoDetail['userId'] = localStorage?.getItem("roleUserId")
    AdminService.RoleSaveAdd(mnoDetail).then((res) => {
      setsuccessOpen(true)
      setRowClick(false)
      manageListdata()
    })
  }

  const changeRole = (ind, Id, e) => {

    e.preventDefault();

    let taskList = [...mnoDetail.taskList]

    taskList[ind][e.target.name] = e.target.value

  }

  const NewchangeRole = (e, idvalue) => {

    e.preventDefault();

    let taskList = [...mnoDetail?.taskList]

    taskList[idvalue][e.target.name] = e.target.value

  }


  useEffect(() => {
    manageAccountdata();
    manageListdata();
  }, []);

  return (
    <div  className={toggleContextVal.event ?"mainMobView" :"mainBoxView"}>
      <div className="NavBoxDiv">
        <div className="NavBoxRow navBottom">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">Maintain Multiple User Roles Details - Admin View</div>
        </div>

        <div>
          <h6><b>User</b></h6>
          <div className="RowBoxFilter">
             
            <div className="row">
              <div className="col-md-3 form-group">
                <div className="NavBoxRowFilterLabel">User Name</div>

                <input type="text"

                  className="form-control"
                  disabled="disabled" value={manageList?.userDetails?.name}></input>
              </div>
              <div className="col-md-3 form-group">
                <div className="NavBoxRowFilterLabel">Email Address</div>

                <input type="text"
                  className="form-control"
                  disabled="disabled" value={manageList?.userDetails?.email}></input>

              </div>
              <div className="col-md-2 form-group">
                <div className="NavBoxRowFilterLabel">Type</div>

                <input type="text" className="form-control"
                  disabled="disabled" value={manageList?.userDetails?.userType}></input>

              </div>
              <div className="col-md-2 form-group">
                <div className="NavBoxRowFilterLabel" >Verified</div>

                <input type="text" className="form-control"
                  disabled="disabled" value={manageList?.userDetails?.verified == 1 ? "Yes" : "No"}></input>

              </div>

              <div className="col-md-2 form-group">
                <div className="NavBoxRowFilterLabel">Created</div>

                <input type="text" className="form-control"
                  disabled="disabled" value={manageList?.userDetails?.created}></input>
              </div>

            </div>

          </div>

          <div>
            <div className="NavBoxRowFilterLabel"><h6><b>Roles</b></h6></div>
            {!loaderVisible ? <Grid container>
              <Grid item sm={3} xs={3} md={3}>
                <div className="NavBoxRowFilterLabel"> <h6><b>Company Name</b></h6> </div>

              </Grid>
              <Grid item sm={3} xs={3} md={3}>
                <div className="NavBoxRowFilterLabel"><h6><b>Type</b></h6></div>

              </Grid>
              <Grid item sm={2} xs={3} md={2}>
                <div className="NavBoxRowFilterLabel"><h6><b>User Role</b></h6></div>

              </Grid>
              <Grid item sm={2} xs={3} md={2}>
                <div className="NavBoxRowFilterLabel"><h6><b>Status</b></h6></div>

              </Grid>

              <Grid item sm={2} xs={3} md={2}>
                <div className="NavBoxRowFilterLabel"><h6><b>Is Default</b></h6></div>

              </Grid>
            </Grid> : null}
            {loaderVisible == true ? <div className="loaderContainer">
              <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
            {manageList?.UserCompaniesDetail?.map((item, index) => (

              <Grid container key={index}>
                <Grid item sm={3} xs={3} md={3}>

                  <div className="NavBoxRowFilterLabel">{item?.companyIdObj?.name}</div>
                </Grid>
                <Grid item sm={3} xs={3} md={3}>

                  <div className="NavBoxRowFilterLabel">{item?.companyIdObj?.companyType}</div>
                </Grid>
                <Grid item sm={2} xs={3} md={2}>

                  <div className="NavBoxRowFilterLabel">{item?.role}</div>
                </Grid>
                <Grid item sm={2} xs={3} md={2}>

                  <select type="select" className="statusValid" data-id={index} id="status"
                    onChange={(e) => changeRole(index, item?.companyIdObj?.companyId, e)}
                    name="status">
                    <option selected={item?.status == "Active" || item?.status == "active" && true} value="Active">Active</option>
                    <option selected={item?.status == "Deleted" || item?.status == "deleted" && true} value="Deleted">Deleted</option>
                  </select>
                </Grid>

                <Grid item sm={2} xs={3} md={2}>

                  <select type="select" className="statusValid" data-id={index}
                    onChange={(e) => changeRole(index, item?.companyIdObj?.companyId, e)} id="isDefault"
                    name="isDefault">
                    <option selected={item?.isDefault == false && true} value="0">No</option>
                    <option selected={item?.isDefault == true && true} value="1">Yes</option>
                  </select>
                </Grid>
              </Grid>

            ))
            }
          </div>
        </div>
        <input type="hidden" id="accountid" value=""></input>
        {rowClick == true ?
          taskNewDetail?.newtaskList?.map((val, idx) => {
            let idvalue = manageList?.UserCompaniesDetail?.length + (idx), name = `name_${idx}`, role = `role_${idx}`, isDefault = `isDefault_${idx}`, status = `status_${idx}`

            return (
              <div key={idx}>

                <Grid container spacing={1}>
                  <Grid item sm={3} md={3} xs={3}>
                    <select type="text" className={errors[idx]?.companyname ? "form-control form-select form-group" : "form-control form-select form-group"}

                      id={name}
                      name="name"
                      data-id={idx}
                      onChange={(e) => NewchangeRole(e, idx)}
                      onClick={() => handleClick(idx)}>
                      <option value=""></option>
                    </select>
                    {errors[idx]?.companyname ? <a className="errorClass">{errors[idx]?.errormsg}</a> : null}
                  </Grid>
                  <Grid item sm={3} md={3} xs={3}></Grid>

                  <Grid item  sm={2} md={2} xs={3}>
                    <select type="text" className="form-control form-select form-group"
                      id={role}
                      name="role"
                      onChange={(e) => NewchangeRole(e, idx)}
                      data-id={idx}>
                      <option value=""></option>

                    </select>
                  </Grid>
                  <Grid item  sm={2} md={2} xs={3}>
                    <select type="text" className="form-control form-select form-group"

                      id={status}
                      data-id={idx}
                      onChange={(e) => NewchangeRole(e, idvalue)}
                      name="status">
                      <option value="Active">Active</option>
                      <option value="Deleted">Deleted</option>

                    </select>
                  </Grid>

                  <Grid item  sm={2} md={2} xs={3}>
                    <select type="text" className="form-control form-select form-group"

                      id={isDefault}
                      data-id={idx}
                      name="isDefault"
                      onChange={(e) => NewchangeRole(e, idvalue)}
                    >
                      <option value="1">Yes</option>
                      <option value="0">No</option>

                    </select>
                  </Grid>
                </Grid>

              </div>

            );
          }) : null}
        <div className="buttonRowFlex">
          <div>
            <button className="inviteButton" color="primary" onClick={addRow}>
              Add Role
            </button>
          </div>
          <div>
            <button className="inviteButton" color="primary" onClick={() => deleteRow()}>
              Cancel
            </button>

            <button className="inviteButton" color="primary" onClick={() => addDetails()}>
              Save
            </button>
          </div>
        </div>

      </div>
      {/* /////////////////////////////////////////Account Picker////////////////////////////////////// */}

      <Dialog open={accountOpen}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        scroll={scroll}
        maxWidth="lg"
        aria-labelledby="form-dialog-title">

        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Account Picker
            <IoMdCloseCircleOutline className="modalClose" onClick={() => setAccountOpen(false)} />
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="NavBoxRowFilter wrapAccount">
            <Grid container spacing={1}>

              <Grid item xs={2}>
                <div className="cmpdialoginputtext">Managed By</div>
                <select type="text" id="searchParentCompany" name="searchParentCompany"
                  onChange={(e) => lifecycleChanges(e)}
                  className="form-control form-select">
                  <option value=""></option>
                  {manageAccount && manageAccount.length > 0
                    ? manageAccount.map((post) => (
                      <option key={post.companyId} value={post.companyId}>
                        {post.name}
                      </option>
                    ))
                    : null}
                </select>
              </Grid>
              <Grid item xs={2}>
                <div className="cmpdialoginputtext">Company Type</div>
                <select type="text" id="searchCompanyType"
                  name="searchCompanyType"
                  onChange={(e) => lifecycleChanges(e)}
                  className="form-control form-select">
                  <option value=""></option>
                  <option value="CHANNEL">CHANNEL</option>
                  <option value="CUSTOMER">CUSTOMER</option>
                  <option value="RESELLER">RESELLER</option>
                  <option value="LICENSEE">LICENSEE</option>
                </select>
              </Grid>

              <Grid item xs={2}>
                <div className="cmpdialoginputtext">Company Id</div>
                <input
                  type="text"
                  id="searchPublisherId"
                  name="searchPublisherId"
                  onChange={(e) => lifecycleChanges(e)}
                  className="form-control">
                </input>
              </Grid>
              <Grid item xs={2}>
                <div className="cmpdialoginputtext">Company Name</div>
                <input
                  type="searchPublisherName"
                  id="searchPublisherName"
                  name="mesage"
                  onChange={(e) => lifecycleChanges(e)}
                  className="form-control">
                </input>
              </Grid>

              <Grid item xs={2}>
                <div className="cmpdialoginputtext">Status</div>
                <select type="text" id="searchStatus"
                  name="searchStatus"
                  onChange={(e) => lifecycleChanges(e)}
                  className="form-control form-select"
                  fullWidth>

                  <option value=""></option>
                  <option value="Active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </Grid>
              <Grid item xs={1}>
                <div className="divSpace">
                  <button
                    className={changedropdown ? "binudefaultbtnafterclick" : "binudefaultbtn"}
                    disabled={changedropdown ? "disabled" : false}
                    style={{ marginTop: '37px', height: '34px' }}
                    onClick={companyListdatas}
                  >
                    Search
                  </button>
                </div>
              </Grid>
            </Grid>
            {accountLoading ? <div className="loaderContainer"><Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          </div>
          <div className="createButtonDiv"></div>
       
        
          {searchs ? (
            <div>
              {searchData && searchData.length > 0 ? (
                <VMCTable key={searchData} data={searchData} col={tablecol}
                  navigateFunctions={handleRouteLinks} tableName="RoleCompany List" />
              ) : (
                <div style={{ alignItems: "center" }}>

                  {emptys ? <EmptyTable data="No Companies Found" col={tablecol} tableName="RoleCompany List" /> : null}
                </div>
              )}
            </div>
          ) : null}
        </DialogContent>

      </Dialog>
      <Snackbar open={erroropen} autoHideDuration={1400} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {message ? message : "An Error Occured!"}
        </Alert>
      </Snackbar>
      <Snackbar open={successopen} autoHideDuration={1400} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
          "Added Successfully!!"
        </Alert>
      </Snackbar>
    </div>
  )
}
export default MultipleRoleDetails;