import React, { useState, useEffect,useContext} from "react";
import { Tabs, Tab, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, withStyles, Grid } from "@material-ui/core";
import Service from "../../../api/accountService";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useHistory, useParams } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import Snackbar from "@material-ui/core/Snackbar";
import { IoMdCloseCircleOutline } from "react-icons/io";
import MuiAlert from "@material-ui/lab/Alert";
import VMCTable from "../../../Recomponent/Table";
import EmptyTable from "../../../Recomponent/EmptyTable";
import Loader from "react-loading";
import { usePromiseTracker } from "react-promise-tracker";
import ChannelService from "../../../api/channelService";
import Services from "../../../api/adminService";
import {
    ToggleContext
  } from "../../context/toggleContext"

function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <div>
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const AntTabs = Tabs;

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: "none",
        borderBottom: "1px solid #ddd ",
        minWidth: "80px",

        textShadow: "none",
        color: "#0059b6",

        "&$selected": {
            color: "#555",
            backgroundColor: "#fff",
            border: "1px solid #ddd",
            borderBottomColor: "transparent",
            borderBottom: "none",
            cursor: "default",
            paddingLeft: "10px",
            paddingRight: "10px",
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);
const useStyles = makeStyles((theme) => ({
    scrollPaper: {
        alignItems: "baseline",

        // default center
    },
}));
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const LoadingIndicator = (props) => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress && (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#ffffff",
                }}
            >
                <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
            </div>
        )
    );
};

function PublisherDetailView(props) {
    let history = useHistory();
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const { companyId } = useParams();
    const [discount, setDiscount] = useState("");
    const [editInfo, setEditInfo] = useState(false);
    const [scroll, setScroll] = useState("paper");
    const [userOpen, setUserOpen] = useState(false);
    const [userDetails, setuserDetails] = useState();
    const [customerData, setcustomerData] = useState();
    const [successopen, setsuccessOpen] = useState(false);
    const [erroropen, seterrorOpen] = useState(false);
    const [lodervisible, setlodervisible] = useState(false);
    const toggleContextVal = useContext(ToggleContext)
    const [tablecols, setTableCols] = useState([
        {
            colName: "User’s Name",
            colMap: "name",
            linkName: true,
            cModel: false,
            cRoute: false,
            fDate: false,
            imprRoute: false,
            userTab: true,
        },
        {
            colName: "Email Address",
            colMap: "email",
            linkName: false,
            cModel: false,
            cRoute: false,
            fDate: false,
            imprRoute: false,
            userTab: false,
        },
        {
            colName: "Role",
            colMap: "role",
            linkName: false,
            cModel: false,
            cRoute: false,
            fDate: false,
            imprRoute: false,
            userTab: false,
        },
        {
            colName: "Type",
            colMap: "userType",
            linkName: false,
            cModel: false,
            cRoute: false,
            fDate: false,
            imprRoute: false,
            userTab: false,
        },
        {
            colName: "Verified",
            colMap: "verified",
            linkName: false,
            cModel: false,
            cRoute: false,
            fDate: false,
            imprRoute: false,
            userTab: false,
        },
        {
            colName: "Status",
            colMap: "status",
            linkName: false,
            cModel: false,
            cRoute: false,
            fDate: false,
            imprRoute: false,
            userTab: false,
        },
        {
            colName: "Created",
            colMap: "created",
            linkName: false,
            cModel: false,
            cRoute: false,
            fDate: true,
            imprRoute: false,
            userTab: false,
        },

    ]);

    const [tablecolspublisher, setTableColsPublisher] = useState([
        {
            colName: "biNu Id",
            colMap: "applicationId",
            linkName: false,
            cModel: false,
            cRoute: false,
            fDate: false,
            imprRoute: false,
            userTab: false,
        },
        {
            colName: "App Name",
            colMap: "name",
            linkName: false,
            cModel: false,
            cRoute: false,
            fDate: false,
            imprRoute: false,
            userTab: false,
        },
        {
            colName: "Product Type",
            colMap: "productIdproductType",
            linkName: false,
            cModel: false,
            cRoute: false,
            fDate: false,
            imprRoute: false,
            userTab: false,
        },
        {
            colName: "Free Domain",
            colMap: "reachSubDomain",
            linkName: false,
            cModel: false,
            cRoute: false,
            fDate: false,
            imprRoute: false,
            userTab: false,
        },
        {
            colName: "Google App Id",
            colMap: "googleAppId",
            linkName: false,
            cModel: false,
            cRoute: false,
            fDate: false,
            imprRoute: false,
            userTab: false,
        },
        {
            colName: "Type",
            colMap: "applicationType",
            linkName: false,
            cModel: false,
            cRoute: false,
            fDate: false,
            imprRoute: false,
            userTab: false,
        },
        {
            colName: "Created",
            colMap: "created",
            linkName: false,
            cModel: false,
            cRoute: false,
            fDate: true,
            imprRoute: false,
            userTab: false,
        },
        {
            colName: "Max Bytes / Day(MB)",
            colMap: "maxBytesPerDayAction",
            linkName: false,
            cModel: false,
            cRoute: false,
            fDate: false,
            imprRoute: false,
            userTab: false,
        },
        {
            colName: "Production Health Check Required",
            colMap: "appln",
            linkName: false,
            cModel: false,
            cRoute: false,
            fDate: false,
            imprRoute: false,
            userTab: false,
        },
        {
            colName: "Status",
            colMap: "status",
            linkName: false,
            cModel: false,
            cRoute: false,
            fDate: false,
            imprRoute: false,
            userTab: false,
        },
        {
            colName: "Revenue Type",
            colMap: "revenueType",
            linkName: false,
            cModel: false,
            cRoute: false,
            fDate: false,
            imprRoute: false,
            userTab: false,
        }
    ]);

    const [editcustomer, seteditcustomer] = useState({
        companyname: "",
        monthly_data: "",
        primary_cust_email: "",
        status: "",
        contract_term: "",
        company_id: ""
    });

    const [invite, setInvite] = useState({
        name: "",
        email_address: "",
        companyId: "",
    });
    const [inviteError, setInviteError] = useState({
        name: false,
        email_address: false,

        companyId: false,
        nameerrmsg: "",
        emailerrmsg: "",
        errResponse: "",
    });
    const [userdata, setUserdata] = useState({
        username: "",
        user_role: "User",
        user_type: "Internal",
        user_verified: "No",
        user_status: "Active",
        user_id: "",
        company_id: "",
    });
    const [userid, setUserId] = useState({
        userId: "",
    });

    const classes = useStyles();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setUserOpen(false);
        }
        setOpen(false);


        setInvite({
            name: "",
            email_address: "",
            companyId: "",
        });
        setErrorField({
            changed_channel_id: false,
        });
        setuserDetails({
            username: "",
            user_role: "User",
            user_type: "Internal",
            user_verified: "Yes",
            user_status: "Active",
            user_id: "",
            company_id: "",
        });
        setUserUpdate({
            username: false,
        });
        setInviteError({
            name: false,
            email_address: false,
            companyId: false,
            errmsg: "",
        });
       
        setEditInfo(false);
        setError({
            companyname: false,
            monthly_data: false,
            channel_cust_id: false,
            account_manager_email: false,
            primary_cust_email: false,
            pricing_discount: false,
            msg: "",
            dataMsg: "",
            channelIdMsg: "",
            emailMsg: "",
            pimayEmailMsg: "",
            discountPriceMsg: "",
        });
    };
    const [empty, setEmpty] = useState(false);
    const [message, setMessage] = useState();
    const [rset, setRst] = useState(false);


    const edit = () => {
        setEditInfo(true);
        localStorage.setItem("EditOpen", true);
    };
    const handlesuccessAlert = () => {
        setsuccessOpen(false);
    };
    const handleAlertClose = () => {
        seterrorOpen(false);

    };

    const [userUpdate, setUserUpdate] = useState({
        username: false,
        msg: "",
    });

    const closeResetPop = () => {
        setRst(false);
        setSucessPopup(false);
    }
    //passwordReset
    const resetPassword = (EMAIL) => {

        trackPromise(
            Service.resetPassword({ email: EMAIL, admin: "admin" }).then((res) => {
                if (res.status === 200) {

                    setUserOpen(false);
                    setRst(true);
                    setMessage(res.data.message);
                }
            }).catch((err) => {
                setResMessage("Something went wrong. Please reload the page!")
                seterrorOpen(true);
            })
        );

    }

    const [errorField, setErrorField] = useState({
        changed_channel_id: false,
        msg: "",
    });


    const redirectRoute = () => {
        history.push("/accountmanager/publisher/index");
    };

    const reg = /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/;

    const handleUser = (e) => {
        e.preventDefault();
        const invitedata = { ...invite };
        invitedata[e.target.id] = e.target.value;
        invitedata["companyId"] = companyId;
        if (e.target.id === "name") {
            setInviteError({
                ...inviteError,
                name: false,
            });
        }
        if (e.target.id === "email_address") {
            if (e.target.value) {
                EmailCheck(e.target.value);
            } else {
                setInviteError({
                    ...inviteError,
                    email_address: true,
                    emailerrmsg: "This field is required",
                });
            }
        }

        setInvite(invitedata);
    };

    ////Check Email
    const [resValue, setResValue] = useState();
    async function EmailCheck(value) {
        trackPromise(
            Service.EmailCheck({ email_address: value })
                .then((res) => {
                    setResValue(res.data);
                    if (res.data === false) {
                        setInviteError({
                            ...inviteError,
                            email_address: true,
                            emailerrmsg: "A user exists with this email. Contact Datafree Support if you wish this user to  be linked to this account",
                        });
                    } else if (value && reg.test(value) === false) {
                        setInviteError({
                            ...inviteError,
                            email_address: true,
                            emailerrmsg: "Please enter a valid email address.",
                        });
                    } else {
                        setInviteError({
                            ...inviteError,
                            email_address: false,
                            emailerrmsg: ""
                        });
                    }
                })
                .catch((error) => {
                    setResMessage("Something went wrong. Please reload the page!")
                    seterrorOpen(true);
                })
        );
    }
    const [sucess, setSucess] = useState();
    const [sucessPopup, setSucessPopup] = useState(false);
    const ValidateInvite = (invite) => {
        let count = 0;
        const nwerror = { ...inviteError }
        if (invite.email_address === "") {
            nwerror.email_address = true;
            nwerror.emailerrmsg = "This field is required."
            count++;
        }
        else {
            if (resValue === false) {
                nwerror.email_address = true;
                nwerror.emailerrmsg = "This​ Email address ​already​ exist"
                count++;
            }
            else if (reg.test(invite.email_address) === false) {
                nwerror.email_address = true;
                nwerror.emailerrmsg = "Please enter a valid email address."
                count++;
            }
            else {
                nwerror.email_address = false;
                nwerror.emailerrmsg = ""
            }
        }


        if (invite.name === "") {
            nwerror.name = true;
            count++;
        }
        else {
            nwerror.name = false;
        }
        setInviteError(nwerror);
        return count;
    }
    const [loadings, setLoadings] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clickInvite, setClickInvite] = useState(false);
    //invite New
    async function InviteNew() {
        const inviteCount = ValidateInvite(invite);
        if (inviteCount === 0) {
            setLoadings(true);
            setClickInvite(true);
            trackPromise(
                Service.inviteUsers(invite)
                    .then((res) => {

                        setClickInvite(false);
                        if (res.status === 200) {
                            setSucessPopup(true);
                            setSucess(res?.data?.message)
                            setEditInfo(false);
                            handleClose();
                            setOpen(false);
                            setLoadings(false);
                        } else {
                            seterrorOpen(true);
                            setEditInfo(true);
                        }
                        getPublisherList();
                    })
                    .catch((error) => {
                        setMessage("Something went wrong. Please reload the page.");
                        seterrorOpen(true);
                    })
            );
        }
    }
    const [resMessage, setResMessage] = useState();

    const EditUser = (e) => {
        e.preventDefault();

        const newdata = { ...userdata };
        newdata[e.target.id] = e.target.value;
        if (e.target.id === "username") {
            if (e.target.value) {
                setUserUpdate({
                    username: false,
                });
            } else {
                setUserUpdate({
                    username: true,
                    msg: "This field is required",
                });
            }
        }

        setUserdata(newdata);
    };

    //editUser Detail
    async function EditUserData() {

        if (userUpdate.username) {
            setUserUpdate({
                username: true,
            });
        } else {
            setLoading(true);
            trackPromise(
                Service.EditUserDetails(userdata)
                    .then((res) => {
                        setLoading(false);
                        if (res.status === 200) {
                            setToastMsg("User Details Updated Successfully!");
                            handleClose();
                            setEditInfo(false);
                            setUserOpen(false);
                            setsuccessOpen(true);
                        } else {
                            seterrorOpen(true);
                            setUserOpen(true);
                            setEditInfo(true); 
                        }
                        getPublisherList();
                    })
                    .catch((err) => {
                        setResMessage(err.response.data);
                        seterrorOpen(true);
                    })
            );
        }
    }

    //getUser
    async function getUserDetail(value,companyIds) {
        setuserDetails({});
        trackPromise(
            Service.getUserDetails({ userId: value, companyId: companyIds})
                .then((res) => {
                    setuserDetails(res.data);
                    setUserdata({
                        username: res.data.userName,
                        user_role: res.data.role,
                        user_type: res.data.type,
                        user_verified: res.data.verified && res.data.verified == 1 ? "Yes" : "No",
                        user_status: res.data.status,
                        user_id: res.data.userId,
                        company_id: res.data.companyId,
                    });
                })
                .catch((err) => {
                    setResMessage("Something went wrong. Please reload the page!")
                    seterrorOpen(true);
                })
        );
    }

    const [error, setError] = useState({
        companyname: false,
        monthly_data: false,
        channel_cust_id: false,
        account_manager_email: false,
        primary_cust_email: false,
        pricing_discount: false,
        msg: "",
        dataMsg: "",
        channelIdMsg: "",
        emailMsg: "",
        pimayEmailMsg: "",
        discountPriceMsg: "",
    });

    const regexMonthlyData =
        /(?!0+$)(^[0-9]+$)|(^[0-9]+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\.[0-9]{1,2})+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\,[0-9]{2,4})+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K))$/i;
    const emailreg = /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/;


    const handleCustomer = (e) => {
        e.preventDefault();
        const newdata = { ...editcustomer };
        newdata[e.target.id] = e.target.value;
        if (e.target.id === "companyname") {
            if (e.target.value) {
                companyCheck(e.target.value);
            } else {
                setError({
                    ...error,
                    companyname: true,
                    msg: "This field is required",
                });
            }
        }
        if (e.target.id === "monthly_data") {
            setError({
                ...error,
                monthly_data: true,
            });
            if (regexMonthlyData.test(e.target.value) === false && e.target.value !== "") {
                setError({
                    ...error,
                    monthly_data: true,
                    dataMsg: "An Alert Email will be sent to your company contacts if the Monthly Forecast Data Usage exceeds this",
                });
            } else {
                setError({
                    ...error,
                    monthly_data: false,
                });
            }
        }
        if (e.target.id === "primary_cust_email") {
            setError({
                ...error,
                channel_cust_id: true,
            });
            if (emailreg.test(e.target.value) === false && e.target.value !== "") {
                setError({
                    ...error,
                    channel_cust_id: true,
                    channelIdMsg: "Please enter a valid email address.",
                });
            } else {
                setError({
                    ...error,
                    channel_cust_id: false,
                });
            }
        }

        seteditcustomer(newdata);
    };

    const [toastMsg, setToastMsg] = useState();
    async function EditCustomerData() {
        editcustomer["company_id"] = companyId;
        if (error.companyname) {
            setError({
                ...error,
                companyname: true,
            });
        } else if (error.monthly_data) {
            setError({
                ...error,
                monthly_data: true,
            });
        } else if (error.channel_cust_id) {
            setError({
                ...error,
                channel_cust_id: true,
            });
        } else if (error.account_manager_email) {
            setError({
                ...error,
                account_manager_email: true,
            });
        } else if (error.primary_cust_email) {
            setError({
                ...error,
                primary_cust_email: true,
            });
        } else if (error.pricing_discount) {
            setError({
                ...error,
                pricing_discount: true,
            });
        } else {

            trackPromise(
                Service.editPublisherCompany(editcustomer)
                    .then((res) => {
                        if (res.status === 200) {
                            setToastMsg("Publisher Details Edited Successfully!")
                            setEditInfo(false);
                            handleClose();
                            setsuccessOpen(true);
                            localStorage.removeItem("EditOpen");
                        } else {
                            seterrorOpen(true);
                            setEditInfo(true);
                        }

                        getPublisherList();
                    })
                    .catch((error) => {
                        setResMessage("Something went wrong. Please reload the page!")
                        seterrorOpen(true);
                    })
            );
        }
    }

    const [addData, setAddData] = useState();
    async function getPublisherList() {
        setlodervisible(true);
        setcustomerData({});
        setEmpty(false);
        trackPromise(
            Service.publisherIdDetailView(companyId)
                .then((res) => {
                    setlodervisible(false);
                    setDiscount(res.data.publisherDetail.channelDiscountPrice);
                    setAddData(res.data);
                    setcustomerData(res.data);
                    seteditcustomer({
                        companyname: res.data.publisherDetail.name,
                        monthly_data: res.data.dataUsageAlert,
                        companyId: res.data.publisherDetail.companyId,
                        channel_cust_id: res.data.publisherDetail.channelCustId,
                        account_manager_email: res.data.publisherDetail.accMngrEmail,
                        primary_cust_email: res.data.publisherDetail.primaryCustEmail,
                        pricing_discount: res.data.publisherDetail.channelDiscountPrice,
                        data_credit_limit: res.data.publisherDetail?.accDataCreditLimitId?.creditLimit ? res.data.publisherDetail.accDataCreditLimitId.creditLimit : "",
                        status: res.data.publisherDetail.status,
                        contract_term: res.data.publisherDetail.contractTerm,
                        company_id: companyId,
                    });

                    if ((res.status === 200 && res.data.usersListDetail.length === 0) || (res.status === 200 && res.data.applicationsListDetail.length === 0)) {
                        setEmpty(true);
                    } else {
                        setEmpty(false);
                    }
                })
                .catch((error) => {
                    setResMessage("Something went wrong. Please reload the page!")
                    seterrorOpen(true);
                })
        );
    }

    //CompanyCheck
    async function companyCheck(cname) {
        try {
            trackPromise(
                Service.NameCheck({ companyname: cname, company_id: companyId }).then((res) => {
                    if (res.data === false) {
                        setError({
                            ...error,
                            company_name: true,
                            msg: "This​ Company ​name ​already​ exist",
                        });
                    } else if (cname.includes(",")) {
                        setError({
                            ...error,
                            company_name: true,
                            msg: "Please enter a name without comma",
                        });
                    } else {
                        setError({
                            ...error,
                            company_name: false,
                        });
                    }
                })
            );
        } catch (err) {
            setResMessage("Something went wrong. Please reload the page!")
            seterrorOpen(true);
        }
    }

    const OpenUserTab = (scrollType, Id, companyId) => {
        getUserDetail(Id,companyId);

        setUserOpen(true);
        setScroll(scrollType);
    };

    const [emailId, setEmailId] = useState({
        email: "",
    });
    const editStatus = (e, id) => {
        e.preventDefault();
        try {
            trackPromise(
                ChannelService.ChangeStatus({ applicationId: id, status: e.target.value }).then((res) => {

                    getPublisherList();
                })
            );
        } catch (err) {
            setResMessage("Something went wrong. Please reload the page!")
            seterrorOpen(true);
        }
    }

    const RevenueChange = (e,ids) =>{
        try {
          trackPromise(
            Services.revenueChange({applicationId:ids, rtId: e.target.value}).then((res) => {
                getPublisherList();
            })
          );
        } catch (err) {
            setResMessage("Something went wrong. Please reload the page!")
            seterrorOpen(true);
        }
      }

    const ChangeHealth = (e, healthon, id) => {
        let health;
        e.target.checked = !healthon
        if (e.target.checked === false) {
            health = "off";
        }
        else {
            health = "on";
        }
        try {
            trackPromise(
                ChannelService.ChangeHealth({ deploymentId: id, health: health }).then((res) => {
                    getPublisherList();
                })
            );
        } catch (err) {
            setResMessage("Something went wrong. Please reload the page!")
            seterrorOpen(true);
        }
    }

    const [openPopup, setOpenPopup] = useState(false);
    document.addEventListener("click", e => {
        let editNot = localStorage.getItem("EditOpen");
        if (editNot) {
            if (e.target.id === "Edit" || e.target.id === "Cancel" || e.target.id === "Save" ||
                e.target.name === "company_name" || e.target.name === "popup" || e.target.id === "status" || e.target.id === "Remove") {
                if (e.target.id === "Cancel" || e.target.id === "Save") {
                    localStorage.removeItem("EditOpen")
                }
                setOpenPopup(false);
            }
            else {
                setOpenPopup(true);
                e.stopImmediatePropagation();
                e.stopPropagation();
                e.preventDefault();
            }
        }
        else {
            setOpenPopup(false);
        }
    }, true);

    useEffect(() => {
        setlodervisible(true);
        document.title = "Datafree Portal";
        getPublisherList();
        localStorage.removeItem("EditOpen");
    }, []);

    return (
        <div className="accountContent" name="popup">
          <div className={toggleContextVal.event ?"accountMenuView" :"accountView"}>
                <div className="accounthead">
                    <div className="accountNavtext">Publisher Details - Account Manager View</div>
                </div>
                <AntTabs
                    value={value}
                    className="tabViewMenu"
                    onChange={handleChange}
                    aria-label="ant example"
                    TabIndicatorProps={{
                        style: {
                            display: "none",
                        },
                    }}
                >
                    <AntTab label="Company" title="Click here to view Company Tab" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
                    <AntTab label="Users" title="Click here to view Users Tab" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
                    <AntTab label="Applications" title="Click here to view Applications Tab" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
                </AntTabs>
                <Typography className={classes.padding} />

                <TabPanel value={value} index={0}>
                    {
                        lodervisible &&
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            top: '0px',
                            right: '0px',
                            margin: 'auto',
                            position: 'absolute',
                            left: '0px',
                            bottom: '0px',
                            zIndex: '3000',
                            backgroundColor: "#ffffff",
                            //boxShadow: '0px 0px 5px -1px rgba(0,0,0,0.75)',
                            paddingLeft: '2px',
                            paddingRight: '2px',
                            paddingTop: '8px',
                            paddingBottom: '8px',
                            borderRadius: '5px',
                            flexDirection: 'column',
                            width: '60px',
                            height: '60px'
                        }} >
                            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />

                        </div>
                    }
                    {customerData?.publisherDetail ? <div className="companyForm" name="popup">
                        <div className="InputflexRow">
                            <div className="customerListflex">
                                <div className="companyLabel">Publisher Name</div>

                                <div>
                                    {!editInfo ? (
                                        <input type="text" className="form-control" disabled="disabled" style={{ cursor: "not-allowed" }} value={customerData?.publisherDetail?.name} />
                                    ) : (
                                        <input
                                            type="text"
                                            className={error.company_name ? "form-control errorField" : "form-control"}
                                            name="popup"
                                            id="companyname"
                                            defaultValue={customerData?.publisherDetail?.name}
                                            onBlur={(e) => handleCustomer(e)}
                                            onChange={(e) => handleCustomer(e)}
                                        />
                                    )}
                                </div>
                                {error.company_name ? <a className="errorClass">{error.msg ? error.msg : "This field is required"}</a> : null}
                            </div>
                            <div className="customerddflex">
                                <div className="companyLabel">Your Customer Identifier</div>
                                <div>
                                    <input type="text" className="form-control" name="popup" disabled="disabled"
                                        style={{ cursor: "not-allowed" }} value={customerData?.publisherDetail?.channelCustId} />
                                </div>
                            </div>
                            <div className="ivitecustdiv">

                                <button className="inviteButton" onClick={edit} title="Click here to edit publisher details" name="popup">
                                    Edit Details
                                </button>

                            </div>
                        </div>
                        {editInfo ? (
                            <div className="InputflexRow">
                                <div className="customerListflex"></div>
                            </div>
                        ) : null}
                        <div className="InputflexRow">
                            <div className="customerddflex">
                                <div className="companyLabel">Monthly Account #datafree Usage Alert (bytes)</div>
                                <div>
                                    {!editInfo ? (
                                        <input type="text" className="form-control" disabled="disabled" style={{ cursor: "not-allowed" }} value={customerData?.dataUsageAlert} />
                                    ) : (
                                        <input
                                            type="text"
                                            className={error.monthly_data ? "form-control errorField" : "form-control"}
                                            name="popup"
                                            id="monthly_data"
                                            defaultValue={customerData?.dataUsageAlert}
                                            onChange={(e) => handleCustomer(e)}
                                        />
                                    )}
                                </div>
                                {error.monthly_data ? <a className="errorClass">{error.dataMsg ? error.dataMsg : ""}</a> : null}
                            </div>
                            {/* <div style={{ width: "8%" }}></div> */}
                            <div className="customerddflex">
                                <div className="companyLabel">Usage Alert Email</div>
                                <div>
                                    {!editInfo ? (
                                        <input type="text" className="form-control"
                                            disabled="disabled" style={{ cursor: "not-allowed" }} value={customerData?.publisherDetail?.primaryCustEmail} />
                                    ) : (
                                        <input
                                            type="text"
                                            className={error.channel_cust_id ? "form-control errorField" : "form-control"}
                                            name="popup"
                                            id="primary_cust_email"
                                            defaultValue={customerData?.publisherDetail?.channelCustId}
                                            onChange={(e) => handleCustomer(e)}
                                        />
                                    )}
                                </div>
                                {error.channel_cust_id ? <a className="errorClass">{error.channelIdMsg ? error.channelIdMsg : ""}</a> : null}
                            </div>
                            <div className="ivitecustdiv"></div>
                        </div>
                        <div className="InputflexRow">
                            <div className="customerddflex">
                                <div className="companyLabel">Account Manager Email</div>
                                <div>

                                    <input type="text" className="form-control" disabled="disabled" style={{ cursor: "not-allowed" }} value={customerData?.publisherDetail?.accMngrEmail} />

                                </div>

                            </div>
                            {/* <div style={{ width: "8%" }}></div> */}
                            <div className="customerddflex">


                            </div>

                            <div className="ivitecustdiv"></div>
                        </div>
                        <div className="InputflexRow">
                            <div className="customerddflex">
                                <div className="companyLabel">Pricing Discount %</div>
                                <div>

                                    <input type="text" className="form-control" disabled="disabled" style={{ cursor: "not-allowed" }} value={customerData?.publisherDetail?.channelDiscountPrice} />

                                </div>

                            </div>
                            {/* <div style={{ width: "8%" }}></div> */}
                            <div className="customerddflex">
                                <div className="companyLabel">Monthly #datafree Data Credit Limit (bytes)</div>
                                <div>

                                    <input type="text" className="form-control" disabled="disabled" style={{ cursor: "not-allowed" }} value={customerData?.publisherDetail?.accDataCreditLimitId?.creditLimit} />

                                </div>
                            </div>
                            <div className="ivitecustdiv"></div>
                        </div>
                        <div className="InputflexRow">
                            <div className="customerddflex">
                                <div className="companyLabel">Status</div>
                                <div>

                                    {!editInfo ? (
                                        <input type="text" className="form-control" name="status" id="status" disabled="disabled" style={{ cursor: "not-allowed" }} value={customerData?.publisherDetail?.status} />
                                    ) : (
                                        <select type="text" className="form-control form-select" name="popup" id="status" onChange={(e) => handleCustomer(e)}>

                                            {customerData?.publisherDetail?.status === "Approved" ? (<>
                                                <option value="Approved" selected>Approved</option>
                                                <option value="Suspended">Suspended</option>
                                                <option value="Archived">Archived</option>

                                            </>) :

                                                customerData?.publisherDetail?.status === "New" ? (<>
                                                    <option value="New" selected>New</option>
                                                    <option value="Approved">Approved</option>
                                                    <option value="NoAgreement">NoAgreement</option>

                                                </>) :
                                                    customerData?.publisherDetail?.status === "NoAgreement" ? (<>
                                                        <option value="NoAgreement" selected>NoAgreement</option>
                                                        <option value="Approved">Approved</option>
                                                        <option value="Archived">Archived</option>

                                                    </>) :
                                                        customerData?.publisherDetail?.status === "Suspended" ? (<>
                                                            <option value="Suspended" selected>Suspended</option>
                                                            <option value="Approved">Approved</option>
                                                            <option value="Archived">Archived</option>

                                                        </>) :
                                                            customerData?.publisherDetail?.status === "Archived" ? (<>
                                                                <option value="Archived" selected>Archived</option>

                                                            </>) :
                                                                null}
                                        </select>
                                    )}
                                </div>
                            </div>
                            {/* <div style={{ width: "8%" }}></div> */}
                            <div className="customerddflex">
                                <div className="companyLabel">Contract Term</div>
                                <div>
                                    {!editInfo ? (
                                        <input type="text" id="contract_term" className="form-control" disabled="disabled" style={{ cursor: "not-allowed" }} value={customerData?.publisherDetail?.contractTerm} />
                                    ) : (
                                        <select type="text" className="form-control form-select" name="popup" id="contract_term" onChange={(e) => handleCustomer(e)}>
                                            <option value="Ad Hoc" selected={customerData?.publisherDetail?.contractTerm === "Ad Hoc" && true}>
                                                Ad Hoc
                                            </option>
                                            <option value="12 Months" selected={customerData?.publisherDetail?.contractTerm === "12 Months" && true}>12 Months</option>
                                            <option value="24 Months" selected={customerData?.publisherDetail?.contractTerm === "24 Months" && true}>24 Months</option>
                                            <option value="36 Months" selected={customerData?.publisherDetail?.contractTerm === "36 Months" && true}>36 Months</option>
                                            <option value="Special" selected={customerData?.publisherDetail?.contractTerm === "Special" && true}>Special</option>
                                        </select>
                                    )}
                                </div>
                            </div>
                            <div className="ivitecustdiv"></div>
                        </div>

                        <div className="InputflexRow">
                            <div className="customerddflex">
                                <div className="companyLabel">Company Type</div>
                                <div>
                                    <input type="text" className="form-control" name="popup" disabled="disabled" value={customerData?.publisherDetail?.companyType} />
                                </div>
                            </div>
                            {/* <div style={{ width: "8%" }}></div> */}
                            <div className="customerddflex">
                                <div className="companyLabel">Created</div>
                                <div>
                                    <input type="text" className="form-control" name="popup" disabled="disabled" style={{ cursor: "not-allowed" }} value={moment(customerData?.publisherDetail?.created).format("YYYY-MM-DD ") + " GMT"} />
                                </div>
                            </div>
                            <div className="ivitecustdiv"></div>
                        </div>
                    </div> : null}

                    <div className="ivitecustdiv">
                        {editInfo ? (
                            <button className="inviteButton" onClick={handleClose} color="primary" id="Cancel">
                                Cancel
                            </button>
                        ) : null}
                        {editInfo ? (
                            <button className="inviteButton" color="primary" onClick={EditCustomerData} id="Save">
                                Save
                            </button>
                        ) : null}
                    </div>
                    <div className="ivitecustdiv">
                        <button className="inviteButton" onClick={redirectRoute} id="Return">
                            Return to Publisher List
                        </button>
                    </div>
                </TabPanel>

                <TabPanel value={value} index={1}>
                    <div className="ivitediv">
                        <button className="inviteButton" onClick={handleClickOpen}>
                            + Invite New User
                        </button>
                    </div>
                    {customerData?.usersListDetail?.length > 0 ? (
                        <VMCTable
                            key={customerData.usersListDetail}
                            data={customerData.usersListDetail}
                            col={tablecols}
                            serviceNav={OpenUserTab}
                            tabNavigation={OpenUserTab}
                            tableName="Publisher User Details View"
                        />
                    ) : (
                        <div>
                            <LoadingIndicator />
                            {empty ? <EmptyTable data="No Users Found" col={tablecols} tableName="Publisher User Details View" /> : null}
                        </div>
                    )}

                    <div className="ivitediv">
                        <button className="inviteButton" onClick={redirectRoute}>
                            Return To Publisher List
                        </button>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {customerData?.applicationsListDetail?.length > 0 ? (
                        <VMCTable key={customerData.applicationsListDetail} data={customerData.applicationsListDetail}
                            additionalData1={addData} col={tablecolspublisher}
                            //navigateFunction={editStatus}
                            changeRevType={RevenueChange}
                            CheckFunction={ChangeHealth}
                            tableName="Publisher Application Details View" />
                    ) : (
                        <div>
                            <LoadingIndicator />
                            {empty ? <EmptyTable data="No Applications Found" col={tablecolspublisher} tableName="Publisher Application Details View" /> : null}
                        </div>
                    )}
                    <div className="ivitediv">
                        <button className="inviteButton" onClick={redirectRoute}>
                            Return To Publisher List
                        </button>
                    </div>
                </TabPanel>
            </div>

            {/* ///////////////////////////////////////////// INVITE USER ///////////////////////////////// */}
            <Dialog open={open} classes={{ scrollPaper: classes.scrollPaper }}
                scroll={scroll}
                fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" className="dialogtitle">
                    <div className="FlexRow">
                        <div className="cmpdialogtitle"> Invite User - Admin View</div>
                        <div className="cmpdialogtitle">
                            <IoMdCloseCircleOutline style={{ cursor: "pointer" }}
                                className="modalClose"
                                onClick={handleClose} />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="dialoginputtext">Name:</div>
                    <input type="text" id="name" label="name" className={inviteError.name ? "form-control errorField" : "form-control"} fullWidth onChange={(e) => handleUser(e)} />
                    {inviteError.name ? <a className="errorClass">{inviteError.nameerrmsg ? inviteError.nameerrmsg : "This field is required"}</a> : null}
                    <div className="dialoginputtext">Email:</div>
                    <input
                        type="text"
                        id="email_address"
                        name="email_address"
                        label="email"
                        className={inviteError.email_address ? "form-control errorField" : "form-control"}
                        onChange={(e) => handleUser(e)}
                        fullWidth
                    />
                    {inviteError.email_address ? <a className="errorClass">{inviteError.emailerrmsg ? inviteError.emailerrmsg : "This field is required"}</a> : null}
                </DialogContent>
                <DialogActions>
                    {loadings ? <div className="loaderContainer">
                        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
                    <div className="ivitediv">
                        <button className="inviteButton" onClick={handleClose} color="primary">
                            Cancel
                        </button>
                        <button className="inviteButton" color="primary"
                            disabled={clickInvite ? "disabled" : false}
                            onClick={InviteNew}>
                            Invite User
                        </button>
                    </div>
                </DialogActions>
            </Dialog>
            <Snackbar open={successopen} autoHideDuration={2500} onClose={handlesuccessAlert}>
                <Alert onClose={handlesuccessAlert} severity="success">
                    {toastMsg ? toastMsg : "Updated Successfully!"}
                </Alert>
            </Snackbar>

            <Snackbar open={erroropen} autoHideDuration={2500} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity="error">
                    {resMessage}
                </Alert>
            </Snackbar>
            {/* //user */}

            {/* /////////////////////////////////////// UPDATE USERDETAILS ////////////////////////// */}
            <Dialog open={userOpen} classes={{ scrollPaper: classes.scrollPaper }} fullWidth scroll={scroll} maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <div className="FlexRow">
                        <div className="cmpdialogtitle">User Details - Account Manager View</div>
                        <div className="cmpdialogtitle">
                            <IoMdCloseCircleOutline style={{ cursor: "pointer" }}
                                className="modalClose"
                                onClick={handleClose} />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Grid container>

                        <Grid item xs={12}>
                            <div className="cmpdialoginputtext">User’s Name</div>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                defaultValue={userDetails ? userDetails.userName : ""}
                                onInput={(e) => EditUser(e)}
                                //onChange={(e) => EditUser(e)}
                                className={userUpdate.username ? "form-control errorField" : "form-control"}
                                fullWidth
                            />
                            {userUpdate.username ? <a className="errorClass">{userUpdate.msg ? userUpdate.msg : "This field is required"}</a> : null}
                        </Grid>
                        <Grid item xs={12}>
                            <div className="cmpdialoginputtext">Email Address</div>
                            <input
                                type="text"
                                id="reg_address"
                                name="reg_address"
                                label="email"
                                defaultValue={userDetails ? userDetails.email : ""}
                                disabled="disabled"
                                onChange={(e) => EditUser(e)}
                                className="form0control"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className="cmpdialoginputtext">Role</div>
                            <select
                                type="text"
                                id="user_role"
                                name="user_role"
                                label="email"
                                className="form-control form-select"
                                fullWidth
                                onChange={(e) => EditUser(e)}
                            >
                                {userDetails?.role == "user" ?
                                    <option value="user" selected={userDetails?.role == "user" && true}>user</option>
                                    :
                                    <option value="user" selected={userDetails?.role == "reseller" && true}>reseller</option>
                                }
                            </select>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="cmpdialoginputtext">Type</div>
                            <select id="user_type" name="user_type" className="form-control form-select" fullWidth onChange={(e) => EditUser(e)}>
                                <option selected={userDetails?.type == "INTERNAL" && true} value="INTERNAL">
                                    INTERNAL
                                </option>
                                <option selected={userDetails?.type == "PUBLISHER" && true} value="PUBLISHER">
                                    PUBLISHER
                                </option>
                            </select>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="cmpdialoginputtext">Verified</div>
                            <select type="text" id="user_verified" name="user_verified" label="email" className="form-control form-select" fullWidth onChange={(e) => EditUser(e)}>
                                <option selected={userDetails?.verified == 1 && true} value="1">
                                    YES
                                </option>
                                <option selected={userDetails?.verified == 0 && true} value="0">
                                    NO
                                </option>
                            </select>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="cmpdialoginputtext">Status</div>
                            <select type="text" id="user_status" name="user_status" label="email" className="form-control form-select" fullWidth onChange={(e) => EditUser(e)}>
                                <option selected={userDetails?.status == "Active" && true} value="Active">
                                    Active
                                </option>
                                <option selected={userDetails?.status == "Deleted" && true} value="Deleted">
                                    Deleted
                                </option>
                            </select>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="cmpdialoginputtext">Created</div>
                            <input type="text" label="email" disabled="disabled" className="form-control" value={moment(userDetails ? userDetails.created : "").format("YYYY-MM-DD HH:mm") + " GMT"} fullWidth />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                {loading ? <div className="loaderContainer">
                        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
                    <div className="buttonRow">
                        <div>
                            <button className="inviteButton" onClick={() => resetPassword(userDetails.email)} color="primary">
                                Request Password Reset
                            </button>
                        </div>
                        <div>
                            <button className="inviteButton" onClick={handleClose} color="primary">
                                Cancel
                            </button>

                            <button className="inviteButton" color="primary" onClick={EditUserData}>
                                Update User Details
                            </button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>



            <Dialog open={openPopup} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }} aria-labelledby="form-dialog-title">

                <DialogContent className="diaContent">
                    <div className="logoConfirmtext">Please Save or Cancel the changes before proceeding</div>
                </DialogContent>
                <DialogActions>
                    <div className="popupbutton">
                        <button className="popupOk" name="popup" color="primary">
                            OK
                        </button>
                    </div>
                </DialogActions>
            </Dialog>
            {/* Password Reset */}
            <Dialog open={rset} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <div className="FlexRow">
                        <div className="cmpdialogtitle">Request Password Reset</div>

                    </div>
                </DialogTitle>
                <DialogContent className="diaContents">
                    <div >{message ? message : null}</div>
                </DialogContent>
                <DialogActions>
                    <div className="popupbutton">
                        <button className="popupOk" color="primary" onClick={closeResetPop}>
                            OK
                        </button>
                    </div>
                </DialogActions>
            </Dialog>
            {/* SUCCESS MESSAGE*/}
            <Dialog open={sucessPopup} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <div className="FlexRow">
                        <div className="cmpdialogtitle">Success Message</div>

                    </div>
                </DialogTitle>
                <DialogContent className="diaContents">
                    <div >{sucess ? sucess : null}</div>
                </DialogContent>
                <DialogActions>
                    <div className="popupbutton">
                        <button className="popupOk" color="primary" onClick={closeResetPop}>
                            OK
                        </button>
                    </div>
                </DialogActions>
            </Dialog>


        </div>
    );
}
export default PublisherDetailView;
