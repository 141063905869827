import React from "react";
import "./App.css";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import Login from "./components/login";
import User from "./components/user";
import Admin from "./components/admin";
import Licensee from "./components/licensee";
import Channel from "./components/channel";
import AccountManager from "./components/accountManager";
import VerifyUser from "./components/login/verifiedUser";
import VerifyPass from "./components/login/verifyPass";
function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/verify/new/user" component={VerifyUser} />
        <Route exact path="/verification/code/" component={VerifyPass} />
        <Route path="/user" component={User} />
        <Route path="/admin" component={Admin} />
        <Route path="/licensee" component={Licensee} />
        <Route path="/channel" component={Channel} />
        <Route path="/accountmanager" component={AccountManager} />
        <Route path="/new/password" component={Login} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
