import React, { useState, useEffect,useContext } from "react";
import "./adminStyle.css";
import applicationIcon from "../../../assets/img/application-icon.png";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";
import { usePromiseTracker } from "react-promise-tracker";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid } from "@material-ui/core";
import AdminService from "../../../api/adminService";
import DataTable from "react-data-table-component";
import { FaCaretDown } from "react-icons/fa";
import EmptyTable from "../../../Recomponent/EmptyTable";
import {
  ToggleContext
} from "../../context/toggleContext"
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
      <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};
const customStyles = {
  headCells: {
    style: {
      border:"1px solid #e7ecf1",
      borderBottom: "1px solid #111"
    },
  },
  cells: {
    style: {
      border:"1px solid #e7ecf1"
    },
  },
};

function ViewBinuIp() {
  const [search, setSearch] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [searchDetails, setSearchDetails] = useState();
  const [changedropdown, setChangedropdown] = useState(false);
  const [successopen, setsuccessOpen] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const [erroropen, seterrorOpen] = useState(false);
  const toggleContextVal = useContext(ToggleContext)
  const [data, setData] = useState({
    ipaddress: "",
    cloud: "",
    account: "",
    region: "",
    zerorated: "",
    service: "",
    name: "",
    client: "",
    status: "",
  });

  const [tablecol, setTableCol] = useState([
    {
      colName: "IpAddress",
      selector: "ipaddress",
      sortable: true,
      
    },
    {
      colName: "IpId",
      selector: "ipId",
      sortable: true,
    },
    {
      colName: "Cloud",
      selector: "cloud",
      sortable: true,
      
    },
    {
      colName: "Account",
      selector: "account",
      sortable: true,
     
    },
    {
      colName: "Region",
      selector: "region",
      sortable: true,
     
    },
    {
      colName: "FirstSeen",
      selector: "firstseen",
      sortable: true,
      
    },
    {
      colName: "ZeroRated",
      selector: "zerorated",
      sortable: true,
     
    },
    {
      colName: "Service",
      selector: "service",
      sortable: true,
     
    },
    {
      colName: "Appliance",
      selector: "appliance",
      sortable: true,
     
    },
    {
      colName: "Name",
      selector: "name",
      sortable: true,
     
    },
    {
      colName: "Client",
      selector: "client",
      sortable: true,
     
    },
    {
      colName: "Status",
      selector: "status",
      sortable: true,
     
    },
  ]); //no application found

  const [tablecols, setTableCols] = useState([
    {
      name: "IpAddress",
      selector: "ipaddress",
      sortable: true,
      
    },
    {
      name: "IpId",
      selector: "ipId",
      sortable: true,
    },
    {
      name: "Cloud",
      selector: "cloud",
      sortable: true,
      
    },
    {
      name: "Account",
      selector: "account",
      sortable: true,
     
    },
    {
      name: "Region",
      selector: "region",
      sortable: true,
     
    },
    {
      name: "FirstSeen",
      selector: "firstseen",
      sortable: true,
      
    },
    {
      name: "ZeroRated",
      selector: "zerorated",
      sortable: true,
     
    },
    {
      name: "Service",
      selector: "service",
      sortable: true,
     
    },
    {
      name: "Appliance",
      selector: "appliance",
      sortable: true,
     
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
     
    },
    {
      name: "Client",
      selector: "client",
      sortable: true,
     
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
     
    },
  ]);

  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };

  const lifecycleChange = (e) => {
    e.preventDefault();
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  };

  //search
  async function dataFreeListListSearch() {
    setSearch(true);
    setChangedropdown(true);
    setSearchDetails({});
    setEmpty(false);
    try {
      trackPromise(
        AdminService.ViewIpDeployment(data).then((res) => {
          setSearchDetails(res.data.applicationListResults);
          if (res.status === 200 && res.data.applicationListResults.length > 0) {         
            setChangedropdown(false);
          } else if (res.status === 200 && res.data.applicationListResults.length === 0) {
            setEmpty(true);
            setChangedropdown(false);
          } else {
            setChangedropdown(true);
          }
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page");
      seterrorOpen(true);
    }
  }

  return (
    <div  className={toggleContextVal.event ?"mainMobView" :"mainBoxView"}>
      <div className="navBoxDiv">
        <div className="NavBoxRow">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo" style={{marginTop:'5px'}}></img>
          </div>
          <div className="NavBoxRowText">View biNu IP Deployments</div>
        </div>
        <div className="navBoxRowFilter" id="binuDeploymentIpsSearch">
          <Grid container spacing={2}>
            <Grid item sm={2} md={2} xs={2}>
              <div className="label_Direct">IpAddress</div>
              <div>
                <input type="text" className="form-control" id="ipaddress" name="ipaddress" onChange={(e) => lifecycleChange(e)}></input>
              </div>
            </Grid>

            <Grid item sm={2} md={2} xs={2}>
              <div className="label_Direct">Cloud</div>
              <div>
                <input type="text" className="form-control" id="cloud" name="cloud" onChange={(e) => lifecycleChange(e)}></input>
              </div>
            </Grid>
            <Grid item sm={2} md={2} xs={2}>
              <div className="label_Direct">Account</div>
              <div>
                <input type="text" className="form-control" id="account" name="account" onChange={(e) => lifecycleChange(e)}></input>
              </div>
            </Grid>
            <Grid item sm={2} md={2} xs={2}>
              <div className="label_Direct">Region</div>
              <div>
                <input type="text" className="form-control" id="region" name="region" onChange={(e) => lifecycleChange(e)}></input>
              </div>
            </Grid>
            <Grid item sm={2} md={2} xs={2}>
              <div className="label_Direct">ZeroRated</div>
              <div>
                <input type="text" className="form-control" id="zerorated" name="zerorated" onChange={(e) => lifecycleChange(e)}></input>
              </div>
            </Grid>
            <Grid item sm={2} md={2} xs={2}>
              <div className="label_Direct">Service</div>
              <div>
                <input type="text" className="form-control" id="service" name="service" onChange={(e) => lifecycleChange(e)}></input>
              </div>
            </Grid>
            <Grid item sm={2} md={2} xs={2}>
              <div className="label_Direct">Name</div>
              <div>
                <input type="text" className="form-control" id="name" name="name" onChange={(e) => lifecycleChange(e)}></input>
              </div>
            </Grid>
            <Grid item sm={2} md={2} xs={2}>
              <div className="label_Direct">Client</div>
              <div>
                <input type="text" className="form-control" id="client" name="client" onChange={(e) => lifecycleChange(e)}></input>
              </div>
            </Grid>
            <Grid item sm={2} md={2} xs={2}>
              <div className="label_Direct">Status</div>
              <div>
                <input type="text" className="form-control" id="status" name="status" onChange={(e) => lifecycleChange(e)}></input>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="ivitediv">
                <button className={changedropdown ? "defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"} onClick={dataFreeListListSearch}>
                  Search
                </button>
              </div>
            </Grid>
           
          </Grid>
         
        </div>
        <div><hr className="applications-list-hr"></hr></div>
          {search ? (
          <div>
            {searchDetails && searchDetails.length > 0 ? (
              <DataTable columns={tablecols} data={searchDetails}  customStyles={customStyles}  
              defaultSortField="title" sortIcon={<FaCaretDown />}  />
          
            ) : (
              <div>
                <LoadingIndicator />
              {empty ? <EmptyTable data="No Applications Found" 
                col={tablecol} customStyles={customStyles} tableName="View Binu Ip deployment" /> : null} 
              </div>
            )}
          </div>
        ) : null}
      </div>

      <Snackbar open={successopen} autoHideDuration={2500} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
          "Updated Successfully!
        </Alert>
      </Snackbar>

      <Snackbar open={erroropen} autoHideDuration={2500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {errMsg ? errMsg : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default ViewBinuIp;
