import React, { useState } from 'react';
import "./adminStyle.css";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

function ConnectCreateFourthStep({NextFormStep, handlePreviousOpen, error, data, handleChange,addRow,deleteRow,connectError}) {
    let history = useHistory(); 
    const newStyle = {
        "&:hover": {
        color: '#333',
        backgroundColor: '#d4d4d4',
        borderColor: '#8c8c8c'
        }
    }
   
    return (
        <div className="directapplication">
            <Grid container spacing={1}>
            <Grid item xs={12}>
                <div className="gridHead">Step 4 of 6 - CONNECT Supported Applications</div>
            </Grid>

            <Grid item xs={12}>
                <div className="labelDirect auth">Included Applications</div>
            </Grid>

            <Grid item xs={6}>
            <div className="labelDirect auth">Application Label in VPN Launcher</div>
            </Grid>
            <Grid item xs={6}>
                <div className="labelDirect auth">Google Playstore App Identifier</div>
            </Grid>


            <Grid item xs={10}>
            <div className="datafeeSubBox" style={{height:'80px'}} >
            {data?.values?.map((val, idx) => {
              let application_label_vpn = `application_label_vpn-${idx}`,playstore_app_identifier=`playstore_app_identifier-${idx}`;
            
                return (
                  <div className="boxRow" style={{marginBottom:'10px'}} key={val.index}>
                    <Grid item xs={4}>
                        <input type="text" 
                        className={
                          !connectError[idx]?.application_label_vpn? "directFieldSelect" : "directFieldSelecterr"
                        }
                        onChange={handleChange}
                        name="application_label_vpn"
                        id={application_label_vpn}
                        data-id ={idx}
                        defaultValue={data?.values[idx]?.application_label_vpn}/>
                  {connectError[idx]?.application_label_vpn? <span style={{float:'left'}} className="errormsg" >Please fill both fields</span> : null}
                        
                   </Grid>
                   <Grid item xs={2}/>
                   <Grid item xs={4}>
                        <input type="text" 
                        className={
                          !connectError[idx]?.playstore_app_identifier? "directFieldSelect" : "directFieldSelecterr"
                        }
                        onChange={handleChange} 
                        name="playstore_app_identifier"
                        id={playstore_app_identifier}
                        defaultValue={data?.values[idx]?.playstore_app_identifier}
                        data-id={idx}
                        />
                  {connectError[idx]?.playstore_app_identifier? <span style={{float:'left'}} className="errormsg" name="identifiererror">{connectError[idx]?.identifiermsg}</span> : null}
                   
                   </Grid>
                    <div className="flex1">
                      <button className="closeIcon" 
                      //style={{borderRadius:'50%'}}
                      data-id={idx}
                      disabled={idx === 0 ? "disabled" : false} onClick={()=>deleteRow(val,idx)}>
                        X
                      </button>
                    </div>
                  </div>
                   );
                })}
               
            </div>
            <div style={{ textAlign: "left" }}>
                    <button className="defultbtn" style={newStyle} onClick={addRow}>
                    Add App
                    </button>
            </div>
          </Grid>





            <Grid item xs={6}>
             <div className="ivitediv">
              <button className="datafreeConnectBtnDanger" color="primary" 
              onClick={()=>history.push("/admin/datafreeconnect/application/index")}>
                Cancel
              </button>
            </div> 
             </Grid>
             <Grid item xs={6}>
                {" "}
                <div className="ivitediv">
                <button className="datafreeConnectButton" color="primary" onClick={()=>handlePreviousOpen(4)}>
                    Previous
                </button>
                {" "}
                <button className="datafreeConnectButton" color="primary" style={{marginLeft:'10px'}}
                onClick={()=>NextFormStep(4)}
                >
                    Next
                </button>
                </div>
            </Grid>  
            </Grid>
        </div>
    )
}

export default ConnectCreateFourthStep
