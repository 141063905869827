import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import moment from "moment";
import { FaTimes } from "react-icons/fa";


const VMCTable = (props) => {
  const [cols, setCols] = useState([]);
  const [datas, setDatas] = useState([]);
  useEffect(() => {
    setCols(props.col);
    setDatas(props.data);
  
    if (props.tableName === "Admin data free direct application") {
      props.additionalData1?.applicationListResult.map((item, index) => {
        props.additionalData1?.appDevDeployment.map((subitem, index) => {
          if (item?.applicationId === subitem?.applicationId?.applicationId) {
            item["deploymentType1"] = subitem?.deploymentType;
            item["biNuAppId"] = subitem?.appId;
          }
        });
        props.additionalData1?.appProdDeployment.map((subitem, index) => {
          if (item?.applicationId === subitem?.applicationId?.applicationId) {
            item["deploymentType1"] = subitem?.deploymenstType;
            item["biNuAppId"] = subitem?.appId;
            
          }
        });
      });
    }
    if (props.tableName === "Admin Application" || props.tableName === "Licensee Application") {
      props.additionalData1?.applicationListResult.map((item, index) => {
        props.additionalData1?.appDevDeployment.map((subitem, indexs) => {
          if (item?.applicationId === subitem?.applicationId?.applicationId) {
            item["devBinuId"] = subitem?.appId;
          }
        });
        props.additionalData1?.revenueType.map((revType, idx) => {
            if(index === idx){
              item["revType"] = revType !== null ? revType : '-';
            } 
        });
        props.additionalData1?.appProdDeployment.map((subitem, indss) => {
          if (item?.applicationId === subitem?.applicationId?.applicationId) {
            item["ProdBinuId"] = subitem?.appId;
          }
        });
      });
    }
    if (props.tableName === "Account Manager Application") {
      props.advanceData?.applicationListResult.map((item, index) => {
        props.advanceData?.devBinuId.map((subitem, index) => {
          if (item?.applicationId === subitem?.applicationId?.applicationId) {
            item["devBinuId"] = subitem?.appId;
          }
        });
        props.advanceData?.prodBinuId.map((subitem, index) => {
          if (item?.applicationId === subitem?.applicationId?.applicationId) {
            item["ProdBinuId"] = subitem?.appId;    
          }
        });
      });
    }

    if (props.tableName === "Account Manager Applications") {
      props.advanceData?.applicationListResult.map((item, index) => {
        props.advanceData?.appDevDeployment.map((subitem, index) => {
          if (item?.applicationId === subitem?.applicationId?.applicationId) {
            item["devBinuId"] = subitem?.appId;
          }
        });
        props.advanceData?.revenueType.map((revType, idx) => {
          if(index === idx){
            item["revType"] = revType !== null ? revType : '-';
          } 
      });
        props.advanceData?.appProdDeployment.map((subitem, index) => {
          if (item?.applicationId === subitem?.applicationId?.applicationId) {
            item["ProdBinuId"] = subitem?.appId;
          
          }
        });
      });
    }

   
  
  }, []);

  const companyList = (item, index) => {
    return (
      <TableRow key={index}>
        {cols.map((colitem, colindex) => (
          <TableCell
            component="th"
            scope="row"
            key={colindex}
            style={
              colitem?.linkName
                ? {
                    textShadow: "none",
                    color: "#337ab7",
                    cursor: "pointer",
                  }
                : {}
            }
            onClick={() => {
              if (colitem.cRoute && props.tableName === "Licensee UserList" && colitem.colMap === "companyIdname") props.navigateFunction(item.companyIdObj.companyType, item.companyIdObj.companyId);

              if (colitem.cRoute && props.tableName === "Channel Publisher LIst" && colitem.colMap === "name") props.navigateFunction(item.companyType, item.companyId);
           
              if (colitem.cRoute && props.tableName === "Licensee Application List") props.navigateFunction(item.publisherId.parentCompanyId.companyId, item.publisherId.parentCompanyId.companyType);
              if (colitem.cRoute && props.tableName === "Licensee Channel" && colitem.colMap === "name") props.navigateFunction(item.companyType, item.companyId);
              if (colitem.cRoute && props.tableName === "Licensee Publisher" && colitem.colMap === "name") props.navigateFunction(item.companyType, item.companyId);

              if (colitem.cRoute && props.tableName === "Licensee Application") props.navigateFunction(item.publisherId.companyType, item.publisherId.companyId);
            
              if (colitem.cRoute && props.tableName === "Admin Licence Service View") props.navigateFunction(item.ldIpId,item.binuIp);
              if (colitem.cRoute && props.tableName === "Admin License Details View") props.navigateFunction(item.licServId);
              if (colitem.cRoute && props.tableName === "Admin BinuSDK") props.navigateFunction("body", item.binuSdkVersionId);
              if (colitem.cRoute && props.tableName === "Admin data free Gateway Domain" && colitem.colMap === "companyIdname")
                props.navigateFunction(item.companyId.companyId, item.companyId.companyType);
              if (colitem.cRoute && props.tableName === "Admin data free Gateway Domain" && colitem.colMap === "gateway") props.navigateFunction(item.gateway, "companyIdgateway");
              
              //if (colitem.cModel) props.clickOpen("body", item.userId);
              if (colitem.cModel && props.tableName === "Admin Binu Platform Version" && colitem.colMap === "binuClientVersionId") props.clickOpen("body", item?.binuClientVersionId, item.isDefault);
              if (colitem.userTab) props.tabNavigation("body", item?.userId? item?.userId:item?.userIdObj?.userId,item?.companyId?item?.companyId:"");
              if (colitem.cModel && props.tableName === "Notification Table") props.clickOpen("body", item?.notificationId);
              
            }}
          >
            {colitem.colMap === "parentCompanyIdname" && props.tableName === "RoleCompany List" && item.parentCompanyId ? (
              item.parentCompanyId.name
            ) 
            :colitem.colMap === "parentCompanyIdname" && props.tableName === "Company List" && item.parentCompanyId ? (
              item.parentCompanyId.name
            ) 
            : colitem.linkName && props.tableName === "Company List" && colitem.cRoute && colitem.colMap === "name" ? (
              <a onClick={() => props.navigateFunction(item.companyType, item.companyId)}>{item.name}</a>
            ) 
            : colitem.linkName && props.tableName === "Maintain Role List" && colitem.cRoute && colitem.colMap === "name" ? (
              <a onClick={() => props.navigateFunction(item?.userId)}>{item.name}</a>
            ):
            props.tableName === "Maintain Role List" && colitem.colMap === "verified" ? (
              <a style={{color:"black"}}>{item.verified == 1 ? "Yes" : "No"}</a>
            ):
            colitem.linkName && props.tableName === "RoleCompany List" && colitem.cRoute && colitem.colMap === "names" ? (
              <a onClick={() => props.navigateFunctions(item.name, item.companyId,item?.companyType)}>{item.name}</a>
            ) :
            colitem.linkName && props.tableName === "Connect Utility Table" && colitem.cRoute && colitem.colMap === "publisherIdparentCompanyIdname" ? (
              <a onClick={() => props.navigateFunction(item.publisherId.parentCompanyId.parentCompanyId.companyType, item.publisherId.parentCompanyId.parentCompanyId.companyId)}>{item.publisherId.parentCompanyId.parentCompanyId.name}</a>
            ) :
            colitem.linkName && props.tableName === "Connect Utility Table" && colitem.cRoute && colitem.colMap === "publisherIdparentCompanyIdparentCompanyIdname" ? (
              <a onClick={() => props.navigateFunction(item.publisherId.parentCompanyId.companyType, item.publisherId.parentCompanyId.companyId)}>{item.publisherId.parentCompanyId.name}</a>
            ) :
            colitem.linkName && props.tableName === "Connect Utility Table" && colitem.cRoute && colitem.colMap === "publisherIdname" ? (
              <a onClick={() => props.navigateFunction(item.companyType, item.publisherId.companyId)}>{item.publisherId.name}</a>
            ) :
            colitem.linkName && props.tableName === "Connect Utility Table" && colitem.cRoute && colitem.colMap === "name" ? (
              <a onClick={() => props.navigateFunction(item.applicationType,item.applicationId)}>{item.name}</a>
            ) :

             colitem.linkName && props.tableName === "Admin data free direct application" && colitem.cRoute && colitem.colMap === "publisherIdparentCompanyIdname" ? (
              <a onClick={() => props.navigateFunction(item.publisherId.parentCompanyId.companyType, item.publisherId.parentCompanyId.companyId)}>{item.publisherId.parentCompanyId.name}</a>
            ) : colitem.linkName && props.tableName === "Admin data free direct application" && colitem.cRoute && colitem.colMap === "name" ? (
              <a onClick={() => props.onClickApp(item.reachSubDomain)}>{item.name}</a>
            ) 
            
            :colitem.linkName && props.tableName === "Admin data free direct application" && colitem.cRoute && colitem.colMap === "publisherIdname" ? (
              <a onClick={() => props.navigateFunction(item.publisherId.companyType, item.publisherId.companyId)}>{item.publisherId.name}</a>
            ) : colitem.linkName && props.tableName === "Admin data free direct application" && colitem.cRoute && colitem.colMap === "name" ? (
              <a onClick={() => props.navigateFunction(item.publisherId.companyType, item.publisherId.companyId)}>{("App Name", item.reachSubDomain)}</a>
            ) 
            : colitem.linkName && props.tableName === "User List" && colitem.cRoute && colitem.colMap === "CompanyParentJson" ? (
              <a onClick={() => props.navigateFunction(item.companyIdObj.companyType, item.companyIdObj.companyId)}>{item.companyIdObj.name}</a>
            )
            
            : colitem.linkName && props.tableName === "User List" && colitem.colMap === "name" ? (
              <a >{item.userIdObj.name}</a>
            ) : props.tableName === "User List" && colitem.colMap === "email" ? (
              <a style={{color:"black"}}>{item.userIdObj.email}</a>
            ) :  props.tableName === "User List" && colitem.colMap === "userType" ? (
              <a style={{color:"black"}}>{item.userIdObj.userType}</a>
            ) : props.tableName === "User List" && colitem.colMap === "companyIdparentCompanyIdname" ? (
              <a style={{color:"black"}}>{item?.companyIdObj?.parentCompanyId?.name}</a>
            ): props.tableName === "User List" && colitem.colMap === "CompanyTypeParentJson" ? (
              <a style={{color:"black"}}>{item.companyIdObj.name}</a>
            ):
            
            colitem.linkName && props.tableName === "Admin Application" && colitem.cRoute && colitem.colMap === "publisherIdparentCompanyIdname" ? (
              <a onClick={() => props.navigateFunction(item.publisherId.parentCompanyId.companyType, item.publisherId.parentCompanyId.companyId)}>{item.publisherId.parentCompanyId.name}</a>
            ) : colitem.linkName && props.tableName === "Admin Application" && colitem.cRoute && colitem.colMap === "publisherIdname" ? (
              <a onClick={() => props.navigateFunction(item.publisherId.companyType, item.publisherId.companyId)}>{item.publisherId.name}</a>
            ) : colitem.linkName && props.tableName === "Admin Utility" && colitem.cRoute && colitem.colMap === "utility_name" ? (
              <a onClick={() => props.navigateFunction(item.utility_name)}>{item.utility_name}</a>
            ) : colitem.linkName && props.tableName === "Admin License Details Channel" && colitem.cRoute && colitem.colMap === "name" ? (
              <a onClick={() => props.navigateFunction(item.companyId)}>{item.name}</a>
            ) : colitem.linkName && props.tableName === "Company  Details Users" && colitem.cRoute && colitem.colMap === "name" ? (
              <a onClick={() => props.navigateFunction(item.companyType, item.companyId)}>{item.name}</a>
            ): colitem.linkName && props.tableName === "AccountManager User List" && colitem.cRoute && colitem.colMap === "companyIdname" ? (
              <a onClick={() => props.navigateFunction(item.companyIdObj?.companyType,item.companyIdObj?.companyId)}>{item?.companyIdObj?.name}</a>
            )
           /* : colitem.linkName && props.tableName == "Connect Utility Table" && colitem.cRoute && colitem.colMap == "versionTag" ? (
              <a onClick={() => props.navigateFunction(props?.additionalData1?.[index].service, props?.additionalData1?.[index].lic_serv_id)}>{props?.additionalData1?.[index].name}</a>
            )*/
            : colitem.linkName && props.tableName === "Licensee Application" && colitem.cRoute && colitem.colMap === "publisherIdname" ? (
              <a onClick={() => props.navigateFunction(item.publisherId.companyType, item.publisherId.companyId)}>{item.publisherId.name}</a>
            ) :colitem.linkName && props.tableName === "Account Manager Application" && colitem.cRoute && colitem.colMap === "publisherIdname" ? (
              <a onClick={() => props.navigateFunction(item.publisherId.companyType, item.publisherId.companyId)}>{item.publisherId.name}</a>
            )
            :colitem.linkName && props.tableName === "Account Manager Applications" && colitem.cRoute && colitem.colMap === "publisherIdname" ? (
              <a onClick={() => props.navigateFunction(item.publisherId.companyType, item.publisherId.companyId)}>{item.publisherId.name}</a>
            )
            
            : colitem.colMap === "name" && props.tableName === "Account Manager Application" ? (
              item.name
            )
            : colitem.colMap === "productIdproductType" && props.tableName === "Account Manager Application" ? (
              item.productId.productType
            )
            : colitem.colMap === "reachSubDomain" && props.tableName === "Account Manager Application" ? (
              item.reachSubDomain
            )
            : colitem.colMap === "googleAppId" && props.tableName === "Account Manager Application" ? (
              item.googleAppId
            )
            : colitem.colMap === "applicationType" && props.tableName === "Account Manager Application" ? (
              item.applicationType
            )
            : colitem.colMap === "publisherIdpubDataUsageAlert" && props.tableName === "Account Manager Application" ? (
              item.publisherId.pubDataUsageAlert
            )
            : colitem.colMap === "publisherIdname" && props.tableName === "Account Manager Application" ? (
              item.publisherId.name
            )

            : colitem.colMap === "name" && props.tableName === "Account Manager Applications" ? (
              item.name
            )
            : colitem.colMap === "revenueType" && props.tableName === "Account Manager Applications" ? (
              item.revType
            )
            : colitem.colMap === "productIdproductType" && props.tableName === "Account Manager Applications" ? (
              item.productId.productType
            )
            : colitem.colMap === "reachSubDomain" && props.tableName === "Account Manager Applications" ? (
              item.reachSubDomain
            )
            : colitem.colMap === "googleAppId" && props.tableName === "Account Manager Applications" ? (
              item.googleAppId
            )
            : colitem.colMap === "applicationType" && props.tableName === "Account Manager Applications" ? (
              item.applicationType
            )
            : colitem.colMap === "publisherIdpubDataUsageAlert" && props.tableName === "Account Manager Applications" ? (
              item.publisherId.pubDataUsageAlert
            )
            : colitem.colMap === "publisherIdname" && props.tableName === "Account Manager Applications" ? (
              item.publisherId.name
            )

            : colitem.colMap === "binuSdkVersionId" && props.tableName === "Admin BinuSDK" ? (
              <span title="Click here to edit biNu SDK version">{item?.binuSdkVersionId}</span>
            )
            : colitem.colMap === "publisherIdcompanyType" && props.tableName === "Account Manager Application" ? (
              item.publisherId.companyType
            )
            : colitem.colMap === "publisherIdstatus" && props.tableName === "Account Manager Application" ? (
              item.publisherId.status
            )
            : colitem.colMap === "ProductionHealth" && props.tableName === "Account Manager Application" ? (
              item.publisherId.companyType
            )
            : colitem.colMap === "status" && props.tableName === "Account Manager Application" ? (
              item.publisherId.companyType
            )

            : colitem.colMap === "publisherIdcompanyType" && props.tableName === "Account Manager Applications" ? (
              item.publisherId.companyType
            )
            : colitem.colMap === "publisherIdstatus" && props.tableName === "Account Manager Applications" ? (
              item.publisherId.status
            )
            : colitem.colMap === "status" && props.tableName === "Account Manager Applications" ? (
              item.publisherId.companyType
            )

            : colitem.colMap === "CompanyTypeParentJson" ? (
              item.companyId.companyType
            ) : colitem.colMap === "publisherIdparentCompanyIdname" && props.tableName === "Admin Application" ? (
              item.publisherId.parentCompanyId.name
            ) : colitem.colMap === "productIdproductType" && props.tableName === "Admin Application" ? (
              item.productId.productType  
            ) 
            : colitem.colMap === "revenueType" && props.tableName === "Admin Application" ? (
              item.revType  
            )
            : colitem.colMap === "revenueType" && props.tableName === "Licensee Application" ? (
              item.revType  
            )
            : colitem.colMap === "publisherIdpubDataUsageAlert" && props.tableName === "Admin Application" ? (
              props?.additionalData1?.prodDataUsage28Days ? (
                props?.additionalData1?.prodDataUsage28Days
              ) : (
                "0"
              )
            ) : colitem.colMap === "publisherIdparentCompanyIdname" && props.tableName === "Licensee Application" ? (
              item.publisherId.parentCompanyId.name
            )
            : colitem.colMap === "devBinuId" && props.tableName === "Licensee Application" ? (
              item.devBinuId ?
              item.devBinuId : "-"
            )

            : colitem.colMap === "ProdBinuId" && props.tableName === "Licensee Application" ? (
              item.ProdBinuId ?
              item.ProdBinuId : "-"
            ) 
            : colitem.colMap === "devBinuId" && props.tableName === "Account Manager Applications" ? (
              item.devBinuId ?
              item.devBinuId : "-"
            )
           
            : colitem.colMap === "name" && props.tableName === "Admin License Details" ? (
              item.userIdObj?.name
            )
            : colitem.colMap === "email" && props.tableName === "Admin License Details" ? (
              item.userIdObj?.email
            )
            : colitem.colMap === "userType" && props.tableName === "Admin License Details" ? (
              item.userIdObj?.userType
            )
            : colitem.colMap === "verified" && props.tableName === "Admin License Details" ? (
              item.userIdObj?.verified == 1?"Yes":"No"
            )
            : colitem.colMap === "created" && props.tableName === "Admin License Details" ? (
              moment(item.userIdObj?.created ).format("Do MMM YYYY") + " GMT"
            )
            : colitem.colMap === "ProdBinuId" && props.tableName === "Account Manager Applications" ? (
              item.ProdBinuId ?
              item.ProdBinuId : "-"
            ) 
            : colitem.colMap === "companyIdparentCompanyIdname" && props.tableName === "Licensee UserList" ? (
              item.companyIdObj.parentCompanyId.name
            ) : colitem.colMap === "companyIdname" && props.tableName === "Licensee UserList" ? (
              item.companyIdObj.name
            ) : colitem.colMap === "companyIdcompanyType" && props.tableName === "Licensee UserList" ? (
              item.companyIdObj.companyType
            )  : colitem.colMap === "status" && props.tableName === "Licensee UserList" ? (
                item.status
            )  : colitem.colMap === "username" && props.tableName === "Licensee UserList" ? (
                  item?.userIdObj?.name
            )
            : colitem.colMap === "email" && props.tableName === "Licensee UserList" ? (
              item?.userIdObj?.email
          ) 
           :  colitem.colMap === "userType" && props.tableName === "Licensee UserList" ? (
          item?.userIdObj?.userType
          ) 
          : colitem.colMap === "verified" && props.tableName === "Licensee UserList" ? (
            item?.userIdObj?.verified ==1?"Yes":"No"
            ) 
             : colitem.colMap === "contractTerm" && props.tableName === "Licensee Publisher" ? (
                  item.contractTerm
            ) : colitem.colMap === "parentCompanyIdname" && props.tableName === "Licensee Publisher" ? (
              item.parentCompanyId.name
            ) : colitem.colMap === "companyIdparentCompanyIdname" && props.tableName === "User List" ? (
              item.companyId?.parentCompanyId?.name
            ) : colitem.colMap === "verified" && props.tableName === "User List" ? (
              item.verified == 1 ? (
                "YES"
              ) : (
                "NO"
              )
            ) : colitem.colMap === "productIdproductType" && props.tableName === "Licensee Application" ? (
              item.productId.productType
            ) : colitem.colMap === "publisherIdpubDataUsageAlert" && props.tableName === "Licensee Application" ? (
              item.prodDataUsage28Days ? (
                item.prodDataUsage28Days
              ) : (
                "0"
              )
            ) : colitem.colMap === "publisherIdcompanyType" && props.tableName === "Admin data free direct application" ? (
              item.publisherId.companyType
            ) : colitem.colMap === "publisherIdstatus" && props.tableName === "Admin data free direct application" ? (
              item.publisherId.status
            ) : colitem.colMap === "prodDataUsage28Days" && props.tableName === "Admin data free direct application" ? (
              item.publisherId.prodDataUsage28Days ? (
                item.publisherId.prodDataUsage28Days
              ) : (
                "0"
              )
            ) : colitem.colMap === "publisherIdname" && props.tableName === "Admin Application" ? (
              item.publisherId.name
            ): colitem.colMap === "name" && props.tableName === "Account Manager Application" ? (
              item.name
            ): colitem.colMap === "productIdproductType" && props.tableName === "Account Manager Application" ? (
              item.productId.productType
            ): colitem.colMap === "reachSubDomain" && props.tableName === "Account Manager Application" ? (
              item.reachSubDomain
            ): colitem.colMap === "googleAppId" && props.tableName === "Account Manager Application" ? (
              item.googleAppId
            ):colitem.colMap === "applicationType" && props.tableName === "Account Manager Application" ? (
              item.applicationType
            ):colitem.colMap === "publisherIdpubDataUsageAlert" && props.tableName === "Account Manager Application" ? (
              item.publisherId.pubDataUsageAlert
            ):colitem.colMap === "publisherIdcompanyType" && props.tableName === "Account Manager Application" ? (
              item.publisherId.companyType
            ):colitem.colMap === "publisherIdstatus" && props.tableName === "Account Manager Application" ? (
              item.publisherId.status
            ):colitem.colMap === "Status" && props.tableName === "Account Manager Application" ? (
              item.status

              ): colitem.colMap === "name" && props.tableName === "Account Manager Applications" ? (
                item.name
              ): colitem.colMap === "productIdproductType" && props.tableName === "Account Manager Applications" ? (
                item.productId.productType
              ): colitem.colMap === "reachSubDomain" && props.tableName === "Account Manager Applications" ? (
                item.reachSubDomain
              ): colitem.colMap === "googleAppId" && props.tableName === "Account Manager Applications" ? (
                item.googleAppId
              ):colitem.colMap === "applicationType" && props.tableName === "Account Manager Applications" ? (
                item.applicationType
              ):colitem.colMap === "publisherIdpubDataUsageAlert" && props.tableName === "Account Manager Applications" ? (
                item.publisherId.pubDataUsageAlert
              ):colitem.colMap === "publisherIdcompanyType" && props.tableName === "Account Manager Applications" ? (
                item.publisherId.companyType
              ):colitem.colMap === "publisherIdstatus" && props.tableName === "Account Manager Applications" ? (
                item.publisherId.status
              ):colitem.colMap === "Status" && props.tableName === "Account Manager Applications" ? (
                item.status
            ):
           /* colitem.colMap == "isDefault" && props.tableName == "Connect Utility Table" ? (
              item.datafreeAppId.name
            ) :*/
            colitem.colMap === "publisherIdname" && props.tableName === "Connect Utility Table" ? (
              item.publisherId.name
            ) :
            colitem.colMap === "publisherIdstatus" && props.tableName === "Connect Utility Table" ? (
              item.publisherId.status
            ) :
            colitem.colMap === "publisherIdcompanyType" && props.tableName === "Connect Utility Table" ? (
              item.publisherId.companyType
            )
             :colitem.colMap === "publisherIdparentCompanyIdname" && props.tableName === "Connect Utility Table" ? (
              item.publisherId.parentCompanyId.name
            ):colitem.colMap === "publisherIdparentCompanyIdparentCompanyIdname" && props.tableName === "Connect Utility Table" ? (
              item.publisherId.parentCompanyId.parentCompanyId.name
            ):
            colitem.colMap === "versionTag" && props.tableName === "Connect Utility Table" ? (
              <a onClick={() => props.serviceNav(props?.additionalData1?.[index].company_id, props?.additionalData1?.[index].lic_serv_id)}>{props?.additionalData1?.[index] ? props?.additionalData1?.[index].name : ""}</a>
             
            ):
             colitem.colMap === "name" && props.tableName === "AccountManager Publisher List" ? (
              <a onClick={() => props.serviceNav(item.companyId)}>{item.name ? item.name : ""}</a>
            ) :
            colitem.colMap === "name" && props.tableName === "Publisher User Details View" ? (
              <a onClick={() => props.serviceNav('body',item?.userIdObj?.userId,item.companyId)}>{item.userIdObj?.name ? item.userIdObj?.name : ""}</a>
             
            ):  colitem.colMap === "name" && props.tableName === "AccountManager User List" ? (
              <a>{item.userIdObj?.name ? item.userIdObj?.name : ""}</a>
             
            ): colitem.colMap === "companyIdname" && props.tableName === "AccountManager User List" ? (
              <a>{ item.companyIdObj?.name?  item.companyIdObj?.name : ""}</a>
             
            ):
           
            colitem.colMap === "isDefault" && props.tableName === "Connect Utility Table" ? (
              props?.additionalData2?.[index] ? props?.additionalData2?.[index].appId : ""
            ):
            

             colitem.colMap === "publisherIdname" && props.tableName === "Licensee Application" ? (
              item.publisherId.name
            ) : colitem.colMap === "parentCompanyIdname" && props.tableName === "Admin data free direct application" ? (
              item.parentCompanyId.name
            ) : colitem.colMap === "publisherIdcompanyType" && props.tableName === "Admin Application" ? (
              item.publisherId.companyType
            ) : colitem.colMap === "prodDataUsage28Days" && props.tableName === "Admin data free direct application" && props.additionalData1.prodDataUsage28Days === null ? (
              "0"
            ) : colitem.colMap === "publisherIdcompanyType" && props.tableName === "Licensee Application" ? (
              item.publisherId.companyType
            ) : colitem.colMap === "prodDataUsage28Days" && props.tableName === "Admin data free direct application" && props.additionalData1.prodDataUsage28Days !== null ? (
              props.additionalData1.prodDataUsage28Days
            ) : colitem.colMap === "serviceservice" && props.tableName === "Admin License Details View" ? (
              item.service.service
            ) : colitem.colMap === "zeroRated" && props.tableName === "Admin License Details View" && item.zeroRated == "1" ? (
              "Yes"
            ) : colitem.colMap === "zeroRated" && props.tableName === "Admin License Details View" && item.zeroRated != "1" ? (
              "No"
            ) : colitem.colMap === "binuIp" && props.tableName === "Admin Licence Service View" && item.binuIp === true ? (
              "Y"
            ) : colitem.colMap === "binuIp" && props.tableName === "Admin Licence Service View" && item.binuIp === false ? (
              "N"
            ) : colitem.colMap === "Created" && props.tableName === "Admin Licence Service View" && props.additionData.licenseeService.service.service === "CONNECT" ? (
              item.Created
            ) : colitem.colMap === "productIdproductType" && props.tableName === "Admin Customer Details Publisher" ? (
              item.productId.productType
            )
            : props.tableName === "Admin Customer Details Publisher" && colitem.colMap === "appln" ? (
              props?.additionalData1?.productionDeploymentObj[index] ?
              <label className="NewCheck"> <input 
               type="checkbox" 
               name="checkbox" 
               id= {"check_"+props?.additionalData1?.productionDeploymentObj[index].deploymentId}
               onChange={(e)=>props.CheckFunction(e,props?.additionalData1?.productionDeploymentObj[index].prodHealthCheckOn,props?.additionalData1?.productionDeploymentObj[index].deploymentId)}
               checked={props?.additionalData1?.productionDeploymentObj[index]?.prodHealthCheckOn}>   
               </input> </label> :  " "
           )
           : props.tableName === "Publisher Application Details View" && colitem.colMap === "appln" ? (
            props?.additionalData1?.productionDeploymentObj[index] ?
            <label className="NewCheck"> <input 
             type="checkbox" 
             name="checkbox" 
             id= {"check_"+props?.additionalData1?.productionDeploymentObj[index].deploymentId}
             onChange={(e)=>props.CheckFunction(e,props?.additionalData1?.productionDeploymentObj[index].prodHealthCheckOn,props?.additionalData1?.productionDeploymentObj[index].deploymentId)}
             checked={props?.additionalData1?.productionDeploymentObj[index]?.prodHealthCheckOn}>   
             </input> </label> :  " "
         ):
         props.tableName === "Publisher Application Details View" && colitem.colMap === "revenueType" ? (
           
          <select className="pubSelect" onChange={(e) => props.changeRevType(e,item.applicationId)}>
            <option value=""></option>
            {props.additionalData1?.revenueType.map((vals, index)=>(
              <option value={vals.rtId} key={index} selected={item.rtId === vals.rtId && true}>{vals.revType}</option>
            ))}
         </select>
       ):
           //AccountManager publisher LIst
           colitem.colMap === "companyId" && props.tableName === "AccountManager Publisher List" ? (
            item.companyId
          ):  colitem.colMap === "companyType" && props.tableName === "AccountManager Publisher List" ? (
            item.companyType
          ) :  colitem.colMap === "status" && props.tableName === "AccountManager Publisher List" ? (
            item.status
          ) :  colitem.colMap === "contractTerm" && props.tableName === "AccountManager Publisher List" ? (
            item.contractTerm
          ) :  colitem.colMap === "created" && props.tableName === "AccountManager Publisher List" ? (
            moment(item.created).format("Do MMM YYYY") + " GMT"
          ) :
             colitem.colMap === "email" && props.tableName === "Publisher User Details View" ? (
            item.userIdObj?.email
          ) :
          colitem.colMap === "role" && props.tableName === "Publisher User Details View" ? (
         item.role
       ) : colitem.colMap === "userType" && props.tableName === "Publisher User Details View" ? (
        item.userIdObj?.userType
      ) : colitem.colMap === "verified" && props.tableName === "Publisher User Details View" ? (
        item.userIdObj?.verified
      ) : colitem.colMap === "status" && props.tableName === "Publisher User Details View" ? (
        item.status
      ) : colitem.colMap === "created" && props.tableName === "Publisher User Details View" ? (
        moment(item.userIdObj?.created).format("Do MMM YYYY")+ " GMT"
      ): colitem.colMap === "email" && props.tableName === "AccountManager User List" ? (
        item.userIdObj?.email
      ) : colitem.colMap === "role" && props.tableName === "AccountManager User List" ? (
        item.role
      )  : colitem.colMap === "userType" && props.tableName === "AccountManager User List" ? (
        item.userIdObj?.userType
      )   : colitem.colMap === "verified" && props.tableName === "AccountManager User List" ? (
        item.userIdObj?.verified
      ) : colitem.colMap === "status" && props.tableName === "AccountManager User List" ? (
        item.status
      ) : colitem.colMap === "companyIdcompanyType" && props.tableName === "AccountManager User List" ? (
        item.companyIdObj?.companyType
      ) 

           : props.tableName === "Admin Customer Details Publisher" && colitem.colMap === "status" ? (
           
            <select className="pubSelect" onChange={(e) => props.navigateFunction(e,item.applicationId)}>
              <option value="Live" selected={item.status === "Live" && true}>Live</option>
              <option value="ForwardTraffic" selected={item.status === "ForwardTraffic" && true}>ForwardTraffic</option>
              <option value="Suspended" selected={item.status === "Suspended" && true}>Suspended</option>
              <option value="Deleted" selected={item.status === "Deleted" && true}>Deleted</option>
           </select>
         )

         : props.tableName === "Admin Customer Details Publisher" && colitem.colMap === "revenueType" ? (
           
          <select className="pubSelect" onChange={(e) => props.changeRevType(e,item.applicationId)}>
            <option value=""></option>
            {props.additionalData1?.revenueType.map((vals, index)=>(
              <option value={vals.rtId} key={index} selected={item.rtId === vals.rtId && true}>{vals.revType}</option>
            ))}
         </select>
       )
            : colitem.colMap === "View Details" && props.tableName === "Admin License Details View" ? (
              "View Details"
            ) : colitem.colMap === "View Details" && props.tableName === "Admin Licence Service View" ? (
              "View Details"
            ) : colitem.colMap === "Edit" && props.tableName === "Admin Licence Service Deployed" ? (
              <a onClick={() => props.navigateFunction(item.hfia_id)}>Edit</a>
            ) 
            : colitem.colMap === "Use for Products" && props.tableName === "Admin Gateway Domain Details View" ? 
            (
              props.additionData?.useForProduct[index]?.map((mainitem, idx) => 
            
                
              <div key={idx}>{mainitem.productType ? mainitem.productType : ""}</div>
          
              )
             
            ) :colitem.colMap === "Points to Service" && props.tableName === "Admin Gateway Domain Details View" ? 
            (
              props.additionData?.pointsToService[index]?.map((mainitem, idx) => 
            
                
              <div key={idx}>{mainitem.licServeId?.name ? mainitem.licServeId?.name: ""}</div>
          
              )
             
            ) 
            
            : colitem.colMap === "Points to Service" && props.tableName === "Admin Gateway Domain Details View" ? (
              props.additionData?.service?.map((mainitem, idx) => mainitem?.map((subItem, idx) => <div key={idx}>{subItem.licServeId?.name ? subItem.licServeId.name : ""}</div>))
            ) : colitem.colMap === "publisherIdstatus" && props.tableName === "Admin Application" ? (
              item.publisherId.status
            ) : colitem.colMap === "publisherIdstatus" && props.tableName === "Licensee Application" ? (
              item.publisherId.status
            ) 
            : colitem.colMap === "ProductionHealth" && props.tableName === "Licensee Application" ? (
              props?.additionalData1?.appProdDeployment?.[index] ?
               <input className="checkboxForm" type="checkbox" disabled="disabled" checked={props?.additionalData1?.appProdDeployment?.[index]?.prodHealthCheckOn}>   
               </input> :  " "
            )
            : colitem.colMap === "binuSdkVersionNote" && item.binuSdkVersionNote.length > 18 ? (
              item.binuSdkVersionNote.substring(0, 50) + "..."
            ) : colitem.colMap === "isDefault" && props.tableName === "Admin BinuSDK" && item.isDefault ? (
              <FaTimes className="fatimesclass" />
            ) : colitem.colMap === "productIdproductType" && props.tableName === "Admin Binu Platform Version" ? (
              item.productId.productType
            ) : colitem.colMap === "isDefault" && props.tableName === "Admin Binu Platform Version" && item.isDefault ? (
              <FaTimes className="fatimesclass" />
            ) : colitem.colMap === "companyIdparentCompanyIdname" && props.tableName === "Admin data free Gateway Domain" ? (
              item.companyId.parentCompanyId.name
            ) : colitem.colMap === "companyIdcompanyType" && props.tableName === "Admin data free Gateway Domain" ? (
              item.companyId.companyType
            ) : colitem.colMap === "companyIdname" && props.tableName === "Admin data free Gateway Domain" ? (
              item.companyId.name

            ) : colitem.colMap === "active_from" && props.tableName === "Notification Table" ? (
                moment(item.activeFrom).format("Do MMM  YYYY") + " GMT"

            ) : colitem.colMap === "start_date" && props.tableName === "Admin Licence Service Deployed" ? (
                  moment(item.start_date).format("DD/MM/YYYY")
            ) : colitem.colMap === "end_date" && props.tableName === "Admin Licence Service Deployed" ? (
              item.end_date ? moment(item.end_date).format("DD/MM/YYYY") : ""
            ) : colitem.colMap === "active_to" && props.tableName === "Notification Table" ? ( 
                  moment(item.activeTo).format("Do MMM  YYYY") + " GMT"
            ) 
            : colitem.colMap === "created" && props.tableName === "Notification Table" ? ( 
              moment(item.created).format("Do MMM  YYYY") + " GMT"
            )
            : colitem.colMap === "status" && props.tableName === "Notification Table" ? ( 
              item.status
            )
            : colitem.colMap === "productIdproductType" && props.tableName === "Publisher Application Details View" ? ( 
              item?.productId?.productType
            )
            : colitem.colMap === "name" && props.tableName === "Admin Customer Details View" ? ( 
              item?.userIdObj?.name
            ):
            colitem.colMap === "email" && props.tableName === "Admin Customer Details View" ? ( 
              item?.userIdObj?.email
            ):
             colitem.colMap === "role" && props.tableName === "Admin Customer Details View" ? ( 
              item?.role
            )
            :
            colitem.colMap === "verified" && props.tableName === "Admin Customer Details View" ? ( 
              item?.userIdObj?.verified === 1?"Yes":"No"
            )
            :
            colitem.colMap === "status" && props.tableName === "Admin Customer Details View" ? ( 
              item?.status
            ):
            colitem.colMap === "created" && props.tableName === "Admin Customer Details View" ? ( 
              item?.userIdObj?.created
            )
            
            :
             colitem.colMap === "userType" && props.tableName === "Admin Customer Details View" ? ( 
              item?.userIdObj?.userType
            )
            
            :
            colitem.colMap === "name" && props.tableName === "Company  Details Users" ? ( 
              item?.userIdObj?.name
            ):
            colitem.colMap === "email" && props.tableName === "Company  Details Users" ? ( 
              item?.userIdObj?.email
            ) 
            :colitem.colMap === "userType" && props.tableName === "Company  Details Users" ? ( 
              item?.userIdObj?.userType
            )
            :colitem.colMap === "verified" && props.tableName === "Company  Details Users" ? ( 
              item?.userIdObj?.verified === 1?"Yes":"No"
            )
            :colitem.colMap === "created" && props.tableName === "Company  Details Users" ? ( 
              moment(item.userIdObj?.created).format("Do MMM YYYY") + " GMT"
            )
            : colitem.colMap === "notificationLevel" && props.tableName === "Notification Table" ? ( 
              item?.notificationLevel?.level
            )
            : colitem.colMap === "notificationType" && props.tableName === "Notification Table" ? ( 
              item?.notificationType?.notification
            ) 
            : colitem.colMap === "accountName" && props.tableName === "Notification Table" ? ( 
              item?.companyId?.name
            ) 
            
            : colitem.colMap === "name" && props.tableName === "Admin Account" ? ( 
              item?.userIdObj?.name
            ) 
            : colitem.colMap === "email" && props.tableName === "Admin Account" ? ( 
              item?.userIdObj?.email
            ) 
            : colitem.colMap === "userType" && props.tableName === "Admin Account" ? ( 
              item?.userIdObj?.userType
            ) 
            : colitem.colMap === "verified" && props.tableName === "Admin Account" ? ( 
              item?.userIdObj?.verified === 1?"Yes":"No"
            )
            : colitem.colMap === "created" && props.tableName === "Admin Account" ? ( 
              moment(item.userIdObj?.created).format("Do MMM YYYY") + " GMT"
            )  
            : colitem.colMap === "notificationId" && props.tableName === "Notification Table" ? ( 
              item?.notificationId
            ) 
            :  colitem.colMap === "productsproductType" && props.tableName === "Admin data free Gateway Domain" ? (
              props.additionalData?.products[index]?.map((mainitem, idx) => 
            
                
              <div key={idx}>{mainitem.productType ? mainitem.productType: ""}</div>
          
              )
             
            ) :
              colitem.colMap === "licServeIdname" && props.tableName === "Admin data free Gateway Domain" ? (
              props.additionalData?.service[index]?.map((mainitem, idx) => 
            
                
              <div key={idx}>{mainitem.licServeId ? mainitem.licServeId.name: ""}</div>
          
              )
             
            )
            : props.tableName === "Admin Application" && colitem.colMap === "ProductionHealth" ? item.ProdBinuId ? (
               props?.additionalData1?.appProdDeployment  ?
                  props?.additionalData1?.appProdDeployment[index].prodHealthCheckOn === true ? 
                <input className="checkboxForm" type="checkbox"  disabled="disabled" checked={true}>
                  
                </input>
                :<input className="checkboxForm" type="checkbox"  disabled="disabled" checked={false}>
                  
                  </input>
                : null 
              
            ) :null:
           props.tableName === "Account Manager Applications" && colitem.colMap === "ProductionHealth" ? item.ProdBinuId ? (
              props?.advanceData?.appProdDeployment[index]?.prodHealthCheckOn === true?
               <input className="checkboxForm" type="checkbox"  disabled="disabled" checked={true}>
                 
               </input>
               :<input className="checkboxForm" type="checkbox"  disabled="disabled" checked={false}>
                 
                 </input>
             
           ) :null:
            // checked={props?.additionalData1?.appDevDeployment[index]?.prodHealthCheckOn == true?true:false}
            // <input className="checkboxForm" type="checkbox"  disabled="disabled" checked={props?.additionalData1?.appDevDeployment[index].map((item,index)=>(
            //   item?.prodHealthCheckOn == true?true:false
            // ))}>
            colitem?.colMap === "companyIdstatus" && props.tableName === "Admin data free Gateway Domain" ? (
              item?.companyId?.status
            ) : colitem.fDate ? (
              moment(item[colitem.colMap]).format("Do MMM  YYYY") + " GMT")
              
              
            : colitem.colMap === "Impersonate" ? (
              item.status == "Active" && item?.role !== "ROLE_ADMIN" ? (
                <button className="impesonatebtn" title="Click here to log in as user" onClick={() => props.imprNavigation(item?.email?item?.email
                  :item?.userIdObj?.email,item?.role?item?.role:"",item?.companyId?item?.companyId:"",item?.companyIdObj?.companyType,item?.companyIdObj?.name)}>
                  Impersonate User
                </button>
              ) : (
                <div></div>
              )
            ) : (
              item[colitem.colMap]
            )}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  

  return (
    <TableContainer>
      <Table className="accouttablestyle" aria-label="simple table" style={{marginLeft:props.tableName==="Admin Licence Service Deployed"?'27px':'0%'}}>
        <TableHead>
          <TableRow>
            {cols.map((item, index) => (
              <TableCell className="tableHead" key={index}>
                {item.colName === "Type" && props.tableName === "Admin Licence Service View" && props.additionData.licenseeService.service.service === "CONNECT"
                  ? "Type"
                  : props.tableName !== "Admin Licence Service View"
                  ? item.colName
                  : item.colName !== "Type" && props.tableName === "Admin Licence Service View"
                  ? item.colName
                  : null}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableName === "Company List"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "User List"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Company  Details Users"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Binu Platform Version"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Admin Binu Platform Version"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Admin Account"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Admin Utility"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Admin BinuSDK"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Admin Application"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Admin data free direct application"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Admin License Details"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Admin License Details Channel"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Admin License Details View"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Admin Gateway Domain Details View"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Admin Customer Details View"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Admin Customer Details Publisher"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Admin Licence Service View"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Admin data free Gateway Domain"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Admin Licence Service Deployed"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Licensee Application"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Licensee Channel"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Licensee Publisher"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Licensee UserList"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Licensee User Account Detail"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "licensee Customer Details View"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "licensee Customer Appln Details View"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Connect Utility Table"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Channel Publisher LIst"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "AccountManager Publisher List"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Publisher User Details View"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "AccountManager User List"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Account Manager Application"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Notification Table"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Account Manager Applications"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Publisher Application Details View"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "RoleCompany List"
            ? datas.map((item, index) => companyList(item, index))
            : props.tableName === "Maintain Role List"
            ? datas.map((item, index) => companyList(item, index))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VMCTable;
