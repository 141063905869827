import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
  makeStyles,
  Grid,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import defaultIcon from "../../../../../assets/img/datafree_defaulticon.png";
import splashImage from "../../../../../assets/img/default-app-splash.jpg";
import arrowRotation from "../../../../../assets/img/arrowrotation.png";
import Adminservice from "../../../../../api/adminService";
import applicationIcon from "../../../../../assets/img/application-icon.png";
import service from "../../../../../api/service";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";
import { IoMdCloseCircleOutline } from "react-icons/io";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});
function GettingStartMax(props) {
  let history = useHistory();
  const classes = useStyles();
  const [appCheck, setAppCheck] = useState(false);
  const [colorchecked, setColorchecked] = useState(false);
  const [accentcolorchecked, setAccentColorchecked] = useState(false);
  const [actioncolorchecked, setActionColorchecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [formStep, setFormStep] = useState(0);
  const [icon, setIcon] = useState({ preview: defaultIcon, raw: "" });
  const [splash, setSplash] = useState({ preview: splashImage, raw: "" });
  var [firstCheckbox, setFirstCheckbox] = useState(false); // true - first check box defaults to checked.
  var [secondCheckbox, setSecondCheckbox] = useState(false);
  var [FirstPermission, setFirstPermission] = useState(false); // true - first check box defaults to checked.
  var [SecondPermission, setSecondPermission] = useState(false);
  var [whiteList, setWhiteList] = useState(false);
  const [ResponseData, setResponseData] = useState();
  var [generateApk, setgenerateApk] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const [error, setError] = useState({
    msg: "",
    name: false,
    website_url: false,
    gateway_domain: false,
    configName: false,
    description: false,
    distribution_email: false,
    email_distribution: false,
    demailmsg: "",
    googleAppId: false,
    appmsg: "",
    imgmsg: "",
    icon_image: false,
    splash_image: false,
    splashmsg: "",
    webmsg: "",
    emailmsg: "",
    googlemsg: "",
    accent_manual: false,
    header_manual: false,
    status_manual: false,
    statusmsg: "",
    accentmsg: "",
    headermsg: "",
    onboarding_url: false,
    urlmsg: "",
    max_bytes_content: false,
    bytemsg: "",
    systest: false,
    systestmsg: "",
    entry_point_url: false,
    hostedType: false,
  });
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handlePreviousOpen = () => {
    setFormStep((cur) => cur - 1);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const cancelReg = () => {
    history.push("/user/datafree");
  };

  const completeFormStep = () => {

    const countnw = ValidateData(data, formStep);
    if (countnw > 0) {
      setFormStep((cur) => cur);
    }
    else {
      setFormStep((cur) => cur + 1);
    }

  };
  const NextFormStep = () => {
    const countnw = ValidateData(data, formStep);
    if (countnw > 0) {
      setFormStep((cur) => cur);
    }
    else {
      setFormStep((cur) => cur + 1);
    }

  };

  const continueFormStep = () => {
    const appId = ResponseData?.applicationId;
    const deployType = ResponseData?.deploymentType;
    if (deployType === "DEV") {
      history.push(`/user/application/${appId}/details`,);
    }

    if (deployType === "PROD") {
      history.push(`/user/application/${appId}/details`,);
    }
  };
  const PreviousRenderBtn = () => {
    if (formStep === 6) {
      return undefined
    }
    else if (formStep === 3) {
      return (
        <button className="regLayoutbtn" onClick={handlePreviousOpen}>
          Pevious
        </button>
      );
    }
    else if (formStep === 4) {
      return (
        <button className="regLayoutbtn" onClick={handlePreviousOpen}>
          Pevious
        </button>
      );
    }
    else if (formStep === 0) {
      return undefined;
    } else {
      return (
        <button className="regLayoutbtn" onClick={handlePreviousOpen}>
          Pevious
        </button>
      );
    }
  };
  const CancelRenderBtn = () => {
    if (formStep === 6) {
      return undefined
    }
    else if (formStep === 3) {
      return (
        <button className="regLayoutbtn" onClick={handleClickOpen}>
          Cancel
        </button>
      );
    }
    else if (formStep === 4) {
      return (
        <button className="regLayoutbtn" onClick={handleClickOpen}>
          Cancel
        </button>
      );
    }
    else {
      return (
        <button className="regLayoutbtn" onClick={handleClickOpen}>
          Cancel
        </button>
      );
    }
  };
  const renderBtn = () => {
    if (formStep === 2) {
      return (
        <button className="regLayoutbtn" onClick={NextFormStep}>
          Next
        </button>
      );
    } else if (formStep === 3) {
      return (
        <button className="regLayoutbtn" onClick={NextFormStep}>
          Next
        </button>
      );

    }
    else if (formStep === 5) {
      return (
        <button className="regLayoutbtn"
          disabled={loaderVisible ? "disabled" : false}
          onClick={Register}>
          Register Application
        </button>
      );
    }
    else if (formStep === 6) {
      return (
        <button className="regLayoutbtn" onClick={continueFormStep}>
          Continue
        </button>
      );
    }
    else if (formStep === 1) {
      return (
        <button className="regLayoutbtn" onClick={NextFormStep}>
          Next
        </button>
      );
    } else {
      return (
        <button className="regLayoutbtn" onClick={completeFormStep}>
          Next
        </button>
      );
    }
  };
  const [GatewayData, setGatewayData] = useState();
  const [ConfigData, setConfigData] = useState();
  async function GatewayDrop() {

    try {
      trackPromise(
        Adminservice.GatewayDrop().then((res) => {

          setGatewayData(res.data?.gatewayDomain);
          setConfigData(res.data?.wrapDefaultValues)
          data.configName = res.data?.wrapDefaultValues?.configName;
          data.description = res.data?.wrapDefaultValues?.description;
          data.package_suffix = res.data?.wrapDefaultValues?.packageSuffix;
          data.distribution_email = res.data?.wrapDefaultValues?.distributionEmail;
          data.email_distribution = res.data?.wrapDefaultValues?.distributionEmail;
        })
      );
    } catch (err) {

    }

  }

  const [data, setData] = useState({
    name: "",
    logo_image: "",
    splash_image: "",
    website_url: "",
    gateway_domain: "",
    max_bytes_content: "",
    configName: "",
    package_suffix: "",
    description: "",
    distribution_email: "",
    platform: "",
    // include_sdks: [],
    googleAppId: "",
    onboarding_url: "",
    use_dark_action_bar_icons: "0",
    systest: "",
    email_distribution: "",
    wrap_form_step: "product-wrap-step6",
    step_no: "6",
    application_type: "",
    appRegisterType: "",
    applicationId: "",
    accent_manual: "",
    header_manual: "",
    status_manual: "",
    status_bar_color: "#303f9f",
    header_color: "#3f51b5",
    accent_color: "#ff4081",
    hostedType: "",
    entry_point_url: "",
  });

  const AppnameReg = /^[^'",]*$/i;
  const webReg = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i');
  const googleReg = /^[a-z][a-z0-9_]*(\.[a-z][a-z0-9_]*)+[0-9a-z_]?$/i;
  const emailReg = /([a-zA-Z0-9_\-\.]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))/;
  const headerReg = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  const statusReg = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  const accentReg = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

  const emailDReg = /^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/i;
  const sysReg = /^((^\*\.)|[a-zA-Z0-9-_])*([.][.a-zA-Z0-9-_]+)$/m;
  const contentReg = /^[0-9]*$/;
  const [warningMsg, setWarningMsg] = useState(false);

  const ValidateData = (data, formStep) => {
    let count = 0;
    const nwerror = { ...error };
    if (formStep === 0) {
      if (data.name === "") {
        nwerror.name = true;
        nwerror.appmsg = "This field is required";
        count++;
      }
      else {
        if (AppnameReg.test(data.name) === false) {
          nwerror.name = true;
          nwerror.appmsg = "Please enter a name without comma";
          count++;
        }
        else {
          nwerror.name = false;
          nwerror.appmsg = "";
        }
      }

      if (error.icon_image === true) {
        nwerror.icon_image = true;
        nwerror.imgmsg = error.imgmsg
        count++;
      }
      else {
        nwerror.icon_image = false;
        nwerror.imgmsg = ""
      }

      if (error.splash_image === true) {
        nwerror.splash_image = true;
        nwerror.splashmsg = error.splashmsg
        count++;
      }
      else {
        nwerror.splash_image = false;
        nwerror.splashmsg = ""
      }
    }

    if (formStep === 1) {
      if (data.name === "") {
        nwerror.name = true;
        nwerror.appmsg = "This field is required";
        count++;
      }
      else {
        if (AppnameReg.test(data.name) === false) {
          nwerror.name = true;
          nwerror.appmsg = "Please enter a name without comma";
          count++;
        }
        else {
          nwerror.name = false;
          nwerror.appmsg = "";
        }
      }

      if (error.icon_image === true) {
        nwerror.icon_image = true;
        nwerror.imgmsg = error.imgmsg
        count++;
      }
      else {
        nwerror.icon_image = false;
        nwerror.imgmsg = ""
      }

      if (error.splash_image === true) {
        nwerror.splash_image = true;
        nwerror.splashmsg = error.splashmsg
        count++;
      }
      else {
        nwerror.splash_image = false;
        nwerror.splashmsg = ""
      }

      if (data.configName === "") {
        nwerror.configName = true;
        count++;
      }
      else {
        nwerror.configName = false;
      }

      if (data.gateway_domain === "") {
        nwerror.gateway_domain = true;
        count++;
      }
      else {
        nwerror.gateway_domain = false;
      }

      if (data.description === "") {
        nwerror.description = true;
        count++;
      }
      else {
        nwerror.description = false;
      }

      if (data.distribution_email === "") {
        nwerror.distribution_email = true;
        nwerror.emailmsg = "This field is required";
        count++;
      }
      else {
        if (emailReg.test(data.distribution_email) === false) {
          nwerror.distribution_email = true;
          nwerror.emailmsg = "You must enter a valid email, or comma separated emails";
          count++;
        }
        else {
          nwerror.distribution_email = false;
          nwerror.emailmsg = "";
        }
      }

      if (data.googleAppId === "") {
        nwerror.googleAppId = false;
        nwerror.googlemsg = "";
      }
      else {
        if (googleReg.test(data.googleAppId) === false) {
          nwerror.googleAppId = true;
          nwerror.googlemsg = "Must have two or more segments separated by a fullstops, all segments starting with a character e.g: 'ABC3.com'";
          count++;
        }
        else {
          nwerror.googleAppId = false;
          nwerror.googlemsg = "";
        }
      }
    }


    if (formStep === 2) {
      if (data.name === "") {
        nwerror.name = true;
        nwerror.appmsg = "This field is required";
        count++;
      }
      else {
        if (AppnameReg.test(data.name) === false) {
          nwerror.name = true;
          nwerror.appmsg = "Please enter a name without comma";
          count++;
        }
        else {
          nwerror.name = false;
          nwerror.appmsg = "";
        }
      }

      if (error.icon_image === true) {
        nwerror.icon_image = true;
        nwerror.imgmsg = error.imgmsg
        count++;
      }
      else {
        nwerror.icon_image = false;
        nwerror.imgmsg = ""
      }

      if (error.splash_image === true) {
        nwerror.splash_image = true;
        nwerror.splashmsg = error.splashmsg
        count++;
      }
      else {
        nwerror.splash_image = false;
        nwerror.splashmsg = ""
      }

      if (data.configName === "") {
        nwerror.configName = true;
        count++;
      }
      else {
        nwerror.configName = false;
      }

      if (data.gateway_domain === "") {
        nwerror.gateway_domain = true;
        count++;
      }
      else {
        nwerror.gateway_domain = false;
      }

      if (data.description === "") {
        nwerror.description = true;
        count++;
      }
      else {
        nwerror.description = false;
      }

      if (data.distribution_email === "") {
        nwerror.distribution_email = true;
        nwerror.emailmsg = "This field is required";
        count++;
      }
      else {
        if (emailReg.test(data.distribution_email) === false) {
          nwerror.distribution_email = true;
          nwerror.emailmsg = "You must enter a valid email, or comma separated emails";
          count++;
        }
        else {
          nwerror.distribution_email = false;
          nwerror.emailmsg = "";
        }
      }

      if (data.googleAppId === "") {
        nwerror.googleAppId = false;
        nwerror.googlemsg = "";
      }
      else {
        if (googleReg.test(data.googleAppId) === false) {
          nwerror.googleAppId = true;
          nwerror.googlemsg = "Must have two or more segments separated by a fullstops, all segments starting with a character e.g: 'ABC3.com'";
          count++;
        }
        else {
          nwerror.googleAppId = false;
          nwerror.googlemsg = "";
        }
      }

      if (data.onboarding_url === "") {
        nwerror.onboarding_url = false;
        nwerror.urlmsg = "";
      }
      else {
        if (webReg.test(data.onboarding_url) === false) {
          nwerror.onboarding_url = true;
          nwerror.urlmsg = "Please enter a valid URL.";
          count++;
        }
        else {
          nwerror.onboarding_url = false;
          nwerror.urlmsg = "";
        }
      }

      if (data.header_manual === "") {
        nwerror.header_manual = false;
        nwerror.headermsg = "";
      }
      else {
        if (headerReg.test(data.header_manual) === false) {
          nwerror.header_manual = true;
          nwerror.headermsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.header_manual = false;
          nwerror.headermsg = "";
          data.header_color = data.header_manual;
        }
      }


      if (data.accent_manual === "") {
        nwerror.accent_manual = false;
        nwerror.accentmsg = "";
      }
      else {
        if (accentReg.test(data.accent_manual) === false) {
          nwerror.accent_manual = true;
          nwerror.accentmsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.accent_manual = false;
          nwerror.accentmsg = "";
          data.accent_color = data.accent_manual;
        }
      }

      if (data.status_manual === "") {
        nwerror.status_manual = false;
        nwerror.statusmsg = "";
      }
      else {
        if (headerReg.test(data.status_manual) === false) {
          nwerror.status_manual = true;
          nwerror.statusmsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.status_manual = false;
          nwerror.statusmsg = "";
          data.status_bar_color = data.status_manual;
        }
      }

    }




    if (formStep === 3) {
      if (data.name === "") {
        nwerror.name = true;
        nwerror.appmsg = "This field is required";
        count++;
      }
      else {
        if (AppnameReg.test(data.name) === false) {
          nwerror.name = true;
          nwerror.appmsg = "Please enter a name without comma";
          count++;
        }
        else {
          nwerror.name = false;
          nwerror.appmsg = "";
        }
      }

      if (error.icon_image === true) {
        nwerror.icon_image = true;
        nwerror.imgmsg = error.imgmsg
        count++;
      }
      else {
        nwerror.icon_image = false;
        nwerror.imgmsg = ""
      }

      if (error.splash_image === true) {
        nwerror.splash_image = true;
        nwerror.splashmsg = error.splashmsg
        count++;
      }
      else {
        nwerror.splash_image = false;
        nwerror.splashmsg = ""
      }

      if (data.configName === "") {
        nwerror.configName = true;
        count++;
      }
      else {
        nwerror.configName = false;
      }

      if (data.gateway_domain === "") {
        nwerror.gateway_domain = true;
        count++;
      }
      else {
        nwerror.gateway_domain = false;
      }

      if (data.description === "") {
        nwerror.description = true;
        count++;
      }
      else {
        nwerror.description = false;
      }

      if (data.distribution_email === "") {
        nwerror.distribution_email = true;
        nwerror.emailmsg = "This field is required";
        count++;
      }
      else {
        if (emailReg.test(data.distribution_email) === false) {
          nwerror.distribution_email = true;
          nwerror.emailmsg = "You must enter a valid email, or comma separated emails";
          count++;
        }
        else {
          nwerror.distribution_email = false;
          nwerror.emailmsg = "";
        }
      }

      if (data.googleAppId === "") {
        nwerror.googleAppId = false;
        nwerror.googlemsg = "";
      }
      else {
        if (googleReg.test(data.googleAppId) === false) {
          nwerror.googleAppId = true;
          nwerror.googlemsg = "Must have two or more segments separated by a fullstops, all segments starting with a character e.g: 'ABC3.com'";
          count++;
        }
        else {
          nwerror.googleAppId = false;
          nwerror.googlemsg = "";
        }
      }

      if (data.onboarding_url === "") {
        nwerror.onboarding_url = false;
        nwerror.urlmsg = "";
      }
      else {
        if (webReg.test(data.onboarding_url) === false) {
          nwerror.onboarding_url = true;
          nwerror.urlmsg = "Please enter a valid URL.";
          count++;
        }
        else {
          nwerror.onboarding_url = false;
          nwerror.urlmsg = "";
        }
      }

      if (data.header_manual === "") {
        nwerror.header_manual = false;
        nwerror.headermsg = "";
      }
      else {
        if (headerReg.test(data.header_manual) === false) {
          nwerror.header_manual = true;
          nwerror.headermsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.header_manual = false;
          nwerror.headermsg = "";
          data.header_color = data.header_manual;
        }
      }


      if (data.accent_manual === "") {
        nwerror.accent_manual = false;
        nwerror.accentmsg = "";
      }
      else {
        if (accentReg.test(data.accent_manual) === false) {
          nwerror.accent_manual = true;
          nwerror.accentmsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.accent_manual = false;
          nwerror.accentmsg = "";
          data.accent_color = data.accent_manual;
        }
      }

      if (data.status_manual === "") {
        nwerror.status_manual = false;
        nwerror.statusmsg = "";
      }
      else {
        if (headerReg.test(data.status_manual) === false) {
          nwerror.status_manual = true;
          nwerror.statusmsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.status_manual = false;
          nwerror.statusmsg = "";
          data.status_bar_color = data.status_manual;
        }
      }

      if (data.max_bytes_content === "") {
        nwerror.max_bytes_content = false;
        nwerror.bytemsg = "";
      }
      else {
        if (contentReg.test(data.max_bytes_content) === false) {
          nwerror.max_bytes_content = true;
          nwerror.bytemsg = "Please enter only digits.";
          count++;
        }
        else {
          nwerror.max_bytes_content = false;
          nwerror.bytemsg = "";
        }
      }



      if (data.systest === "") {
        nwerror.systest = false;
        nwerror.systestmsg = "";
      }
      else {
        if (sysReg.test(data.systest) === false) {
          nwerror.systest = true;
          nwerror.systestmsg = "Please enter a valid whitelist";
          count++;
        }
        else {
          nwerror.systest = false;
          nwerror.systestmsg = "";
        }
      }

    }



    if (formStep === 4) {
      if (data.name === "") {
        nwerror.name = true;
        nwerror.appmsg = "This field is required";
        count++;
      }
      else {
        if (AppnameReg.test(data.name) === false) {
          nwerror.name = true;
          nwerror.appmsg = "Please enter a name without comma";
          count++;
        }
        else {
          nwerror.name = false;
          nwerror.appmsg = "";
        }
      }


      if (error.icon_image === true) {
        nwerror.icon_image = true;
        nwerror.imgmsg = error.imgmsg
        count++;
      }
      else {
        nwerror.icon_image = false;
        nwerror.imgmsg = ""
      }

      if (error.splash_image === true) {
        nwerror.splash_image = true;
        nwerror.splashmsg = error.splashmsg
        count++;
      }
      else {
        nwerror.splash_image = false;
        nwerror.splashmsg = ""
      }

      if (data.configName === "") {
        nwerror.configName = true;
        count++;
      }
      else {
        nwerror.configName = false;
      }

      if (data.gateway_domain === "") {
        nwerror.gateway_domain = true;
        count++;
      }
      else {
        nwerror.gateway_domain = false;
      }

      if (data.description === "") {
        nwerror.description = true;
        count++;
      }
      else {
        nwerror.description = false;
      }

      if (data.distribution_email === "") {
        nwerror.distribution_email = true;
        nwerror.emailmsg = "This field is required";
        count++;
      }
      else {
        if (emailReg.test(data.distribution_email) === false) {
          nwerror.distribution_email = true;
          nwerror.emailmsg = "You must enter a valid email, or comma separated emails";
          count++;
        }
        else {
          nwerror.distribution_email = false;
          nwerror.emailmsg = "";
        }
      }

      if (data.googleAppId === "") {
        nwerror.googleAppId = false;
        nwerror.googlemsg = "";
      }
      else {
        if (googleReg.test(data.googleAppId) === false) {
          nwerror.googleAppId = true;
          nwerror.googlemsg = "Must have two or more segments separated by a fullstops, all segments starting with a character e.g: 'ABC3.com'";
          count++;
        }
        else {
          nwerror.googleAppId = false;
          nwerror.googlemsg = "";
        }
      }

      if (data.onboarding_url === "") {
        nwerror.onboarding_url = false;
        nwerror.urlmsg = "";
      }
      else {
        if (webReg.test(data.onboarding_url) === false) {
          nwerror.onboarding_url = true;
          nwerror.urlmsg = "Please enter a valid URL.";
          count++;
        }
        else {
          nwerror.onboarding_url = false;
          nwerror.urlmsg = "";
        }
      }

      if (data.header_manual === "") {
        nwerror.header_manual = false;
        nwerror.headermsg = "";
      }
      else {
        if (headerReg.test(data.header_manual) === false) {
          nwerror.header_manual = true;
          nwerror.headermsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.header_manual = false;
          nwerror.headermsg = "";
          data.header_color = data.header_manual;
        }
      }


      if (data.accent_manual === "") {
        nwerror.accent_manual = false;
        nwerror.accentmsg = "";
      }
      else {
        if (accentReg.test(data.accent_manual) === false) {
          nwerror.accent_manual = true;
          nwerror.accentmsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.accent_manual = false;
          nwerror.accentmsg = "";
          data.accent_color = data.accent_manual;
        }
      }

      if (data.status_manual === "") {
        nwerror.status_manual = false;
        nwerror.statusmsg = "";
      }
      else {
        if (headerReg.test(data.status_manual) === false) {
          nwerror.status_manual = true;
          nwerror.statusmsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.status_manual = false;
          nwerror.statusmsg = "";
          data.status_bar_color = data.status_manual;
        }
      }

      if (data.max_bytes_content === "") {
        nwerror.max_bytes_content = false;
        nwerror.bytemsg = "";
      }
      else {
        if (contentReg.test(data.max_bytes_content) === false) {
          nwerror.max_bytes_content = true;
          nwerror.bytemsg = "Please enter only digits.";
          count++;
        }
        else {
          nwerror.max_bytes_content = false;
          nwerror.bytemsg = "";
        }
      }

      if (data.systest === "") {
        nwerror.systest = false;
        nwerror.systestmsg = "";
      }
      else {
        if (sysReg.test(data.systest) === false) {
          nwerror.systest = true;
          nwerror.systestmsg = "Please enter a valid whitelist";
          count++;
        }
        else {
          nwerror.systest = false;
          nwerror.systestmsg = "";
        }
      }

      if (data.hostedType === "") {
        nwerror.hostedType = true;
        count++;
      }
      else {
        nwerror.hostedType = false;
      }
      if (data.entry_point_url === "") {
        if (radioBtn) {
          nwerror.entry_point_url = true;
          nwerror.webmsg = "This field is required";
          count++;
        }
        else {
          nwerror.entry_point_url = false;
          nwerror.webmsg = "";
        }
      }
      else {
        if (webReg.test(data.entry_point_url) === false) {
          nwerror.entry_point_url = true;
          nwerror.webmsg = "Please enter a valid URL.";
          count++;
        }
        else {
          nwerror.entry_point_url = false;
          nwerror.webmsg = "";
        }
      }

    }


    if (formStep === 5) {
      if (data.name === "") {
        nwerror.name = true;
        nwerror.appmsg = "This field is required";
        count++;
      }
      else {
        if (AppnameReg.test(data.name) === false) {
          nwerror.name = true;
          nwerror.appmsg = "Please enter a name without comma";
          count++;
        }
        else {
          nwerror.name = false;
          nwerror.appmsg = "";
        }
      }


      if (error.icon_image === true) {
        nwerror.icon_image = true;
        nwerror.imgmsg = error.imgmsg
        count++;
      }
      else {
        nwerror.icon_image = false;
        nwerror.imgmsg = ""
      }

      if (error.splash_image === true) {
        nwerror.splash_image = true;
        nwerror.splashmsg = error.splashmsg
        count++;
      }
      else {
        nwerror.splash_image = false;
        nwerror.splashmsg = ""
      }

      if (data.configName === "") {
        nwerror.configName = true;
        count++;
      }
      else {
        nwerror.configName = false;
      }

      if (data.gateway_domain === "") {
        nwerror.gateway_domain = true;
        count++;
      }
      else {
        nwerror.gateway_domain = false;
      }

      if (data.description === "") {
        nwerror.description = true;
        count++;
      }
      else {
        nwerror.description = false;
      }

      if (data.distribution_email === "") {
        nwerror.distribution_email = true;
        nwerror.emailmsg = "This field is required";
        count++;
      }
      else {
        if (emailReg.test(data.distribution_email) === false) {
          nwerror.distribution_email = true;
          nwerror.emailmsg = "You must enter a valid email, or comma separated emails";
          count++;
        }
        else {
          nwerror.distribution_email = false;
          nwerror.emailmsg = "";
        }
      }

      if (data.googleAppId === "") {
        nwerror.googleAppId = false;
        nwerror.googlemsg = "";
      }
      else {
        if (googleReg.test(data.googleAppId) === false) {
          nwerror.googleAppId = true;
          nwerror.googlemsg = "Must have two or more segments separated by a fullstops, all segments starting with a character e.g: 'ABC3.com'";
          count++;
        }
        else {
          nwerror.googleAppId = false;
          nwerror.googlemsg = "";
        }
      }

      if (data.onboarding_url === "") {
        nwerror.onboarding_url = false;
        nwerror.urlmsg = "";
      }
      else {
        if (webReg.test(data.onboarding_url) === false) {
          nwerror.onboarding_url = true;
          nwerror.urlmsg = "Please enter a valid URL.";
          count++;
        }
        else {
          nwerror.onboarding_url = false;
          nwerror.urlmsg = "";
        }
      }

      if (data.header_manual === "") {
        nwerror.header_manual = false;
        nwerror.headermsg = "";
      }
      else {
        if (headerReg.test(data.header_manual) === false) {
          nwerror.header_manual = true;
          nwerror.headermsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.header_manual = false;
          nwerror.headermsg = "";
          data.header_color = data.header_manual;
        }
      }


      if (data.accent_manual === "") {
        nwerror.accent_manual = false;
        nwerror.accentmsg = "";
      }
      else {
        if (accentReg.test(data.accent_manual) === false) {
          nwerror.accent_manual = true;
          nwerror.accentmsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.accent_manual = false;
          nwerror.accentmsg = "";
          data.accent_color = data.accent_manual;
        }
      }

      if (data.status_manual === "") {
        nwerror.status_manual = false;
        nwerror.statusmsg = "";
      }
      else {
        if (headerReg.test(data.status_manual) === false) {
          nwerror.status_manual = true;
          nwerror.statusmsg = "Please enter a valid color";
          count++;
        }
        else {
          nwerror.status_manual = false;
          nwerror.statusmsg = "";
          data.status_bar_color = data.status_manual;
        }
      }

      if (data.max_bytes_content === "") {
        nwerror.max_bytes_content = false;
        nwerror.bytemsg = "";
      }
      else {
        if (contentReg.test(data.max_bytes_content) === false) {
          nwerror.max_bytes_content = true;
          nwerror.bytemsg = "Please enter only digits.";
          count++;
        }
        else {
          nwerror.max_bytes_content = false;
          nwerror.bytemsg = "";
        }
      }



      if (data.systest === "") {
        nwerror.systest = false;
        nwerror.systestmsg = "";
      }
      else {
        if (sysReg.test(data.systest) === false) {
          nwerror.systest = true;
          nwerror.systestmsg = "Please enter a valid whitelist";
          count++;
        }
        else {
          nwerror.systest = false;
          nwerror.systestmsg = "";
        }
      }

      if (data.email_distribution === "") {
        if (generateApk) {
          nwerror.email_distribution = true;
          nwerror.demailmsg = "This field is required";
          count++;
        }
        else {
          nwerror.email_distribution = false;
          nwerror.demailmsg = "";
        }

      }
      else {
        if (emailDReg.test(data.email_distribution) === false) {
          nwerror.email_distribution = true;
          nwerror.demailmsg = "Please enter a valid EmailDistribution.";
          count++;
        }
        else {
          nwerror.email_distribution = false;
          nwerror.demailmsg = "";
        }
      }

      if (data.hostedType === "") {
        nwerror.hostedType = true;
        count++;
      }
      else {
        nwerror.hostedType = false;
      }

      if (data.entry_point_url === "") {
        if (radioBtn) {
          nwerror.entry_point_url = true;
          nwerror.webmsg = "This field is required";
          count++;
        }
        else {
          nwerror.entry_point_url = false;
          nwerror.webmsg = "";
        }
      }
      else {
        if (webReg.test(data.entry_point_url) === false) {
          nwerror.entry_point_url = true;
          nwerror.webmsg = "Please enter a valid URL.";
          count++;
        }
        else {
          nwerror.entry_point_url = false;
          nwerror.webmsg = "";
        }
      }

    }
    setError(nwerror);
    return count;
  }
  function handleChange(e) {
    setError(false);
    const newdata = { ...data };
    newdata['appRegisterType'] = props.appRegisterType;
    newdata['application_type'] = props.application_type
    newdata[e.target.id] = e.target.value;

    if (e.target.id === "name") {
      if (e.target.value) {
        if (AppnameReg.test(e.target.value) === false) {
          setError({
            ...error,
            name: true,
            appmsg: "Please enter a name without comma"
          });
        }
        else {
          setError({
            ...error,
            name: false,
            appmsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          name: true,
          appmsg: "This field is required."
        });
      }
    }

    if (e.target.id === "website_url") {
      if (e.target.value) {
        if (webReg.test(e.target.value) === false) {
          setError({
            ...error,
            website_url: true,
            webmsg: "Please enter a valid URL."
          });
        }
        else {
          setError({
            ...error,
            website_url: false,
            webmsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          website_url: true,
          webmsg: "This field is required."
        });
      }
    }

    if (e.target.id === "icon_image") {
      if (e.target.files.length !== 0) {
        var img = document.createElement("img");
        img.src = URL.createObjectURL(e.target.files[0]);
        var filePath = e.target.files[0].name;

        if (!allowedExtensions.exec(filePath)) {
          setIcon({
            preview: "",
            raw: "",
          });
          setError({
            ...error,
            icon_image: true,
            imgmsg: "Selected file is not an image"
          });
        }
        else {
          img.onload = function () {
            if (img.width >= 120 && img.height >= 120) {

              setIcon({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],

              });
              setError({
                ...error,
                icon_image: false,
                imgmsg: ""
              });
              UploadIconImage(e.target.files[0]);
            }
            else if (img.width <= 120) {
              setIcon({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                icon_image: true,
                imgmsg: "Minimum width must be 120px"
              });
            }
            else if (img.height <= 120) {
              setIcon({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                icon_image: true,
                imgmsg: "Minimum height must be 120px"
              });
            }
          };
        }
      }
      else {
        setIcon({
          preview: "",
          raw: "",
        });
        setError({
          ...error,
          icon_image: false,
          imgmsg: ""
        });

      }
    }


    if (e.target.id === "splash_image") {
      if (e.target.files.length !== 0) {
        var img2 = document.createElement("img");
        img2.src = URL.createObjectURL(e.target.files[0]);
        var filePath2 = e.target.files[0].name;

        if (!allowedExtensions.exec(filePath2)) {
          setSplash({
            preview: "",
            raw: "",
          });
          setError({
            ...error,
            splash_image: true,
            splashmsg: "Selected file is not an image"
          });
        }
        else {
          img2.onload = function () {
            if (img2.width >= 1080 && img2.height >= 1920) {

              setSplash({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],

              });
              setError({
                ...error,
                splash_image: false,
                splashmsg: ""
              });
              UploadSplashImage(e.target.files[0]);
            }
            else if (img2.width <= 1080) {
              setSplash({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                splash_image: true,
                splashmsg: "Minimum width must be 1080px"
              });
            }
            else if (img2.height <= 120) {
              setSplash({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                splash_image: true,
                splashmsg: "Minimum height must be 1920px"
              });
            }
          };
        }
      }
      else {
        setSplash({
          preview: "",
          raw: "",
        });
        setError({
          ...error,
          splash_image: false,
          splashmsg: ""
        });

      }
    }

    if (e.target.id === "googleAppId") {
      if (e.target.value) {
        if (googleReg.test(e.target.value) === false) {
          setError({
            ...error,
            googleAppId: true,
            googlemsg: "Must have two or more segments separated by a fullstops, all segments starting with a character e.g: 'ABC3.com'"
          });
        }
        else {
          setError({
            ...error,
            googleAppId: false,
            googlemsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          googleAppId: false,
          googlemsg: ""
        });
      }
    }

    if (e.target.id === "distribution_email") {
      if (e.target.value) {
        if (emailReg.test(e.target.value) === false) {
          setError({
            ...error,
            distribution_email: true,
            emailmsg: "You must enter a valid email, or comma separated emails"
          });
        }
        else {
          setError({
            ...error,
            distribution_email: false,
            emailmsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          distribution_email: true,
          emailmsg: "This field is required"
        });
      }
    }


    if (e.target.id === "configName") {
      if (e.target.value) {

        setError({
          ...error,
          configName: false,
        });

      }
      else {
        setError({
          ...error,
          configName: true,
        });
      }
    }

    if (e.target.id === "gateway_domain") {
      if (e.target.value) {

        setError({
          ...error,
          gateway_domain: false,
        });

      }
      else {
        setError({
          ...error,
          gateway_domain: true,
        });
      }
    }

    if (e.target.id === "description") {
      if (e.target.value) {

        setError({
          ...error,
          description: false,
        });

      }
      else {
        setError({
          ...error,
          description: true,
        });
      }
    }

    if (e.target.id === "header_manual") {
      if (e.target.value) {
        if (headerReg.test(e.target.value) === false) {
          setError({
            ...error,
            header_manual: true,
            headermsg: "Please enter a valid color"
          });
        }
        else {
          setError({
            ...error,
            header_manual: false,
            headermsg: ""
          });
        }
      }
      else {

        setError({
          ...error,
          header_manual: false,
          headermsg: ""
        });
      }
    }

    if (e.target.id === "status_manual") {
      if (e.target.value) {
        if (statusReg.test(e.target.value) === false) {
          setError({
            ...error,
            status_manual: true,
            statusmsg: "Please enter a valid color"
          });
        }
        else {
          setError({
            ...error,
            status_manual: false,
            statusmsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          status_manual: false,
          statusmsg: ""
        });
      }
    }

    if (e.target.id === "accent_manual") {
      if (e.target.value) {
        if (accentReg.test(e.target.value) === false) {
          setError({
            ...error,
            accent_manual: true,
            accentmsg: "Please enter a valid color"
          });
        }
        else {
          setError({
            ...error,
            accent_manual: false,
            accentmsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          accent_manual: false,
          accentmsg: ""
        });
      }
    }

    if (e.target.id === "onboarding_url") {
      if (e.target.value) {
        if (webReg.test(e.target.value) === false) {
          setError({
            ...error,
            onboarding_url: true,
            urlmsg: "Please enter a valid URL."
          });
        }
        else {
          setError({
            ...error,
            onboarding_url: false,
            urlmsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          onboarding_url: false,
          urlmsg: ""
        });
      }
    }

    if (e.target.id === "max_bytes_content") {
      if (e.target.value) {
        if (contentReg.test(e.target.value) === false) {
          setError({
            ...error,
            max_bytes_content: true,
            bytemsg: "Please enter only digits."
          });
        }
        else {
          setError({
            ...error,
            max_bytes_content: false,
            bytemsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          max_bytes_content: false,
          bytemsg: ""
        });
      }
    }

    if (e.target.id === "systest") {
      if (e.target.value) {
        if (sysReg.test(e.target.value) === false) {
          setError({
            ...error,
            systest: true,
            systestmsg: "Please enter a valid whitelist."
          });
        }
        else {
          setError({
            ...error,
            systest: false,
            bytemsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          systest: false,
          bytemsg: ""
        });
      }
    }

    if ((e.target.id === "entry_point_url")) {
      if (e.target.value) {
        if (webReg.test(e.target.value) === false) {
          setError({
            ...error,
            entry_point_url: true,
            webmsg: "Please enter a valid URL."
          });
        }
        else {

          if (e.target.value.indexOf("https://") === 0 === false) {
            setError({
              ...error,
              entry_point_url: true,
              webmsg: ""
            });
            setWarningMsg(true)
          }
          else {
            setError({
              ...error,
              entry_point_url: false,
              webmsg: ""
            });
          }

        }
      }
      else {
        setError({
          ...error,
          entry_point_url: true,
          webmsg: "This field is required"
        });
      }
    }

    if (e.target.id === "email_distribution") {
      if (e.target.value) {
        if (emailDReg.test(e.target.value) === false) {
          setError({
            ...error,
            email_distribution: true,
            demailmsg: "Please enter a valid EmailDistribution."
          });
        }
        else {
          setError({
            ...error,
            email_distribution: false,
            demailmsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          email_distribution: true,
          demailmsg: "This field is required"
        });
      }
    }
    setData(newdata);
  }
  const [IconImage, setIconImage] = useState({
    icon_image: ""
  })
  const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
  function UploadIconDefault() {


    fetch(defaultIcon)
      .then((res) => res.blob())
      .then((myBlob) => {
        myBlob.name = "datafree_defaulticon.png";
        myBlob.lastModified = new Date();
        const myFile = new File([myBlob], "datafree_defaulticon.png", {
          type: myBlob.type,
        });

        let values = myFile !== undefined ? new FormData() : "";
        if (myFile !== undefined) {
          values.append("icon_image", myFile ? myFile : "");
        }
        trackPromise(
          Adminservice.uploadIcon(values).then((res) => {

            setIconImage({
              icon_image: res.data,
            })

          }).catch((error) => {
            setErrMsg("Something went wrong. Please reload the page!");
            seterrorOpen(true);
          })

        );
      });

  }

  async function UploadIconImage(imageVal) {
    let file = imageVal;

    let values = file !== undefined ? new FormData() : "";
    if (file !== undefined) {
      values.append("icon_image", file ? file : "");
    }


    trackPromise(
      Adminservice.uploadIcon(values).then((res) => {

        setIconImage({
          icon_image: res.data,
        })
      }).catch((error) => {
        setErrMsg("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      })

    );
  }

  const [SplashImage, setSplashImage] = useState({
    splash_image: ""
  })

  function UploadSplashDefault() {

    fetch(splashImage)
      .then((res) => res.blob())
      .then((myBlob) => {
        myBlob.name = "default-app-splash.jpg";
        myBlob.lastModified = new Date();
        const myFile = new File([myBlob], "default-app-splash.jpg", {
          type: myBlob.type,
        });

        let values = myFile !== undefined ? new FormData() : "";
        if (myFile !== undefined) {
          values.append("splash_image", myFile ? myFile : "");
        }

        trackPromise(
          Adminservice.uploadSplash(values).then((res) => {

            setSplashImage({
              splash_image: res.data,

            })
          }).catch((error) => {
            setErrMsg("Something went wrong. Please reload the page!");
            seterrorOpen(true);
          })

        );
      });

  }

  async function UploadSplashImage(imageVal) {
    let file = imageVal;

    let values = file !== undefined ? new FormData() : "";
    if (file !== undefined) {
      values.append("splash_image", file ? file : "");
    }


    trackPromise(
      Adminservice.uploadSplash(values).then((res) => {

        setSplashImage({
          splash_image: res.data,
        })
      }).catch((error) => {
        setErrMsg("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      })

    );


  }
  const [pops] = useState({
    appDeploymentType: "DEV",
    productId: "1"
  });
  const [platformData, setPlatformData] = useState();
  const [loaderVisible, setLoaderVisible] = useState(false);
  const popUp = () => {
     
        Adminservice.popUp(pops).then((res) => {
          setPlatformData(res.data?.VersionClientAddPopUps)
          res.data?.VersionClientAddPopUps.map(val => {
            if (val.selected === "yes") {
              data.platform = val.id;
            }
          })
        })
     
  }

  const Register = () => {
    setLoaderVisible(true);
    const nextAllow = ValidateData(data, formStep)
    if (appCheck) {
      data["application_options"] = "yes"
    }
    else {
      data["application_options"] = ""
    }

    if (generateApk) {
      data["generate_apk"] = "yes"
    }

    if (whiteList) {
      data["use_test_whitelist"] = "on"
    }

    if (colorchecked) {
      data["sbColor"] = "on"
    }

    if (actioncolorchecked) {
      data["hColor"] = "on"
    }

    if (accentcolorchecked) {
      data["accentColor"] = "on"
    }

    if (FirstPermission) {
      data["location_permission"] = 1
    }

    if (SecondPermission) {
      data["storage_permission"] = 1
    }

    if (nextAllow === 0) {
      data["icon_image"] = IconImage.icon_image;
      data["splash_image"] = SplashImage.splash_image;

      try {
        trackPromise(
          service.RegisterForm(data).then((res) => {
            setLoaderVisible(false);
            setResponseData(res.data)
            setFormStep((cur) => cur + 1);
          })
        );
      } catch (err) {
        setErrMsg("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      }
    }

  };
  const [radioBtn, setRadioBtn] = useState(false)
  const handleRadio = (e) => {

    const newdata = { ...data }
    newdata[e.target.name] = e.target.value;
    if (e.target.id === "hostType1") {
      setRadioBtn(false)
      if (e.target.value) {
        setError({
          ...error,
          hostedType: false
        })
      }
      else {
        setError({
          ...error,
          hostedType: true
        })
      }
    }

    if (e.target.id === "hostType2") {
      setRadioBtn(true)
      if (e.target.value) {
        setError({
          ...error,
          hostedType: false
        })
      }
      else {
        setError({
          ...error,
          hostedType: true
        })
      }
    }

    setData(newdata);

  }

  const handleCheck = (e) => {

    if (data?.include_sdks?.length === undefined) {
      setData({ ...data, include_sdks: [] })
    }

    if (e.target.id === "include_sdks1") {
      setFirstCheckbox(!firstCheckbox)
      if (e.target.checked === true) {
        setData((prevState) => ({
          ...prevState,
          include_sdks: [...prevState.include_sdks, { include_sdks: e.target.value }],
        }))
      }
      else {
        var index = data.include_sdks.map(x => {
          return x.include_sdks
        }).indexOf(e.target.value);

        const list = [...data.include_sdks]
        list.splice(index, 1);
        setData({ ...data, include_sdks: list })
      }

    }

    if (e.target.id === "include_sdks2") {
      setSecondCheckbox(!secondCheckbox)
      if (e.target.checked === true) {
        setData((prevState) => ({
          ...prevState,
          include_sdks: [...prevState.include_sdks, { include_sdks: e.target.value }],
        }))
      }
      else {
        let index = data.include_sdks.map(x => {
          return x.include_sdks
        }).indexOf(e.target.value);

        const list = [...data.include_sdks]
        list.splice(index, 1);
        setData({ ...data, include_sdks: list })
      }
    }

  }
  useEffect(() => {
    UploadIconDefault();
    UploadSplashDefault();
    popUp();
    GatewayDrop();
  }, [])
  return (
    <div>
      <div className="publishStepsMain">
        {formStep === 0 && (
          <section>
            <Grid container spacing={1}>
              <Grid item xs={11}>
                <div className="registerFormTitle">
                  Step 1) Register Application
                </div>
                <div>
                  <input
                    type="text"
                    className={
                      !error.name ? "registerInput" : "registerInputerr"
                    }
                    placeholder="Enter the name of the Application you wish to Register and publish #datafree"
                    name="name"
                    id="name"
                    onChange={handleChange}
                    defaultValue={data.name}
                  />
                  {error.name ? (
                    <span className="errormsg">{error.appmsg}</span>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="registerFormTitle">Options</div>
                <div>
                  <input
                    type="checkbox"
                    name="application_options"
                    id="application_options"
                    onChange={() => setAppCheck(!appCheck)}
                    checked={appCheck}
                  />
                  <label for="websiteName" className="maxRegisterForm">
                    Application Options
                  </label>
                  <img src={applicationIcon} className="appImage" alt=""></img>
                </div>
              </Grid>
              <Grid item xs={10}></Grid>
              <Grid item xs={12}>
                {appCheck ? (
                  <div className="registerIconUpload">
                    <div className="rowClass loadimg">
                      <img src={icon.preview ? icon.preview : ""} alt="" className="imgdefaultIcon"></img>
                    </div>
                    <div className="registerIconBox">
                      <div className="IconText1">Upload an icon image</div>
                      <div className="IconText2">
                        This is used within the Publisher Console as a visual
                        prompter only
                      </div>
                      <div className="IconText3">
                        (Min. dimensions of 120px X 120px)
                      </div>
                      {error.icon_image ? (
                        <p style={{ float: 'left' }} className="errorClass">{error.imgmsg}</p>
                      ) : (
                        ""
                      )}
                      <div className="file-upload">
                        <div className="file-select">
                          <div className="file-select-button" id="fileName" defaultValue={data.icon_image}>
                            Choose File
                          </div>
                          <div className="file-select-name" id="noFile">
                            default-app-icon.png
                          </div>
                          <input
                            type="file"
                            name="icon_image"
                            id="icon_image"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </Grid>
              <Grid item xs={12}>
                {appCheck ? (
                  <div className="registerIconUpload">
                    <div className="rowClass loadimg">
                      <img src={splash.preview ? splash.preview : ""} alt="" className="imgdefaultIcon"></img>
                    </div>
                    <div className="registerIconBox">
                      <div className="IconText1">Upload a splash image</div>
                      <div className="IconText2">
                        This is used within the App as the initial screen
                        displayed at App startup
                      </div>
                      <div className="IconText3">
                        (Min. dimensions of 1080px X 1920px)
                      </div>
                      {error.splash_image ? (
                        <p style={{ float: 'left' }} className="errorClass">{error.splashmsg}</p>
                      ) : (
                        ""
                      )}
                      <div className="file-upload">
                        <div className="file-select">
                          <div className="file-select-button" id="fileName" defaultValue={data.splash_image}>
                            Choose File
                          </div>
                          <div className="file-select-name" id="noFile">
                            default-app-icon.png
                          </div>
                          <input
                            type="file"
                            name="splash_image"
                            id="splash_image"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </section>
        )}
        {/* //step2 */}
        {formStep === 1 && (
          <section>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                <div className="registerFormTitle">
                  Step 2) Modify App configuration options as desired -
                  OPTIONAL, you can edit these later through the Publication
                  Designer
                </div>
              </Grid>

              <Grid item xs={5}>
                <div className="registerFormTitle" style={{ marginTop: "5px" }}>
                  Name
                </div>
                <div>
                  <input
                    type="text"
                    className={
                      !error.configName ? "registerInput" : "registerInputerr"
                    }

                    name="configName"
                    required
                    id="configName"
                    onChange={handleChange}
                    defaultValue={data.configName}
                  />
                  {error.configName ? (
                    <span className="errormsg">This filed is required</span>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <div className="registerFormTitle" style={{ marginTop: "5px" }}>
                  Package Suffix
                </div>
                <div>
                  <input
                    type="text"
                    className="registerInput"
                    name="package_suffix"
                    required
                    id="package_suffix"
                    onChange={handleChange}
                    defaultValue={data.package_suffix}
                  />
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className="registerFormTitle" style={{ marginTop: "5px" }}>
                  Description
                </div>
                <div>
                  <input
                    type="text"
                    className={
                      !error.description ? "registerInput" : "registerInputerr"
                    }

                    name="description"
                    required
                    id="description"
                    onChange={handleChange}
                    defaultValue={data.description + " " + data.name}
                  />
                  {error.description ? (
                    <span className="errormsg">This field is required</span>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <label for="websiteName" className="labelMax">
                  A link to the generated APK will be emailed to any valid addresses noted in the Email Distribution List
                </label>
                <div className="registerFormTitle" style={{ marginTop: "5px" }}>
                  Distribution Email
                </div>
                <div>
                  <input
                    type="text"
                    className={
                      !error.distribution_email ? "registerInput" : "registerInputerr"
                    }

                    name="distribution_email"
                    required
                    id="distribution_email"
                    onChange={handleChange}
                    defaultValue={data.distribution_email}
                  />
                  {error.distribution_email ? (
                    <span className="errormsg">{error.emailmsg}</span>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className="registerFormTitle" style={{ marginTop: "5px" }}>
                  biNu Platform
                </div>
                <div>
                  <select
                    className="registerInput"
                    required
                    id="platform"
                    name="platform"
                    onChange={handleChange}
                    style={{ width: '108%' }}
                  >
                    <option> </option>
                    {platformData && platformData?.length > 0
                      ? platformData.map((post) => (
                        <option key={post.id} value={post.id} selected={post.selected === "yes" && true}>
                          {post.name}
                        </option>
                      ))
                      : null}
                  </select>
                </div>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <div className="registerFormTitle">Include SDKs</div>
                <div>
                  <input
                    type="checkbox"
                    name="include_sdks"
                    id="include_sdks1"
                    value="EMTRACKER_SDK"
                    checked={firstCheckbox}
                    onChange={handleCheck}
                  />
                  <label for="include_sdks" className="optiontext">
                    Effective Measure Tracker
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="include_sdks"
                    id="include_sdks2"
                    value="VIDEO_SDK"
                    checked={secondCheckbox}
                    onChange={handleCheck}

                  /> <label for="include_sdks" className="optiontext">
                    Video Advertising
                  </label>
                </div>

              </Grid>
              <Grid item xs={5}>
                <div className="registerFormTitle" style={{ marginTop: "5px", paddingBottom: '5px' }}>
                  Google App Id
                </div>
                <label for="websiteName" className="labelMax">
                  If entered, cannot be changed. If you are unsure leave blank for now
                </label>
                <div>
                  <input
                    type="text"
                    style={{ marginTop: '7px' }}
                    className={
                      !error.googleAppId ? "registerInput" : "registerInputerr"
                    }
                    placeholder="The name that your App will be known by within the Google Playstore"
                    name="googleAppId"
                    required
                    id="googleAppId"
                    onChange={handleChange}
                    value={data.googleAppId}
                  />
                  {error.googleAppId ? (
                    <span className="errormsg">{error.googlemsg}</span>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <div className="registerFormTitle">App Permissions</div>
                <div>
                  <input
                    type="checkbox"
                    name="location_permission"
                    id="location_permission"
                    checked={FirstPermission}
                    onChange={() => setFirstPermission(!FirstPermission)}
                    value="1"
                  /> <label for="storage_permission" className="optiontext">
                    Allow App to request Location Service Permission on install
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="storage_permission"
                    id="storage_permission"
                    value="1"
                    checked={SecondPermission}
                    onChange={() => setSecondPermission(!SecondPermission)}
                  /> <label for="storage_permission" className="optiontext">
                    Allow App to request Storage Permission on install
                  </label>
                </div>


              </Grid>
              <Grid item xs={5}>
                <div className="registerFormTitle">
                  Choose your #datafree Gateway domain
                </div>
                <div>
                  <select
                    className={
                      !error.gateway_domain
                        ? "registerInput"
                        : "registerInputerr"
                    }
                    required
                    id="gateway_domain"
                    name="gateway_domain"
                    defaultValue={data.gateway_domain}
                    onChange={handleChange}
                    style={{ width: '108%' }}
                  >
                    <option value=""> </option>
                    {GatewayData && GatewayData?.length > 0
                      ? GatewayData.map((post) => (
                        <option key={post.gateway} value={post.gateway}>
                          {post.gateway}
                        </option>
                      ))
                      : null}
                  </select>
                  {error.gateway_domain ? (
                    <span className="errormsg">This field is required</span>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}></Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </section>
        )}
        {formStep === 2 && (
          <section>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div className="registerFormTitle">
                  Step 3) Modify App look and feel options as desired -
                  OPTIONAL, you can edit these later through the Publication
                  Designer
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="registerFormTitle">Onboarding URL</div>
                <div>
                  <input
                    type="text"
                    className={!error.onboarding_url ? "registerInput" : "registerInputerr"}
                    placeholder=""
                    name="onboarding_url"
                    id="onboarding_url"
                    onChange={handleChange}
                    defaultValue={data.onboarding_url}
                  />
                  {error.onboarding_url ? (
                    <span className="errormsg">{error.urlmsg}</span>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={12}></Grid>
              {colorchecked ?
                <Grid item xs={6}>
                  <div className="registerFormTitle">Status Bar Colour</div>
                  <div>
                    <input
                      type="text"
                      className={!error.status_manual ? "registerInput" : "registerInputerr"}
                      placeholder=""
                      name="status_manual"
                      id="status_manual"
                      onChange={handleChange}
                      defaultValue={data.status_manual}
                    />
                    {error.status_manual ? (
                      <span style={{ float: 'left' }} className="errormsg">{error.statusmsg}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid> : null}
              <Grid item xs={12}></Grid>
              <div style={{ display: "flex" }}>
                {!colorchecked ? <div className="registerFormTitle">Status Bar Colour</div> : ""}

                {!colorchecked ? <div className="colorPickerDiv">
                  <input
                    type="color"
                    className="colorBox"
                    style={{ cursor: "pointer" }}
                    name="status_bar_color"
                    id="status_bar_color"
                    onChange={handleChange}
                    defaultValue={data.status_bar_color}

                  />
                  <input
                    type="text"
                    className="colorInput"
                    disabled="disabled"
                    defaultValue={data.status_bar_color}
                  />
                </div> : ""}
                <div className="spanFormTitle">Click here to Enter Manually</div>
                <div>
                  <input
                    type="checkbox"
                    className="spancheck"
                    name="websiteName"
                    id="websiteName"
                    onChange={() => setColorchecked(!colorchecked)}
                    checked={colorchecked}
                  />
                </div>

              </div>
              <Grid item xs={6}></Grid>
              <Grid item xs={12}></Grid>
              {actioncolorchecked ?
                <Grid item xs={6}>
                  <div className="registerFormTitle">Action Bar Colour</div>
                  <div>
                    <input
                      type="text"
                      className={!error.header_manual ? "registerInput" : "registerInputerr"}
                      placeholder=""
                      name="header_manual"
                      id="header_manual"
                      onChange={handleChange}
                      defaultValue={data.header_manual}
                    />
                    {error.header_manual ? (
                      <span style={{ float: 'left' }} className="errormsg">{error.headermsg}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid> : null}
              <Grid item xs={12}></Grid>
              <div style={{ display: "flex" }}>
                {!actioncolorchecked ? <div className="registerFormTitle">Action Bar Colour</div> : ""}

                {!actioncolorchecked ? <div className="colorPickerDiv">
                  <input
                    type="color"
                    className="colorBox"
                    style={{ cursor: "pointer" }}
                    name="header_color"
                    id="header_color"
                    onChange={handleChange}
                    defaultValue={data.header_color}
                  />
                  <input
                    type="text"
                    className="colorInput"
                    disabled="disabled"
                    defaultValue={data.header_color}
                  />
                </div> : ""}
                <div className="spanFormTitle">Click here to Enter Manually</div>
                <div>
                  <input
                    type="checkbox"
                    name="websiteName"
                    className="spancheck"
                    id="websiteName"
                    onChange={() => setActionColorchecked(!actioncolorchecked)}
                    checked={actioncolorchecked}
                  />
                </div>

              </div>

              <Grid item xs={6}></Grid>
              <Grid item xs={12}></Grid>
              {accentcolorchecked ?
                <Grid item xs={6}>
                  <div className="registerFormTitle">Accent Colour</div>
                  <div>
                    <input
                      type="text"
                      className={!error.accent_manual ? "registerInput" : "registerInputerr"}
                      placeholder=""
                      name="accent_manual"
                      id="accent_manual"
                      defaultValue={data.accent_manual}
                      onChange={handleChange}
                    />
                    {error.accent_manual ? (
                      <span style={{ float: 'left' }} className="errormsg">{error.accentmsg}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid> : null}
              <Grid item xs={12}></Grid>
              <div style={{ display: "flex" }}>

                {!accentcolorchecked ? <div className="registerFormTitle">Accent Colour</div> : ""}

                {!accentcolorchecked ? <div className="colorPickerDiv">
                  <input
                    type="color"
                    className="colorBox"
                    style={{ cursor: "pointer" }}
                    id="accent_color"
                    name="accent_color"
                    defaultValue={data.accent_color}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    className="colorInput"
                    disabled="disabled"
                    defaultValue={data.accent_color}
                  />
                </div> : ""}
                <div className="spanFormTitle">Click here to Enter Manually</div>
                <div>
                  <input
                    type="checkbox"
                    name="websiteName"
                    className="spancheck"
                    id="websiteName"
                    onChange={() => setAccentColorchecked(!accentcolorchecked)}
                    checked={accentcolorchecked}
                  />
                </div> </div>

              <Grid item xs={6}></Grid>

              <Grid item xs={12}></Grid>
              <Grid item xs={6}>
                <div className="registerFormTitle">Use Dark Action Bar Icons</div>
                <div>
                  <select
                    type="text"
                    className="registerInput"
                    placeholder=""
                    name="use_dark_action_bar_icons"
                    id="use_dark_action_bar_icons"
                    onChange={handleChange}
                  ><option value="0">NO</option>
                    <option value="1">YES</option></select>
                </div>
              </Grid>
              <Grid item xs={5}></Grid>
              <Grid item xs={12}></Grid>
            </Grid>


          </section>
        )}

        {formStep === 3 && (
          <section>
            <Grid container spacing={1}>
              <Grid item xs={11}>
                <div className="registerFormTitle" style={{ marginTop: "15px" }}>
                  Step 4) Content Access Details - OPTIONAL, you can define these
                  later through the Publication Designer
                </div>
              </Grid>
            </Grid>
            <Grid item xs={11}>
              <div className="regInLayoutbtns">
                <div className="registerInBox">
                  <div className="registerFormTitle">
                    Max Bytes per content object
                  </div>
                  <div>
                    <input
                      type="text"
                      className={
                        !error.max_bytes_content
                          ? "regFormInput"
                          : "regFormInputerr"
                      }
                      required
                      placeholder="Enter the max size in bytes per content object to be delivered #datafree"
                      name="max_bytes_content"
                      id="max_bytes_content"
                      defaultValue={data.max_bytes_content}
                      onChange={handleChange}
                      style={{ width: '192%' }}
                    />
                    {error.max_bytes_content ? (
                      <span className="errormsg">{error.contentmsg}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="registerInBox">
                  <label className="registerFormTitle">
                    Test Whitelist
                  </label>
                  <div style={{ display: "flex" }}>
                    <div className="registerFormTitle topMax" >Use whitelist</div>

                    <input
                      type="checkbox"
                      name="use_test_whitelist"
                      className="spancheck leftMax"
                      id="use_test_whitelist"
                      value="on"
                      checked={whiteList}
                      onChange={() => setWhiteList(!whiteList)}

                    />
                  </div>
                  <div>
                    <textarea
                      className={
                        !error.systest
                          ? "registerInputTextarea"
                          : "registerInputTextareaerr"
                      }
                      id="systest"
                      name="systest"
                      onBlur={handleChange}
                      defaultValue={data.systest}
                      placeholder="Enter the list of website domains to be delivered #datafree"
                      required
                    ></textarea>

                  </div>
                  {error.systest ? (
                    <span className="errormsg">{error.systestmsg}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

            </Grid>
          </section>
        )}

        {formStep === 4 && (
          <section>
            <Grid item xs={12}>
              <div className="registerFormTitle" style={{ marginTop: "15px" }}>
                Step 5) Modify App entry point structure - OPTIONAL- by default the Entry Point will be created as biNu hosted, you can modify this later through the Publisher Designer
              </div>

            </Grid>
            <Grid item xs={12}>
              <input type="radio" id="hostType1" name="hostedType" className="labelMaxs" value="binuHosted"
                checked={data.hostedType === "binuHosted"}
                onChange={handleRadio} />
              <label for="html" className="labelMaxs">Use biNu Hosted App Entry Point (Template App)</label>
              <span className="labelsMax"> - If you are new to biNu Platform App development this is recommended</span><br></br>
            </Grid>

            <Grid item xs={12}>
              <input type="radio" id="hostType2" name="hostedType" className="labelMaxs" value="selfHosted"
                checked={data.hostedType === "selfHosted"}
                onChange={handleRadio} />
              <label for="html" className="labelMaxs">Use Self Hosted App Entry Point</label>
              <span className="labelsMax"> - You will need to enter the URL of the Entry Point for your App below</span><br></br>
            </Grid>
            {error.hostedType ? (
              <span style={{ float: 'left' }} className="errormsg errorMax">This field is required.</span>
            ) : (
              ""
            )}

            {radioBtn ? (
              <Grid item xs={8}>
                <div style={{ alignItems: "center" }}>
                  <label className="labelMax" >
                    Entry Point URL
                  </label>
                  <input
                    type="text"
                    className={!error.entry_point_url ? "registerMax" : "registerMaxerr"}
                    name="entry_point_url"
                    id="entry_point_url"
                    placeholder=""
                    defaultValue={data.entry_point_url}
                    onBlur={handleChange}

                  />

                </div>
                {error.entry_point_url ? (
                  <span style={{ float: 'left' }} className="errormsg errorMax">{error.webmsg}</span>
                ) : (
                  ""
                )}
              </Grid>) : null}
          </section>
        )}

        {formStep === 5 && (
          <section>
            <Grid item xs={12}>
              <div className="registerFormTitle" style={{ marginTop: "15px" }}>
                Step 6) Generate test APK - OPTIONAL, you can generate the APK
                later through the Publication Designer
              </div>

            </Grid>
            <Grid item xs={4}>
              <div style={{ display: "flex" }}>
                <div >
                  {props.appRegisterType === "PROD" ?
                    <input
                      type="checkbox"
                      name="generate_apk"
                      className="spanchecks"
                      id="generate_apk"
                      disabled="disabled"
                      style={{ cursor: 'not-allowed' }}
                    /> :
                    <input
                      type="checkbox"
                      name="generate_apk"
                      className="spancheck"
                      id="generate_apk"
                      checked={generateApk}
                      onChange={() => setgenerateApk(!generateApk)}
                    />}

                  <label for="websiteName" className="maxRegisterForm">
                    Generate APK on Registration
                    <img src={arrowRotation} className="arrowImage" alt=""></img>
                  </label>

                </div>
              </div>
            </Grid>

            {generateApk ? (
              <Grid item xs={12}>
                <div className="labelsArrow" >A link to the generated APK will be emailed to any valid addresses noted in the Email Distribution List</div>
                <Grid item xs={8}>
                  <div style={{ display: 'flex' }}>
                    <label className="labelsArrow"> Email Distribution List:</label>
                    <input
                      type="text"
                      className={!error.email_distribution ? "registerMax" : "registerMaxerr"}
                      name="email_distribution"
                      id="email_distribution"
                      placeholder="users@email.address"
                      defaultValue={data.email_distribution}
                      onChange={handleChange}
                    />

                  </div>
                  {error.email_distribution ? (
                    <span style={{ float: 'left' }} className="errormsg errorMax">{error.demailmsg}</span>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            ) : null}
            <br></br>

          </section>
        )}
        {formStep === 6 && (
          <section>
            <Grid item xs={12}>
              <div className="registerFormTitle" >
                Registration of your Application has been successful.

              </div>

            </Grid>
            <Grid item xs={12}>
              <div className="registerFormTitleMax" >
                To modify details further, update security rules or generate App, navigate to the #datafree
                Publication List screen and enter the Publication Designer
              </div>

            </Grid>

          </section>
        )}
        <div className="regLayoutbtns">
          <div>{CancelRenderBtn()}</div>
          {
            loaderVisible &&
            <div>
              <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />

            </div>
          }
          <div className="btnsLayout">
            <div>
              {PreviousRenderBtn()}
              {/* ///previous */}
            </div>

            <div>

              {renderBtn()}
              {/* //nect byn */}
            </div>

          </div>
        </div>
      </div>

      <Dialog
        open={open}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Getting started - Register an Application / Website
            <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContentText className="DialogContentText">
          Are you sure do you want to cancel?
        </DialogContentText>
        <DialogActions>
          <button className="inviteButton"
            onClick={cancelReg}
            style={{ width: '46%', marginLeft: '10px' }} color="primary" name="popup">
            Yes
          </button>

          <button className="inviteButton"
            color="primary" style={{ width: '46%' }} onClick={() => setOpen(false)} name="popup">
            No
          </button>
        </DialogActions>
      </Dialog>
      {/* //Warning Popup */}

      <Dialog open={warningMsg} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" name="popup"
        onClick={() => setWarningMsg(false)} aria-labelledby="form-dialog-title">

        <DialogContent>

          <div className="logoConfirmtext">For security purposes it is advised that the given Entry Point URL is an https address.
          </div>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv" style={{ textAlign: 'center' }}>
            <button className="editbtn" name="popup" onClick={() => setWarningMsg(false)} color="primary">
              Continue
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={erroropen}
        autoHideDuration={1500}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {errMsg ? errMsg : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default GettingStartMax;
