import React, { useEffect, useState,useContext } from "react";
import { useHistory } from "react-router-dom";
import applicationIcon from "../../../assets/img/application-icon.png";
import EmptyTable from "../../../Recomponent/EmptyTable";
import ChannelService from "../../../api/channelService";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";
import { usePromiseTracker } from "react-promise-tracker";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  Grid,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {
  ToggleContext
} from "../../context/toggleContext"


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
         <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};
function ApplicationList() {
  const [search, setSearch] = useState(false);
  const [changedropdown, setChangedropdown] = useState(false);
  const [publisherDetails, setPublisherDetails] = useState();
  const toggleContextVal = useContext(ToggleContext)
  const [HealthDetails, setHealthDetails] = useState();
  let history = useHistory();

  const handleRouteLink = (id) => {
    history.push(`/channel/publisher/${id}/detail/view`);
  };
  const [tablecols, setTableCols] = useState([
    
    {
      colName : 'App Name',
      colMap: 'name',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Product Type',
      colMap: 'productIdproductType',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Free Domain',
      colMap: 'reachSubDomain',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Google App Id',
      colMap: 'googleAppId',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Type',
      colMap: 'applicationType',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Dev biNu Id',
      colMap: 'devBinuId',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Prod biNu Id',
      colMap: 'ProdBinuId',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Production Data Usage Mb (28 days)',
      colMap: 'publisherIdpubDataUsageAlert',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Revenue Type',
      colMap: 'revenueType',
      linkName : false,
      cModel : false,
      cRoute : true,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Publisher',
      colMap: 'publisherIdname',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Company Type',
      colMap: 'publisherIdcompanyType',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Status',
      colMap: 'publisherIdstatus',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Production Health Check Required',
      colMap: 'ProductionHealth',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
      
    },
    {
      colName : 'Status',
      colMap: 'status',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
  ])
  const [data, setData] = useState({
    searchDeploymentType: "",
    searchProductType: "",
    searchBinuId: "",
    searchApplicationName: "",
    searchFreeDomain: "",
    searchPublisherName: "",
    channelId: "",
  });
  const [erroropen, seterrorOpen] = useState(false);
  const [errMsg,setErrMsg] =useState();
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const [productType, setProductType] = useState(false);
  async function Channeldata() {
    try {
      trackPromise(
        ChannelService.channelUserRequest().then((res) => {
          setProductType( res.data?.productTypes);
          data["channelId"] = res.data?.userDetails?.companyId?.companyId;
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const [empty,setEmpty] =useState(false);
  const [Revtypes,setRevtypes] =useState();
  async function publisherListdata() {
    data["channelId"]=localStorage.getItem("channelId")
    setSearch(true);
    setChangedropdown(true);
    setEmpty(false);
    setPublisherDetails();
    try {
      trackPromise(
        ChannelService.channelApplicationSearch(data).then((res) => {
          setPublisherDetails(res.data);
          setHealthDetails(res.data?.prodHealthDetails);
          setRevtypes(res.data?.revenueType)
          if(res.status == 200 && res.data.applicationListResult.length > 0){   
            setChangedropdown(false);
          }
          else if(res.status == 200 && res.data.applicationListResult.length== 0){
            setEmpty(true);
            setChangedropdown(false);
           }
          else{
            setChangedropdown(true);
           }
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const lifecycleChange = (e) => {
    e.preventDefault();
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  };

  useEffect(() => {
    Channeldata();
  }, []);

  return (
    <div className={toggleContextVal.event ?"mainMobView" :"mainBoxView"}>
      <div className="NavBoxDiv">
        <div className="NavBoxRow col-lg-6 col-md-6 col-sm-6 col-xs-5">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">Application List - Channel View</div>
        </div>
        <div className="NavBoxRowFilter table-responsive">

        <table className="table-sm">
            <tbody>
              <tr>
                <td className="col-md-2">
                Deployment Type
                </td>
                <td className="col-md-2">
                Product Type
                </td>
                <td className="col-md-1">
                biNu Id
                </td>
                <td className="col-md-2">
                Application Name
                </td>
                <td className="col-md-2">
                Free Domain
                </td>
                <td className="col-md-2">
                Publisher Name
                </td>
                <td className="col-md-2">

                </td>
              </tr>
              <tr>
                <td className="col-md-1">
                <select
                  type="text"
                  className="form-control form-select"
                  id="searchDeploymentType"
                  name="searchDeploymentType"
                  onChange={(e) => lifecycleChange(e)}
                >
               <option value=""></option>
                  <option value="PROD">PROD</option>
                  <option value="DEV">DEV</option>
                </select>
                </td>
                <td className="col-md-2">
                <select
                  type="text"
                  className="form-control form-select"
                  id="searchProductType"
                  name="searchProductType"
                  onChange={(e) => lifecycleChange(e)}
                ><option value=""></option>
                {productType && productType.length > 0 ? (
                            productType.map((post) => (
                              <option
                                key={post.productId}
                                value={post.productId}
                              >
                                {post.productType}
                              </option>
                            ))
                          ) : null}
                </select>
                </td>
                <td className="col-md-1">
                <input
                  type="text"
                  className="form-control"
                  id="searchBinuId"
                  name="searchBinuId"
                  onChange={(e) => lifecycleChange(e)}
                ></input>

                </td>
                <td className="col-md-3">
                <input
                  type="text"
                  className="form-control"
                  id="searchApplicationName"
                  name="searchApplicationName"
                  onChange={(e) => lifecycleChange(e)}
                ></input>
                </td>
                <td className="col-md-1">
                <select
                  type="text"
                  className="form-control form-select"
                  onChange={(e) => lifecycleChange(e)}
                  id="searchFreeDomain"
                  name="searchFreeDomain"
                >
             
                  <option value=""></option>
                  <option value="no">no</option>
                  <option value="yes">yes</option>
                </select>
                </td>
                <td className="col-md-3">
                <select
                  type="text"
                  className="form-control form-select"
                  onChange={(e) => lifecycleChange(e)}
                  id="searchPublisherName"
                  name="searchPublisherName"
                >
                 
                  <option value=""></option>
                  <option value="Active">Active</option>
                 
                </select>
                </td>
                <td className="col-md-1">
                <button
              className={changedropdown ?  "defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"}
              disabled={changedropdown ? "disabled" : false}
              onClick={publisherListdata}>
            
              Search
            </button>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div><hr className="applications-list-hr"></hr></div>
        
        {search ? (
          <div>
            {publisherDetails &&
            publisherDetails?.applicationListResult.length > 0 ? (
              <TableContainer>
                <Table striped bordered className="accouttablestyle" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="tableHead">App Name</TableCell>
                      <TableCell className="tableHead">Product Type</TableCell>
                      <TableCell className="tableHead">Free Domain</TableCell>
                      <TableCell className="tableHead">Google App Id</TableCell>
                      <TableCell className="tableHead">Type</TableCell>
                      <TableCell className="tableHead">Dev biNu Id</TableCell>
                      <TableCell className="tableHead">Prod biNu Id</TableCell>
                      <TableCell className="tableHead">
                        Production Data Usage Mb (28 days)
                      </TableCell>
                      <TableCell className="tableHead">Revenue Type</TableCell>
                      <TableCell className="tableHead">Publisher</TableCell>
                      <TableCell className="tableHead">Company Type</TableCell>
                      <TableCell className="tableHead">Status</TableCell>
                      <TableCell className="tableHead">
                        Production Health Check Required
                      </TableCell>
                      <TableCell className="tableHead">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {publisherDetails.applicationListResult.map(
                      (row, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {row.name ? row.name : ""}
                          </TableCell>
                          <TableCell 
                          >
                            {row.productId.productType
                              ? row.productId.productType
                              : ""}
                          </TableCell>
                          <TableCell>
                            {row.reachSubDomain ? row.reachSubDomain : ""}
                          </TableCell>
                          <TableCell>
                            {row.googleAppId ? row.googleAppId : ""}
                          </TableCell>
                          <TableCell>
                            {row.applicationType ? row.applicationType : ""}
                          </TableCell>
                          <TableCell>
                            {row.applicationId ? row.applicationId : "-"}
                          </TableCell>
                          <TableCell>
                            {row.productId.productId
                              ? row.productId.productId
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {row.appDataUsageAlert
                              ? row.appDataUsageAlert
                              : "0"}
                          </TableCell>
                          <TableCell>
                           {Revtypes?.[index] ? 
                            Revtypes?.[index] ? Revtypes?.[index] : ""
                             : " " }
                          </TableCell>
                          <TableCell
                          style={{
                            textShadow: "none",
                            color: "#337ab7",
                            cursor: "pointer",
                          }}
                          onClick={()=>handleRouteLink(row.publisherId.companyId)}>
                            {row.publisherId.name
                              ? row.publisherId.name
                              : ""}
                          </TableCell>
                          <TableCell>
                            {row.publisherId.companyType
                              ? row.publisherId.companyType
                              : ""}
                          </TableCell>
                          <TableCell>
                            {row.publisherId.status
                              ? row.publisherId.status
                              : ""}
                          </TableCell>
                          <TableCell>
                           {HealthDetails?.[index] ? 
                            <input className="checkboxForm" type="checkbox"
                             style={{cursor:'not-allowed'}} disabled="disabled" 
                             checked={HealthDetails?.[index].prodHealthCheckOn ? HealthDetails?.[index].prodHealthCheckOn : ""}/>
                             : " " }
                             </TableCell>
                          <TableCell>
                            {row.status ? row.status : ""}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
           ) : (
             <div>
            <LoadingIndicator />
            {empty ? <EmptyTable data="No Applications Found" col={tablecols} tableName="Applications List" /> : null}
             </div> 
          )}
          </div>
        ) : null}
      </div>
      <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success">
          {errMsg ? errMsg : "Updated Successfully!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default ApplicationList;
