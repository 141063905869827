import React from "react";
import { Route, Switch } from "react-router-dom";
import ApplicationList from "./accountPages/applications";
import PublisherList from "./accountPages/publisherList";
import UserList from "./accountPages/userList";
import AccountManagerAccount from "./accountPages/account";
import PublisherDetailView from "./accountPages/publisherDetailView";
import Reporting from "./accountPages/reporting";
import NotificationDetails from "../channel/channelPages/notificationDetails";
import UserDetails from "../user/userPages/userDetails";
export default function Admin() {
  return (
    <div>
      <Switch>
        <Route exact path="/accountmanager/applications/index" component={ApplicationList} />
        <Route exact path="/accountmanager/publisher/index" component={PublisherList} />
        <Route exact path="/accountmanager/userlist/index" component={UserList} />
        <Route exact path="/accountmanager/account/details/view" component={AccountManagerAccount} />
        <Route exact path="/accountmanager/publisher/:companyId/detail/view" component={PublisherDetailView} />
        <Route exact path="/accountmanager/notification/details" component={NotificationDetails}/>
        <Route exact path="/accountmanager/reporting/index" component={Reporting}/>
        <Route exact path="/accountmanager/user/details/view" component={UserDetails}/>
      </Switch>
    </div>
  );
}
