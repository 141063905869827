import React, { useState, useEffect,useContext } from "react";
import "./adminStyle.css";
import applicationIcon from "../../../assets/img/application-icon.png";
import { useHistory } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";
import { usePromiseTracker } from "react-promise-tracker";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Grid } from "@material-ui/core";
import AdminService from "../../../api/adminService";
import VMCTable from "../../../Recomponent/Table";
import EmptyTable from "../../../Recomponent/EmptyTable";
import { IoMdCloseCircleOutline } from "react-icons/io";
import {
  ToggleContext
} from "../../context/toggleContext"
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};

function MultipleRoles() {
  const [search, setSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [licenseeCompany, setlicenseeCompany] = useState();
  const [creditLimit, setCreditLimit] = useState();
  const [owningChannel, setOwningChannel] = useState();
  const [searchDetails, setSearchDetails] = useState();
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [manageList, setManageList] = useState();
  const [changedropdown, setChangedropdown] = useState(false);
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const toggleContextVal = useContext(ToggleContext)
  const [data, setData] = useState({
    searchUserName: "",
    searchEmail: "",
    searchVerified: "",

  });
  const [invite, setInvite] = useState({
    company_type: "",
    owning_channel: "",
    companyname: "",
    licensee_shortcode: "",
    licensee_company_name: "",
    customer_identifier: "",
    logo_icon_image: "",
    credit_limit: "",
    status: "",
    discount_price: "",
    manager_email: "",
    reg_company_name: "",
    reg_business_number: "",
    reg_address: "",
    reg_phone_number: "",
    option_create_user: "",
    users_name: "",
    email_address: "",
  });

  const [tablecols, setTableCols] = useState([
    {
      colName: "User’s Name",
      colMap: "name",
      linkName: true,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Email Address",
      colMap: "email",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Type",
      colMap: "userType",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Verified",
      colMap: "verified",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },

  ]);
  let history = useHistory();

  const handleClose = () => {

    setOpen(false);
    setChanneldropdown(false);
    setCustomerdropdown(false);
    setLicenseedropdown(false);
    setError({
      company_type: false,
      companyname: false,
      customer_identifier: false,
      licensee_company_name: false,
      status: false,
      reg_business_number: false,
      credit_email: false,
      owning_channel: false,
      reg_company_name: false,
      licensee_shortcode: false,
      users_name: false,
      manager_email: false,
      email_address: false,
      discount_price: false,
      reg_address: false,
      reg_addressmsg: false,


      logo_icon_image: false,

    });
    setInvite({
      company_type: "",
      owning_channel: "",
      companyname: "",
      licensee_shortcode: "",
      licensee_company_name: "",
      customer_identifier: "",
      logo_icon_image: "",
      credit_limit: "",
      status: "",
      discount_price: "",
      manager_email: "",
      reg_company_name: "",
      reg_business_number: "",
      reg_address: "",
      reg_phone_number: "",
      option_create_user: "",
      users_name: "",
      email_address: "",
    });
    setImage({ preview: "", raw: "" });
  };
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };

  const handleRouteLink = (companyId) => {

    history.push(`/admin/multipleuser/${companyId}/details`);

  };
  const lifecycleChange = (e) => {
    e.preventDefault();
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    setEmpty(false);
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  };
  const checkfunction = () => {
    setChecked(!checked);
    if (!checked) {
      setError({
        users_name: false,
        email_address: false
      })
    }
  }
  const [customerdropdown, setCustomerdropdown] = useState(false);
  const [channeldropdown, setChanneldropdown] = useState(false);
  const [licenseedropdown, setLicenseedropdown] = useState(false);

  //managed By List
  async function manageListdata() {
    try {
      trackPromise(
        AdminService.ManagedListData().then((res) => {
          setManageList(res.data.publisherListResult);
          setlicenseeCompany(res.data.licenseeCompanies);
          setOwningChannel(res.data.owningChannel);
          setCreditLimit(res.data.creditLimit);
        })
      );
    } catch (err) {
      setMessage("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const nameReg = /^.*[A-Za-z].*/i;
  ////CompanyNameCheck
  async function companyNameCheck(cname) {
    try {
      trackPromise(
        AdminService.companyNameCheck({ companyname: cname }).then((res) => {
          if (res.data === false) {
            setError({
              ...error,
              companyname: true,
              msg: "This​ Company ​name ​already​ exist",
            });
          } else if (cname.includes(",")) {
            setError({
              ...error,
              companyname: true,
              msg: "Please enter a name without comma",
            });
          }
          else if (nameReg.test(cname) === false) {
            setError({
              ...error,
              companyname: true,
              msg: "Field must have at least 1 character a-z or A-Z",
            });
          }
          else {
            setError({
              ...error,
              companyname: false,
            });
          }
        })
      );
    } catch (err) {
      setMessage("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }

  //check LicenseeShortCode
  async function checkShortCode(short_code) {
    trackPromise(
      AdminService.CheckShortcode({ licensee_shortcode: short_code })
        .then((res) => {
          if (res.data === false) {
            setError({
              ...error,
              licensee_shortcode: true,
              codeErrmsg: "Value must be unique",
            });
          } else {
            setError({
              ...error,
              licensee_shortcode: false,
            });
          }
        })
        .catch((error) => {
          setMessage("Something went wrong. Please reload the page!");
          seterrorOpen(true);
        })
    );
  }

  const [empty, setEmpty] = useState(false);
  //search
  async function MultipleRolesdata() {
    setSearch(true);
    setChangedropdown(true);
    setEmpty(false);
    setSearchDetails({});
    try {
      trackPromise(
        AdminService.userRoleSearch(data).then((res) => {

          setSearchDetails(res.data);
          if (res.status === 200 && res.data.length > 0) {
            setChangedropdown(false);
          } else if (res.status === 200 && res.data.length === 0) {
            setEmpty(true);
            setChangedropdown(false);
          } else {
            setChangedropdown(true);
            setEmpty(false);
          }
        })
      );
    } catch (error) {
      setMessage("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }

  //edit Image
  async function EditImageData() {

    let file = document.getElementById("logo_icon_image").files[0];


    let values = file !== undefined ? new FormData() : "";
    if (file !== undefined) {
      values.append("logo_icon_image", file ? file : "");
    }
    try {

      if (values.toString().trim().length > 0) {
        setClickSave(true)
        trackPromise(
          AdminService.editImage(values).then((res) => {

            if (res.status === 200) {
              setClickSave(false)
              invite["logo_icon_image"] = res.data;

              apiInvite();
            }
            //
          })
        );
      } else {
        apiInvite();
      }
    } catch (err) {
      setMessage("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }

  }
  const [error, setError] = useState({
    company_type: false,
    companyname: false,
    customer_identifier: false,
    licensee_company_name: false,
    status: false,
    reg_business_number: false,
    credit_email: false,
    owning_channel: false,
    reg_company_name: false,
    licensee_shortcode: false,
    users_name: false,
    manager_email: false,
    email_address: false,
    discount_price: false,
    reg_address: false,
    reg_addressmsg: false,
    msg: "",
    manager_emailmsg: "",
    validemailmsg: "",
    regmsg: "",
    validnomsg: "",
    codeErrmsg: "",
    creditEmailMsg: "",
    reg_phone_number: "",
    regNoMsg: "",
    regBusNoMsg: "",
    logo_icon_image: false,
    imgValidmsg: "",
  });
  const numberRegex = /^\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*$/;
  const emailreg = /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/;
  const validNumberRegex = /^(?=.*[0-9])[+ 0-9]+$/i;
  const validBusinessRegex = /^[0-9A-Za-z\s+-]/i;
  const custIdReg = /^[a-zA-Z0-9-]*$/i;
  //customer_identifier
  const InviteCompany = (e) => {
    setLoaderVisible(true);
    e.preventDefault();
    // errorHandler(e);

    const newdata = { ...invite };
    newdata[e.target.id] = e.target.value;
    if (e.target.id === "status") {
      setError({
        ...error,
        status: false,
      });
    }
    if (e.target.id === "companyname") {
      if (e.target.value) {
        companyNameCheck(e.target.value);
      } else {
        setError({
          ...error,
          companyname: true,
          msg: "This field is required",
        });
      }
    }
    if (e.target.id === "manager_email") {
      setError({
        ...error,
        manager_email: true,
      });
      if (emailreg.test(e.target.value) === false && e.target.value !== "") {
        setError({
          ...error,
          manager_email: true,
          manager_emailmsg: "Please enter a valid email address.",
        });
      } else {
        setError({
          ...error,
          manager_email: false,
        });
      }
    }

    if (e.target.id === "customer_identifier") {
      if (e.target.value) {
        if (custIdReg.test(e.target.value) === false) {
          setError({
            ...error,
            customer_identifier: true,

          });
        } else {
          setError({
            ...error,
            customer_identifier: false,
          });
        }
      }
      else {
        setError({
          ...error,
          customer_identifier: false,
        });
      }

    }

    if (e.target.id === "credit_email") {
      setError({
        ...error,
        credit_email: true,
      });
      if (emailreg.test(e.target.value) === false && e.target.value !== "") {
        setError({
          ...error,
          credit_email: true,
          creditEmailMsg: "Please enter a valid email address.",
        });
      } else {
        setError({
          ...error,
          credit_email: false,
        });
      }
    }
    if (e.target.id === "company_type") {
      setError({
        ...error,
        company_type: false,
      });
    }
    if (e.target.id === "licensee_company_name") {
      setError({
        ...error,
        licensee_company_name: false,
      });
    }
    if (e.target.id === "users_name") {
      setError({
        ...error,
        users_name: false,
      });
    }
    if (e.target.id === "email_address") {
      if (e.target.value) {
        EmailCheck(e.target.value);
      } else {
        setError({
          ...error,
          email_address: true,
          validemailmsg: "This field is required",
        });
      }
    }

    if (e.target.id === "reg_business_number") {
      setError({
        ...error,
        reg_business_number: true,
      });
      if (validBusinessRegex.test(e.target.value) === false && e.target.value !== "") {
        setError({
          ...error,
          reg_business_number: true,
          regBusNoMsg: "Only alphanumeric characters or underscore and the first character must be a letter",
        });
      } else {
        setError({
          ...error,
          reg_business_number: false,
        });
      }
    }

    if (e.target.id === "reg_phone_number") {
      setError({
        ...error,
        reg_phone_number: true,
      });
      if (validNumberRegex.test(e.target.value) === false && e.target.value !== "") {
        setError({
          ...error,
          reg_phone_number: true,
          regNoMsg: "Valid characters are '+' 0-9 and space",
        });
      } else {
        setError({
          ...error,
          reg_phone_number: false,
        });
      }
    }

    if (e.target.id === "owning_channel") {
      setError({
        ...error,
        owning_channel: false,
      });
    }
    if (e.target.id === "licensee_shortcode") {
      if (e.target.value) {
        checkShortCode(e.target.value);
      } else {
        setError({
          ...error,
          licensee_shortcode: true,
          codeErrmsg: "This field is required",
        });
      }
    }
    if (e.target.id === "logo_icon_image") {

      if (e.target.files.length !== 0) {
        let img = new Image();
        img.src = URL.createObjectURL(e.target.files[0]);
        var filePath = e.target.files[0].name;
        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        if (!allowedExtensions.exec(filePath)) {
          setImage({
            preview: "",
            raw: "",
          });
          setError({
            ...error,
            logo_icon_image: true,
            imgValidmsg: "Selected file is not an image",
          });
        }
        else {
          img.onload = () => {
            if (img.width < 180) {
              setImage({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                logo_icon_image: true,
                imgValidmsg: "Minimum width must be 180px",
              });
            }
            else if (img.height < 65) {
              setImage({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                logo_icon_image: true,
                imgValidmsg: "Minimum height must be 65px",
              });
            }
            else {
              setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
              });
              setError({
                ...error,
                logo_icon_image: false,
                imgValidmsg: "",
              });
            }
          };
        }
      }
    }
    if (e.target.id === "discount_price") {
      setError({
        discount_price: false,
      });
      if (numberRegex.test(e.target.value) === false && e.target.value !== "") {
        setError({
          ...error,
          discount_price: true,
          validnomsg: "Please enter a valid number.",
        });
      } else if (e.target.value > 99) {
        setError({
          ...error,
          discount_price: true,
          validnomsg: "Please enter a value less than or equal to 99.9.",
        });
      } else {
        setError({
          ...error,
          discount_price: false,
        });
      }
    }
    if (e.target.id === "reg_company_name") {
      setError({
        ...error,
        reg_company_name: false,
      });
      if (/[a-zA-Z]/.test(e.target.value) === false && e.target.value !== "") {
        setError({
          ...error,
          reg_company_name: true,
          regmsg: "Field must have at least 1 character a-z or A-Z",
        });
      } else {
        setError({
          ...error,
          reg_company_name: false,
        });
      }
    }
    if (e.target.id === "reg_address") {
      setError({
        ...error,
        reg_address: false,
      });
      if (/[a-zA-Z]/.test(e.target.value) === false && e.target.value !== "") {
        setError({
          ...error,
          reg_address: true,
          reg_addressmsg: "Field must have at least 1 character a-z or A-Z",
        });
      } else {
        setError({
          ...error,
          reg_address: false,
        });
      }
    }

    //  if(e.target.id=='company_type'){
    if ((e.target.id === "company_type" && e.target.value === "CUSTOMER") || e.target.value === "RESELLER") {
      setCustomerdropdown(true);
      setChanneldropdown(false);
      setLicenseedropdown(false);
    } else if (e.target.id === "company_type" && e.target.value === "CHANNEL") {
      setCustomerdropdown(false);
      setLicenseedropdown(false);
      setChanneldropdown(true);
    } else if (e.target.id === "company_type" && e.target.value === "LICENSEE") {
      setCustomerdropdown(false);
      setChanneldropdown(false);
      setLicenseedropdown(true);
    } else if (e.target.id === "company_type" && e.target.value === "") {
      setCustomerdropdown(false);
      setChanneldropdown(false);
      setLicenseedropdown(false);
    }
    if (e.target.files) {
      setImage({
        preview: URL?.createObjectURL(e?.target?.files[0]),
        raw: e.target.files[0],
      });
    }
    if (checked) {
      newdata["option_create_user"] = "yes";
    } else {
      newdata["option_create_user"] = "no";
    }
    setInvite(newdata);
  };

  ////Check Email
  async function EmailCheck(value) {
    trackPromise(
      AdminService.EmailCheck({ email_address: value })
        .then((res) => {
          if (res.data === false) {
            setError({
              ...error,
              email_address: true,
              validemailmsg: "This​ email ​already​ used",
            });
          } else if (value && emailreg.test(value) === false) {
            setError({
              ...error,
              email_address: true,
              validemailmsg: "Please enter a valid email address.",
            });
          } else {
            setError({
              ...error,
              email_address: false,
            });
          }
        })
        .catch((error) => {

        })
    );
  }
  const [message, setMessage] = useState();
  const checkError = () => {
    setError({
      ...error,
      company_type: invite.company_type.length === 0 ? true : false,
      companyname: invite.companyname.length === 0 ? true : false,
      users_name: invite.users_name.length === 0 ? true : false,
      email_address: invite.email_address.length === 0 ? true : false,
      owning_channel: customerdropdown ? (invite.owning_channel.length === 0 ? true : false) : false,
      status: invite.status.length === 0 ? true : false,
      licensee_company_name: channeldropdown ? (invite.licensee_company_name.length === 0 ? true : false) : false,
      licensee_shortcode: licenseedropdown ? (invite.licensee_shortcode.length === 0 ? true : false) : false,
      logo_icon_image: licenseedropdown || channeldropdown ? (error.logo_icon_image ? true : false) : false,
      customer_identifier: customerdropdown ? (error.customer_identifier ? true : false) : false,
    });

  };
  const apiInvite = () => {
    setClickSave(true)
    trackPromise(
      AdminService.AddCompany(invite).then((res) => {
        setClickSave(false)
        if (res.status === 200) {
          setLoaderVisible(false);
          setToastMsg("Comapany Details Added Successfully")
          setsuccessOpen(true);
          handleClose();
        }
      }).catch((error) => {
        setMessage(error.response.data);
        seterrorOpen(true);
      })
    );

  };
  //invite companyUser
  const typeRef = React.useRef();
  const cmpyNameRef = React.useRef();
  const statusRef = React.useRef();
  const owingRef = React.useRef();
  const licenseeCodeRef = React.useRef();
  const idRef = React.useRef();
  const imageRef = React.useRef();
  const [clickSave, setClickSave] = useState(false);
  const [toastMsg, setToastMsg] = useState();
  function inviteCompany() {

    if (customerdropdown && invite.owning_channel.length === 0) {

      checkError();
      owingRef.current.focus();
    } else if (!licenseedropdown && invite.status.length === 0) {
      checkError();
      statusRef.current.focus();
    } else if (channeldropdown && invite.licensee_company_name.length === 0) {

      checkError();
      cmpyNameRef.current.focus();
    }
    else if (licenseedropdown && invite.licensee_shortcode.length === 0) {
      checkError();
      licenseeCodeRef.current.focus();
    }
    else if (invite.company_type.length === 0 || invite.companyname.length === 0) {

      checkError();
      typeRef.current.focus();
      cmpyNameRef.current.focus();
    }
    else if (error.logo_icon_image) {
      checkError();
      imageRef.current.focus();
    }
    else if (error.customer_identifier) {

      checkError();
      idRef.current.focus();
    }

    else if (invite.users_name.length === 0 || invite.email_address.length === 0) {

      if (checked === true) {

        checkError();
      } else {

        channeldropdown || licenseedropdown ? EditImageData() : apiInvite();
      }
    } else {

      channeldropdown || licenseedropdown ? EditImageData() : apiInvite();
    }
  }
  useEffect(() => {
    document.title = "Datafree Portal";
    manageListdata();
  }, []);

  return (
    <div  className={toggleContextVal.event ?"mainMobView" :"mainBoxView"}>
      <div className="NavBoxDiv">
        <div className="NavBoxRow col-lg-6 col-md-6 col-sm-6 col-xs-5">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">Maintain Multiple User Roles List - Admin View</div>
        </div>
        <div className="NavBoxRowFilter pullright table-responsive table-sm">

          <table className="table-sm">
            <tbody>
              <tr>
              <td className="col-md-5">
                
                </td>
                <td className="col-md-2">
                  User's Name
                </td>
                <td className="col-md-3">
                  Email Address
                </td>
                <td className="col-md-1">
                  Verified
                </td>
                <td className="col-md-2">

                </td>

              </tr>
              <tr>
              <td className="col-md-5">
                
                </td>
                <td className="col-md-2">
                  <input type="text"

                    className="form-control"
                    id="searchUserName" name="searchUserName" onChange={(e) => lifecycleChange(e)}></input>
                </td>
                <td className="col-md-3">
                  <input type="text"

                    className="form-control" id="searchEmail" name="searchEmail" onChange={(e) => lifecycleChange(e)}></input>

                </td>
                <td className="col-md-1">
                  <select type="text" className="form-control form-select"

                    id="searchVerified" name="searchVerified" onChange={(e) => lifecycleChange(e)}>
                    <option value=""></option>
                    <option value="0">No</option>
                    <option value="1">Yes</option>

                  </select>
                </td>
                <td className="col-md-2">
                  <button className={changedropdown ? "defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"}

                    disabled={changedropdown ? "disabled" : false}
                    onClick={MultipleRolesdata}>
                    Search
                  </button>
                </td>

              </tr>
            </tbody>
          </table>

        </div>
        <div><hr className="applications-list-hr"></hr></div>

        {search ? (
          <div>
            {searchDetails && searchDetails.length > 0 ? (
              <VMCTable key={searchDetails} data={searchDetails} col={tablecols} navigateFunction={handleRouteLink}
                tableName="Maintain Role List" />
            ) : (
              <div style={{ alignItems: "center" }}>
                <LoadingIndicator />
                {empty ? <EmptyTable data="No Dats Found" col={tablecols} tableName="Maintain Role List" /> : null}
              </div>
            )}
          </div>
        ) : null}
      </div>
      {/* search ? (
              <LoadingIndicator />
              
            ) : null */}
      {/* <EmptyTable  data="No Companies Found" col={tablecols}  tableName="Company List" /> */}
      {/* //// ======================================Create Company Form  ======================= */}
      <Dialog open={open} classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth scroll={scroll} maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Create Company - Admin View
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <div className="cmpdialoginputtext">Company Type</div>
              <select
                type="text"
                id="company_type"
                name="company_type"
                label="company_type"
                style={{ height: '34px' }}
                className={error.company_type ? "errcmpnydialoginput" : "cmpnydialoginput"}
                onChange={(e) => InviteCompany(e)}
                ref={typeRef}
                fullWidth
              >
                <option value=""></option>
                <option value="CUSTOMER">CUSTOMER</option>
                <option value="CHANNEL">CHANNEL</option>
                <option value="RESELLER">RESELLER</option>
                <option value="LICENSEE">LICENSEE</option>
              </select>
              {error.company_type ? <a className="errorClass">This field is required.</a> : null}
            </Grid>

            <Grid item xs={6}>
              {customerdropdown ? (
                <div>
                  <div className="cmpdialoginputtext">Owning Channel</div>
                  <select
                    type="text"
                    id="owning_channel"
                    name="owning_channel"
                    label="company_type"
                    style={{ height: '34px' }}
                    className={error.owning_channel ? "errcmpnydialoginput" : "cmpnydialoginput"}
                    onChange={(e) => InviteCompany(e)}
                    ref={owingRef}
                    fullWidth
                  >
                    <option value=""></option>
                    {owningChannel && owningChannel.length > 0
                      ? owningChannel.map((post) => (
                        <option key={post.companyId} value={post.companyId}>
                          {post.name}
                        </option>
                      ))
                      : null}
                  </select>
                  {error.owning_channel ? <a className="errorClass">This field is required.</a> : null}
                </div>
              ) : null}
              {channeldropdown || licenseedropdown ? (
                <div>
                  <div className="fileIcon ">
                    <img src={image.preview ? image.preview : ""} alt="" className="imgStyle"></img>
                  </div>
                  <div className="imgiconRow">
                    <div className="companyLabel">Upload Company Logo </div>
                    <div className="filesubText">(Min. dimensions of 180px X 65px)</div>
                    <input id="logo_icon_image" ref={imageRef} type="file" onChange={(e) => InviteCompany(e)}
                      name="logo_icon_image" />
                    {error.logo_icon_image ? <a className="errorClass">{error.imgValidmsg ? error.imgValidmsg : ""}</a> : null}
                  </div>
                </div>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Company Name</div>
              <input
                type="text"
                id="companyname"
                name="companyname"
                label="email"
                className={error.companyname ? "errcmpnydialoginput" : "cmpnydialoginput"}
                onChange={(e) => InviteCompany(e)}
                fullWidth
                ref={cmpyNameRef}
              />
              {error.companyname ? <a className="errorClass">{error.msg ? error.msg : "This field is required"}</a> : null}
            </Grid>
            {licenseedropdown ? (
              <Grid item xs={12}>
                <div className="cmpdialoginputtext">Licensee ShortCode</div>
                <input
                  type="text"
                  id="licensee_shortcode"
                  name="licensee_shortcode"
                  label="email"
                  className={error.licensee_shortcode ? "errcmpnydialoginput" : "cmpnydialoginput"}
                  onChange={(e) => InviteCompany(e)}
                  fullWidth
                  ref={licenseeCodeRef}
                />
                {error.licensee_shortcode ? <a className="errorClass">{error.codeErrmsg ? error.codeErrmsg : "This field is required"}</a> : null}
              </Grid>
            ) : null}
            <Grid item xs={12}>
              {customerdropdown ? (
                <div>
                  <div className="cmpdialoginputtext">Channel Customer Identifier</div>
                  <input type="text" id="customer_identifier"
                    name="customer_identifier"
                    label="email"
                    ref={idRef}
                    className={!error.customer_identifier ? "cmpnydialoginput" : "errcmpnydialoginput"}
                    onChange={(e) => InviteCompany(e)} fullWidth />
                  {error.customer_identifier ? <a className="errorClass">Only alphanumeric characters and hyphen is allowed</a> : null}
                </div>
              ) : null}
              {channeldropdown ? (
                <div>
                  <div className="cmpdialoginputtext">Licensee Company</div>
                  <select
                    type="text"
                    id="licensee_company_name"
                    name="licensee_company_name"
                    label="company_type"
                    className={error.licensee_company_name ? "errcmpnydialoginput" : "cmpnydialoginput"}
                    onChange={(e) => InviteCompany(e)}
                    fullWidth
                  >
                    <option value=""></option>
                    {licenseeCompany && licenseeCompany.length > 0
                      ? licenseeCompany.map((post) => (
                        <option key={post.companyId} value={post.companyId}>
                          {post.name}
                        </option>
                      ))
                      : null}
                  </select>
                  {error.licensee_company_name ? <a className="errorClass">This field is required.</a> : null}
                </div>
              ) : null}
            </Grid>
            {customerdropdown ? (
              <Grid item xs={6}>
                <div>
                  <div className="cmpdialoginputtext">#datafree Data Credit Limit</div>
                  <select type="text" id="credit_limit" label="credit_limit"
                    style={{ height: '34px' }}
                    className="cmpnydialoginput" onChange={(e) => InviteCompany(e)} fullWidth>
                    <option value=""></option>
                    {creditLimit && creditLimit.length > 0
                      ? creditLimit.map((post) => (
                        <option key={post.creditLimitId} value={post.creditLimitId}>
                          {post.creditLimit}
                        </option>
                      ))
                      : null}
                  </select>
                </div>
              </Grid>
            ) : null}

            {channeldropdown || licenseedropdown ? (
              <Grid item xs={6}>
                <div>
                  <div className="cmpdialoginputtext">Credit Controller Email</div>
                  <input
                    type="text"
                    id="credit_email"
                    label="credit_email"
                    className={error.credit_email ? "errcmpnydialoginput" : "cmpnydialoginput"}
                    onChange={(e) => InviteCompany(e)}
                    fullWidth
                  ></input>
                  {error.credit_email ? <a className="errorClass">{error.creditEmailMsg}</a> : null}
                </div>
              </Grid>
            ) : null}

            {!licenseedropdown ? (
              <Grid item xs={6}>
                <div className="cmpdialoginputtext">Status</div>
                <select type="text" ref={statusRef} id="status" name="status"
                  style={{ height: '34px' }}
                  className={error.status ? "errcmpnydialoginput" : "cmpnydialoginput"} onChange={(e) => InviteCompany(e)} fullWidth>
                  <option value=""></option>
                  <option value="New">New</option>
                  <option value="NoAgreement">NoAgreement</option>
                </select>
                {error.status ? <a className="errorClass">This field is required.</a> : null}
              </Grid>
            ) : null}
            {customerdropdown ? (
              <Grid item xs={6}>
                <div className="cmpdialoginputtext">Price Discount %</div>
                <input
                  type="text"
                  id="discount_price"
                  name="discount_price"
                  label="company_type"
                  style={{ width: '96%' }}
                  className={error.discount_price ? "errcmpnydialoginput" : "cmpnydialoginput"}
                  onChange={(e) => InviteCompany(e)}
                  fullWidth
                />{" "}
                {error.discount_price ? <a className="errorClass">{error.validnomsg}.</a> : null}
              </Grid>
            ) : null}
            {customerdropdown ? (
              <Grid item xs={6}>
                <div className="cmpdialoginputtext">Account Manager Email</div>
                <input
                  type="text"
                  id="manager_email"
                  name="manager_email"
                  label="company_type"
                  style={{ width: '96%' }}
                  className={error.manager_email ? "errcmpnydialoginput" : "cmpnydialoginput"}
                  onChange={(e) => InviteCompany(e)}

                  fullWidth
                />
                {error.manager_email ? <a className="errorClass">{error.manager_emailmsg}.</a> : null}
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Registered Company Name</div>
              <input
                type="text"
                id="reg_company_name"
                name="reg_company_name"
                label="companyname"
                className={error.reg_company_name ? "errcmpnydialoginput" : "cmpnydialoginput"}
                onChange={(e) => InviteCompany(e)}
                fullWidth
              />{" "}
              {error.reg_company_name ? <a className="errorClass">{error.regmsg}.</a> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Registered Business Number (if appropriate)</div>
              <input
                type="text"
                id="reg_business_number"
                name="reg_business_number"
                label="email"
                className={error.reg_business_number ? "errcmpnydialoginput" : "cmpnydialoginput"}
                onChange={(e) => InviteCompany(e)}
                fullWidth
              />
              {error.reg_business_number ? <a className="errorClass">{error.regBusNoMsg}.</a> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Registered Address</div>
              <input
                type="text"
                id="reg_address"
                name="reg_address"
                label="email"
                className={error.reg_address ? "errcmpnydialoginput" : "cmpnydialoginput"}
                onChange={(e) => InviteCompany(e)}
                fullWidth
              />
              {error.reg_address ? <a className="errorClass">{error.reg_addressmsg}.</a> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Company Phone Number</div>
              <input
                type="text"
                id="reg_phone_number"
                name="reg_phone_number"
                label="email"
                className={error.reg_phone_number ? "errcmpnydialoginput" : "cmpnydialoginput"}
                onChange={(e) => InviteCompany(e)}
                fullWidth
              />
              {error.reg_phone_number ? <a className="errorClass">{error.regNoMsg}.</a> : null}
            </Grid>

            <div>
              <input type="checkbox" name="option-create-user" id="option-create-user" onChange={() => checkfunction()} checked={checked} />
              <label for="websiteName" className="cmpdialoginputtext">
                Optionally Create User ?
              </label>
            </div>
            {checked ? (
              <>
                <Grid item xs={12}>
                  <div className="cmpdialoginputtext">User’s Name</div>
                  <input
                    type="text"
                    id="users_name"
                    name="users_name"
                    label="users_name"
                    className={error.users_name ? "errcmpnydialoginput" : "cmpnydialoginput"}
                    onChange={(e) => InviteCompany(e)}
                    fullWidth
                  />
                  {error.users_name ? <a className="errorClass">This field is required.</a> : null}
                </Grid>
                <Grid item xs={12}>
                  <div className="cmpdialoginputtext">Email Address (username)</div>
                  <input
                    type="text"
                    id="email_address"
                    name="email_address"
                    label="email"
                    className={error.email_address ? "errcmpnydialoginput" : "cmpnydialoginput"}
                    onChange={(e) => InviteCompany(e)}
                    fullWidth
                  />
                  {error.email_address ? <a className="errorClass">{error.validemailmsg ? error.validemailmsg : "This field is required"}</a> : null}
                </Grid>
              </>
            ) : (
              <div></div>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {loaderVisible ? <LoadingIndicator /> : null}
          <div className="ivitediv">
            <button className="inviteButton" onClick={handleClose} color="primary">
              Cancel
            </button>

            <button className="inviteButton"
              disabled={clickSave ? "disabled" : false}
              onClick={inviteCompany} color="primary">
              Save
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar open={successopen} autoHideDuration={1500} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
          {toastMsg ? toastMsg : "Saved Successfully!!"}
        </Alert>
      </Snackbar>

      <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default MultipleRoles;