import React, { createContext, useState } from "react"

const ToggleContext = createContext("")
const ToggleDispatchContext = createContext("")
const MobContext = createContext("")
const MobDispatchContext = createContext("")
function ToggleContextProvider({ children }) {

    const [clickToggle, setClickToggle] = useState({
        event: true
    })
    const [mobileView, setMobileView] = useState({
        event: false
    })
    return (
        <ToggleContext.Provider value={clickToggle}>
            <ToggleDispatchContext.Provider value={setClickToggle}>
            <MobContext.Provider value={mobileView}>
            <MobDispatchContext.Provider value={setMobileView}>
                {children}
                </MobDispatchContext.Provider>
        </MobContext.Provider>
            </ToggleDispatchContext.Provider>
        </ToggleContext.Provider>
    )
}

export {
   ToggleContextProvider,ToggleContext,ToggleDispatchContext,MobContext,MobDispatchContext

}