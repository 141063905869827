import React, { useState, useEffect,useContext } from "react";
import "./adminStyle.css";
import applicationIcon from "../../../assets/img/application-icon.png";
import { useHistory } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import VMCTable from '../../../Recomponent/Table';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import EmptyTable from "../../../Recomponent/EmptyTable";
import AdminService from "../../../api/adminService";
import Loader from "react-loading";
import { usePromiseTracker } from "react-promise-tracker";
import {
  ToggleContext
} from "../../context/toggleContext"
const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
          
        }}
      >
          <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Connect() {
  const [search, setSearch] = useState(false);
  const [searchDetails, setSearchDetails] = useState();
  const[changedropdown,setChangedropdown]= useState(false);
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const toggleContextVal = useContext(ToggleContext)
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const [data, setData] = useState({
    licensee_list: "",
    searchChannel: "",
    connectServiceSearches: "",
    connectServiceSearch: "",
    searchDeploymentStatus: "",
    searchBinuId: "",
    searchApplicationName:"" ,
    searchPublisherName:"" ,
    searchProductType: "6"
      
  });
  
const [empty, setEmpty] = useState(false);

  const [tablecols, setTableCols] = useState([
    {
      colName : 'Licensee',
      colMap: 'publisherIdparentCompanyIdname',
      linkName : true,
      cModel : false,
      cRoute : true,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Channel',
      colMap: 'publisherIdparentCompanyIdparentCompanyIdname',
      linkName :true,
      cModel : false,
      cRoute : true,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Publisher',
      colMap: 'publisherIdname',
      linkName : true,
      cModel : false,
      cRoute : true,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Customer Type',
      colMap: 'publisherIdcompanyType',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Customer Status',
      colMap: 'publisherIdstatus',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'CONNECT Config Server',
      colMap: 'versionTag',
      linkName : true,
      cModel : false,
      cRoute : true,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'App Name',
      colMap: 'name',
      linkName : true,
      cModel : false,
      cRoute :true,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Datafree App Id',
      colMap: 'isDefault',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
        colName : 'App Status',
        colMap: 'status',
        linkName : false,
        cModel : false,
        cRoute : false,
        fDate:false,
        imprRoute: false,
        userTab:false
      },
      {
        colName : 'Production Data Usage Mb (28 days)',
        colMap: 'maxBytesPerDayAction',
        linkName : false,
        cModel : false,
        cRoute : false,
        fDate:false,
        imprRoute: false,
        userTab:false
      },
  ])
  let history = useHistory();
 
  const handleRouteLink = (companytype, companyId) => {
  
    if (companytype === "CUSTOMER" || "RESLR CLNT") {
      history.push(`/admin/customer/${companyId}/detail/view`);
    }
    if (companytype === "CHANNEL") {
      history.push(`/admin/Channel/${companyId}/detail/view`, {
        companyId: companyId,
      });
    }
    if (companytype === "LICENSEE") {
      history.push(`/admin/licensee/${companyId}/detail/view`, {
        licenseeId: companyId,
      });
    }

    if (companytype === "VPN") {
      history.push(`/admin/datafreeconnect/application/${companyId}/detail/view`);
    }

    if (companytype === "CONNECT") {
      history.push(`/admin/view/licensee/${companyId}/service/details`);
    }
  };
  
  const handleServiceLink = (cmpyId, licenseeServiceId) =>{
    history.push(`/admin/view/licensee/${licenseeServiceId}/service/details`,{licseeid:cmpyId});
  }
  const [ChannelData, setChannelData] = useState();
  async function ChannelName(companyId) {
    try {
      trackPromise(
        AdminService.ConnectChannelDrop({licenseeId:companyId}).then((res) => {
          setChannelData(res.data?.licenseeChannels);
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }

  const lifecycleChange = (e) => {
    e.preventDefault();
    changedropdown?setChangedropdown(false):setChangedropdown(false);
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    if(e.target.id === "licensee_list"){
      ChannelName(e.target.value) 
    }
    setData(newdata);
  };
  
  const[connectData ,setConnectData] = useState()
 //Connect Index
 function ConnectIndex() {
  
  try {
    trackPromise(
      AdminService.connectIndex().then((res) => {
     
       setConnectData(res.data);
      })
    );
  } catch (err) {
    setErrMsg("Something went wrong. Please reload the page!");
    seterrorOpen(true);
  }
}

function utiltyRouteLink() {

  history.push("/admin/datafreeconnect/application/create");
}

const [extraData, setExtraData] = useState();
//search
async function connectSearch() {
  setSearch(true);
  setChangedropdown(true);
  setEmpty(false);
  try {
    trackPromise(
      AdminService.connectSearch(data).then((res) => {   
        setSearchDetails(res.data.applicationListResult);
        setExtraData(res.data);
        if (res.status === 200 && res.data.applicationListResult.length > 0) {   
          setChangedropdown(false);
          setEmpty(false);
        } else if (res.status === 200 && res.data.applicationListResult.length === 0) {
          setEmpty(true);
          setChangedropdown(false);
        } else {
          setChangedropdown(true);
          setEmpty(false);
        }
      })
    );
  } catch (err) {
    setErrMsg("Something went wrong. Please reload the page!");
    seterrorOpen(true);
  }
}

useEffect(() => {
    ConnectIndex();
}, []);

  return (
    <div  className={toggleContextVal.event ?"mainMobView" :"mainBoxView"}>
      <div className="NavBoxDiv">
        <div className="NavBoxRow col-lg-6 col-md-6 col-sm-6 col-xs-5">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">#datafree CONNECT Application List - Admin View</div>
        </div>
        <div className="NavBoxRowFilter table-responsive">
        <table className="table-sm">
            <tbody>
              <tr>
                <td className="col-md-2">
                Licensee
                </td>
                <td className="col-md-2">
                Channel
                </td>
                <td className="col-md-2">
                CONNECT Config Service
                </td>
                <td className="col-md-1">
                Status
                </td>
                <td className="col-md-1">
                Datafree App ID
                </td>
                <td className="col-md-2">
                Application Name
                </td>
                <td className="col-md-1">
                Publisher Name
                </td>
                <td className="col-md-1">
               
                </td>
              </tr>
              <tr>
                <td className="col-md-2">
                <select
                  type="text"
                  className="form-control form-select"
                  id="licensee_list"
                  name="licensee_list"
                  onChange={(e) => lifecycleChange(e)}
                >
                  <option value=""></option>
                  {connectData && connectData.licensee.length > 0
                    ? connectData.licensee.map((post) => (
                        <option key={post.companyId} value={post.companyId}>
                          {post.name}
                        </option>
                      ))
                    : null}
                </select>
                </td>
                <td className="col-md-2">
                <select
                  type="text"
                  className="form-control form-select"
                  id="searchChannel"
                  name="searchChannel"
                  onChange={(e) => lifecycleChange(e)}
                >
                  <option value=""></option>
                  {ChannelData && ChannelData.length > 0
                    ? ChannelData.map((post) => ( 
                      <option key={post.companyId} value={post.companyId}>
                      {post.name}
                    </option>
                      ))
                   : null} 
                </select>

                </td>
                <td className="col-md-2">
                <select
                  type="text"
                  className="form-control form-select"
                  id="connectServiceSearches"
                  name="connectServiceSearches"
                  onChange={(e) => lifecycleChange(e)}
                >
                  <option value=""></option>
                  {connectData && connectData.configService.length > 0 && data.licensee_list === "" 
                    ? connectData.configService.map((post) => (
                        <option key={post.lic_serv_id} value={post.name}>
                          {post.name}
                        </option>
                      ))
                    : null}
                </select>
                </td>
                <td className="col-md-1">
                <select
                  type="text"
                  className="form-control form-select"
                  id="searchDeploymentStatus"
                  name="searchDeploymentStatus"
                  onChange={(e) => lifecycleChange(e)}
                >
                  <option value=""></option>
                  <option value="Live">Live</option>
                  <option value="Deleted">Deleted</option>
                </select>
                </td>
              
                <td className="col-md-1">
                <input
                  type="text"
                  className="form-control"
                  id="searchBinuId"
                  name="searchBinuId"
                  onChange={(e) => lifecycleChange(e)}
                ></input>
                </td>

                <td className="col-md-2">
                <input
                  type="text"
                  className="form-control"
                  id="searchApplicationName"
                  name="searchApplicationName"
                  onChange={(e) => lifecycleChange(e)}
                ></input>
                </td>
                <td className="col-md-1">
                <input
                  type="text"
                  className="form-control"
                  id="searchPublisherName"
                  name="searchPublisherName"
                  onChange={(e) => lifecycleChange(e)}
                ></input>
                </td>
                <td className="col-md-1">
                <button
               className={changedropdown ? "defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"}
              onClick={connectSearch}
               disabled={changedropdown ? "disabled" : false}
              >
                Search
              </button>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div><hr className="applications-list-hr"></hr></div>
        <div className="createButtonDiv">
          <button
            
            className="defultbtn"
            title="Click here to create new #datafree Connect App"
            onClick={utiltyRouteLink}
          >
            + Create New #datafree Connect App
          </button>
        </div>
      
        {search ? (
          <div>
             {searchDetails && searchDetails.length > 0
                    ?(
                      <VMCTable key={searchDetails} data={searchDetails} 
                      additionalData1={extraData?.connectConfiqServer} 
                      additionalData2={extraData?.datafreeAppId} col={tablecols} 
                      navigateFunction={handleRouteLink}
                      serviceNav={handleServiceLink} 
                      tableName="Connect Utility Table"
                      />
                  ) : (
                    <div>
                    <LoadingIndicator />
                    {empty ? <EmptyTable data="No Applications Found" col={tablecols} tableName="Connect Utility Table" /> : null}
                  </div>
      )}
          </div>
        ) : (
          ""
        )}
      </div>
     
      <Snackbar open={successopen} autoHideDuration={1500} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
          "Updated Successfully!
        </Alert>
      </Snackbar>

      <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {errMsg ? errMsg : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default Connect;
