import React, { useEffect, useState,useContext } from "react";
import applicationIcon from "../../../assets/img/application-icon.png";
import ChannelService from "../../../api/channelService";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";
import {
  ToggleContext
} from "../../context/toggleContext"


function NotificationDetails() {
  const [loading, setLoading] = useState(false);
  const [notifyDetails, setNotifyDetails] = useState();
  const toggleContextVal = useContext(ToggleContext)
  const Channeldata=()=> {
    setLoading(true);
    try {
      trackPromise(
        ChannelService.notificationDetails().then((res) => {
          setNotifyDetails(res?.data?.notificationDetails);
          setLoading(false);
        })
      );
    } catch (err) {
    
    }
  }
  useEffect(() => {
    setLoading(true);
    document.title = "Datafree Portal";
    Channeldata();
  }, []);

  return (
    <div className={toggleContextVal.event ?"mainMobView" :"mainBoxView"}>
      <div className="NavBoxDiv">
        <div className="NavBoxRow">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">Datafree Notifications</div>
        </div>
        {loading?  <div className="loaderContainer"><Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} /></div>:null}
         {!loading ?
        <div className="margTop">
                    <table className="notifyTable">
                        <thead className="RowThead">
                            <td className="RowTr">Notification ID</td>
                            <td className="RowTr">Level</td>
                            <td className="RowTr">Type</td>
                            <td className="RowTr">Message</td>
                        </thead>
                        <tbody>
                        {notifyDetails && notifyDetails.length > 0 ? notifyDetails?.map((row) => (
                          <tr key={row.index}>
                            <td className="RowTrs" component="th" scope="row">{row.notification_id}</td>
                            <td className="RowTrs">{row.notification_level}</td>
                            <td className="RowTrs">{row.notification_type}</td>
                            <td className="RowTrs">{row.notification_text}</td>
                          </tr>
                        )) : null
                        }
                        </tbody>
                    </table>
                </div> 
            : null}
      </div> 
    </div>
  );
}
export default NotificationDetails;
