import React, { useState } from "react";
import "./gettingStarted.css";
import WebCard from "../gettingStarted/register/webCard";
import AppCard from "../gettingStarted/register/appCard";
import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});
function GettingStarted() {
  let history = useHistory();
  const [development, setDevelopment] = useState(false);
  const [open, setOpen] = useState(false);
  const [type,setType]=useState("");
  const classes = useStyles();

  const RegDevelopment = (value) => {
    setType(value);
    setDevelopment(true);
  };
  const handlecancelbtn = () => {
    setDevelopment(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const cancelReg = () => {
    history.push("/user/datafree");
  };
  return (
    <div className="mainStartView col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="gettingStartTitle">
        Getting Started - Register an Application / Website
      </div>
      {!development ? (
      <div className="gettingstarthead">
        Use this screen to Register an Application or Website that you wish to
        develop #datafree
      </div>
      ):
      <div className="gettingstarthead" style={{width:'87%'}}>
        Use this screen to Register an Application or Website that you wish to
        develop #datafree
      </div>
      }
      {!development ? (
        <div className="gettingstartcontent">
          <div className="contentHead">
            Are you registering for the purpose of Development or Production ?
          </div>
          <div className="developmentContent">
            <div className="develophead">Development :</div>
            <div className="developheadcontent">
              Develop a proof of concept within a safe environment with
              supporting tools
            </div>
          </div>
          <div className="developsubheadcontent">
            - Development Websites / Apps are Free to Trial (No charge) <br />-
            They do NOT deliver #datafree connection <br />- Development Apps
            integrate with biNu development tools <br />- Full security rules
            are NOT required prior to testing <br />- Development Apps CANNOT be
            released through Google Play Store <br />- Generated Development
            Apps are Pre-signed with a Development Certificate <br />-
            Registered details can be cloned to Production version once
            Development complete
          </div>
          <div className="developmentdiv">
            <button className="developmentregbtn" onClick={(e)=>RegDevelopment("DEV")}>
              Register for Development
            </button>
          </div>
          {/* /// */}
          <div className="developmentContent">
            <div className="develophead">Production :</div>
            <div className="developheadcontent">
              Produce a finalised version of your #datafree solution
            </div>
          </div>
          <div className="developsubheadcontent">
            - To use this requires your account to have been pre-approval by
            biNU (<a href = "https://bi.nu/contact" target="_blank" style={{textDecoration:'none'}}>Contact Us</a>)
            <br />- Production Websites / Apps operate #datafree <br />- you
            will be charged for usage dependent upon Plan
            <br />- Full security rules MUST be set prior to production release
            (not required during registration)
            <br />- Production Apps, once generated, require self signing (TLS
            certificate)
            <br />- Production Apps, once signed, can be released to Google Play
            Store
          </div>
          <div className="developmentdiv">
            <button className="developmentregbtn" onClick={(e)=>RegDevelopment("PROD")}>
              Register for Production
            </button>
          </div>
        </div>
      ) : (
        <div className="datafreeLayout">
          <div className="datafreewebtitle">#datafree for Websites </div>
          <div className="datafreeLayoutRow">
            <WebCard deploytype={type}/>
          </div>
          <div className="datafreewebtitle">#datafree for Apps </div>
          <div className="datafreeLayoutRow">
            <AppCard deploytype={type}/>
          </div>
          <div className="datafreeLayoutbtns">
            <div>
              <button className="Layoutbtn" onClick={handleClickOpen}>
                Cancel
              </button>
            </div>
            <div>
              <button className="Layoutbtn" onClick={handlecancelbtn}>
                Previous
              </button>
            </div>
          </div>
        </div>
      )}
      <Dialog
        open={open}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="dialogtitle">
          Getting started - Register an Application / Website
        </DialogTitle>
        <DialogContentText className="DialogContentText">
          Are you sure do you want to cancel?
        </DialogContentText>
        <DialogActions>
          <button className="inviteButton" color="primary" onClick={cancelReg}>
            YES
          </button>
          <button
            className="inviteButton"
            color="primary"
            onClick={() => setOpen(false)}
          >
            NO
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default GettingStarted;
