import React, { useState, useEffect,useContext } from "react";
import { Tabs, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, withStyles, Grid } from "@material-ui/core";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import Loader from "react-loading";
import { usePromiseTracker } from "react-promise-tracker";
import { IoMdCloseCircleOutline } from "react-icons/io";
import "../detailViews/detailView.css";
import { trackPromise } from "react-promise-tracker";
import service from "../../../api/licenseeService";
import { useParams } from "react-router-dom";
import Service from "../../../api/adminService";
import jwt from "jwt-decode";
import LicenseeService from "../../../api/licenseeService";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import VMCTable from "../../../Recomponent/Table";
import alpha from "../../../assets/img/blank1.png";
import EmptyTable from "../../../Recomponent/EmptyTable";
import { MdWarning } from "react-icons/md";
import {
  ToggleContext
} from "../../context/toggleContext"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div>
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AntTabs = Tabs;
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    borderBottom: "1px solid #ddd ",
    minWidth: "80px",

    textShadow: "none",
    color: "#0059b6",

    "&$selected": {
      color: "#555",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderBottomColor: "transparent",
      borderBottom: "none",
      cursor: "default",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  scrollPaper: {
    alignItems: "baseline",
  },
}));

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};
function LicenseeDetailView(props) {
  let history = useHistory();
  let file = null;
  let API_URL = process.env.REACT_APP_API_URL;
  const { licenceeid } = useParams();
  const [licenseeDetail, setLicenseeDetail] = useState();
  const classes = useStyles();
  const [scroll, setScroll] = useState("paper");
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [inviteUser, setinviteUser] = useState(false);
  const [successopen, setsuccessOpen] = useState(false);
  const [successRequest, setsuccessRequest] = useState(false);
  const [userDetails, setuserDetails] = useState();
  const [user_id, setUser_id] = useState();
  const [erroropen, seterrorOpen] = useState(false);
  const [editInfo, setEditInfo] = useState(false);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [openUser, setOpenUser] = useState(false);
  const [addLIcensee, setAddLIcensee] = useState(false);
  const [checked, setChecked] = useState(true);
  const [invitedSuccess, setInvitedSuccess] = useState(false);
  const [lodervisible, setlodervisible] = useState(false);
  const [toastMsg, setToastMsg] = useState();
  const toggleContextVal = useContext(ToggleContext)
  const [userid, setUserId] = useState({
    userId: "",
  });
  const [userNameError, setUserNameError] = useState({
    username: false,
    msg: "",
  });
  const [tablecols, setTableCols] = useState([
    {
      colName: "User’s Name",
      colMap: "name",
      linkName: true,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: true,
    },
    {
      colName: "Email Address",
      colMap: "email",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Role",
      colMap: "role",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Type",
      colMap: "userType",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Verified",
      colMap: "verified",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Status",
      colMap: "status",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Created",
      colMap: "created",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: true,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Impersonate",
      colMap: "Impersonate",
      linkName: true,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: true,
      userTab: false,
    },
  ]);

  const [tablecolschannel, setTableColsChannel] = useState([
    {
      colName: "Channel Id",
      colMap: "companyId",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Channel Name",
      colMap: "name",
      linkName: true,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Company Type",
      colMap: "companyType",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Account Level",
      colMap: "accountLevel",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "#datafree Data Credit Limit",
      colMap: "accDataCreditLimitId",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Account #datafree Usage Alert",
      colMap: "pubDataUsageAlert",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: true,
    },
    {
      colName: "Status",
      colMap: "status",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Created",
      colMap: "created",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: true,
      imprRoute: false,
      userTab: false,
    },
  ]);

  const [tablecolsdetails, setTableColsDetails] = useState([
    {
      colName: "Name",
      colMap: "name",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Description",
      colMap: "description",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Service",
      colMap: "serviceservice",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Proxy Domain",
      colMap: "proxyDomain",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Zero Rated ?",
      colMap: "zeroRated",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Status",
      colMap: "status",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "",
      colMap: "View Details",
      linkName: true,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
  ]);

  const [tablecolsgateway, setTableColsGateway] = useState([
    {
      colName: "#datafree Gateway Domain",
      colMap: "gateway",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Description",
      colMap: "description",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Status",
      colMap: "status",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Use for Products",
      colMap: "Use for Products",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Points to Service",
      colMap: "Points to Service",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
    setinviteUser(false);
    setsuccessRequest(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
    setsuccessRequest(false);
    setResultMsg(false);
  };
  const PopClose = () => {

    //alert();
    setOpenPopup(false);
  }
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setAddLIcensee(false);
      setOpen(false);
      setOpenUser(false);
      setinviteUser(false);
      setRemoveDialogue(false);
    }


    setEditInfo(false);

    setImgRemoved(false);
    setErrorServiceField({
      service_name: false,
      licensee_service_description: false,
      licensee_service_types: false,
      licensee_service_zerorated: false,
      licensee_service_status: false,
      licensee_service_status: false,

    });

    setInvite({
      name: "",
      email_address: "",
      user_role: "",
      companyId: "",
    });
    setError({
      company_name: false,
      logo_icon_image: false,
      imgValidmsg: "",
      msg: "",

    });
    setInviteError({
      name: false,
      email_address: false,
      user_role: false,
      companyId: false,
      nameErMsg: "",
      emailErrMsg: "",
      roleErrMsg: "",
    });


    SetProductData([]);
    setServiceData({
      service_name: "",
      licensee_service_description: "",
      licensee_service_types: "",
      supported_products: [],
      licensee_service_zerorated: "",
      licensee_service_status: "",
      ldip_id: "",
      HNI: "",
      company_id: "",
    });
  };



  const edit = () => {
    setEditInfo(true);
    localStorage.setItem("EditOpen", true);
  };
  const [invite, setInvite] = useState({
    name: "",
    email_address: "",
    user_role: "",
    companyId: "",
  });
  const [inviteError, setInviteError] = useState({
    name: false,
    email_address: false,
    user_role: false,
    companyId: false,
    nameErMsg: "",
    emailErrMsg: "",
    roleErrMsg: "",
  });

  const [empty, setEmpty] = useState(false);

  const reg = /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/;

  const handleUser = (e) => {
    e.preventDefault();
    const invitedata = { ...invite };
    invitedata[e.target.id] = e.target.value;
    invitedata["companyId"] = licenceeid;
    if (e.target.id === "name") {
      setInviteError({
        ...inviteError,
        name: false,
      });
    }
    if (e.target.id === "email_address") {
      if (e.target.value) {
        EmailCheck(e.target.value);
      } else {
        setError({
          ...inviteError,
          email_address: true,
          emailErrMsg: "This field is required",
        });
      }
    }
    if (e.target.id === "user_role") {
      setInviteError({
        ...inviteError,
        user_role: false,
      });
    }

    setInvite(invitedata);
  };

  ////Check Email
  async function EmailCheck(value) {
    trackPromise(
      Service.EmailCheck({ email_address: value })
        .then((res) => {
          if (res.data === false) {
            setInviteError({
              ...inviteError,
              email_address: true,
              emailErrMsg: "This​ email address already exists please use admin utilities to check current user access",
            });
          } else if (value && reg.test(value) === false) {
            setInviteError({
              ...inviteError,
              email_address: true,
              emailErrMsg: "Please enter a valid email address.",
            });
          } else {
            setInviteError({
              ...inviteError,
              email_address: false,
            });
          }
        })
        .catch((error) => {

        })
    );
  }

  const [loading, setLoading] = useState(false);
  const [clickInvite, setClickInvite] = useState(false);
  //invite New
  async function InviteNew() {
    if (invite.name.length == 0 && invite.email_address.length == 0 && invite.user_role.length == 0) {
      setInviteError({
        name: true,
        email_address: true,
        user_role: true,
      });
    } else if (inviteError.name) {
      setInviteError({
        ...inviteError,
        name: true,
      });
    } else if (inviteError.email_address) {
      setInviteError({
        ...inviteError,
        email_address: true,
      });
    } else if (inviteError.user_role) {
      setInviteError({
        ...inviteError,
        user_role: true,
      });
    } else {
      setLoading(true);
      setClickInvite(true);
      try {
        trackPromise(
          Service.invitechannelUser(invite).then((res) => {

            if (res.status === 200) {
              handleClose();
              setInvitedSuccess(true);
              setinviteUser(false);
              setLoading(false);

            } else {
              seterrorOpen(true);
              setEditInfo(true);
              setInvitedSuccess(false);
            }
            setClickInvite(false);
            getLicenseeData();
            licenseeDetail();
          })
        );
      } catch (err) {
        setErrMessage("Something went wrong. Please reload the page");
        seterrorOpen(true);
      }
    }
  }
  const channelRoute = (channelId) => {
    history.push(`/admin/Channel/${channelId}/detail/view`);
  };
  const viewSevice = (licId) => {
    history.push(`/admin/licensee/${licId}/service/details`, { licseeid: licenceeid });
  };
  const redirectRoute = () => {
    history.push("/admin/company/index");
  };
  const inviteNew = () => {
    setinviteUser(true);
    setScroll('body');
  };

  const [emailId, setEmailId] = useState({
    email: "",
    role: "",
    companyId: ""
  });
  const [status, setStatus] = useState({
    id: "",
  });
  const [serviceData, setServiceData] = useState({
    service_name: "",
    licensee_service_description: "",
    licensee_service_types: "",
    supported_products: [],
    licensee_service_zerorated: "",
    licensee_service_status: "",
    ldip_id: "",
    HNI: "",
    company_id: "",
  });
  const [errorServiceField, setErrorServiceField] = useState({
    service_name: false,
    licensee_service_description: false,
    licensee_service_types: false,
    licensee_service_zerorated: false,
    licensee_service_status: false,
    msg: "",
  });
  const handleLIcecenseeAdd = (scrollType) => {
    setAddLIcensee(true);
    setScroll(scrollType);
  }
  const [removeDialogue, setRemoveDialogue] = useState(false);
  const [ImgRemoved, setImgRemoved] = useState(false);
  const removeLogo = () => {
    setImgRemoved(true);
    setRemoveDialogue(false);
    setEditData({
      company_name: licenseeDetail.publisherDetail.name,
      logo_icon_image: null,
      status: licenseeDetail.publisherDetail.status,
      company_id: licenseeDetail.publisherDetail.companyId,
      removeCompanyLogo: "yes",

    });

    setImage({ preview: "", raw: "" });
  };
  const DialogClose = () => {

    setImgRemoved(false);
    setRemoveDialogue(false);
  }

  //editUser Detail
  async function EditUserData() {
    setlodervisible(true);
    if (userNameError.username) {
      setUserNameError({
        username: true,
      });
    } else {
      try {
        trackPromise(
          Service.editUser(userdata).then((res) => {
            setlodervisible(false);
            if (res.status === 200) {
              setToastMsg("User Details Edited Successfully!");
              setEditInfo(false);
              setsuccessOpen(true);
              setOpenUser(false);
              getLicenseeData();
            } else {
              seterrorOpen(true);
              getLicenseeData();
              setOpenUser(true);
            }
            getLicenseeData();
          })
        );
      } catch (err) {
        setErrMessage("Something went wrong. Please reload the page");
        seterrorOpen(true);
      }
    }
  }
  const [productData, SetProductData] = useState();

  //CHECK STATUS
  async function CheckStatus(service_type) {
    status["id"] = service_type;
    serviceData.supported_products.splice(0, serviceData.supported_products.length);
    setSelectedCheckValue({});
    try {
      trackPromise(
        Service.CheckAdminStatus(status).then((res) => {
          SetProductData(res.data.licenseeDomainServiceStatus.products ? res.data.licenseeDomainServiceStatus.products : "");

          let newSelectedCheckValue = { ...selectedCheckValue };

          for (let i = 0; i <= res.data.licenseeDomainServiceStatus.products.length; i++) {

            newSelectedCheckValue[i] = res.data.licenseeDomainServiceStatus.products[i].productId;

            serviceData.supported_products.push({ supported_prodducts: res.data.licenseeDomainServiceStatus.products[i].productId });
            if (i == res.data.licenseeDomainServiceStatus.products.length - 1) {

              setSelectedCheckValue(newSelectedCheckValue);


            }

          }

        })
      );
    } catch (err) {

    }
  }
  const [message, setMessage] = useState();
  //passwordReset
  async function resetPassword(EMAIL) {

    try {
      trackPromise(
        Service.passWordReset({ email: EMAIL, admin: "admin" }).then((res) => {
          if (res.status === 200) {
            setsuccessRequest(true);
            setMessage(res.data.message);
            handleClose();
          }
        })
      );
    } catch (err) {
      setErrMessage("Something went wrong. Please reload the page");
      seterrorOpen(true);
    }
  }

  const [clickSave, setClickSave] = useState(false);
  //ADD LICENSEE SERVICE
  async function AddService() {
    setlodervisible(true);
    if (
      serviceData.service_name.length === 0 ||
      serviceData.licensee_service_description.length === 0 ||
      serviceData.licensee_service_types.length === 0 ||
      serviceData.licensee_service_zerorated.length === 0 ||
      serviceData.licensee_service_status.length === 0
    ) {
      setErrorServiceField({
        service_name: serviceData.service_name.length === 0 ? true : false,
        licensee_service_description: serviceData.licensee_service_description.length === 0 ? true : false,
        licensee_service_types: serviceData.licensee_service_types.length === 0 ? true : false,
        licensee_service_zerorated: serviceData.licensee_service_zerorated.length === 0 ? true : false,
        licensee_service_status: serviceData.licensee_service_zerorated.length === 0 ? true : false,
        licensee_service_status: serviceData.licensee_service_status.length === 0 ? true : false,
        msg: "This field is required",
      });
    }

    else {
      setClickSave(true);
      try {
        trackPromise(
          Service.AddAdminLicenseeService(serviceData).then((res) => {
            setlodervisible(false);
            if (res.status === 200) {
              setToastMsg("Licensee Service Added Successfully!");
              setClickSave(false);
              setsuccessOpen(true);
              getLicenseeData();
              handleClose();
            }
          })
        );
      } catch (err) {
        setErrMessage("Something went wrong. Please reload the page");
        seterrorOpen(true);
      }
    }
  }
  const [selectedCheckValue, setSelectedCheckValue] = useState({});

  const handleCheck = (e, checkValue, index) => {
    if (selectedCheckValue[index] == checkValue) {
      let newSelectedCheckValue = { ...selectedCheckValue };
      newSelectedCheckValue[index] = 0;
      setSelectedCheckValue(newSelectedCheckValue);
      let newSelected = { ...serviceData.supported_products };
      newSelected[index] = 0;
      serviceData.supported_products.splice(index, 1);

    } else {
      let newSelectedCheckValue = { ...selectedCheckValue };
      newSelectedCheckValue[index] = checkValue;
      setSelectedCheckValue(newSelectedCheckValue);
      serviceData.supported_products.push({ supported_prodducts: checkValue });
    }
  };

  const handleServiceChange = (e) => {
    e.preventDefault();
    const newserviceData = { ...serviceData };
    newserviceData[e.target.id] = e.target.value;
    newserviceData["company_id"] = licenceeid;
    if (e.target.id === "service_name") {
      setErrorServiceField({
        ...errorServiceField,
        service_name: false,
      });
    }
    if (e.target.id === "licensee_service_description") {
      setErrorServiceField({
        ...errorServiceField,
        licensee_service_description: false,
      });
    }
    if (e.target.id === "licensee_service_types") {
      setErrorServiceField({
        ...errorServiceField,
        licensee_service_types: false,
      });
    }
    if (e.target.id === "licensee_service_zerorated") {
      setErrorServiceField({
        ...errorServiceField,
        licensee_service_zerorated: false,
      });
    }
    if (e.target.id === "licensee_service_zerorated") {
      setErrorServiceField({
        ...errorServiceField,
        licensee_service_zerorated: false,
      });
    }
    if (e.target.id === "licensee_service_status") {
      setErrorServiceField({
        ...errorServiceField,
        licensee_service_status: false,
      });
    }

    if (e.target.id === "licensee_service_types") {
      CheckStatus(e.target.value);
    }

    setServiceData(newserviceData);
  };

  const [error, setError] = useState({
    company_name: false,
    logo_icon_image: false,
    imgValidmsg: "",
    msg: "",

  });
  const [nameCheck, setNameCheck] = useState({
    company_name: "",
    companyid: "",
  });
  const cmpyReg = /[a-zA-Z]/;
  ////CompanyCheck
  async function companyCheck(cname) {
    nameCheck["company_name"] = cname;
    nameCheck["companyid"] = licenceeid;
    try {
      trackPromise(
        Service.companyCheck(nameCheck).then((res) => {
          if (res.data === false) {
            setError({
              ...error,
              company_name: true,
              msg: "This​ Company ​name ​already​ exist",
            });
          }
          else {
            if (cname.includes(",")) {
              setError({
                ...error,
                company_name: true,
                msg: "Please enter a name without comma",
              });
            }
            else if (cmpyReg.test(cname) === false) {
              setError({
                ...error,
                company_name: true,
                msg: "Field must have at least 1 character a-z or A-Z",
              });
            }
            else {
              setError({
                ...error,
                company_name: false,
              });
            }
          }
        })
      );
    } catch (err) {

    }
  }

  const impersonate = (emailid, role, Id, type, name) => {
    emailId["email"] = emailid;
    emailId["role"] = role;
    emailId["companyId"] = Id;
    try {
      trackPromise(
        Service.impersonateUser(emailId).then((res) => {

          if (res.status === 200 && res.statusText === "OK") {
            localStorage.setItem("token", res.data);
            localStorage.setItem("Impersonation", true);
            localStorage.setItem("companyname", name)
            localStorage.setItem("companytype", type)
            localStorage.setItem("companyrole", role)
            var decode1 = jwt(res.data);

            if (decode1.roles[0] === "ROLE_USER") props.history.push("/user/datafree");
            else if (decode1.roles[0] === "ROLE_RESELLER") props.history.push("/user/datafree");
            else if (decode1.roles[0] === "ROLE_CUSTOMER") props.history.push("/user/datafree");

            else if (decode1.roles[0] === "ROLE_LICENSEE") props.history.push("/licensee/index/action");
            else if (decode1.roles[0] === "ROLE_CHANNEL") props.history.push("/channel/application/index");
            else if (decode1.roles[0] === "ROLE_ACCOUNTMANAGER") props.history.push("/accountmanager/applications/index");
          } else {
            alert("!please try again");
          }
        })
      );
    } catch (err) {

    }
  };
  const [editData, setEditData] = useState({
    company_name: "",
    logo_icon_image: "",
    status: "",
    company_id: "",
    removeCompanyLogo: "",
  });

  const dataChange = (e) => {

    const newdata = { ...editData };
    newdata[e.target.id] = e.target.value;
    if (e.target.id === "logo_icon_image") {
      if (e.target.files.length !== 0) {

        let img = new Image();
        img.src = URL.createObjectURL(e.target.files[0]);
        var filePath = e.target.files[0].name;
        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        if (!allowedExtensions.exec(filePath)) {
          setImage({
            preview: "",
            raw: "",
          });
          setError({
            ...error,
            logo_icon_image: true,
            imgValidmsg: "Selected file is not an image",
          });
        }
        else {
          img.onload = () => {
            if (img.width < 180) {
              setImage({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                logo_icon_image: true,
                imgValidmsg: "Minimum width must be 180px",
              });
            }
            else if (img.height < 65) {
              setImage({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                logo_icon_image: true,
                imgValidmsg: "Minimum height must be 65px",
              });
            }
            else {
              setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
              });
              setError({
                ...error,
                logo_icon_image: false,
                imgValidmsg: "",
              });
            }
          };
        }

      }


    }

    if (e.target.id === "company_name") {
      if (e.target.value) {
        companyCheck(e.target.value);
      } else {
        setError({
          ...error,
          company_name: true,
          msg: "This field is required",
        });
      }
    }

    setEditData(newdata);
  };
  const [userdata, setuserdata] = useState({
    username: "",
    user_role: "licensee",
    user_type: "Intenal",
    user_verified: "No",
    user_status: "Active",
    user_id: "",
    company_id: "",
  });
  const handleLicenseeUser = (e) => {
    e.preventDefault();
    const newdata = { ...userdata };
    newdata[e.target.id] = e.target.value;
    newdata["user_id"] = user_id;
    newdata["company_id"] = licenceeid;
    if (e.target.id === "username") {
      if (e.target.value === "") {
        setUserNameError({
          username: true,
          msg: "This field is required",
        });
      } else {
        setUserNameError({
          username: false,
        });
      }
    }
    setuserdata(newdata);
  };
  //getUser
  async function getUserDetail(value, Id) {
    setuserDetails({});
    setUser_id({});

    try {
      trackPromise(
        Service.getUserById({ userId: value, companyId: Id }).then((res) => {
          setuserDetails(res.data);
          setuserdata({
            username: res.data.userName,
            user_role: res.data.role,
            user_type: res.data.type,
            user_verified: res.data.verified && res.data.verified == 1 ? "Yes" : "No",
            user_status: res.data.status,
            user_id: res.data.userId,
            company_id: res.data.companyId,
          });
          setUser_id(res.data.userId);

        })
      );
    } catch (err) {
      setErrMessage("Something went wrong. Please reload the page");
      seterrorOpen(true);
    }
  }

  const OpenUserTab = (scrollType, Id, cId) => {
    userid["userId"] = Id;
    setUserId(userid);

    getUserDetail(Id, cId);
    setOpenUser(true);
    setScroll(scrollType);
  };

  const [resultmsg, setResultMsg] = useState(false);

  //editLicensee
  async function EditLicenseeData() {

    if (error.company_name) {
      setError({
        ...error,
        company_name: true,
      });
    } else {
      LicenseeService.adminLicenseeEditRequest(editData).then((res) => {
        setlodervisible(false);
        if (res.status === 200) {
          setToastMsg("Channel Details Edited Successfully!")
          setEditInfo(false);
          setsuccessOpen(true);
        } else {
          seterrorOpen(true);
        }
        getLicenseeData();
      }).catch((error) => {
        setMessage(error.response.data);
        setResultMsg(true)
        if (error?.response?.status === 500)
          setlodervisible(false);
        setErrMessage(error?.response?.data?.data);
        seterrorOpen(true);
      })


    }
  }
  const [openPopup, setOpenPopup] = useState(false);

  document.addEventListener("click", e => {
    let editNot = localStorage.getItem("EditOpen");
    if (editNot) {
      if (e.target.id === "Edit" || e.target.id === "Cancel" || e.target.id === "Save" || e.target.name === "logo_icon_image" || e.target.id === "logo_icon_image" ||
        e.target.type === "file" || e.target.name === "company_name" || e.target.name === "popup" || e.target.id === "status" || e.target.id === "Remove") {

        if (e.target.id === "Cancel" || e.target.id === "Save" || e.target.id === "Return") {
          localStorage.removeItem("EditOpen");
        }
        setOpenPopup(false);
      }
      else {
        setOpenPopup(true);
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();
      }
    }
    else {
      setOpenPopup(false);
    }

  }, true);
  const [errMessage, setErrMessage] = useState();
  //edit Image
  async function EditImageData(e) {


    e.preventDefault();
    editData["company_id"] = licenceeid;
    let file = document.getElementById("logo_icon_image").files[0];
    if (error.logo_icon_image === false) {
      let values = file != undefined ? new FormData() : "";
      if (file != undefined) {
        values.append("logo_icon_image", file ? file : "");
      }
      if (values.toString().trim().length > 0) {
        Service.editImage(values).then((res) => {
          if (res.status === 200) {
            setImgRemoved(false)
            editData["logo_icon_image"] = res.data;
            EditLicenseeData(editData);
          }
        }).catch((err) => {
          if (err?.response?.status === 500)
            setErrMessage(err?.response?.data?.data);
          seterrorOpen(true);
        })
      } else {
        EditLicenseeData(editData);
      }
    }
  }

  const getLicenseeData = () => {
    setlodervisible(true);
    setLicenseeDetail({});
    setEmpty(false);
    trackPromise(
      service
        .licenseeDetailView(licenceeid)
        .then((res) => {
          setlodervisible(false);
          setLicenseeDetail(res.data);
          setEditData({
            company_name: res.data.publisherDetail.name,
            logo_icon_image: res.data?.publisherDetail?.logoFile ? res.data?.publisherDetail?.logoFile : "",
            status: res.data.publisherDetail.status,
            company_id: res.data.publisherDetail.companyId,
            removeCompanyLogo: ""
          });
          if (
            (res.status == 200 && res.data.usersListDetail.length === 0) ||
            res.data.licenseeChannelList.length === 0 ||
            res.data.licenseeService.length === 0 ||
            res.data.gatewayDomain.length === 0
          ) {
            setEmpty(true);
          } else {
            setEmpty(false);
          }
        })
        .catch((err) => {
          setErrMessage("Something went wrong. Please reload the page");
          seterrorOpen(true);
        })
    );
  };


  useEffect(() => {
    getLicenseeData();
    setlodervisible(true);
    localStorage.removeItem("EditOpen");
  }, []);

  return (
    <div className="accountContent">
      <div className={toggleContextVal.event ?"accountMenuView" :"accountView"}>
        <div className="accounthead">
          <div className="accountNavtext">Company Details - Admin View</div>
        </div>
        <AntTabs
          value={value}
          className="tabViewMenu"
          onChange={handleChange}
          aria-label="ant example"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          <AntTab label="Company" title="Click here to view Company Tab" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
          <AntTab label="Users" title="Click here to view Users Tab" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
          <AntTab label="Channel" title="Click here to view Publishers Tab" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
          <AntTab label="Licensee Services" title="Click here to view Deployed IPs" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
          <AntTab label="Gateway Domain" title="Click here to view Gateway Domain" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
        </AntTabs>

        <TabPanel value={value} index={0}>
          {
            lodervisible &&
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: '0px',
              right: '0px',
              margin: 'auto',
              position: 'absolute',
              left: '0px',
              bottom: '0px',
              zIndex: '3000',
              backgroundColor: "#ffffff",

              paddingTop: '8px',
              paddingBottom: '8px',
              borderRadius: '5px',
              flexDirection: 'column',
              width: '70px',
              height: '60px'
            }} >
              <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />

            </div>
          }
          {licenseeDetail?.publisherDetail ?
            <div>
              <div className="row">
                <div className="col-md-4 form-group">
                  <div className="companyLabel">Company Name</div>
                  {!editInfo ? (
                    <input type="text" className="form-control" disabled="disabled" style={{ cursor: "not-allowed" }} value={licenseeDetail?.publisherDetail?.name} />
                  ) : (
                    <input
                      type="text"
                      className={error.company_name ? "errorField form-control" : "form-control"}

                      name="company_name"
                      id="company_name"
                      defaultValue={licenseeDetail?.publisherDetail?.name}
                      onChange={(e) => dataChange(e)}
                      onBlur={(e) => dataChange(e)}
                    />
                  )}
                  {error.company_name ? <a className="errorClass">{error.msg ? error.msg : "This field is required"}</a> : null}
                </div>

                <div className="col-md-4 form-group">
                  {!editInfo ? <div className="companyLabel">Company Logo</div> : ""}
                  {editInfo || licenseeDetail?.publisherDetail.logoFile !== null ?
                    <div className="fileIcon">
                      <img src={
                        image.preview
                          ? image.preview :
                          !ImgRemoved && licenseeDetail?.publisherDetail.logoFile !== null ?
                            API_URL + licenseeDetail?.logoFile + licenseeDetail?.publisherDetail.logoFile
                            : alpha}
                        onError={(event) => event.target.src = alpha}
                        alt=""
                        className="imgStyle"
                      ></img>
                    </div> : ""}
                  {editInfo ? (
                    <div className="imgFileRow">
                      <div className="companyLabel">Upload Company Logo </div>
                      <div className="filesubText">(Min. dimensions of 180px X 65px)</div>
                      <input id="logo_icon_image" type="file" name="logo_icon_image" onChange={(e) => dataChange(e)} />
                      <label htmlFor="file">{file}</label>
                      {error.logo_icon_image ? <a className="errorClass">{error.imgValidmsg ? error.imgValidmsg : ""}</a> : null}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-4 form-group">
                  {!editInfo ? (
                    <button className="inviteButton" title="Click here to edit publisher details" id="Edit" onClick={edit}>
                      Edit Details
                    </button>
                  ) : null}
                </div>
              </div>
              {editInfo && !ImgRemoved && licenseeDetail?.publisherDetail?.logoFile ? (
                <div className="row">
                  <div className="col-md-6 form-group"></div>

                  <div className="col-md-6 form-group">
                    <div className="ivitediv">
                      <button className="changeOwnButton spaceTop" id="Remove" name="popup" onClick={() => setRemoveDialogue(true)}>
                        Remove Company Logo
                      </button>
                    </div>
                  </div>
                </div>) : null}
              <div className="row">
                <div className="col-md-4 form-group">
                  <div className="companyLabel">Licensee Shortcode</div>
                  <div>
                    <input type="text" className="form-control" name="CustomerIdentifier" disabled="disabled"
                      style={{ cursor: "not-allowed" }} value={licenseeDetail?.publisherDetail?.licenseeShortcode} />
                  </div>
                </div>
                <div className="col-md-8 form-group"></div>
              </div>
              <div className="row">
                <div className="col-md-4 form-group">
                <div className="companyLabel">Status</div>
               
                  {!editInfo ? (
                    <input type="text" className="form-control" name="CustomerIdentifier" style={{ cursor: "not-allowed" }} disabled="disabled" value={licenseeDetail?.publisherDetail?.status} />
                  ) : (
                    <select type="text" className="form-control" name="status"
                     
                      id="status" onChange={(e) => dataChange(e)}
                      onBlur={(e) => dataChange(e)}>


                      {licenseeDetail?.publisherDetail?.status === "Approved" ? (<>
                        <option value="Approved" selected>Approved</option>
                        <option value="Suspended">Suspended</option>
                        <option value="Archived">Archived</option>

                      </>) :

                        licenseeDetail?.publisherDetail?.status === "New" ? (<>
                          <option value="New" selected>New</option>
                          <option value="Approved">Approved</option>
                          <option value="NoAgreement">NoAgreement</option>

                        </>) :
                          licenseeDetail?.publisherDetail?.status === "NoAgreement" ? (<>
                            <option value="NoAgreement" selected>NoAgreement</option>
                            <option value="Approved">Approved</option>
                            <option value="Archived">Archived</option>

                          </>) :
                            licenseeDetail?.publisherDetail?.status === "Suspended" ? (<>
                              <option value="Suspended" selected>Suspended</option>
                              <option value="Approved">Approved</option>
                              <option value="Archived">Archived</option>

                            </>) :
                              licenseeDetail?.publisherDetail?.status === "Archived" ? (<>
                                <option value="Archived" selected>Archived</option>

                              </>) :
                                null}
                    </select>
                  )}
                </div>
              </div>
            </div>

            : null}

          <div className="ivitediv">
            {editInfo ? (
              <button className="inviteButton" id="Cancel" onClick={handleClose} color="primary">
                Cancel
              </button>
            ) : null}
            {editInfo ? (
              <button className="inviteButton" id="Save" color="primary" onClick={EditImageData}>
                Save
              </button>
            ) : null}
          </div>
          <div className="ivitediv">
            <button className="inviteButton" id="Return" onClick={redirectRoute}>
              Return to Company List
            </button>
          </div>

        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="ivitediv">
            <button className="inviteButton" onClick={inviteNew}>
              + Invite New User
            </button>
          </div>

          <div>
            {licenseeDetail?.usersListDetail?.length > 0 ? (
              <VMCTable data={licenseeDetail.usersListDetail} col={tablecols} tabNavigation={OpenUserTab} imprNavigation={impersonate} tableName="Admin License Details" />
            ) : (
              <div>
                <LoadingIndicator />
                {empty ? <EmptyTable data="No Users Found" col={tablecols} tableName="Admin License Details" /> : null}
              </div>
            )}
          </div>

          <div className="ivitediv">
            <button className="inviteButton" onClick={redirectRoute}>
              Return To Company List
            </button>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          {licenseeDetail?.licenseeChannelList?.length > 0 ? (
            <VMCTable data={licenseeDetail.licenseeChannelList} col={tablecolschannel} navigateFunction={channelRoute} tableName="Admin License Details Channel" />
          ) : (
            <div>
              <LoadingIndicator />
              {empty ? <EmptyTable data="No Channels Found" col={tablecolschannel} tableName="Admin License Details Channel" /> : null}
            </div>
          )}
          <div className="ivitediv">
            <button className="inviteButton" onClick={redirectRoute}>
              Return To Company List
            </button>
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div className="tableFlexRow">
            <div className="cmpdialogtitle cmptitleColor">Services</div>
            <div className="cmpdialogtitle">
              <button className="inviteButton" onClick={() => handleLIcecenseeAdd('body')}>
                Add Licensee Service
              </button>
            </div>
          </div>
          {/* <div className="ivitediv">
            <button className="inviteButton" onClick={() => setAddLIcensee(true)}>
              Add Licensee Service
            </button>
          </div> */}
          {licenseeDetail && licenseeDetail?.licenseeService?.length > 0 ? (
            <VMCTable data={licenseeDetail.licenseeService} col={tablecolsdetails} navigateFunction={viewSevice} tableName="Admin License Details View" />
          ) : (
            <div>
              <LoadingIndicator />
              {empty ? <EmptyTable data="No Licensee Services Found" col={tablecolsdetails} tableName="Admin License Details View" /> : null}
            </div>
          )}

          <div className="ivitediv">
            <button className="inviteButton" onClick={redirectRoute}>
              Return To Company List
            </button>
          </div>
        </TabPanel>
        <TabPanel value={value} index={4}>
          {licenseeDetail && licenseeDetail?.gatewayDomain?.length > 0 ? (
            <VMCTable data={licenseeDetail?.gatewayDomain} col={tablecolsgateway} tableName="Admin Gateway Domain Details View" additionData={licenseeDetail} />
          ) : (
            <div>
              <LoadingIndicator />
              {empty ? <EmptyTable data="No Records Found" col={tablecolsgateway} tableName="Admin Gateway Domain Details View" /> : null}
            </div>
          )}

          <div className="ivitediv">
            <button className="inviteButton" id="Return" onClick={redirectRoute}>
              Return To Company List
            </button>
          </div>
        </TabPanel>
      </div>
      <Dialog open={open} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm"
        onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle">
          <div className="FlexRow">
            <div className="cmpdialogtitle">  Invite Publisher User </div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} />
            </div>
          </div>

        </DialogTitle>
        <DialogContent>
          <div className="dialoginputtext">Name:</div>
          <input type="text" id="name" label="name" className="dialoginput" fullWidth />
          <div className="dialoginputtext">Email:</div>
          <input type="text" id="email" label="email" className="dialoginput" fullWidth />
        </DialogContent>
        <DialogActions>
          <button className="inviteButton" onClick={handleClose} color="primary">
            Cancel
          </button>
          <button className="inviteButton" onClick={handleClose} color="primary">
            Invite User
          </button>
        </DialogActions>
      </Dialog>
      {/* //***************adminLIcensee Users*********** */}
      <Dialog open={openUser} classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth scroll={scroll} maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle">
          <div className="FlexRow">
            <div className="cmpdialogtitle">User Details - Admin View</div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="dialoginputtext">Company Name</div>
          <input type="text" disabled="disabled" className="form-control" defaultValue={userDetails ? userDetails.companyName : ""} fullWidth />
          <div className="dialoginputtext">Company Type</div>
          <input type="text" disabled="disabled" className="form-control" defaultValue={userDetails ? userDetails.companyType : ""} fullWidth />
          <div className="dialoginputtext">User’s Name</div>
          <input
            type="text"
            id="username"
            name="username"
            label="username"
            className={userNameError.username ? "form-control errorField" : "form-control"}
            onChange={(e) => handleLicenseeUser(e)}
            defaultValue={userDetails ? userDetails.userName : ""}
            fullWidth
          />
          {userNameError.username ? <a className="errorClass">{userNameError.msg ? userNameError.msg : "This field is required"}</a> : null}
          <div className="dialoginputtext">Email Address</div>
          <input type="text" disabled="disabled" className="form-control" defaultValue={userDetails ? userDetails.email : ""} onChange={(e) => handleLicenseeUser(e)} fullWidth />
          <div className="dialoginputtext">Role</div>
          <select
            type="text"
            id="user_role"
            name="user_role"
            label="email"
            className="form-control form-select"
            onInput={(e) => handleLicenseeUser(e)}
            //onChange={(e) => handleLicenseeUser(e)}
            fullWidth
          >

            <option value="licensee" selected={userDetails?.role == "licensee" && true}>licensee</option>
          </select>
          <div className="dialoginputtext">Type</div>
          <select type="text" id="user_type" name="user_type" label="email" className="form-control form-select" onChange={(e) => handleLicenseeUser(e)} fullWidth>
            <option selected={userDetails?.type == "INTERNAL" && true} value="INTERNAL">
              INTERNAL
            </option>
            <option selected={userDetails?.type == "PUBLISHER" && true} value="PUBLISHER">
              PUBLISHER
            </option>
          </select>
          <div className="dialoginputtext">Verified</div>
          <select type="text" id="user_verified" name="user_verified" label="email" className="form-control form-select" onChange={(e) => handleLicenseeUser(e)} fullWidth>
            <option selected={userDetails?.verified == 1 && true} value="Yes">
              YES
            </option>
            <option selected={userDetails?.verified == 0 && true} value="No">
              NO
            </option>
          </select>
          <div className="dialoginputtext">Status</div>
          <select type="text" id="user_status" name="user_status" label="email" className="form-control form-select" onChange={(e) => handleLicenseeUser(e)} fullWidth>
            <option selected={userDetails?.status == "Active" && true} value="Active">
              Active
            </option>
            <option selected={userDetails?.status == "Deleted" && true} value="Deleted">
              Deleted
            </option>
          </select>
          <div className="dialoginputtext">Created</div>
          <input type="text" label="email" disabled="disabled" className="form-control" value={moment(userDetails ? userDetails.created : "").format("YYYY-MM-DD HH:mm") + " GMT"} />
        </DialogContent>
        <DialogActions>
          {lodervisible ? <LoadingIndicator /> : null}
          <div className="buttonRow">
            <div>
              <button className="inviteButton" color="primary" onClick={() => resetPassword(userDetails.email)}>
                Request Password Reset
              </button>
            </div>
            <div>
              <button className="inviteButton" onClick={handleClose} color="primary">
                Cancel
              </button>

              <button className="inviteButton" onClick={EditUserData} color="primary">
                Update User Details
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
      {/* //ADD lICENSEE SERVICES */}
      <Dialog open={addLIcensee}
        scroll={scroll}

        classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle">
          <div className="FlexRow">
            <div className="cmpdialogtitle"> Add Licensee Service</div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="accounthead">
            <div className="dialoginputtext flex3">Service Name:</div>

            <div className="flex6">
              <input
                type="text"
                id="service_name"
                name="service_name"
                label="name"
                className={errorServiceField.service_name ? "form-control errorClass" : "form-control"}
                fullWidth
                onChange={(e) => handleServiceChange(e)}
              />
              {errorServiceField.service_name ? <a className="errorClass">{errorServiceField.msg ? errorServiceField.msg : "This field is required"}</a> : null}
            </div>
          </div>
          <div className="accounthead">
            <div className="dialoginputtext" style={{ flex: "3" }}>
              Description:
            </div>

            <div className="flex6">
              <input
                type="text"
                id="licensee_service_description"
                name="licensee_service_description"
                className={errorServiceField.licensee_service_description ? "form-control errorClass" : "form-control"}
                fullWidth
                onChange={(e) => handleServiceChange(e)}
              />
              {errorServiceField.licensee_service_description ? <a className="errorClass" >{errorServiceField.msg ? errorServiceField.msg : "This field is required"}</a> : null}
            </div>
          </div>
          <div className="accounthead">
            <div className="dialoginputtext flex3">Service Type:</div>

            <div className="flex6">
              <select
                type="text"
                id="licensee_service_types"
                name="licensee_service_types"
                style={{ width: '100%', height: '34px' }}
                className={errorServiceField.licensee_service_types ? "form-control form-select errorClass" : "form-control form-select"}
                fullWidth
                onChange={(e) => handleServiceChange(e)}
              >
                <option value=""></option>
                <option value="CONNECT">CONNECT</option>
                <option value="CONNECT_CONFIG">CONNECT_CONFIG</option>
                <option value="DIRECT">DIRECT</option>
                <option value="REACH/SWITCH GATEWAY">REACH/SWITCH GATEWAY</option>
                <option value="WRAP/MAX GATEWAY">WRAP/MAX GATEWAY</option>
              </select>
              {errorServiceField.licensee_service_types ? <a className="errorClass">{errorServiceField.msg ? errorServiceField.msg : "This field is required"}</a> : null}
            </div>
          </div>

          <div className="subhead">Supported Products</div>
          {productData && productData.length > 0
            ? productData.map((item, index) => (
              <div key={index}>
                <label for="websiteName" className="cmpdialoginputtext">
                  {item.productName}
                </label>
                <input
                  type="checkbox"
                  name={item.productId}
                  id={item.productId}
                  onClick={(e) => handleCheck(e, item.productId, index)}
                  checked={selectedCheckValue[index] == item.productId ? true : false}
                />
              </div>
            ))
            : null}

          <div className="divfieldSpace"></div>
          <div className="accounthead">
            <div className="dialoginputtext flex3">Zero Rated ?:</div>

            <div className="flex6">
              {" "}
              <select
                type="text"
                id="licensee_service_zerorated"
                label="email"
                style={{ width: '100%', height: '34px' }}
                className={errorServiceField.licensee_service_zerorated ? "form-control form-select errorClass" : "form-control form-select"}
                name="licensee_service_zerorated"
                fullWidth
                onChange={(e) => handleServiceChange(e)}
              >
                <option value=""></option>
                <option value="0">NO</option>
                <option VALUE="1">YES</option>
              </select>
              {errorServiceField.licensee_service_zerorated ? <a className="errorClass" >{errorServiceField.msg ? errorServiceField.msg : "This field is required"}</a> : null}
            </div>
          </div>
          <div className="accounthead">
            <div className="dialoginputtext flex3">Status:</div>

            <div className="flex6">
              <select
                type="text"
                id="licensee_service_status"
                name="licensee_service_status"
                style={{ width: '100%', height: '34px' }}
                className={errorServiceField.licensee_service_status ? "form-control form-select errorClass" : "form-control form-select"}
                fullWidth
                onChange={(e) => handleServiceChange(e)}
              >
                <option value=""></option>
                <option value="LIVE">LIVE</option>
                <option VALUE="OFF">OFF</option>
              </select>
              {errorServiceField.licensee_service_status ? <a className="errorClass">{errorServiceField.msg ? errorServiceField.msg : "This field is required"}</a> : null}
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          {lodervisible ? <LoadingIndicator /> : null}
          <div className="ivitediv">
            <button className="inviteButton" onClick={handleClose} color="primary">
              Cancel
            </button>

            <button className="inviteButton" onClick={AddService}
              disabled={clickSave ? "disabled" : false}
              color="primary">
              Save
            </button>
          </div>
        </DialogActions>
      </Dialog>

      {/* //INVITE uSER */}
      <Dialog open={inviteUser} classes={{ scrollPaper: classes.scrollPaper }}
        scroll={scroll}
        fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle">
          <div className="FlexRow">
            <div className="cmpdialogtitle"> Invite User - Admin View</div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} />
            </div>
          </div>


        </DialogTitle>

        <DialogContent>

          <div className="dialoginputtext">Name:</div>
          <input type="text" id="name" label="name" className={inviteError.name ? "form-control" : "form-control"} onChange={(e) => handleUser(e)} fullWidth />
          {inviteError.name ? <a className="errorClass">{inviteError.nameErMsg ? inviteError.nameErMsg : "This field is required"}</a> : null}
          <div className="dialoginputtext">Email:</div>
          <input
            type="text"
            id="email_address"
            name="email_address"
            label="email"
            className={inviteError.email_address ? "form-control errorField" : "form-control"}
            onChange={(e) => handleUser(e)}
            fullWidth
          />
          {inviteError.email_address ? <a className="errorClass">{inviteError.emailErrMsg ? inviteError.emailErrMsg : "This field is required"}</a> : null}
          <div className="dialoginputtext">Role:</div>
          <select type="text" id="user_role" name="user_role" label="email" className={inviteError.user_role ? "form-control form-select errorField" : "form-control form-select"} onChange={(e) => handleUser(e)} fullWidth>
            <option value=""></option>
            <option value="licensee">licensee</option>

          </select>
          {inviteError.user_role ? <a className="errorClass">{inviteError.roleErrMsg ? inviteError.roleErrMsg : "This field is required"}</a> : null}

        </DialogContent>

        <DialogActions>
          {loading ? <div className="loaderContainer">
            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="ivitediv">
            <button className="inviteButton" onClick={handleClose} color="primary">
              Cancel
            </button>
            <button className="inviteButton"
              disabled={clickInvite ? "disabled" : false}
              onClick={InviteNew} color="primary">
              Invite User
            </button>
          </div>
        </DialogActions>
      </Dialog>
      {/* ////////////////// Remove Company Logo ///////////////// */}
      <Dialog open={removeDialogue} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" name="popup" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle  border">
          <div className="FlexRow border" >
            <div className="cmpdialogtitle"> </div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={handleClose} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="ContentHead">
            <div className="warningIcon"><MdWarning /></div>
            <div className="warningText">WARNING !</div>

          </div>
          <div className="logoConfirmtext">Are you sure, do you want to delete Company Logo?</div>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv">
            <button className="continueBtn" name="popup" onClick={DialogClose} color="primary">
              Cancel
            </button>

            <button className="continueBtn" name="popup" onClick={removeLogo} color="primary">
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>


      {/*///////////////////////Popup//////////////////////////////*/}
      <Dialog open={openPopup} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }}
        aria-labelledby="form-dialog-title">

        <DialogContent className="diaContent">
          <div className="logoConfirmtext">Please Save or Cancel the changes before proceeding</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" name="popup" color="primary">
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>



      <Dialog open={invitedSuccess} classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Success Message</div>
        </DialogTitle>
        <DialogContent className="diaContent">
          <div className="logoConfirmtext" style={{ fontSize: '17px' }}>Successfully added new user. Invitation link sent to user's E-mail id.</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" style={{ width: '11%' }} name="popup" color="primary" onClick={() => setInvitedSuccess(false)}>
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <Snackbar open={successopen} autoHideDuration={1500} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
          {toastMsg ? toastMsg : "Updated Successfully!"}
        </Alert>
      </Snackbar>

      <Snackbar open={resultmsg} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar open={successRequest} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar open={erroropen} autoHideDuration={2500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {errMessage ? errMessage : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default LicenseeDetailView;
