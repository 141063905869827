import React, { useState, useEffect } from "react";
import "./style.css"
import {
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Box,
  withStyles,
  Grid,
  Typography,
} from "@material-ui/core";
import service from "../../../../../api/service";
import services from "../../../../../api/adminService";
import Loader from "react-loading";
import {MdWarning} from "react-icons/md";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { trackPromise } from "react-promise-tracker";
import { FaLongArrowAltDown, FaTimes, FaDownload } from "react-icons/fa";

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",

    // default center
  },
});

const AntTabs = Tabs;

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    borderBottom: "1px solid #ddd ",
    minWidth: "80px",

    textShadow: "none",
    color: "#0059b6",

    "&$selected": {
      color: "#555",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderBottomColor: "transparent",
      borderBottom: "none",
      cursor: "default",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div style={{ backgroundColor: 'white', marginTop:'0px' }}>
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function WrapDevelopment(props) {
  let API_URL = process.env.REACT_APP_API_URL;
  const [applicationData, setApplicationData] = useState(false);
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [mdnewconfig, setmdnewconfig] = useState(false)
  const [mdcloneconfig, setmdcloneconfig] = useState(false);
  const [mdbuildapk, setmdbuildapk] = useState(false)
  const [appdelet, setappdelet] = useState(false)
  const [colorchecked, setColorchecked] = useState(false);
  const [accentcolorchecked, setAccentColorchecked] = useState(false);
  const [actioncolorchecked, setActionColorchecked] = useState(false);

  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [configDetail, setCOnfigDetail] = useState();

  const getApplicationDetails = () => {
        setLoading(true)
        service.applicationData(props.applicationId).then((res) => {
          setLoading(false);
          setApplicationData(res.data.applicationObj);
          setCOnfigDetail(res.data.applicationObj?.existingConfigDetails);
          setNoData({
            ...noData,
            distribution_email : res.data.applicationObj?.user?.email,
            productId: 1,
            type:"DEV",
            applicationId:props.applicationId
          })
         
          setSecurityData({
            ...securityData,
            max_bytes_content: res.data?.applicationObj?.editDevSecurityRulesDetails?.maxBytesContent,
            max_bytes_per_content_hidden: res.data?.applicationObj?.editDevSecurityRulesDetails?.maxBytesContentHidden,
            max_bytes_per_content_changed: "no",
            systest: res?.data?.applicationObj?.editDevSecurityRulesDetails?.devWhiteList === null ? "" : res?.data?.applicationObj?.editDevSecurityRulesDetails?.devWhiteList,
            application_id:props.applicationId
          });

          setUseCheck(res?.data?.applicationObj?.editDevSecurityRulesDetails?.useDevWhiteList === true ? true : false);
        }).catch((err)=>{
 
        })
  };


  const getApplicationDetail = () => {
        service.applicationData(props.applicationId).then((res) => {
          setApplicationData(res.data.applicationObj);
          setCOnfigDetail(res.data.applicationObj?.existingConfigDetails);

          
          setNoData({
            ...noData,
            distribution_email : res.data.applicationObj?.user?.email,
            productId: 1,
            type:"DEV",
            applicationId:props.applicationId
          })
         
          setSecurityData({
            ...securityData,
            max_bytes_content: res.data?.applicationObj?.editDevSecurityRulesDetails?.maxBytesContent,
            max_bytes_per_content_hidden: res.data?.applicationObj?.editDevSecurityRulesDetails?.maxBytesContentHidden,
            max_bytes_per_content_changed: "no",
            systest: res?.data?.applicationObj?.editDevSecurityRulesDetails?.devWhiteList === null ? "" : res?.data?.applicationObj?.editDevSecurityRulesDetails?.devWhiteList,
            application_id:props.applicationId
          });

          setUseCheck(res?.data?.applicationObj?.editDevSecurityRulesDetails?.useDevWhiteList === true ? true : false);
        }).catch((err)=>{
 
        })
  };
  const [xmlNodeFound, setXmlNodeFound] = useState(false);
  const appNodeFile = () =>{
    service.applicationData(props.applicationId).then((res) => {
      setApplicationData(res.data.applicationObj);
      if(res.data.applicationObj?.editConfigDetails?.awsXmlNotFound === "yes"){
        setXmlNodeFound(true);
      }
    }).catch((err)=>{

    });
  }
  const [useCheck, setUseCheck] = useState(false);
  useEffect(() => {
  setLoading(true);
  document.title = "Datafree Portal";
  getApplicationDetails();
  appNodeFile();
  getGenerateApp();
  getPlatform();
  }, []);

  const[loading,setLoading] = useState(false);
  const [hasApp, setHasApp] = useState(false);
  const getGenerateApp = () => {
    setLoading(true)
    service.generateApp({
      "applicationId": props?.applicationId,
      "type": props?.deployType
    }).then((res) => {
      setLoading(false);
      if (res?.data?.hasAppId === 'Yes') {
        setHasApp(true); 
        getApplicationDetails();
      }
      else {
        setHasApp(false);
      }
    }).catch((err) => {

    })

  };

  const [platformData, setPlatformData] = useState();
  const getPlatform = () => {
    service.popupPlatform({appDeploymentType:"DEV"}).then((res) => {
     
      setPlatformData(res.data?.VersionClientAddPopUps)
    }).catch((err) => {

    })

  };

  
  function openModal() {
    
    service.createdApp({applicationId:props?.applicationId}).then((res) => {

      if(res.data === "not-exist"){
        openModalNewConfig();
      }
      else if(res.data === "exist"){
        setIsOpen(true);
        setScroll('body');

      }
      //setPlatformData(res.data?.VersionClientAddPopUps)
    }).catch((err) => {

    })
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModalNewConfig() {
    setIsOpen(false)
    setmdnewconfig(true);
    setScroll('body');
  }

  function closeModalNewConfig() {
    setmdnewconfig(false);
    setNoData({
      ...noData,
      name: "",
      description: "",
      platform: "",
      package_suffix: "",
      distribution_email: "",
      onboarding_url: "" ,
      use_dark_action_bar_icons: 0,
      website_url: "",
      entry_point_hosting: "",
      start_url: "",
      status_bar_color:"#303f9f",
      header_color:"#3f51b5",
      accent_color:"#ff4081",
      status_manual:"",
      header_manual:"",
      accent_manual:""
    });
    setNoError({
      name: false,
      description: false,
      platform: false,
      package_suffix: false,
      distribution_email: false,
      onboarding_url: false,
      website_url: false,
      entry_point_hosting: false,
      start_url: false,
      emailMsg:"",
      webMsg:"",
      urlMsg:"",
      packageMsg:"",
      status_manual:false,
      header_manual: false,
      accent_manual: false,
      statusMsg:"",
      headerMsg:"",
      accentMsg:""
    })
  }

  function openModalCloneConfig() {
    setIsOpen(false)
    setmdcloneconfig(true);
    setScroll('body');
  }

  function closeModalCloneConfig() {

    setmdcloneconfig(false);
    setYesData({
    dev_configs: "",
    name: "",
    deploymentType: "DEV",
    description: ""
    });
    setYesError({
    dev_configs: false,
    name: false,
    description: false
    })
  }

  // Build APK model functions
  function openModalBulidApk() {
    setmdbuildapk(true);
    setScroll('body');
  }

  function closeModalBuildApk() {
    setmdbuildapk(false);
  }

  // Delete App model functions
  function openModalDelete() {
    setappdelet(true);
    setScroll('body');
  }

  function closeModalDelete() {
    setappdelet(false);
  }

  const [apkEditError,setApkEditError] = useState([{
    name: false,
    description: false,
    distribution_email: false,
    emailMsg:""
  }])

  const customStylesNewConfig = {
    content: {
      width: '600px',
      height: '600px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    }
  }

  const customStylesCloneConfig = {
    content: {
      width: '450px',
      height: '500px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    }
  }

  const [yesData, setYesData] = useState({
    dev_configs: "",
    name: "",
    deploymentType: "DEV",
    description: ""
  })
  const [yesError, setYesError] = useState({
    dev_configs: false,
    name: false,
    description: false
  });

  const addYes = (e) =>{
    const newdata = { ...yesData };
    newdata[e.target.id] = e.target.value;
    

    if(e.target.id === "dev_configs"){
      if(e.target.value){
        setYesError({
          ...yesError,
          dev_configs:false
        })
      }
      else{
        setYesError({
          ...yesError,
          dev_configs:true
        })
      }
    }

    if(e.target.id === "name"){
      if(e.target.value){
        setYesError({
          ...yesError,
          name:false
        })
      }
      else{
        setYesError({
          ...yesError,
          name:true
        })
      }
    }

    if(e.target.id === "description"){
      if(e.target.value){
        setYesError({
          ...yesError,
          description:false
        })
      }
      else{
        setYesError({
          ...yesError,
          description:true
        })
      }
    }

    setYesData(newdata);
  }

  const validateYesSave = (yesData) =>{

    let count = 0;
    const nwerror = {...yesError};

    if(yesData.dev_configs === ""){
      nwerror.dev_configs = true;
      count++;
    }else{
      nwerror.dev_configs = false;
    }

    if(yesData.name === ""){
      nwerror.name = true;
      count++;
    }else{
      nwerror.name = false;
    }

    if(yesData.description === ""){
      nwerror.description = true;
      count++;
    }else{
      nwerror.description = false;
    }

    setYesError(nwerror);
    return count;
  }
  const yesSave = () =>{

    const yCount = validateYesSave(yesData)

    if(yCount === 0){
      setClickSaveDis(true);
      setLoading(true)
      
      service.yesClick(yesData).then((res) => {
        setClickSaveDis(false);
        setLoading(false)
        closeModalCloneConfig();
        getGenerateApp();
      }).catch((err) => {
  
      })
    }
  }

  const [developerMenu , setDeveloperMenu] = useState(false);
  const [noData, setNoData] = useState({
    productId:1,
    applicationId: props?.applicationId,
    name: "",
    description: "",
    type: "DEV",
    platform: "",
    package_suffix: "",
    distribution_email: "",
    onboarding_url: "" ,
    use_dark_action_bar_icons: 0,
    website_url: "",
    entry_point_hosting: "",
    start_url: "",
    status_bar_color:"#303f9f",
    header_color:"#3f51b5",
    accent_color:"#ff4081",
    status_manual:"",
    header_manual:"",
    accent_manual:""
  });

  const [noError, setNoError] = useState({
    name: false,
    description: false,
    platform: false,
    package_suffix: false,
    distribution_email: false,
    onboarding_url: false,
    website_url: false,
    entry_point_hosting: false,
    start_url: false,
    emailMsg:"",
    webMsg:"",
    urlMsg:"",
    packageMsg:"",
    status_manual:false,
    header_manual: false,
    accent_manual: false,
    statusMsg:"",
    headerMsg:"",
    accentMsg:""
  });

  const webReg = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  const colorReg = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  const packageReg = /^[a-zA-Z]+[a-zA-Z0-9_]*$/i;
  const addNo = (e) => {
    const newdata = {...noData};
    newdata[e.target.id] = e.target.value;
  
    if(e.target.id === "name"){
      if(e.target.value){
        setNoError({
          ...noError,
          name:false
        })
      }
      else{
        setNoError({
          ...noError,
          name:true
        })
      }
    }

    if(e.target.id === "description"){
      if(e.target.value){
        setNoError({
          ...noError,
          description:false
        })
      }
      else{
        setNoError({
          ...noError,
          description:true
        })
      }
    }

    if(e.target.id === "platform"){
      if(e.target.value){
        setNoError({
          ...noError,
          platform:false
        })
      }
      else{
        setNoError({
          ...noError,
          platform:true
        })
      }
    }


    if(e.target.id === "website_url"){
      if(e.target.value){
        if(webReg.test(e.target.value) === false){
          setNoError({
            ...noError,
            website_url:true,
            webMsg:"Please enter a valid URL."
          })
        }else{
          setNoError({
            ...noError,
            website_url:false,
            webMsg:""
          })
        }
      }
      else{
        setNoError({
            ...noError,
            website_url:true,
            webMsg:"This field is required."
          })
      }
    } 

    if(e.target.id === "distribution_email"){
      if(e.target.value){
        if(distemail.test(e.target.value) === false){
          setNoError({
            ...noError,
            distribution_email:true,
            emailMsg:"You must enter a valid email, or comma separated emails"
          })
        }else{
          setNoError({
            ...noError,
            distribution_email:false,
            emailMsg:""
          })
        }
      }
      else{
        setNoError({
            ...noError,
            distribution_email:true,
            emailMsg:"This field is required."
          })
      }
    } 

    if(e.target.id === "onboarding_url"){
      if(e.target.value){
        if(webReg.test(e.target.value) === false){
          setNoError({
            ...noError,
            onboarding_url:true,
            urlMsg:"Please enter a valid URL."
          })
        }else{
          setNoError({
            ...noError,
            onboarding_url:false,
            urlMsg:""
          })
        }
      }
      else{
        setNoError({
            ...noError,
            onboarding_url:false,
            urlMsg:""
          })
      }
    } 

    if(e.target.id === "package_suffix"){
      if(e.target.value){
        if(packageReg.test(e.target.value) === false){
          setNoError({
            ...noError,
            package_suffix:true,
            packageMsg:"Only alphanumeric characters or underscore and the first character must be a letter"
          })
        }
        else if((e.target.value).length > 10){
          setNoError({
            ...noError,
            package_suffix:true,
            packageMsg:"Please enter no more than 10 characters."
          })
        } 
        else{
          setNoError({
            ...noError,
            package_suffix:false,
            packageMsg:""
          })
        }
      }
      else{
        setNoError({
            ...noError,
            package_suffix:false,
            packageMsg:""
          })
      }
    } 


    if(e.target.id === "status_manual"){
      if(e.target.value){
     
        if(colorReg.test(e.target.value) === false){
           setNoError({
            ...noError,
            status_manual: true,
            statusMsg: "Please enter a valid color"
          });
        }
        else{
          setNoError({
            ...noError,
            status_manual: false,
            statusMsg: ""         
          });
        }
      }
      else{
        if(colorchecked){
          setNoError({
            ...noError,
            status_manual: true,
            statusMsg: "This field is required"         
          });
        }
        else{
          setNoError({
            ...noError,
            status_manual: false,
            statusMsg: ""         
          });
        }
      }
      
    }


    if(e.target.id === "header_manual"){
      if(e.target.value){
     
        if(colorReg.test(e.target.value) === false){
           setNoError({
            ...noError,
            header_manual: true,
            headerMsg: "Please enter a valid color"
          });
        }
        else{
          setNoError({
            ...noError,
            header_manual: false,
            headerMsg: ""         
          });
        }
      }
      else{
        if(actioncolorchecked){
          setNoError({
            ...noError,
            header_manual: true,
            headerMsg: "This field is required"         
          });
        }
        else{
          setNoError({
            ...noError,
            header_manual: false,
            headerMsg: ""         
          });
        }
      }
      
    }


    if(e.target.id === "accent_manual"){
      if(e.target.value){
     
        if(colorReg.test(e.target.value) === false){
           setNoError({
            ...noError,
            accent_manual: true,
            accentMsg: "Please enter a valid color"
          });
        }
        else{
          setNoError({
            ...noError,
            accent_manual: false,
            accentMsg: ""         
          });
        }
      }
      else{
        if(accentcolorchecked){
          setNoError({
            ...noError,
            accent_manual: true,
            accentMsg: "This field is required"         
          });
        }
        else{
          setNoError({
            ...noError,
            accent_manual: false,
            accentMsg: ""         
          });
        }
      }
      
    }
    setNoData(newdata);
  }

  const validateNoSave = (noData) =>{

    let count = 0;
    const nwerror = {...noError};

    if(noData.platform === ""){
      nwerror.platform = true;
      count++;
    }else{
      nwerror.platform = false;
    }

    if(noData.name === ""){
      nwerror.name = true;
      count++;
    }else{
      nwerror.name = false;
    }

    if(noData.description === ""){
      nwerror.description = true;
      count++;
    }else{
      nwerror.description = false;
    }

    if(noData.website_url === ""){
      nwerror.website_url = true;
      nwerror.webMsg = "This field is required";
      count++;
    }else{
      if(webReg.test(noData.website_url) === false){
        nwerror.website_url = true;
        nwerror.webMsg = "Please enter a valid URL.";
        count++;
      }
      else{
        nwerror.website_url = false;
        nwerror.webMsg = "";
      } 
    }


    if(noData.distribution_email === ""){
      nwerror.distribution_email = true;
      nwerror.emailMsg = "This field is required";
      count++;
    }else{
      if(distemail.test(noData.distribution_email) === false){
        nwerror.distribution_email = true;
        nwerror.emailMsg = "You must enter a valid email, or comma separated emails";
        count++;
      }
      else{
        nwerror.distribution_email = false;
        nwerror.emailMsg = "";
      }
      
    }

    if(noData.onboarding_url === ""){
      nwerror.onboarding_url = false;
      nwerror.urlMsg = "";
    }else{
      if(webReg.test(noData.onboarding_url) === false){
        nwerror.onboarding_url = true;
        nwerror.urlMsg = "Please enter a valid URL.";
        count++;
      }
      else{
        nwerror.onboarding_url = false;
        nwerror.urlMsg = "";
      } 
    }

    if(noData.package_suffix === ""){
      nwerror.package_suffix = false;
      nwerror.packageMsg = "";
    }else{
      if(packageReg.test(noData.package_suffix) === false){
        nwerror.package_suffix = true;
        nwerror.packageMsg = "Please enter a valid URL.";
        count++;
      }
      else if((noData.package_suffix).length > 10){
        nwerror.package_suffix = true;
        nwerror.packageMsg = "Please enter a valid URL.";
        count++;
      } 
      else{
        nwerror.package_suffix = false;
        nwerror.packageMsg = "";
      } 
    }


    if(noData.header_manual === ""){
      if(actioncolorchecked){
        nwerror.header_manual = true;
        nwerror.headerMsg = "This field is required";
        count++;
      }
      else{
        nwerror.header_manual = false;
        nwerror.headerMsg = "";
      }
      
    }else{
      if(colorReg.test(noData.header_manual) === false){
        nwerror.header_manual = true;
        nwerror.headerMsg = "Please enter a valid color";
        count++;
      }
       else{
        nwerror.header_manual = false;
        nwerror.headerMsg = "";
        noData.header_color = noData.header_manual;
      } 
    }


    if(noData.status_manual === ""){

      if(colorchecked){
        nwerror.status_manual = true;
        nwerror.statusMsg = "This field is required";
        count++;
      }
      else{
        nwerror.status_manual = false;
        nwerror.statusMsg = "";
      }
      
    }else{
      if(colorReg.test(noData.status_manual) === false){
        nwerror.status_manual = true;
        nwerror.statusMsg = "Please enter a valid color";
        count++;
      }
      
      else{
        nwerror.status_manual = false;
        nwerror.statusMsg = "";
        noData.status_bar_color = noData.status_manual;
      } 
    }

    if(noData.accent_manual === ""){
      if(accentcolorchecked){
        nwerror.accent_manual = true;
        nwerror.accentMsg = "This field is required";
        count++;
      }
      else{
        nwerror.accent_manual = false;
        nwerror.accentMsg = "";
      }
    }else{
      if(colorReg.test(noData.accent_manual) === false){
        nwerror.accent_manual = true;
        nwerror.accentMsg = "Please enter a valid color";
        count++;
      }
      
      else{
        nwerror.accent_manual = false;
        nwerror.accentMsg = "";
        noData.accent_color = noData.accent_manual;
      } 
    }

    setNoError(nwerror);
    return count;
  }
  const [clickSaveDis, setClickSaveDis] = useState(false);
  const noSave = () =>{

    const nCount = validateNoSave(noData)
   
    if(nCount === 0){
      setClickSaveDis(true);
      setLoading(true)
      if(developerMenu){
        noData.include_developer_menu = "on"
      }
       
      let vals = "";
      if(include1){
         vals = "EMTRACKER_SDK"

        if(include2){
          vals = vals + ",VIDEO_SDK"
        }
        
      }
      else{
        if(include2){
          vals = "VIDEO_SDK"
        }
      }
      noData.include_sdks = vals;
    
      service.noClick(noData).then((res) => {
       
        setClickSaveDis(false);
        setLoading(false)
        closeModalNewConfig();
        getGenerateApp();
      }).catch((err) => {
  
      })
    }
  }
  
  const getBuildPopup = (id) => {
        services.GetPopup({configId:id}).then((res) => {    
          setBuildPopup(res.data);   
        }).catch((err)=>{
 
        })
  }
   const BuildView = (id) =>{

    if(id !== ""){
      getBuildPopup(id);
 
     service.viewBuildApk({configId:id}).then((res) => {
 
       setBuildApkData(res.data);
       setEditApk({
        build_id: "",
        buildTypes:"",
        version_code: res.data?.obj.versionCode ?  res.data?.obj.versionCode : "",
        version_name:res.data?.obj.versionName ?  res.data?.obj.versionName : "",
        comments: res.data?.obj.comment ?  res.data?.obj.comment : "",
        email_distribution: res.data?.obj.distributionEmail ?  res.data?.obj.distributionEmail : "",
        package_suffix: res.data?.obj.packageSuffix ?  res.data?.obj.packageSuffix : "",
        application_id: res.data?.obj.applicationid ?  res.data?.obj.applicationid : "",
        deployment_type: res.data?.obj.deploymentType ?  res.data?.obj.deploymentType : "",
        deployment_id: res.data?.obj.deploymentId ?  res.data?.obj.deploymentId : "",
        configid: id,
       })
       openModalBulidApk();
       
     }).catch((err) => {
 
     })
    }
   } 

  const [BuildApkData, setBuildApkData] = useState();
  const [BuildPopup, setBuildPopup] = useState();
  const [editApk, setEditApk] = useState({
    build_id: "",
    buildTypes:"",
    version_code: "",
    version_name:"",
    comments: "",
    email_distribution: "",
    package_suffix: "",
    application_id: "",
    deployment_type: "",
    deployment_id: "",
    configid: ""
  });

  const [ApkError, setApkError] = useState({
    buildTypes:false,
    version_code: false,
    version_name: false,
    comments: false,
    email_distribution: false,
    package_suffix: false,
    versionMsg: "",
    emailMsg: "",
  });

  const versionReg = /^[0-9]*$/;
  const distemail = /([a-zA-Z0-9_\-\.]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))/
 
  const handleApkEdit = (e) =>{
    //
    e.preventDefault();
    const newdata={...editApk};
    newdata[e.target.id] = e.target.value;

    if(e.target.id === "version_code"){
      if(e.target.value){
        if(versionReg.test(e.target.value) === false){
          setApkError({
            ...ApkError,
            version_code:true,
            versionMsg: "Please enter only digits.",
          })
        }
        else{
          setApkError({
            ...ApkError,
            version_code:false,
            versionMsg:"",
          })
        }
      }
      else{
        setApkError({
          ...ApkError,
          version_code:true,
          versionMsg:"This field is required",
        })
      }
    }

    if(e.target.id === "version_name"){
      if(e.target.value){
          setApkError({
            ...ApkError,
            version_name:false,
          })
      }
      else{
        setApkError({
          ...ApkError,
          version_name:true,
        })
      }
    }

    if(e.target.id === "comments"){
      if(e.target.value){
          setApkError({
            ...ApkError,
            comments:false,
          })
      }
      else{
        setApkError({
          ...ApkError,
          comments:true,
        })
      }
    }

    if(e.target.id === "email_distribution"){
      if(e.target.value){
        if(distemail.test(e.target.value) === false){
          setApkError({
            ...ApkError,
            email_distribution:true,
            emailMsg: "You must enter a valid email, or comma separated emails",
          })
        }
        else{
          setApkError({
            ...ApkError,
            email_distribution:false,
            emailMsg:"",
          })
        }
      }
      else{
        setApkError({
          ...ApkError,
          email_distribution:true,
          emailMsg:"This field is required",
        })
      }
    }

      if(e.target.id === "buildTypes"){
            if(e.target.value){
              setApkError({
                ...ApkError,
                buildTypes:false,
              })
            }
            else{
              setApkError({
                ...ApkError,
                buildTypes:true,
              })
            }
      } 

setEditApk(newdata);
  }

  const BuildSave = () =>{
    const apkCount = ValidateApk(editApk);
    if(apkCount === 0){
          setLoading(true);
          service.createApk(editApk).then((res) => {
           
            if(res.data.status === "success"){
              setmdbuildapk(false);
              var x = document.getElementById("buildmsgoff");
              x.style.display = "block";
              setLoading(false);
            }
          }).catch((err)=>{
 
          })
    }
  }

 

  const ValidateApk = (editApk) => {
    let apkcounts = 0;
    const apkerror = {...ApkError};

    if(editApk.version_code === ""){
      apkerror.version_code = true;
      apkerror.versionMsg = "This field is required";
      apkcounts++;
    }
    else{
      if(versionReg.test(editApk.version_code) === false){
        apkerror.version_code = true;
        apkerror.versionMsg = "Please enter only digits.";
        apkcounts++;
      }
      else{
        apkerror.version_code = false;
        apkerror.versionMsg = "";
      }
    }


    if(editApk.email_distribution === ""){
      apkerror.email_distribution = true;
      apkerror.emailMsg = "This field is required";
      apkcounts++;
    }
    else{
      if(distemail.test(editApk.email_distribution) === false){
        apkerror.email_distribution = true;
        apkerror.emailMsg = "You must enter a valid email, or comma separated emails";
        apkcounts++;
      }
      else{
        apkerror.email_distribution = false;
        apkerror.emailMsg = "";
      }
    }


    if(editApk.version_name === ""){
      apkerror.version_name = true;
      apkcounts++;
    }
    else{
      apkerror.version_name = false;  
    }

    if(editApk.comments === ""){
      apkerror.comments = true;
      apkcounts++;
    }
    else{
      apkerror.comments = false;  
    }
    if(BuildApkData.userType === "INTERNAL"){
      if(editApk.buildTypes === ""){
        apkerror.buildTypes = true;
        apkcounts++;
      }
      else{
        apkerror.buildTypes = false;  
      }
    } 
    else{
        apkerror.buildTypes = false;  
    } 
        
    
    setApkError(apkerror);
    return apkcounts;
  }
  const [editAppOpen, setEditAppOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const handleAppClose = (event,reason) => {
    if (reason !== 'backdropClick') {
      setEditAppOpen(false);
    }
   
  };
const [MoreData,setMoreData] = useState();
const [AppAttributes,setAppAttributes] = useState();
const GetInfo = (id) =>{
      service.viewMore({buildId:id}).then((res) => {
        
        setMoreData(res.data.moreInfo);
        const val = res.data.moreInfo?.appAttributes.replace(/["{}]/g, "");
        
        setAppAttributes(val.split(','));
     
        var x = document.getElementById("Moreinfo_"+id);
        let y = document.getElementById("debugDownload" + id);
        if (x.style.display === "none") {
          x.style.display = "block";
        } else {
          x.style.display = "none";
        }
        if (y.style.display === "block") {
          y.style.display = "none";
        } else {
          y.style.display = "none";
        }
      }).catch((err)=>{
 
      })
}

  const [openBuild, setopenBuild] = useState(false);
  const openBuildClose = () => {
    setopenBuild(false);
  };

  const [BuildDetails, setBuildDetails] = useState();
  const [configIdData, setconfigIdData] = useState();
  const viewHistory = (id) =>{
    setConfigCheck(false);
    if(id !== ""){
     
      setconfigIdData(id);
          service.viewBuildHistory({configId:id}).then((res) => {
           
            setBuildDetails(res.data?.buildTableDetails);
            setopenBuild(true);
            setScroll('body');
          }).catch((err)=>{
 
          })
    }
  }
 
const [configCheck, setConfigCheck] = useState(false);
const [configWrapCheck, setConfigWrapCheck] = useState(false);
const showAllWraps = (e) =>{
  const userIds = applicationData.user?.userId;
  setConfigWrapCheck(!configWrapCheck);
  if(e.target.checked === true){
    try {
      setLoading(true);
        service.wrapConfigCheck({applicationId:props.applicationId,userId:userIds}).then((res) => {
          
          setLoading(false);
          setApplicationData(res.data.applicationObj);
          setCOnfigDetail(res.data.applicationObj?.existingConfigDetails);
          setNoData({
            ...noData,
            distribution_email : res.data.applicationObj?.user?.email,
            productId: 1,
            type:"DEV",
            applicationId:props.applicationId
          })
         
          setSecurityData({
            ...securityData,
            max_bytes_content: res.data?.applicationObj?.editDevSecurityRulesDetails?.maxBytesContent,
            max_bytes_per_content_hidden: res.data?.applicationObj?.editDevSecurityRulesDetails?.maxBytesContentHidden,
            max_bytes_per_content_changed: "no",
            systest: res?.data?.applicationObj?.editDevSecurityRulesDetails?.devWhiteList === null ? "" : res?.data?.applicationObj?.editDevSecurityRulesDetails?.devWhiteList,
            application_id:props.applicationId
          });

          setUseCheck(res?.data?.applicationObj?.editDevSecurityRulesDetails?.useDevWhiteList === true ? true : false);
        })
    } catch (err) {
   
    }
  }
  else{
    try {
      setLoading(true);
        service.wrapConfigCheck({applicationId:props.applicationId}).then((res) => {
          
          setLoading(false);
          setApplicationData(res.data.applicationObj);
          setCOnfigDetail(res.data.applicationObj?.existingConfigDetails);
          setNoData({
            ...noData,
            distribution_email : res.data.applicationObj?.user?.email,
            productId: 1,
            type:"DEV",
            applicationId:props.applicationId
          })
         
          setSecurityData({
            ...securityData,
            max_bytes_content: res.data?.applicationObj?.editDevSecurityRulesDetails?.maxBytesContent,
            max_bytes_per_content_hidden: res.data?.applicationObj?.editDevSecurityRulesDetails?.maxBytesContentHidden,
            max_bytes_per_content_changed: "no",
            systest: res?.data?.applicationObj?.editDevSecurityRulesDetails?.devWhiteList === null ? "" : res?.data?.applicationObj?.editDevSecurityRulesDetails?.devWhiteList,
            application_id:props.applicationId
          });

          setUseCheck(res?.data?.applicationObj?.editDevSecurityRulesDetails?.useDevWhiteList === true ? true : false);
        })
    } catch (err) {
   
    }
  }
}
const showBuilds = (e) =>{
 
  setConfigCheck(!configCheck);
  
  if(e.target.checked === true){
    
    try {
      trackPromise(
        service.checkConfig({deploymentId:applicationData?.appDeploymentDevObj?.deploymentId}).then((res) => {
         
          setBuildDetails(res.data?.BuildDetails);
        })
      );
    } catch (err) {
   
    }
  }
  else{
 
    viewHistory(configIdData);
  }
}

const handleClose = (Id) => {
 
  applicationData?.generateConfigObjects?.DEV.map((item, index) => {
    if (item?.id === Id) {
      item['editInfo'] = false
    }
  })
  getApplicationDetails();
};

const [apkEdit, setApkEdit] = useState({
  name: "",
  description: "",
  platform: "",
  package_suffix: "",
  distribution_email: "",
  configId: "",
  deploymentType: "DEV",
  currentPlatform: "",
  productId: "1",
})

const [lookFeel, setLookFeel] = useState({
onboarding_url: "",
use_dark_action_bar_icons: "",
status_bar_color: "",
status_manual:"",
header_manual:"",
accent_manual:"",
header_color: "",
accent_color: "",
deploymentType: "DEV",
configId: ""
})

const [lookError, setLookError] = useState({
  onboarding_url: false,
  status_manual:false,
  header_manual: false,
  accent_manual: false,
  accentMsg:"",
  statusMsg:"",
  headerMsg:"",
  urlMsg:""
  })
  const launchxmlUrl = (url)=>{
    let  baseAPI_URL =process.env.REACT_APP_BASE_URL;
    let subbaseUrl =baseAPI_URL.toString().split("/");
    let hostName = subbaseUrl[2].toString().split(".");
    let path=baseAPI_URL.split(hostName[0]).join("validator");
  
    window.open(path+"/validate/gui?url="+ url);
    
  
  }
const [location, setLocation] = useState(false);
const [storage, setStorage] = useState(false);
const [menu, setMenu] = useState(false);
//getConfig Details
const getConfigDetails = (configId,deployId) => {
  service.getConfig({ "configId": configId, "deployment_id":deployId }).then((res) => {
    setApkEdit({
      name: res?.data?.name,
      description: res?.data?.description,
      platform: res?.data?.platformId,
      package_suffix: res?.data?.packageSuffix,
      distribution_email: res?.data?.distributionEmail,
      currentPlatform: res?.data?.platformId,
      configId: res?.data?.id,
      deploymentType: "DEV",
      productId: "1",
    });

    setLookFeel({
      ...lookFeel,
      onboarding_url: res?.data?.onBoardingUrl,
      use_dark_action_bar_icons : res?.data?.useDarkActionBarIcons,
      status_bar_color: res?.data?.statusbarcolor,
      header_color: res?.data?.actionBarColor,
      accent_color: res?.data?.accentColor,
      status_manual: res?.data?.statusbarcolor,
      header_manual: res?.data?.actionBarColor,
      accent_manual: res?.data?.accentColor,
      deploymentType: "DEV",
      configId: res?.data?.id,

    });

    let inc = res.data?.includeSdks.split(",").includes("EMTRACKER_SDK");

    let inc1 = res.data?.includeSdks.split(",").includes("VIDEO_SDK");
    
    setinclude1(inc ? true : false);
    setinclude2(inc1 ? true : false);

    setLocation(res?.data?.locationPermission === true ? true : false);
    setStorage(res?.data?.storagePermission === true ? true : false);
    setMenu(res?.data?.includeDevMenu === "no" ? false : true);

    document.getElementById("appnodeshow_"+configId).value = res?.data?.xmlFile;
    document.getElementById("docshow_"+configId).value = res?.data?.xmlDocFile;
    

  }).catch((err) => {

  })

};

const edit = (Id, deployId, index) => {
  applicationData?.generateConfigObjects?.DEV.map((item, index) => {
    if (item?.id === Id) {
      item['editInfo'] = true;
    }
  })
  getConfigDetails(Id,deployId);
};
const handleEdit = (e) => {
    
  const newdata = {...apkEdit};
  newdata[e.target.id] = e.target.value;

  if(e.target.id === "name"){
    if(e.target.value){
      setApkEditError({
        ...apkEditError,
        name: false
      })
    }
   else{
    setApkEditError({
      ...apkEditError,
      name: true
    })
   }
  }

  if(e.target.id === "description"){
    if(e.target.value){
      setApkEditError({
        ...apkEditError,
        description: false
      })
    }
   else{
    setApkEditError({
      ...apkEditError,
      description: true
    })
   }
  }

  if(e.target.id === "distribution_email"){
    if(e.target.value){
      if(distemail.test(e.target.value) === false){
        setApkEditError({
          ...apkEditError,
          distribution_email: true,
          emailMsg:"You must enter a valid email, or comma separated emails"
        })
      }
      else{
        setApkEditError({
          ...apkEditError,
          distribution_email: false,
          emailMsg:""
        })
      }
    }
   else{
    setApkEditError({
      ...apkEditError,
      distribution_email: true,
      emailMsg:"This field is required"
    })
   }
  }

  setApkEdit(newdata);
}
const validateApkConfig = (apkEdit) =>{

  let count = 0;
  let nwerror = {...apkEditError};

  if(apkEdit.name === ""){
    nwerror.name = true;
    count++;
  }
  else{
    nwerror.name = false;  
  }

  if(apkEdit.description === ""){
    nwerror.description = true;
    count++;
  }
  else{
    nwerror.description = false;  
  }

  if(apkEdit.distribution_email === ""){
    nwerror.distribution_email = true;
    nwerror.emailMsg = "This field is required"
    count++;
  }
  else{
    if(distemail.test(apkEdit.distribution_email) === false){
      nwerror.distribution_email = true;
      nwerror.emailMsg = "You must enter a valid email, or comma separated emails"
      count++;
    }
    else{
      nwerror.distribution_email = false;
      nwerror.emailMsg = ""
    }
  }

  setApkEditError(nwerror);
  return count;
}
const saveEdit = (Id,deployId) => {
  apkEdit['configId'] = Id;

  const wCount = validateApkConfig(apkEdit);
  //alert(wCount);
  if(wCount === 0){

    if(menu){
      apkEdit.include_developer_menu = "on"
    }

    if(storage){
      apkEdit.storage_permission = ""
    }

    if(location){
      apkEdit.location_permission = ""
    }
    let vals = "";
    if(include1){
      vals = "EMTRACKER_SDK";
      if(include2){
        vals = vals + ",VIDEO_SDK";
      }
      apkEdit.include_sdks = vals;
    }
    else{
      if(include2){
        vals = "VIDEO_SDK";
      }
      apkEdit.include_sdks = vals;
    }
  
    service.wrapApkConfig(apkEdit).then((res) => {
      handleClose();
    
    }).catch((err) => {
  
    })
  }
 
}


const editLook = (conId, deployId, index) => {
  applicationData?.generateConfigObjects?.DEV.map((item, index) => {
    if (item?.id === conId) {
      item['editLk'] = true;
    }
  })
  getConfigDetails(conId,deployId);
};

const handleCloseLook = (conId, deployId) => {
  setActionColorchecked(false);
  setColorchecked(false);
  setAccentColorchecked(false);
  /*setLookFeel({
    onboarding_url: "",
    use_dark_action_bar_icons: "",
    status_bar_color: "",
    status_manual:"",
    header_manual:"",
    accent_manual:"",
    header_color: "",
    accent_color: "",
    deploymentType: "DEV",
    configId: ""
  })*/
  applicationData?.generateConfigObjects?.DEV.map((item, index) => {
    if (item?.id === conId) {
      item['editLk'] = false
    }
  })
  //getConfigDetails(conId,deployId);
  getApplicationDetail();
};

const lookFeelEdit = (Id, deployId) => {
  const wCount = validateLookFeel(lookFeel);
  //alert(wCount);
  if(wCount === 0){
    if(lookFeel.use_dark_action_bar_icons === "yes"){
      lookFeel.use_dark_action_bar_icons = 1;
    }
    else{
      lookFeel.use_dark_action_bar_icons = 0;
    }
    service.lookEdit(lookFeel).then((res) => {
      
    handleCloseLook(Id, deployId);
    getApplicationDetails();
    }).catch((err) => {
  
    })
  }
 
}


const validateLookFeel = (lookFeel) =>{
  let count = 0;
  const nwerror = {...lookError};

  if(lookFeel.onboarding_url === ""){
    nwerror.onboarding_url = false;
    nwerror.urlMsg = "";
  }else{
    if(webReg.test(lookFeel.onboarding_url) === false){
      nwerror.onboarding_url = true;
      nwerror.urlMsg = "Please enter a valid URL.";
      count++;
    }
    else{
      nwerror.onboarding_url = false;
      nwerror.urlMsg = "";
    } 
  }

  

  if(lookFeel.header_manual === ""){
    if(actioncolorchecked){
      nwerror.header_manual = true;
      nwerror.headerMsg = "This field is required";
      count++;
    }
    else{
      nwerror.header_manual = false;
      nwerror.headerMsg = "";
    }
    
  }else{
    if(colorReg.test(lookFeel.header_manual) === false){
      nwerror.header_manual = true;
      nwerror.headerMsg = "Please enter a valid color";
      count++;
    }
    
    else{
      nwerror.header_manual = false;
      nwerror.headerMsg = "";
      if(actioncolorchecked){
        lookFeel.header_color = lookFeel.header_manual;
      } 
    } 
  }


  if(lookFeel.status_manual === ""){

    if(colorchecked){
      nwerror.status_manual = true;
      nwerror.statusMsg = "This field is required";
      count++;
    }
    else{
      nwerror.status_manual = false;
      nwerror.statusMsg = "";
    }
    
  }else{
    if(colorReg.test(lookFeel.status_manual) === false){
      nwerror.status_manual = true;
      nwerror.statusMsg = "Please enter a valid color";
      count++;
    }
    
    else{
      nwerror.status_manual = false;
      nwerror.statusMsg = "";
      if(colorchecked){
        lookFeel.status_bar_color = lookFeel.status_manual;
      }
      
    } 
  }

  if(lookFeel.accent_manual === ""){
    if(accentcolorchecked){
      nwerror.accent_manual = true;
      nwerror.accentMsg = "This field is required";
      count++;
    }
    else{
      nwerror.accent_manual = false;
      nwerror.accentMsg = "";
    }
  }else{
    if(colorReg.test(lookFeel.accent_manual) === false){
      nwerror.accent_manual = true;
      nwerror.accentMsg = "Please enter a valid color";
      count++;
    }
    
    else{
      nwerror.accent_manual = false;
      nwerror.accentMsg = "";
      if(accentcolorchecked){
        lookFeel.accent_color = lookFeel.accent_manual;
      }
    } 
  }

  setLookError(nwerror);
  return count;
}

const handleFeel = (e) =>{
  const newdata = {...lookFeel};
  newdata[e.target.id] = e.target.value;

  if(e.target.id === "onboarding_url"){
      if(e.target.value){
        if(webReg.test(e.target.value) === false){
          setLookError({
            ...lookError,
            onboarding_url:true,
            urlMsg:"Please enter a valid URL."
          })
        }else{
          setLookError({
            ...lookError,
            onboarding_url:false,
            urlMsg:""
          })
        }
      }
      else{
        setLookError({
            ...lookError,
            onboarding_url:false,
            urlMsg:""
          })
      }
    } 



    if(e.target.id === "status_manual"){
      if(e.target.value){
     
        if(colorReg.test(e.target.value) === false){
          setLookError({
            ...lookError,
            status_manual: true,
            statusMsg: "Please enter a valid color"
          });
        }
        else{
          setLookError({
            ...lookError,
            status_manual: false,
            statusMsg: ""         
          });
        }
      }
      else{
        if(colorchecked){
          setLookError({
            ...lookError,
            status_manual: true,
            statusMsg: "This field is required"         
          });
        }
        else{
          setLookError({
            ...lookError,
            status_manual: false,
            statusMsg: ""         
          });
        }
      }
      
    }


    if(e.target.id === "header_manual"){
      if(e.target.value){
     
        if(colorReg.test(e.target.value) === false){
          setLookError({
            ...lookError,
            header_manual: true,
            headerMsg: "Please enter a valid color"
          });
        }
        else{
          setLookError({
            ...lookError,
            header_manual: false,
            headerMsg: ""         
          });
        }
      }
      else{
        if(actioncolorchecked){
          setLookError({
            ...lookError,
            header_manual: true,
            headerMsg: "This field is required"         
          });
        }
        else{
          setLookError({
            ...lookError,
            header_manual: false,
            headerMsg: ""         
          });
        }
      }
      
    }


    if(e.target.id === "accent_manual"){
      if(e.target.value){
     
        if(colorReg.test(e.target.value) === false){
          setLookError({
            ...lookError,
            accent_manual: true,
            accentMsg: "Please enter a valid color"
          });
        }
        else{
          setLookError({
            ...lookError,
            accent_manual: false,
            accentMsg: ""         
          });
        }
      }
      else{
        if(accentcolorchecked){
          setLookError({
            ...lookError,
            accent_manual: true,
            accentMsg: "This field is required"         
          });
        }
        else{
          setLookError({
            ...lookError,
            accent_manual: false,
            accentMsg: ""         
          });
        }
      }
      
    }

    setLookFeel(newdata);
}

const editEntry = (conId, deployId, index) => {
  applicationData?.generateConfigObjects?.DEV.map((item, index) => {
    if (item?.id === conId) {
      item['editEty'] = true;
    }
  })
  getConfigDetails(conId,deployId);
};

const closeEntry = (conId,deployId) =>{
 applicationData?.generateConfigObjects?.DEV.map((item, index) => {
    if (item?.id === conId) {
      item['editEty'] = false
    }
  })
  //getConfigDetails(conId,deployId);
  getApplicationDetails();
}
const [undoEdits, setUndoEdits] = useState(false);
const handleCloseEntry = (conId, deployId) => {

  document.getElementById("xmlFlag"+conId).value = "READY";
  document.getElementById("xmlflagRes"+conId).value = "";
  setLoadings(true);
  let xy = document.getElementById("editConfig"+conId)
  xy.style.opacity = '0.2' 
  service.getConfig({configId:conId}).then((res) => {
      setLoadings(false);
     
      let x = document.getElementById("xmlResult_"+conId)
      x.style.display = 'none'
      closeEntry(conId,deployId);
      setUndoEdits(true);
      xy.style.opacity = '1' 
      
    
  }).catch((err) => {
  
  })

};

const [configVal, setConfigVal] = useState(
  {
    configId : "",
    deployId : ""
  }
);
const [hasNoBuild, setHasNoBuild] = useState(false);
const [hasNoValidate, setHasNoValidate] = useState(false);
const EntryEdit = (Id, deployIds) => {
  //const wCount = validateLookFeel(lookFeel);

  let xmlNodeflg = document.getElementById("xmlFlag"+Id).value;
  let xmlNoderes = document.getElementById("xmlflagRes"+Id).value;
  let xys = document.getElementById("editConfig"+Id);
  xys.style.opacity = '0.2';
  setConfigVal({
    configId : Id,
    deployId : deployIds
  })
  document.getElementById("bucketid").value = Id;
  
  if(xmlNodeflg === "READY"){
    closeEntry(Id,deployIds);
    xys.style.opacity = '1' 
  }
  if(xmlNodeflg === "EDITS"){
    setHasNoValidate(true);
    xys.style.opacity = '1' 
  }
  else if(xmlNodeflg === "VALIDATE" && xmlNoderes === "failed"){
    setLoadings(true);
    service.buildWrap({configId:Id}).then((res) => { 
    
      if(res.data === null){
        setHasNoBuild(true);
        
        setLoadings(false);
        xys.style.opacity = '1' 
      }
    }).catch((err) => {
    
    })
  }

  else if(xmlNodeflg === "VALIDATE" && xmlNoderes === "success"){
    setLoadings(true);
    service.buildWrap({configId:Id}).then((res) => { 

      continueBucket();
    }).catch((err) => {
    
    })
  }

}

const [entryXml,setEntryXml] = useState(false);

const continueBucket = () =>{
  let configid = configVal.configId;
  let depIds = configVal.deployId;
 
  let statexml = document.getElementById("xmlStat"+configid).value
  let x = document.getElementById("xmlResult_"+configid);
  x.style.display = 'none';
  let xy = document.getElementById("editConfig"+configid)
  xy.style.opacity = '0.2' 
  setHasNoBuild(false);
  setLoadings(true);
  service.bucketWrap({configId:configid, xmlStatus: statexml}).then((res) => { 
    closeEntry(configid,depIds);
    setEntryXml(true);
    setLoadings(false);
    xy.style.opacity = '1';
    if(res.data === "success"){
      getApplicationDetails();
      //window.location.reload();
    }
    
  }).catch((err) => {
  
  })
}
const fileChange = (value,Id) => {

    if(value === "appnode"){
      let x = document.getElementById("appnodeshow_"+Id);
      x.style.display="block";
      let y = document.getElementById("docshow_"+Id);
      y.style.display="none";
    }

    if(value === "xmldoc"){
      let y = document.getElementById("docshow_"+Id);
      y.style.display="block";
      let x = document.getElementById("appnodeshow_"+Id);
      x.style.display="none";
    }
}

const xmlAssistant = (Id) =>{
  let xmlNodeflg = document.getElementById("xmlFlag"+Id).value;
  let appnodeshow = document.getElementById("appnodeshow_"+Id).value;
  let docshow = document.getElementById("docshow_"+Id).value;
  let xys = document.getElementById("editConfig"+Id);
  xys.style.opacity = '0.2';
  let  baseAPI_URL =process.env.REACT_APP_BASE_URL;
  let subbaseUrl =baseAPI_URL.toString().split("/");
  let hostName = subbaseUrl[2].toString().split(".");
  let path=baseAPI_URL.split(hostName[0]).join("validator");
  
  setLoadings(true)
  service.getLaunchStatus({
    appNodeValidateStatus: "",
    appNodeXml: appnodeshow,
    appNodeXmlFlag: xmlNodeflg,
    configId: Id,
    docXml: docshow,
    stylesheetFile: ""
  }).then((res) => {
    if(res?.status === 200){
      xys.style.opacity = '1';
      setLoadings(false)
      window.open(path+"/validate/gui?url="+res.data); 
    }
  }).catch((err) => {
  
  });
}
const [securityData, setSecurityData] = useState({
max_bytes_content: "",
max_bytes_per_content_hidden: "",
max_bytes_per_content_changed: "",
use_test_whitelist: "",
systest: "",
application_id: "",
product_id: 1
});

const [securityError, setSecurityError] = useState({
  max_bytes_content: false,
  systest: false,
  sysMsg:"",
  contentMsg:""
  })

const editSecurity = (conId, deployId, index) => {
  applicationData?.generateConfigObjects?.DEV.map((item, index) => {
    if (item?.id === conId) {
      item['editSk'] = true;
    }
  })
  getConfigDetails(conId,deployId);
};

const [include1, setinclude1] = useState(false);
const [include2, setinclude2] = useState(false);
const handleCloseSecurity = (conId, deployId) => {
 
  applicationData?.generateConfigObjects?.DEV.map((item, index) => {
    if (item?.id === conId) {
      item['editSk'] = false
    }
  })

  getApplicationDetails();
};

const securityEdit = (Id, deployId) => {

  const syCount = validateSecurity(securityData);
  //alert(wCount);
  if(syCount === 0){
    if(useCheck){
      securityData.use_test_whitelist = "on"
    }
    service.securityEdits(securityData).then((res) => {
      
    handleCloseSecurity(Id, deployId);
    }).catch((err) => {
  
    })
  }
 
}
const whitelistReg = /^((^\*\.)|[a-zA-Z0-9-_])*([.][.a-zA-Z0-9-_]+)$/m;
const contentReg = /^[0-9]*$/;
const handleSecurity = (e) =>{
  const newdata = { ...securityData}
  newdata[e.target.id] = e.target.value;
  if (e.target.id === "max_bytes_content") {
    if (e.target.value) {
      newdata['max_bytes_per_content_changed'] = 'yes'
      newdata['max_bytes_per_content_hidden'] = e.target.value
      if(contentReg.test(e.target.value) === false){
       setSecurityError({
          ...securityError,
          max_bytes_content: true,
          contentMsg: "Invalid Format."
        });
      }
      else{
       setSecurityError({
        ...securityError,
          max_bytes_content: false,
          contentMsg: ""
        })
      }
   
    }
    else {
      newdata['max_bytes_per_content_changed'] = 'no'
      newdata['max_bytes_per_content_hidden'] = securityEdit?.max_bytes_content
      setSecurityError({
        ...securityError,
        max_bytes_content: false,
        contentMsg: ""
      })
    }
  }

  if (e.target.id === "systest") {
    if (e.target.value) {
      if (whitelistReg.test(e.target.value) === false) {
        setSecurityError({
          ...securityError,
          systest: true,
          sysMsg: "Please enter a valid whitelist."
        });
      }
      else {
        setSecurityError({
          ...securityError,
          systest: false,
          sysMsg: ""
        })
      }
    }
    else {
      setSecurityError({
        ...securityError,
        systest: false,
        sysMsg: ""
      })
    } 
  }
  setSecurityData(newdata);
}

const validateSecurity = (securityData) =>{
 
  let swCount = 0;
  let nwerror = { ...securityError }

  if (securityData.max_bytes_content === "" || securityData.max_bytes_content === null) {
    nwerror.max_bytes_content = false;
    nwerror.contentMsg = "";
  }
  else {
    if (contentReg.test(securityData.max_bytes_content) === false) {
      nwerror.max_bytes_content= true;
      nwerror.contentMsg = "Invalid Format.";
      swCount++;
    }
    else {
      nwerror.max_bytes_content= false;
      nwerror.contentMsg = ""
    }
  }

    if (securityData.systest === "") {
      nwerror.systest = false;
      nwerror.sysMsg = "";
    }
    else {

      if (whitelistReg.test(securityData.systest) === false) {
        nwerror.systest = true;
        nwerror.sysMsg = "Please enter a valid whitelist.";
        swCount++;
      }
      else {
        nwerror.systest = false;
        nwerror.sysMsg = ""
      }
    }

  
  setSecurityError(nwerror);
  return swCount;

}

const dispalyOff = () =>{
  let xy = document.getElementById('buildmsgoff');
  xy.style.display = "none";
}
const[loadings,setLoadings] = useState(false);
const validateXMLClick = (id) =>{

  let appnodeshow = document.getElementById("appnodeshow_"+id).value;
  let docshow = document.getElementById("docshow_"+id).value;
  let xy = document.getElementById("editConfig"+id)
  xy.style.opacity = '0.2' 
  setLoadings(true);
  service.validateXml({appNodeXml:appnodeshow,docXml:docshow,configId:id,stylesheetFile:"" ,hostUrl:API_URL}).then((res) => {
   
    setLoadings(false);
    xy.style.opacity = '1' ;
      let x = document.getElementById("xmlResult_"+id)
      x.style.display = 'block'
      document.getElementById("xmlFlag"+id).value = "VALIDATE"
      if(res.data.status ===  'success'){
        document.getElementById("xmlflagRes"+id).value = "success"
        document.getElementById("xml_results_"+id).innerText = 'Validation of XML successful';
      }
      else if(res.data.status ===  'error'){
   
        document.getElementById("xmlflagRes"+id).value = "failed"
        document.getElementById("xml_results_"+id).innerText = res.data?.details;
      }
    
    }).catch((err) => {
  
    }) 
}

const fileContentChange = (e, Id) => {
  document.getElementById("xmlFlag"+Id).value = "EDITS";
}
const [downloadApks, setDownloadApks] = useState();
const [downloadApksKey, setDownloadApksKey] = useState();
const [apkList, setApkList] = useState(false);
const showApk = (buildid) => {
  services.apkUrl({build_id:buildid}).then((res) => {
  
    setDownloadApks(res?.data?.apkUrlJson);
    setDownloadApksKey(res?.data?.key)
    setApkList(true);
 }).catch((err) => {

 })
}

//const [downloadapkDebug, setDownloadApkDebug] = useState();
const showApkDebug = (index,buildid) => {
 let x = document.getElementById("debugDownload"+buildid);
 if(x.style.display === "none"){
   x.style.display = "block"
 }
 else{
  x.style.display = "none"
}
// setDownloadApkDebug(BuildDetails[index].downloadApk?.apkUrlJson);
}

const deleteVersion = () =>{
  service.deleteAppReg({applicationId: props.applicationId},'version').then((res) => {
    closeModalDelete();
    getApplicationDetails();
    getGenerateApp();
   
  }).catch((err) => {

  })
}
const launchUrl =(appId)=>{
  let  baseAPI_URL =process.env.REACT_APP_BASE_URL;
  let subbaseUrl =baseAPI_URL.toString().split("/");
  window.open(subbaseUrl[0]+"//"+"qamonitor"+"."+subbaseUrl[2]+"/login/"+appId);
  
 }
  return (
    
    <div id="base">
       <div className="row alert alert-success app-details-page-bottom-btns build_apk_Success" id="buildmsgoff" hidden="hidden" style={{display: 'none'}}>
                
        The apk build has been scheduled and you will receive an email shortly with a link to the generated file.
        <IoMdCloseCircleOutline className="modalClose" name="popup"  style={{color: '#aba8a8'}}  onClick={dispalyOff}/>
     </div>
      {loading?  <div className="loaderContainer"><Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} /></div>:null}
          {!loading && hasApp  ? (
      applicationData?.generateConfigObjects?.DEV ? <div>

        <div style={{
          flexDirection: 'row', display: 'flex', justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <div>
            <p style={{
              color: '#888787',
              fontWeight: 'bold',
              marginBottom: '30px'
            }}>
              biNu ID :- {applicationData.applicationId}
            </p>
          </div>
          <div>
            <p className="devmainHead">
              Development App Configurations, Entry Points and Builds
            </p>
          </div>
          <div>
            <label>
              Show My Configs
              <input type="checkbox" 
              checked={configWrapCheck}
              onChange={(e) => showAllWraps(e)}
              />
          </label>
          </div>

        </div>
        {loading ? <div className="loaderContainer"><Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
        {loadings ? <div className="loaderContainer" style={{marginTop:'-20px'}}>
          <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
       {!loading && hasApp ? applicationData?.generateConfigObjects?.DEV?.map((item, index) => (
              
        <div className="dev-detail-container" key={index} id={"editConfig"+item.id} style={{opacity:'1'}}>
          <div style={{ border: '2px solid black', width: '32%', borderRadius: 10, padding: 10, justifyContent: "flex-start", height:'519px' }}>
            <div style={{ justifyContent: 'flex-start', display: "flex", marginBottom: "10px" }}>
              <span className="title">biNu ID : </span>
              <span className="value"> {applicationData.applicationId}</span>
            </div>
            <div style={{ justifyContent: 'flex-start', display: "flex", marginBottom: "10px" }}>
              <span className="title">Config ID  : </span>
              <span className="value"> {item.id}</span>
            </div>
            <div style={{ justifyContent: 'flex-start', display: "flex", marginBottom: "10px" }}>
              <span className="title">Name : </span>
              <span className="value"> {item.name}</span>
            </div>
            <div style={{ justifyContent: 'flex-start', display: "flex", marginBottom: "10px" }}>
              <span className="title">Last Build : </span>
              <span className="value">{item.date}</span>
            </div>
            <div style={{ alignItems: 'flex-start', display: "flex", flexDirection: 'column' }}>
              <span className="title" style={{ marginBottom: "10px" }}>Tools : </span>
              <button className="devdtbtn1"
              style={{width:'97%',height:'45px',padding:'10px'}}
               data-id={item.id}  onClick={() =>BuildView(item.id)}
              >
                Build APK
              </button>
              <button className="devdtbtn1" data-id={item.id} 
              style={{width:'97%',height:'45px',padding:'10px'}}
              onClick={() =>viewHistory(item.id)}>
                Build History and Download
              </button>
              <button className="devdtbtn1" style={{width:'97%',height:'45px',padding:'10px'}} onClick={()=>launchxmlUrl(applicationData?.editConfigDetailsAll[index]?.entryPointHosting === "biNu"?applicationData?.editConfigDetailsAll[index]?.homeUrl:applicationData?.editConfigDetailsAll[index]?.start_url)}>

                Launch binu App XML Assistant
               
              </button>
              <button className="devdtbtn1" style={{width:'97%'}} onClick={()=>launchUrl(applicationData.applicationId)}>

               Launch WRAP #datafree Workbench
              </button> 
              <button className="devdtbtn2"
              style={{width:'97%',height:'45px',padding:'10px'}}
              data-id={item.id} onClick={()=>{openModalDelete()}} >
                Delete App Version Default
              </button>

            </div>

          </div>
          <div style={{ border: '2px solid black', width: '61%', borderRadius: 10 ,height: '537px',overflowY:'auto'}}>
         
            <AntTabs
              value={value}
              onChange={handleChange}
              className="tabChange tabViewMenu" 
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
              style={{
                backgroundColor: 'white'
              }}
              
            >
              <AntTab
                label="APK Config"
                title="Click here to view APK Config Tab"
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
              />
              <AntTab
                label="Look and Feel"
                title="Click here to view Look and Feel Tab"
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
                
              />
              <AntTab
                label="Entry Point"
                title="Click here to view Entry Point Tab"
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
                
              />
              <AntTab
                label="Security"
                title="Click here to view Security Tab"
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
                
              />
            </AntTabs>

            <TabPanel value={value} index={0}>
                <div className="developmentSecurityTab">
                  <button
                    className={!item?.editInfo ? 'editbtn' : 'NotEdit'}
                    id={item?.id} 
                    onClick={() => edit(item?.id,item?.deploymentId?.deploymentId,index)}
                    style={{ margin: '5px', borderRadius: '10px', width: '70px', height: '35px' }}
                   >
                    Edit
                  </button>
                  <button
                    className={item?.editInfo ? 'editbtn' : 'NotEdit'}
                    onClick={() => handleClose(item?.id, item?.deploymentId?.deploymentId)}
                    style={{ margin: '5px', borderRadius: '10px', width: '70px', height: '35px' }}
                    >
                    Cancel
                  </button>
                  <button
                    className={item?.editInfo ? 'editbtn' : 'NotEdit'}
                    onClick={() => saveEdit(item?.id, item?.deploymentId?.deploymentId)}
                    style={{ margin: '5px', borderRadius: '10px', width: '70px', height: '35px' }}
                    >
                    Save
                  </button>
                </div>
                <div>
                <div className="InputflexRow">
                  <div className="ddflex">
                    <label className="companyLabel" style={{ marginBottom: '5px' }}>Name</label>
                    {!item?.editInfo ? (
                    <input type="text"
                      disabled="disabled" 
                      className={"InputWrap"}
                      value={item.name} 
                      name="name"
                      id="name"
                      data-id={index}/>
                    ):(

                      <input type="text"
                      className={!apkEditError.name ? "InputWrapEdit" :"InputWrapEdit errEdit"}
                      defaultValue={item.name} 
                      name="name"
                      id="name"
                      data-id={index}
                      onChange={handleEdit}/>  
                    )
                    }

                    {item?.editInfo ? (
                    apkEditError.name ?
                    (<p 
                    className="errorClass" name="identifiererror">This field is required</p>) 
                    : ""):null}
                  </div>
                  <div className="ddflex">
                    <label className="companyLabel" style={{ marginBottom: '5px' }}>Description</label>

                    {!item?.editInfo ? (
                    <textarea type="text" disabled="disabled" 
                    className="TextAreaWrap"
                    value={item.description} 
                      name="description"
                      id="description"
                      data-id={index}>
                    </textarea>
                    ):
                    <textarea type="text" 
                    className={!apkEditError.description ? "TextAreaWrapEdit":"TextAreaWrapEdit errEdit"}
                    defaultValue={item.description} 
                      name="description"
                      id="description"
                      data-id={index}
                      onChange={handleEdit}>
                    </textarea>
                    }
                  {item?.editInfo ? (
                  apkEditError.description ?
                  (<p 
                  className="errorClass" name="identifiererror">This field is required</p>) 
                  : ""
                  ):null}
                  </div>
                </div>

              
                <div className="InputflexRow">
                  <div className="ddflex">
                    <label className="companyLabel" style={{ marginBottom: '5px' }}>biNu Platform</label> 
                    
                    <select disabled={!item?.editInfo ? true:false} 
                    className={!item?.editInfo ? "SelectWrap":"SelectWrapEdit"}
                    name="platform"
                    id="platform"
                    data-id={index}
                    onChange={handleEdit}
                     >
                      {applicationData?.platformPop && applicationData?.platformPop.length > 0
                    ? applicationData?.platformPop.map((post) => ( 
                      <option key={post.id} value={post.id} selected={item.platform === post.id && true}>
                      {post.name}
                    </option>
                      ))
                   : null} 
                    </select> 
                 
                  </div> 
                  <div className="ddflex">
                    <label className="companyLabel" style={{ marginBottom: '5px' }}>Google App Id</label>
                    <input type="text" disabled={true}  
                    defaultValue={applicationData.editConfigDetails?.packageId}
                    className="InputWrap"
                    />
                  </div>
                </div>



                <div className="InputflexRow">
                  <div className="ddflex"></div>
                  <div className="ddflex">
                    <label className="companyLabel" style={{ marginBottom: '5px' }}>Package Suffix</label>

                    {!item?.editInfo ? (
                    <input type="text" disabled="disabled"
                    value={applicationData.editConfigDetails?.packageSuffix}
                    className="InputWrap" 
                    name="package_suffix"
                    id="package_suffix"
                    data-id={index} />
                    ):
                    <input type="text"  
                    defaultValue={applicationData.editConfigDetails?.packageSuffix}
                    className="InputWrapEdit"
                    name="package_suffix"
                    id="package_suffix"
                    data-id={index} 
                    onChange={handleEdit}/>

                    }
                  </div>
                </div>


                <div className="InputflexRow">
                  <div className="ddflex">
                    <label className="companyLabel" style={{ marginBottom: '5px' }}>Distribution Email</label>
                    
                    {!item?.editInfo ? (
                    <textarea disabled="disabled"
                    className="TextAreaWrap"
                      name="distribution_email"
                      id="distribution_email"
                      data-id={index}  
                      value={applicationData.editConfigDetails?.distributionEmail}
                      >
                    </textarea>
                    ):(
                    <textarea   
                    className={!apkEditError.distribution_email ? "TextAreaWrapEdit":"TextAreaWrapEdit errEdit"}
                      name="distribution_email"
                      id="distribution_email"
                      data-id={index}  
                      defaultValue={applicationData.editConfigDetails?.distributionEmail}
                      onChange={handleEdit}>
                    </textarea>

                    )}
                    {item?.editInfo ? (
                    apkEditError.distribution_email ?
                    (<p 
                    className="errorClass" name="identifiererror">{apkEditError.emailMsg}</p>) 
                    : ""
                    ):null}
                  </div>
                  
                  {!item?.editInfo ? (
                  <div className="ddflex">
                    <div className="companyLabel">Include SDKs</div>
                    <div>
                      <input type="checkbox" 
                      style={{pointerEvents:'none'}}
                      readOnly
                      checked={item.includeSdks.split(",").includes("EMTRACKER_SDK") ? true : false} 
                      />
                      <label className="ipinputtext">
                        Effective Measure Tracker
                      </label>
                    </div>
                    <div>
                      <input type="checkbox"
                      style={{pointerEvents:'none'}}
                      readOnly
                      checked={item.includeSdks.split(",").includes("VIDEO_SDK") ? true : false} 
                      />
                      <label className="ipinputtext">
                        Video Advertising
                      </label>
                    </div>
                  </div> ) :
                   <div className="ddflex">
                    <div className="companyLabel">Include SDKs</div>
                    <div>
                      <input type="checkbox" 
                      checked={include1}
                      onChange={()=>setinclude1(!include1)}
                      />
                      <label className="ipinputtext">
                        Effective Measure Tracker
                      </label>
                    </div>
                    <div>
                      <input type="checkbox" 
                      checked={include2}
                      onChange={()=>setinclude2(!include2)}
                      />
                      <label className="ipinputtext">
                      Video Advertising
                      </label>
                     
                    </div>
                  </div> }
                </div>

                  

                <div className="InputflexRow">
                
                {!item?.editInfo ? (
                  <div className="ddflex">
                    <div>
                    <label className="companyLabel">Include Developer Menu</label>
                      <input type="checkbox"
                      style={{pointerEvents:'none',marginTop:'0px'}}
                      checked={applicationData?.configDev[index].includeDevMenu === true ? true : false}/>
                    </div>
                    <div className="companyLabel">App Permissions</div>
                    <div>
                      <input type="checkbox" 
                      style={{pointerEvents:'none'}}
                      checked={applicationData?.configDev[index].locationPermission === true ? true : false} 
                      readOnly/>
                       <label className="ipinputtext">
                        Allow App to request Location Service Permission on
                        install
                      </label>
                    </div>
                    <div>
                      <input type="checkbox"
                      style={{pointerEvents:'none'}}
                      checked={applicationData?.configDev[index].storagePermission === true ? true : false} 
                      readOnly/>
                      <label className="ipinputtext">Allow App to request Storage Permission on install</label>
                    </div>
                    
                  </div>
                  ) : 
                  <div className="ddflex">
                    <div>
                      <label className="companyLabel">Include Developer Menu</label>
                      <input type="checkbox"
                       style={{marginTop:'0px'}}
                       onChange={() => setMenu(!menu)}
                       checked={menu}/>
                    </div>
                    <div className="companyLabel">App Permissions</div>
                    <div>
                      <input type="checkbox" 
                      onChange={() => setLocation(!location)}
                      checked={location} />
                      <label className="ipinputtext">
                        Allow App to request Location Service Permission on
                        install
                      </label>
                    </div>
                    <div>
                      <input type="checkbox"
                      onChange={() => setStorage(!storage)}
                      checked={storage} />
                      <label className="ipinputtext">Allow App to request Storage Permission on install</label>
                    </div>
                    
                  </div>
                  }
                </div>
                </div>
            </TabPanel>

            <TabPanel value={value} index={1}>
                <div className="developmentSecurityTab">
                  <button className={!item?.editLk ? 'editbtn' : 'NotEdit'} 
                  id={item?.id} 
                  style={{ margin: '5px', borderRadius: '10px', width: '70px', height: '35px' }}
                  onClick={() => editLook(item?.id,item?.deploymentId?.deploymentId, index)}>
                  Edit
                </button>

                <button className={item?.editLk ? 'editbtn' : 'NotEdit'} 
                style={{ margin: '5px', borderRadius: '10px', width: '70px', height: '35px' }}
                onClick={() => handleCloseLook(item?.id, item?.deploymentId?.deploymentId,index)}>
                  Cancel
                </button>

                <button className={item?.editLk ? 'editbtn' : 'NotEdit'} 
                style={{ margin: '5px', borderRadius: '10px', width: '70px', height: '35px' }}
                onClick={() => lookFeelEdit(item?.id,item?.deploymentId?.deploymentId)}>Save</button>

                </div>
                <div className="InputflexRow">
                  <div className="ddflex">
                    <div className="companyLabel" style={{ marginBottom: '5px', width: '100%' }}>Onboarding URL</div>
                    {!item?.editLk ?
                    <div>
                    <input type="text" disabled="disabled"
                    className="InputWrap"
                    value={applicationData?.configDev[index].onBoardingUrl} />
                    </div>
                      :
                      <div>
                      <input type="text" 
                      className={lookError.onboarding_url ? "InputWrapEdit errEdit" : "InputWrapEdit"}
                      id="onboarding_url"
                      value={lookFeel.onboarding_url} 
                      onChange={handleFeel}
                      
                      />
                      {lookError.onboarding_url ? (
                        <span style={{float:'left'}} className="errormsg">{lookError.urlMsg}</span>
                      ) : ""}
                    </div>
                      
                      } 
                      
                  </div>
                  <div className="ddflex">
                    <div className="companyLabel" style={{ marginBottom: '5px', width: '100%'}}>Use Dark Action Bar Icons</div>
                    <div>

                    {!item?.editLk ?
                      <select 
                      className="SelectWrap"
                      disabled={true}>
                        <option value="no" selected={applicationData?.configDev[index]?.useDarkActionBarIcons === false && true}>No</option>
                        <option value="yes" selected={applicationData?.configDev[index]?.useDarkActionBarIcons === true && true}>Yes</option>
                      </select>
                      :
                      <select 
                      className="SelectWrapEdit"
                      id="use_dark_action_bar_icons"
                      onChange={handleFeel}
                      >
                        <option value="no" selected={lookFeel.use_dark_action_bar_icons === "no" && true}>No</option>
                        <option value="yes" selected={lookFeel.use_dark_action_bar_icons === "yes" && true}>Yes</option>
                      </select>
                    }
                    </div>
                  </div>
                </div>
                <div className="InputflexRow">
                <div className="ddflex">
                {colorchecked?<div>
                <div className="companyLabel">Status Bar Color</div>
                      <div>
                        <input
                          type="text"
                          className={lookError.status_manual ? "registerInput errEdit" : "registerInput"}
                          id="status_manual"
                          value={lookFeel.status_manual}
                          onChange={handleFeel}
                        />

                        {lookError.status_manual ? (
                          <span style={{float:'left'}} className="errormsg">{lookError.statusMsg}</span>
                        ) : (
                          ""
                        )}
                      </div>
                </div>:""}
                {!colorchecked? <div className="companyLabel" style={{ marginBottom: '5px' }}>Status Bar Color</div>:""}
                {!colorchecked?
                    <div className="colorPickerDiv">
                    {!item?.editLk ?
                      <>
                      <input
                        type="color"
                        className="colorBox"
                        style={{cursor:'not-allowed' }}
                        value={applicationData?.configDev[index]?.statusBarColor}
                      />
                      <input
                        type="text"
                        className="colorInput"
                        disabled="disabled"
                        value={applicationData?.configDev[index]?.statusBarColor}
                      />
                      </>
                      :
                      <>
                      <input
                        type="color"
                        className="colorBox"
                        id="status_bar_color"
                        style={{ cursor: "pointer",cursor:'not-allowed' }}
                        value={lookFeel.status_bar_color}
                        onChange={handleFeel}
                      />
                      <input
                        type="text"
                        className="colorInput"
                        disabled="disabled"
                        value={lookFeel.status_bar_color}
                      />
                      </>
                    }
                      
                    </div>:""}
                    <div>
                      <label className="companyLabel">
                        Click here to Enter Manually
                        <input type="checkbox" 
                        disabled={!item?.editLk ? "disabled" : false} 
                        onChange={() => setColorchecked(!colorchecked)}
                        checked={colorchecked}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="ddflex">
                     {actioncolorchecked?<div>
                    <div className="companyLabel">Action Bar Color</div>
                          <div>
                            <input
                              type="text"
                              className={lookError.header_manual ? "registerInput errEdit" : "registerInput"}
                              placeholder=""
                              onChange={handleFeel}
                              id="header_manual"
                              value={lookFeel.header_manual}
                            />
                         {lookError.header_manual ? (
                          <span style={{float:'left'}} className="errormsg">{lookError.headerMsg}</span>
                        ) : (
                          ""
                        )}
                          </div>
                    </div>:""}
                    {!actioncolorchecked? <div className="companyLabel" style={{ marginBottom: '5px', width: '100%' }}>Action Bar Color</div>:""}
                    {!actioncolorchecked? <div className="colorPickerDiv">
                    {!item?.editLk ?
                    <>
                      <input
                        type="color"
                        className="colorBox"
                        style={{ cursor: "not-allowed" }}
                        value={applicationData?.configDev[index]?.actionBarColor}
                      />
                      <input
                        type="text"
                        className="colorInput"
                        disabled="disabled"
                        value={applicationData?.configDev[index]?.actionBarColor}
                      />
                    </>
                      :
                      <>
                      <input
                        type="color"
                        className="colorBox"
                        style={{ cursor: "pointer" }}
                        id="header_color"
                        onChange={handleFeel}
                        value={lookFeel.header_color}
                      />

                      <input
                        type="text"
                        className="colorInput"
                        disabled="disabled"
                        value={lookFeel.header_color}
                      />
                      </>
                    }
                      
                    </div>:""}
                    <div>
                      <label className="companyLabel">
                        Click here to Enter Manually
                        <input type="checkbox" 
                        disabled={!item?.editLk ? "disabled" : false} 
                        onChange={() => setActionColorchecked(!actioncolorchecked)}
                        checked={actioncolorchecked}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="InputflexRow">
                    <div className="ddflex">
                    {accentcolorchecked?<div>
                    <div className="companyLabel">Accent Color</div>
                          <div>
                            <input
                              type="text"
                              className={lookError.accent_manual ? "registerInput errEdit" : "registerInput"}
                              placeholder=""
                              id="accent_manual"
                              onChange={handleFeel}
                              value={lookFeel.accent_manual}
                            />
                        {lookError.accent_manual ? (
                          <span style={{float:'left'}} className="errormsg">{lookError.accentMsg}</span>
                        ) : (
                          ""
                        )}
                          </div>
                    </div>:""}
                    { !accentcolorchecked?<div className="companyLabel" style={{ marginBottom: '5px' }}>Accent Colour</div>:""}
                    { !accentcolorchecked?<div className="colorPickerDiv">
                    {!item?.editLk ?
                    <>
                      <input
                        type="color"
                        className="colorBox"
                        style={{ cursor: "not-allowed" }}
                        value={applicationData?.configDev[index]?.accentColor}
                      />
                      <input
                        type="text"
                        className="colorInput"
                        disabled="disabled"
                        value={applicationData?.configDev[index]?.accentColor}
                      />
                    </>
                      :
                      <>
                       <input
                        type="color"
                        className="colorBox"
                        id="accent_color"
                        style={{ cursor: "pointer" }}
                        onChange={handleFeel}
                        value={lookFeel.accent_color}
                      />
                      <input
                        type="text"
                        className="colorInput"
                        disabled="disabled"
                        value={lookFeel.accent_color}
                      />
                    </>
                    }
                    </div>:""}
                    <div>
                      <label className="companyLabel">
                        Click here to Enter Manually
                        <input type="checkbox" 
                         onChange={() => setAccentColorchecked(!accentcolorchecked)}
                         checked={accentcolorchecked}
                        disabled= {!item?.editLk ? "disabled" : false }/>
                      </label>
                    </div>
                  </div>
                </div>
            </TabPanel>

            <TabPanel value={value} index={2}>
              <div className="developmentSecurityTab" >
                  <button className={!item?.editEty ? 'editbtn' : 'NotEdit'} 
                  id={item?.id} 
                  style={{ margin: '5px', borderRadius: '10px', width: '70px', height: '35px' }}
                  onClick={() => editEntry(item?.id,item?.deploymentId?.deploymentId, index)}>
                 Edit
                </button>

                <button className={item?.editEty ? 'editbtn' : 'NotEdit'} 
                style={{ margin: '5px', borderRadius: '10px', height: '35px' }}
                onClick={() => handleCloseEntry(item?.id, item?.deploymentId?.deploymentId,index)}>
                   Undo All Edits
                </button>

                <button className={item?.editEty ? 'editbtn' : 'NotEdit'} 
                style={{ margin: '5px', borderRadius: '10px', height: '35px' }}
                onClick={() => EntryEdit(item?.id,item?.deploymentId?.deploymentId)}>Save All Edits</button>
              </div>
              <input type="hidden" id="bucketid" value=""></input>
              
                <div className="InputflexRow">
                  <div className="entrypointLabel">
                    Entry Point Hosting{" "}
                  </div>
                  <input
                    type="text"
                    className="entryPointinputField"
                    name="name"
                    disabled="disabled"
                    value={applicationData?.editConfigDetailsAll[index]?.entryPointHosting}
                  />
                  <div className="flexSpace8"></div>
                  <div className="entrypointLabel">XML Validated</div>
                  {applicationData?.editConfigDetailsAll[index]?.xmlValidated ? 
                  <span className="xmlvalidateStyle">
                    <FaTimes />
                  </span>
                  : <span className="xmlvalidateStyle">
                
                </span> }
                </div>
                <input type="hidden" id={"xmlStat"+item.id} 
                value={applicationData?.editConfigDetailsAll[index]?.xmlValidated ? "1" : "0"}></input>
             
                <div className="appnodeMainDiv">
                  <div className="xmlEditDiv">
                    <div className="marginBottomClass">
                      <label className="entrypointLabel">
                        File
                      </label>
                        <select 
                        id="fdropdown"
                        style={{height:'21px'}}
                        className={!item?.editEty ? "filecustomSelect" : "filecustomsSelect"}
                        onChange={(e)=>fileChange(e.target.value,item?.id)}
                        >
                          <option value="appnode">Appnode</option>
                          <option value="xmldoc">XML Doc</option>  
                           
                        </select>
                        <label className="downarrow">
                          <FaLongArrowAltDown />
                        </label>

                      <div>
                       
                        <textarea className="formgroup" id={"appnodeshow_"+item?.id}   hidden="hidden"
                        style={{color:'red',display:'block'}} 
                        defaultValue={applicationData?.editConfigDetailsAll[index]?.xmlFile}
                        disabled={item?.editEty ? false : "disabled"}
                        onChange={(e) => fileContentChange(e, item?.id)}
                        >
                        </textarea>
                        
                        <textarea className="formgroup" id={"docshow_"+item?.id}  hidden="hidden"
                        style={{color:'red',display:'none'}} 
                        disabled={item?.editEty ? false : "disabled"}
                        defaultValue={applicationData?.editConfigDetailsAll[index]?.xmlDocFile}
                        onChange={(e) => fileContentChange(e, item?.id)}
                        >
                        
                        </textarea>

                        <div className="clearfix">
                          <button className={item?.editEty ? 'editbtn' : 'subtabButton'} 
                          style={{width:'100%',height:'34px'}} 
                          color="primary" 
                          onClick={()=>xmlAssistant(item?.id)}>
                           
                            Launch XML Assistant
                           
                          </button>
                          <div className="flexSpace"></div>
                          <button className={item?.editEty ? 'editbtn' : 'subtabButton'} 
                            style={{width:'100%',height:'34px'}} 
                            data-configid={item?.id}
                            id="xmlClick"
                            onClick={()=>validateXMLClick(item?.id)}
                            color="primary">
                            Validate XML
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input type="hidden" id={"xmlFlag"+item?.id} value="READY"/>
                <input type="hidden" id={"xmlflagRes"+item?.id} value=""/>
                <div className="appnodeMainDiv" id={"xmlResult_"+item?.id} hidden="hidden"
                style={{display:'none'}}>
                  <div className="xmlEditDiv">
                      <div id={"xml_results_"+item?.id} style={{color:'red'}}></div>  
                  </div>
                </div>
            </TabPanel>

            <TabPanel value={value} index={3}>
                <div className="developmentSecurityTab">
                <button className={!item?.editSk ? 'editbtn' : 'NotEdit'} 
                  id={item?.id} 
                  style={{ margin: '5px', borderRadius: '10px', width: '70px', height: '35px' }}
                  onClick={() => editSecurity(item?.id,item?.deploymentId?.deploymentId, index)}>
                  Edit
                </button>

                <button className={item?.editSk ? 'editbtn' : 'NotEdit'} 
                style={{ margin: '5px', borderRadius: '10px', width: '70px', height: '35px' }}
                onClick={() => handleCloseSecurity(item?.id, item?.deploymentId?.deploymentId)}>
                  Cancel
                </button>

                <button className={item?.editSk ? 'editbtn' : 'NotEdit'} 
                style={{ margin: '5px', borderRadius: '10px', width: '70px', height: '35px' }}
                onClick={() => securityEdit(item?.id,item?.deploymentId?.deploymentId)}>Save</button>
                </div>
               
              <div className="InputflexRow">
                <div className="ddflex"></div>
                <div className="ddflex alignRight">
                  <div>
                    <label className="ipinputtext">
                      Use whitelist
                    </label>
                    {!item?.editSk ?  (<input
                      type="checkbox"
                      style={{pointerEvents:'none'}}
                      checked={useCheck}
                      readOnly
                    />) : <input
                      type="checkbox"
                      checked={useCheck}
                      onChange={()=> setUseCheck(!useCheck)} />}
                  </div>
                </div>
              </div>
              <div className="InputflexRow">

                <div className="ddflex">
                  <div className="companyLabel">
                    Max Bytes per content object
                  </div>
                  <div>
                  {!item?.editSk ?  (
                      <input
                        type="text"
                        className="InputWrap"
                        disabled="disabled"
                        value={applicationData.editDevSecurityRulesDetails?.maxBytesContent}
                      />
                    ) : (
                      <input
                        type="text"
                        className={securityError.max_bytes_content ? "InputWrapEdit errEdit" : "InputWrapEdit"}
                        name="max_bytes_content"
                        id="max_bytes_content"
                        value={securityData?.max_bytes_content} 
                        onChange={handleSecurity}
                      />
                    )}
                    {securityError.max_bytes_content ?
                      (<p
                        className="errorClass" name="identifiererror">{securityError.contentMsg}</p>)
                      : ""}
                  </div>
                </div>


                <div className="ddflex">
                  <div className="companyLabel">Test Whitelist</div>
                  <div>
                  {!item?.editSk ?  (
                      <textarea
                        type="text"
                        className="TextAreaWrap"
                        disabled="disabled"
                        value={applicationData.editDevSecurityRulesDetails?.devWhiteList}

                      />
                    ) : (
                      <textarea
                        type="text"
                        className={securityError.systest ? "TextAreaWrapEdit errEdit" : "TextAreaWrapEdit"}
                        value={securityData.systest}
                        id="systest"
                        onChange={handleSecurity}
                      />
                    )}
                    {securityError.systest ?
                      (<p
                        className="errorClass" name="identifiererror">{securityError.sysMsg}</p>)
                      : ""}
                  </div>
                </div>
              </div>
            </TabPanel>
          </div>
        </div>
      ))
      : null }
      </div> : null ) : null}
      {!loading ? <div className="configDefinebtn" onClick={openModal}
      title="Click here to add new Development Config">
        {" "}
        + Create Development App Configuration and Define Entry Point
      </div> : null}
      {/** configration clone dialoge box */}
     

      {/** without clone/ add new confiration */}
      <Dialog
        open={mdnewconfig}
        style={customStylesNewConfig}
        scroll={scroll}
        classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm"
        //style={{maxWidth:'43% !important'}}
        aria-labelledby="form-dialog-title"
      >
       <DialogTitle id="form-dialog-title" className="dialogtitle connectTitle">
         
         <div className="cmpdialogtitle"> Create App Config and Entry Point
         <IoMdCloseCircleOutline className="modalClose" name="popup"  onClick={closeModalNewConfig} />
         </div>
        
       <div style={{lineHeight:'2.6 !important'}}>{" "}</div>
       </DialogTitle>

       <DialogContent>
        <div style={{ margin: '10px' }}>
          <p>App Configuration Options</p>
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', margin: '10px' }}
        >
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label>Name</label>
            <input  
            className={noError.name ? "newconfig errEdit" : "newconfig"}
            id="name"
            onChange={addNo}
            type="text" />

          {noError.name ?
          (<p 
               className="errorClass" name="identifiererror">This field is required</p>) 
              : ""} 
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label>Description</label>
            <textarea
            id="description"
            style={{height:'55px'}} 
            className={noError.description ? "newconfig errEdit" : "newconfig"}
            onChange={addNo}
            > </textarea>
            {noError.description ?
          (<p 
               className="errorClass" name="identifiererror">This field is required</p>) 
              : ""} 
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label>biNu Platform</label>
            <select
              type="text"
              id="platform"
              name="platform"
              className={noError.platform ? "newconfig errEdit" : "newconfig"}
              style={{height:'34px'}}
              fullWidth
              onChange={addNo}
            >
              <option value=""></option>
                      {platformData && platformData.length > 0
                      ? platformData.map((post) => ( 
                        <option key={post.id} value={post.id}>
                        {post.name}
                      </option>
                        ))
                    : null}
            </select>
            {noError.platform ?
          (<p 
               className="errorClass" name="identifiererror">This field is required</p>) 
              : ""} 
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label>Google App Id</label>
            <input className="newconfiginput" type="text" disabled={true} value={applicationData?.googleAppId}/>
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label>Package Suffix</label>
            <input 
            className={noError.package_suffix ? "newconfig errEdit" : "newconfig"}
            id="package_suffix" 
            onChange={addNo}
            type="text" />

        {noError.package_suffix ?
          (<p 
               className="errorClass" name="identifiererror">{noError.packageMsg}</p>) 
              : ""}
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label>Distribution Email</label>
            <textarea 
            id="distribution_email"
            style={{height:'55px'}}
            className={noError.distribution_email ? "newconfig errEdit" : "newconfig"}
            onChange={addNo}
            value={noData.distribution_email}
            > </textarea>
            {noError.distribution_email ?
          (<p 
               className="errorClass" name="identifiererror">{noError.emailMsg}</p>) 
              : ""}
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label>Include SDKs</label>
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label>
              <input type="checkbox" 
              name="include_sdks"
              value="EMTRACKER_SDK"
              checked={include1}
              onChange={()=>setinclude1(!include1)}
              />
              Effective Measure Tracker
            </label>
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label>
              <input type="checkbox" 
              name="include_sdks"
              value="VIDEO_SDK"
              checked={include2}
              onChange={()=>setinclude2(!include2)}
              />
              Video Advertising
            </label>
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label>
              Include Developer Menu
              <input type="checkbox" 
              id="include_developer_menu"
              style={{marginTop:'0px'}}
              checked={developerMenu}
              onChange={()=> setDeveloperMenu(!developerMenu)}
              />
            </label>
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label style={{ fontWeight: 'bolder' }}>Look and Feel</label>
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label>Onboarding URL</label>
            <input 
            className={noError.onboarding_url ? "newconfig errEdit" : "newconfig"}
            onChange={addNo}
            id="onboarding_url"
             type="text" />

        {noError.onboarding_url ?
          (<p 
               className="errorClass" name="identifiererror">{noError.urlMsg}</p>) 
              : ""}
          </div>
            {colorchecked ? <div>
              <div className="registerFormTitle  textcls">Status Bar Colour</div>
              <div>
                <input
                  type="text"
                  className={noError.status_manual ? "registerInputs errEdit" : "registerInputs"}
                  placeholder=""
                  name="maxByteContent"
                  id="status_manual"
                  onChange={addNo}
                  value={noData.status_manual}
                />

          {noError.status_manual ?
          (<p 
               className="errorClass" name="identifiererror" style={{marginLeft:'10px'}}>{noError.statusMsg}</p>) 
              : ""}
              </div>
            </div> : ""}
            <div className="flexrow backGrndCOLOR">
              {!colorchecked ? <div className="registerFormTitle textcls">Status Bar Colour</div> : ""}

              {!colorchecked ? <div className="colorPickerDiv">
                <input
                  type="color"
                  className="colorBox"
                  id="status_bar_color"
                  style={{ cursor: "pointer" }}
                  value={noData.status_bar_color}
                  onChange={addNo}
                />
                <input
                  type="text"
                  className="colorInput"
                  disabled="disabled"
                  id="status_bar_color"
                  value={noData.status_bar_color}
                />
              </div> : ""}
              <div className="spanFormTitle textcls">Click here to Enter Manually</div>
              <div>
                <input
                  type="checkbox"
                  className="spancheck"
                  name="websiteName"
                  id="websiteName"
                  onChange={() => setColorchecked(!colorchecked)}
                  checked={colorchecked}
                />
              </div></div>
            {/* //Action Color */}

            {actioncolorchecked ? <div>
              <div className="registerFormTitle textcls">Action Bar Colour</div>
              <div>
                <input
                  type="text"
                  className={noError.header_manual ? "registerInputs errEdit" : "registerInputs"}
                  placeholder=""
                  name="maxByteContent"
                  id="header_manual"
                  onChange={addNo}
                  value={noData.header_manual}
                />

              {noError.header_manual ?
              (<p 
               className="errorClass" name="identifiererror" style={{marginLeft:'10px'}}>{noError.headerMsg}</p>) 
              : ""}
              </div>
            </div> : ""}
            <div className="flexrow backGrndCOLOR">
              {!actioncolorchecked ? <div className="registerFormTitle textcls">Action Bar Colour</div> : ""}

              {!actioncolorchecked ? <div className="colorPickerDiv">
                <input
                  type="color"
                  className="colorBox"
                  id="header_color"
                  style={{ cursor: "pointer" }}
                  value={noData.header_color}
                  onChange={addNo}
                />
                <input
                  type="text"
                  className="colorInput"
                  disabled="disabled"
                  id="header_color"
                  value={noData.header_color}
                />
              </div> : ""}
              <div className="spanFormTitle textcls">Click here to Enter Manually</div>
              <div>
                <input
                  type="checkbox"
                  className="spancheck"
                  name="websiteName"
                  id="websiteName"
                  onChange={() => setActionColorchecked(!actioncolorchecked)}
                  checked={actioncolorchecked}
                />
              </div></div>
            {/* Accent Color */}
            {accentcolorchecked ? <div>
              <div className="registerFormTitle textcls">Accent Colour</div>
              <div>
                <input
                  type="text"
                  className={noError.accent_manual ? "registerInputs errEdit" : "registerInputs"}
                  placeholder=""
                  name="maxByteContent"
                  id="accent_manual"
                  onChange={addNo}
                  value={noData.accent_manual}
                />
                {noError.accent_manual ?
              (<p 
               className="errorClass" name="identifiererror" style={{marginLeft:'10px'}}>{noError.accentMsg}</p>) 
              : ""}
              </div>
            </div> : ""}
            <div className="flexrow backGrndCOLOR">
              {!accentcolorchecked ? <div className="registerFormTitle textcls">Accent Colour</div> : ""}

              {!accentcolorchecked ? <div className="colorPickerDiv">
                <input
                  type="color"
                  className="colorBox"
                  id="accent_color"
                  style={{ cursor: "pointer" }}
                  value={noData.accent_color}
                  onChange={addNo}
                />
                <input
                  type="text"
                  id="accent_color"
                  className="colorInput"
                  disabled="disabled"
                  value={noData.accent_color}
                />
              </div> : ""}
              <div className="spanFormTitle textcls">Click here to Enter Manually</div>
              <div>
                <input
                  type="checkbox"
                  className="spancheck"
                  name="websiteName"
                  id="websiteName"

                  onChange={() => setAccentColorchecked(!accentcolorchecked)}
                  checked={accentcolorchecked}
                />
              </div></div>
         

          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label>Use Dark Action Bar Icons</label>
            <select
              type="text"
              id="use_dark_action_bar_icons"
              name="use_dark_action_bar_icons"
              className="newconfig"
              style={{height:'34px'}}
              fullWidth
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label style={{ fontWeight: 'bolder' }}>App Entry Point, Structure</label>
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label>Website URL</label>
            <input 
            className={noError.website_url ? "newconfig errEdit" : "newconfig"}
            onChange={addNo}
            id="website_url"
            type="text" />

          {noError.website_url ?
          (<p 
               className="errorClass" name="identifiererror">{noError.webMsg}</p>) 
              : ""} 
          </div>
          </div>

          </DialogContent>
          <DialogActions>
          {loading?  <div className="loaderContainer"><Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} /></div>:null}
         
        <div style={{ margin: '10px', flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
          <div className="ivitediv">
            <button
              className="inviteButton"
              onClick={closeModalNewConfig}
              >
              Cancel
            </button><button className="inviteButton" 
            disabled={clickSaveDis ? "disabled" : false}
            onClick={noSave}
           >
             Save and Return
            </button>
          </div>
         
        </div>
        </DialogActions>
      </Dialog>

      {/** with clone/ add new confiration */}
      <Dialog
        open={mdcloneconfig}
        style={customStylesCloneConfig}
        scroll={scroll}
        classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm"
        contentLabel="Clone confiration"
      >
        <DialogTitle id="form-dialog-title" className="dialogtitle connectTitle">
         
         <div className="cmpdialogtitle"> Clone App Config and Entry Point
         <IoMdCloseCircleOutline className="modalClose" name="popup"  onClick={closeModalCloneConfig} />
         </div>
        
       <div style={{lineHeight:'2.6 !important'}}>{" "}</div>
       </DialogTitle>
       <DialogContent>
        <div style={{ margin: '10px' }}>
          <p>Choose Development Configuration to Clone</p>
        </div>
        <div style={{ margin: '10px', flexDirection: 'column', display: 'flex', border:'none' }}>
          <label>Select Development Configuration</label>
           <select
              type="text"
              id="dev_configs"
              name="dev_configs"
              onChange={(e) => addYes(e)}
              className={yesError.dev_configs ? "newconfig errEdit" : "newconfig"}
              style={{height:'38px'}}
              fullWidth
            >
              <option value="">Select from list</option>
              {configDetail && configDetail.length > 0
                ? configDetail.map((post) => (
                  <option key={post.configId} value={post.configId}>
                    {post.name}
                  </option>
                ))
                : null}
            </select>
            {yesError.dev_configs ?
          (<p 
               className="errorClass" name="identifiererror">This field is required</p>) 
              : ""} 
        </div>
        <div style={{ margin: '10px' }}>
          <p>App Config Options</p>
        </div>
        <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
          <label>New Name for Cloned Config</label>
          <input 
          className={yesError.name ? "cmpnydialoginput errEdit" : "cmpnydialoginput"} 
          type="text" 
          id="name"
          value={yesData.name}
          onChange={(e) => addYes(e)} 
          
          />
           {yesError.name ?
          (<p 
               className="errorClass" name="identifiererror">This field is required</p>) 
              : ""} 
        </div>
        <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
          <label>New Description for Cloned Config</label>
          <input 
           className={yesError.description ? "cmpnydialoginput errEdit" : "cmpnydialoginput"} 
          type="text"  
          id="description"
          value={yesData.description}
          onChange={(e) => addYes(e)}
          
          />
           {yesError.description ?
          (<p 
               className="errorClass" name="identifiererror">This field is required</p>) 
              : ""} 
        </div>
        </DialogContent>
        <DialogActions>
        {loading?  <div className="loaderContainer"><Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} /></div>:null}
         
        
        <div className="ivitediv">
            <button
              className="inviteButton"
              onClick={closeModalCloneConfig}
              >
              Cancel
            </button>
         
            <button className="inviteButton" 
            disabled={clickSaveDis ? "disabled" : false}
            onClick={yesSave}
            >
             Save and Return
            </button> </div>
         
        
        </DialogActions>
      </Dialog>

    {/** Build APK Model */}
    <Dialog
       open={mdbuildapk} 
       scroll={scroll}
       classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" onClose={closeModalBuildApk} aria-labelledby="form-dialog-title"
      >
        

        <DialogTitle id="form-dialog-title" className="dialogtitle connectTitle">
         
          <div className="cmpdialogtitle"> Build APK
          <IoMdCloseCircleOutline className="modalClose" name="popup"  onClick={closeModalBuildApk} />
          </div>
         
        <div style={{lineHeight:'2.6 !important'}}>{" "}</div>
        </DialogTitle>
        <DialogContent>
        <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
          <label style={{fontSize:'14px'}}>biNu Id :</label>
          <input className="newconfiginput" type="text" disabled={true} 
          value={BuildApkData?.obj?.binuAppId}
          style={{cursor:'not-allowed'}}/>
        </div>
        <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
          <label style={{fontSize:'14px'}}>Server Entry Point Config</label>
          <input className="newconfiginput" type="text" disabled={true} value={BuildApkData?.obj?.configName} 
          style={{cursor:'not-allowed'}}/>
        </div>
        
        <div style={{ margin: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <label style={{ marginRight: '22px',fontSize:'14px' }}>Entry Point Hosting</label>
          <input className="wrapInputs" type="text" disabled={true}  value={BuildApkData?.obj?.entryPointHosting}/>
        </div>
        <div style={{ margin: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop:'-17px' }}>
          <label style={{ marginRight: '20px',fontSize:'14px' }}>Onboarding URL</label>
          <input className="wrapInputs" type="text" disabled={true} value={BuildApkData?.obj?.onBoardingUrl}/>
        </div>
        <div style={{ margin: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop:'-17px' }}>
          <label style={{ marginRight: '20px',fontSize:'14px' }}>biNu Platform</label>
          <input className="wrapInputs" type="text" disabled={true} value={BuildApkData?.obj?.binuName}/>
        </div>
        <div style={{ margin: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop:'-17px' }}>
          <label style={{ marginRight: '20px',fontSize:'14px' }}>Name</label>
          <input className="wrapInputs" type="text" disabled={true} value={BuildApkData?.obj?.packageSuffix}/>
        </div>
        <div style={{ margin: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop:'-17px' }}>
          <label style={{ marginRight: '20px',fontSize:'14px' }}>Value</label>
          <input className="wrapInputs" type="text" disabled={true} value={BuildApkData?.obj?.reachGateway}/>
        </div>
        
        <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
          <label style={{fontSize:'14px'}}>Version Code</label>
          <input 
          className={!ApkError.version_code ? "newconfig" :"newconfigerr"}
          type="text"
           name="version_code" id="version_code" 
          defaultValue={BuildApkData?.obj?.versionCode}
          onChange={handleApkEdit} />
            {ApkError.version_code ? (
                <span style={{float:'left'}} className="errormsg topAlign">{ApkError.versionMsg}</span>
              ) : (
                ""
              )}
        </div>
        <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
          <label style={{fontSize:'14px'}}>Version Name</label>
          <input 
          className={!ApkError.version_name ? "newconfig" :"newconfigerr"}
          type="text" 
          name="version_name" id="version_name" 
          defaultValue={BuildApkData?.obj?.versionName}
          onChange={handleApkEdit} />
          {ApkError.version_name ? (
                <span style={{float:'left'}} className="errormsg topAlign">This field is required</span>
              ) : (
                ""
              )}
        </div>
        <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
          <label style={{fontSize:'14px'}}>Comments</label>
          <input 
          className={!ApkError.comments ? "newconfig" :"newconfigerr"}
          type="text" 
          name="comments" id="comments" 
          defaultValue={BuildApkData?.obj?.comment}
          onChange={handleApkEdit}/>
          {ApkError.comments ? (
                <span style={{float:'left'}} className="errormsg topAlign">This field is required</span>
              ) : (
                ""
              )}
        </div>
        <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
          <label style={{fontSize:'14px'}}>On completion of the APK generation, an email will be sent to any valid addresses noted in the Email Distribution List</label>
        </div>
        <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
          <label style={{fontSize:'14px'}}>Email Distribution</label>
          <input 
          className={!ApkError.email_distribution ? "newconfig" :"newconfigerr"}
          type="text" name="email_distribution" 
          id="email_distribution"
          defaultValue={BuildApkData?.obj?.distributionEmail}
          onChange={handleApkEdit}/>

            {ApkError.email_distribution ? (
                <span style={{float:'left'}} className="errormsg topAlign">{ApkError.emailMsg}</span>
              ) : (
                ""
              )}
        </div>
        {BuildApkData?.userType === "INTERNAL" ? (
        <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
          <label style={{fontSize:'14px'}}>Build Type</label>
          <select 
          className={!ApkError.buildTypes ? "newconfig" :"newconfigerr"}
          type="text" 
          style={{height:'35px'}}
          name="buildTypes" id="buildTypes" onChange={handleApkEdit}>
          <option value="">Select</option>
             {BuildPopup && BuildPopup.length > 0
                    ? BuildPopup.map((post) => ( 
                      <option key={post.id} value={post.id}>
                      {post.name}
                    </option>
                      ))
                   : null}     
          </select>

          {ApkError.buildTypes ? (
                <span style={{float:'left'}} className="errormsg topAlign">This field is required</span>
              ) : (
                ""
              )}
        </div>
        ) : null}
        <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
          <label style={{fontSize:'14px'}}>Package Suffix Override</label>
          <input className="newconfig" type="text" 
          name="package_suffix"
          id="package_suffix"
          onChange={handleApkEdit}
          defaultValue={BuildApkData?.obj?.packageSuffix}/>
        </div>
        </DialogContent>
        
        <DialogActions>
        {loading?  <div className="loaderContainer"><Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} /></div>:null}
        <div style={{ margin: '10px', flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ margin: '10px'}}>
            <button
              className="BuildBtn"
              onClick={closeModalBuildApk}
              >
              Cancel
            </button>
          </div>
          <div style={{ margin: '10px', height: '30px' }}>
            <button className="BuildBtn" 
            
            onClick={BuildSave}>
             Build
            </button>
          </div>
        </div>
        </DialogActions>
      </Dialog>

 <Dialog open={openBuild} 
 scroll={scroll}
 classes={{ scrollPaper: classes.scrollPaper }} 
 fullWidth 
 maxWidth="lg" onClose={handleAppClose} aria-labelledby="form-dialog-title">
        
        <DialogTitle id="form-dialog-title" className="dialogtitle connectTitle">
         
          <div className="cmpdialogtitle"> Build History
          <IoMdCloseCircleOutline className="modalClose" name="popup"  onClick={openBuildClose} />
          </div>
         
        <div style={{lineHeight:'2.6 !important'}}>{" "}</div>
        </DialogTitle>
        <DialogContent>
         
        <div style={{
          flexDirection: 'row', display: 'flex', justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <div>
            <p style={{
              fontWeight: 'normal',
            }}>
              biNu ID :- {applicationData.applicationId}
            </p>
          </div>
          <div>
            <p style={{
              fontWeight: 'normal',
            }}>
              
            </p>
          </div>
          <div>
            <label>
               Show Builds for all Configs
              <input type="checkbox" 
               checked={configCheck}
               onChange={(e) => showBuilds(e)}
              />
            </label>
          </div>

        </div>
        <input type="hidden" id="hideValue" value={configIdData}></input>
              <Grid container spacing={1} >
                <Grid item xs={1}>
                  <div className="dialoginputtext" style={{fontWeight:'400'}}>Build Id</div>
               
                </Grid> 
               
                <Grid item xs={2}>
                  <div className="dialoginputtext" style={{fontWeight:'400'}}>Date</div>
                 
                </Grid>
                <Grid item xs={2}>
                  <div className="dialoginputtext" style={{fontWeight:'400'}}>Base Config Name</div>
                  
                </Grid>
                <Grid item xs={1}>
                  <div className="dialoginputtext" style={{fontWeight:'400'}}>Version Code</div>
                </Grid>
                <Grid item xs={1}>
                  <div className="dialoginputtext" style={{fontWeight:'400'}}>Version Name</div>
               </Grid>
                <Grid item xs={1}>
                  <div className="dialoginputtext" style={{fontWeight:'400'}}>Binu Version</div>
                 
                </Grid>

               <Grid item xs={1}>
                  <div className="dialoginputtext" style={{fontWeight:'400'}}>Status</div>
                 
                </Grid>
              </Grid>

              {BuildDetails && BuildDetails.length > 0 ?
              BuildDetails.map((apk,index) => ( 
                
                <Grid container spacing={1} style={{borderTop: '1px solid #e5e5e5'}} key={index}>
                <Grid item xs={1}>
                  <div className="dialoginputtext">{apk.id}</div>
                </Grid> 
                <Grid item xs={2}>
                  <div className="dialoginputtext">{apk.created + " GMT"}</div>
                </Grid>
                <Grid item xs={2}>
                  <div className="dialoginputtext">{apk.configName}</div>
                </Grid>
                <Grid item xs={1}>
                  <div className="dialoginputtext" >{apk.versionCode}</div>
                </Grid>
                <Grid item xs={1}>
                  <div className="dialoginputtext">{apk.versionName}</div>
               </Grid>
                <Grid item xs={1}>
                  <div className="dialoginputtext" style={{textAlign:'center'}}>{apk.tag}</div>
                </Grid>
               <Grid item xs={1}>
                  <div className="dialoginputtext">{apk.status}</div>
                </Grid>
                <Grid item xs={2}>
                  <div className="dialoginputtext">
                  {apk.buildType === "NORMAL" ?
                     apk.downloadApk !== null ? 

                    (
                      <a className="anchDownload" 
                      onClick={() => showApk(apk.id)}
                      >Download</a>
                    ) : "Download" : null}

                  {apk.buildType !== "NORMAL" ? 
                    apk.downloadApk !== null ? 
                  (
                    <a className="anchDownload" 
                    onClick={() => showApkDebug(index,apk.id)}
                    >Download Apk</a>
                  ) : "Download Apk" 
                
                    : null }
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div className="dialoginputtext">
                    <a style={{cursor:'pointer'}} onClick={() => GetInfo(apk.id)}>More Info</a>
                  </div>
                </Grid>
                
                 
                <div id={"debugDownload"+apk.id} style={{display:'none'}}>
                  {apk && apk.downloadApk?.apkUrlJson?.map((uls,index) => (

                <Grid container>
                  <div className="dialoginputtext" style={{marginBottom:'20px'}}>
                
                  <label className="downstyle">
                    <a className="anchDownload" 
                     href={API_URL+apk.key+uls.url}
                     download
                    >{"Download "+ uls.channel +"  Apk"}</a>
                    </label>
                  </div>
                </Grid>

                  ))
                
                  }
                </div>
                <div  aria-label="simple table" style={{display:'none', fontSize:'14px'}} name="more_info" id={"Moreinfo_" + apk.id}>
                
                <span>
                <b>Deployment Id : </b>
                {MoreData?.deploymentId?.deploymentId ? MoreData?.deploymentId?.deploymentId : ""}
                </span>
                <br/>
                <br/>
                <span>
                <b>Base Config Id : </b>
                {MoreData?.baseConfigId?.configId ? MoreData?.baseConfigId?.configId : ""}
                </span>
                <br/>
                <br/>
                <span>
                <b>Base Config Name : </b>
                {MoreData?.baseConfigId?.name ? MoreData?.baseConfigId?.name : ""}
                </span>
                <br/>
                <br/>
                <span>
                <b>Deployment Type : </b>
                {MoreData?.deploymentType ? MoreData?.deploymentType : ""}
                </span>
                <br/>
                <br/>
                <span>
                <b>Proxy Endpoint : </b>
                {MoreData?.proxyEndpoint ? MoreData?.proxyEndpoint : ""}
                </span>
                <br/>
                <br/>
                <span>
                <b>Google App Id : </b>
                {MoreData?.googleAppId ? MoreData?.googleAppId : ""}
                </span>
                <br/>
                <br/>
                <span>
                <b>Distribution Email  : </b>
                {MoreData?.distributionEmail ? MoreData?.distributionEmail : ""}
                </span>
                <br/>
                <br/>
                <span>
                <b>Build Type : </b>
                {MoreData?.buildType ? MoreData?.buildType : ""}
                </span>
                <br/>
                <br/>
                <span>
                <b>Status Datetime : </b>
                {MoreData?.statusDatetime ? MoreData?.statusDatetime : ""}
                </span>
                <br/>
                <br/>
                <span>
                <b>Comments : </b>
                {MoreData?.comment ? MoreData?.comment : ""}
                </span>
                <br/>
                <br/>
                <span>
                <b>Version Tag : </b>
                {MoreData?.versionTag ? MoreData?.versionTag : ""}
                </span>
                <br/>
                <br/>
                <span><b>App Attributes</b> <br/>
                {AppAttributes ? AppAttributes.map((att) =>(
                 <div key={att}>
                   <br/><label>{att}</label><br/>
                   
                   </div> 
                
                ) ): null}
               
                </span>
                <span><b>Channels :</b> 
                {MoreData?.channels ? MoreData?.channels : ""}  
                </span>
                <br />
                <br />
                <span><b>Generated By :</b> 
                {MoreData?.userId?.name ? MoreData?.userId?.name : ""}  
                </span>
                <br />
                <br />
                <span><b>Created On :</b> 
                {MoreData?.userId?.created ? MoreData?.userId?.created : ""}  
                </span>
              </div>
                
              </Grid>


                ))
             : null}
              

               
        </DialogContent>
        <DialogActions>
          <div className="ivitediv">
            <button className="inviteButton" onClick={openBuildClose} color="primary">
              Return
            </button>
          </div>
        </DialogActions>
      </Dialog>


      <Dialog open={appdelet} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" name="popup" onClick={closeModalDelete} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle  border">
          <div className="FlexRow border" >
            <div className="cmpdialogtitle"> </div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline style={{ cursor: "pointer" }} 
              className="modalClose"
              name="popup" onClick={closeModalDelete} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="ContentHead">
         <div className="warningIcon"><MdWarning/></div>
         <div className="warningText">WARNING !</div>
       
          </div>
          <div className="logoConfirmtext">Are you sure you want to delete this #datafree App Version ? 
          This cannot be undone.</div>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv" style={{textAlign:'center'}}>
            <button className="continueBtn" name="popup" onClick={closeModalDelete} color="primary">
              Cancel
            </button>

            <button className="continueBtn" name="popup"  color="primary" onClick={deleteVersion}>
            Confirm Delete
            </button>
          </div>
        </DialogActions>
      </Dialog>

      

      <Dialog
        open={modalIsOpen}
        aria-labelledby="form-dialog-title"
        classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm"
        contentLabel="Example Modal"
      >
        
        <DialogTitle id="form-dialog-title" className="dialogtitle connectTitle">
         
          <div className="cmpdialogtitle"> Clone Development Config as template 
          <IoMdCloseCircleOutline className="modalClose" name="popup"  onClick={closeModal} />
          </div>
         
        <div style={{lineHeight:'2.6 !important'}}>{" "}</div>
        </DialogTitle>
        <DialogContent>
          <div className="logoConfirmtext" style={{fontSize:'16px'}}>Do you want to clone a previously defined configuration?</div>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv" style={{textAlign:'center'}}>
            <button className="continueBtns" name="popup" onClick={openModalCloneConfig} color="primary">
              Yes
            </button>

            <button className="continueBtns" name="popup"  onClick={openModalNewConfig} color="primary">
            No
            </button>
          </div>
        </DialogActions>
        
      </Dialog>


      <Dialog open={undoEdits}  classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{minHeight:'50px !important'}} 
aria-labelledby="form-dialog-title">
      
        <DialogContent className="diaContent">
          <div className="logoConfirmtext">Undo Edits Complete.</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" name="popup" color="primary" onClick={() => setUndoEdits(false)}>
             OK
            </button>
          </div>
        </DialogActions>
            </Dialog>

            <Dialog open={hasNoBuild}  classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{minHeight:'50px !important'}} 
aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className="connectTitle">
         
         <div className="headXml"> Warning ! Entry Point Invalid.
         </div>
        
       <div style={{lineHeight:'2.6 !important'}}>{" "}</div>
       </DialogTitle>
        <DialogContent className="diaContent">
          <div className="logoConfirmtext" style={{color:'#939598'}}>
            <p> Your Entry Point XML is invalid. If you choose to continue, any Generated APK will likely NOT 
              function as expected.</p>
              
            <p> Cancel if you wish to further edit the Entry Point XML and re-validate. You can also use the
            XML Assistant to validate the Entry Point XML
           </p>
          
            </div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
          <button className="popupOk" name="popup" color="primary" 
          style={{marginRight:'10%', width:'35%'}}
          onClick={() => setHasNoBuild(false)}>
             Cancel
            </button>
            <button className="popupOk" name="popup" color="primary" 
             style={{width:'35%'}}
            onClick={continueBucket}>
             Continue
            </button>
          </div>
        </DialogActions>
            </Dialog>


            <Dialog open={entryXml}  classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{minHeight:'50px !important'}} 
aria-labelledby="form-dialog-title">
      
        <DialogContent className="diaContent">
          <div className="logoConfirmtext">New Entry Point XML Saved.</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" name="popup" color="primary" onClick={() => setEntryXml(false)}>
             OK
            </button>
          </div>
        </DialogActions>
            </Dialog>

      
            <Dialog open={hasNoValidate}  classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{minHeight:'50px !important'}} 
aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className="connectTitle">
         
         <div className="headXml"> Edits Not Validated
         </div>
        
       <div style={{lineHeight:'2.6 !important'}}>{" "}</div>
       </DialogTitle>
        <DialogContent className="diaContent">
          <div className="logoConfirmtext">Validate XML Prior to Saving</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" name="popup" color="primary" onClick={() => setHasNoValidate(false)}>
             OK
            </button>
          </div>
        </DialogActions>
            </Dialog>

            <Dialog open={apkList} classes={{ scrollPaper: classes.scrollPaper }}
            scroll={scroll}  
            maxWidth="sm" 
            fullWidth
            aria-labelledby="form-dialog-title"
            >
        

        <DialogTitle id="form-dialog-title" className="dialogtitle connectTitle">
         
          <div className="cmpdialogtitle">Click to Download
          </div>
         
        <div style={{lineHeight:'2.6 !important'}}>{" "}</div>
        </DialogTitle>
        <DialogContent>
         
              {downloadApks?.map((ul,index) => (
                <Grid Xs={12} >
                <Grid item xs={12}>
              <div className="dialoginputtext" style={{marginBottom:'20px'}}>
                
                <label className="downstyle">{"Download "+ ul.channel +"  Apk"}</label>
                <span style={{marginLeft:'4%'}}>
                <a style={{color:'black'}} 
                href={API_URL+downloadApksKey+ul.url} download><FaDownload /></a>
                </span>
                </div>
              </Grid> 

            <Grid item xs={12}>
            <div className="dialoginputtext" style={{marginBottom:'20px'}}>
              
              <label className="downstyle">{"Download "+ ul.channel +"  Aab"}</label>
              <span style={{marginLeft:'4%'}}>
                       <a style={{color:'black'}} 
                       href={API_URL+downloadApksKey+ul.aabUrl} download><FaDownload /></a>
              </span>
              </div>
            </Grid> 
            </Grid>
              ))}
              
               
        </DialogContent>
        <DialogActions>
          <div className="ivitediv">
          <button className="inviteButtonCancel" onClick={()=>setApkList(false)} color="primary">
              Cancel
            </button>
          </div>
        </DialogActions>
      </Dialog>


      <Dialog open={xmlNodeFound} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" name="popup" onClick={closeModalDelete} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle  border">
          <div className="FlexRow border" >
            <div className="cmpdialogtitle"> </div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline style={{ cursor: "pointer" }}
              className="modalClose"
              name="popup" onClick={()=>setXmlNodeFound(false)}/>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="ContentHead">
         <div className="warningIcon"><MdWarning/></div>
         <div className="warningText">WARNING !</div>
       
          </div>
          <div className="logoConfirmtext" style={{fontSize:'14px'}}>  
          <p>Error App Node file not found please contact biNu Support at:</p>
          <a href="https://www.bi.nu/contact">https://www.bi.nu/contact</a></div>
        </DialogContent>
        <DialogActions>
           <div className="popupbutton">
            <button className="popupOk" 
            style={{color:'red',border:'2px solid red'}}
            name="popup"  color="primary" onClick={()=>setXmlNodeFound(false)}>
            OK
            </button>
          </div>
        </DialogActions>
      </Dialog>

    </div>
  )

}

 