import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Service from "../../../../api/service";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { trackPromise } from "react-promise-tracker";
import moment from "moment";
function DirectPage() {
  const { paramsId } = useParams();
  const [applicationData, setApplicationData] = useState();
  const getDetails = () => {
    try {
      trackPromise(
        Service.applicationData(paramsId).then((res) => {
          setApplicationData(res.data.applicationObj);
        })
      );
    } catch (err) {
      
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <div className="directapplication">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="gridHead">App Details</div>
        </Grid>

        <Grid item xs={4}>
          <div className="labelDirect">App Name</div>
          <div>
            <input type="text" className="directFieldInput" disabled="disabled" value={applicationData ? applicationData.appName : ""} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="labelDirect">Deployment Type</div>
          <div>
            <input type="text" className="directFieldInput" disabled="disabled" value={applicationData ? applicationData.deploymentType : ""} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="labelDirect">Status</div>
          <div>
            <input type="text" className="directFieldInput" disabled="disabled" value={applicationData ? applicationData.status : ""} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="labelDirect">Created</div>
          <div>
            <input type="text" className="directFieldInput" disabled="disabled" value={moment(applicationData ? applicationData.created : "").format("Do MMM  YYYY")} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="labelDirect">Defn. Approved</div>
          <div>
            <input type="text" className="directFieldInput" disabled="disabled" value={applicationData ? applicationData.ipConfigApproved : ""} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="gridHead">Data Usage Limits</div>
        </Grid>
        <Grid item xs={3}>
          <div className="labelDirect">Monthly App #datafree Data Alert Limit (bytes)</div>
        </Grid>
        <Grid item xs={3}>
          <div>
            <input type="text" className="directFieldInput" disabled="disabled" value={applicationData ? applicationData.appDataUsageAlert : ""} />
          </div>
        </Grid>

        <Grid item xs={3}>
          <div className="labelDirect">Alert Email Addresses (comma separated)</div>
        </Grid>
        <Grid item xs={3}>
          <div>
            <input type="text" className="directFieldInput" disabled="disabled" value={applicationData ? applicationData.alertEmail : ""} />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className="labelDirect">Maximum Bytes per User</div>
        </Grid>
        <Grid item xs={3}>
          <div>
            <input type="text" className="directFieldInput" disabled="disabled" value={applicationData ? applicationData.maxBytesPerUser : ""} />
          </div>
        </Grid>

        <Grid item xs={6}></Grid>
        <Grid item xs={12}>
          <div className="rowClass">
            <div className="labelDirect">Include Daily #datafree Data Limit Alerting ?</div>

            <div container className="datafeeBox">
              <div className="labelDirect">Daily #datafree data limit (bytes)</div>

              <div>
                <input type="text" className="BoxsubFieldInput" disabled="disabled" value={applicationData ? applicationData.maxBytesPerDay : ""} />
              </div>
              <div className="labelDirect">Required Action on Daily Limit</div>

              <div>
                <select type="text" className="BoxFieldInput" disabled="disabled">
                  <option>Send Alerts Only</option>
                </select>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="gridHead">biNu Server Assignment</div>
        </Grid>
        <Grid item xs={4}>
          <div className="labelDirect">Reserved IP</div>
          <div>
            <input type="text" className="directFieldInput" disabled="disabled" value={applicationData ? applicationData.ipId : ""} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className="labelDirect">biNu App Id</div>
          <div>
            <input type="text" className="directFieldInput" disabled="disabled" value={applicationData ? applicationData.appId : ""} />
          </div>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={12}>
          <div className="gridHead">Customer Server Specifics</div>
        </Grid>
        <Grid item xs={4}>
          <div className="labelDirect">IPv4 Address - Endpoint</div>
          <div>
            <input type="text" className="directFieldInput" disabled="disabled" value={applicationData ? applicationData.directIpForward : ""} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="labelDirect">biNu #datafree Sub-Domain</div>
          <div>
            <input type="text" className="directFieldInput" disabled="disabled" value={applicationData ? applicationData.reachSubDomain : ""} />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="labelDirect">biNu #datafree Gateway</div>
          <div>
            <input type="text" className="directFieldInput" disabled="disabled" value={applicationData ? applicationData.reachGateway : ""} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="gridHead">Protocol and Port Requirement</div>
        </Grid>
        <Grid item xs={4}>
          <div className="labelDirect">Protocol</div>
        </Grid>
        <Grid item xs={4}>
          <div className="labelDirect">Start of Port Range</div>
        </Grid>
        <Grid item xs={4}>
          <div className="labelDirect">End of Port Range</div>
        </Grid>
        <Grid item xs={10}>
          <div className="datafeeSubBox">
            <div className="boxRow">
              <div>
                <input type="text" disabled="disabled" className="inputBoxRow" value="GRE" />
              </div>
              <div>
                <input type="text" disabled="disabled" className="inputBoxRow" />
              </div>
              <div>
                <input type="text" disabled="disabled" className="inputBoxRow" />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className="divSpace"></div>
    </div>
  );
}
export default DirectPage;
