import React, { useState, useEffect ,useContext} from "react";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Grid,
  withStyles,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Loader from "react-loading";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { MdWarning } from "react-icons/md";
import alpha from "../../../assets/img/blank1.png";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import { trackPromise } from "react-promise-tracker";
import Box from "@material-ui/core/Box";
import accountIcon from "../../../assets/img/account-icon-black.png";
import VMCTable from '../../../Recomponent/Table';
import Service from "../../../api/licenseeService";
import Services from "../../../api/adminService";
import EmptyTable from "../../../Recomponent/EmptyTable"
import { usePromiseTracker } from "react-promise-tracker";
import {
  ToggleContext
} from "../../context/toggleContext"
function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div>
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const AntTabs = Tabs;

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    borderBottom: "1px solid #ddd ",
    minWidth: "80px",

    textShadow: "none",
    color: "#0059b6",

    "&$selected": {
      color: "#555",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderBottomColor: "transparent",
      borderBottom: "none",
      cursor: "default",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
const useStyles = makeStyles((theme) => ({
  scrollPaper: {
    alignItems: "baseline",
  },
}));

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader type="spokes" color="#333333" height={30} width={30} />
      </div>
    )
  );
};


function LicenseeAccountDetailView() {
  let file = null;
  const [value, setValue] = React.useState(0);
  const [editInfo, setEditInfo] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = useState("paper");
  let history = useHistory();
  let API_URL = process.env.REACT_APP_API_URL;
  const classes = useStyles();
  const toggleContextVal = useContext(ToggleContext)
  const handleChange = (event, newValue) => {
    localStorage.setItem("Tab", newValue)
    setValue(newValue);

  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
    setEditInfo(false);
    setOpenUser(false);
    setInviteError({
      name: false,
      email_address: false,
      companyId: false,
      nameErMsg: "",
      emailErrMsg: "",
    });
    setInvite({
      ...invite,
      name: "",
      email_address: ""
    });
  };
  const [userData, SetUserData] = useState()
  const [tableUsercols, setTableUserCols] = useState([
    {
      colName: 'User’s Name',
      colMap: 'name',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Email Address',
      colMap: 'email',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Role',
      colMap: 'role',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Type',
      colMap: 'userType',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Verified',
      colMap: 'verified',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Status',
      colMap: 'status',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Created',
      colMap: 'created',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: true,
      imprRoute: false,
      userTab: false
    },


  ])
  const [tableServicecols, setTableServiceCols] = useState([
    {
      colName: 'Name',
      colMap: 'name',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Description',
      colMap: 'email',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Service',
      colMap: 'role',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Proxy Domain',
      colMap: 'userType',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Zero Rated ?',
      colMap: 'verified',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Status',
      colMap: 'status',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },


  ])
  const [licenseeService, setLicenseeService] = useState();
  const [invitedSuccess, setInvitedSuccess] = useState(false);
  const [lodervisible, setlodervisible] = useState(false);
  const [gatewayDomain, setGatewayDomain] = useState();
  const [empty, setEmpty] = useState(false);
  const licenseeAccountDetailView = (event) => {
    setEmpty(false);
    SetUserData();
    setlodervisible(true);
    try {
      trackPromise(
        Service.licenseeAccountDetailView().then((res) => {
          setlodervisible(false);
          SetUserData(res.data);
          setLicenseeService(res.data.licenseeService)
          setGatewayDomain(res.data.licenseeService)
          setEditData({
            company_name: res.data?.publisherDetail?.name ? res.data?.publisherDetail?.name : "",
            logo_icon_image: res.data?.publisherDetail?.logoImage ? res.data?.publisherDetail?.logoImage : "",
            company_id: res.data?.publisherDetail?.company_id ? res.data?.publisherDetail?.company_id : "",
            removeCompanyLogo: "",
            status: res.data?.publisherDetail?.status ? res.data?.publisherDetail?.status : "",
          })
          setChannelCompanyId(res.data?.publisherDetail?.company_id);
          invite["companyId"] = res.data?.publisherDetail?.company_id;

          if ((res.status == 200 && res.data.usersListDetail.length === 0) || (res.status == 200 && res.data.licenseeService.length === 0)
            || (res.status == 200 && res.data.gatewayDomain.length === 0)) {

            setEmpty(true);
          } else {
            setEmpty(false);
          }
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }


  const edit = () => {
    setEditInfo(true);
    localStorage.setItem("EditOpen", true)
  };
  const inviteNew = () => {
    setOpen(true);
    setScroll('body');
  };
  const [removeDialogue, setRemoveDialogue] = useState(false);
  const [ImgRemoved, setImgRemoved] = useState(false);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [editData, setEditData] = useState({
    company_name: "",
    logo_icon_image: "",
    status: "",
    company_id: "",
    removeCompanyLogo: "",
  });
  const removeLogo = () => {
    setImgRemoved(true);
    setRemoveDialogue(false);
    setEditData({
      company_name: userData?.publisherDetail.name,
      logo_icon_image: null,
      status: userData?.publisherDetail.status,
      company_id: userData?.publisherDetail.company_id,
      removeCompanyLogo: "yes",

    });

    setImage({ preview: "", raw: "" });
  };
  const DialogClose = () => {

    setImgRemoved(false);
    setRemoveDialogue(false);
  }
  const [error, setError] = useState({
    company_name: false,
    logo_icon_image: false,
    nameMsg: "",
    imgMsg: ""

  });
  //edit Image
  async function EditImageData() {
    if (error.company_name) {
      setError({
        ...error,
        company_name: true,
      });
    }
    else if (error.logo_icon_image) {
      setError({
        ...error,
        logo_icon_image: true,
      });
    }
    else {
      let file = document.getElementById("logo_icon_image").files[0];


      let values = file != undefined ? new FormData() : "";
      if (file != undefined) {
        values.append("logo_icon_image", file ? file : "");
      }
      try {

        if (values.toString().trim().length > 0) {
          trackPromise(

            Services.editImage(values).then((res) => {

              if (res.status === 200) {

                editData['logo_icon_image'] = res.data;
                setImgRemoved(false);
                EditChannelData(editData);

              }
              // 
            })
          );
        }
        else {
          EditChannelData(editData);
        }

      } catch (err) {
        setErrMsg("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      }
    }

  }
  const [successopen, setsuccessOpen] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState();
  async function EditChannelData() {
    try {
      trackPromise(
        Service.accountEdit(editData).then((res) => {
          if (res.status === 200) {
            setToastMsg("Licensee Details Edited Successfully!");
            setEditInfo(false);
            setsuccessOpen(true);
          } else {
            seterrorOpen(true);
            setEditInfo(true);
          }

          licenseeAccountDetailView();

        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }

  const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
  const nameReg = /^.*[A-Za-z].*/i;
  const emailReg = /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/;
  const [channelCompanyId, setChannelCompanyId] = useState();
  const dataChange = (e) => {
    e.preventDefault();
    const newdata = { ...editData };
    newdata[e.target.id] = e.target.value;

    if (e.target.id === "company_name") {
      CheckCompany(e.target.value, channelCompanyId)
      if (e.target.value) {
        if ((e.target.value).includes(",")) {
          setError({
            ...error,
            company_name: true,
            nameMsg: "Please enter a name without comma",
          });
        }
        else if (nameReg.test(e.target.value) === false) {
          setError({
            ...error,
            company_name: true,
            nameMsg: "Field must have at least 1 character a-z or A-Z",
          });
        }
        else {
          setError({
            ...error,
            company_name: false,
            nameMsg: ""
          })
        }

      }

      else {
        setError({
          ...error,
          company_name: true,
          nameMsg: "This field is required."
        })
      }

    }

    if (e.target.id === "logo_icon_image") {
      if (e.target.files.length !== 0) {
        var img = document.createElement("img");
        img.src = URL.createObjectURL(e.target.files[0]);
        var filePath = e.target.files[0].name;

        if (!allowedExtensions.exec(filePath)) {
          setImage({
            preview: "",
            raw: "",
          });
          setError({
            ...error,
            logo_icon_image: true,
            imgMsg: "Selected file is not an image"
          });
        }
        else {
          img.onload = function () {
            if (img.width >= 65 && img.height >= 180) {

              setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
              });
              setError({
                ...error,
                logo_icon_image: false,
                imgMsg: ""
              });

            }
            else if (img.width <= 65) {
              setImage({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                logo_icon_image: true,
                imgMsg: "Minimum width must be 65px"
              });
            }
            else if (img.height <= 180) {
              setImage({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                logo_icon_image: true,
                imgMsg: "Minimum height must be 180px"
              });
            }
          };
        }
      }
      else {
        setImage({
          preview: "",
          raw: "",
        });
        setError({
          ...error,
          logo_icon_image: false,
          imgMsg: ""
        });

      }
    }


    setEditData(newdata);
  };

  const [resVal, setResVal] = useState();
  async function CheckCompany(name, id) {


    try {
      if (name.includes(",")) {
        setError({
          ...error,
          company_name: true,
          nameMsg: "Please enter a name without comma",
        });
      }
      else if (nameReg.test(name) === false) {
        setError({
          ...error,
          company_name: true,
          nameMsg: "Field must have at least 1 character a-z or A-Z",
        });
      }
      else {
        trackPromise(
          Services.CheckCompany({ companyname: name, companyid: id }).then((res) => {

            setResVal(res.data);
            if (res.data === true) {
              setError({
                ...error,
                company_name: false,
                nameMsg: ""
              })
            }

            else {
              setError({
                ...error,
                company_name: true,
                nameMsg: "This​ Company ​name ​already​ exist"
              })
            }

          })
        );
      }

    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }

  const [openPopup, setOpenPopup] = useState(false);

  document.addEventListener("click", e => {

    let editNot = localStorage.getItem("EditOpen");
    if (editNot) {
      if (e.target.id === "Edit" || e.target.id === "credit_control_email" || e.target.id === "Cancel" || e.target.id === "Save" || e.target.id === "Return" || e.target.name === "logo_icon_image" || e.target.id === "logo_icon_image"  || 
        e.target.type === "file" || e.target.name === "company_name" || e.target.name === "popup" || e.target.id === "status" || e.target.id === "Remove") {

        if (e.target.id === "Cancel" || e.target.id === "Save" || e.target.id === "Return") {
          localStorage.removeItem("EditOpen")

        }
        setOpenPopup(false);
      }
      else {
        setOpenPopup(true);
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();

      }
    }
    else {
      setOpenPopup(false);

    }

  }, true);
  const reg = /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/;
  const [inviteError, setInviteError] = useState({
    name: false,
    email_address: false,
    companyId: false,

    nameErMsg: "",
    emailErrMsg: "",
  });

  const [invite, setInvite] = useState({
    name: "",
    email_address: "",
    companyId: "",
    user_role: "licensee"
  });
  const handleUser = (e) => {

    const invitedata = { ...invite };
    invitedata[e.target.id] = e.target.value;
    if (e.target.id === "name") {
      if (e.target.value) {
        setInviteError({
          ...inviteError,
          name: false,
        });
      } else {
        setInviteError({
          ...inviteError,
          name: true,
        });
      }

    }
    if (e.target.id === "email_address") {
      if (e.target.value) {
        EmailCheck(e.target.value);
      } else {
        setInviteError({
          ...inviteError,
          email_address: true,
          emailErrMsg: "This field is required",
        });
      }
    }
    setInvite(invitedata);

  };

  ////Check Email
  async function EmailCheck(value) {
    trackPromise(
      Service.checkMails({ email_address: value })
        .then((res) => {
          if (res.data === false) {
            setInviteError({
              ...inviteError,
              email_address: true,
              emailErrMsg: "A User exist with this email.Contact Datafree Support if you wish this user to be linked to this account",
            });
          } else if (value && reg.test(value) === false) {
            setInviteError({
              ...inviteError,
              email_address: true,
              emailErrMsg: "Please enter a valid email address.",
            });
          } else {
            setInviteError({
              ...inviteError,
              email_address: false,
            });
          }
        })
        .catch((error) => {
          setErrMsg("Something went wrong. Please reload the page!");
          seterrorOpen(true);
        })
    );
  }

  const [clickInvite, setClickInvite] = useState(false);
  const inviteUser = () => {

    if (invite.name.length == 0 && invite.email_address.length == 0) {
      setInviteError({
        name: true,
        email_address: true,
      });
    } else if (inviteError.name) {
      setInviteError({
        ...inviteError,
        name: true,
      });
    } else if (inviteError.email_address) {
      setInviteError({
        ...inviteError,
        email_address: true,
      });
    }
    else {
      setlodervisible(true);
      setClickInvite(true);
      try {
        trackPromise(
          Service.inviteNew(invite).then((res) => {

            if (res.data === "success") {
              handleClose();
              setlodervisible(false);
              setOpen(false);
              setInvitedSuccess(true);
              setInvite({
                ...invite,
                name: "",
                email_address: ""
              });

            }
            setClickInvite(false);
            licenseeAccountDetailView();
          })
        );
      } catch (err) {
        setErrMsg("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      }

    }
  }
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const ServiceDetail = (id) => {
    history.push(`/licensee/account/view/${id}/service/details`);
  }
  useEffect(() => {
    setlodervisible(true);
    licenseeAccountDetailView();
    localStorage.removeItem("EditOpen")
  }, []);

  return (
    <div className="accountContent">
      <div className={toggleContextVal.event ?"accountMenuView" :"accountView"}>
        <div className="accounthead">
          <div>
            <img src={accountIcon} alt=""></img>
          </div>
          <div className="accountToptext">
            Accounts Details - Licensee View
          </div>
        </div>
        <AntTabs
          value={value}
          className="tabViewMenu"
          onChange={handleChange}
          aria-label="ant example"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          <AntTab
            label="Company"
            title="Click here to view Company Tab"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          />
          <AntTab
            label="Users"
            title="Click here to view Users Tab"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          />
          <AntTab
            label="Licensee Services"
            title="Click here to view Deployed Ips"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          />
          <AntTab
            label="Gateway Domain"
            title="Click here to view Gateway Domains"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          />
        </AntTabs>
        <Typography className={classes.padding} />
        <TabPanel value={value} index={0}>

          {
            lodervisible &&
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: '0px',
              right: '0px',
              margin: 'auto',
              position: 'absolute',
              left: '0px',
              bottom: '0px',
              zIndex: '3000',
              backgroundColor: "#ffffff",
              //boxShadow: '0px 0px 5px -1px rgba(0,0,0,0.75)',
              // paddingLeft: '2px',
              // paddingRight: '2px',
              paddingTop: '8px',
              paddingBottom: '8px',
              borderRadius: '5px',
              flexDirection: 'column',
              width: '70px',
              height: '60px'
            }} >
              <Loader
                type="spokes" color="#333333" height={30} width={30}
              />
            </div>
          }

          <div className="companyForm">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4} md={4}>
                <div className="companyLabel">Company Name</div>

                <div>
                  {!editInfo ? (
                    <input
                      type="text"
                      className="form-control"
                      name="Publishername"
                      disabled="disabled"
                      style={{ cursor: 'not-allowed' }}
                      value={userData?.publisherDetail?.name}
                    />
                  ) : (
                    <input
                      type="text"
                      className={!error.company_name ? "form-control" : "form-control errorField"}
                      name="popup"
                      id="company_name"
                      defaultValue={userData?.publisherDetail?.name}
                      onChange={(e) => dataChange(e)}
                    />
                  )}
                  {error.company_name ? <a className="errorClass">{error.nameMsg}</a> : null}
                </div>
              </Grid>
              <Grid item  xs={12} sm={1} md={1}></Grid>
              <Grid item xs={12} sm={4} md={4}>
                <div className="companyLabel">Company Logo</div>
                {editInfo || userData?.publisherDetail?.logoImage !== null ?
                  <div className="fileIcon"><img
                    src={
                      image.preview
                        ? image.preview :
                        !ImgRemoved && userData?.publisherDetail.logoImage ?
                          API_URL + userData?.publisherDetail.logo_file + userData?.publisherDetail.logoImage
                          : alpha}
                    onError={(event) => event.target.src = alpha}
                    alt=""
                    className="imgStyle"
                  ></img></div> : ""}
                {editInfo ? (
                  <div className="imgFileRow">
                    <div className="companyLabel">Upload Company Logo </div>
                    <div className="filesubText">
                      (Min. dimensions of 180px X 65px)
                    </div>
                    {error.logo_icon_image ? <a className="errorClass">{error.imgMsg}</a> : null}
                    <input type="file"  id="logo_icon_image"
                      name="logo_icon_image" onChange={(e) => dataChange(e)} />
                    <label htmlFor="file">{file}</label>
                  </div>
                ) : null}
              </Grid>
              <Grid item  xs={12} sm={3} md={3}>
                {!editInfo ? (
                  <div className="InputflexRow">
                    <div className="companyListflex"><button className="inviteButton"
                      id="Edit" onClick={edit}>
                      Edit Details
                    </button></div>
                  </div>
                ) : null}
              </Grid>
              {editInfo && !ImgRemoved && userData?.publisherDetail?.logoImage ? (
                <Grid item xs={12}>
                  <div className="ivitediv">
                    <button className="changeOwnButton spaceTop" id="Remove" onClick={() => setRemoveDialogue(true)}>
                      Remove Company Logo
                    </button>
                  </div>
                </Grid>
              ) : null}
              <Grid item xs={12} sm={4} md={4}>
                <div className="companyLabel">Licensee Shortcode</div>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="popup"
                    disabled="disabled"
                    style={{ cursor: 'not-allowed' }}
                    value={userData?.publisherDetail?.licensee_shortcode}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4}></Grid>
              <Grid item xs={12} sm={4} md={4}></Grid>
              <Grid item  xs={12} sm={4} md={4}>
                <div className="companyLabel">Status</div>
                <div>
                  {!editInfo ? (
                    <input
                      type="text"

                      className="form-control"
                      name="companyname"
                      id="companyname"
                      disabled="disabled"
                      style={{ cursor: 'not-allowed' }}
                      value={userData?.publisherDetail?.status}
                    />
                  ) : (
                    <select type="text" className="form-control" name="popup"
                      id="status"
                      onChange={(e) => dataChange(e)}
                     >

                      {userData?.publisherDetail?.status === "Approved" ? (<>
                        <option value="Approved" selected>Approved</option>
                        <option value="Suspended">Suspended</option>
                        <option value="Archived">Archived</option>

                      </>) :

                        userData?.publisherDetail?.status === "New" ? (<>
                          <option value="New" selected>New</option>
                          <option value="Approved">Approved</option>
                          <option value="NoAgreement">NoAgreement</option>

                        </>) :
                          userData?.publisherDetail?.status === "NoAgreement" ? (<>
                            <option value="NoAgreement" selected>NoAgreement</option>
                            <option value="Approved">Approved</option>
                            <option value="Archived">Archived</option>

                          </>) :
                            userData?.publisherDetail?.status === "Suspended" ? (<>
                              <option value="Suspended" selected>Suspended</option>
                              <option value="Approved">Approved</option>
                              <option value="Archived">Archived</option>

                            </>) :
                              userData?.publisherDetail?.status === "Archived" ? (<>
                                <option value="Archived" selected>Archived</option>

                              </>) :
                                null}
                    </select>
                  )}
                </div>
              </Grid>
            </Grid>
            <div className="ivitediv">
              {editInfo ? (
                <button
                  className="inviteButton"
                  onClick={handleClose}
                  color="primary"
                  id="Cancel"
                >
                  Cancel
                </button>
              ) : null}
              {editInfo ? (
                <button
                  className="inviteButton"
                  color="primary"
                  id="Save" onClick={EditImageData}
                >
                  Save
                </button>
              ) : null}</div>
          </div>

        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="ivitediv">
            <button className="inviteButton" onClick={inviteNew}>
              + Invite New User
            </button>
          </div>
          {userData?.usersListDetail.length > 0 ?
            <VMCTable data={userData?.usersListDetail} col={tableUsercols}


              tableName="Licensee User Account Detail" />
            :
            //<EmptyTable data="No Users Found" col={tableUsercols} tableName="Licensee User Account Detail" />
            <div>
              <LoadingIndicator />
              {empty ? <EmptyTable data="No Users Found" col={tableUsercols} tableName="Licensee User Account Detail" /> : null}
            </div>

          }
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TableContainer>
            <Table className="accouttablestyle" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableHead">Name</TableCell>
                  <TableCell className="tableHead">Description</TableCell>
                  <TableCell className="tableHead">Service</TableCell>
                  <TableCell className="tableHead">Proxy Domain</TableCell>
                  <TableCell className="tableHead">Zero Rated ?</TableCell>
                  <TableCell className="tableHead">Status</TableCell>
                  <TableCell className="tableHead"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {licenseeService?.length > 0 ? licenseeService.map((row) => (
                  <TableRow key={row?.licServId}>
                    <TableCell component="th" scope="row">
                      {row?.name}
                    </TableCell>
                    <TableCell>{row?.description}</TableCell>
                    <TableCell>{row?.service?.service}</TableCell>
                    <TableCell>{row?.proxyDomain}</TableCell>
                    <TableCell>{row?.zeroRated === 1 ? "Yes" : "No"}</TableCell>
                    <TableCell>{row?.status}</TableCell>
                    <TableCell><a onClick={() => ServiceDetail(row?.licServId)} style={{ cursor: 'pointer' }}>View Details</a></TableCell>
                  </TableRow>
                )) :

                  <>
                    {empty ? <TableRow>No Services Found</TableRow> : null}
                  </>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TableContainer>
            <Table className="accouttablestyle" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableHead">#datafree Gateway Domain</TableCell>
                  <TableCell className="tableHead">Description</TableCell>
                  <TableCell className="tableHead">Status</TableCell>
                  <TableCell className="tableHead">Use for Products</TableCell>
                  <TableCell className="tableHead">Points to Service</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData?.gatewayDomain?.length > 0 ? userData?.gatewayDomain?.map((row, indexs) => (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {row?.gateway}
                    </TableCell>
                    <TableCell>{row?.description}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>
                      {userData?.products?.[indexs] ? userData?.products?.[indexs].map((rows, idx) => (
                        <p>
                          {rows.productType}
                        </p>
                      )) : ""}
                    </TableCell>
                    <TableCell>
                      {userData?.pointServices?.[indexs] ? userData?.pointServices?.[indexs].map((r, idxs) => (
                        <p>
                          {r.licServeId?.name}
                        </p>
                      )) : ""}

                    </TableCell>
                  </TableRow>
                )) :

                  (
                    <>
                      {empty ? <TableRow>No Gateway Domains Found</TableRow> : null}
                    </>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>

      </div>
      <Dialog
        open={open}
        classes={{ scrollPaper: classes.scrollPaper }}
        scroll={scroll}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >

        <DialogTitle id="form-dialog-title" className="dialogtitle">
          <div className="FlexRow">
            <div className="cmpdialogtitle">  Invite User - Licensee View </div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} />
            </div>
          </div>

        </DialogTitle>
        <DialogContent>

          <div className="dialoginputtext">Name:</div>
          <input
            type="text"
            id="name"
            label="name"
            className={!inviteError.name ? "form-control" : "form-control errorField"}
            onChange={(e) => handleUser(e)}
            fullWidth
          />
          {inviteError.name ? <a className="errorClass">{inviteError.nameErMsg ? inviteError.nameErMsg : "This field is required"}</a> : null}
          <div className="dialoginputtext">Email:</div>
          <input
            type="text"
            id="email_address"
            label="email_address"
            onChange={(e) => handleUser(e)}
            className={!inviteError.email_address ? "form-control" : "form-control errorField"}
            fullWidth
          />
          {inviteError.email_address ? <a className="errorClass">{inviteError.emailErrMsg ? inviteError.emailErrMsg : "This field is required"}</a> : null}
          <div className="dialoginputtext">Role:</div>
          <input
            type="text"
            id="role"
            label="role"
            disabled="disabled"
            style={{ cursor: 'not-allowed'}}
            className="form-control"
            value="licensee"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          {lodervisible ? <div className="loaderContainer">
            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="ivitediv">
            <button
              className="inviteButton"
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </button>
            <button
              className="inviteButton"
              onClick={inviteUser}
              disabled={clickInvite ? "disabled" : false}
              color="primary"
            >
              Invite User
            </button>
          </div>
        </DialogActions>
      </Dialog>
      {/* //***************adminLIcensee Users*********** */}
      <Dialog
        open={openUser}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="dialogtitle">
          User Details - Admin View
        </DialogTitle>
        <DialogContent>
          <div className="dialoginputtext">Company Name:</div>
          <input
            type="text"
            id="name"
            label="name"
            disabled="disabled"
            value="Datafree Technologies"
            className="form-control"
            fullWidth
          />
          <div className="dialoginputtext">Company Type:</div>
          <input
            type="text"
            id="email"
            label="email"
            disabled="disabled"
            className="form-control"
            value="Licensee"
            fullWidth
          />
          <div className="dialoginputtext">User’s Name:</div>
          <input
            type="text"
            id="email"
            label="email"
            className="form-control"
            value="Joysl"
            fullWidth
          />
          <div className="dialoginputtext">Email Address:</div>
          <input
            type="text"
            id="email"
            label="email"
            disabled="disabled"
            className="form-control"
            value="renuka+joy@xminds.com"
            fullWidth
          />
          <div className="dialoginputtext">Role:</div>
          <select
            type="text"
            id="email"
            label="email"
            className="form-control form-select"
            value="renuka+joy@xminds.com"
            fullWidth
          >
            <option>licensee</option>
          </select>
          <div className="dialoginputtext">Type:</div>
          <select
            type="text"
            id="email"
            label="email"
            className="form-control form-select"
            value="renuka+joy@xminds.com"
            fullWidth
          >
            <option>INTERNAL</option>
            <option>PUBLISHER</option>
          </select>
          <div className="dialoginputtext">Verified:</div>
          <select
            type="text"
            id="email"
            label="email"
            className="form-control form-select"
            value="renuka+joy@xminds.com"
            fullWidth
          >
            <option>YES</option>
            <option>NO</option>
          </select>
          <div className="dialoginputtext">Status:</div>
          <select
            type="text"
            id="email"
            label="email"
            className="form-control form-select"
            value="renuka+joy@xminds.com"
            fullWidth
          >
            <option>Active</option>
            <option>Deleted</option>
          </select>
          <div className="dialoginputtext">Created:</div>
          <input
            type="text"
            id="email"
            label="email"
            disabled="disabled"
            className="form-control"
            value="2021-06-09 07:23 GMT"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <div className="buttonRow">
            <div>
              <button
                className="inviteButton"
                onClick={handleClose}
                color="primary"
              >
                Request Password Reset
              </button>
            </div>
            <div>
              <button
                className="inviteButton"
                onClick={handleClose}
                color="primary"
              >
                Cancel
              </button>

              <button
                className="inviteButton"
                onClick={handleClose}
                color="primary"
              >
                Update User Details
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>



      {/* ////////////////// Remove Company Logo ///////////////// */}
      <Dialog open={removeDialogue} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" name="popup" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle  border">
          <div className="FlexRow border" >
            <div className="cmpdialogtitle"> </div>
            <div className="cmpdialogtitle" name="popup">
              <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={DialogClose} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="ContentHead">
            <div className="warningIcon"><MdWarning /></div>
            <div className="warningText">WARNING !</div>

          </div>
          <div className="logoConfirmtext">Are you sure, do you want to delete Company Logo?</div>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv">
            <button className="continueBtn" name="popup" onClick={DialogClose}
              style={{ marginRight: '10px' }}
              color="primary">
              Cancel
            </button>

            <button className="continueBtn" name="popup" onClick={removeLogo} color="primary">
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>

      {/*///////////////////////Popup//////////////////////////////*/}
      <Dialog open={openPopup} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }} aria-labelledby="form-dialog-title">

        <DialogContent className="diaContent">
          <div className="logoConfirmtext">Please Save or Cancel the changes before proceeding</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" name="popup" color="primary">
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>




      <Dialog open={invitedSuccess} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Success Message</div>

        </DialogTitle>
        <DialogContent className="diaContent">
          <div className="logoConfirmtext" style={{ fontSize: '17px' }}>Successfully added new user. Invitation link sent to user's E-mail id.</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" style={{ width: '11%' }} name="popup" color="primary" onClick={() => setInvitedSuccess(false)}>
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <Snackbar open={successopen} autoHideDuration={1500} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
          {toastMsg ? toastMsg : "Updated Successfully!"}
        </Alert>
      </Snackbar>
      <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success">
          {errMsg ? errMsg : "Updated Successfully!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default LicenseeAccountDetailView;
