import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Dialog,
  Box,
  Typography,
  makeStyles,
  withStyles,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Grid,
} from "@material-ui/core";
import DatePicker from "react-datepicker";
import service from "../../../../../api/service";
import services from "../../../../../api/adminService";
import MuiAlert from "@material-ui/lab/Alert";
import { FaLongArrowAltDown, FaTimes, FaDownload } from "react-icons/fa";
import moment from "moment";
import Snackbar from "@material-ui/core/Snackbar";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { IoMdCloseCircleOutline } from "react-icons/io";
import Loader from "react-loading";
import DateFnsUtils from '@date-io/date-fns';
import { TimePicker } from "@material-ui/pickers";


const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});
const AntTabs = Tabs;
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    borderBottom: "1px solid #ddd ",
    minWidth: "80px",

    textShadow: "none",
    color: "#0059b6",

    "&$selected": {
      color: "#555",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderBottomColor: "transparent",
      borderBottom: "none",
      cursor: "default",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function createData(
  UsersName,
  Email,
  Address,
  Role,
  Type,
  Verified,
  Status,
  Created
) {
  return { UsersName, Email, Address, Role, Type, Verified, Status, Created };
}

function MaxProduction(props) {
  const classes = useStyles();
  let API_URL = process.env.REACT_APP_API_URL;
  const [deleteopen, setDeleteOpen] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const [checked, setChecked] = useState(0);
  const [applicationData, setApplicationData] = useState(false);
  const [configDetail, setCOnfigDetail] = useState();
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [configCreateopen, setConfigCreateopen] = useState(false);
  const [effectiveChecked, setEffectiveChecked] = useState(false);
  const [vidAdChecked, setVideoAdChecked] = useState(false);
  const [appConfig, setAppConfig] = useState(false);
  const [colorchecked, setColorchecked] = useState(false);
  const [accentcolorchecked, setAccentColorchecked] = useState(false);
  const [actioncolorchecked, setActionColorchecked] = useState(false);
  const [configStatus, setConfigStatus] = useState("");
  const [configAction, setConfigAction] = useState("");
  const [configAccent, setConfigAccent] = useState("");
  const [scroll, setScroll] = useState("paper");
  const [maxEntryUrl, setMaxEntryUrl] = useState(true);
  const [InEdit, setInEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [devChecked, setDevChecked] = useState(false);
  const [includeEmChecked, setIncludeEmChecked] = useState(false);
  const [locPermission, setLocPermission] = useState(false);
  const handleClose = (Id, depId) => {
    setChangeMsg(false)
    setDeleteOpen(false);
    setValidate(false);
    setValidateDiv("");
    setInEdit(false)
    setDeleteOpen(false);
    applicationData?.configProd?.map((item, index) => {
      if (item?.configId === Id) {

        item['editInfo'] = false
      }

    })
    getConfigDetails(Id, depId);
  };

  const entryPointChangeHandler = (e) => {
    const newdata = { ...entrypointSelf };
    newdata[e.target.id] = e.target.value;

    if ((e.target.id === "start_url")) {
      if (e.target.value) {
        if (webReg.test(e.target.value) === false) {

          setError({
            ...error,
            entry_point_url: true,
            webmsg: "Please enter a valid URL."
          });
        }

        else {

          if (apkValidMsg.test(e.target.value) === false) {
            setError({
              ...error,
              entry_point_url: true,
              webmsg: "Please enter a valid URL."
            });

            setWarningMsg(true)
          } else {
            setError({
              ...error,
              entry_point_url: false,
              webmsg: ""
            });
          }
        }
      }
      else {
        setError({
          ...error,
          entry_point_url: true,
          webmsg: "This field is required"
        });
      }
    }
    setEntryPointSelf(newdata);
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [apkEdit, setApkEdit] = useState({
    name: "",
    description: "",
    platform: "",
    package_suffix: "",
    distribution_email: "",
    location_permission: "",
    configId: "",
    deploymentType: "PROD",
    currentPlatform: "",
    productId: "2",
    include_developer_menu: ""
  })
  const handleFeel = (e) => {
    const newdata = { ...lookFeel };
    newdata[e.target.id] = e.target.value;

    if (e.target.id === "onboarding_url") {
      if (e.target.value) {
        if (webReg.test(e.target.value) === false) {
          setLookError({
            ...lookError,
            onboarding_url: true,
            urlMsg: "Please enter a valid URL."
          })
        } else {
          setLookError({
            ...lookError,
            onboarding_url: false,
            urlMsg: ""
          })
        }
      }
      else {
        setLookError({
          ...lookError,
          onboarding_url: false,
          urlMsg: ""
        })
      }
    }



    if (e.target.id === "status_manual") {
      if (e.target.value) {

        if (colorReg.test(e.target.value) === false) {
          setLookError({
            ...lookError,
            status_manual: true,
            statusMsg: "Please enter a valid color"
          });
        }
        else {
          setLookError({
            ...lookError,
            status_manual: false,
            statusMsg: ""
          });
        }
      }
      else {
        if (colorchecked) {
          setLookError({
            ...lookError,
            status_manual: true,
            statusMsg: "This field is required"
          });
        }
        else {
          setLookError({
            ...lookError,
            status_manual: false,
            statusMsg: ""
          });
        }
      }

    }


    if (e.target.id === "header_manual") {
      if (e.target.value) {

        if (colorReg.test(e.target.value) === false) {
          setLookError({
            ...lookError,
            header_manual: true,
            headerMsg: "Please enter a valid color"
          });
        }
        else {
          setLookError({
            ...lookError,
            header_manual: false,
            headerMsg: ""
          });
        }
      }
      else {
        if (actioncolorchecked) {
          setLookError({
            ...lookError,
            header_manual: true,
            headerMsg: "This field is required"
          });
        }
        else {
          setLookError({
            ...lookError,
            header_manual: false,
            headerMsg: ""
          });
        }
      }

    }


    if (e.target.id === "accent_manual") {
      if (e.target.value) {

        if (colorReg.test(e.target.value) === false) {
          setLookError({
            ...lookError,
            accent_manual: true,
            accentMsg: "Please enter a valid color"
          });
        }
        else {
          setLookError({
            ...lookError,
            accent_manual: false,
            accentMsg: ""
          });
        }
      }
      else {
        if (accentcolorchecked) {
          setLookError({
            ...lookError,
            accent_manual: true,
            accentMsg: "This field is required"
          });
        }
        else {
          setLookError({
            ...lookError,
            accent_manual: false,
            accentMsg: ""
          });
        }
      }

    }

    setLookFeel(newdata);
  }
  const edit = (Id, depId) => {
    applicationData?.configProd?.map((item, index) => {
      if (item?.configId === Id) {

        item['editInfo'] = true
      }
      applicationData?.editConfigDetailsAll?.map((subitem, ind) => {
        if (subitem?.id === Id && subitem?.deploymentType === "PROD") {
          item['xmlDocFile'] = subitem?.xmlDocFile;
          item['xmlFile'] = subitem?.xmlFile;
          item['stylesheetFile'] = subitem?.stylesheetFile;
          xmlConfig['appNodeXml'] = subitem?.xmlFile;
          xmlConfig['docXml'] = subitem?.xmlDocFile;
          xmlConfig['stylesheetFile'] = subitem?.stylesheetFile;
        }
      })
    })

    getConfigDetails(Id, depId);


  };
  const handleEvent = (e) => {
    const newdata = { ...eventNew }
    newdata[e.target.id] = e.target.value;

    if (e.target.id === "description") {
      if (e.target.value) {
        setEventError({
          ...eventError,
          description: false
        });
      }
    }
    setEventNew(newdata);
  }
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
    setConfigCreateopen(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const [prodLength, setProdLength] = useState(false)
  const getApplicationDetails = () => {
    setLoading(true);

    service.applicationData(props.applicationId).then((res) => {

      setApplicationData(res.data.applicationObj);
      setLoading(false);
      setCOnfigDetail(res.data.applicationObj.existingConfigDetails);
      setSecurityDta({
        monthly_data: res.data.applicationObj?.configProd[0]?.deploymentId?.applicationId.appDataUsageAlert,
        alert_email: res.data.applicationObj?.configProd[0]?.deploymentId?.applicationId.alertEmail,
        include_daily_dataFree_limit: "",
        max_daily_spend: res.data.applicationObj?.configProd[0]?.deploymentId?.applicationId.maxBytesPerDay,
        action_required: res.data.applicationObj?.configProd[0]?.deploymentId?.applicationId.maxBytesPerDayAction,
        production: res?.data?.applicationObj?.configProd[0]?.deploymentId?.whitelist ? res?.data?.applicationObj?.configProd[0]?.deploymentId?.whitelist : "",
        max_bytes_content: res?.data?.applicationObj?.configProd[0]?.deploymentId?.securityRules?.contentSize ? res.data?.applicationObj?.configProd[0]?.deploymentId?.securityRules?.contentSize : "",
        max_bytes_per_content_hidden: res?.data?.applicationObj?.configProd[0]?.deploymentId?.securityRules?.contentSize ? res.data?.applicationObj?.configProd[0]?.deploymentId?.securityRules?.contentSize : "",
        max_bytes_per_content_changed: "no",
        application_id: props.applicationId
      })

      if (res.data.applicationObj?.prod?.applicationId?.maxBytesPerDayAction === 1) {
        setChecked(true)
      } else {
        setChecked(false)

      }
      if (res.data.applicationObj?.configProd?.length === 0) {
        setProdLength(true)
      } else {
        setProdLength(false)
      }


    }).catch((err) => {

    })

  };
  const [platformData, setPlatformData] = useState();
  const getPlatform = () => {
    service.popupPlatform({ appDeploymentType: "PROD" }).then((res) => {

      setPlatformData(res.data?.VersionClientAddPopUps)
    }).catch((err) => {

    })

  };

  const [hasApp, setHasApp] = useState(false);
  const getGenerateApp = () => {

    service.generateApp({
      "applicationId": props?.applicationId,
      "type": props?.deployType
    }).then((res) => {

      if (res?.data?.hasAppId === 'Yes') {
        setHasApp(true); setLoading(false);
        getApplicationDetails();
      }
      else {
        setHasApp(false);
      }
    }).catch((err) => {

    })

  };
  const [warningMsg, setWarningMsg] = useState(false);

  const validateEvent = (eventNew) => {
    let rCount = 0;
    let nwerror = { ...eventError }

    if (eventNew.description === "") {
      nwerror.description = true;
      rCount++;
    }
    else {
      nwerror.description = false;
    }

    setEventError(nwerror);
    return rCount;
  }

  const [addEvent, setAddEvent] = useState();
  const [eventNew, setEventNew] = useState({
    start_date: moment(new Date()).format("DD/MM/YYYY"),
    end_date: moment(new Date()).format("DD/MM/YYYY"),
    start_time: moment(new Date()).format("hh:mm A"),
    //start_time : convertTime(new Date()),
    type: "Build",
    description: "",
    application_id: props.applicationId,

  });
  const [clear, setClear] = useState(false);
  const contentReg = /^[0-9]*$/;
  const emailReg = /^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/i;
  const mimeReg = /^[a-z]+\/(\*|[a-z]+[-+.a-z0-9]*)$/m;
  const monthlyReg = /(?!0+$)(^[0-9]+$)|(^[0-9]+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\.[0-9]{1,2})+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\,[0-9]{2,4})+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K))$/i


  const [yesData, setYesData] = useState({
    dev_configs: "",
    name: "",
    deploymentType: "DEV",
    description: ""
  })
  const [yesError, setYesError] = useState({
    dev_configs: false,
    name: false,
    description: false
  });
  const validateYesSave = (yesData) => {

    let count = 0;
    const nwerror = { ...yesError };

    if (yesData.dev_configs === "") {
      nwerror.dev_configs = true;
      count++;
    } else {
      nwerror.dev_configs = false;
    }

    if (yesData.name === "") {
      nwerror.name = true;
      count++;
    } else {
      nwerror.name = false;
    }

    if (yesData.description === "") {
      nwerror.description = true;
      count++;
    } else {
      nwerror.description = false;
    }

    setYesError(nwerror);
    return count;
  }
  const addYes = (e) => {
    const newdata = { ...yesData };
    newdata[e.target.id] = e.target.value;


    if (e.target.id === "dev_configs") {
      if (e.target.value) {
        setYesError({
          ...yesError,
          dev_configs: false
        })
      }
      else {
        setYesError({
          ...yesError,
          dev_configs: true
        })
      }
    }

    if (e.target.id === "name") {
      if (e.target.value) {
        setYesError({
          ...yesError,
          name: false
        })
      }
      else {
        setYesError({
          ...yesError,
          name: true
        })
      }
    }

    if (e.target.id === "description") {
      if (e.target.value) {
        setYesError({
          ...yesError,
          description: false
        })
      }
      else {
        setYesError({
          ...yesError,
          description: true
        })
      }
    }

    setYesData(newdata);
  }
  const handleStart = (date) => {


    setStartDate(date ? date : new Date());

    const newIPdata = { ...eventNew };
    newIPdata['start_date'] = date !== null ? moment(date).format("DD/MM/YYYY") : "";
    setEventNew(newIPdata);
  }

  const handleEnd = (date) => {


    setEndDate(date ? date : null);
    const newIPdata = { ...eventNew };
    newIPdata['end_date'] = date !== null ? moment(date).format("DD/MM/YYYY") : "";
    setEventNew(newIPdata);
  }

  const convertTime = (time) => {
    let hours = time.getHours();
    let minutes = time.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let times = hours + ':' + minutes + ' ' + ampm;
    return times;
  }
  const handleTime = (time) => {

    setstartTime(time ? time : null)

    let timesnw = convertTime(time);


    const newIPdata = { ...eventNew };
    newIPdata['start_time'] = timesnw !== null ? timesnw : "";
    setEventNew(newIPdata);
  }

  //create clone config
  const createCloneConfig = () => {
    const yCount = validateYesSave(yesData)

    if (yCount === 0) {
      service.yesClick(yesData).then((res) => {

        closeModalCloneConfig();
        getApplicationDetails();
      }).catch((err) => {

      })
    }

  };
  const [startDate, setStartDate] = useState(new Date());
  const [include2, setinclude2] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setstartTime] = useState(new Date());
  const [include1, setinclude1] = useState(false);
  function closeModalCloneConfig() {

    setConfigCreateopen(false)
    setYesData({
      dev_configs: "",
      name: "",
      deploymentType: "PROD",
      description: ""
    });
    setYesError({
      dev_configs: false,
      name: false,
      description: false
    })
  }

  function timeConvert(utctime) {
    const dateString = utctime;
    const userOffset = new Date().getTimezoneOffset() * 60 * 1000;
    const localDate = new Date(dateString);
    const utcDate = new Date(localDate.getTime() + userOffset);
    return utcDate.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric'
    });
  }
  const [AppAttributes, setAppAttributes] = useState();
  const [eventError, setEventError] = useState({
    description: false,
  });
  const [entryXml, setEntryXml] = useState(false);
  const continueBucket = () => {

    let configid = document.getElementById("bucketid").value;
    let statexml = document.getElementById("xmlStat").value;

    setHasNoBuild(false);
    service.bucketWrap({ configId: configid, xmlStatus: statexml }).then((res) => {
      closeEntry(configid);
      getApplicationDetails();
      setEntryXml(true);
      setHasBuild(false);
      setHasNoBuild(false);
    }).catch((err) => {

    })
  }
  const [undoEdits, setUndoEdits] = useState(false);
  const closeEntry = (conId) => {
    applicationData?.generateConfigObjects?.DEV.map((item, index) => {
      if (item?.id === conId) {
        item['editEty'] = false
      }
    })
    //getConfigDetails(conId,deployId);
    getApplicationDetails();
  }
  const [MoreData, setMoreData] = useState();
  const GetInfo = (id) => {

    try {

      service.viewMore({ buildId: id }).then((res) => {

        setMoreData(res.data.moreInfo);
        const val = res.data.moreInfo?.appAttributes.replace(/["{}]/g, "");

        setAppAttributes(val.split(','));

        var x = document.getElementById("Moreinfo_" + id);
        let y = document.getElementById("debugDownload" + id);
        if (x.style.display === "none") {
          x.style.display = "block";
        } else {
          x.style.display = "none";
        }
        if (y.style.display === "block") {
          y.style.display = "none";
        } else {
          y.style.display = "none";
        }
      })

    } catch (err) {

    }
  }
  const appConfigOpenClick = (scrollType) => {
    setAppConfig(true);
    setConfigCreateopen(false);
    setOpen(false);
    setScroll(scrollType);
  };

  const createConfigCheck = (scrollType) => {
    setLoading(true);

    service.createConfigCheck({ "applicationId": props.applicationId }).then((res) => {
      setLoading(false);
      if (res?.data === 'exist') {
        setOpen(true);
        setScroll(scrollType);
      }
      else {
        setAppConfig(true);
        setScroll(scrollType);
      }


    }).catch((err) => {

    })

  };
  const [developerMenu, setDeveloperMenu] = useState(false);
  const [lookFeel, setLookFeel] = useState({
    onboarding_url: "",
    use_dark_action_bar_icons: "",
    status_bar_color: "",
    status_manual: "",
    header_manual: "",
    accent_manual: "",
    header_color: "",
    accent_color: "",
    deploymentType: "DEV",
    configId: ""
  })
  //getConfig Details
  const getConfigDetails = (configId, depId) => {
    service.configDetails({ "config_id": configId, "deployment_id": depId }).then((res) => {
      setApkEdit({
        ...apkEdit,
        name: res?.data?.config?.name,
        description: res?.data?.config?.description,
        platform: res?.data?.config?.binuClientVersionId?.binuClientVersionId,
        package_suffix: res?.data?.config?.package_suffix,
        distribution_email: res?.data?.config?.distributionEmail,
        location_permission: res?.data?.config?.location_permission === true ? "" : null,
        currentPlatform: res?.data?.config?.binuClientVersionId?.binuClientVersionId,
        include_developer_menu: res?.data?.config?.include_dev_menu === true ? "" : null,

      })
      setLookFeel({
        ...lookFeel,
        onboarding_url: res?.data?.config?.onBoardingUrl,
        status_bar_color: res?.data?.config?.status_bar_color,
        header_color: res?.data?.config?.action_bar_color,
        accent_color: res?.data?.config?.accent_color,
        use_dark_action_bar_icons: res?.data?.config?.useDarkActionBarIcons ? 1 : 0


      })
      setSecurityDta({
        ...securityDta,
        max_bytes_content: res?.data?.appDeployment?.securityRules?.contentSize,
        systest: res?.data?.appDeployment?.whitelist,
        use_test_whitelist: res?.data?.appDeployment?.useWhiteList,
        alert_email: res?.data?.appDeployment?.applicationId?.alertEmail,
        include_daily_dataFree_limit: res?.data?.appDeployment?.applicationId?.appDataUsageAlert,
        max_daily_spend: res?.data?.appDeployment?.applicationId?.maxBytesPerDay,
        action_required: res?.data?.appDeployment?.deploymentId?.applicationId?.maxBytesPerDayAction,
        max_bytes_content: res?.data?.appDeployment?.deploymentId?.applicationId?.securityRules?.contentSize,
        max_bytes_per_content_hidden: res?.data?.appDeployment?.deploymentId?.applicationId?.securityRules?.contentSize,
        max_bytes_per_content_changed: 'no',
        production: res?.data?.appDeployment?.whitelist,
        monthly_data: res?.data?.appDeployment?.applicationId?.appDataUsageAlert
      })
      if (res.data.appDeployment?.prod?.applicationId?.maxBytesPerDayAction === 1) {
        setChecked(true)
      } else {
        setChecked(false)

      }

    }).catch((err) => {

    })

  };
  const [eventDisable, setEventDisable] = useState();
  const addNewEvent = () => {
    setAddEvent(true);
    setEventDisable(true);
  }

  const handleCloseEntry = (conId, deployId) => {
    setValidateLoader(true);
    let xy = document.getElementById("editConfig")
    xy.style.opacity = '0.2'
    service.getConfig({ configId: conId }).then((res) => {
      setValidateLoader(false);
      closeEntry(conId, deployId);
      setUndoEdits(true);
      xy.style.opacity = '1'


    }).catch((err) => {

    })

  };
  const editLook = (conId, deployId, index) => {

    applicationData?.configProd?.map((item, index) => {
      if (item?.configId === conId) {
        item['editInfo'] = true;
      }
    })
    getConfigDetails(conId, deployId);
  };

  const [validError, setValidError] = useState({
    name: false,
    description: false,
    platform: false,
    package_suffix: false,
    distribution_email: false,
    location_permission: false,
    configId: false,
    currentPlatform: false,
    include_developer_menu: false,
    suffixMsg: "",
    emailMsg: "",

  })

  const [ApkError, setApkError] = useState({
    buildTypes: false,
    version_code: false,
    version_name: false,
    comments: false,
    email_distribution: false,
    package_suffix: false,
    versionMsg: "",
    emailMsg: "",
  });
  const ValidateField = (editApk) => {
    let apkcounts = 0;
    const apkerror = { ...validError };

    if (editApk.distribution_email === "") {
      apkerror.distribution_email = true;
      apkerror.emailMsg = "This field is required";
      apkcounts++;
    }
    else {
      if (distemail.test(editApk.distribution_email) === false) {
        apkerror.distribution_email = true;
        apkerror.emailMsg = "You must enter a valid email, or comma separated emails";
        apkcounts++;
      }
      else {
        apkerror.distribution_email = false;
        apkerror.emailMsg = "";
      }
    }


    if (editApk.name === "") {
      apkerror.name = true;
      apkcounts++;
    }
    else {
      apkerror.name = false;
    }

    if (editApk.description === "") {
      apkerror.description = true;
      apkcounts++;
    }
    else {
      apkerror.description = false;
    }
    if (editApk.package_suffix === "") {
      apkerror.package_suffix = false;
      apkerror.suffixMsg = "";
    } else {
      if (packageReg.test(editApk.package_suffix) === false) {
        apkerror.package_suffix = true;
        apkerror.suffixMsg = "Please enter a valid URL.";
        apkcounts++;
      }
      else if ((editApk.package_suffix)?.length > 10) {
        apkerror.package_suffix = true;
        apkerror.suffixMsg = "Please enter a valid URL.";
        apkcounts++;
      }
      else {
        apkerror.package_suffix = false;
        apkerror.suffixMsg = "";
      }
    }

    setValidError(apkerror);
    return apkcounts;
  }
  const saveEdit = (Id, depId) => {
    apkEdit['configId'] = Id;
    const editCount = ValidateField(apkEdit);
    if (editCount === 0) {
      let vals = "";
      if (include1) {
        vals = "EMTRACKER_SDK";
        if (include2) {
          vals = vals + ",VIDEO_SDK";
        }
        apkEdit.include_sdks = vals;
      }
      else {
        if (include2) {
          vals = "VIDEO_SDK";
        }
        apkEdit.include_sdks = vals;
      }
      service.apkEdit(apkEdit).then((res) => {
        applicationData?.configProd?.map((item, index) => {
          if (item?.configId === Id) {

            item['editInfo'] = false
          }

        })

        getApplicationDetails();

      }).catch((err) => {

      })
    }
  }
  const ValidateApk = (editApk) => {
    let apkcounts = 0;
    const apkerror = { ...ApkError };

    if (editApk.version_code === "") {
      apkerror.version_code = true;
      apkerror.versionMsg = "This field is required";
      apkcounts++;
    }
    else {
      if (versionReg.test(editApk.version_code) === false) {
        apkerror.version_code = true;
        apkerror.versionMsg = "Please enter only digits.";
        apkcounts++;
      }
      else {
        apkerror.version_code = false;
        apkerror.versionMsg = "";
      }
    }


    if (editApk.email_distribution === "") {
      apkerror.email_distribution = true;
      apkerror.emailMsg = "This field is required";
      apkcounts++;
    }
    else {
      if (distemail.test(editApk.email_distribution) === false) {
        apkerror.email_distribution = true;
        apkerror.emailMsg = "You must enter a valid email, or comma separated emails";
        apkcounts++;
      }
      else {
        apkerror.email_distribution = false;
        apkerror.emailMsg = "";
      }
    }


    if (editApk.version_name === "") {
      apkerror.version_name = true;
      apkcounts++;
    }
    else {
      apkerror.version_name = false;
    }

    if (editApk.comments === "") {
      apkerror.comments = true;
      apkcounts++;
    }
    else {
      apkerror.comments = false;
    }

    if (BuildApkData.userType === "INTERNAL") {
      if (editApk.buildTypes === "") {
        apkerror.buildTypes = true;
        apkcounts++;
      }
      else {
        apkerror.buildTypes = false;
      }
    }
    else {
      apkerror.buildTypes = false;
    }
    setApkError(apkerror);
    return apkcounts;
  }
  const handleCloseLook = (conId, deployId) => {
    setActionColorchecked(false);
    setColorchecked(false);
    setAccentColorchecked(false);

    applicationData?.generateConfigObjects?.DEV.map((item, index) => {
      if (item?.configId === conId) {
        item['editInfo'] = false
      }
    })
    //getConfigDetails(conId,deployId);
    getApplicationDetails()
  };
  const [BuildPopup, setBuildPopup] = useState();
  const handleApkEdit = (e) => {
    //
    e.preventDefault();
    const newdata = { ...editApk };
    newdata[e.target.id] = e.target.value;

    if (e.target.id === "version_code") {
      if (e.target.value) {
        if (versionReg.test(e.target.value) === false) {
          setApkError({
            ...ApkError,
            version_code: true,
            versionMsg: "Please enter only digits.",
          })
        }
        else {
          setApkError({
            ...ApkError,
            version_code: false,
            versionMsg: "",
          })
        }
      }
      else {
        setApkError({
          ...ApkError,
          version_code: true,
          versionMsg: "This field is required",
        })
      }
    }

    if (e.target.id === "version_name") {
      if (e.target.value) {
        setApkError({
          ...ApkError,
          version_name: false,
        })
      }
      else {
        setApkError({
          ...ApkError,
          version_name: true,
        })
      }
    }

    if (e.target.id === "comments") {
      if (e.target.value) {
        setApkError({
          ...ApkError,
          comments: false,
        })
      }
      else {
        setApkError({
          ...ApkError,
          comments: true,
        })
      }
    }

    if (e.target.id === "email_distribution") {
      if (e.target.value) {
        if (distemail.test(e.target.value) === false) {
          setApkError({
            ...ApkError,
            email_distribution: true,
            emailMsg: "You must enter a valid email, or comma separated emails",
          })
        }
        else {
          setApkError({
            ...ApkError,
            email_distribution: false,
            emailMsg: "",
          })
        }
      }
      else {
        setApkError({
          ...ApkError,
          email_distribution: true,
          emailMsg: "This field is required",
        })
      }
    }

    if (e.target.id === "buildTypes") {
      if (e.target.value) {
        setApkError({
          ...ApkError,
          buildTypes: false,
        })
      }
      else {
        setApkError({
          ...ApkError,
          buildTypes: true,
        })
      }
    }
    setEditApk(newdata);
  }
  function openModalBulidApk() {
    setmdbuildapk(true);
    setScroll('body');
  }
  const BuildSave = () => {
    const apkCount = ValidateApk(editApk);
    if (apkCount === 0) {
      setLoading(true);
      service.createApk(editApk).then((res) => {
        if (res.data.status === "success") {
          setmdbuildapk(false);
          var x = document.getElementById("buildmsgoff");
          x.style.display = "block";
          setLoading(false);
        }
      }).catch((err) => {

      })


    }
  }
  const popUpClose = () => {
    setAppConfig(false);
    setNoError({
      name: false,
      description: false,
      platform: false,
      package_suffix: false,
      distribution_email: false,
      onboarding_url: false,
      website_url: false,
      entry_point_hosting: false,
      start_url: false,
      emailMsg: "",
      webMsg: "",
      urlMsg: "",
      packageMsg: "",
      status_manual: false,
      header_manual: false,
      accent_manual: false,
      statusMsg: "",
      headerMsg: "",
      accentMsg: ""
    });
  }
  const closeEvent = () => {
    setAddEvent(false);
    setEventDisable(false);
  }
  function closeModalBuildApk() {
    setmdbuildapk(false);
  }
  const [mdbuildapk, setmdbuildapk] = useState(false);
  const [BuildApkData, setBuildApkData] = useState();
  const [editApk, setEditApk] = useState({
    build_id: "",
    buildTypes: "",
    version_code: "",
    version_name: "",
    comments: "",
    email_distribution: "",
    package_suffix: "",
    application_id: "",
    deployment_type: "",
    deployment_id: "",
    configid: ""
  });
  const getBuildPopup = (id) => {
    try {
      services.GetPopup({ configId: id }).then((res) => {

        setBuildPopup(res.data);


      })
    } catch (err) {

    }
  }
  const [convertSelf, setConvertSelf] = useState(false)
  const [convertSelfBody, setConvertSelfBody] = useState({
    entryPointUrl: "",
    configid: ""
  })
  const [selfErr, setSelfErr] = useState({
    entrypoint: false
  })
  const selfChange = (e) => {
    const selfData = { ...convertSelfBody }
    selfData[e.target.id] = e.target.value;
    applicationData?.configProd.map((item, index) => {
      selfData['configid'] = item?.configId
    })
    if (e.target.id === "entryPointUrl") {
      setSelfErr({
        ...selfErr,
        entrypoint: false,
      });
    }
    setConvertSelfBody(selfData)
  }

  const converAppSelf = () => {
    if (convertSelfBody?.entryPointUrl?.length === 0) {
      setSelfErr({
        ...selfErr,
        entrypoint: true,
      });
    } else {
      service.convertSelf(convertSelfBody).then((res) => {
        setLoading(false)
        setConvertSelf(false)
        getApplicationDetails()
      }).catch((err) => {

      })
    }

  }
  const BuildView = (id) => {

    if (id !== "") {
      getBuildPopup(id);
      service.viewBuildApk({ configId: id }).then((res) => {

        setBuildApkData(res.data);
        setEditApk({
          build_id: "",
          buildTypes: "",
          version_code: res.data?.obj.versionCode ? res.data?.obj.versionCode : "",
          version_name: res.data?.obj.versionName ? res.data?.obj.versionName : "",
          comments: res.data?.obj.comment ? res.data?.obj.comment : "",
          email_distribution: res.data?.obj.distributionEmail ? res.data?.obj.distributionEmail : "",
          package_suffix: res.data?.obj.packageSuffix ? res.data?.obj.packageSuffix : "",
          application_id: res.data?.obj.applicationid ? res.data?.obj.applicationid : "",
          deployment_type: res.data?.obj.deploymentType ? res.data?.obj.deploymentType : "",
          deployment_id: res.data?.obj.deploymentId ? res.data?.obj.deploymentId : "",
          configid: id,
        })
        openModalBulidApk();

      }).catch((err) => {

      })
    }
  }
  const changeSDK = (e) => {
    const newdata = { ...apkEdit };
    newdata[e.target.id] = e.target.value;

    if (e.target.id === "include_developer_menu") {
      setDevChecked(!devChecked)

      if (e?.target?.checked === false) {
        newdata['include_developer_menu'] = null;
      }
      else {
        newdata['include_developer_menu'] = "";
      }

    }
    if (e.target.id === "location_permission") {
      setLocPermission(!locPermission)

      if (e?.target?.checked === false) {
        newdata['location_permission'] = null;
      }
      else {
        newdata['location_permission'] = "";
      }

    }
    if (e.target.id === "EMTRACKER_SDK") {
      setIncludeEmChecked(!includeEmChecked)

      if (e?.target?.checked === false) {
        newdata['include_sdks'] = 'EMTRACKER_SDK';
      }
      else {
        newdata['include_sdks'] = "";
      }
    }
    if (e.target.id === "distribution_email") {
      if (e.target.value) {
        if (distemail.test(e.target.value) === false) {
          setValidError({
            ...validError,
            distribution_email: true,
            emailMsg: "You must enter a valid email, or comma separated emails"
          })
        } else {
          setValidError({
            ...validError,
            distribution_email: false,
            emailMsg: ""
          })
        }
      }
      else {
        setValidError({
          ...validError,
          distribution_email: true,
          emailMsg: "This field is required."
        })
      }
    }
    if (e.target.id === "name") {
      if (e.target.value) {
        setValidError({
          ...validError,
          name: false
        })
      }
      else {
        setValidError({
          ...validError,
          name: true
        })
      }
    }

    if (e.target.id === "description") {
      if (e.target.value) {
        setValidError({
          ...validError,
          description: false
        })
      }
      else {
        setValidError({
          ...validError,
          description: true
        })
      }
    }
    if (e.target.id === "package_suffix") {
      if (e.target.value) {
        if (packageReg.test(e.target.value) === false) {
          setValidError({
            ...validError,
            package_suffix: true,
            suffixMsg: "Only alphanumeric characters or underscore and the first character must be a letter"
          })
        }
        else if ((e.target.value).length > 10) {
          setValidError({
            ...validError,
            package_suffix: true,
            suffixMsg: "Please enter no more than 10 characters."
          })
        }
        else {
          setValidError({
            ...validError,
            package_suffix: false,
            suffixMsg: ""
          })
        }
      }
      else {
        setValidError({
          ...validError,
          package_suffix: false,
          suffixMsg: ""
        })
      }
    }
    setApkEdit(newdata);
  }
  const [lookError, setLookError] = useState({
    onboarding_url: false,
    urlMsg: ""

  })
  const validateLookFeel = (lookndfeel) => {
    let count = 0;
    const nwerror = { ...lookError };

    if (lookndfeel.onboarding_url === "") {
      nwerror.onboarding_url = false;
      nwerror.urlMsg = "";
    } else {
      if (webReg.test(lookndfeel.onboarding_url) === false) {
        nwerror.onboarding_url = true;
        nwerror.urlMsg = "Please enter a valid URL.";
        count++;
      }
      else {
        nwerror.onboarding_url = false;
        nwerror.urlMsg = "";
      }
    }

    setLookError(nwerror);
    return count;
  }

  //save
  const lookFeelEdit = (Id, deployId) => {
    lookFeel['configId'] = Id;
    const wCount = validateLookFeel(lookFeel);
    //alert(wCount);
    if (wCount === 0) {
      if (lookFeel.use_dark_action_bar_icons === "yes") {
        lookFeel.use_dark_action_bar_icons = 1;
      }
      else {
        lookFeel.use_dark_action_bar_icons = 0;
      }
      service.lookEdit(lookFeel).then((res) => {

        handleCloseLook(Id, deployId);
        getApplicationDetails();
      }).catch((err) => {

      })
    }

  }
  const [entrypoint, setEntryPoint] = useState({
    deploymentType: "PROD",

    start_url: "",

    configId: ""
  })
  const [xmlConfig, setXmlConfig] = useState({
    appNodeXml: "",
    docXml: "",
    stylesheetFile: "",
    configId: "",
    hostUrl:""
  })
  const [error, setError] = useState({

    webmsg: "",
    entry_point_url: false,

  });

  const [xmlval, setXml] = useState(false);
  const [validateLoader, setValidateLoader] = useState(false);
  const validateCondition = (Id) => {
    setValidate(true);
    let xy = document.getElementById("editConfig")
    xy.style.opacity = '0.2'
    setValidateLoader(true)
    xmlConfig['configId'] = Id;
    xmlConfig['appNodeXml'] = applicationData?.editConfigDetails.xmlFile;
    xmlConfig['docXml'] = applicationData?.editConfigDetails.xmlDocFile;
    xmlConfig['stylesheetFile'] = applicationData?.editConfigDetails.stylesheetFile;
    xmlConfig['hostUrl'] = API_URL
    service.validateXml(xmlConfig).then((res) => {
      setValidateLoader(false)
      xy.style.opacity = '1';
      if (res.data.status === 'success') {

        setValidateDiv("Validation of XML successful");
        setXml(true)
      }
      else if (res.data.status === 'error') {
        setValidateDiv(res.data?.details);
        setXml(false)

      }

    }).catch((err) => {

    })
  }
  const [hasBuild, setHasBuild] = useState(false);
  const [validateDiv, setValidateDiv] = useState("");
  const [hasNoBuild, setHasNoBuild] = useState(false);
  const [editValMsg, setEditValMsg] = useState(false);
  const [validate, setValidate] = useState(false);
  const [changeMsg, setChangeMsg] = useState(false);
  const entryPointSave = (Id, depId, xmlVal) => {

    entrypoint['configId'] = Id;
    document.getElementById("xmlStat").value = xmlVal ? 1 : 0;
    if (changeMsg === false) {

      applicationData?.configProd?.map((item, index) => {
        if (item?.configId === Id) {

          item['editInfo'] = false

        }

      })
      getConfigDetails(Id, depId);
    }
    else if (changeMsg === true && validate === false) {

      setEditValMsg(true);

    } else if (validate === true) {
      service.buildWrap({ configId: Id }).then((res) => {

        if (res.data.message === "noBuilds") {
          setHasNoBuild(true);
          setValidateDiv("");
          document.getElementById("bucketid").value = Id;
        }
        else if (res.data.message === "hasBuilds") {
          setHasBuild(true);
          setValidateDiv("");
        }
      }).catch((err) => {

      })
    }



  }
  const [openBuild, setopenBuild] = useState(false);
  const openBuildClose = () => {
    setopenBuild(false);

  };
  const [editAppOpen, setEditAppOpen] = useState(false);
  const handleAppClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setEditAppOpen(false);
    }

  };
  const [configIdData, setconfigIdData] = useState();
  const viewHistory = (id) => {
    setConfigCheck(false);
    if (id !== "") {

      setconfigIdData(id);

      service.viewBuildHistory({ configId: id }).then((res) => {

        setBuildDetails(res.data?.buildTableDetails);
        setopenBuild(true);

        setScroll('body');
      }).catch((err) => {

      })


    }
  }
  const [BuildDetails, setBuildDetails] = useState();
  const [configCheck, setConfigCheck] = useState(false);
  const showBuilds = (e) => {

    setConfigCheck(!configCheck);

    if (e.target.checked === true) {

      service.checkConfig({ deploymentId: applicationData?.appDeploymentDevObj?.deploymentId }).then((res) => {

        setBuildDetails(res.data?.BuildDetails);
      }).catch((err) => { })

    }
    else {

      viewHistory(configIdData);
    }
  }
  const launchStatus = (Id, xmlFile, xmlDocFile, stylesheetFile, depId) => {
    validatexmlConfig['configId'] = Id;
    let baseAPI_URL = process.env.REACT_APP_BASE_URL;
    let subbaseUrl = baseAPI_URL.toString().split("/");
    let hostName = subbaseUrl[2].toString().split(".");
    let path = baseAPI_URL.split(hostName[0]).join("validator")

    service.getLaunchStatus(validatexmlConfig).then((res) => {
      if (res?.status === 200) {
        window.open(path + "/validate/gui?url=" + res.data)
      }


    }).catch((err) => {

    })
  }
  const [securityDta, setSecurityDta] = useState({
    monthly_data: "",
    alert_email: "",
    include_daily_dataFree_limit: "",
    max_daily_spend: "",
    action_required: "",
    max_bytes_content: "",
    max_bytes_per_content_hidden: "",
    max_bytes_per_content_changed: "",
    production: "",
    application_id: "",
    product_id: "2"
  })
  //security Error
  const [securityError, setSecurityError] = useState({
    monthly_data: false,
    alert_email: false,
    include_daily_dataFree_limit: false,
    max_daily_spend: false,
    action_required: false,
    max_bytes_content: false,
    max_bytes_per_content_hidden: false,
    max_bytes_per_content_changed: false,
    content_type: false,
    contentMsg: "",
    mimeMsg: "",
    monthlyMsg: "",
    emailMsg: "",
    typeMsg: ""
  })

  const securityEdit = (Id) => {
    securityDta['application_id'] = props.applicationId;
    service.editProdSecurity(securityDta).then((res) => {
      applicationData?.configProd?.map((item, index) => {
        if (item?.configId === Id) {

          item['editInfo'] = false
        }

      })
      getConfigDetails();
      getApplicationDetails();

    }).catch((err) => {

    })
  }

  const [noData, setNoData] = useState({
    productId: 1,
    applicationId: props?.applicationId,
    name: "",
    description: "",
    type: "PROD",
    platform: "",
    package_suffix: "",
    distribution_email: "",
    onboarding_url: "",
    use_dark_action_bar_icons: 0,
    start_url: "",
    entry_point_hosting: "Self",
    include_developer_menu: "",
    status_bar_color: "",
    header_color: "",
    accent_color: "",

  });

  const [noError, setNoError] = useState({
    name: false,
    description: false,
    platform: false,
    package_suffix: false,
    distribution_email: false,
    onboarding_url: false,
    website_url: false,
    entry_point_hosting: false,
    start_url: false,
    emailMsg: "",
    webMsg: "",
    urlMsg: "",
    packageMsg: "",
    status_manual: false,
    header_manual: false,
    accent_manual: false,
    statusMsg: "",
    headerMsg: "",
    accentMsg: ""
  });
  function openModalNewConfig() {
    setOpen(false)
    setAppConfig(true);
  }

  function openModalCloneConfig() {
    setOpen(false)
    setConfigCreateopen(true);
  }
  function closeModalNewConfig() {
    setAppConfig(false);
    setNoData({
      ...noData,
      name: "",
      description: "",
      platform: "",
      package_suffix: "",
      distribution_email: "",
      onboarding_url: "",
      use_dark_action_bar_icons: 0,
      website_url: "",
      entry_point_hosting: "",
      start_url: "",
      status_bar_color: "#303f9f",
      header_color: "#3f51b5",
      accent_color: "#ff4081",
      status_manual: "",
      header_manual: "",
      accent_manual: ""
    });
    setNoError({
      name: false,
      description: false,
      platform: false,
      package_suffix: false,
      distribution_email: false,
      onboarding_url: false,
      website_url: false,
      entry_point_hosting: false,
      start_url: false,
      emailMsg: "",
      webMsg: "",
      urlMsg: "",
      packageMsg: "",
      status_manual: false,
      header_manual: false,
      accent_manual: false,
      statusMsg: "",
      headerMsg: "",
      accentMsg: ""
    })
  }
  const webReg = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  const colorReg = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  const packageReg = /^[a-zA-Z]+[a-zA-Z0-9_]*$/i;
  const versionReg = /^[0-9]*$/;
  const distemail = /([a-zA-Z0-9_\-\.]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))/
  const addNo = (e) => {
    const newdata = { ...noData };
    newdata[e.target.id] = e.target.value;

    if (e.target.id === "name") {
      if (e.target.value) {
        setNoError({
          ...noError,
          name: false
        })
      }
      else {
        setNoError({
          ...noError,
          name: true
        })
      }
    }

    if (e.target.id === "description") {
      if (e.target.value) {
        setNoError({
          ...noError,
          description: false
        })
      }
      else {
        setNoError({
          ...noError,
          description: true
        })
      }
    }

    if (e.target.id === "platform") {
      if (e.target.value) {
        setNoError({
          ...noError,
          platform: false
        })
      }
      else {
        setNoError({
          ...noError,
          platform: true
        })
      }
    }


    if (e.target.id === "start_url") {
      if (e.target.value) {
        if (webReg.test(e.target.value) === false) {
          setNoError({
            ...noError,
            website_url: true,
            webMsg: "Please enter a valid URL."
          })
        } else {
          setNoError({
            ...noError,
            website_url: false,
            webMsg: ""
          })
        }
      }
      else {
        setNoError({
          ...noError,
          website_url: true,
          webMsg: "This field is required."
        })
      }


    }
    if (e.target.id === "entry_point_hosting") {
      if (e.target.value === "Self") {
        setMaxEntryUrl(true);
      } else {
        setMaxEntryUrl(false);
      }
    }

    if (e.target.id === "distribution_email") {
      if (e.target.value) {
        if (distemail.test(e.target.value) === false) {
          setNoError({
            ...noError,
            distribution_email: true,
            emailMsg: "You must enter a valid email, or comma separated emails"
          })
        } else {
          setNoError({
            ...noError,
            distribution_email: false,
            emailMsg: ""
          })
        }
      }
      else {
        setNoError({
          ...noError,
          distribution_email: false,
          emailMsg: ""
        })
      }
    }

    if (e.target.id === "onboarding_url") {
      if (e.target.value) {
        if (webReg.test(e.target.value) === false) {
          setNoError({
            ...noError,
            onboarding_url: true,
            urlMsg: "Please enter a valid URL."
          })
        } else {
          setNoError({
            ...noError,
            onboarding_url: false,
            urlMsg: ""
          })
        }
      }
      else {
        setNoError({
          ...noError,
          onboarding_url: false,
          urlMsg: ""
        })
      }
    }

    if (e.target.id === "package_suffix") {
      if (e.target.value) {
        if (packageReg.test(e.target.value) === false) {
          setNoError({
            ...noError,
            package_suffix: true,
            packageMsg: "Only alphanumeric characters or underscore and the first character must be a letter"
          })
        }
        else if ((e.target.value).length > 10) {
          setNoError({
            ...noError,
            package_suffix: true,
            packageMsg: "Please enter no more than 10 characters."
          })
        }
        else {
          setNoError({
            ...noError,
            package_suffix: false,
            packageMsg: ""
          })
        }
      }
      else {
        setNoError({
          ...noError,
          package_suffix: false,
          packageMsg: ""
        })
      }
    }


    if (e.target.id === "status_bar_color") {

      if (e.target.value) {
        setConfigStatus(e.target.value);
        if (colorReg.test(e.target.value) === false) {
          setNoError({
            ...noError,
            status_manual: true,
            statusMsg: "Please enter a valid color"
          });
        }
        else {
          setNoError({
            ...noError,
            status_manual: false,
            statusMsg: ""
          });
        }
      }
      else {
        if (colorchecked) {
          setNoError({
            ...noError,
            status_manual: true,
            statusMsg: "This field is required"
          });
        }
        else {
          setNoError({
            ...noError,
            status_manual: false,
            statusMsg: ""
          });
        }
      }

    }


    if (e.target.id === "header_color") {
      if (e.target.value) {
        setConfigAction(e.target.value);
        if (colorReg.test(e.target.value) === false) {
          setNoError({
            ...noError,
            header_manual: true,
            headerMsg: "Please enter a valid color"
          });
        }
        else {
          setNoError({
            ...noError,
            header_manual: false,
            headerMsg: ""
          });
        }
      }
      else {
        if (actioncolorchecked) {
          setNoError({
            ...noError,
            header_manual: true,
            headerMsg: "This field is required"
          });
        }
        else {
          setNoError({
            ...noError,
            header_manual: false,
            headerMsg: ""
          });
        }
      }

    }

    if (e.target.id === "accent_color") {
      if (e.target.value) {
        setConfigAccent(e.target.value);
        if (colorReg.test(e.target.value) === false) {
          setNoError({
            ...noError,
            accent_manual: true,
            accentMsg: "Please enter a valid color"
          });
        }
        else {
          setNoError({
            ...noError,
            accent_manual: false,
            accentMsg: ""
          });
        }
      }
      else {
        if (accentcolorchecked) {
          setNoError({
            ...noError,
            accent_manual: true,
            accentMsg: "This field is required"
          });
        }
        else {
          setNoError({
            ...noError,
            accent_manual: false,
            accentMsg: ""
          });
        }
      }

    }
    if (e.target.id === 'include_developer_menu') {
      setDeveloperMenu(!developerMenu)
      if (e?.target?.checked === false) {
        newdata[''] = null;
      }
      else {
        newdata['include_developer_menu'] = "";
      }

    }

    setNoData(newdata);
  }

  const validateNoSave = (noData) => {

    let count = 0;
    const nwerror = { ...noError };

    if (noData.platform === "") {
      nwerror.platform = true;
      count++;
    } else {
      nwerror.platform = false;
    }

    if (noData.name === "") {
      nwerror.name = true;
      count++;
    } else {
      nwerror.name = false;
    }

    if (noData.description === "") {
      nwerror.description = true;
      count++;
    } else {
      nwerror.description = false;
    }
    if (maxEntryUrl) {
      if (noData.start_url === "") {
        nwerror.website_url = true;
        nwerror.webMsg = "This field is required";
        count++;
      } else {
        if (webReg.test(noData.start_url) === false) {
          nwerror.website_url = true;
          nwerror.webMsg = "Please enter a valid URL.";
          count++;
        }
        else {
          nwerror.website_url = false;
          nwerror.webMsg = "";
        }
      }
    } else {
      nwerror.website_url = false;
      nwerror.webMsg = "";
    }

    if (noData.distribution_email === "") {
      nwerror.distribution_email = false;
      nwerror.emailMsg = "";

    } else {
      if (distemail.test(noData.distribution_email) === false) {
        nwerror.distribution_email = true;
        nwerror.emailMsg = "You must enter a valid email, or comma separated emails";
        count++;
      }
      else {
        nwerror.distribution_email = false;
        nwerror.emailMsg = "";
      }

    }

    if (noData.onboarding_url === "") {
      nwerror.onboarding_url = false;
      nwerror.urlMsg = "";
    } else {
      if (webReg.test(noData.onboarding_url) === false) {
        nwerror.onboarding_url = true;
        nwerror.urlMsg = "Please enter a valid URL.";
        count++;
      }
      else {
        nwerror.onboarding_url = false;
        nwerror.urlMsg = "";
      }
    }

    if (noData.package_suffix === "") {
      nwerror.package_suffix = false;
      nwerror.packageMsg = "";
    } else {
      if (packageReg.test(noData.package_suffix) === false) {
        nwerror.package_suffix = true;
        nwerror.packageMsg = "Please enter a valid URL.";
        count++;
      }
      else if ((noData.package_suffix).length > 10) {
        nwerror.package_suffix = true;
        nwerror.packageMsg = "Please enter a valid URL.";
        count++;
      }
      else {
        nwerror.package_suffix = false;
        nwerror.packageMsg = "";
      }
    }


    if (noData.header_color === "") {
      if (actioncolorchecked) {
        nwerror.header_manual = false;
        nwerror.headerMsg = "";

      }
      else {
        nwerror.header_manual = false;
        nwerror.headerMsg = "";
      }

    } else {
      if (colorReg.test(noData.header_color) === false) {
        nwerror.header_manual = true;
        nwerror.headerMsg = "Please enter a valid color";
        count++;
      }

      else {
        nwerror.header_manual = false;
        nwerror.headerMsg = "";

      }
    }


    if (noData.status_bar_color === "") {

      if (colorchecked) {
        nwerror.status_manual = false;
        nwerror.statusMsg = "";

      }
      else {
        nwerror.status_manual = false;
        nwerror.statusMsg = "";
      }

    } else {
      if (colorReg.test(noData.status_bar_color) === false) {
        nwerror.status_manual = true;
        nwerror.statusMsg = "Please enter a valid color";
        count++;
      }

      else {
        nwerror.status_manual = false;
        nwerror.statusMsg = "";

      }
    }

    if (noData.accent_color === "") {
      if (accentcolorchecked) {
        nwerror.accent_manual = false;
        nwerror.accentMsg = "";

      }
      else {
        nwerror.accent_manual = false;
        nwerror.accentMsg = "";
      }
    } else {
      if (colorReg.test(noData.accent_color) === false) {
        nwerror.accent_manual = true;
        nwerror.accentMsg = "Please enter a valid color";
        count++;
      }

      else {
        nwerror.accent_manual = false;
        nwerror.accentMsg = "";

      }
    }

    setNoError(nwerror);
    return count;
  }
  const noSave = () => {
    const nCount = validateNoSave(noData)

    if (nCount === 0) {
      setLoading(true);
      if (developerMenu) {
        noData.include_developer_menu = ""
      }
      service.noClick(noData).then((res) => {
        setProdLength(false)
        setHasApp(false);
        setLoading(false);
        getApplicationDetails();
        closeModalNewConfig();

      }).catch((err) => {

      })
    }
  }
  const [useAlert, setUseAlert] = useState(false);
  const [DomainMap, setDomainMap] = useState([
    {
      domain_map_source: "",
      domain_map_target: "",
      domain_map_https: "",
      domain_map_redirect: "",
    }
  ]);
  const whitelistReg = /^((^\*\.)|[a-zA-Z0-9-_])*([.][.a-zA-Z0-9-_]+)$/m;
  const [useCheck, setUseCheck] = useState(false)
  const dailyReg = /(?!0+$)(^[0-9]+$)|(^[0-9]+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\.[0-9]{1,2})+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\,[0-9]{2,4})+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K))$/i;

  const handleData = (e) => {

    const newdata = { ...securityDta }

    newdata[e.target.id] = e.target.value;
    if (e.target.id === "max_bytes_content") {
      if (e.target.value) {
        newdata['max_bytes_per_content_changed'] = 'yes'
        newdata['max_bytes_per_content_hidden'] = e.target.value
        if (contentReg.test(e.target.value) === false) {
          setSecurityError({
            ...securityError,
            max_bytes_content: true,
            contentMsg: "Invalid Format."
          });
        }
        else {
          setSecurityError({
            ...securityError,
            max_bytes_content: false,
            contentMsg: ""
          })
        }

      }
      else {
        newdata['max_bytes_per_content_changed'] = 'no'
        newdata['max_bytes_per_content_hidden'] = securityEdit?.max_bytes_content
        setSecurityError({
          ...securityError,
          max_bytes_content: false,
          contentMsg: ""
        })
      }
    }
    if (e.target.id === "content_type") {
      if (e.target.value) {
        var contentTypeValue = e.target.value;
        var modifiedContentTypeValue = '';
        if (contentTypeValue !== '') {
          var originalContentType = contentTypeValue.split('\n');
          for (var i = 0; i < originalContentType.length; i++) {
            if (i === originalContentType.length - 1) {
              if (originalContentType[i] !== '') {
                if (originalContentType[i].indexOf("/") === -1) {
                  modifiedContentTypeValue += originalContentType[i] + '/*';
                } else {
                  modifiedContentTypeValue += originalContentType[i];
                }
              }
            } else {
              if (originalContentType[i] !== '') {
                if (originalContentType[i].indexOf("/") === -1) {
                  modifiedContentTypeValue += originalContentType[i] + '/*' + '\n';
                } else {
                  modifiedContentTypeValue += originalContentType[i] + '\n';
                }
              }
            }
          }

        }

        if (mimeReg.test(modifiedContentTypeValue) === false) {
          setSecurityError({
            ...securityError,
            content_type: true,
            mimeMsg: "Please enter a valid content type."
          });
        }
        else {
          setSecurityError({
            ...securityError,
            content_type: false,
            mimeMsg: ""
          });
          e.target.value = modifiedContentTypeValue;
          newdata[e.target.id] = e.target.value;
        }

      }
      else {
        setSecurityError({
          ...securityError,
          content_type: false,
          mimeMsg: ""
        })
      }
    }
    if (e.target.id === "alert_email") {
      if (e.target.value) {
        if (emailReg.test(e.target.value) === false) {
          setSecurityError({
            ...securityError,
            alert_email: true,
            emailMsg: "Not valid emails"
          });
        }
        else {
          setSecurityError({
            ...securityError,
            alert_email: false,
            emailMsg: ""
          })
        }

      }
      else {
        setSecurityError({
          ...securityError,
          alert_email: true,
          emailMsg: "Required"
        })
      }
    }
    if (e.target.id === "monthly_data") {
      if (e.target.value) {
        if (monthlyReg.test(e.target.value) === false) {
          setSecurityError({
            ...securityError,
            monthly_data: true,
            monthlyMsg: "An Alert Email will be sent to your alert email address if the Monthly Forecast Data Usage exceeds this"
          });
        }
        else {
          setSecurityError({
            ...securityError,
            monthly_data: false,
            monthlyMsg: ""
          })
        }

      }
      else {
        setSecurityError({
          ...securityError,
          monthly_data: false,
          monthlyMsg: ""
        })
      }
    }

    if (e.target.id === "production") {
      if (e.target.value) {
        if (whitelistReg.test(e.target.value) === false) {
          setSecurityError({
            ...securityError,
            production: true,
            sysMsg: "Please enter a valid whitelist."
          });
        }
        else {
          setSecurityError({
            ...securityError,
            production: false,
            sysMsg: ""
          })
        }
      }
      else {
        setSecurityError({
          ...securityError,
          production: false,
          sysMsg: ""
        })
      }
    }
    if (e.target.id === "include_daily_dataFree_limit") {
      setUseAlert(!useAlert)
      newdata[e.target.id] = e.target.checked;
    }
    setSecurityDta(newdata);
  }
  const ClearData = (e) => {
    setClear(true);
    if (e.target.id === 'max_daily_spend') {
      const newdata = { ...securityDta, [e.target.id]: e.target.value };
      document.getElementById('max_daily_spend').value = "";
      setSecurityDta(newdata);

    }

  }
  const [editProd, setEditProd] = useState(false);
  const editProSecurity = () => {
    setEditProd(true);
  };

  const handleCloseProSecurity = () => {
    setEditProd(false);
    setSecurityError({
      monthly_data: false,
      alert_email: false,
      include_daily_dataFree_limit: false,
      max_daily_spend: false,
      action_required: false,
      max_bytes_content: false,
      max_bytes_per_content_hidden: false,
      max_bytes_per_content_changed: false,
      content_type: false,
      contentMsg: "",
      mimeMsg: "",
      monthlyMsg: "",
      emailMsg: "",
      typeMsg: "",
      dailyMsg: "",
      sysMsg: "",
      production: false,
    });
    setSecurityDta({
      monthly_data: "",
      alert_email: "",
      include_daily_dataFree_limit: "",
      max_daily_spend: "",
      action_required: "",
      max_bytes_content: "",
      max_bytes_per_content_hidden: "",
      max_bytes_per_content_changed: "",
      content_type: "",
      application_id: "",
      product_id: 1,
    });

    getApplicationDetails();
  };

  const validateSecurityProd = (securityEdit) => {
    let swCount = 0;
    let nwerror = { ...securityError }

    if (securityEdit.monthly_data === "" || securityEdit.monthly_data === null) {
      nwerror.monthly_data = false;
      nwerror.monthlyMsg = "";
    }
    else {
      if (monthlyReg.test(securityEdit.monthly_data) === false) {
        nwerror.monthly_data = true;
        nwerror.monthlyMsg = "An Alert Email will be sent to your alert email address if the Monthly Forecast Data Usage exceeds this";
        swCount++;
      }
      else {
        nwerror.monthly_data = false;
        nwerror.monthlyMsg = ""
      }
    }

    if (securityEdit.alert_email === "" || securityEdit.alert_email === null) {
      nwerror.alert_email = true;
      nwerror.emailMsg = "Required";
      swCount++;
    }
    else {

      if (emailReg.test(securityEdit.alert_email) === false) {
        nwerror.alert_email = true;
        nwerror.emailMsg = "Not valid emails";
        swCount++;
      }
      else {
        nwerror.alert_email = false;
        nwerror.emailMsg = ""
      }
    }

    if (securityEdit.max_bytes_content === "" || securityEdit.max_bytes_content === null) {
      nwerror.max_bytes_content = false;
      nwerror.contentMsg = "";
    }
    else {
      if (contentReg.test(securityEdit.max_bytes_content) === false) {
        nwerror.max_bytes_content = true;
        nwerror.contentMsg = "Invalid Format.";
        swCount++;
      }
      else {
        nwerror.max_bytes_content = false;
        nwerror.contentMsg = ""
      }
    }


    if (securityEdit.max_daily_spend === "" || securityEdit.max_daily_spend === null) {
      nwerror.max_daily_spend = false;
      nwerror.dailyMsg = "";
    }
    else {

      if (dailyReg.test(securityEdit.max_daily_spend) === false) {
        nwerror.max_daily_spend = true;
        nwerror.dailyMsg = "Invalid Format.";
        swCount++;
      }
      else {
        nwerror.max_daily_spend = false;
        nwerror.dailyMsg = ""
      }
    }

    if (securityEdit.production === "" || securityEdit.production === null) {
      nwerror.production = false;
      nwerror.sysMsg = "";
    }
    else {

      if (whitelistReg.test(securityEdit.production) === false) {
        nwerror.production = true;
        nwerror.sysMsg = "Please enter a valid whitelist.";
        swCount++;
      }
      else {
        nwerror.production = false;
        nwerror.sysMsg = ""
      }
    }

    setSecurityError(nwerror);
    return swCount;

  }
  const apkValidMsg = /^(https:\/\/www\.|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  const SaveSecurityProd = () => {
    const sCount = validateSecurityProd(securityDta);

    if (sCount === 0) {

      securityDta.taskList = DomainMap;
      if (useCheck) {
        securityDta.use_production_whitelist = 1;
      }
      if (securityDta.include_daily_dataFree_limit === true) {
        securityDta.include_daily_dataFree_limit = 1;
      }
      else {
        securityDta.include_daily_dataFree_limit = 0;
      }

      securityDta['application_id'] = props?.applicationId;
      service.editSecurity(securityDta).then((res) => {
        if (res?.status === 200) {
          handleCloseProSecurity();
        }
      })
    }
  }
  const addEventSwitch = () => {

    const eventCount = validateEvent(eventNew);
    if (eventCount === 0) {
      service.addEventsNew(eventNew).then((res) => {
        if (res?.status === 200) {
          handleClose();
          setEditProd(false);
          setAddEvent(false);
          setEventDisable(false);
          getApplicationDetails();

        }
      });
    }

  }

  const fileChange = (e, Id) => {
    validatexmlConfig['appNodeXmlFlag'] = 'EDITS';
    validatexmlConfig['appNodeValidateStatus'] = '';
    if (e.target.value === "appnode") {
      let x = document.getElementById("appNodeXml");
      x.style.display = "block";
      let y = document.getElementById("docXml");
      y.style.display = "none";
      let z = document.getElementById("stylesheetFile");
      z.style.display = "none";
    }

    if (e.target.value === "xmldoc") {
      let y = document.getElementById("docXml");
      y.style.display = "block";
      let x = document.getElementById("appNodeXml");
      x.style.display = "none";
      let z = document.getElementById("stylesheetFile");
      z.style.display = "none";
    }
    if (e.target.value === "Stylesheet") {
      let y = document.getElementById("stylesheetFile");
      y.style.display = "block";
      let x = document.getElementById("appNodeXml");
      x.style.display = "none";
      let z = document.getElementById("docXml");
      z.style.display = "none";
    }
  }
  const launchxmlUrl = (url) => {
    let baseAPI_URL = process.env.REACT_APP_BASE_URL;
    let subbaseUrl = baseAPI_URL.toString().split("/");
    let hostName = subbaseUrl[2].toString().split(".");
    let path = baseAPI_URL.split(hostName[0]).join("validator")

    window.open(path + "/validate/gui?url=" + url);
  }
  const [validatexmlConfig, setValidateXmlConfig] = useState({
    appNodeXml: "",
    docXml: "",
    stylesheetFile: "",
    configId: "",
    appNodeValidateStatus: "",
    appNodeXmlFlag: "READY"
  })
  const fileContentChange = (e, Id) => {
    validatexmlConfig['appNodeXmlFlag'] = 'EDITS';
    validatexmlConfig['appNodeValidateStatus'] = '';
    setChangeMsg(true);
    const data = { ...xmlConfig }
    data[e.target.id] = e.target.value;
    setXmlConfig(data);
  }
  const [hasNoBuildSelf, setHasNoBuildSelf] = useState(false);
  const [hasBuildSelf, setHasBuildSelf] = useState(false);
  const entryPointSelfSave = (Id, depId) => {
    service.buildWrap({ configId: Id }).then((res) => {
      if (res.data.message === "noBuilds") {
        setHasNoBuildSelf(true);
        document.getElementById("configInput").value = Id;
      }
      else if (res.data.message === "hasBuilds") {
        document.getElementById("configInput").value = Id;
        setHasBuildSelf(true);
        setHasNoBuildSelf(false);


      }


    }).catch((err) => {

    })
  }
  const [entrypointSelf, setEntryPointSelf] = useState({
    deploymentType: "DEV",
    start_url: "",
    appnode_xml: "",
    configId: ""
  })
  const continueSelfEdit = () => {
    let config = document.getElementById("configInput").value;
    entrypointSelf['configId'] = config;
    service.editEntryPoint(entrypointSelf).then((res) => {
      setHasNoBuildSelf(false);
      applicationData?.configProd?.map((item, index) => {
        if (item?.configId === config) {

          item['editInfo'] = false
        }

      })
      service.getConfig({ configId: config }).then((res) => {
        getApplicationDetails();
      })

    }).catch((err) => {

    })
  }

  const [downloadApks, setDownloadApks] = useState();
  const [apkList, setApkList] = useState(false);
  const [downloadApksKey, setDownloadApksKey] = useState();
  const showApk = (buildid) => {
    services.apkUrl({ build_id: buildid }).then((res) => {

      setDownloadApks(res?.data?.apkUrlJson);
      setDownloadApksKey(res?.data?.key)
      setApkList(true);
    }).catch((err) => {

    })
  }

  const showApkDebug = (index, buildid) => {
    let x = document.getElementById("debugDownload" + buildid);
    if (x.style.display === "none") {
      x.style.display = "block"
    }
    else {
      x.style.display = "none"
    }
  }

  useEffect(() => {
    setLoading(true);
    document.title = "Datafree Portal";
    getApplicationDetails();
    getGenerateApp();
    getPlatform();
  }, [appConfig]);

  return (
    <div>
      <div className="dialoguRow">
        <div className="devHead">
          {applicationData?.configProd?.map((item, index) => (
            <div className="devHead" key={index}>
              {"biNu ID : " + item?.deploymentId?.appId}
            </div>
          ))}</div>
        <div className="devmainHead">
          Production App Configurations, Entry Points and Builds
        </div>

        <div>

        </div>
      </div>
      {loading ? <div className="loaderContainer"><Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
      {validateLoader ? <div className="loaderContainer" style={{ marginTop: '20px' }}>
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
      {!loading && hasApp ? applicationData?.configProd?.map((item, index) => (

        item.binuArchive === false ? (
          <div style={{ flexDirection: "column" }}>
            <div className="developmentTab" key={index} id={"editConfig"} style={{ opacity: '1' }}>
              <div className="developTabContent">
                <div className="developTabContenthead">
                  biNu ID : <span>{item.deploymentId.appId}</span>
                </div>
                <div className="developTabContenthead">
                  Config ID :<span>{item.configId}</span>
                </div>
                <div className="developTabContenthead">
                  Name :<span>{item.name}</span>
                </div>
                <div className="developTabContenthead">
                  Last Build :<span>{item.date}</span>
                </div>

                <div className="developToolsTabContenthead">Tools:</div>
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <button className="workbench" onClick={() => BuildView(item.configId)}>Build APK</button>
                </div>
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <button className="workbench" onClick={() => viewHistory(item.configId)}>
                    Build History and Download
                  </button>
                </div>
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <button className="workbench" onClick={() => launchxmlUrl(item?.hostingFlag === "biNu" ? item?.homeUrl : item?.start_url)}>

                    Launch binu App XML Assistant

                  </button>
                </div>

              </div>

              <div className="developMaxTabContent">
                <AntTabs
                  value={value}
               
                  onChange={handleChange}
                  className="tabChange tabViewMenu"
                  TabIndicatorProps={{
                    style: {
                      display: "none",
                    },
                  }}
                >
                  <AntTab
                    label="APK Config"
                    style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  />
                  <AntTab
                    label="Look and Feel"
                    style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  />
                  <AntTab
                    label="Entry Point"
                    style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  />
                  <AntTab
                    label="Security"
                    style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  />
                  <AntTab
                    label="Events"
                    style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  />
                </AntTabs>

                <TabPanel value={value} index={0}>
                  <div className="developmentSecurityTab">
                    <button className={!item?.editInfo ? 'editbtn' : 'NotEdit'} onClick={() => edit(item?.configId, item?.deploymentId?.deploymentId, index)}>
                      Edit
                    </button>

                    <button className={item?.editInfo ? 'editbtn' : 'NotEdit'} onClick={() => handleClose(item?.configId, item?.deploymentId?.deploymentId, index)}>
                      Cancel
                    </button>

                    <button className={item?.editInfo ? 'editbtn' : 'NotEdit'} onClick={() => saveEdit(item?.configId, item?.deploymentId?.deploymentId)}>Save</button>
                  </div>
                  <div className="companyForm">
                    <div className="InputflexRow">
                      <div className="ddflex">
                        <div className="companyLabel">Name</div>

                        <div>
                          {!item?.editInfo ? (
                            <input
                              type="text"
                              className="inputField"
                              name="name"
                              disabled="disabled"
                              value={item.name}
                            />
                          ) : (
                            <input
                              type="text"
                              className={validError?.name ? "editinputField errEdit" : "editinputField"}
                              name="name"
                              id="name"
                              onChange={(e) => changeSDK(e)}
                              onBlur={(e) => changeSDK(e)}
                              defaultValue={item.name}
                            />
                          )}
                          {validError.name ?
                            (<p
                              className="errorClass" name="identifiererror">This field is required</p>)
                            : ""}
                        </div>
                      </div>

                      <div className="ddflex">
                        <div className="companyLabel">Description</div>
                        <div>
                          {!item?.editInfo ? (
                            <input
                              type="text"
                              className="inputField"
                              name="Description"
                              disabled="disabled"
                              value={item.description}
                            />
                          ) : (
                            <input
                              type="text"
                              className={validError?.description ? "editinputField errEdit" : "editinputField"}
                              name="description"
                              id="description"
                              onChange={(e) => changeSDK(e)}
                              onBlur={(e) => changeSDK(e)}
                              defaultValue={item.description}
                            />
                          )}
                          {validError.description ?
                            (<p
                              className="errorClass" name="identifiererror">This field is required</p>)
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="InputflexRow">
                      <div className="ddflex">
                        <div className="companyLabel">biNu Platform</div>
                        <div>
                          {!item?.editInfo ? (
                            <input
                              type="text"
                              className="inputField"
                              disabled="disabled"
                              value={item?.binuClientVersionId?.binuClientVersionName}
                            />
                          ) : (
                            <select
                              type="text"
                              className="application-form-control"
                              name="platform"
                              id="platform"
                              onChange={(e) => changeSDK(e)}
                              style={{ width: '110%', height: '34px' }}
                            >
                              {applicationData?.platformPop && applicationData?.platformPop.length > 0
                                ? applicationData?.platformPop.map((post) => (
                                  <option key={post.id} value={post.id} selected={item.platform === post.id && true}>
                                    {post.name}
                                  </option>
                                ))
                                : null}
                            </select>
                          )}
                        </div>
                      </div>

                      <div className="ddflex">
                        <div className="companyLabel">Google App Id</div>
                        <div>

                          <input
                            type="text"
                            className="inputField"
                            name="Google App Id"
                            disabled="disabled"
                            value={applicationData.googleAppId}
                          />

                        </div>
                      </div>
                    </div>
                    <div className="InputflexRow">
                      <div className="ddflex">
                        <div className="companyLabel">Distribution Email</div>
                        <div>
                          {!item?.editInfo ? (
                            <input
                              type="text"
                              className="inputField"
                              name="DistributionEmail"
                              disabled="disabled"
                              value={item?.distributionEmail}
                            />
                          ) : (
                            <input
                              type="text"
                              className={validError?.distribution_email ? "editinputField errEdit" : "editinputField"}
                              name="distribution_email"
                              id="distribution_email"
                              onChange={(e) => changeSDK(e)}
                              defaultValue={item?.distributionEmail}
                            />
                          )}
                          {validError.emailMsg ?
                            (<p
                              className="errorClass" name="identifiererror">{validError.emailMsg ? validError.emailMsg : "This field is required"}</p>)
                            : ""}
                        </div>
                      </div>
                      <div className="ddflex">
                        <div className="packageLabel ">Package Suffix</div>
                        <div>
                          {!item?.editInfo ? (
                            <input
                              type="text"
                              className="inputField"
                              name="UsageAlert"
                              disabled="disabled"
                              value={item?.packageSuffix}
                            />
                          ) : (
                            <input
                              type="text"
                              className={validError?.package_suffix ? "editinputField errEdit" : "editinputField"}
                              name="package_suffix"
                              id="package_suffix"
                              onChange={(e) => changeSDK(e)}
                              onBlur={(e) => changeSDK(e)}
                              defaultValue={item?.packageSuffix}
                            />
                          )}
                          {validError.suffixMsg ?
                            (<p
                              className="errorClass" name="identifiererror">{validError.suffixMsg ? validError.suffixMsg : ""}</p>)
                            : ""}
                        </div>
                      </div>

                      <div></div>
                    </div>
                    <div className="InputflexRow">
                      <div className="ddflex">
                        <div>
                          <label for="websiteName" className="ipinputtext">
                            Include Developer Menu
                          </label>
                          <input
                            type="checkbox"
                            name="websiteName"
                            id="include_developer_menu"
                            onChange={(e) => changeSDK(e)}
                            onBlur={(e) => changeSDK(e)}
                            //onChange={() => setChecked(!checked)}
                            defaultChecked={item?.includeDevMenu === true || 1 ? !devChecked : devChecked}
                          />
                        </div>
                        <div className="companyLabel">App Permissions</div>
                        <div>
                          <input
                            type="checkbox"
                            name="location_permission"
                            id="location_permission"
                            onChange={(e) => changeSDK(e)}
                            onBlur={(e) => changeSDK(e)}
                            defaultChecked={item?.locationPermission === true || 1 ? !locPermission : locPermission}
                          />
                          <label for="websiteName" className="ipinputtext">
                            Allow App to request Location Service Permission on
                            install
                          </label>
                        </div>
                      </div>
                      {!item?.editInfo ?
                        <div style={{
                          display: "flex", flexDirection: 'column',
                          alignItems: 'flex-start', marginBottom: '10px', width: '210px', marginTop: '15px'
                        }}>
                          <label className="companyLabel">
                            Include SDKs
                          </label>
                          <label className="companyLabel">
                            <input type="checkbox"
                              checked={applicationData?.configProd[0]?.includeSdks.split(",").includes("EMTRACKER_SDK") ? true : false}
                              style={{ pointerEvents: 'none' }}
                            />
                            Effective Measure Tracker
                          </label>
                          <label className="companyLabel">
                            <input type="checkbox"
                              style={{ pointerEvents: 'none' }}
                              checked={applicationData?.configProd[0]?.includeSdks.split(",").includes("VIDEO_SDK") ? true : false}
                            />
                            Video Advertising
                          </label>
                        </div> :
                        <div style={{
                          display: "flex", flexDirection: 'column',
                          alignItems: 'flex-start', marginBottom: '10px', width: '210px', marginTop: '15px'
                        }}>
                          <label className="companyLabel">
                            Include SDKs
                          </label>
                          <label className="companyLabel">
                            <input type="checkbox"
                              defaultChecked={applicationData?.configProd[0]?.includeSdks.split(",").includes("EMTRACKER_SDK") ? true : false}
                              checked={include1}
                              onChange={() => setinclude1(!include1)}
                            />
                            Effective Measure Tracker
                          </label>
                          <label className="companyLabel">
                            <input type="checkbox"
                              defaultChecked={applicationData?.configProd[0]?.includeSdks.split(",").includes("VIDEO_SDK") ? true : false}
                              checked={include2}
                              onChange={() => setinclude2(!include2)}
                            //checked={applicationData?.editConfigDetails?.allSdks[1]['name'] === "VIDEO_SDK" ? true : false} 
                            />
                            Video Advertising
                          </label>
                        </div>

                      }
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                <div className="developmentSecurityTab">
                <button className={!item?.editInfo ? 'editbtn' : 'NotEdit'}
              
                  style={{ margin: '5px', borderRadius: '10px', width: '70px', height: '35px' }}
                  onClick={() => editLook(item?.configId, item?.deploymentId?.deploymentId, index)}>
                  Edit
                </button>

                <button className={item?.editInfo ? 'editbtn' : 'NotEdit'}
                  style={{ margin: '5px', borderRadius: '10px', width: '70px', height: '35px' }}
                  onClick={() => handleCloseLook(item?.configId, item?.deploymentId?.deploymentId, index)}>
                  Cancel
                </button>

                <button className={item?.editInfo ? 'editbtn' : 'NotEdit'}
                  style={{ margin: '5px', borderRadius: '10px', width: '70px', height: '35px' }}
                  onClick={() => lookFeelEdit(item?.configId, item?.deploymentId?.deploymentId)}>Save</button>

              </div>
              <div className="InputflexRow">
                <div className="ddflex">
                  <div className="companyLabel" style={{ marginBottom: '5px', width: '100%' }}>Onboarding URL</div>
                  {!item?.editInfo  ?
                    <div>
                      <input type="text" disabled="disabled"
                        className="InputWrap"
                        value={item.onBoardingUrl}/>
                    </div>
                    :
                    <div>
                      <input type="text"
                        className={lookError.onboarding_url ? "InputWrapEdit errEdit" : "InputWrapEdit"}
                        id="onboarding_url"
                        defaultValue ={item.onBoardingUrl}
                       
                        onChange={handleFeel}

                      />
                      {lookError.onboarding_url ? (
                        <span style={{ float: 'left' }} className="errormsg">{lookError.urlMsg}</span>
                      ) : ""}
                    </div>

                  }

                </div>
                <div className="ddflex">
                  <div className="companyLabel" style={{ marginBottom: '5px', width: '100%' }}>Use Dark Action Bar Icons</div>
                  <div>

                    {!item?.editInfo  ?
                      <select
                        className="SelectWrap"
                        disabled={true}>
                        <option value="no" selected={applicationData?.configProd[index]?.useDarkActionBarIcons === false && true}>No</option>
                        <option value="yes" selected={applicationData?.configProd[index]?.useDarkActionBarIcons === true && true}>Yes</option>
                      </select>
                      :
                      <select
                        className="SelectWrapEdit"
                        id="use_dark_action_bar_icons"
                        onChange={handleFeel}
                      >
                        <option value="no" selected={lookFeel.use_dark_action_bar_icons === "no" && true}>No</option>
                        <option value="yes" selected={lookFeel.use_dark_action_bar_icons === "yes" && true}>Yes</option>
                      </select>
                    }
                  </div>
                </div>
              </div>
              <div className="InputflexRow">
                <div className="ddflex">
                  {colorchecked ? <div>
                    <div className="companyLabel">Status Bar Color</div>
                    <div>
                      <input
                        type="text"
                        className={lookError.status_manual ? "registerInput errEdit" : "registerInput"}
                        id="status_manual"
                        value={lookFeel.status_manual}
                        onChange={handleFeel}
                      />

                      {lookError.status_manual ? (
                        <span style={{ float: 'left' }} className="errormsg">{lookError.statusMsg}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div> : ""}
                  {!colorchecked ? <div className="companyLabel" style={{ marginBottom: '5px' }}>Status Bar Color</div> : ""}
                  {!colorchecked ?
                    <div className="colorPickerDiv">
                      {!item?.editInfo  ?
                        <>
                          <input
                            type="color"
                            className="colorBox"
                            style={{ cursor: 'not-allowed' }}
                            value={applicationData?.configProd[index]?.statusBarColor}
                          />
                          <input
                            type="text"
                            className="colorInput"
                            disabled="disabled"
                            value={applicationData?.configProd[index]?.statusBarColor}
                          />
                        </>
                        :
                        <>
                          <input
                            type="color"
                            className="colorBox"
                            id="status_bar_color"
                            style={{ cursor: "pointer", cursor: 'not-allowed' }}
                            value={lookFeel.status_bar_color}
                            onChange={handleFeel}
                          />
                          <input
                            type="text"
                            className="colorInput"
                            disabled="disabled"
                            value={lookFeel.status_bar_color}
                          />
                        </>
                      }

                    </div> : ""}
                  <div>
                    <label className="companyLabel">
                      Click here to Enter Manually
                      <input type="checkbox"
                        disabled={!item?.editInfo  ? "disabled" : false}
                        onChange={() => setColorchecked(!colorchecked)}
                        checked={colorchecked}
                      />
                    </label>
                  </div>
                </div>
                <div className="ddflex">
                  {actioncolorchecked ? <div>
                    <div className="companyLabel">Action Bar Color</div>
                    <div>
                      <input
                        type="text"
                        className={lookError.header_manual ? "registerInput errEdit" : "registerInput"}
                        placeholder=""
                        onChange={handleFeel}
                        id="header_manual"
                        value={lookFeel.header_manual}
                      />
                      {lookError.header_manual ? (
                        <span style={{ float: 'left' }} className="errormsg">{lookError.headerMsg}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div> : ""}
                  {!actioncolorchecked ? <div className="companyLabel" style={{ marginBottom: '5px', width: '100%' }}>Action Bar Color</div> : ""}
                  {!actioncolorchecked ? <div className="colorPickerDiv">
                    {!item?.editInfo  ?
                      <>
                        <input
                          type="color"
                          className="colorBox"
                          style={{ cursor: "not-allowed" }}
                          value={applicationData?.configProd[index]?.actionBarColor}
                        />
                        <input
                          type="text"
                          className="colorInput"
                          disabled="disabled"
                          value={applicationData?.configProd[index]?.actionBarColor}
                        />
                      </>
                      :
                      <>
                        <input
                          type="color"
                          className="colorBox"
                          style={{ cursor: "pointer" }}
                          id="header_color"
                          onChange={handleFeel}
                          value={lookFeel.header_color}
                        />

                        <input
                          type="text"
                          className="colorInput"
                          disabled="disabled"
                          value={lookFeel.header_color}
                        />
                      </>
                    }

                  </div> : ""}
                  <div>
                    <label className="companyLabel">
                      Click here to Enter Manually
                      <input type="checkbox"
                        disabled={!item?.editInfo  ? "disabled" : false}
                        onChange={() => setActionColorchecked(!actioncolorchecked)}
                        checked={actioncolorchecked}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="InputflexRow">
                <div className="ddflex">
                  {accentcolorchecked ? <div>
                    <div className="companyLabel">Accent Color</div>
                    <div>
                      <input
                        type="text"
                        className={lookError.accent_manual ? "registerInput errEdit" : "registerInput"}
                        placeholder=""
                        id="accent_manual"
                        onChange={handleFeel}
                        value={lookFeel.accent_manual}
                      />
                      {lookError.accent_manual ? (
                        <span style={{ float: 'left' }} className="errormsg">{lookError.accentMsg}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div> : ""}
                  {!accentcolorchecked ? <div className="companyLabel" style={{ marginBottom: '5px' }}>Accent Colour</div> : ""}
                  {!accentcolorchecked ? <div className="colorPickerDiv">
                    {!item?.editInfo  ?
                      <>
                        <input
                          type="color"
                          className="colorBox"
                          style={{ cursor: "not-allowed" }}
                          value={applicationData?.configProd[index]?.accentColor}
                        />
                        <input
                          type="text"
                          className="colorInput"
                          disabled="disabled"
                          value={applicationData?.configProd[index]?.accentColor}
                        />
                      </>
                      :
                      <>
                        <input
                          type="color"
                          className="colorBox"
                          id="accent_color"
                          style={{ cursor: "pointer" }}
                          onChange={handleFeel}
                          value={lookFeel.accent_color}
                        />
                        <input
                          type="text"
                          className="colorInput"
                          disabled="disabled"
                          value={lookFeel.accent_color}
                        />
                      </>
                    }
                  </div> : ""}
                  <div>
                    <label className="companyLabel">
                      Click here to Enter Manually
                      <input type="checkbox"
                        onChange={() => setAccentColorchecked(!accentcolorchecked)}
                        checked={accentcolorchecked}
                        disabled={!item?.editInfo  ? "disabled" : false} />
                    </label>
                  </div>
                </div>
              </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {item?.hostingFlag === 'biNu' ?
                    <div className="developmentSecurityTab">
                      <button className={!item?.editInfo ? 'editbtn' : 'NotEdit'} onClick={() => edit(item?.configId, item?.deploymentId?.deploymentId,)}>
                        Edit
                      </button>

                      <button className={item?.editInfo ? 'editbtn' : 'NotEdit'} onClick={() => handleCloseEntry(item?.configId, item?.deploymentId?.deploymentId, index)}>
                        Undo All Edits
                      </button>

                      <button className={item?.editInfo ? 'editbtn' : 'NotEdit'} onClick={() => entryPointSave(item?.configId, item?.deploymentId?.deploymentId,)}>Save All Edits</button>
                    </div>
                    : <div className="developmentSecurityTab">
                      <button className={!item?.editInfo ? 'editbtn' : 'NotEdit'} onClick={() => edit(item?.configId, item?.deploymentId?.deploymentId,)}>
                        Edit
                      </button>

                      <button className={item?.editInfo ? 'editbtn' : 'NotEdit'} onClick={() => handleClose(item?.configId, item?.deploymentId?.deploymentId,)}>
                        Cancel
                      </button>

                      <button className={item?.editInfo ? 'editbtn' : 'NotEdit'} onClick={() => entryPointSelfSave(item?.configId, item?.deploymentId?.deploymentId)}>Save</button>
                    </div>
                  }

                  <div className="InputflexRow">
                    <div className="entrypointLabel">
                      Entry Point Hosting{" "}
                    </div>
                    <input
                      type="text"
                      className="entryPointinputField"
                      name="start_url"
                      id="start_url"
                      disabled="disabled"
                      value={item?.hostingFlag}
                    />
                    <div className="flexSpace8"></div>

                    {item?.hostingFlag === 'biNu' ? <div className="entrypointLabel">XML Validated</div> : ""}
                    {item?.hostingFlag === 'biNu' ? applicationData?.editConfigDetailsAll[index]?.xmlValidated ?
                      <span className="xmlvalidateStyle">
                        <FaTimes />
                      </span> : <span className="xmlvalidateStyle"> </span> : ""}


                  </div>
                  <input type="hidden" id="xmlStat"
                  ></input>

                  {item?.hostingFlag === "biNu" ?
                    <div className="appnodeMainDiv">
                      <div className="xmlEditDiv">
                        <div className="marginBottomClass">
                          <label for="name" className="entrypointLabel">
                            File
                          </label>
                          <select className="filecustomSelect" disabled={item?.editInfo ? false : "disabled"} onChange={(e) => fileChange(e, item?.id)}>
                            <option value="appnode">Appnode</option>
                            <option value="xmldoc">XML Doc</option>
                            <option value="Stylesheet">Stylesheet</option>
                          </select>
                          <label className="downarrow">
                            <FaLongArrowAltDown />
                          </label>

                          <div>
                            <textarea className="formgroup" id="appNodeXml" hidden="hidden"
                              style={{ color: 'red', display: 'block' }}
                              defaultValue={item.xmlFile ? item?.xmlFile : applicationData?.editConfigDetailsAll[index]?.xmlFile}
                              disabled={item?.editInfo ? false : "disabled"} onChange={(e) => fileContentChange(e, item?.id)}></textarea>

                            <textarea className="formgroup" id="docXml" hidden="hidden"
                              style={{ color: 'red', display: 'none' }} disabled={item?.editInfo ? false : "disabled"}
                              defaultValue={item.xmlDocFile ? item?.xmlDocFile : applicationData?.editConfigDetailsAll[index]?.xmlDocFile} onChange={(e) => fileContentChange(e, item?.id)}
                            ></textarea>
                            <textarea className="formgroup" id="stylesheetFile" hidden="hidden"
                              style={{ color: 'red', display: 'none' }} disabled={item?.editInfo ? false : "disabled"}
                              defaultValue={item.stylesheetFile ? item?.stylesheetFile : applicationData?.editConfigDetailsAll[index]?.stylesheetFile} onChange={(e) => fileContentChange(e, item?.id)}
                            >
                            </textarea>
                            {/* <textarea className="formgroup textareaField" value={applicationData?.editConfigDetails?.xmlFile} disabled="disabled" /> */}
                            <div className="clearfix">
                              <button className={item?.editInfo ? 'subtabButton btnStyle' : 'subtabButton'} color="primary" onClick={() => launchStatus(item?.configId, item?.xmlFile, item?.xmlDocFile, item?.stylesheetFile, item?.deploymentId)}>
                                Launch XML Assistant
                              </button>
                              <div className="flexSpace"></div>
                              <button className={item?.editInfo ? 'subtabButton btnStyle' : 'subtabButton'} color="primary" onClick={() => validateCondition(item?.configId)}>
                                Validate XML
                              </button>
                            </div>

                          </div>

                        </div>

                      </div>

                      <div className="xmlEditDiv">
                        <div id="xml_results_" style={{ color: 'red' }}>{validateDiv}</div>
                      </div>
                    </div>

                    : <div>
                      <div className="entrypointLabel" >
                        Entry Point URL
                      </div>
                      {!item?.editInfo ? <div> <input
                        type="text"
                        className="entyPointurlfield"
                        disabled="disabled"
                        value={item?.homeUrl}
                        style={{ marginLeft: "0px" }}
                      /></div> : <div> <input
                        type="text"
                        className={!error.entry_point_url ? "editentyPointurlfield" : "errEdit editentyPointurlfield"}
                        id="start_url"
                        name="start_url"
                        onChange={(e) => entryPointChangeHandler(e)}
                        style={{ marginLeft: "0px" }}
                      /></div>
                      }
                      {error.entry_point_url ? (
                        <span style={{ float: 'left', marginBottom: "4px" }} className="errorClass">{error.webmsg}</span>
                      ) : (
                        ""
                      )}
                      <div className="launchurlInputfield" onClick={() => launchxmlUrl(item?.hostingFlag === "biNu" ? item?.homeUrl : item?.start_url)}>
                        + Launch XML Assistant
                      </div>
                      <input id="configInput" type="hidden"></input>
                    </div>

                  }

                </TabPanel>
                <TabPanel value={value} index={3}>
                  <div className="developmentSecurityTab">
                    <button
                      className={!editProd ? "editbtn" : "NotEdit"}
                      onClick={editProSecurity}
                    >
                      Edit
                    </button>

                    <button className={editProd ? "editbtn" : "NotEdit"}
                      onClick={handleCloseProSecurity}
                    >
                      Cancel
                    </button>

                    <button className={editProd ? "editbtn" : "NotEdit"}
                      onClick={SaveSecurityProd}
                    >Save</button>
                  </div>

                  <div className="InputflexRow">
                    <div className="entrypointLabel" style={{ width: '30%' }}>
                      Monthly App #datafree Data Alert (bytes){" "}
                    </div>
                    {!editProd ?
                      <input
                        type="text"
                        className="entryinputField"
                        name="name"
                        disabled="disabled"
                        style={{ marginLeft: '5%' }}
                        value={applicationData ? applicationData?.configProd[0]?.deploymentId?.applicationId?.appDataUsageAlert : ""}
                      />
                      :
                      <input
                        type="text"
                        className={securityError?.monthly_data ? "editentryinputField errEdit" : "editentryinputField"}
                        name="popup"
                        id="monthly_data"
                        onChange={(e) => handleData(e)}
                        onBlur={(e) => handleData(e)}
                        style={{ marginLeft: '5%' }}
                        defaultValue={applicationData ? applicationData?.configProd[0]?.deploymentId?.applicationId?.appDataUsageAlert : ""}
                      />

                    }

                  </div>
                  {securityError.monthly_data ? (
                    <div className="errorClass" style={{ width: '20%', marginLeft: '35%' }}>{securityError?.monthlyMsg}</div>
                  ) : null}

                  <div className="InputflexRow">
                    <div className="entrypointLabel" style={{ width: '40%' }}>
                      Alert Email Addresses (comma separated){" "}
                    </div>
                    {!editProd ?
                      <input
                        type="text"
                        className="entyPointfield"
                        name="name"
                        disabled="disabled"
                        style={{ marginLeft: '7%' }}
                        value={applicationData ? applicationData?.configProd[0]?.deploymentId?.applicationId?.alertEmail : ""}
                      />
                      :
                      <input
                        type="text"
                        className={securityError?.alert_email ? "editentyPointfield errEdit" : "editentyPointfield"}
                        name="popup"
                        id="alert_email"
                        onChange={(e) => handleData(e)}
                        onBlur={(e) => handleData(e)}
                        style={{ marginLeft: '-5%' }}
                        defaultValue={applicationData ? applicationData?.configProd[0]?.deploymentId?.applicationId?.alertEmail : ""}
                      />
                    }
                  </div>
                  {securityError.alert_email ? (
                    <div className="errorClass" style={{ width: '20%', marginLeft: '35%' }}>{securityError?.emailMsg}</div>
                  ) : null}
                  <div className="InputflexRow">
                    <div className="entrypointLabel" style={{ width: '32%' }}>
                      Include Daily #datafree Data Limit Alerting ?
                    </div>
                    <input type="checkbox" name="name" value="Self"
                      id="include_daily_dataFree_limit"
                      disabled={!editProd ? "disabled" : false}
                      onChange={() => setUseAlert(!useAlert)}
                      className="dailyLimit" />
                  </div>
                  {useAlert ? (
                    <div className="borderDiv">
                      <div className="InputflexRow">
                        <div className="entrypointLabel" style={{ width: '31%' }}>
                          Daily #datafree data limit (bytes)
                        </div>
                        {!editProd ?
                          <input
                            type="text"
                            className="entryinputField"
                            name="name"
                            disabled="disabled"
                            value={securityDta?.max_daily_spend}
                            style={{ marginLeft: '0%' }}
                          />
                          :
                          <input
                            type="text"
                            className={securityError?.max_daily_spend ? "editentryinputField errEdit" : "editentryinputField"}
                            name="max_daily_spend"
                            id="max_daily_spend"
                            onChange={(e) => handleData(e)}
                            onBlur={(e) => handleData(e)}
                            //defaultValue={applicationData ? applicationData?.prod?.applicationId?.maxBytesPerDay : ""}
                            defaultValue={securityDta.max_daily_spend}
                            style={{ marginLeft: '0%' }}
                          />
                        }

                        <button
                          type="button"
                          className={!editProd ? "clearButton" : "clearblueButton"}
                          name="max_daily_spend"
                          id="max_daily_spend"
                          disabled={!editProd ? "disabled" : false}
                          onClick={ClearData}
                        >Clear Value</button>

                      </div>
                      {securityError.max_daily_spend ? (
                        <div className="errorClass" style={{ width: '20%', marginLeft: '35%', marginBottom: '5px' }}>{securityError?.dailyMsg}</div>
                      ) : null}
                      <div className="InputflexRow">
                        <div className="entrypointLabel" style={{ width: '30%' }}>
                          Required Action on Daily Limit
                        </div>
                        {!editProd ? (
                          <select
                            type="text"
                            className="entyPointfield"
                            name="name"
                            disabled="disabled"
                            style={{ marginLeft: '6%', height: '37px' }}
                          >
                            <option value="0" selected={applicationData?.configProd[0]?.deploymentId?.applicationId?.maxBytesPerDayAction === 0 && true}>Send Alerts Only</option>
                            <option value="1" selected={applicationData?.configProd[0]?.deploymentId?.applicationId?.maxBytesPerDayAction === 1 && true}>Send Alerts and Stop App</option>
                          </select>) :
                          <select
                            type="text"
                            className="editentyPointfield"
                            name="popup"
                            id="action_required"
                            onChange={(e) => handleData(e)}
                            onBlur={(e) => handleData(e)}
                            style={{ marginLeft: '1%', height: '37px' }}
                          >
                            <option value="0" selected={applicationData?.configProd[0]?.deploymentId?.applicationId?.maxBytesPerDayAction === 0 && true}>Send Alerts Only</option>
                            <option value="1" selected={applicationData?.configProd[0]?.deploymentId?.applicationId?.maxBytesPerDayAction === 1 && true}>Send Alerts and Stop App</option>
                          </select>}
                      </div>
                    </div>) : null}
                  <div className="InputflexRow">
                    <div className="entrypointLabel">
                      Max Bytes per content object
                    </div>
                    {!editProd ? (
                      <input
                        type="text"
                        className="entryinputField"
                        name="name"
                        disabled="disabled"
                        style={{ marginLeft: '3%' }}
                        value={applicationData ? applicationData?.configProd[0]?.deploymentId?.securityRules?.contentSize : ""}
                      />) :
                      <input
                        type="text"
                        className={securityError.max_bytes_content ? "editentryinputField errEdit" : "editentryinputField"}
                        name="max_bytes_content"
                        id="max_bytes_content"
                        onChange={(e) => handleData(e)}
                        onBlur={(e) => handleData(e)}
                        style={{ marginLeft: '3%' }}
                        defaultValue={applicationData ? applicationData?.configProd[0]?.deploymentId?.securityRules?.contentSize : ""}
                      />}
                  </div>
                  {securityError.max_bytes_content ? (
                    <div className="errorClass" style={{ width: '20%', marginLeft: '35%' }}>{securityError?.contentMsg}</div>
                  ) : null}

                  <div className="entypointflex">
                    <div className="companyLabel">#datafree Gateway domain</div>

                    <div>
                      <input
                        type="text"
                        className="entyPointfield"
                        name="name"
                        disabled="disabled"
                        style={{ marginBottom: '10px' }}
                        value={applicationData ? applicationData?.configProd[0]?.deploymentId?.applicationId?.reachGateway : ""}
                      />

                    </div>
                  </div>

                  <Grid item xs={12}>
                    <div className="companyLabel">Production WhiteList

                      <input
                        type="checkbox"
                        id="use_production_whitelist"
                        checked={useCheck}
                        disabled={!editProd ? 'disabled' : false}
                        onChange={() => setUseCheck(!useCheck)}
                        style={{ float: 'right', marginRight: '100px' }}
                      />
                      <label className="companyLabel" style={{ float: 'right' }}>Use whitelist</label>
                    </div>

                    <div>
                      {!editProd ? (
                        <textarea
                          type="text"
                          className="entyPointfield"
                          id="production"
                          disabled="disabled"
                          style={{ height: '70px' }}
                          value={applicationData ? applicationData?.configProd[0]?.deploymentId?.whitelist : ""}
                        />
                      ) : (
                        <textarea
                          type="text"
                          className={securityError.production ? "editentyPointfield errEdit" : "editentyPointfield"}
                          id="production"
                          onChange={(e) => handleData(e)}
                          onBlur={(e) => handleData(e)}
                          style={{ height: '70px' }}
                          defaultValue={applicationData ? applicationData?.configProd[0]?.deploymentId?.whitelist : ""}

                        />
                      )}
                      {securityError.production ? (
                        <div className="errorClass">{securityError.sysMsg}</div>
                      ) : null}
                    </div>
                  </Grid>

                </TabPanel>
                <TabPanel value={value} index={4}>
                  <div style={{ backgroundColor: 'white', }}>
                    <div style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'flex-end', }}>
                      <button
                        className={!eventDisable ? "clearblueButton" : "clearButton"}
                        disabled={eventDisable ? "disabled" : false}
                        onClick={addNewEvent}>
                        + Create New Event
                      </button>

                      <button
                        className={!eventDisable ? "clearButton" : "editbtn"}
                        disabled={!eventDisable ? "disabled" : false}
                        onClick={closeEvent}>
                        Cancel
                      </button>

                      <button
                        className={!eventDisable ? "clearButton" : "editbtn"}
                        disabled={!eventDisable ? "disabled" : false}
                        onClick={addEventSwitch}
                      >Save</button>
                    </div>
                    <div>
                      <table>
                        <thead className="RowTab">
                          <td className="Rowcell">Start Date</td>
                          <td className="Rowcell">End Date</td>
                          <td className="Rowcell">Start Time</td>
                          <td className="Rowcell">Type</td>
                          <td className="Rowcell">Description</td>
                        </thead>
                        <tbody>
                          {applicationData?.events && applicationData?.events.length > 0 ? applicationData?.events.map((row) => (
                            <tr key={row.index}>
                              <td className="Rowcells" component="th" scope="row">
                                {
                                  moment(row.startDate.slice(0, 10)).format("DD/MM/YYYY")}
                              </td>
                              <td className="Rowcells">{moment(row.endDate.slice(0, 10)).format("DD/MM/YYYY")}</td>
                              <td className="Rowcells">{
                                timeConvert(row.startTime)
                              }</td>
                              <td className="Rowcells">{row.type}</td>
                              <td className="Rowcells">{row.description}</td>
                            </tr>
                          )) :
                            (<tr>
                              <td colSpan={5}>No Events Found</td>
                            </tr>)
                          }
                        </tbody>
                      </table>
                    </div>
                    {addEvent ?
                      <div className="InputflexRow" style={{ marginTop: '15px' }}>
                        <DatePicker
                          id="start_date"
                          style={{ width: '70% !important' }}
                          selected={startDate}
                         
                          name="start_date"
                          className="editdateField"
                          popperPlacement='top-start'
                          autoComplete="off"
                          onChange={handleStart}
                          dateFormat="dd/MM/yyyy"
                        />
                        <DatePicker
                          id="end_date"
                          name="end_date"
                          selected={endDate}
                          
                          style={{ width: '70% !important' }}
                          className="editdateField"
                          popperPlacement='top-start'
                          autoComplete="off"
                          dateFormat="dd/MM/yyyy"
                          onChange={handleEnd}
                        />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <TimePicker
                            className="editentrytime"
                            name="start_time"
                            id="start_time"
                            autoOk
                            value={startTime}
                            dateFormat="dd/MM/yyyy"
                            onChange={handleTime}
                          />
                        </MuiPickersUtilsProvider>
                        <select
                          type="text"
                          className="editentryinputField"
                          name="type"
                          id="type"
                          onChange={handleEvent}
                          style={{ width: '70%', marginLeft: '1%', marginRight: '1%', height: '38px' }}
                        >
                          <option value="Build">Build</option>
                          <option value="Release">Release</option>
                          <option value="Promotion">Promotion</option>
                          <option value="Other">Other</option>
                        </select>
                        <textarea
                          type="text"
                          onChange={handleEvent}
                          className={eventError.description ? "editentryinputField errEdit" : "editentryinputField"}
                          name="description"
                          id="description"
                          style={{ width: '80%', height: '50px', marginLeft: '1%' }}
                        />
                        {eventError.description ?
                          (<p
                            className="errormsg" name="identifiererror">This field is required</p>)
                          : ""}
                      </div>

                      : null

                    }

                  </div>
                </TabPanel>
              </div>

            </div>
            {!loading && item?.hostingFlag === 'biNu' ?
              <div className="configDefinebtn" onClick={() => setConvertSelf(true)}>
                + Convert App to Self-Hosted Entry Point
              </div> : null}
          </div>
        ) : (
          <div>
            <div className="headline">
              Production Archive - biNu Hosted App Entry Point
            </div>
            {/* //production Achieve */}
            <div className="developmentTab">
              <div className="developTabContent">
                <div className="developTabContenthead">
                  biNu ID : <span>{item.deploymentId.appId}</span>
                </div>
                <div className="developTabContenthead">
                  Config ID :<span>{item.configId}</span>
                </div>
                <div className="developTabContenthead">
                  Name :<span>{item.name}</span>
                </div>
                <div className="developTabContenthead">
                  Last Build :<span></span>
                </div>

                <div className="developToolsTabContenthead">Tools:</div>
                <div className="subheadline">
                  Production APKs built prior to Self Hosting continue to use
                  this Entry point
                </div>
                <div className="subheadline">
                  You will need to maintain this biNu Hosted Entry Point to
                  update users who still have those versions installsed
                </div>
                <div className="subheadline">
                  Check Build History for prior version builds
                </div>
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <button className="workbench">
                    Launch biNu App XML Assistant{" "}
                  </button>
                </div>
              </div>
              <div className="developMaxTabContent">
                <AntTabs
                  value={value}
                
                  onChange={handleChange}
                  className="tabChange tabViewMenu"
                  TabIndicatorProps={{
                    style: {
                      display: "none",
                    },
                  }}
                >
                  <AntTab
                    label="Entry Point"
                    style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  />
                </AntTabs>

                <TabPanel value={value} index={0}>
                  {item?.hostingFlag === 'biNu' ?
                    <div className="developmentSecurityTab">
                      <button className='NotEdit' disabled="disabled">
                        Edit
                      </button>

                      <button className='NotEdit' disabled="disabled">
                        Undo All Edits
                      </button>

                      <button className='NotEdit' disabled="disabled">Save All Edits</button>
                    </div>
                    : <div className="developmentSecurityTab">
                      {applicationData?.editConfigDetails?.awsXmlNotFound === 'yes' ?
                        <button className='NotEdit'
                          disabled='disabled'>
                          Edit
                        </button> :
                        <button className={!item?.editInfo ? 'editbtn' : 'NotEdit'} onClick={() => edit(item?.configId, item?.deploymentId?.deploymentId)}
                        >
                          Edit
                        </button>}

                      <button className={item?.editInfo ? 'editbtn' : 'NotEdit'} onClick={() => handleClose(item?.configId, item?.deploymentId?.deploymentId,)}>
                        Cancel
                      </button>

                      <button className={item?.editInfo ? 'editbtn' : 'NotEdit'} onClick={() => entryPointSelfSave(item?.configId, item?.deploymentId?.deploymentId)}>Save</button>
                    </div>
                  }

                  <div className="InputflexRow">
                    <div className="entrypointLabel">
                      Entry Point Hosting{" "}
                    </div>
                    <input
                      type="text"
                      className="entryPointinputField"
                      name="start_url"
                      id="start_url"
                      disabled="disabled"
                      value={item?.hostingFlag}
                    />
                    <div className="flexSpace8"></div>

                    {item?.hostingFlag === 'biNu' ? <div className="entrypointLabel">XML Validated</div> : ""}
                    {item?.hostingFlag === 'biNu' ? item?.xmlValidated ? <span className="xmlvalidateStyle">
                      <FaTimes />
                    </span> : <span className="xmlvalidateStyle"> </span> : ""}

                  </div>
                  <input type="hidden" id="xmlStat"
                  ></input>

                  {item?.hostingFlag === 'biNu' ?
                    <div className="appnodeMainDiv">
                      <div className="xmlEditDiv">
                        <div className="marginBottomClass">
                          <label for="name" className="entrypointLabel">
                            File
                          </label>
                          <select className="filecustomSelect" disabled={item?.editInfo ? false : "disabled"} >
                            <option value="appnode">Appnode</option>
                            <option value="xmldoc">XML Doc</option>
                            <option value="Stylesheet">Stylesheet</option>
                          </select>
                          <label className="downarrow">
                            <FaLongArrowAltDown />
                          </label>

                          <div>
                            <textarea className="formgroup" id="appNodeXml" hidden="hidden"
                              style={{ color: 'red', display: 'block' }}
                              defaultValue={item?.xmlFile}
                              disabled={item?.editInfo ? false : "disabled"} ></textarea>

                            <textarea className="formgroup" id="docXml" hidden="hidden"
                              style={{ color: 'red', display: 'none' }} disabled={item?.editInfo ? false : "disabled"}
                              defaultValue={item?.xmlDocFile}
                            ></textarea>
                            <textarea className="formgroup" id="stylesheetFile" hidden="hidden"
                              style={{ color: 'red', display: 'none' }} disabled={item?.editInfo ? false : "disabled"}
                              defaultValue={item?.stylesheetFile}
                            >
                            </textarea>
                            {/* <textarea className="formgroup textareaField" value={applicationData?.editConfigDetails?.xmlFile} disabled="disabled" /> */}
                            <div className="clearfix">
                              <button className={item?.editInfo ? 'subtabButton btnStyle' : 'subtabButton'} color="primary" disabled="disabled">
                                Launch XML Assistant
                              </button>
                              <div className="flexSpace"></div>
                              <button className={item?.editInfo ? 'subtabButton btnStyle' : 'subtabButton'} color="primary" disabled="disabled">
                                Validate XML
                              </button>
                            </div>

                          </div>

                        </div>

                      </div>

                      <div className="xmlEditDiv">
                        <div id="xml_results_" style={{ color: 'red' }}>{validateDiv}</div>
                      </div>
                    </div>

                    : <div>
                      <div className="entrypointLabel" >
                        Entry Point URL
                      </div>
                      {!item?.editInfo ? <div> <input
                        type="text"
                        className="entyPointurlfield"
                        disabled="disabled"
                        value={item?.homeUrl}
                        style={{ marginLeft: "0px" }}
                      /></div> : <div> <input
                        type="text"
                        className={!error.entry_point_url ? "editentyPointurlfield" : "errEdit editentyPointurlfield"}
                        id="start_url"
                        name="start_url"
                        onChange={(e) => entryPointChangeHandler(e)}
                        style={{ marginLeft: "0px" }}
                      /></div>
                      }
                      {error.entry_point_url ? (
                        <span style={{ float: 'left', marginBottom: "4px" }} className="errorClass" >{error.webmsg}</span>
                      ) : (
                        ""
                      )}
                      <div className="launchurlInputfield" onClick={() => launchxmlUrl(item?.hostingFlag === "biNu" ? item?.homeUrl : item?.start_url)}>
                        + Launch XML Assistant
                      </div>
                      <input id="configInput" type="hidden"></input>
                    </div>

                  }

                </TabPanel>
              </div>
            </div>

          </div>
        )
      )
      ) : (
        !loading ?
          (<div className="configDefinebtn" onClick={() => createConfigCheck('body')}>
            + Create Production App Configuration and Define Entry Point
          </div>) : null
      )}

      <Dialog
        open={deleteopen}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="deletedialoguettitle">
          WARNING!
        </DialogTitle>
        <DialogContent>
          <div>
            Are you sure you want to delete this #datafree Website Registration
            ? This cannot be undone.
          </div>
        </DialogContent>
        <DialogActions>
          <div className="iviteRow">
            <button
              className="deletecancelbtn"
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </button>
            <div className="flexSpace"></div>
            <button className="deletecancelbtn" color="primary">
              Confirm Deletion
            </button>
          </div>
        </DialogActions>
      </Dialog>
      {/* //clone Config */}
      <Dialog
        open={open}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="dialogtitle">
          <div className="cmpdialogtitle">  Clone Development Config as template
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={() => appConfigOpenClick("body")} />
          </div>

        </DialogTitle>
        <DialogContentText className="DialogContentText">
          Do you want to clone a previously defined configuration?
        </DialogContentText>
        <DialogActions>
          <Grid container>
            <Grid item xs={6}>
              <button
                className="inviteButton"
                style={{ width: "90%" }}
                color="primary"
                onClick={openModalCloneConfig}
              >
                YES
              </button>
            </Grid>
            <Grid item xs={6}>
              <button
                className="inviteButton"
                style={{ width: "90%" }}
                color="primary"
                onClick={openModalNewConfig}
              >
                NO
              </button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {/* //cloneApp define config form */}
      <Dialog
        open={configCreateopen}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="dialogtitle">
          <div className="cmpdialogtitle">    Clone App Config and Entry Point
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={closeModalCloneConfig} />
          </div>

        </DialogTitle>
        <DialogContentText className="DialogContentText">
          <div className="dialogdiv">
            <div className="cmpdialoginputtext">
              {" "}
              Choose Development Configuration to Clone
            </div>
            <div className="cmpdialoginputtext">
              Select Development Configuration
            </div>
            <select
              type="text"
              id="dev_configs"
              name="dev_configs"
              onChange={(e) => addYes(e)}
              className={yesError.dev_configs ? "dialoginput errEdit" : "dialoginput"}

              fullWidth
            >
              <option value=""></option>
              {configDetail && configDetail.length > 0
                ? configDetail.map((post) => (
                  <option key={post.configId} value={post.configId}>
                    {post.name}
                  </option>
                ))
                : null}
            </select>
            {yesError.dev_configs ?
              (<p
                className="errorClass marginTop" name="identifiererror">This field is required</p>)
              : ""}
            <div className="cmpdialoginputtext">New Name for Cloned Config</div>
            <input
              type="text"
              id="name"
              name="name"
              onChange={(e) => addYes(e)}
              className={yesError.name ? "cmpnydialoginput errEdit" : "cmpnydialoginput"}
              defaultValue={yesData.name}
              fullWidth
            />
            {yesError.name ?
              (<p
                className="errorClass marginTop" name="identifiererror">This field is required</p>)
              : ""}
            <div className="cmpdialoginputtext">
              New Description for Cloned Config
            </div>
            <input
              type="text"
              id="description"
              name="description"
              onChange={(e) => addYes(e)}
              className={yesError.description ? "cmpnydialoginput errEdit" : "cmpnydialoginput"}
              defaultValue={yesData.description}
              fullWidth
            />
            {yesError.description ?
              (<p
                className="errorClass marginTop" name="identifiererror">This field is required</p>)
              : ""}
          </div>
        </DialogContentText>
        <DialogActions>
          <div className="ivitediv">
            <button
              className="inviteButton"
              color="primary"
              onClick={closeModalCloneConfig}
            >
              Cancel
            </button>

            <button
              className="inviteButton"
              color="primary"
              onClick={createCloneConfig}
            >
              Save and Return
            </button>
          </div>
        </DialogActions>
      </Dialog>
      {/* //Create App Config Create Open */}
      <Dialog
        open={appConfig}
        scroll={scroll}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="dialogtitle">

          <div className="cmpdialogtitle"> Create App Config and Entry Point
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={() => setAppConfig(false)} />
          </div>
        </DialogTitle>
        <DialogContentText className="DialogContentText">
          <div className="dialogdiv">
            <div className="textBoldHead">
              App Configuration Options
            </div>
            <div className="textcls">Name</div>
            <input
              type="text"
              id="name"
              name="name"
              onChange={(e) => addNo(e)}
              className={noError.name ? "cmpnydialoginput errEdit" : "cmpnydialoginput"}
              fullWidth
            />
            {noError.name ?
              (<p
                className="errorClass marginTop" name="identifiererror">This field is required</p>)
              : ""}
            <div className="textcls">Description</div>
            <textarea
              type="text"
              id="description"
              name="description"
              onChange={(e) => addNo(e)}
              className={noError.description ? "cmpnydialoginput errEdit" : "cmpnydialoginput"}
              fullWidth
            />
            {noError.description ?
              (<p
                className="errorClass marginTop" name="identifiererror">This field is required</p>)
              : ""}
            <div className=" textcls">
              biNu Platform
            </div>
            <select
              type="text"
              id="platform"
              name="platform"
              onChange={(e) => addNo(e)}
              className={noError.platform ? "cmpnydialoginput errEdit" : "cmpnydialoginput"}
              style={{ width: '99%', height: "35px" }}
              fullWidth

            >
              <option value=""></option>
              {platformData && platformData.length > 0
                ? platformData.map((post) => (
                  <option key={post.id} value={post.id}>
                    {post.name}
                  </option>
                ))
                : null}
            </select>
            {noError.platform ?
              (<p
                className="errorClass marginTop" name="identifiererror">This field is required</p>)
              : ""}
            <div className="textcls">Google App Id</div>
            <input
              type="text"

              disabled="disabled"
              className="cmpnydialoginput inputDisabled"
              fullWidth
              value={applicationData?.googleAppId}
            />

            <div className="textcls">
              Package Suffix
            </div>
            <input
              type="text"
              id="package_suffix"
              name="package_suffix"
              onChange={(e) => addNo(e)}
              className={noError.package_suffix ? "cmpnydialoginput errEdit" : "cmpnydialoginput"}
              fullWidth
            />
            {noError.package_suffix ?
              (<p
                className="errorClass marginTop" name="identifiererror">{noError.packageMsg}</p>)
              : ""}
            <div className=" textcls">
              Distribution Email
            </div>
            <textarea
              type="text"
              id="distribution_email"
              name="distribution_email"
              onChange={(e) => addNo(e)}
              className={noError.distribution_email ? "cmpnydialoginput errEdit" : "cmpnydialoginput"}
              fullWidth
            />
            {noError.distribution_email ?
              (<p
                className="errorClass marginTop" name="identifiererror">{noError.emailMsg}</p>)
              : ""}
            <div className="textcls">Include SDKs</div>
            <div className="">
              <input
                type="checkbox"
                name="websiteName"
                id="websiteName"
                checked={effectiveChecked}
                onChange={(e) => setEffectiveChecked(!effectiveChecked)}
              />
              <label for="websiteName" className="optiontext textcls">
                Effective Measure Tracker
              </label></div>

            <div >
              <input
                type="checkbox"
                name="websiteName"
                id="websiteName"
                checked={vidAdChecked}
                onChange={(e) => setVideoAdChecked(!vidAdChecked)}
              />
              <label for="websiteName" className="optiontext textcls">
                Video Advertising
              </label></div>
            <div>
              <label for="websiteName" className="optiontext textcls">
                Include Developer Menu
              </label>
              <input
                type="checkbox"
                name="include_developer_menu"
                id="include_developer_menu"
                checked={developerMenu}
                onChange={(e) => addNo(e)}

              />
            </div>
            <div className="textBoldHead">
              Look and Feel

            </div>

            <div className="textcls">
              Onboarding URL
            </div>
            <input
              type="text"
              id="onboarding_url"
              name="onboarding_url"
              onChange={(e) => addNo(e)}
              className={noError.onboarding_url ? "cmpnydialoginput errEdit" : "cmpnydialoginput"}
              fullWidth
            />
            {noError.onboarding_url ?
              (<p
                className="errorClass marginTop" name="identifiererror">{noError.urlMsg}</p>)
              : ""}
            {colorchecked ? <div>
              <div className="registerFormTitle  textcls">Status Bar Colour</div>
              <div>
                <input
                  type="text"
                  className={noError.status_manual ? "cmpnydialoginput errEdit" : "cmpnydialoginput"}
                  placeholder=""
                  name="maxByteContent"
                  id="maxByteContent"
                />
              </div>

              {noError.status_manual ?
                (<p
                  className="errorClass marginTop" name="identifiererror" style={{ marginLeft: '10px' }}>{noError.statusMsg}</p>)
                : ""}
            </div> : ""}

            <div className="flexrow backGrndCOLOR">
              {!colorchecked ? <div className="registerFormTitle textcls">Status Bar Colour</div> : ""}

              {!colorchecked ? <div className="colorPickerDiv">
                <input
                  type="color"
                  className="colorBox"
                  style={{ cursor: "pointer" }}
                  id="status_bar_color"
                  name="status_bar_color"
                  // value={color}
                  onChange={(e) => addNo(e)}
                  defaultValue="#303f9f"
                />
                <input
                  type="text"
                  className="colorInput"
                  disabled="disabled"
                  value={configStatus ? configStatus : "#303f9f"}
                />
              </div> : ""}
              <div className="spanFormTitle textcls">Click here to Enter Manually</div>
              <div>
                <input
                  type="checkbox"
                  className="spancheck"
                  name="websiteName"
                  id="websiteName"
                  onChange={() => setColorchecked(!colorchecked)}
                  checked={colorchecked}
                />
              </div></div>
            {/* //Action Color */}

            {actioncolorchecked ? <div>
              <div className="registerFormTitle textcls">Action Bar Colour</div>
              <div>
                <input
                  type="text"
                  className={noError.header_manual ? "cmpnydialoginput errEdit" : "cmpnydialoginput"}
                  placeholder=""
                  name="maxByteContent"
                  id="maxByteContent"
                />
              </div>
              {noError.header_manual ?
                (<p
                  className="errorClass marginTop" name="identifiererror" style={{ marginLeft: '10px' }}>{noError.headerMsg}</p>)
                : ""}
            </div> : ""}
            <div className="flexrow backGrndCOLOR">
              {!actioncolorchecked ? <div className="registerFormTitle textcls">Action Bar Colour</div> : ""}

              {!actioncolorchecked ? <div className="colorPickerDiv">
                <input
                  type="color"
                  className="colorBox"
                  style={{ cursor: "pointer" }}
                  id="header_color"
                  name="header_color"
                  defaultValue="#3f51b5"
                  onChange={(e) => addNo(e)}


                />
                <input
                  type="text"
                  className="colorInput"
                  disabled="disabled"
                  value={configAction ? configAction : "#3f51b5"}
                />
              </div> : ""}
              <div className="spanFormTitle textcls">Click here to Enter Manually</div>
              <div>
                <input
                  type="checkbox"
                  className="spancheck"
                  name="websiteName"
                  id="websiteName"
                  onChange={() => setActionColorchecked(!actioncolorchecked)}
                  checked={actioncolorchecked}
                />
              </div></div>
            {/* Accent Color */}
            {accentcolorchecked ? <div>
              <div className="registerFormTitle textcls">Accent Colour</div>
              <div>
                <input
                  type="text"
                  className={noError.accent_manual ? "cmpnydialoginput errEdit" : "cmpnydialoginput"}

                  placeholder=""

                />
              </div>
              {noError.accent_manual ?
                (<p
                  className="errorClass marginTop" name="identifiererror" style={{ marginLeft: '10px' }}>{noError.accentMsg}</p>)
                : ""}
            </div> : ""}
            <div className="flexrow backGrndCOLOR">
              {!accentcolorchecked ? <div className="registerFormTitle textcls">Accent Colour</div> : ""}

              {!accentcolorchecked ? <div className="colorPickerDiv">
                <input
                  type="color"
                  className="colorBox"
                  style={{ cursor: "pointer" }}
                  id="accent_color"
                  name="accent_color"
                  defaultValue="#ff4081"
                  onChange={(e) => addNo(e)}


                />
                <input
                  type="text"
                  className="colorInput"
                  disabled="disabled"
                  value={configAccent ? configAccent : "#ff4081"}
                />
              </div> : ""}
              <div className="spanFormTitle textcls">Click here to Enter Manually</div>
              <div>
                <input
                  type="checkbox"
                  className="spancheck"
                  name="websiteName"
                  id="websiteName"
                  onChange={() => setAccentColorchecked(!accentcolorchecked)}
                  checked={accentcolorchecked}
                />
              </div></div>
            <div className=" textcls">
              Use Dark Action Bar Icons
            </div>
            <select
              type="text"
              id="use_dark_action_bar_icons"
              name="use_dark_action_bar_icons"
              onChange={(e) => addNo(e)}
              className="dialoginput"
              fullWidth
            >
              <option value=""></option>
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
            <div className="textBoldHead">
              App Entry Point, Structure

            </div>
            <div style={{ display: "flex" }}>
              <div className="registerFormTitle textcls">Entry Point Hosting </div>
              <div><select
                type="text"
                id="entry_point_hosting"
                name="entry_point_hosting"
                onChange={(e) => addNo(e)}
                className="dialoginput"
                fullWidth
              >
                <option value="Self">Self</option>
                <option value="biNu">biNu</option>

              </select></div>
            </div>
            {maxEntryUrl ? <div className="textcls">Entry Point URL</div> : null}
            {maxEntryUrl ? <input
              type="text"
              id="start_url"
              name="start_url"
              onChange={(e) => addNo(e)}
              className={noError.website_url ? "cmpnydialoginput errEdit" : "cmpnydialoginput"}
              fullWidth
            /> : null}
            {maxEntryUrl ? noError.website_url ?
              (<p
                className="errorClass marginTop" name="identifiererror">{noError.webMsg}</p>)
              : "" : null}
          </div>


        </DialogContentText>
        <DialogActions>
          {loading ? <div className="loaderContainer"><Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="ivitediv">
            <button
              className="inviteButton"
              color="primary"
              onClick={popUpClose}
            >
              Cancel
            </button>

            <button
              className="inviteButton"
              color="primary"
              onClick={noSave}
            >
              Save and Return
            </button>
          </div>
        </DialogActions>
      </Dialog>

      {/* //Warning Popup */}

      <Dialog open={warningMsg} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" name="popup" onClick={() => setWarningMsg(false)} aria-labelledby="form-dialog-title">

        <DialogContent>

          <div className="logoConfirmtext">For security purposes it is advised that the given Entry Point URL is an https address.
          </div>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv" style={{ textAlign: 'center' }}>
            <button className="editbtn" name="popup" onClick={() => setWarningMsg(false)} color="primary">
              Continue
            </button>


          </div>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={successopen}
        autoHideDuration={2500}
        onClose={handlesuccessAlert}
      >
        <Alert onClose={handlesuccessAlert} severity="success">
          "Updated Successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={erroropen}
        autoHideDuration={2500}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          An Error Occured!
        </Alert>
      </Snackbar>


      {/* //    edit Not Validated Popup   */}
      <Dialog open={editValMsg} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" name="popup" onClick={() => setEditValMsg(false)} aria-labelledby="form-dialog-title">

        <DialogContent>

          <div className="logoConfirmtext">Edits Not Validated
          </div>
          <div className="logoConfirmtext">Validate XML Prior to Saving
          </div>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv" style={{ textAlign: 'center' }}>
            <button className="editbtn" name="popup" onClick={() => setEditValMsg(false)} color="primary">
              OK
            </button>


          </div>
        </DialogActions>
      </Dialog>

      {/* //warning for Entrypoint Invalid */}
      <Dialog open={hasNoBuild} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="connectTitle">

          <div className="headXml"> Warning ! Entry Point Invalid.
          </div>

          <div style={{ lineHeight: '2.6 !important' }}>{" "}</div>
        </DialogTitle>
        <DialogContent className="diaContent">
          <div className="logoConfirmtext" style={{ color: '#939598' }}>
            <p> Your Entry Point XML is invalid. If you choose to continue, any Generated APK will likely NOT
              function as expected.</p>

            <p> Cancel if you wish to further edit the Entry Point XML and re-validate. You can also use the
              XML Assistant to validate the Entry Point XML
            </p>
            <input type="hidden" id="bucketid" value=""></input>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" name="popup" color="primary"
              style={{ marginRight: '10%', width: '35%' }}
              onClick={() => setHasNoBuild(false)}>
              Cancel
            </button>
            <button className="popupOk" name="popup" color="primary"
              style={{ width: '35%' }} onClick={continueBucket}
            >
              Continue
            </button>
          </div>
        </DialogActions>
      </Dialog>


      {/* //warning for build */}
      <Dialog open={hasBuild} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="connectTitle">

          <div className="headXml"> Warning ! New Entry Point XML being Saved.
          </div>

          <div style={{ lineHeight: '2.6 !important' }}>{" "}</div>
        </DialogTitle>
        <DialogContent className="diaContent">
          <div className="logoConfirmtext" style={{ color: '#939598' }}>
            <p> If you choose to Continue, any APKs using this Entry Point will immediately be impacted by the changes including End Users if this is a Production Deployment</p>


            <input type="hidden" id="bucketid" value=""></input>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" name="popup" color="primary"
              style={{ marginRight: '10%', width: '35%' }}
              onClick={() => setHasBuild(false)}>
              Cancel
            </button>
            <button className="popupOk" name="popup" color="primary"
              style={{ width: '35%' }} onClick={continueBucket}
            >
              Continue
            </button>
          </div>
        </DialogActions>
      </Dialog>
      {/* //close Dialog */}
      <Dialog open={entryXml} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }}
        aria-labelledby="form-dialog-title">

        <DialogContent className="diaContent">
          <div className="logoConfirmtext">New Entry Point XML Saved.</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" name="popup" color="primary" onClick={() => setEntryXml(false)}>
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>

      {/** Build APK Model */}
      <Dialog
        open={mdbuildapk}
        scroll={scroll}
        classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" onClose={closeModalBuildApk} aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="dialogtitle connectTitle">
          <div className="cmpdialogtitle"> Build APK
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={closeModalBuildApk} />
          </div>
          <div style={{ lineHeight: '2.6 !important' }}>{" "}</div>
        </DialogTitle>
        <DialogContent>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label style={{fontSize:'14px'}}>biNu Id :</label>
            <input className="newconfiginput" type="text" disabled={true}
              value={BuildApkData?.obj?.binuAppId}
              style={{ cursor: 'not-allowed' }} />
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label style={{fontSize:'14px'}}>Server Entry Point Config</label>
            <input className="newconfiginput" type="text" disabled={true} value={BuildApkData?.obj?.configName}
              style={{ cursor: 'not-allowed' }} />
          </div>

          <div style={{ margin: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <label style={{ marginRight: '22px',fontSize: '14px'  }}>Entry Point Hosting</label>
            <input className="wrapInputs" type="text" disabled={true} value={BuildApkData?.obj?.entryPointHosting} />
          </div>
          <div style={{ margin: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '-17px' }}>
            <label style={{ marginRight: '20px',fontSize: '14px'  }}>Onboarding URL</label>
            <input className="wrapInputs" type="text" disabled={true} value={BuildApkData?.obj?.onBoardingUrl} />
          </div>
          <div style={{ margin: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '-17px' }}>
            <label style={{ marginRight: '20px',fontSize: '14px'  }}>biNu Platform</label>
            <input className="wrapInputs" type="text" disabled={true} value={BuildApkData?.obj?.binuName} />
          </div>
          <div style={{ margin: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '-17px' }}>
            <label style={{ marginRight: '20px',fontSize: '14px'  }}>Name</label>
            <input className="wrapInputs" type="text" disabled={true} value={BuildApkData?.obj?.packageSuffix} />
          </div>
          <div style={{ margin: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '-17px' }}>
            <label style={{ marginRight: '20px',fontSize: '14px'  }}>Value</label>
            <input className="wrapInputs" type="text" disabled={true} value={BuildApkData?.obj?.reachGateway} />
          </div>

          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label style={{fontSize:'14px'}}>Version Code</label>
            <input
              className={!ApkError.version_code ? "newconfig" : "newconfigerr"}
              type="text"
              name="version_code" id="version_code"
              defaultValue={BuildApkData?.obj?.versionCode}
              onChange={handleApkEdit} />
            {ApkError.version_code ? (
              <span style={{ float: 'left' }} className="errormsg topAlign">{ApkError.versionMsg}</span>
            ) : (
              ""
            )}
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label style={{fontSize:'14px'}}>Version Name</label>
            <input
              className={!ApkError.version_name ? "newconfig" : "newconfigerr"}
              type="text"
              name="version_name" id="version_name"
              defaultValue={BuildApkData?.obj?.versionName}
              onChange={handleApkEdit} />
            {ApkError.version_name ? (
              <span style={{ float: 'left' }} className="errormsg topAlign">This field is required</span>
            ) : (
              ""
            )}
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label style={{fontSize:'14px'}}>Comments</label>
            <input
              className={!ApkError.comments ? "newconfig" : "newconfigerr"}
              type="text"
              name="comments" id="comments"
              defaultValue={BuildApkData?.obj?.comment}
              onChange={handleApkEdit} />
            {ApkError.comments ? (
              <span style={{ float: 'left' }} className="errormsg topAlign">This field is required</span>
            ) : (
              ""
            )}
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label style={{ fontSize: '14px' }}>On completion of the APK generation, an email will be sent to any valid addresses noted in the Email Distribution List</label>
          </div>
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label style={{fontSize:'14px'}}>Email Distribution</label>
            <input
              className={!ApkError.email_distribution ? "newconfig" : "newconfigerr"}
              type="text" name="email_distribution"
              id="email_distribution"
              defaultValue={BuildApkData?.obj?.distributionEmail}
              onChange={handleApkEdit} />

            {ApkError.email_distribution ? (
              <span style={{ float: 'left' }} className="errormsg topAlign">{ApkError.emailMsg}</span>
            ) : (
              ""
            )}
          </div>
          {BuildApkData?.userType === "INTERNAL" ? (
            <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
              <label style={{fontSize:'14px'}}>Build Type</label>
              <select
                className={!ApkError.buildTypes ? "newconfig" : "newconfigerr"}
                type="text"
                style={{ height: '35px' }}
                name="buildTypes" id="buildTypes" onChange={handleApkEdit}>
                <option value="">Select</option>
                {BuildPopup && BuildPopup.length > 0
                  ? BuildPopup.map((post) => (
                    <option key={post.id} value={post.id}>
                      {post.name}
                    </option>
                  ))
                  : null}
              </select>

              {ApkError.buildTypes ? (
                <span style={{ float: 'left' }} className="errormsg topAlign">This field is required</span>
              ) : (
                ""
              )}
            </div>
          ) : null}
          <div style={{ margin: '10px', flexDirection: 'column', display: 'flex' }}>
            <label style={{fontSize:'14px'}}>Package Suffix Override</label>
            <input className="newconfig" type="text"
              name="package_suffix"
              id="package_suffix"
              onChange={handleApkEdit}
              defaultValue={BuildApkData?.obj?.packageSuffix} />
          </div>
        </DialogContent>

        <DialogActions>
          {loading ? <div className="loaderContainer"><Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div style={{ margin: '10px', flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ margin: '10px' }}>
              <button
                className="BuildBtn"
                onClick={closeModalBuildApk}
              >
                Cancel
              </button>
            </div>
            <div style={{ margin: '10px', height: '30px' }}>
              <button className="BuildBtn"
                onClick={BuildSave}>
                Build
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openBuild}
        scroll={scroll}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        maxWidth="lg"
        onClose={handleAppClose}
        aria-labelledby="form-dialog-title">

        <DialogTitle id="form-dialog-title" className="dialogtitle connectTitle">

          <div className="cmpdialogtitle"> Build History
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={openBuildClose} />
          </div>

          <div style={{ lineHeight: '2.6 !important' }}>{" "}</div>
        </DialogTitle>
        <DialogContent>

          <div style={{
            flexDirection: 'row', display: 'flex', justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <div>
              <p style={{
                fontWeight: 'normal',
              }}>
                biNu ID :- {applicationData.applicationId}
              </p>
            </div>
            <div>
              <p style={{
                fontWeight: 'normal',
              }}>

              </p>
            </div>
            <div>
              <label>
                Show Builds for all Configs
                <input type="checkbox"
                  checked={configCheck}
                  onChange={(e) => showBuilds(e)}
                />
              </label>
            </div>

          </div>
          <input type="hidden" id="hideValue" value={configIdData}></input>
          <Grid container spacing={1} >
            <Grid item xs={1}>
              <div className="dialoginputtext" style={{ fontWeight: '400' }}>Build Id</div>

            </Grid>

            <Grid item xs={2}>
              <div className="dialoginputtext" style={{ fontWeight: '400' }}>Date</div>

            </Grid>
            <Grid item xs={2}>
              <div className="dialoginputtext" style={{ fontWeight: '400' }}>Base Config Name</div>

            </Grid>
            <Grid item xs={1}>
              <div className="dialoginputtext" style={{ fontWeight: '400' }}>Version Code</div>
            </Grid>
            <Grid item xs={1}>
              <div className="dialoginputtext" style={{ fontWeight: '400' }}>Version Name</div>
            </Grid>
            <Grid item xs={1}>
              <div className="dialoginputtext" style={{ fontWeight: '400' }}>Binu Version</div>

            </Grid>

            <Grid item xs={1}>
              <div className="dialoginputtext" style={{ fontWeight: '400' }}>Status</div>

            </Grid>
          </Grid>

          {BuildDetails && BuildDetails.length > 0 ?
            BuildDetails.map((apk, index) => (

              <Grid container spacing={1} style={{ borderTop: '1px solid #e5e5e5' }} key={index}>
                <Grid item xs={1}>
                  <div className="dialoginputtext">{apk.id}</div>
                </Grid>
                <Grid item xs={2}>
                  <div className="dialoginputtext">{apk.created + " GMT"}</div>
                </Grid>
                <Grid item xs={2}>
                  <div className="dialoginputtext">{apk.configName}</div>
                </Grid>
                <Grid item xs={1}>
                  <div className="dialoginputtext" >{apk.versionCode}</div>
                </Grid>
                <Grid item xs={1}>
                  <div className="dialoginputtext">{apk.versionName}</div>
                </Grid>
                <Grid item xs={1}>
                  <div className="dialoginputtext" style={{ textAlign: 'center' }}>{apk.tag}</div>
                </Grid>
                <Grid item xs={1}>
                  <div className="dialoginputtext">{apk.status}</div>
                </Grid>
                <Grid item xs={2}>
                  <div className="dialoginputtext">

                    {apk.buildType === "NORMAL" ?
                      apk.downloadApk !== null ?

                        (
                          <a className="anchDownload"
                            onClick={() => showApk(apk.id)}
                          >Download</a>
                        ) : "Download" : null}

                    {apk.buildType !== "NORMAL" ?
                      apk.downloadApk !== null ?
                        (
                          <a className="anchDownload"
                            onClick={() => showApkDebug(index, apk.id)}
                          >Download Apk</a>
                        ) : "Download Apk"

                      : null}
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div className="dialoginputtext">
                    <a style={{ cursor: 'pointer' }} onClick={() => GetInfo(apk.id)}>More Info</a>
                  </div>
                </Grid>


                <div id={"debugDownload" + apk.id} style={{ display: 'none' }}>
                  {apk && apk.downloadApk?.apkUrlJson?.map((uls, index) => (

                    <Grid container>
                      <div className="dialoginputtext" style={{ marginBottom: '20px' }}>

                        <label className="downstyle">
                          <a className="anchDownload"
                            href={API_URL + apk.key + uls.url}
                            download
                          >{"Download " + uls.channel + "  Apk"}</a>
                        </label>
                      </div>
                    </Grid>

                  ))

                  }
                </div>

                <div aria-label="simple table" style={{ display: 'none', fontSize:'14px' }} name="more_info" id={"Moreinfo_" + apk.id}>

                  <span>
                    <b>Deployment Id : </b>
                    {MoreData?.deploymentId?.deploymentId ? MoreData?.deploymentId?.deploymentId : ""}
                  </span>
                  <br />
                  <br />
                  <span>
                    <b>Base Config Id : </b>
                    {MoreData?.baseConfigId?.configId ? MoreData?.baseConfigId?.configId : ""}
                  </span>
                  <br />
                  <br />
                  <span>
                    <b>Base Config Name : </b>
                    {MoreData?.baseConfigId?.name ? MoreData?.baseConfigId?.name : ""}
                  </span>
                  <br />
                  <br />
                  <span>
                    <b>Deployment Type : </b>
                    {MoreData?.deploymentType ? MoreData?.deploymentType : ""}
                  </span>
                  <br />
                  <br />
                  <span>
                    <b>Proxy Endpoint : </b>
                    {MoreData?.proxyEndpoint ? MoreData?.proxyEndpoint : ""}
                  </span>
                  <br />
                  <br />
                  <span>
                    <b>Google App Id : </b>
                    {MoreData?.googleAppId ? MoreData?.googleAppId : ""}
                  </span>
                  <br />
                  <br />
                  <span>
                    <b>Distribution Email  : </b>
                    {MoreData?.distributionEmail ? MoreData?.distributionEmail : ""}
                  </span>
                  <br />
                  <br />
                  <span>
                    <b>Build Type : </b>
                    {MoreData?.buildType ? MoreData?.buildType : ""}
                  </span>
                  <br />
                  <br />
                  <span>
                    <b>Status Datetime : </b>
                    {MoreData?.statusDatetime ? MoreData?.statusDatetime : ""}
                  </span>
                  <br />
                  <br />
                  <span>
                    <b>Comments : </b>
                    {MoreData?.comment ? MoreData?.comment : ""}
                  </span>
                  <br />
                  <br />
                  <span>
                    <b>Version Tag : </b>
                    {MoreData?.versionTag ? MoreData?.versionTag : ""}
                  </span>
                  <br />
                  <br />
                  <span><b>App Attributes</b> <br />
                    {AppAttributes ? AppAttributes.map((att) => (
                      <div key={att}>
                        <br /><label>{att}</label><br />

                      </div>

                    )) : null}

                  </span>
                  <span><b>Channels :</b>
                    {MoreData?.channels ? MoreData?.channels : ""}
                  </span>
                  <br />
                  <br />
                  <span><b>Generated By :</b>
                    {MoreData?.userId?.name ? MoreData?.userId?.name : ""}
                  </span>
                  <br />
                  <br />
                  <span><b>Created On :</b>
                    {MoreData?.userId?.created ? MoreData?.userId?.created : ""}
                  </span>
                </div>

              </Grid>


            ))
            : null}

        </DialogContent>
        <DialogActions>
          <div className="ivitediv">
            <button className="inviteButton" onClick={openBuildClose} color="primary">
              Return
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog open={hasNoBuildSelf} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="connectTitle">

          <div className="headXml">Warning ! Entry Point URL has changed.
          </div>

          <div style={{ lineHeight: '2.6 !important' }}>{" "}</div>
        </DialogTitle>
        <DialogContent className="diaContent">
          <div className="logoConfirmtext" style={{ color: '#939598' }}>
            You can use the XML Assistant if you wish to validate the Entry Point before Saving


            <input type="hidden" id="bucketid" value=""></input>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" name="popup" color="primary"
              style={{ marginRight: '10%', width: '35%' }}
              onClick={() => setHasNoBuildSelf(false)}>
              Cancel
            </button>
            <button className="popupOk" name="popup" color="primary"
              style={{ width: '35%' }} onClick={continueSelfEdit}
            >
              Continue
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog open={hasBuildSelf} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="connectTitle">

          <div className="headXml"> Warning ! Entry Point URL has changed.
          </div>

          <div style={{ lineHeight: '2.6 !important' }}>{" "}</div>
        </DialogTitle>
        <DialogContent className="diaContent">
          <div className="logoConfirmtext" style={{ color: '#939598' }}>
            <p> You can use the XML Assistant if you wish to validate the Entry Point before Saving</p>
            <div className="headXml"> Warning ! Entry Point URL has changed.
            </div>
            <p>New APK will be required for Entry Point URL to come into effect. Previous APKs will continue to reference old Entry Point URL</p>

          </div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" name="popup" color="primary"
              style={{ marginRight: '10%', width: '35%' }}
              onClick={() => setHasBuildSelf(false)}>
              Cancel
            </button>
            <button className="popupOk" name="popup" color="primary"
              style={{ width: '35%' }} onClick={continueSelfEdit}
            >
              Continue
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog open={apkList} classes={{ scrollPaper: classes.scrollPaper }}
        scroll={scroll}
        maxWidth="sm"
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="dialogtitle connectTitle">
          <div className="cmpdialogtitle">Click to Download</div>
          <div style={{ lineHeight: '2.6 !important' }}>{" "}</div>
        </DialogTitle>
        <DialogContent>
          {downloadApks?.map((ul, index) => (
            <Grid Xs={12} >
              <Grid item xs={12}>
                <div className="dialoginputtext" style={{ marginBottom: '20px' }}>

                  <label className="downstyle">{"Download " + ul.channel + "  Apk"}</label>
                  <span style={{ marginLeft: '4%' }}>
                    <a style={{ color: 'black' }}
                      href={API_URL + downloadApksKey + ul.url} download><FaDownload /></a>
                  </span>
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="dialoginputtext" style={{ marginBottom: '20px' }}>

                  <label className="downstyle">{"Download " + ul.channel + "  Aab"}</label>
                  <span style={{ marginLeft: '4%' }}>
                    <a style={{ color: 'black' }}
                      href={API_URL + downloadApksKey + ul.aabUrl} download><FaDownload /></a>
                  </span>
                </div>
              </Grid>
            </Grid>
          ))}
        </DialogContent>
        <DialogActions>
          <div className="ivitediv">
            <button className="inviteButtonCancel" onClick={() => setApkList(false)} color="primary">
              Cancel
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog open={undoEdits} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }}
        aria-labelledby="form-dialog-title">

        <DialogContent className="diaContent">
          <div className="logoConfirmtext">Undo Edits Complete.</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" name="popup" color="primary" onClick={() => setUndoEdits(false)}>
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>
      {/* //=================================== convertAppself =============================*/}
      <Dialog open={convertSelf} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle connectTitle">

          <div className="cmpdialogtitle"> Convert Entry-Point to Self-Hosted
            <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={() => setConvertSelf(false)} />
          </div>

          <div style={{ lineHeight: '2.6 !important' }}>{" "}</div>
        </DialogTitle>
        <DialogContent className="diaContent">
          <div>
            <div className="selfModalText">Warning ! This action cannot be reverted.</div>
            <div className="selfModalText">Creation of this Entry Point to Self Hosted will require revalidation of the new Entry Point XML and generation of a new APK for it to come into effect.</div>
            <div className="selfModalText">Access to the current biNu Hosted Entry Point XML will be available for maintenance purposes</div>
            <div className="selfModalText">Enter the URL of the Self Hosted Entry Point
              <div className="selfModalText">
                <input className={selfErr?.entrypoint ? "errcmpnydialoginput" : "cmpnydialoginput"} type="text" id="entryPointUrl" onChange={(e) => selfChange(e)} />
                {selfErr?.entrypoint ? <a className="errorClass">{selfErr?.entrypoint ? "This field is required" : ""}</a> : null}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv">
            <button className="inviteButton" name="popup" color="primary"
              style={{ width: '15%' }}
              onClick={() => setConvertSelf(false)}>
              Cancel
            </button>
            <button className="inviteButton" name="popup" color="primary" style={{ width: '15%' }}
              onClick={converAppSelf}
            >
              Save
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default MaxProduction;
