import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Dialog,
  Box,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  withStyles,
  Grid,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { TimePicker } from "@material-ui/pickers";
import moment from "moment";
import { FaTimes } from "react-icons/fa";
import DatePicker from "react-datepicker";
import service from "../../../../../api/service";
import { trackPromise } from "react-promise-tracker";

import Loader from "react-loading";
const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});
const AntTabs = Tabs;
function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    borderBottom: "1px solid #ddd ",
    minWidth: "80px",

    textShadow: "none",
    color: "#0059b6",

    "&$selected": {
      color: "#555",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderBottomColor: "transparent",
      borderBottom: "none",
      cursor: "default",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);


function SwitchProduction(props) {
  const classes = useStyles();
  const [deleteopen, setDeleteOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [editProd, setEditProd] = useState(false);
  const [applicationData, setApplicationData] = useState(false);
  const handleCloseSecurity = () => {
    setEditProd(false);
    setSecurityEdit({
      monthly_data: "",
      alert_email: "",
      include_daily_dataFree_limit: "",
      max_daily_spend: "",
      action_required: "",
      max_bytes_content: "",
      content_type: "",
      max_bytes_per_content_hidden: "",
      max_bytes_per_content_changed: "",
      application_id: props.applicationId
    })

    setSecurityError({
      monthly_data: false,
      alert_email: false,
      include_daily_dataFree_limit: false,
      max_daily_spend: false,
      action_required: false,
      max_bytes_content: false,
      max_bytes_per_content_hidden: false,
      max_bytes_per_content_changed: false,
      content_type: false,
      contentMsg: "",
      mimeMsg: "",
      monthlyMsg: "",
      emailMsg: "",
      typeMsg: "",
      dailyMsg: "",
      sysMsg: "",
      production: false,
    });


    getApplicationDetails();
  }
  const handleClose = () => {
    setEditProd(false);
    setDeleteOpen(false);
     setEventNew({
    start_date : moment(new Date()).format("DD/MM/YYYY"),
    end_date : moment(new Date()).format("DD/MM/YYYY"),
    start_time : moment(new Date()).format("hh:mm A"),
    type: "Build",
    description: "",
    application_id: props.applicationId,
    });
    getApplicationDetails();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const edit = () => {
    setEditProd(true);
  };
  const [DomainMap, setDomainMap] = useState([
    {
      domain_map_source: "",
      domain_map_target: "",
      domain_map_https: "",
      domain_map_redirect: "",
    }
  ]);
  const [DomainError, setDomainError] = useState([{
    domain_map_source: false,
    domain_map_target: false,
    sourcemsg: "",
    targetmsg: "",
  }]);

  const [securityEdit, setSecurityEdit] = useState({
    monthly_data: "",
    alert_email: "",
    include_daily_dataFree_limit: "",
    max_daily_spend: "",
    action_required: "",
    max_bytes_content: "",
    max_bytes_per_content_hidden: "",
    max_bytes_per_content_changed: "",
    content_type: "",
    application_id: props.applicationId

  })
  const [securityError, setSecurityError] = useState({
    monthly_data: false,
    alert_email: false,
    include_daily_dataFree_limit: false,
    max_daily_spend: false,
    action_required: false,
    max_bytes_content: false,
    max_bytes_per_content_hidden: false,
    max_bytes_per_content_changed: false,
    content_type: false,
    contentMsg: "",
    mimeMsg: "",
    monthlyMsg: "",
    emailMsg: "",
    typeMsg: "",
    dailyMsg: "",
    sysMsg: "",
    production: false,
  })
  const [useCheck, setUseCheck] = useState(false);
  const [securityEditss, setSecurityEditss] = useState({
    content_type: ""
  })
  const getApplicationDetails = () => {
    setLoading(true);
    try {
      trackPromise(
        service.applicationData(props.applicationId).then((res) => {
          setApplicationData(res.data.applicationObj);

          res?.data?.applicationObj?.prod?.securityRules?.blockedContent?.mime?.map((item, index) => (
            setSecurityEditss({ content_type: item ? item : "" })
          ));
          if (res?.data?.applicationObj?.appObjBinuSdkVersionId === "1") {


            setSecurityEdit({
              monthly_data: res.data.applicationObj?.prod?.applicationId?.appDataUsageAlert ? res.data.applicationObj?.prod?.applicationId?.appDataUsageAlert : "",
              alert_email: res.data.applicationObj?.prod?.applicationId?.alertEmail ? res.data.applicationObj?.prod?.applicationId?.alertEmail : "",
              action_required: res.data.applicationObj?.prod?.applicationId?.maxBytesPerDayAction,
              max_bytes_content: res.data.applicationObj?.prod?.securityRules?.contentSize ? res.data.applicationObj?.prod?.securityRules?.contentSize : "",
              max_daily_spend: res.data.applicationObj?.prod?.applicationId?.maxBytesPerDay ? res.data.applicationObj?.prod?.applicationId?.maxBytesPerDay : "",
              max_bytes_per_content_hidden: res.data.applicationObj?.prod?.securityRules?.contentSize ? res.data.applicationObj?.prod?.securityRules?.contentSize : "",
              max_bytes_per_content_changed: "no",
              content_type: securityEditss.content_type
            })
            // res?.data?.applicationObj?.prod?.securityRules?.blockedContent?.mime?.map((item, index) => {
            //   setSecurityEdit({...securityEdit, content_type: item ?  item : ""})

            // });
          }

          if (res?.data?.applicationObj?.appObjBinuSdkVersionId === "1" && res?.data?.applicationObj?.appObjReachGateway === null) {

            res?.data?.applicationObj?.prod?.securityRules?.domainMap.map((v, index) => {
              if (index === 0) {
                setDomainMap(
                  [{
                    domain_map_source: v.source ? v.source : "",
                    domain_map_target: v.target ? v.target : "",
                    domain_map_https: v.httpsOnly ? v.httpsOnly : false,
                    domain_map_redirect: v.redirect ? v.redirect : false,
                  },]);


                setDomainError(
                  [{
                    domain_map_source: false,
                    domain_map_target: false,
                    sourcemsg: "",
                    targetmsg: "",
                  },]);

              }
              else {
                setDomainMap((prevState) => (
                  [...prevState, {
                    domain_map_source: v.source ? v.source : "",
                    domain_map_target: v.target ? v.target : "",
                    domain_map_https: v.httpsOnly ? v.httpsOnly : false,
                    domain_map_redirect: v.redirect ? v.redirect : false,
                  },]))


                setDomainError((prevState) => (
                  [...prevState, {
                    domain_map_source: false,
                    domain_map_target: false,
                    sourcemsg: "",
                    targetmsg: "",
                  },]));

              }



            })

            setSecurityEdit({
              monthly_data: res.data.applicationObj?.prod?.applicationId?.appDataUsageAlert ? res.data.applicationObj?.prod?.applicationId?.appDataUsageAlert : "",
              alert_email: res.data.applicationObj?.prod?.applicationId?.alertEmail ? res.data.applicationObj?.prod?.applicationId?.alertEmail : "",
              action_required: res.data.applicationObj?.prod?.applicationId?.maxBytesPerDayAction ? res.data.applicationObj?.prod?.applicationId?.maxBytesPerDayAction : "",
              max_daily_spend: res.data.applicationObj?.prod?.applicationId?.maxBytesPerDay ? res.data.applicationObj?.prod?.applicationId?.maxBytesPerDay : "",
              binu_sdk_version: res?.data?.applicationObj?.appObjBinuSdkVersionId,
              production: res?.data?.applicationObj?.prod?.whitelist ? res?.data?.applicationObj?.prod?.whitelist : "",
              //use_production_whitelist: res?.data?.applicationObj?.prod?.useWhiteList === true ? true : false,
              max_bytes_content: res?.data?.applicationObj?.prod?.securityRules?.contentSize ? res.data.applicationObj?.prod?.applicationId?.securityRules?.contentSize : "",
              max_bytes_per_content_hidden: res?.data?.applicationObj?.prod?.securityRules?.contentSize ? res.data.applicationObj?.prod?.applicationId?.securityRules?.contentSize : "",
              max_bytes_per_content_changed: "no",
              content_type: securityEditss.content_type
            })
            // res?.data?.applicationObj?.prod?.securityRules?.blockedContent?.mime?.map((item, index) => (
            //   setSecurityEdit({...securityEdit, content_type: item ?  item : ""})
            // ))

          }

          if (res?.data?.applicationObj?.appObjBinuSdkVersionId !== "1") {


            setSecurityEdit({
              monthly_data: res.data.applicationObj?.prod?.applicationId?.appDataUsageAlert ? res.data.applicationObj?.prod?.applicationId?.appDataUsageAlert : "",
              alert_email: res.data.applicationObj?.prod?.applicationId?.alertEmail ? res.data.applicationObj?.prod?.applicationId?.alertEmail : "",
              action_required: res.data.applicationObj?.prod?.applicationId?.maxBytesPerDayAction,
              max_daily_spend: res.data.applicationObj?.prod?.applicationId?.maxBytesPerDay ? res.data.applicationObj?.prod?.applicationId?.maxBytesPerDay : "",
              binu_sdk_version: res?.data?.applicationObj?.appObjBinuSdkVersionId,
              production: res?.data?.applicationObj?.prod?.whitelist ? res?.data?.applicationObj?.prod?.whitelist : "",
              //use_production_whitelist: res?.data?.applicationObj?.prod?.useWhiteList === true ? true : false,
              max_bytes_content: res?.data?.applicationObj?.prod?.securityRules?.contentSize ? res?.data?.applicationObj?.prod?.securityRules?.contentSize : "",
              max_bytes_per_content_hidden: res?.data?.applicationObj?.prod?.securityRules?.contentSize ? res?.data?.applicationObj?.prod?.securityRules?.contentSize : "",
              max_bytes_per_content_changed: "no",
              content_type: securityEditss.content_type,
            });


          }
          setUseCheck(res?.data?.applicationObj?.prod?.useWhiteList === true ? true : false);
          setLoading(false);
          setUseAlert(res?.data?.applicationObj?.prod?.applicationId?.maxBytesPerDay !== null ? true : false)
        })
      );
    } catch (err) {

    }
  };
  const whitelistReg = /^((^\*\.)|[a-zA-Z0-9-_])*([.][.a-zA-Z0-9-_]+)$/m;
  const contentReg = /^[0-9]*$/;
  const emailReg = /^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/i;
  const mimeReg = /^[a-z]+\/(\*|[a-z]+[-+.a-z0-9]*)$/m;
  const monthlyReg = /(?!0+$)(^[0-9]+$)|(^[0-9]+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\.[0-9]{1,2})+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\,[0-9]{2,4})+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K))$/i
  const dailyReg = /(?!0+$)(^[0-9]+$)|(^[0-9]+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\.[0-9]{1,2})+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\,[0-9]{2,4})+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K))$/i;
  const handleData = (e) => {
    const newdata = { ...securityEdit }
    newdata[e.target.id] = e.target.value;
    if (e.target.id === "max_bytes_content") {
      if (e.target.value) {
        newdata['max_bytes_per_content_changed'] = 'yes'
        newdata['max_bytes_per_content_hidden'] = e.target.value
        if (contentReg.test(e.target.value) === false) {
          setSecurityError({
            ...securityError,
            max_bytes_content: true,
            contentMsg: "Invalid Format."
          });
        }
        else {
          setSecurityError({
            ...securityError,
            max_bytes_content: false,
            contentMsg: ""
          })
        }

      }
      else {
        newdata['max_bytes_per_content_changed'] = 'no'
        newdata['max_bytes_per_content_hidden'] = securityEdit?.max_bytes_content
        setSecurityError({
          ...securityError,
          max_bytes_content: false,
          contentMsg: ""
        })
      }
    }
    if (e.target.id === "content_type") {
      if (e.target.value) {
        var contentTypeValue = e.target.value;
        var modifiedContentTypeValue = '';
        if (contentTypeValue !== '') {
          var originalContentType = contentTypeValue.split('\n');
          for (var i = 0; i < originalContentType.length; i++) {
            if (i === originalContentType.length - 1) {
              if (originalContentType[i] !== '') {
                if (originalContentType[i].indexOf("/") === -1) {
                  modifiedContentTypeValue += originalContentType[i] + '/*';
                } else {
                  modifiedContentTypeValue += originalContentType[i];
                }
              }
            } else {
              if (originalContentType[i] !== '') {
                if (originalContentType[i].indexOf("/") === -1) {
                  modifiedContentTypeValue += originalContentType[i] + '/*' + '\n';
                } else {
                  modifiedContentTypeValue += originalContentType[i] + '\n';
                }
              }
            }
          }

        }

        if (mimeReg.test(modifiedContentTypeValue) === false) {
          setSecurityError({
            ...securityError,
            content_type: true,
            mimeMsg: "Please enter a valid content type."
          });
        }
        else {
          setSecurityError({
            ...securityError,
            content_type: false,
            mimeMsg: ""
          });
          e.target.value = modifiedContentTypeValue;
          newdata[e.target.id] = e.target.value;
        }

      }
      else {
        setSecurityError({
          ...securityError,
          content_type: false,
          mimeMsg: ""
        })
      }
    }
    if (e.target.id === "alert_email") {
      if (e.target.value) {
        if (emailReg.test(e.target.value) === false) {
          setSecurityError({
            ...securityError,
            alert_email: true,
            emailMsg: "Not valid emails"
          });
        }
        else {
          setSecurityError({
            ...securityError,
            alert_email: false,
            emailMsg: ""
          })
        }

      }
      else {
        setSecurityError({
          ...securityError,
          alert_email: true,
          emailMsg: "Required"
        })
      }
    }
    if (e.target.id === "monthly_data") {
      if (e.target.value) {
        if (monthlyReg.test(e.target.value) === false) {
          setSecurityError({
            ...securityError,
            monthly_data: true,
            monthlyMsg: "An Alert Email will be sent to your alert email address if the Monthly Forecast Data Usage exceeds this"
          });
        }
        else {
          setSecurityError({
            ...securityError,
            monthly_data: false,
            monthlyMsg: ""
          })
        }

      }
      else {
        setSecurityError({
          ...securityError,
          monthly_data: false,
          monthlyMsg: ""
        })
      }
    }

    if (e.target.id === "max_daily_spend") {
      if (e.target.value) {
        if (dailyReg.test(e.target.value) === false) {
          setSecurityError({
            ...securityError,
            max_daily_spend: true,
            dailyMsg: "Invalid format"
          });
        }
        else {
          setSecurityError({
            ...securityError,
            max_daily_spend: false,
            dailyMsg: ""
          })
        }

      }
      else {
        setSecurityError({
          ...securityError,
          max_daily_spend: false,
          dailyMsg: ""
        })
      }
    }

    if (e.target.id === "production") {
      if (e.target.value) {
        if (whitelistReg.test(e.target.value) === false) {
          setSecurityError({
            ...securityError,
            production: true,
            sysMsg: "Please enter a valid whitelist."
          });
        }
        else {
          setSecurityError({
            ...securityError,
            production: false,
            sysMsg: ""
          })
        }
      }
      else {
        setSecurityError({
          ...securityError,
          production: false,
          sysMsg: ""
        })
      }
    }

    /*if(e.target.id === "use_production_whitelist"){
      setUseCheck(!useCheck)
      newdata[e.target.id] = e.target.checked; 
    }*/

    if (e.target.id === "include_daily_dataFree_limit") {
      setUseAlert(!useAlert)
      newdata[e.target.id] = e.target.checked;
    }


    setSecurityEdit(newdata);
  }
  const deleteRow = (record, idx) => {
    const temp = [...DomainMap];
    const nwvals = temp.filter(r => r !== record);
    setDomainMap(nwvals);

    const temp1 = [...DomainError];
    temp1.splice(idx, 1);
    setDomainError(temp1);
  }

  const addRow = (e) => {
    e.preventDefault();

    setDomainMap((prevState) => (
      [...prevState, {
        domain_map_source: "",
        domain_map_target: "",
        domain_map_https: "",
        domain_map_redirect: "",

      }]
    ))

    setDomainError((prevState) => (
      [...prevState, {
        domain_map_source: false,
        domain_map_target: false,
        sourcemsg: "",
        targetmsg: "",
      }]
    ))

  }

  const handleDomain = (e) => {
    e.preventDefault();
    if (["domain_map_source", "domain_map_target"].includes(e.target.name)) {
      //const valuesNew = [...SupApps];
      DomainMap[e.target.dataset.id][e.target.name] = e.target.value

      checkError();
    }
    if (["domain_map_https", "domain_map_redirect"].includes(e.target.name)) {
      //const valuesNew = [...SupApps];
      //alert(e.target.checked);

      DomainMap[e.target.dataset.id][e.target.name] = e.target.checked
      checkError();
    }
    setDomainMap(DomainMap);

  }
  const apkValidReg = /^[a-z][a-z0-9_]*(\.[a-z0-9_]+)+[0-9a-z_]$/i;
  const apkReg = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  const checkError = () => {
    for (let i = 0; i <= DomainMap.length; i++) {
      setDomainError(DomainError.filter((newError, index) => {
        if (index === i) {
          if (DomainMap[i]?.domain_map_source !== "") {
            if (apkValidReg.test(DomainMap[i]?.domain_map_source) === false) {
              newError.domain_map_source = true;
              newError.sourcemsg = "Please enter a valid URL";
            }
            else {
              newError.domain_map_source = false;
              newError.sourcemsg = "";
            }
          }
          else {
            newError.domain_map_source = false;
            newError.sourcemsg = "";
          }


          if (DomainMap[i]?.domain_map_target !== "") {
            if (apkReg.test(DomainMap[i]?.domain_map_target) === false) {
              newError.domain_map_target = true;
              newError.targetmsg = "Please enter a valid URL";
            }
            else {
              newError.domain_map_target = false;
              newError.targetmsg = "";
            }
          }
          else {
            newError.domain_map_target = false;
            newError.targetmsg = "";
          }

        }
        return newError;
      }))


    }

  }
  const launchUrl = (appId) => {
    let baseAPI_URL = process.env.REACT_APP_BASE_URL;
    let subbaseUrl = baseAPI_URL.toString().split("/");
    let hostName = subbaseUrl[2].toString().split(".");
    let path = baseAPI_URL.split(hostName[0]).join("qamonitor");
    window.open(path + "/login/" + appId);

  }

  const [useAlert, setUseAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const createDev = () => {
    try {
      trackPromise(
        service.createSwitch({ application_id: props.applicationId, application_type: "prod", product_type: "3" }).then((res) => {

          getGenerateApp();
        })
      );
    } catch (err) {

    }
  }
  //Switch Prod Save
  const [switchClick, setSwitchClick] = useState();
  const [switchPop, setSwitchPop] = useState(false);
  const [message, setMessage] = useState();
  const SaveSwitchProd = () => {
    const sCount = validateSwitchProd(securityEdit);

    if (sCount === 0) {
      securityEdit.taskList = DomainMap;
      if (applicationData?.appObjBinuSdkVersionId !== 1) {
        if (useCheck) {
          securityEdit.use_production_whitelist = 1;
        }
      }
      if (securityEdit.include_daily_dataFree_limit === true) {
        securityEdit.include_daily_dataFree_limit = 1;
      }
      else {
        securityEdit.include_daily_dataFree_limit = 0;
      }
      if (applicationData?.appObjReachGateway === null && applicationData?.appObjBinuSdkVersionId === 1) {

        securityEdit.taskList = DomainMap;
      }
      securityEdit['application_id'] = props?.applicationId;
      setSwitchClick(true);
      service.editSecurity(securityEdit).then((res) => {
        if (res?.data?.message === "success") {
          handleCloseSecurity();
        }
        if (res?.data?.message === "failed") {
          setMessage(res?.data?.description);
          setSwitchPop(true);
        }
        setSwitchClick(false);
      })
    }
  }

  const validateSwitchProd = (securityEdit) => {
    let swCount = 0;
    let nwerror = { ...securityError }

    if (securityEdit.monthly_data === "" || securityEdit.monthly_data === null) {
      nwerror.monthly_data = false;
      nwerror.monthlyMsg = "";
    }
    else {
      if (monthlyReg.test(securityEdit.monthly_data) === false) {
        nwerror.monthly_data = true;
        nwerror.monthlyMsg = "An Alert Email will be sent to your alert email address if the Monthly Forecast Data Usage exceeds this";
        swCount++;
      }
      else {
        nwerror.monthly_data = false;
        nwerror.monthlyMsg = ""
      }
    }

    if (securityEdit.alert_email === "" || securityEdit.alert_email === null) {
      nwerror.alert_email = true;
      nwerror.emailMsg = "Required";
      swCount++;
    }
    else {

      if (emailReg.test(securityEdit.alert_email) === false) {
        nwerror.alert_email = true;
        nwerror.emailMsg = "Not valid emails";
        swCount++;
      }
      else {
        nwerror.alert_email = false;
        nwerror.emailMsg = ""
      }
    }

    if (securityEdit.max_bytes_content === "" || securityEdit.max_bytes_content === null) {
      nwerror.max_bytes_content = false;
      nwerror.contentMsg = "";
    }
    else {
      if (contentReg.test(securityEdit.max_bytes_content) === false) {
        nwerror.max_bytes_content = true;
        nwerror.contentMsg = "Invalid Format.";
        swCount++;
      }
      else {
        nwerror.max_bytes_content = false;
        nwerror.contentMsg = ""
      }
    }
    if (securityEdit.content_type === "" || securityEdit.content_type === null) {
      nwerror.content_type = false;
      nwerror.mimeMsg = "";
    }
    else {

      if (mimeReg.test(securityEdit.content_type) === false) {
        nwerror.content_type = true;
        nwerror.mimeMsg = "Please enter a valid content type.";
        swCount++;
      }
      else {
        nwerror.content_type = false;
        nwerror.mimeMsg = ""
      }
    }

    if (securityEdit.max_daily_spend === "" || securityEdit.max_daily_spend === null) {
      nwerror.max_daily_spend = false;
      nwerror.dailyMsg = "";
    }
    else {

      if (dailyReg.test(securityEdit.max_daily_spend) === false) {
        nwerror.max_daily_spend = true;
        nwerror.dailyMsg = "Invalid Format.";
        swCount++;
      }
      else {
        nwerror.max_daily_spend = false;
        nwerror.dailyMsg = ""
      }
    }

    if (applicationData?.appObjBinuSdkVersionId !== 1) {

      if (securityEdit.production === "" || securityEdit.production === null) {
        nwerror.production = false;
        nwerror.sysMsg = "";
      }
      else {

        if (whitelistReg.test(securityEdit.production) === false) {
          nwerror.production = true;
          nwerror.sysMsg = "Please enter a valid whitelist.";
          swCount++;
        }
        else {
          nwerror.production = false;
          nwerror.sysMsg = ""
        }
      }
    }

    for (let i = 0; i <= DomainMap.length; i++) {
      setDomainError(DomainError.filter((newError, index) => {
        if (index === i) {
          if (DomainMap[i]?.domain_map_source !== "") {
            if (apkValidReg.test(DomainMap[i]?.domain_map_source) === false) {
              newError.domain_map_source = true;
              newError.sourcemsg = "Please enter a valid URL";
              swCount++;
            }
            else {
              newError.domain_map_source = false;
              newError.sourcemsg = "";
            }
          }
          else {
            newError.domain_map_source = false;
            newError.sourcemsg = "";
          }


          if (DomainMap[i]?.domain_map_target !== "") {
            if (apkReg.test(DomainMap[i]?.domain_map_target) === false) {
              newError.domain_map_target = true;
              newError.targetmsg = "Please enter a valid URL";
              swCount++;
            }
            else {
              newError.domain_map_target = false;
              newError.targetmsg = "";
            }
          }
          else {
            newError.domain_map_target = false;
            newError.targetmsg = "";
          }

        }
        return newError;
      }))


    }
    setSecurityError(nwerror);
    return swCount;

  }

  const ClearData = (e) => {
    setSecurityEdit({
      ...securityEdit,
      max_daily_spend: ""
    });
  }

  const [addEvent, setAddEvent] = useState();
  const [eventDisable, setEventDisable] = useState();
  const addNewEvent = () => {
    setAddEvent(true);
    setEventDisable(true);
  }
  const closeEvent = () => {
    setAddEvent(false);
    setEventDisable(false);
  }
  const [eventNew,setEventNew] = useState({
  start_date : moment(new Date()).format("DD/MM/YYYY"),
  end_date : moment(new Date()).format("DD/MM/YYYY"),
  start_time : moment(new Date()).format("hh:mm A"),
  //start_time : convertTime(new Date()),
  type: "Build",
  description: "",
  application_id: props.applicationId,
    
  });

  const handleEvent = (e) => {
    const newdata = { ...eventNew }
    newdata[e.target.id] = e.target.value;

    if (e.target.id === "description") {
      if (e.target.value) {
        setEventError({
          ...eventError,
          description: false
        });
      }
    }
    setEventNew(newdata);
  }
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setstartTime] = useState(new Date());


  const handleEnd = (date) => {


    setEndDate(date ? date : null);
    const newIPdata = { ...eventNew };
    newIPdata['end_date'] = date !== null ? moment(date).format("DD/MM/YYYY") : "";
    setEventNew(newIPdata);
  }
  const handleStart = (date) => {


    setStartDate(date ? date : new Date());

    const newIPdata = { ...eventNew };
    newIPdata['start_date'] = date !== null ? moment(date).format("DD/MM/YYYY") : "";
    setEventNew(newIPdata);
  }

  const convertTime = (time) => {
    let hours = time.getHours();
    let minutes = time.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let times = hours + ':' + minutes + ' ' + ampm;
    return times;
  }
  const handleTime = (time) => {

    setstartTime(time ? time : null)

    let timesnw = convertTime(time);


    const newIPdata = { ...eventNew };
    newIPdata['start_time'] = timesnw !== null ? timesnw : "";
    setEventNew(newIPdata);
  }

  const [eventError, setEventError] = useState({
    description: false,
  });

  const validateEvent = (eventNew) => {
    let rCount = 0;
    let nwerror = { ...eventError }

    if (eventNew.description === "") {
      nwerror.description = true;
      rCount++;
    }
    else {
      nwerror.description = false;
    }

    setEventError(nwerror);
    return rCount;
  }
  const addEventSwitch = () => {

    const eventCount = validateEvent(eventNew);
    if (eventCount === 0) {
      service.addEventsNew(eventNew).then((res) => {
        if (res?.status === 200) {
          setAddEvent(false);
          setEventDisable(false);
          handleClose();
          getApplicationDetails();

        }
      });
    }

  }
  function timeConvert(utctime) {
    const dateString = utctime;
    const userOffset = new Date().getTimezoneOffset() * 60 * 1000;
    const localDate = new Date(dateString);
    const utcDate = new Date(localDate.getTime() + userOffset);
    return utcDate.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric'
    });
  }

  const [hasApp, setHasApp] = useState(false);
  const getGenerateApp = () => {
    setLoading(true)
    service.generateApp({
      "applicationId": props?.applicationId,
      "type": "PROD"
    }).then((res) => {

      setLoading(false);
      if (res.data?.hasAppId === "Yes") {

        getApplicationDetails();
        setHasApp(true);
      }
      else {
        setHasApp(false);
      }

    }).catch((err) => {

    })

  };

  useEffect(() => {
    setLoading(true);
    document.title = "Datafree Portal";
    getApplicationDetails();
    getGenerateApp();

  }, []);
  return (
    <div>
      <div className="dialoguRow">
        <div className="devHead"></div>
        <div className="devmainHead">Production App SDK / Library</div>
        <div className="devHead"></div>
      </div>

      {loading ? <div className="loaderContainer">
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
      {!loading && hasApp ? (
        <div className="developmentTab">
          <div className="developTabContent">

            <div className="developTabContenthead">
              biNu ID :{" "}
              <span>
                {applicationData ? applicationData?.prod?.appId : ""}
              </span>
            </div>
            <div className="developTabContenthead">
              Security Key  : <span>{applicationData ? applicationData?.prod?.securityKey : ""}</span>
            </div>

            <div className="developToolsTabContenthead">Tools:</div>

            <div style={{ textAlign: "left", marginLeft: "10px", marginTop: '60px' }}>
              <button className="devdtbtn1"
                style={{ width: '97%', height: '60px', padding: '14px', cursor: 'pointer' }}>
                <a style={{ textDecoration: 'none' }}
                  target="_blank"
                  href={applicationData ? applicationData?.prod?.binuSdkVersionId?.binuSdkDocumentation : ""}
                >
                  #datafree SWITCH Integration Documentation
                </a>
              </button>
            </div>
            {applicationData?.prod?.binuSdkVersionId?.binuSdkVersionId === 1 && applicationData?.prod?.applicationId?.reachGateway != null ?
              <div style={{ textAlign: "left", marginLeft: "10px" }} >
                <button className="devdtbtn1"
                  style={{ width: '97%', height: '60px', padding: '14px', cursor: 'pointer' }} onClick={() => launchUrl(applicationData?.prod?.appId)}>

                  Launch SWITCH Lite #datafree Workbench

                </button>
              </div> : null}


          </div>

          <div className="developMaxTabContent">
            <AntTabs
              value={value}
              onChange={handleChange}
              className="tabChange tabViewMenu"
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
            >
              <AntTab
                label="Security"
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
              />
              <AntTab
                label="Events"
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
              />
            </AntTabs>

            <TabPanel value={value} index={0}>

              <div className="developmentSecurityTab">
                <button
                  className={!editProd ? "editbtn" : "NotEdit"}
                  style={{ margin: '5px', borderRadius: '10px', width: '70px', height: '35px' }}
                  onClick={edit}>
                  Edit
                </button>

                <button className={editProd ? "editbtn" : "NotEdit"}
                  style={{ margin: '5px', borderRadius: '10px', width: '70px', height: '35px' }}
                  onClick={handleCloseSecurity}>
                  Cancel
                </button>

                <button className={editProd ? "editbtn" : "NotEdit"}
                  disabled={switchClick ? 'disabled' : false}
                  style={{ margin: '5px', borderRadius: '10px', width: '70px', height: '35px' }}
                  onClick={SaveSwitchProd}>Save</button>
              </div>
              <div className="InputflexRow">
                <div className="entrypointLabel">
                  biNu SDK Version
                </div>

                {applicationData?.prod?.binuSdkVersionId?.binuSdkVersionId === "1" ? (
                  <input
                    type="text"
                    className="entryinputField"
                    name="name"
                    disabled="disabled"
                    style={{ marginLeft: '11%', cursor: 'not-allowed' }}
                    value={applicationData ? applicationData?.prod?.binuSdkVersionId?.binuSdkVersionTag : ""}
                  />
                ) :
                  (
                    !editProd ? (
                      <select
                        type="text"
                        className="entryinputField"
                        disabled="disabled"
                        style={{ cursor: 'not-allowed', width: '17%', height: '38px', marginLeft: '11%' }}
                      >
                        {applicationData?.binuSdkVersion?.map(post => (
                          post.binuSdkVersionId !== 1 ?
                            <option key={post.binuSdkVersionId}
                              value={post.binuSdkVersionId}
                              selected={post.binuSdkVersionId === applicationData?.prod?.binuSdkVersionId?.binuSdkVersionId && true}>
                              {post.binuSdkVersionTag}
                            </option>
                            : null
                        ))}
                      </select>)

                      :
                      <select
                        type="text"
                        className="editinputField"
                        name="binu_sdk_version"
                        id="binu_sdk_version"
                        onChange={handleData}
                        style={{ width: '17%', height: '38px', marginLeft: '11%' }}

                      >
                        {applicationData?.binuSdkVersion?.map(post => (
                          post.binuSdkVersionId !== 1 ?
                            <option key={post.binuSdkVersionId}
                              value={post.binuSdkVersionId}
                              selected={post.binuSdkVersionId === applicationData?.prod?.binuSdkVersionId?.binuSdkVersionId && true}>
                              {post.binuSdkVersionTag}
                            </option>
                            : null
                        ))}
                      </select>
                  )}
              </div>
              <div className="InputflexRow">
                <div className="entrypointLabel" style={{ width: '30%' }}>
                  Monthly App #datafree Data Alert (bytes){" "}
                </div>
                {!editProd ?
                  <input
                    type="text"
                    className="entryinputField"
                    name="name"
                    disabled="disabled"
                    style={{ marginLeft: '1%' }}
                    value={applicationData ? applicationData?.prod?.applicationId?.appDataUsageAlert : ""}
                  />
                  :
                  <input
                    type="text"
                    className={securityError?.monthly_data ? "editentryinputField errEdit" : "editentryinputField"}
                    name="popup"
                    id="monthly_data"
                    style={{ marginLeft: '1%' }}
                    defaultValue={applicationData ? applicationData?.prod?.applicationId?.appDataUsageAlert : ""}
                    onChange={handleData}
                  />

                }

              </div>
              {securityError.monthly_data ? (
                <div className="errorClass" style={{ width: '20%', marginLeft: '31%' }}>{securityError?.monthlyMsg}</div>
              ) : null}

              <div className="InputflexRow">
                <div className="entrypointLabel" style={{ width: '40%' }}>
                  Alert Email Addresses (comma separated){" "}
                </div>
                {!editProd ?
                  <input
                    type="text"
                    className="entyPointfield"
                    name="name"
                    disabled="disabled"
                    value={applicationData ? applicationData?.prod?.applicationId?.alertEmail : ""}
                  />
                  :
                  <input
                    type="text"
                    className={securityError?.alert_email ? "editentyPointfield errEdit" : "editentyPointfield"}
                    name="popup"
                    id="alert_email"
                    style={{ marginLeft: '-9%' }}
                    onChange={(e) => handleData(e)}
                    onBlur={(e) => handleData(e)}
                    defaultValue={applicationData ? applicationData?.prod?.applicationId?.alertEmail : ""}
                  />
                }
              </div>
              {securityError.alert_email ? (
                <div className="errorClass" style={{ width: '20%', marginLeft: '31%' }}>{securityError?.emailMsg}</div>
              ) : null}
              <div className="InputflexRow">
                <div className="entrypointLabel" style={{ width: '32%' }}>
                  Include Daily #datafree Data Limit Alerting ?
                </div>
                <input type="checkbox" name="name" value="Self"
                  id="include_daily_dataFree_limit"
                  disabled={!editProd ? "disabled" : false}
                  checked={useAlert}
                  onChange={handleData} className="dailyLimit" />
              </div>
              {useAlert ? (
                <div className="borderDiv">
                  <div className="InputflexRow">
                    <div className="entrypointLabel" style={{ width: '31%' }}>
                      Daily #datafree data limit (bytes)
                    </div>
                    {!editProd ?
                      <input
                        type="text"
                        className="entryinputField"
                        name="name"
                        disabled="disabled"
                        value={applicationData ? applicationData?.prod?.applicationId?.maxBytesPerDay : ""}
                        style={{ marginLeft: '0%' }}
                      />
                      :
                      <input
                        type="text"
                        className={securityError?.max_daily_spend ? "editentryinputField errEdit" : "editentryinputField"}
                        name="max_daily_spend"
                        id="max_daily_spend"
                        //defaultValue={applicationData ? applicationData?.prod?.applicationId?.maxBytesPerDay : ""}
                        value={securityEdit.max_daily_spend}
                        style={{ marginLeft: '0%' }}
                        onChange={(e) => handleData(e)}
                        onBlur={(e) => handleData(e)}
                      />
                    }

                    <button
                      type="button"
                      className={!editProd ? "clearButton" : "clearblueButton"}
                      name="name"
                      disabled={!editProd ? "disabled" : false}
                      onClick={ClearData}
                    >Clear Value</button>

                  </div>
                  {securityError.max_daily_spend ? (
                    <div className="errorClass" style={{ width: '20%', marginLeft: '31%', marginBottom: '5px' }}>{securityError?.dailyMsg}</div>
                  ) : null}
                  <div className="InputflexRow">
                    <div className="entrypointLabel" style={{ width: '30%' }}>
                      Required Action on Daily Limit
                    </div>
                    {!editProd ? (
                      <select
                        type="text"
                        className="entyPointfield"
                        name="name"
                        disabled="disabled"
                        style={{ marginLeft: '6%', height: '37px' }}
                      >
                        <option value="0" selected={applicationData?.prod?.applicationId?.maxBytesPerDayAction === 0 && true}>Send Alerts Only</option>
                        <option value="1" selected={applicationData?.prod?.applicationId?.maxBytesPerDayAction === 1 && true}>Send Alerts and Stop App</option>
                      </select>) :
                      <select
                        type="text"
                        className="editentyPointfield"
                        name="popup"
                        id="action_required"
                        style={{ marginLeft: '1%', height: '37px' }}
                        onChange={(e) => handleData(e)}
                        onBlur={(e) => handleData(e)}
                      >
                        <option value="0" selected={applicationData?.prod?.applicationId?.maxBytesPerDayAction === 0 && true}>Send Alerts Only</option>
                        <option value="1" selected={applicationData?.prod?.applicationId?.maxBytesPerDayAction === 1 && true}>Send Alerts and Stop App</option>
                      </select>}
                  </div>
                </div>) : null}
              <div className="InputflexRow">
                <div className="entrypointLabel">
                  Max Bytes per content object
                </div>
                {!editProd ? (
                  <input
                    type="text"
                    className="entryinputField"
                    name="name"
                    disabled="disabled"
                    style={{ marginLeft: '3%' }}
                    value={applicationData ? applicationData?.prod?.securityRules?.contentSize : ""}
                  />) :
                  <input
                    type="text"
                    className={securityError?.max_bytes_content ? "editentryinputField errEdit" : "editentryinputField"}
                    name="max_bytes_content"
                    id="max_bytes_content"
                    onChange={(e) => handleData(e)}
                    onBlur={(e) => handleData(e)}
                    style={{ marginLeft: '3%' }}
                    defaultValue={applicationData ? applicationData?.prod?.securityRules?.contentSize : ""}
                  />}
              </div>
              {securityError.max_bytes_content ? (
                <div className="errorClass" style={{ width: '20%', marginLeft: '31%' }}>{securityError?.contentMsg}</div>
              ) : null}
              {applicationData?.prod?.binuSdkVersionId?.binuSdkVersionId !== "1" ? (
                <Grid item xs={12}>
                  <div className="companyLabel">Test WhiteList

                    <input
                      type="checkbox"
                      id="use_production_whitelist"
                      checked={useCheck}
                      disabled={!editProd ? 'disabled' : false}
                      onChange={() => setUseCheck(!useCheck)}
                      style={{ float: 'right', marginRight: '100px' }}
                    />
                    <label className="companyLabel" style={{ float: 'right' }}>Production whitelist</label>
                  </div>

                  <div>
                    {!editProd ? (
                      <textarea
                        type="text"
                        className="entyPointfield"
                        id="production"
                        disabled="disabled"
                        style={{ height: '70px' }}
                        value={applicationData ? applicationData?.prod?.whitelist : ""}
                      />
                    ) : (
                      <textarea
                        type="text"
                        className={securityError.production ? "editentyPointfield errEdit" : "editentyPointfield"}
                        id="production"
                        style={{ height: '70px' }}
                        defaultValue={applicationData ? applicationData?.prod?.whitelist : ""}
                        onBlur={handleData}
                        onChange={handleData}
                      />
                    )}
                    {securityError.production ? (
                      <div className="errorClass">{securityError.sysMsg}</div>
                    ) : null}
                  </div>
                </Grid>) : null}

              <div className="entypointflex">
                <div className="companyLabel">Content Types Blocked (Mime Types)</div>

                <div>
                  {!editProd ? (
                    <textarea
                      type="text"
                      className="entyPointfield"
                      name="name"
                      disabled="disabled"
                      style={{ height: '75px' }}
                      value={applicationData ? applicationData?.prod?.securityRules?.blockedContent?.mime : ""}
                    />
                  ) : (
                    <textarea
                      type="text"
                      className={securityError?.content_type ? "editentyPointfield errEdit" : "editentyPointfield"}

                      style={{ height: '75px' }}
                      name="content_type"
                      id="content_type"
                      onBlur={(e) => handleData(e)}
                      defaultValue={applicationData ? applicationData?.prod?.securityRules?.blockedContent?.mime : ""}
                    />
                  )}
                </div>
                {securityError.content_type ? (
                  <div className="errorClass" >{securityError?.mimeMsg}</div>
                ) : null}
              </div>
              <div className="entypointflex">
                <div className="companyLabel">#datafree Gateway domain</div>

                <div>
                  <input
                    type="text"
                    className="entyPointfield"
                    name="name"
                    disabled="disabled"
                    style={{ marginBottom: '10px' }}
                    value={applicationData ? applicationData?.prod?.applicationId?.reachGateway : ""}
                  />

                </div>
              </div>

              {applicationData?.prod?.binuSdkVersionId?.binuSdkVersionId === "1" && applicationData?.prod?.applicationId?.reachGateway === null ? (
                <Grid item xs={12} spacing={1}>
                  <div className="companyLabel">Domain Map (for datafree SWITCH Lite Product only)</div>
                  <div className="datafeeSubBox boxConnect" style={{ marginLeft: '2px' }}>
                    <div className="boxRow">
                      <div className="flex1" style={{ marginTop: '6px', marginLeft: '33px' }}>
                        <div className="companyLabel" style={{ textAlign: 'right' }}>Source</div>
                      </div>

                      <div className="flex1" style={{ marginTop: '6px', marginLeft: '48px' }}>
                        <div className="companyLabel" style={{ textAlign: 'right', marginLeft: '69px' }}>Target</div>
                      </div>
                      <div className="flex1" style={{ marginTop: '6px', marginLeft: '33px' }}>
                        <div className="companyLabel" style={{ textAlign: 'right', marginLeft: '32px' }}>HTTPS Only</div>
                      </div>
                      <div className="flex1" style={{ marginTop: '6px', marginLeft: '33px' }}>
                        <div className="companyLabel" style={{ textAlign: 'right' }}>Redirect</div>
                      </div>
                      <div className="flex1" style={{ marginTop: '6px', marginLeft: '33px' }}>
                        <div className="companyLabel" style={{ textAlign: 'right' }}>Delete</div>
                      </div>
                    </div>


                    {!editProd && applicationData?.prod?.securityRules?.domainMap ?
                      applicationData?.prod?.securityRules?.domainMap.map((item, index) => (
                        <div className="boxRow" key={item.index}>
                          <div className="flex1" style={{ marginTop: '6px', marginLeft: '33px' }}>

                            <input
                              type="text"
                              className="editinputField"
                              style={{ width: '220%', cursor: 'not-allowed' }}
                              disabled="disabled"
                              value={item?.source ? item?.source : ""}
                            />
                          </div>

                          <div className="flex1" style={{ marginTop: '6px', marginLeft: '48px' }}>

                            <input
                              type="text"
                              className="editinputField"
                              style={{ marginLeft: '60px', cursor: 'not-allowed' }}
                              disabled="disabled"
                              value={item?.target ? item?.target : ""}
                            />
                          </div>
                          <div className="flex1" style={{ marginTop: '12px', marginLeft: '33px' }}>

                            <input
                              type="checkbox"
                              style={{ marginLeft: '90px', cursor: 'not-allowed' }}
                              checked={item?.httpsOnly ? item?.httpsOnly : false}
                            />
                          </div>
                          <div className="flex1" style={{ marginTop: '12px', marginLeft: '33px' }}>

                            <input
                              type="checkbox"
                              style={{ marginLeft: '20px', cursor: 'not-allowed' }}
                              checked={item?.redirect ? item?.redirect : false}
                            />
                          </div>
                          <div className="flex1" style={{ marginTop: '6px', marginLeft: '0px' }}>
                            <button disabled="disabled"
                              style={{ marginLeft: '69px', border: 'none' }}>
                              <FaTimes className="fatimesclass" style={{ fontSize: '20px', color: 'red' }} />
                            </button>
                          </div>
                        </div>

                      )) :



                      DomainMap.map((v, index) => (
                        <div className="boxRow" key={v.index}>
                          <div className="flex1" style={{ marginTop: '6px', marginLeft: '33px' }}>

                            <input
                              type="text"
                              id="domain_map_source"
                              name="domain_map_source"
                              className={
                                !DomainError[index]?.domain_map_source ? "editinputField" : "editinputFielderr"
                              }
                              style={{ width: '220%' }}
                              data-id={index}
                              value={v.domain_map_source}
                              onChange={handleDomain}
                            />
                            {DomainError[index]?.domain_map_source ? <span style={{ float: 'left', width: '258%' }} className="errormsg" name="identifiererror">{DomainError[index]?.sourcemsg}</span> : null}

                          </div>

                          <div className="flex1" style={{ marginTop: '6px', marginLeft: '48px' }}>

                            <input
                              type="text"
                              className={
                                !DomainError[index]?.domain_map_target ? "editinputField" : "editinputFielderr"
                              }
                              id="domain_map_target"
                              name="domain_map_target"
                              style={{ marginLeft: '60px' }}
                              data-id={index}
                              value={v.domain_map_target}
                              onChange={handleDomain}
                            />
                            {DomainError[index]?.domain_map_target ? <span style={{ float: 'left', width: '151%', marginLeft: '53px' }} className="errormsg" name="identifiererror">{DomainError[index]?.targetmsg}</span> : null}

                          </div>
                          <div className="flex1" style={{ marginTop: '12px', marginLeft: '33px' }}>

                            <input
                              type="checkbox"
                              id="domain_map_https"
                              name="domain_map_https"
                              style={{ marginLeft: '90px' }}
                              checked={v.domain_map_https}
                              data-id={index}
                              onChange={handleDomain}
                            />
                          </div>
                          <div className="flex1" style={{ marginTop: '12px', marginLeft: '33px' }}>

                            <input
                              type="checkbox"
                              id="domain_map_redirect"
                              name="domain_map_redirect"
                              style={{ marginLeft: '20px' }}
                              checked={v.domain_map_redirect}
                              data-id={index}
                              onChange={handleDomain}
                            />
                          </div>
                          <div className="flex1" style={{ marginTop: '6px', marginLeft: '0px' }}>
                            <button
                              style={{ marginLeft: '69px', border: 'none' }} id={index}
                              onClick={() => deleteRow(v, index)}>
                              <FaTimes className="fatimesclass" style={{ fontSize: '20px', color: 'red' }} />
                            </button>
                          </div>
                        </div>))

                    }
                    <div style={{ textAlign: "left", marginTop: '12px' }}>
                      <button className={editProd ? "editbtn" : "NotEdit"} style={{ marginLeft: '30px' }}
                        disabled={!editProd ? "disabled" : false}
                        name="popup"
                        onClick={addRow}
                      >
                        Add App
                      </button>
                    </div>
                  </div>
                </Grid>) : null}

            </TabPanel>

            <TabPanel value={value} index={1}>
              <div className="developmentSecurityTab">
                <button
                  className={!eventDisable ? "clearblueButton" : "clearButton"}
                  disabled={eventDisable ? "disabled" : false}
                  onClick={addNewEvent}>
                  + Create New Event
                </button>

                <button
                  className={!eventDisable ? "clearButton" : "EditEvent"}
                  disabled={!eventDisable ? "disabled" : false}
                  onClick={closeEvent}>
                  Cancel
                </button>

                <button
                  className={!eventDisable ? "clearButton" : "EditEvent"}
                  disabled={!eventDisable ? "disabled" : false}
                  onClick={addEventSwitch}
                >Save</button>
              </div>
              <div>
                <table className="notifyTable">
                  <thead className="RowTab">
                    <td className="Rowcell">Start Date</td>
                    <td className="Rowcell">End Date</td>
                    <td className="Rowcell">Start Time</td>
                    <td className="Rowcell">Type</td>
                    <td className="Rowcell">Description</td>
                  </thead>
                  <tbody>
                    {applicationData?.events && applicationData?.events.length > 0 ? applicationData?.events.map((row) => (
                      <tr key={row.index}>
                        <td className="Rowcells" component="th" scope="row">
                          {
                            moment(row.startDate.slice(0, 10)).format("DD/MM/YYYY")}
                        </td>
                        <td className="Rowcells">{moment(row.endDate.slice(0, 10)).format("DD/MM/YYYY")}</td>
                        <td className="Rowcells">{
                          timeConvert(row.startTime)
                        }</td>
                        <td className="Rowcells">{row.type}</td>
                        <td className="Rowcells">{row.description}</td>
                      </tr>
                    )) :
                      (<tr>
                        <td colSpan={5}>No Events Found</td>
                      </tr>)
                    }
                  </tbody>
                </table>
              </div>
              {addEvent ?
                <div className="InputflexRow">
                  <DatePicker
                    id="start_date"
                    style={{ width: '70% !important' }}
                    selected={startDate}
                    //onChange={(date) => setStartDate(date)}
                    name="start_date"
                    className="editdateField"
                    popperPlacement='top-start'
                    autoComplete="off"
                    onChange={handleStart}
                    dateFormat="dd/MM/yyyy"
                  />
                  <DatePicker
                    id="end_date"
                    name="end_date"
                    selected={endDate}
                    //onChange={(date) => setEndDate(date)}
                    style={{ width: '70% !important' }}
                    className="editdateField"
                    popperPlacement='top-start'
                    autoComplete="off"
                    dateFormat="dd/MM/yyyy"
                    onChange={handleEnd}
                  />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                      className="editentrytime"
                      name="start_time"
                      id="start_time"
                      autoOk
                      value={startTime}
                      dateFormat="dd/MM/yyyy"
                      onChange={handleTime}
                    />
                  </MuiPickersUtilsProvider>
                  <select
                    type="text"
                    className="editentryinputField"
                    name="type"
                    id="type"
                    onChange={handleEvent}
                    style={{ width: '70%', marginLeft: '1%', marginRight: '1%', height: '38px' }}
                  >
                    <option value="Build">Build</option>
                    <option value="Release">Release</option>
                    <option value="Promotion">Promotion</option>
                    <option value="Other">Other</option>
                  </select>
                  <textarea
                    type="text"
                    onChange={handleEvent}
                    className={eventError.description ? "editentryinputField errEdit" : "editentryinputField"}
                    name="description"
                    id="description"
                    style={{ width: '80%', height: '50px', marginLeft: '1%' }}
                  />
                  {eventError.description ?
                    (<p
                      className="errormsg" name="identifiererror">This field is required</p>)
                    : ""}
                </div>

                : null

              }

            </TabPanel>
          </div>
        </div>
      ) : (
        !loading && !hasApp ?
          <div className="configDefinebtn" onClick={createDev}>
            + Create Production App Configuration and Define Entry Point
          </div> : null
      )}

      <Dialog
        open={deleteopen}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="deletedialoguettitle">
          WARNING!
        </DialogTitle>
        <DialogContent>
          <div>
            Are you sure you want to delete this #datafree Website Registration
            ? This cannot be undone.
          </div>
        </DialogContent>
        <DialogActions>
          <div className="iviteRow">
            <button
              className="deletecancelbtn"
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </button>
            <div className="flexSpace"></div>
            <button className="deletecancelbtn" color="primary">
              Confirm Deletion
            </button>
          </div>
        </DialogActions>
      </Dialog>

      {/* SUCCESS MESSAGE*/}
      <Dialog open={switchPop} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" aria-labelledby="form-dialog-title">

        <DialogContent className="diaContents">
          <div >{message ? message : null}</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" color="primary" onClick={() => setSwitchPop(false)}>
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default SwitchProduction;
