import React, { useEffect, useState,useContext } from "react";
import { useHistory } from "react-router-dom";
import applicationIcon from "../../../assets/img/application-icon.png";
import Service from "../../../api/licenseeService";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";
import VMCTable from '../../../Recomponent/Table'
import { usePromiseTracker } from "react-promise-tracker";
import EmptyTable from "../../../Recomponent/EmptyTable";
import { IoMdCloseCircleOutline } from "react-icons/io";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Grid,
} from "@material-ui/core";
import {
  ToggleContext
} from "../../context/toggleContext"
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});
const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
       <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ChannelList() {
  const [search, setSearch] = useState(false);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [scroll, setScroll] = React.useState("paper");
  const [checked, setChecked] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const toggleContextVal = useContext(ToggleContext)
  const [channelList, setChannelList] = useState();
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  let history = useHistory();
  const [changedropdown, setChangedropdown] = useState(false);
  const handleClose = () => {
    setOpen(false);
   setError({
      companyname:false,
      status:false,
      reg_phone_number:false,
      users_name: false,
      email_address: false,
      validemailmsg:"",
      nameMSG:"",
      statusMSG:"",
      regNoMsg:""
    })
  };
  const handleClickOpen = (scrollType) => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
    setOpen(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const [tablecols, setTableCols] = useState([
    {
      colName : 'Channel ID',
      colMap: 'companyId',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Channel Name',
      colMap: 'name',
      linkName : true,
      cModel : false,
      cRoute : true,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Company Type',
      colMap: 'companyType',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Status',
      colMap: 'status',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Contract Term',
      colMap: 'contractTerm',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: false,
      userTab:false
    },
    {
      colName : 'Created',
      colMap: 'created',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:true,
      imprRoute: false,
      userTab:false
    },
   
  ])

  const [data, setData] = useState({
    searchChannelID: "",
    searchChannelName: "",
    searchChannelStatus: "",
    searchLicenseeId: "",
  });
  async function Userdata() {
    try {
      trackPromise(
        Service.licenseeUserRequest().then((res) => {
       
          data['searchLicenseeId']=res.data.userDetails.companyId.companyId;
         invite['licenseeId']= res.data.userDetails.companyId.companyId;
        })
      );
    } catch (err) {
    
    }
  }
  const[error,setError] = useState({
    companyname:false,
    status:false,
    reg_phone_number:false,
    reg_business_number: false,
    reg_company_name:false,
    users_name: false,
    email_address: false,
    validemailmsg:"",
    nameMSG:"",
    statusMSG:"",
    regNoMsg:"",
    regBusNoMsg: "",
    regNameMsg:"",
    reg_addressmsg:"",
  })
  
  const validateChannel = invite => {
    let channelCount = 0;
    const nwerror = { ...error};

    if(invite.companyname === ""){
      nwerror.companyname = true;
      nwerror.nameMSG = "This field is required"
      channelCount++;
      cmpyNameRef.current.focus();
    }
    else{

      if(error.companyname === true){
        nwerror.companyname = true;
        nwerror.nameMSG = error.nameMSG;
        channelCount++;
        cmpyNameRef.current.focus();
      }
      else{
        nwerror.companyname = false;
        nwerror.nameMSG = "";
      }
    }

    if(invite.status === ""){
      nwerror.status = true;
      channelCount++;
      statusRef.current.focus();
    }
    else{
      nwerror.status = false;
    }

    if(checked){
      if(invite.email_address !== ""){
      
        if(emailreg.test(invite.email_address) === false){
            nwerror.email_address = true;
            nwerror.validemailmsg = "Enter a valid email address";
            channelCount++;
        }
        else if(emailRes === false){
            nwerror.email_address = true;
            nwerror.validemailmsg = "This email already used";
            channelCount++;
        }
        else{
          nwerror.email_address = false;
          nwerror.validemailmsg = "";
        }
      }
      else{
        nwerror.email_address = true;
        nwerror.validemailmsg = "This field is required";
        channelCount++;
      }

      if(invite.users_name !== ""){
      
          nwerror.users_name = false;
      }
      else{
        nwerror.users_name = true;
        channelCount++;
      }
    }

    if (invite.reg_company_name === "") {
      nwerror.reg_company_name = false;
      nwerror.regNameMsg = "";
    }
    else{
      if(nameReg.test(invite.reg_company_name) === false){
        nwerror.reg_company_name = true;
        nwerror.regNameMsg = "Field must have at least 1 character a-z or A-Z";
        channelCount++;
      }
      else{
        nwerror.reg_company_name = false;
        nwerror.regNameMsg = "";
      }
    }
    

    if (invite.reg_address === "") {
      nwerror.reg_address = false;
      nwerror.reg_addressmsg = "";
    }
    else{
      if(nameReg.test(invite.reg_address) === false){
        nwerror.reg_address = true;
        nwerror.reg_addressmsg = "Field must have at least 1 character a-z or A-Z";
        channelCount++;
      }
      else{
        nwerror.reg_address = false;
        nwerror.reg_addressmsg = "";
      }
    }

    if(invite.reg_business_number === "") {
      nwerror.reg_business_number = false;
      nwerror.regBusNoMsg = "";
    }
    else{
      if (validBusinessRegex.test(invite.reg_business_number) === false) {
        nwerror.reg_business_number = true;
        nwerror.regBusNoMsg = "Only alphanumeric characters or underscore and the first character must be a letter";
        channelCount++;
      } else {
        nwerror.reg_business_number = false;
        nwerror.regBusNoMsg = "";
      }
    }
        

    if(invite.reg_phone_number === "") {
      nwerror.reg_phone_number = false;
      nwerror.regNoMsg = "";
    }
    else{
      if (validNumberRegex.test(invite.reg_phone_number) === false) {
        nwerror.reg_phone_number = true;
        nwerror.regNoMsg = "Valid characters are '+' 0-9 and space";
        channelCount++;
      } else {
        nwerror.reg_phone_number = false;
        nwerror.regNoMsg = "";
      }
    }

    setError(nwerror);
    return channelCount;

  }
  //invite companyUser
  const [errMsg, setErrMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [clickSave, setClickSave] = useState(false);
  const cmpyNameRef = React.useRef();
  const statusRef = React.useRef();
  async function inviteCompany() {
    const chCount = validateChannel(invite);
    if(chCount === 0){
    setLoading(true);
    setClickSave(true);
      trackPromise(
        Service.AddLicenseeCompany(invite).then((res) => {
      
          if (res.status === 200) {
            handleClose();
            setsuccessOpen(true);
            setOpen(false);
            setClickSave(false);
            setInvite({
              company_types: "CHANNEL",
              licenseeId: "",
              companyname: "",
              status: "",
              reg_company_name: "",
              reg_business_number: "",
              reg_address: "",
              reg_phone_number: "",
              option_create_user: "",
              users_name: "",
              email_address: "",
            })
            setLoading(false);
            LicenseeIndexdata();
          }
        }).catch((error)=>{
          setErrMsg("Something went wrong. Please reload the page.");
          seterrorOpen(true);
        })
      );
    }
    
  }

  const [empty, setEmpty] = useState(false);
  async function LicenseeIndexdata() {
    data['searchLicenseeId'] = localStorage.getItem("licenseeId")
    setSearch(true);
    setChangedropdown(true);
    setEmpty(false);
    setChannelList({})
    try {
      trackPromise(
        Service.licenseeIndexRequest(data).then((res) => {
          setChannelList(res.data.channelListResult);
          if (res.status == 200 && res.data.channelListResult.length > 0) {
         
            setEmpty(false);
            setChangedropdown(false);
          } else if (res.status == 200 && res.data.channelListResult.length == 0) {
            setEmpty(true);
            setChangedropdown(false);
          } else {
            setChangedropdown(true);
            setEmpty(false);
          }
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page.");
      seterrorOpen(true);
    }
  }
  const dataChange = (e) => {
    e.preventDefault();
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    
 
    setData(newdata);
  };
  const handleRouteLink = (companytype, companyId) => {
    if (companytype === "CHANNEL") {
      history.push(`/licensee/channel/${companyId}/detail/view`, {
        companyId: companyId,
      });
    }
  };
  const [invite, setInvite] = useState({
    company_types: "CHANNEL",
    licenseeId: "",
    companyname: "",
    status: "",
    reg_company_name: "",
    reg_business_number: "",
    reg_address: "",
    reg_phone_number: "",
    option_create_user: "",
    users_name: "",
    email_address: "",
     
  });
  const validNumberRegex = /^(?=.*[0-9])[+ 0-9]+$/i;
  const emailreg = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
  const validBusinessRegex = /^[0-9A-Za-z\s+-]/i;
  const nameReg = /^.*[A-Za-z].*/i;

  const InviteCompany = (e) => {
    e.preventDefault();
    const newdata = { ...invite };
    newdata[e.target.id] = e.target.value;
    if(e.target.id === "companyname"){
      if (e.target.value) {
        companyNameCheck(e.target.value);
      } else {
        setError({
          ...error,
          companyname: true,
          nameMSG:"This field is required"
         
        });
      }
    }
    if (e.target.id === "status") {
      if(e.target.value){
        setError({
          ...error,
          status: false,
        });
      }
      else{
        setError({
          ...error,
          status: true,
        });
      }
    }
    if (e.target.id === "reg_business_number") {
      if(e.target.value){
        if (validBusinessRegex.test(e.target.value) === false && e.target.value != "") {
          setError({
            ...error,
            reg_business_number: true,
            regBusNoMsg: "Only alphanumeric characters or underscore and the first character must be a letter",
          });
        } else {
          setError({
            ...error,
            reg_business_number: false,
            regBusNoMsg:""
          });
        }
      }
      else{
        setError({
          ...error,
          reg_business_number: false,
          regBusNoMsg:""
        });
      }
      
    }

    if (e.target.id === "reg_company_name") {
      if(e.target.value){
        if (nameReg.test(e.target.value) === false) {
          setError({
            ...error,
            reg_company_name: true,
            regNameMsg: "Field must have at least 1 character a-z or A-Z",
          });
        } else {
          setError({
            ...error,
            reg_company_name: false,
            regNameMsg: ""
          });
        }
      }
      else{
        setError({
          ...error,
          reg_company_name: false,
          regNameMsg: ""
        });
      }
      
    }

    if (e.target.id === "users_name") {
      if(checked){
        if(e.target.value){
          setError({
            ...error,
            users_name: false,
          });
        }
        else{
          setError({
            ...error,
            users_name: true,
          });
        }
      }
      else{
        setError({
          ...error,
          users_name: false,
        });
      }
    }
    if (e.target.id === "email_address") {
      if(checked){
        if (e.target.value) {
          EmailCheck(e.target.value);
        } else {
          setError({
            ...error,
            email_address: true,
            validemailmsg: "This field is required",
          });
        }
      }
      else{
        setError({
          ...error,
          email_address: false,
          validemailmsg: "",
        });
      }
    }

    if (e.target.id === "reg_phone_number") {
      if(e.target.value){
        if (validNumberRegex.test(e.target.value) === false && e.target.value != "") {
          setError({
            ...error,
            reg_phone_number: true,
            regNoMsg: "Valid characters are '+' 0-9 and space",
          });
        } else {
          setError({
            ...error,
            reg_phone_number: false,
            regNoMsg:""
          });
        }
      }
      else{
        setError({
          ...error,
          reg_phone_number: false,
          regNoMsg:""
        });
      } 
    }

    if (e.target.id === "reg_address") {
      if(e.target.value){
        if (nameReg.test(e.target.value) === false) {
          setError({
            ...error,
            reg_address: true,
            reg_addressmsg: "Field must have at least 1 character a-z or A-Z",
          });
        } else {
          setError({
            ...error,
            reg_address: false,
            reg_addressmsg:""
          });
        }
      }
      else{
        setError({
          ...error,
          reg_address: false,
          reg_addressmsg:""
        });
      } 
    }

    if (checked) {
      newdata["option_create_user"] = "yes";
    } else {
      newdata["option_create_user"] = "no";
    }

    setInvite(newdata);
  };
  const [emailRes, setEmailRes] = useState();
  ////Check Email
  async function EmailCheck(value) {
    trackPromise(
      Service.licenseeEmailExist({ email_address: value })
        .then((res) => {
          setEmailRes(res.data);
          if (res.data === false) {
            setError({
              ...error,
              email_address: true,
              validemailmsg: "This​ email ​already​ used",
            });
          } else if (value && emailreg.test(value) === false) {
            setError({
              ...error,
              email_address: true,
              validemailmsg: "Please enter a valid email address.",
            });
          } else {
            setError({
              ...error,
              email_address: false,
            });
          }
        })
        .catch((error) => {
          setErrMsg("Something went wrong. Please reload the page.");
          seterrorOpen(true);
        })
    );
  }
////CompanyNameCheck
async function companyNameCheck(cname) {

  if(cname.includes(",")){
    setError({
      ...error,
      companyname: true,
      nameMSG: "Please enter a name without comma",
    });
  }
  else if(nameReg.test(cname) === false){
    setError({
      ...error,
      companyname: true,
      nameMSG: "Field must have at least 1 character a-z or A-Z",
    });
  }
  else{
    try {
      trackPromise(
        Service.licenseeCompanyName({ companyname: cname }).then((res) => {
          if (res.data === false) {
            setError({
              ...error,
              companyname: true,
              nameMSG: "This​ Company ​name ​already​ exist",
            });
          } 
           else {
            setError({
              ...error,
              companyname: false,
              nameMSG:""
            });
          }
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page.");
      seterrorOpen(true);
    }
  }
 
}


  useEffect(() => {
    document.title = "Datafree Portal";
    Userdata();
  }, []);

  return (
    <div className={toggleContextVal.event ?"mainMobView" :"mainBoxView"}>
      <div className="NavBoxDiv">
        <div className="NavBoxRow col-lg-6 col-md-6 col-sm-6 col-xs-5">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">Channel List - Licensee View</div>
        </div>
        <div className="NavBoxRowFilter table-responsive">
        <table className="table-sm">
            <tbody>
              <tr>
                <td className="col-md-2">
                Channel ID
                </td>
                <td className="col-md-2">
                Channel Name
                </td>
                <td className="col-md-1">
                Status
                </td>
                <td className="col-md-2">
               
                </td>
               
              </tr>
              <tr>
                <td className="col-md-1">
                <input
                  type="text"
                  className="form-control"
              
                  id="searchChannelID"
                  name="searchChannelID"
                  onChange={(e) => dataChange(e)}
                ></input>
                </td>
                <td className="col-md-2">
                <input
                  type="text"
                  className="form-control"
                  
                  id="searchChannelName"
                  name="searchChannelName"
                  onChange={(e) => dataChange(e)}
                ></input>
                </td>
                <td className="col-md-1">
                <select
                  type="text"
                  className="form-control form-select"
                  id="searchChannelStatus"
                 
                  name="searchChannelStatus"
                  onChange={(e) => dataChange(e)}
                >
                  <option value=""></option>
                  <option value="New">New</option>
                  <option value="Approved">Approved</option>
                </select>

                </td>
                <td className="col-md-3">
                <button
                className={changedropdown ? "defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"}
                disabled={changedropdown ? "disabled" : false}
               
                onClick={LicenseeIndexdata}
              >
                Search
              </button>
                </td>
               
               
              </tr>
            </tbody>
          </table>

        </div>
        <div><hr className="applications-list-hr"></hr></div>
        <div className="createButtonDiv">
          <button className="licbtn" onClick={() => handleClickOpen("body")}
          title="Click here to create new Company">
            + Create New Channel
          </button>
        </div>
        
        {search ? (
          <div>
            {channelList && channelList.length > 0 ? (
              <VMCTable key={channelList} data={channelList}  col={tablecols} navigateFunction={handleRouteLink} tableName="Licensee Channel"/>
              ) :  
              
              <div>
              <LoadingIndicator />
              {empty ? <EmptyTable data="No Companies Found" col={tablecols} tableName="Connect Utility Table" /> : null}
              </div>
            }  
              </div>
              ) : (
                ""
              )}
           
      </div>
      <Dialog
        open={open}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        maxWidth="sm"
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{borderBottom: '1px solid #e5e5e5'}}>
          <div className="cmpdialogtitle">Create Channel - Licensee View
          <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <div className="cmpdialoginputtext">Company Type</div>
              <input
                type="text"
                value="CHANNEL"
                label="name"
                id="company_types"
                name="company_types"
                className="form-control"
                disabled="disabled"
                
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Company Name</div>
              <input
                type="text"
                id="companyname"
                name="companyname"
                label="email"
                ref={cmpyNameRef}
                className={error.companyname ? "form-control errorField" : "form-control"}
                onChange={(e) => InviteCompany(e)}
                fullWidth
              />
               {error.companyname ? <a className="errorClass">{error.nameMSG ? error.nameMSG : "This field is required"}</a> : null}
            </Grid>
            <Grid item xs={6}>
              <div className="cmpdialoginputtext">Status</div>
              <select
                type="text"
                id="status"
                name="status"
                style={{height:'34px'}}
                onChange={(e) => InviteCompany(e)}
                className={error.status?"form-control form-select errorField":"form-control form-select"}
                ref={statusRef}
                fullWidth
              >
                <option></option>
                <option value="New">New</option>
                <option value="NoAgreement">NoAgreement</option>
              </select>
              {error.status ? <a className="errorClass">{error.statusMSG ? error.statusMSG : "This field is required"}</a> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Registered Company Name</div>
              <input
                type="text"
                id="reg_company_name"
                name="reg_company_name"
                className={error.reg_company_name? "form-control errorField" : "form-control" }
                onChange={(e) => InviteCompany(e)}
                fullWidth
              />
              {error.reg_company_name ? <a className="errorClass">{error.regNameMsg}.</a> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">
                Registered Business Number (if appropriate)
              </div>
              <input
                type="text"
                id="reg_business_number"
                name="reg_business_number"
                onChange={(e) => InviteCompany(e)}
                className={error.reg_business_number ? "form-control errorField" : "form-control"}
                fullWidth
              />
                {error.reg_business_number ? <a className="errorClass">{error.regBusNoMsg}.</a> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Registered Address</div>
              <input
                type="text"
                id="reg_address"
                name="reg_address"
                onChange={(e) => InviteCompany(e)}
                className={error.reg_address ? "form-control errorField" : "form-control"}
                fullWidth
              />
              {error.reg_address ? <a className="errorClass">{error.reg_addressmsg}.</a> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Company Phone Number</div>
              <input
                type="text"
                id="reg_phone_number"
                name="reg_phone_number"
                className={error.reg_phone_number?"form-control errorField":"form-control"}
                onChange={(e) => InviteCompany(e)}
                fullWidth
              />
               {error.reg_phone_number? <a className="errorClass">{error.regNoMsg ? error.regNoMsg : "This field is required"}</a> : null}
            </Grid>
            <div>
              <input
                type="checkbox"
                name="option_create_user"
                id="option_create_user"
                onChange={() => setChecked(!checked)}
                checked={checked}
              />
              <label for="websiteName" className="cmpdialoginputtext">
                Optionally Create User ?
              </label>
            </div>
            {checked ? (
              <>
                <Grid item xs={12}>
                  <div className="cmpdialoginputtext">User’s Name</div>
                  <input
                    type="text"
                    id="users_name"
                    label="users_name"
                    onChange={(e) => InviteCompany(e)}
                    className={error.users_name ? "form-control errorField" : "form-control"}
                    fullWidth
                  />
                  {error.users_name ? <a className="errorClass">This field is required.</a> : null}
                </Grid>
                <Grid item xs={12}>
                  <div className="cmpdialoginputtext">
                    Email Address (username)
                  </div>
                  <input
                    type="text"
                    id="email_address"
                    label="email_address"
                    onChange={(e) => InviteCompany(e)}
                    className={error.email_address ? "form-control errorField" : "form-control"}
                    fullWidth
                  />
                    {error.email_address? <a className="errorClass">{error.validemailmsg ? error.validemailmsg : "This field is required"}</a> : null}
                </Grid>
              </>
            ) : (
              <div></div>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
        {loading ?  <div className="loaderContainer">
            <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} /></div>:null}          <div className="ivitediv">
            <button
              className="inviteButton"
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </button>
            <button
              className="inviteButton"
              onClick={inviteCompany}
              disabled={clickSave ? "disabled" : false}
              color="primary"
            >
              Save
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={successopen}
        autoHideDuration={1500}
        onClose={handlesuccessAlert}
      >
        <Alert onClose={handlesuccessAlert} severity="success">
          "New Channel Created Successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={erroropen}
        autoHideDuration={2500}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
         {errMsg ? errMsg : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default ChannelList;
