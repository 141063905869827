import axios from "axios";
import {
  API_URL,
  authRequests,
  datafreeRequests,
  userProfileRequests,
  utilityRequests,accountRequests, gettingStartRequests, companyListRequests,
  reportingRequests,forgettenpassword,licenseeListRequests,applicationListRequests,configRequests
} from "./constants";

class service {
  //login
  async Login(data) {
    const response = await axios.post(`${API_URL}${authRequests.login}`, data);
    return response;
  }
   //login
   async UserLogin(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(`${API_URL}${authRequests.verifiedLogin}`, data, { headers });
    return response;
  }
  //Register
  async RegisterForm(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${gettingStartRequests.gettingStartedRegister}`,
      data,
      { headers }
    );
    return response;
  }

 //adminLoginCheck
async CheckLogin() {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
 
  const response = await axios.get(
    `${API_URL}${authRequests.checkLoginUser}`,
  
    { headers }
    // { headers,params: data  }
  );
  return response;
}
 //getChangeAccountData
 async ChangeAccount() {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
 
  const response = await axios.get(
    `${API_URL}${authRequests.getchangeAccount}`,
  
    { headers }
    // { headers,params: data  }
  );
  return response;
}
  //Get User Data
  async GetUserData() {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.get(
      `${API_URL}${userProfileRequests.getUserData}`,
      { headers }
    );
    return response;
  }
  //update UserData
  async UpdateUser(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${userProfileRequests.updateUserData}`,
      data,
      { headers }
    );
    return response;
  }
//report Download

async reportDownload(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${userProfileRequests.reportDownload}`,
    data,
    { headers }
  );
  return response;
}
async ReachSecuritySave(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${gettingStartRequests.saveReachSecutity}`,
    data,
    { headers }
  );
  return response;
}
//reach Prod Security
async ReachProdSecuritySave(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${gettingStartRequests.reachProdSecurity}`,
    data,
    { headers }
  );
  return response;
}

//generateAppId
async generateApp(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${gettingStartRequests.generateAppId}`,
    data,
    { headers }
  );
  return response;
}
//appCreateConfig Check
async createConfigCheck(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${datafreeRequests.appCreated}`,
    data,
    { headers }
  );
  return response;
}
//apkConfig Edit

async apkEdit(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${datafreeRequests.editApkConfig}`,
    data,
    { headers }
  );
  return response;
}
 //getconfigDetailsById
 async configDetails(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${datafreeRequests.getConfigdetails}`,
    data,
    { headers }
  );
  return response;
}
 //look and feel
 async editLookandFeel(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${datafreeRequests.configLookandFeel}`,
    data,
    { headers }
  );
  return response;
}
//configEntryPointEdit
async editEntryPoint(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${datafreeRequests.configEntryPoint}`,
    data,
    { headers }
  );
  return response;
}

//configSecurityEdit
async editSecurityMax(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${datafreeRequests.configSecurityEdit}`,
    data,
    { headers }
  );
  return response;
}
//maxProdSecurityEdit
async editProdSecurity(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${datafreeRequests.maxProdSecurityEdit}`,
    data,
    { headers }
  );
  return response;
}
//delete App Registration
async deleteAppReg(data,name) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${datafreeRequests.deleteAppRegistration}${name}`,data,
  
    { headers }
  );
  return response;
}


// App creation exists
async createdApp(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${datafreeRequests.createdApp}`,data,
  
    { headers }
  );
  return response;
}

//xml validate config
async validateXml(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${datafreeRequests.xmlValidateConfig}`,data,
  { headers }
  );
  return response;
}


// Build Wrap
async buildWrap(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${datafreeRequests.buildWrap}`,data,
  
    { headers }
  );
  return response;
}


// bucketWrap Wrap
async bucketWrap(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${datafreeRequests.bucketWrap}`,data,
  
    { headers }
  );
  return response;
}

// check config checkbox Wrap
async wrapConfigCheck(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${datafreeRequests.wrapConfigCheck}`,data,
  
    { headers }
  );
  return response;
}

  //update UserPassword
  async updatePassword(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${userProfileRequests.updatePassword}`,
      data,
      { headers }
    );
    return response;
  }
  //show data to Card in Datafree
  async IndexData() {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };

    const response = await axios.get(
      `${API_URL}${datafreeRequests.getCardDataIndex}`,
      { headers }
    );
    return response;
  }
  
  //Search By lifecycle,producttype,freedomain
  async Search(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${datafreeRequests.search}`,
      data,
      { headers }
    );
    return response;
  }
  //show details in publisherDesigner Page
   async applicationData(id){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.get(
      `${API_URL}${datafreeRequests.applicationDetails}/${id}/details`, {headers}
      
    );
    return response;
  }

  // User ****************
  async Cloneconfigration(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    let kk = 'https://publisher-s-tab-ui.xminds.bi.nu/user/clone/config'
    let tt = 'https://publisher-s-tab-ui-upgrade.xminds.com/v1/clone/config'
   
    const response = await axios.post(
      tt,
      data,
      { headers }
    );
    return response;
  }

  //show details in publisherDesigner Page
  async utilityData(id){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.get(
      `${API_URL}${utilityRequests.getUtilities}`, {headers}
      
    );
    return response;
  }
  //AccountDetail View
  async accountData(id){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.get(
      `${API_URL}${accountRequests.getAccountDetails}`, {headers}
      
    );
    return response;
  }
  //edit Account Details
  async EditAccount(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${accountRequests.editAccountDetails}`,
      data,
      { headers }
    );
    return response;
  }
  //invite User
  async inviteUser(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${accountRequests.inviteUser}`,
      data,
      { headers }
    );
    return response;
  }
  //create clone config
  
  async cloneconfig(data) {
    let auth = localStorage.getItem("token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth,
    };
    const response = await axios.post(
      `${API_URL}${configRequests.cloneConfig}`,
      data,
      { headers }
    );
    return response;
  }
  //reportApplications 
  
  async reportData(id){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.get(
      `${API_URL}${reportingRequests.getReportByIndex}`, {headers}
      
    );
    return response;
  }
  //reportperiod 
  
  async reportPeriod(id){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${reportingRequests.getPeriodIndex}`,id,
      { headers }
      
    );
    return response;
  }
  //search report
  async searchReport(body){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${reportingRequests.reportingSearch}`,body,
      { headers }
      
    );
    return response;
  }

  //Reach Prod Save
  async saveReachProd(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${datafreeRequests.saveReachProd}`,data,
      { headers }
      
    );
    return response;
  }

  //Check have switch app created
  async ifAppId(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${datafreeRequests.ifAppId}`,data,
      { headers }
      
    );
    return response;
  }

  //Create switch dev and prod
  async createSwitch(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${datafreeRequests.createSwitch}`,data,
      { headers }
      
    );
    return response;
  }

  //Create switch dev and prod
  async saveSwitcDev(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${datafreeRequests.saveSwitcDev}`,data,
      { headers }
      
    );
    return response;
  }


  //Edit switch Prod security
  async editSecurity(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${datafreeRequests.editSecurity}`,data,
      { headers }
      
    );
    return response;
  }

  //Add switch Prod NEw Event
  async addEventsNew(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${datafreeRequests.addEventsNew}`,data,
      { headers }
      
    );
    return response;
  }

  //get wrap platform versions
  async popupPlatform(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${datafreeRequests.popupPlatform}`,data,
      { headers }
      
    );
    return response;
  }

  //get wrap Yes Click
  async yesClick(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${datafreeRequests.yesClick}`,data,
      { headers }
      
    );
    return response;
  }

  //get wrap No Click
  async noClick(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${datafreeRequests.noClick}`,data,
      { headers }
      
    );
    return response;
  }

  //view Build APK 
  async viewBuildApk(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${datafreeRequests.viewBuildApk}`,data,
      { headers }
      
    );
    return response;
  }

  // Create Build APK 
  async createApk(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${datafreeRequests.createApk}`,data,
      { headers }
      
    );
    return response;
  }

  // View Build History
  async viewBuildHistory(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${datafreeRequests.viewBuildHistory}`,data,
      { headers }
      
    );
    return response;
  }

  // View Build History check config
  async checkConfig(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${datafreeRequests.checkConfig}`,data,
      { headers }
      
    );
    return response;
  }

  // View Build History moreInfo
  async viewMore(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${datafreeRequests.viewMore}`,data,
      { headers }
      
    );
    return response;
  }
 //showConfig
   async showConfigData(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${companyListRequests.showConfig}`,data,
      { headers }
      
    );
    return response;
   }
   //convertSelfSave
   async convertSelf(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${companyListRequests.convertAppSelf}`,data,
      { headers }
      
    );
    return response;
   }
   // Wrap apk Config Edit
   async wrapApkConfig(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${datafreeRequests.wrapApkConfig}`,data,
      { headers }
      
    );
    return response;
  }
 //launch Xml Status

 async getLaunchStatus(data){
  let auth = localStorage.getItem("token");
  const headers = {
         Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth
  }
  
  const response = await axios.post(
    `${API_URL}${datafreeRequests.launchXmlStatus}`,data,
    { headers }
    
  );
  return response;
}
  // Wrap get Config Details
  async getConfig(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${datafreeRequests.getConfig}`,data,
      { headers }
      
    );
    return response;
  }
  
  // Wrap look feel edit
  async lookEdit(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${datafreeRequests.lookEdit}`,data,
      { headers }
      
    );
    return response;
  }

  // Wrap security edit
  async securityEdits(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${datafreeRequests.securityEdits}`,data,
      { headers }
      
    );
    return response;
  }

  // Wrap entry point edit validate XML
  async validateXml(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${datafreeRequests.validateXml}`,data,
      { headers }
      
    );
    return response;
  }
  //forgetPassword
  async forgetPassword(body){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${forgettenpassword.forgetPassword}`,body,
      { headers }
      
    );
    return response;
  }
  //edit admin licensee Detail
   async editAdminLicensee(body){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${licenseeListRequests.editLicenseeDetailView}`,body,
      { headers }
      
    );
    return response;
  }
  //applicationIndex
  async applicationIndex(id){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.get(
      `${API_URL}${applicationListRequests.getApplicationIndex}`, {headers}
      
    );
    return response;
  }

    //New Schedule reporting Search
  async reportSearch(data){
    let auth = localStorage.getItem("token");
    const headers = {
           Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth
    }
    
    const response = await axios.post(
      `${API_URL}${companyListRequests.reportSearch}`,data,
      { headers }
      
    );
    return response;
  }

    //New reporting Search
    async scheduleReportSave(data){
      let auth = localStorage.getItem("token");
      const headers = {
             Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth
      }
      
      const response = await axios.post(
        `${API_URL}${companyListRequests.scheduleReportSave}`,data,
        { headers }
        
      );
      return response;
    }

    //New reporting Search get AppID
    async getAppId(data){
      let auth = localStorage.getItem("token");
      const headers = {
             Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth
      }
      
      const response = await axios.post(
        `${API_URL}${companyListRequests.getAppId}`,data,
        { headers }
        
      );
      return response;
    }

    

    //New reporting deleteReport
    async deleteReport(data){
      let auth = localStorage.getItem("token");
      const headers = {
             Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth
      }
      
      const response = await axios.post(
        `${API_URL}${companyListRequests.deleteReport}`,data,
        { headers }
        
      );
      return response;
    }

    //verify Email address
async verifyUser(name) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.get(
    `${API_URL}${companyListRequests.verifyUser}${name}`,
  
    { headers }
  );
  return response;
}


 //verify Email address reset Password
 async resetVerify(name) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.get(
    `${API_URL}${companyListRequests.resetVerify}${name}`,
  
    { headers }
  );
  return response;
}

//create new Password
async newPass(data) {
  let auth = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + auth,
  };
  const response = await axios.post(
    `${API_URL}${companyListRequests.newPass}`,data,
  
    { headers }
  );
  return response;
}
}

export default new service();
