import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import AdminService from "../../../api/adminService";
import { trackPromise } from "react-promise-tracker";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import Loader from "react-loading";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AdminDatafreeGatewayDomainCreate() {
  let history = useHistory();
  const [manageList, setManageList] = useState();
  const [CompanyName, setCompanyName] = useState();
  const [companyType, setCompanyType] = useState();
  const [serviceData, setServiceData] = useState();
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const regexDomain = /^[a-zA-Z0-9][\w\.-]+[a-zA-Z0-9]$/i;
  const [channel] = useState({
    channelId: "",
  });
  const [company] = useState({
    companyId: "",
  });
  const [service] = useState({
    service: "",
  });
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
    history.push("/admin/datafreegatewaydomain/index");
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };
  const route =()=>{
    history.push("/admin/datafreegatewaydomain/index");
  }
  const [data, setData] = useState({
    managed_by: "",
    company_name: "",
    company_type: "",
    gateway_domain: "",
    status: "",
    data_free_description: "",
    point_licensee_service: "",
  });

  const [error, setError] = useState({
    managed_by: false,
    company_name: false,
    gateway_domain: false,
    status: false,
    point_licensee_service: false,
    msg:"",
    gatewaymsg: "",
  });
  const [message, setMessage] = useState();
  //managed By List
  async function manageListdata() {
    try {
      trackPromise(
        AdminService.datafreeGatewayDomain().then((res) => {
          setManageList(res.data.publisherListResult);
        })
      );
    } catch (error) {
        setMessage(error.response.data);
        seterrorOpen(true);
    }
  }
  //companyName
  async function ComapnyName(companyId) {
    channel["channelId"] = companyId;
    try {
      trackPromise(
        AdminService.getCompanyName_GatewayDomain(channel).then((res) => {
          setCompanyName(res.data);
        })
      );
    } catch (error) {
      setMessage(error.response.data);
      seterrorOpen(true);
    }
  }
  //companyType
  async function CompanyType(companyName) {
    company["companyId"] = companyName;
    try {
      trackPromise(
        AdminService.getCompanyType_GatewayDomain(company).then((res) => {
          setCompanyType(res.data);
        })
      );
    } catch (err) {
   
    }
  }
  //Service
  async function Service(serviceId) {
    service["service"] = serviceId;
    try {
      trackPromise(
        AdminService.getLicenseeService_GatewayDomain(service).then((res) => {
          setServiceData(res.data);
        })
      );
    } catch (err) {
     
    }
  }
  //save
  const [loading, setLoading] = useState(false);
  const [saveClick, setSaveClick] = useState(false);
  async function SaveData() {
    setLoading(true);
    setSaveClick(true);
    try {
      trackPromise(AdminService.SaveDatafreeGateway(data).then((res) => {
        setSaveClick(false);
        if (res.status === 200) {
          setsuccessOpen(true);
          setLoading(false);
        }
      }));
    } catch (error) {
      setMessage(error.response.data);
      seterrorOpen(true);
    }
  }
  const lifecycleChange = (e) => {
    e.preventDefault();
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    
    if (e.target.id === "managed_by") {
      ComapnyName(e.target.value);
      if(e.target.value){
        setError({
          ...error,
          managed_by: false,
        });
      }
      else{
        setError({
          ...error,
          managed_by: true,
          msg: "This field is required."
        });
      }
      
    }
    if (e.target.id === "company_name") {
      CompanyType(e.target.value);
      newdata['company_type']=e.target.value

      if(e.target.value){
        setError({
          ...error,
          company_name: false,
          msg: ""
        });
      }
      else{
        setError({
          ...error,
          company_name: true,
          msg: "This field is required."
        });
      }
      
    }
    if (e.target.id === "point_licensee_service") {
      Service(e.target.value);

      if(e.target.value){
        setError({
          ...error,
          point_licensee_service: false,
          msg: ""
        });
      }
      else{
        setError({
          ...error,
          point_licensee_service: true,
          msg: "This field is required."
        });
      }
    }

    if (e.target.id === "company_type") {
      
      if(e.target.value){
        setError({
          ...error,
          company_type: false,
          msg: ""
        });
      }
      else{
        setError({
          ...error,
          company_type: true,
          msg: "This field is required."
        });
      }
    }

    if (e.target.id === "status") {
      
      if(e.target.value){
        setError({
          ...error,
          status: false,
          msg: ""
        });
      }
      else{
        setError({
          ...error,
          status: true,
          msg: "This field is required."
        });
      }
    }


    if (e.target.id === "gateway_domain") {
      
      if(e.target.value){
        if(regexDomain.test(e.target.value) === false){
          setError({
            ...error,
            gateway_domain: true,
            gatewaymsg: "Invalid Domain"
          });
        }
        else{
          setError({
            ...error,
            gateway_domain: false,
            gatewaymsg: ""
          });
        }
      }
      else{
        setError({
          ...error,
          gateway_domain: true,
          gatewaymsg: "This field is required."
        });
      }
    }

   
    setData(newdata);
  };

  function validateDate(){
    let error = {};
    let count = 0;
    let msg = "This field required";
    if(data.managed_by === ""){
      error.managed_by = true;
      error.msg = msg;
      count++;
    }
    else{
      error.managed_by = false;
      error.msg = "";
    }

    if(data.company_name === ""){
      error.company_name = true;
      error.msg = msg;
      count++;
    }
    else{
      error.company_name = false;
      error.msg = "";
    }

    if(data.company_type === ""){
      error.company_name = true;
      error.msg = msg;
      count++;
    }
    else{
      error.company_type = false;
      error.msg = "";
    }

    if(data.status === ""){
      error.status = true;
      error.msg = msg;
      count++;
    }
    else{
      error.status = false;
      error.msg = "";
    }

    if(data.gateway_domain === ""){
      error.gateway_domain = true;
      error.gatewaymsg = msg;
      count++;
    }
    else{
      if(regexDomain.test(data.gateway_domain) === false){
        error.gateway_domain = true;
        error.gatewaymsg = "Invalid Domain";
        count++;
      }else{
        error.gateway_domain = false;
        error.gatewaymsg = "";
      }
    }

    if(data.point_licensee_service === ""){
      error.point_licensee_service = true;
      error.msg = msg;
      count++;
    }
    else{
      error.point_licensee_service = false;
      error.msg = "";
    }
    setError(error);

    if(count === 0){
      SaveData();
    }
  }
  
  useEffect(() => {
    manageListdata();
  }, []);

  return (
    <div>
      <div className="NavBoxRow">
        <div className="NavBoxRowText">Company Specific #datafree Gateway Domain Creation</div>
      </div>
      <div className="directapplication">
        <Grid container>
          <Grid item xs={12} sm={4} md={4}>
            <div className="company_Label">Managed By</div>
            <div>
              <select type="text" 
            
              className={
                !error.managed_by ? "form-control form-select" : "form-control form-select errorField"
              }
              id="managed_by" name="managed_by" onChange={(e) => lifecycleChange(e)}>
                <option value=""></option>
                {manageList && manageList.length > 0
                  ? manageList.map((post) => (
                      <option key={post.companyId} value={post.companyId}>
                        {post.name}
                      </option>
                    ))
                  : null}
              </select>
              {error.managed_by ? (
                <label style={{float:'left', marginBottom:'17px', marginTop:'0px'}} className="errorClass">{error.msg}</label>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item  xs={12} sm={4} md={4}></Grid>
          <Grid item xs={12} sm={4} md={4}></Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className="company_Label">Company Name</div>
            <div>
              <select type="text" 
             
              className={
                !error.company_name ? "form-control form-select" : "form-control form-select errorField"
              }
              id="company_name" name="company_name" onChange={(e) => lifecycleChange(e)}>
                <option value=""></option>
                {CompanyName && CompanyName.length > 0
                  ? CompanyName.map((post) => (
                      <option key={post.id} value={post.id}>
                        {post.name}
                      </option>
                    ))
                  : null}
              </select>
              {error.company_name ? (
                <span style={{float:'left', marginBottom:'17px', marginTop:'0px'}} className="errorClass">{error.msg}</span>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={1} md={1}></Grid>
          <Grid item xs={12} sm={2} md={2}>
            <div className="company_Label">Company Type</div>
            <div>
              <input type="text" 
              className="form-control" 
              id="company_type" name="company_type" disabled="disabled" 
              onChange={(e) => lifecycleChange(e)} value={companyType?.companies} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}></Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className="company_Label">#datafree Gateway Domain</div>
            <div>
              <input type="text" 
              className={
                !error.gateway_domain ? "form-control" : "form-control errorField"
              }
              id="gateway_domain"  name="gateway_domain" onChange={(e) => lifecycleChange(e)}></input>
              
              {error.gateway_domain ? (
                <span style={{float:'left', marginBottom:'17px', marginTop:'0px'}} className="errorClass">{error.gatewaymsg}</span>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={1} md={1}></Grid>
          <Grid item  xs={12} sm={2} md={2}>
            <div className="company_Label">Status</div>
            <div>
              <select type="text" 
            
              className={
                !error.status ? "form-control form-select" : "form-control form-select errorField"
              }
              
              id="status" name="status" onChange={(e) => lifecycleChange(e)}>
                <option value=""></option>
                <option value="LIVE">LIVE</option>
                <option value="OFF">OFF</option>
              </select>
              {error.status ? (
                <span style={{float:'left', marginBottom:'17px', marginTop:'0px'}} className="errorClass">{error.msg}</span>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}></Grid>
          <Grid item  xs={12} sm={4} md={4}>
            <div className="company_Label">Description</div>
            <div>
              <textarea type="text"  className="form-control" id="data_free_description" name="data_free_description" onChange={(e) => lifecycleChange(e)}></textarea>
            </div>
          </Grid>
          <Grid item xs={12} sm={8} md={8}></Grid>
          <Grid item xs={12} sm={12} md={12}>
            {" "}
            <div className="company_Label" style={{marginBottom:'25px'}}>Points to Licensee Service </div>
          </Grid>
          <Grid item  xs={12} sm={3} md={3}>
            <div className="company_Label">Licensee Service Name</div>
            <div>
              <select type="text" 
             
              className={
                !error.point_licensee_service ? "form-control form-select" : "form-control form-select errorField"
              }
              id="point_licensee_service" name="point_licensee_service" onChange={(e) => lifecycleChange(e)}>
                <option value=""></option>
                {companyType && companyType?.licensee?.length > 0
                  ? companyType?.licensee.map((post) => (
                      <option key={post.licenseeId} value={post.licenseeId}>
                        {post.licenseeName}
                      </option>
                    ))
                  : null}
              </select>
              {error.point_licensee_service ? (
                <span style={{float:'left', marginBottom:'17px', marginTop:'0px'}} className="errorClass">{error.msg}</span>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item  xs={12} sm={1} md={1}></Grid>
          <Grid item xs={12} sm={4} md={4}>
            <div className="company_Label">Proxy Domain</div>
            <div>
              <input type="text" 
              name="proxydomain"
              id="proxydomain"
              className="form-control" disabled="disabled" value={serviceData?.service} />
            </div>
          </Grid>
          <Grid item xs={12} sm={5} md={5}></Grid>
          <Grid item xs={12} sm={11} md={11}>
            <div className="gridHead" style={{marginBottom:'10px'}}>To be used with #datafree Products :</div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {serviceData && serviceData.supportedProducts.length > 0 ? serviceData.supportedProducts.map((item, index) => <div className="companyLabel" style={{marginBottom:'5px'}}> {"- "+item?.productName}</div>) : null}
          </Grid>
          <Grid item xs={12}  sm={12} md={12}>
          {loading ?  <div className="loaderContainer">
            <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} /></div>:null}
          <div className="ivitediv">
              <button className="inviteButton" color="primary" onClick={route}>
                Cancel
              </button>
              <button className="inviteButton" color="primary" 
              disabled={saveClick ? "disabled" : false}
              onClick={validateDate}>
                Save
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
      <Snackbar
        open={successopen}
        autoHideDuration={1500}
        onClose={handlesuccessAlert}
      >
        <Alert onClose={handlesuccessAlert} severity="success">
         #datafree Gateway Domain Created Successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={erroropen}
        autoHideDuration={1500}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {message ? message : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>

  );
}
export default AdminDatafreeGatewayDomainCreate;
