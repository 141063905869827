import React, { useState, useEffect,useContext } from "react";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  withStyles,
  Grid,
} from "@material-ui/core";
import Loader from "react-loading";
import ChannelService from "../../../api/channelService";
import Service from "../../../api/adminService";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import accountIcon from "../../../assets/img/account-icon-black.png";
import { trackPromise } from "react-promise-tracker";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { MdWarning } from "react-icons/md";
import alpha from "../../../assets/img/blank1.png";
import moment from "moment";
import {
  ToggleContext
} from "../../context/toggleContext"
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div>
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AntTabs = Tabs;

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    borderBottom: "1px solid #ddd ",
    minWidth: "80px",

    textShadow: "none",
    color: "#0059b6",

    "&$selected": {
      color: "#555",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderBottomColor: "transparent",
      borderBottom: "none",
      cursor: "default",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
const useStyles = makeStyles((theme) => ({
  scrollPaper: {
    alignItems: "baseline",
  },
}));
function ChannelAccount() {
  let file = null;
  const [value, setValue] = React.useState(0);
  const [editInfo, setEditInfo] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [invitedSuccess, setInvitedSuccess] = useState(false);
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  let API_URL = process.env.REACT_APP_API_URL;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [channelData, setChannelData] = useState();
  const toggleContextVal = useContext(ToggleContext)
  const handleClose = () => {
    setOpen(false);
    setEditInfo(false);
    setOpenUser(false);
    setLoading(false);
    setError({
      companyname: false,
      logo_icon_image: false,
      credit_control_email: false,
      emailMsg: "",
      nameMsg: "",
      imgMsg: ""
    });
    setInvite({
      ...invite,
      name: "",
      email_address: "",
      user_role: "",
    });

    setInviteError({
      name: false,
      email_address: false,
      user_role: false,
      companyId: false,

      nameErMsg: "",
      emailErrMsg: "",
      roleErrMsg: "",
    })

  };
  const [errMsg, setErrMsg] = useState();
  const handleOpen = () => {
    setEditInfo(true);
    localStorage.setItem("EditOpen", true);
  }
  const editOpen = () => {
    setOpen(true);
    setScroll('body');
  }
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };

  async function Channeldata() {
    try {
      trackPromise(
        ChannelService.channelUserRequest().then((res) => {
          invite["companyId"] = res.data?.userDetails?.companyId?.companyId;
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const [editdata, seteditdata] = useState({
    companyname: "",
    logo_icon_image: "",
    credit_control_email: "",
    company_id: "",
    removeCompanyLogo: ""
  });

  const [lodervisible, setlodervisible] = useState(false);
  const [clickInvite, setClickInvite] = useState(false);
  const [toastMsg, setToastMsg] = useState();
  const [loading, setLoading] = useState(false);
  const edit = () => {
    invite["companyId"] = localStorage.getItem("channelId");
    if (invite.name.length == 0 && invite.email_address.length == 0 && invite.user_role.length == 0) {
      setInviteError({
        name: true,
        email_address: true,
        user_role: true,
      });
    } else if (inviteError.name) {
      setInviteError({
        ...inviteError,
        name: true,
      });
    } else if (inviteError.email_address) {
      setInviteError({
        ...inviteError,
        email_address: true,
      });
    } else if (inviteError.user_role) {
      setInviteError({
        ...inviteError,
        user_role: true,
      });
    } else {
      setLoading(true);
      setClickInvite(true);
      try {
        trackPromise(
          ChannelService.IniteAccountUser(invite).then((res) => {
            if (res.status === 200) {
              //setEditInfo(false);
              // setInvitedSuccess(true);
              handleClose();
              setLoading(false);
              setToastMsg("New User Invited Successfully!");
              setOpen(false);
              setInvite({
                ...invite,
                name: "",
                email_address: "",
                user_role: "",
              });
              setInvitedSuccess(true);
            } else {
              seterrorOpen(true);
              setEditInfo(true);
              setOpen(true);
              setInvitedSuccess(false);
            }
            setClickInvite(false);
            AccountRequest();
          })
        );
      } catch (err) {
        setErrMsg("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      }

    }
  }

  async function AccountRequest() {
    setlodervisible(true)
    try {
      trackPromise(
        ChannelService.channelAccount().then((res) => {
          setlodervisible(false)

          setChannelData(res.data);
          setChannelCompanyId(res.data?.publisherDetail.parentCompanyId.companyId);
          seteditdata({
            companyname: res.data?.publisherDetail?.name ? res.data?.publisherDetail?.name : "",
            logo_icon_image: res.data?.publisherDetail?.logoFile ? res.data?.publisherDetail?.logoFile : "",
            credit_control_email: res.data?.publisherDetail?.creditControlEmail ? res.data?.publisherDetail?.creditControlEmail : "",
            company_id: res.data?.publisherDetail?.companyId ? res.data?.publisherDetail?.companyId : "",
            removeCompanyLogo: ""
          })
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const [error, setError] = useState({
    companyname: false,
    logo_icon_image: false,
    credit_control_email: false,
    emailMsg: "",
    nameMsg: "",
    imgMsg: ""

  });
  const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
  const nameReg = /^.*[A-Za-z].*/i;
  const emailReg = /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/;
  const [channelCompanyId, setChannelCompanyId] = useState();
  const dataChange = (e) => {
    e.preventDefault();
    const newdata = { ...editdata };
    newdata[e.target.id] = e.target.value;

    if (e.target.id === "companyname") {
      CheckCompany(e.target.value, channelCompanyId)
      if (e.target.value) {
        if ((e.target.value).includes(",")) {
          setError({
            ...error,
            companyname: true,
            nameMsg: "Please enter a name without comma",
          });
        }
        else if (nameReg.test(e.target.value) === false) {
          setError({
            ...error,
            companyname: true,
            nameMsg: "Field must have at least 1 character a-z or A-Z",
          });
        }
        else {
          setError({
            ...error,
            companyname: false,
            nameMsg: ""
          })
        }

      }

      else {
        setError({
          ...error,
          companyname: true,
          nameMsg: "This field is required."
        })
      }

    }

    if (e.target.id === "logo_icon_image") {
      if (e.target.files.length !== 0) {
        var img = document.createElement("img");
        img.src = URL.createObjectURL(e.target.files[0]);
        var filePath = e.target.files[0].name;

        if (!allowedExtensions.exec(filePath)) {
          setImage({
            preview: "",
            raw: "",
          });
          setError({
            ...error,
            logo_icon_image: true,
            imgMsg: "Selected file is not an image"
          });
        }
        else {
          img.onload = function () {
            if (img.width >= 65 && img.height >= 180) {

              setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
              });
              setError({
                ...error,
                logo_icon_image: false,
                imgMsg: ""
              });
            }
            else if (img.width <= 65) {
              setImage({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                logo_icon_image: true,
                imgMsg: "Minimum width must be 65px"
              });
            }
            else if (img.height <= 180) {
              setImage({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                logo_icon_image: true,
                imgMsg: "Minimum height must be 180px"
              });
            }
          };
        }
      }
      else {
        setImage({
          preview: "",
          raw: "",
        });
        setError({
          ...error,
          logo_icon_image: false,
          imgMsg: ""
        });

      }
    }


    if (e.target.id === "credit_control_email") {
      if (e.target.value) {

        if (emailReg.test(e.target.value) === false) {
          setError
            ({
              ...error,
              credit_control_email: true,
              emailMsg: "Please enter a valid email address."
            })
        }
        else {
          setError({
            ...error,
            credit_control_email: false,
            emailMsg: ""
          })
        }
      }
      else {
        setError({
          ...error,
          credit_control_email: true,
          emailMsg: "This field is required."
        })
      }
    }


    seteditdata(newdata);
  };

  const [resVal, setResVal] = useState();
  async function CheckCompany(name, id) {

    try {
      if (name.includes(",")) {
        setError({
          ...error,
          companyname: true,
          nameMsg: "Please enter a name without comma",
        });
      }
      else if (nameReg.test(name) === false) {
        setError({
          ...error,
          companyname: true,
          nameMsg: "Field must have at least 1 character a-z or A-Z",
        });
      }
      else {
        trackPromise(
          Service.CheckCompany({ companyname: name, companyid: id }).then((res) => {

            setResVal(res.data);
            if (res.data === true) {
              setError({
                ...error,
                companyname: false,
                nameMsg: ""
              })
            }

            else {
              setError({
                ...error,
                companyname: true,
                nameMsg: "This​ Company ​name ​already​ exist"
              })
            }

          })
        );
      }

    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  async function getData() {
    try {
      trackPromise(
        ChannelService.channelUserRequest().then((res) => {
          editdata['company_id'] = res.data?.userDetails?.companyId?.companyId;

        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  //edit Image
  const companyRef = React.useRef();
  const emailRef = React.useRef();
  async function EditImageData() {
    if (error.companyname) {
      setError({
        ...error,
        companyname: true,
      });
      companyRef.current.focus();
    }
    else if (error.logo_icon_image) {
      setError({
        ...error,
        logo_icon_image: true,
      });
    }
    else if (error.credit_control_email) {
      setError({
        ...error,
        credit_control_email: true,
      });
      emailRef.current.focus();
    }
    else {
      let file = document.getElementById("logo_icon_image").files[0];

      let values = file != undefined ? new FormData() : "";
      if (file != undefined) {
        values.append("logo_icon_image", file ? file : "");
      }
      try {

        if (values.toString().trim().length > 0) {
          trackPromise(

            Service.editImage(values).then((res) => {

              if (res.status === 200) {

                editdata['logo_icon_image'] = res.data;
                EditChannelData(editdata);

              }

            })
          );
        }
        else {
          EditChannelData(editdata);
        }

      } catch (err) {
        setErrMsg("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      }
    }

  }
  async function EditChannelData() {

    try {
      trackPromise(
        ChannelService.EditAccountDetails(editdata).then((res) => {
          if (res.status === 200) {
            setEditInfo(false);
            window.location.reload();
          } else {
            seterrorOpen(true);
            setEditInfo(true);
          }

          AccountRequest();
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }

  const [removeDialogue, setRemoveDialogue] = useState(false);
  const [ImgRemoved, setImgRemoved] = useState(false);

  const removeLogo = () => {

    setImgRemoved(true);

    setRemoveDialogue(false);

    seteditdata({
      companyname: channelData.publisherDetail.name,
      logo_icon_image: null,
      credit_control_email: channelData.publisherDetail?.creditControlEmail,
      status: channelData.publisherDetail.status,
      company_type: channelData.publisherDetail.companyType,
      company_id: channelData.publisherDetail.companyId,
      removeCompanyLogo: "yes",
    });

    setImage({ preview: "", raw: "" });
    //localStorage.removeItem("EditOpen")
  };
  const DialogClose = () => {

    setImgRemoved(false);
    setRemoveDialogue(false);
  }


  const [openPopup, setOpenPopup] = useState(false);

  document.addEventListener("click", e => {

    let editNot = localStorage.getItem("EditOpen");
    if (editNot) {
      if (e.target.id === "Edit" || e.target.id === "credit_control_email" || e.target.id === "Cancel"

        || e.target.id === "Save" || e.target.id === "Return" || e.target.id === "popupClose" || e.target.name === "logo_icon_image" || e.target.id === "logo_icon_image" ||
        e.target.type === "file" || e.target.name === "company_name" || e.target.name === "popup" || e.target.id === "status" || e.target.id === "Remove") {

        if (e.target.id === "Cancel" || e.target.id === "Save" || e.target.id === "Return") {
          localStorage.removeItem("EditOpen")

        }
        setOpenPopup(false);
      }
      else {
        setOpenPopup(true);
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();

      }
    }
    else {
      setOpenPopup(false);

    }

  }, true);

  const [inviteError, setInviteError] = useState({
    name: false,
    email_address: false,
    user_role: false,
    companyId: false,

    nameErMsg: "",
    emailErrMsg: "",
    roleErrMsg: "",
  });

  const [invite, setInvite] = useState({
    name: "",
    email_address: "",
    user_role: "",
    companyId: "",
  });
  ////Check Email
  async function EmailCheck(value) {
    trackPromise(
      Service.EmailCheck({ email_address: value })
        .then((res) => {
          if (res.data === false) {
            setInviteError({
              ...inviteError,
              email_address: true,
              emailErrMsg: "This​ email address already exists please use admin utilities to check current user access",
            });
          } else if (value && reg.test(value) === false) {
            setInviteError({
              ...inviteError,
              email_address: true,
              emailErrMsg: "Please enter a valid email address.",
            });
          } else {
            setInviteError({
              ...inviteError,
              email_address: false,
            });
          }
        })
        .catch((error) => {
          setErrMsg("Something went wrong. Please reload the page!");
          seterrorOpen(true);
        })
    );
  }

  const reg = /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/;

  const handleUser = (e) => {
    e.preventDefault();
    const invitedata = { ...invite };
    invitedata[e.target.id] = e.target.value;
    if (e.target.id === "name") {
      setInviteError({
        ...inviteError,
        name: false,
      });
    }
    if (e.target.id === "email_address") {
      if (e.target.value) {
        EmailCheck(e.target.value);
      } else {
        setInviteError({
          ...inviteError,
          email_address: true,
          emailErrMsg: "This field is required",
        });
      }
    }

    if (e.target.id === "user_role") {
      setInviteError({
        ...inviteError,
        user_role: false,
      });
    }
    setInvite(invitedata);
  };
  useEffect(() => {
    setlodervisible(true);
    AccountRequest();
    Channeldata();
    getData();
    localStorage.removeItem("EditOpen")
  }, []);

  return (
    <div className="accountContent">
      <div className={toggleContextVal.event ?"accountMenuView" :"accountView"}>
        <div className="accounthead">
          <div>
            <img src={accountIcon} alt=""></img>
          </div>
          <div className="accountToptext">
            Accounts Details - Channel View
          </div>
        </div>
        <AntTabs
          value={value}
          className="tabViewMenu"
          onChange={handleChange}
          aria-label="ant example"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          <AntTab
            label="Company"
            title="Click here to view Company Tab"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          />
          <AntTab
            label="Users"
            title="Click here to view Users Tab"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          />
        </AntTabs>
        <Typography className={classes.padding} />
        <TabPanel value={value} index={0}>
          {
            lodervisible &&
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: '0px',
              right: '0px',
              margin: 'auto',
              position: 'absolute',
              left: '0px',
              bottom: '0px',
              zIndex: '3000',
              backgroundColor: "#ffffff",
              //boxShadow: '0px 0px 5px -1px rgba(0,0,0,0.75)',
              // paddingLeft: '2px',
              // paddingRight: '2px',
              paddingTop: '8px',
              paddingBottom: '8px',
              borderRadius: '5px',
              flexDirection: 'column',
              width: '70px',
              height: '60px'
            }} >
              <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
            </div>
          }
          <div className="companyForm">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4} md={4}>
                <div className="companyLabel">Company Name</div>

                <div>
                  {!editInfo ? (
                    <input

                      type="text"
                      className="companyListinputField"
                      style={{ cursor: 'not-allowed' }}
                      disabled="disabled"
                      value={channelData ? channelData.publisherDetail.name : ""}
                    />
                  ) : (
                    <input
                      type="text"
                      ref={companyRef}
                      className={!error.companyname ? "editcompanyListField" : "editcompanyListFielderr"}
                      name="popup"
                      onChange={(e) => dataChange(e)}
                      id="companyname"
                      defaultValue={channelData ? channelData.publisherDetail.name : ""}
                    />
                  )}
                  {error.companyname ? <a className="errorClass">{error.nameMsg}</a> : null}
                </div>
              </Grid>
              <Grid item xs={12} sm={1} md={1}></Grid>
              <Grid item xs={12} sm={4} md={4}>
                {/* <div className="companyLabel">Company Logo</div> */}
                {editInfo || channelData?.publisherDetail.logoFile !== null ?
                  <div className="fileIcon"><img
                    src={
                      image.preview
                        ? image.preview :
                        !ImgRemoved ?
                          API_URL + channelData?.logoFile + channelData?.publisherDetail.logoFile
                          : alpha}
                    onError={(event) => event.target.src = alpha}
                    alt=""
                    className="imgStyle"
                  ></img></div> : null}
                {editInfo ? (
                  <div className="imgFileRow">
                    <div className="companyLabel">Upload Company Logo </div>
                    <div className="filesubText">
                      (Min. dimensions of 180px X 65px)

                    </div>
                    {error.logo_icon_image ? <a className="errorClass">{error.imgMsg}</a> : null}
                    <input id="logo_icon_image" type="file" name="logo_icon_image" onChange={(e) => dataChange(e)} />
                    <label htmlFor="file">{file}</label>
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <div className="ivitediv">
                  {!editInfo ? (
                    <button className="inviteButton" id="Edit" onClick={handleOpen}>
                      Edit Details
                    </button>
                  ) : null}
                </div>
              </Grid>
              {editInfo && !ImgRemoved && channelData?.publisherDetail?.logoFile ? (
                <Grid item xs={12} sm={12} md={12}>
                  <div className="ivitediv">
                    <button className="changeOwnButton spaceTop" id="Remove"
                      onClick={() => setRemoveDialogue(true)}>
                      Remove Company Logo
                    </button>
                  </div>
                </Grid>
              ) : null}
              <Grid item xs={12} sm={4} md={4}>
                <div className="companyLabel">Licensee Owner</div>
                <div>
                  <input
                    type="text"
                    className="inputField"
                    name="CustomerIdentifier"
                    disabled="disabled"
                    style={{ cursor: 'not-allowed' }}
                    value={channelData ? channelData.publisherDetail?.parentCompanyId.name : ""}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={1} md={1}></Grid>
              <Grid item xs={12} sm={4} md={4}></Grid>
              <Grid item xs={12} sm={4} md={4}>
                <div className="companyLabel">Credit Controller Email</div>

                <div>
                  {!editInfo ? (
                    <input
                      type="text"
                      className="companyListinputField"
                      name="popup"
                      disabled="disabled"
                      style={{ cursor: 'not-allowed' }}
                      value={channelData ? channelData.publisherDetail.creditControlEmail : ""}
                    />
                  ) : (
                    <input
                      type="text"
                      className={!error.credit_control_email ? "editcompanyListField" : "editcompanyListFielderr"}
                      name="popup"
                      id="credit_control_email"
                      ref={emailRef}
                      defaultValue={channelData ? channelData.publisherDetail.creditControlEmail : ""}
                      onChange={(e) => dataChange(e)}
                    />
                  )}
                  {error.credit_control_email ? <a className="errorClass">{error.emailMsg}</a> : null}
                </div>
              </Grid>
              <Grid item xs={12} sm={1} md={1}></Grid>
              <Grid item xs={12} sm={4} md={4}>
                <div className="companyLabel">Status</div>

                <div>

                  <input
                    type="text"
                    className="companyListinputField"
                    name="popup"
                    disabled="disabled"
                    value={channelData ? channelData.publisherDetail.status : ""}
                  />

                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <div className="companyLabel">Company Type</div>

                <div>

                  <input
                    type="text"
                    className="companyListinputField"
                    name="popup"
                    disabled="disabled"
                    value={channelData ? channelData.publisherDetail.companyType : ""}
                  />

                </div>
              </Grid>
              <Grid item xs={12} sm={1} md={1}></Grid>
              <Grid item xs={12} sm={4} md={4}>
                <div className="companyLabel">Created</div>

                <div>

                  <input
                    type="text"
                    className="companyListinputField"
                    disabled="disabled"
                    value={moment(channelData?.publisherDetail?.created).format("YYYY-MM-DD ") + " GMT"}
                    name="popup"
                  />

                </div>
              </Grid>
            </Grid>
          </div>

          <div className="ivitediv">
            {editInfo ? (
              <button
                className="inviteButton"
                onClick={handleClose}
                color="primary"
                id="Cancel"
              >
                Cancel
              </button>
            ) : null}
            {editInfo ? (
              <button className="inviteButton" color="primary" id="Save" onClick={EditImageData}>
                Save
              </button>
            ) : null}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TableContainer>
            <Table className="accouttablestyle" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableHead">User’s Name</TableCell>
                  <TableCell className="tableHead">Email Address</TableCell>
                  <TableCell className="tableHead">Role</TableCell>
                  <TableCell className="tableHead">Type</TableCell>
                  <TableCell className="tableHead">Verified</TableCell>
                  <TableCell className="tableHead">Status</TableCell>
                  <TableCell className="tableHead">Created</TableCell>

                </TableRow>
              </TableHead>
             {channelData?.usersListDetail?.length > 0?<TableBody>
                {channelData?.usersListDetail.map((row,index) => (
                  <TableRow key={index}>
                    <TableCell
                      component="th"
                      scope="row"

                    >
                      {row?.userIdObj?.username}
                    </TableCell>
                    <TableCell> {row?.userIdObj?.email}</TableCell>

                    <TableCell>{row?.role}</TableCell>
                    <TableCell>{row?.userIdObj?.userType}</TableCell>
                    <TableCell>{row?.userIdObj?.verified == 1 ? "Yes" : "No"}</TableCell>
                    <TableCell>{row?.status}</TableCell>
                    <TableCell>{moment(row?.userIdObj?.created).format("Do MMM YYYY") + " GMT"}</TableCell>
                    
                  </TableRow>
                ))}
              </TableBody> : ""}
            </Table>
          </TableContainer>
          {!editInfo ? (
            <div className="ivitediv">
              <button className="inviteButton" onClick={editOpen}>
                + Invite New User
              </button>
            </div>
          ) : (
            <div className="ivitediv">
              <button
                className="inviteButton"
                onClick={handleClose}
                color="primary"
              >
                Cancel
              </button>
              <button className="inviteButton" color="primary" >
                Save
              </button>
            </div>
          )}
        </TabPanel>
      </div>

      {/* //***************adminLIcensee Users*********** */}


      {/* //INVITE uSER */}
      <Dialog open={open} classes={{ scrollPaper: classes.scrollPaper }} scroll={scroll} fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle">
          <div className="FlexRow">
            <div className="cmpdialogtitle"> Invite Channel User </div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} />
            </div>
          </div>

        </DialogTitle>
        <DialogContent>
          <div className="dialoginputtext">Name:</div>
          <input type="text" id="name" label="name" className={inviteError.name ? "form-control errorField" : "form-control"} onChange={(e) => handleUser(e)} fullWidth />
          {inviteError.name ? <a className="errorClass">{inviteError.nameErMsg ? inviteError.nameErMsg : "This field is required"}</a> : null}
          <div className="dialoginputtext">Email:</div>
          <input
            type="text"
            id="email_address"
            name="email_address"
            label="email"
            className={inviteError.email_address ? "form-control errorField" : "form-control"}
            onChange={(e) => handleUser(e)}
            fullWidth
          />
          {inviteError.email_address ? <a className="errorClass">{inviteError.emailErrMsg ? inviteError.emailErrMsg : "This field is required"}</a> : null}
          <div className="dialoginputtext">Role:</div>
          <select type="text" id="user_role" name="user_role" label="email"
            className={inviteError.user_role ? "form-control form-select errorField" : "form-control form-select"} onChange={(e) => handleUser(e)} fullWidth>
            <option value=""></option>
            <option value="channel">channel</option>
            <option value="account manager">account manager</option>
          </select>
          {inviteError.user_role ? <a className="errorClass">{inviteError.roleErrMsg ? inviteError.roleErrMsg : "This field is required"}</a> : null}
        </DialogContent>
        <DialogActions>
          {loading ? <div className="loaderContainer">
            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="ivitediv">
            <button className="inviteButton" onClick={handleClose} color="primary">
              Cancel
            </button>

            <button className="inviteButton" disabled={clickInvite ? "disabled" : false} onClick={edit} color="primary">
              Invite User
            </button>
          </div>
        </DialogActions>
      </Dialog>


      {/* ////////////////// Remove Company Logo ///////////////// */}
      <Dialog open={removeDialogue} classes={{ scrollPaper: classes.scrollPaper }} name="popup" fullWidth maxWidth="sm" onClose={DialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle  border">
          <div className="FlexRow border" >
            <div className="cmpdialogtitle"> </div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline className="modalClose" id="popupClose" onClick={() => setRemoveDialogue(false)} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="ContentHead">
            <div className="warningIcon"><MdWarning /></div>
            <div className="warningText">WARNING !</div>

          </div>
          <div className="logoConfirmtext">Are you sure, do you want to delete Company Logo?</div>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv">
            <button className="continueBtn" name="popup"
              style={{ marginRight: '10px' }}
              onClick={DialogClose} color="primary">
              Cancel
            </button>

            <button className="continueBtn" name="popup" onClick={removeLogo} color="primary">
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>
      {/*///////////////////////Popup//////////////////////////////*/}
      <Dialog open={openPopup} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }} aria-labelledby="form-dialog-title">

        <DialogContent className="diaContent">
          <div className="logoConfirmtext">Please Save or Cancel the changes before proceeding</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" name="popup" color="primary">
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog open={invitedSuccess} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Success Message</div>

        </DialogTitle>
        <DialogContent className="diaContent">
          <div className="logoConfirmtext" style={{ fontSize: '17px', padding: '22px 71px !important' }}>Successfully added new user. Invitation link sent to user's E-mail id.</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" style={{ width: '11%' }} name="popup" color="primary" onClick={() => setInvitedSuccess(false)}>
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={successopen}
        autoHideDuration={2500}
        onClose={handlesuccessAlert}
      >
        <Alert onClose={handlesuccessAlert} severity="success">
          {toastMsg ? toastMsg : "Updated Successfully!"}
        </Alert>
      </Snackbar>

      <Snackbar
        open={erroropen}
        autoHideDuration={2500}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {errMsg ? errMsg : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default ChannelAccount;
