import React, { useState, useEffect} from "react";
import { IoIosSettings } from "react-icons/io";
import service from "../../../api/service";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";
import { usePromiseTracker } from "react-promise-tracker";
import { useHistory } from "react-router-dom";
import VMCTable from '../../../Recomponent/Table'

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
          overflowY:'auto'
        }}
      >
        <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};
function Adminutilities() {
  const [utilityData, setUtilityData] = useState(false);
  let history = useHistory();

  const utilitydata = () => {
    try {
      trackPromise(
        service.utilityData().then((res) => {    
          setUtilityData(res.data.utilityUserAccessDetail);
        })
      );
    } catch (err) {
    
    }
  };
  const utiltyRouteLink = (name) =>{
  if(name === "#datafree DIRECT App Maintenance"){
    history.push("/admin/datafreedirect/application/index");
  }
  if(name === "Maintain Company specific #datafree Gateway Domain"){
    history.push("/admin/datafreegatewaydomain/index");
  }
  if(name === "View biNu IP Deployments"){
    history.push("/admin/view/binuipdeployment/index");
  }
  if(name === "Maintain MNO Invoice Reference Data"){
    history.push("/admin/mno/invoice/reference/data");
  }
  if(name === "Maintain Moya User Statistics"){
    history.push("/admin/moya/user/statistics");
  }
  if(name === "AWS Data Usage Stats (Cloudwatch summary)"){
    window.open("https://pub.bi.nu/utils/portal/get_data_usage_report.php");
    
  }
  if(name ==="Monthly Billing Production"){
    window.open("https://pub.bi.nu/utils/portal/billing_request.php");
    
  }
  if(name === "#datafree CONNECT App Maintenance"){
    history.push("/admin/datafreeconnect/application/index");
    
  }
  if(name === "Notifications Maintenance"){
    history.push("/admin/notifications/maintenance/index");
    
  }
  if(name === "Maintain Multiple User Roles"){
    history.push("/admin/multiple/maintenance/index");
    
  }
  if(name === "Maintain Licensee Revenue Types"){
    history.push("/admin/revenue/maintenance/index");
    
  }
  }

  useEffect(() => {
    utilitydata();
  }, []);

  const [tablecols, setTableCols] = useState([
    {
      colName : 'Utility Name',
      colMap: 'utility_name',
      linkName : true,
      cModel : false,
      cRoute : true,
      fDate:false,
      imprRoute: true,
      userTab:false
    },
    {
      colName : 'Utility Description',
      colMap: 'utility_desc',
      linkName : false,
      cModel : false,
      cRoute : false,
      fDate:false,
      imprRoute: true,
      userTab:false
    },
  ])

  return (
    <div className="utilityContentAdmin">
      <div className="utilityView">
        <div className="utilityhead">
          <div>
            <IoIosSettings className="utilityheadIcon" />
          </div>
          <div className="utilToptext">Utilities</div>
        </div>
        <div className="utilitytables">
          <LoadingIndicator />
          {
            utilityData?.length > 0 ? 
            <VMCTable key={utilityData} data={utilityData} col={tablecols}
              navigateFunction={utiltyRouteLink}
              tableName="Admin Utility"
            />
            : <div style={{textAlign:'center'}}><p style={{fontWeight:'bold'}}></p></div>
          }
        </div>
      </div>
    </div>
  );
}
export default Adminutilities;
