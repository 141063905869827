import React, { useState, useEffect,useContext} from "react";
import "./adminStyle.css";
import applicationIcon from "../../../assets/img/reporting-icon-black.png";
import { useHistory } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import VMCTable from "../../../Recomponent/Table";
import moment from "moment";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Grid } from "@material-ui/core";
import AdminService from "../../../api/adminService";
import Loader from "react-loading";
import EmptyTable from "../../../Recomponent/EmptyTable";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { usePromiseTracker } from "react-promise-tracker";
import {
  ToggleContext
} from "../../context/toggleContext"

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});
const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function BinuplatformVersion() {
  const [search, setSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [empty, setEmpty] = useState(false);
  const [checked, setChecked] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [searchDetails, setSearchDetails] = useState();
  const [changedropdown, setChangedropdown] = useState(false);
  const [versionIdOpen, setversionIdOpen] = useState(false);
  const [versionDetails, setversionDetails] = useState();
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const toggleContextVal = useContext(ToggleContext)
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
    setversionIdOpen(false);
    ;
  };
  const handleAlertClose = () => {
    seterrorOpen(false);

  };
  const [data, setData] = useState({
    versionID: "",
    versionName: "",
    product: "",
    status: "",
    platformType: "",
    versionTag: "",
    isDefault: "",
  });
  const [editVersion, setEditVersion] = useState({
    version_name: "",
    status: "",
    editDefaultVersion: "",
    binuId: "",
  });
  const [versionCreate, setVersionCreate] = useState({
    versionName: "",
    product: "1",
    status: "Active",
    platformType: "Beta",
    versionTag: "",
    isDefault: "0",
  });

  const [versionId, setVersionId] = useState({
    versionId: "",
  });
  const [tablecols, setTableCols] = useState([
    {
      colName: "Version Id",
      colMap: "binuClientVersionId",
      linkName: true,
      cModel: true,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Version Name",
      colMap: "binuClientVersionName",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Product",
      colMap: "productIdproductType",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Status",
      colMap: "status",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Platform Type",
      colMap: "platformType",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Version Tag",
      colMap: "versionTag",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Created",
      colMap: "created",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: true,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Use as Default",
      colMap: "isDefault",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
  ]);
  let history = useHistory();

  const handleVersion = (scrollType, Id, isDefault) => {
    setChecked(isDefault);
    setversionIdOpen(true);
    setScroll(scrollType);
    VersionDetails(Id, isDefault);
    editVersion["binuId"] = Id;
  };

  const [versionErrorField, setVersionErrorField] = useState({
    version_name: false,
    versionTag: false,
    nameMsg: "",
    versionTagMsg: "",
  });

  const versionUpdate = (e) => {
    e.preventDefault();
    const newdata = { ...editVersion, [e.target.id]: e.target.value };
    if (e.target.id === "editDefaultVersion") {
      setChecked({ checked: !checked });
      if (e.target.checked === true) {

        newdata['editDefaultVersion'] = 1;
      }
      else if (e.target.checked === false) {

        newdata['editDefaultVersion'] = 0;
      }
    }
    setEditVersion(newdata);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }

    setversionIdOpen(false);
    setVersionErrorField({
      version_name: false,
      versionTag: false,
      nameMsg: false,
      versionTagMsg: false,
    });
    setVersionCreate({
      versionName: "",
      product: "1",
      status: "Active",
      platformType: "Beta",
      versionTag: "",
      isDefault: "0",
    });
  };
  const handleClickOpen = (scrollType) => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleRouteLink = (companytype, companyId) => {

    if (companytype === "CUSTOMER" || "RESLR CLNT") {
      history.push(`/admin/customer/${companyId}/detail/view`);
    }
    if (companytype === "CHANNEL") {
      history.push(`/admin/Channel/${companyId}/detail/view`, {
        companyId: companyId,
      });
    }
    if (companytype === "LICENSEE") {
      history.push(`/admin/licensee/${companyId}/detail/view`, {
        licenseeId: companyId,
      });
    }
  };
  const lifecycleChange = (e) => {
    e.preventDefault();
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    setEmpty(false);
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  };

  //Version Details
  async function VersionDetails(id, isdefault) {
    ///setChecked(isdefault?false:true);
    setSearch(true);
    setChangedropdown(true);
    try {
      setversionDetails();
      trackPromise(
        AdminService.versionDetails({ versionId: id }).then((res) => {
          setversionDetails(res.data.binuClientVersionDetails);
          editVersion["binuId"] = id;
          setEditVersion({
            version_name: res.data.binuClientVersionDetails[0].binuClientVersionName,
            status: res.data.binuClientVersionDetails[0].status,
            editDefaultVersion: checked.checked,
            binuId: id,
          });
        })
      );
    } catch (err) {
      setMessage("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const [message, setMessage] = useState();
  //Edit Version
  function EditVersionDetails() {
    setLoading(true);
    trackPromise(
      AdminService.versionUpdate(editVersion).then((res) => {
        setLoading(false);
        if ((res.status = 200)) {
          setversionIdOpen(false);
          setToastMsg("Version Details Edited Successfully!")
          setsuccessOpen(true);
          setVersionId(false);
        }
        companyListdata();
      }).catch((error) => {
        setMessage("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      })
    );
  }
  //search
  function companyListdata() {
    setSearchDetails({});
    setSearch(true);
    setChangedropdown(true);
    setEmpty(false);
    try {

      trackPromise(

        AdminService.versionSearch(data).then((res) => {

          setSearchDetails(res.data.binuClientVersionsResult);
          if (res.status === 200 && res.data.binuClientVersionsResult.length > 0) {
            setChangedropdown(false);
          } else if (res.status === 200 && res.data.binuClientVersionsResult.length === 0) {
            setEmpty(true);
            setChangedropdown(false);
          } else {
            setChangedropdown(true);
          }
        })
      );
    } catch (error) {
      setMessage("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  //binuVesion Index
  function binuVersionIndex() {
    setSearch(true);
    try {
      trackPromise(AdminService.BinuVersionIndex().then((res) => { }));
    } catch (err) {
      setMessage("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const versionHandle = (e) => {
    e.preventDefault();
    const versionData = { ...versionCreate, [e.target.id]: e.target.value };
    //versionData[e.target.id] = e.target.value;
    if (e.target.id === "versionName") {
      setVersionErrorField({
        ...versionErrorField,
        version_name: false,
      });
    }
    if (e.target.id === "versionTag") {
      setVersionErrorField({
        ...versionErrorField,
        versionTag: false,
      });
    }
    if (e.target.id === "isDefault") {
      if (e.target.value === "Yes") {
        versionData["isDefault"] = 1;
      } else {
        versionData["isDefault"] = 0;
      }
    }
    setVersionCreate(versionData);
  };
  //Add Version
  const [clickSave, setClickSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastMsg, setToastMsg] = useState();
  function AddVersion() {
    if (versionCreate.versionName.length === 0 && versionCreate.versionTag.length === 0) {
      setVersionErrorField({
        ...versionErrorField,
        version_name: true,
        versionTag: true,
      });
    } else {
      setClickSave(true);
      setLoading(true);
      trackPromise(
        AdminService.AddVersion(versionCreate)
          .then((res) => {
            setClickSave(false);
            if (res.status === 200) {
              setToastMsg("Version Added Successfully!")
              companyListdata();
              setsuccessOpen(true);
              handleClose();
              setLoading(false);
            }
          })
          .catch((error) => {
            setMessage("Something went wrong. Please reload the page!");
            seterrorOpen(true);
          })
      );
    }
  }

  useEffect(() => {
    binuVersionIndex();
  }, []);

  return (
    <div className={toggleContextVal.event ?"mainMobView" :"mainBoxView"}>
      <div className="NavBoxDiv">
        <div className="NavBoxRow col-lg-6 col-md-6 col-sm-6 col-xs-5">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">biNu Platform Version List</div>
        </div>
        <div className="NavBoxRowFilter table-responsive">
          <table className="table-sm">
            <tbody>
              <tr>
                <td className="col-md-2">
                  Version ID
                </td>
                <td className="col-md-2">
                  Version Name
                </td>
                <td className="col-md-2">
                  Product
                </td>
                <td className="col-md-2">
                  Status
                </td>
                <td className="col-md-2">
                  Platform Type
                </td>
                <td className="col-md-2">
                  Version Tag
                </td>
                <td className="col-md-2">
                  Is Default
                </td>
                <td className="col-md-2">

                </td>
              </tr>
              <tr>
                <td className="col-md-2">
                  <input type="text" className="form-control" id="versionID" name="versionID" onChange={(e) => lifecycleChange(e)}></input>
                </td>
                <td className="col-md-2">
                  <input type="text" className="form-control" id="versionName" name="versionName" onChange={(e) => lifecycleChange(e)}></input>

                </td>
                <td className="col-md-1">
                  <select type="text" className="form-control form-select" id="product" name="product" onChange={(e) => lifecycleChange(e)}>
                    <option value=""></option>
                    <option value="1">#datafree WRAP</option>
                    <option value="2">#datafree MAX</option>
                    <option value="3">#datafree SWITCH</option>
                    <option value="4">#datafree REACH</option>
                    <option value="5">#datafree DIRECT</option>
                    <option value="6">#datafree CONNECT</option>
                  </select>
                </td>
                <td className="col-md-3">
                  <select type="text" className="form-control form-select" id="status" name="status" onChange={(e) => lifecycleChange(e)}>
                    <option value=""></option>
                    <option value="Active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </td>
                <td className="col-md-2">
                  <input type="text" className="form-control" id="platformType" name="platformType" onChange={(e) => lifecycleChange(e)}></input>
                </td>
                <td className="col-md-2">
                  <input type="text" className="form-control" id="versionTag" name="versionTag" onChange={(e) => lifecycleChange(e)}></input>
                </td>
                <td className="col-md-2">
                <select type="text" className="form-control form-select" id="isDefault" name="isDefault" onChange={(e) => lifecycleChange(e)}>
                  <option value=""></option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
                </td>
                <td className="col-md-2">
                <button className={changedropdown ?"defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"} disabled={changedropdown ? "disabled" : false} onClick={companyListdata}>
                Search
              </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div><hr className="applications-list-hr"></hr></div>

        </div>
        <div className="createButtonDiv">
          <button className="defultbtn"
            title="Click here to create new Version"
            onClick={() => handleClickOpen("body")}>
            + Create New Version
          </button>
        </div>

        {search ? (
          <div>
            {searchDetails && searchDetails.length > 0 ? (
              <VMCTable key={searchDetails} data={searchDetails} col={tablecols}
                navigateFunction={handleRouteLink}
                tableName="Admin Binu Platform Version" clickOpen={handleVersion} />
            ) : (
              <div>
                <LoadingIndicator />
                {empty ? <EmptyTable data="No Versions Found" col={tablecols} tableName="Company List" /> : null}
              </div>
            )}
          </div>
        ) : null}
      </div>
      {/* //======================versionID ==========================*/}
      <Dialog open={versionIdOpen} classes={{ scrollPaper: classes.scrollPaper }} fullWidth scroll={scroll} maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <div className="FlexRow">
            <div className="cmpdialogtitle">biNu Platform Version Details</div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline style={{ cursor: "pointer" }} className="modalClose"
                onClick={handleClose} />
            </div>
          </div>
        </DialogTitle>
        {versionDetails?.map((item, index) => (
          <DialogContent key={index}>
            <div className="cmpdialoginputtext">Version Id</div>
            <input type="text" disabled="disabled" style={{ cursor: "not-allowed" }} value={item?.binuClientVersionId} className="form-control" fullWidth />
            <div className="cmpdialoginputtext">Version Name</div>
            <input type="text" id="version_name" label="version_name" onChange={(e) => versionUpdate(e)}
              className="form-control" defaultValue={item?.binuClientVersionName} fullWidth />
            <div className="cmpdialoginputtext">Product</div>
            <input type="text" disabled="disabled" style={{ cursor: "not-allowed" }} value={item?.productId?.productType} className="form-control" fullWidth />
            <div className="cmpdialoginputtext">Status</div>
            <select type="text" id="status" name="status" onChange={(e) => versionUpdate(e)} className="dialogSelectinput" defaultValue={item?.status} fullWidth>
              <option></option>
              <option>Active</option>
              <option>InActive</option>
            </select>
            <div className="cmpdialoginputtext">Platform Type</div>
            <input type="text" disabled="disabled" value={item?.platformType} style={{ cursor: "not-allowed" }} className="form-control" fullWidth />
            <div className="cmpdialoginputtext">Version Tag</div>
            <input type="text" disabled="disabled" style={{ cursor: "not-allowed" }} value={item?.versionTag} label="email" className="form-control" fullWidth />
            <div className="cmpdialoginputtext">Created</div>
            <input type="text" disabled="disabled" value={moment(item.created).format("YYYY-MM-DD hh:mm ") + "GMT"} style={{ cursor: "not-allowed" }} className="form-control" fullWidth />

            <div>
              <label for="websiteName" className="cmpdialoginputtext">
                Default Version
              </label>
              <input
                type="checkbox"
                name="editDefaultVersion"
                id="editDefaultVersion"
                onChange={(e) => versionUpdate(e)}
                defaultChecked={checked}
              // checked={item.isDefault== true?true:false}
              />
            </div>
          </DialogContent>
        ))}
        <DialogActions>
          {loading ? <div className="loaderContainer">
            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="ivitediv">
            <button className="inviteButton" onClick={handleClose} color="primary">
              Cancel
            </button>
            <button className="inviteButton"
              onClick={EditVersionDetails} color="primary">
              Update Details
            </button>
          </div>
        </DialogActions>
      </Dialog>

      {/* /////////////////////////////////////////Create Version////////////////////////////////////// */}

      <Dialog open={open} classes={{ scrollPaper: classes.scrollPaper }} fullWidth scroll={scroll} maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">

        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Create New Platform Version
            <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <div className="cmpdialoginputtext">Version Name</div>
            </Grid>
            <Grid item xs={6}>
              <input
                type="text"
                id="versionName"
                name="versionName"
                label="email"
                onChange={(e) => versionHandle(e)}
                className={versionErrorField.version_name ? "errorField form-control" : "form-control"}
                fullWidth
              />
              {versionErrorField.version_name ? <a className="errorClass paddingBottom">{versionErrorField.nameMsg ? versionErrorField.nameMsg : "This field is required"}</a> : null}
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={4}>
              <div className="cmpdialoginputtext">Product</div>
            </Grid>
            <Grid item xs={6}>
              <select type="text" id="product" name="product" onChange={(e) => versionHandle(e)} className="form-control form-select" fullWidth>
                <option value="1">#datafree WRAP</option>
                <option value="2">#datafree MAX</option>
                <option value="3">#datafree SWITCH</option>
                <option value="4">#datafree REACH</option>
                <option value="5">#datafree DIRECT</option>
                <option value="6">#datafree CONNECT</option>
              </select>
            </Grid>{" "}
            <Grid item xs={2}>
              {" "}
            </Grid>
            <Grid item xs={4}>
              <div className="cmpdialoginputtext">Status</div>
            </Grid>
            <Grid item xs={6}>
              <select type="text" id="status" name="status" label="name" onChange={(e) => versionHandle(e)} className="form-control form-select" fullWidth>
                <option value="Active">Active</option>
                <option value="InActive">InActive</option>
              </select>
            </Grid>
            <Grid item xs={4}>
              <div className="cmpdialoginputtext">Platform Type</div>
            </Grid>
            <Grid item xs={6}>
              <select type="text" id="platformType" label="platformType" onChange={(e) => versionHandle(e)} className="form-control form-select" fullWidth>
                {" "}
                <option value="Beta">Beta</option>
                <option value="Production">Production</option>
              </select>
            </Grid>
            <Grid item xs={4}>
              <div className="cmpdialoginputtext">Version Tag</div>
            </Grid>
            <Grid item xs={6}>
              <input
                type="text"
                id="versionTag"
                name="versionTag"
                onChange={(e) => versionHandle(e)}
                className={versionErrorField.versionTag ? "errorField form-control" : "form-control"}
                fullWidth
              />
              {versionErrorField.versionTag ? <a className="errorClass">{versionErrorField.versionTagMsg ? versionErrorField.versionTagMsg : "This field is required"}</a> : null}
            </Grid>
            <Grid item xs={4}>
              <div className="cmpdialoginputtext">Is Default</div>
            </Grid>
            <Grid item xs={3}>
              <select type="text" id="isDefault" name="isDefault" onChange={(e) => versionHandle(e)} className="form-control form-select" fullWidth>
                {" "}
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {loading ? <div className="loaderContainer">
            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="ivitediv">
            <button className="inviteButton" onClick={handleClose} color="primary">
              Cancel
            </button>
            <button className="inviteButton"
              disabled={clickSave ? "disabled" : false}
              onClick={AddVersion} color="primary">
              Save
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar open={successopen} autoHideDuration={1500} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
          {toastMsg ? toastMsg : "Saved Successfully!!"}
        </Alert>
      </Snackbar>

      <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {message ? message : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default BinuplatformVersion;
