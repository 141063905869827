import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Box,
  withStyles,
  Grid,
  Typography,
} from "@material-ui/core";
import service from "../../../../../api/service";
import { MdWarning } from "react-icons/md";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",

    // default center
  },
});
const AntTabs = Tabs;
function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    borderBottom: "1px solid #ddd ",
    minWidth: "80px",

    textShadow: "none",
    color: "#0059b6",

    "&$selected": {
      color: "#555",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderBottomColor: "transparent",
      borderBottom: "none",
      cursor: "default",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function ReachDevelopment(props) {
  const classes = useStyles();
  const [deleteopen, setDeleteOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editInfo, setEditInfo] = useState(false);
  const [applicationData, setApplicationData] = useState();
  const handleopendelete = () => {
    setDeleteOpen(true);
  };
  const [scroll, setScroll] = useState("paper");
  const handleClose = () => {
    setEditInfo(false);
    setDeleteOpen(false);
    setOpen(false)
    setReachError({
      website_url: false,
      max_bytes_content: false,
      content_type: false,
      webMsg: "",
      speedMsg: "",
      emailMsg: "",
      contentMsg: "",
      mimeMsg: ""
    })
    setError({
      max_bytes_content: false,
      content_type: false,
      contentMsg: "",
      mimeMsg: ""
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const edit = () => {
    setEditInfo(true);
  };
  const getApplicationDetails = () => {

    try {
      trackPromise(
        service.applicationData(props?.applicationId).then((res) => {
          setApplicationData(res.data.applicationObj);
          res?.data?.applicationObj?.dev?.securityRules?.blockedContent?.mime?.map((item, index) => (
            setSecurityEdit({
              ...securityEdit,
              max_bytes_content: res?.data?.applicationObj?.dev?.securityRules?.contentSize,
              max_bytes_per_content_changed: 'no',
              max_bytes_per_content_hidden: res?.data?.applicationObj?.dev?.securityRules?.contentSize,

              content_type: item ? item : ""
            })


          ))
        })
      );
    } catch (err) {

    }
  };
  const [hasApp, setHasApp] = useState(false);
  const getGenerateApp = () => {
    setLoading(true)
    service.generateApp({
      "applicationId": props?.applicationId,
      "type": props?.deployType
    }).then((res) => {
      setLoading(false)
      if (res?.data?.hasAppId === 'Yes') {
        setHasApp(true);
      }
      else {
        setHasApp(false);
      }
    }).catch((err) => {

    })

  };
  const handleClickOpen = (scrollType) => {
    setOpen(true);
    setScroll(scrollType);
  };
  const webReg = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  const [clickSave, setClickSave] = useState();
  const [reachData, setReachData] = useState({
    website_url: "",
    max_bytes_content: "",
    content_type: "",
    application_id: props.applicationId,
    appRegisterType: "dev",
    application_type: "reach"
  });
  const [open, setOpen] = useState(false)
  const [reachError, setReachError] = useState({
    website_url: false,
    max_daily_spend: false,
    alert_email: false,
    max_bytes_content: false,
    content_type: false,
    webMsg: "",
    speedMsg: "",
    emailMsg: "",
    contentMsg: "",
    mimeMsg: ""
  });
  const validateReach = (reachData) => {
    let rCount = 0;
    let nwerror = { ...reachError }
    if (reachData.website_url === "") {
      nwerror.website_url = true;
      nwerror.webMsg = "This field is required";
      rCount++;
    }
    else {
      if (webReg.test(reachData.website_url) === false) {
        nwerror.website_url = true;
        nwerror.webMsg = "Please enter a valid URL.";
        rCount++;
      }
      else {
        nwerror.website_url = false;
        nwerror.webMsg = ""
      }
    }

    if (reachData.max_bytes_content === "") {
      nwerror.max_bytes_content = false;
      nwerror.contentMsg = "";
    }
    else {
      if (contentReg.test(reachData.max_bytes_content) === false) {
        nwerror.max_bytes_content = true;
        nwerror.contentMsg = "Please enter only digits.";
        rCount++;
      }
      else {
        nwerror.max_bytes_content = false;
        nwerror.contentMsg = ""
      }
    }

    if (reachData.content_type === "") {
      nwerror.content_type = false;
      nwerror.mimeMsg = "";
    }
    else {

      if (mimeReg.test(reachData.content_type) === false) {
        nwerror.content_type = true;
        nwerror.mimeMsg = "Please enter a valid content type.";
        rCount++;
      }
      else {
        nwerror.content_type = false;
        nwerror.mimeMsg = ""
      }
    }

    setReachError(nwerror);
    return rCount;
  }

  const [loadings, setLoadings] = useState(false)
  const SaveReach = (e) => {
    e.preventDefault();

    const reachCount = validateReach(reachData);

    if (reachCount === 0) {
      setClickSave(true);
      setLoadings(true);
      service.saveReachProd(reachData).then((res) => {
        setLoadings(false);
        setClickSave(false);
        setOpen(false);
        getGenerateApp();
        getApplicationDetails();
      })

    }
  }
  const handleData = (e) => {
    const newdata = { ...reachData };
    newdata[e.target.id] = e.target.value;
    if (e.target.id === "website_url") {
      if (e.target.value) {
        if (webReg.test(e.target.value) === false) {
          setReachError({
            ...reachError,
            website_url: true,
            webMsg: "Please enter a valid URL."
          });
        }
        else {
          setReachError({
            ...reachError,
            website_url: false,
            webMsg: ""
          });
        }
      } else {
        setReachError({
          ...reachError,
          website_url: true,
          webMsg: "This field is required"
        })
      }
    }

    if (e.target.id === "content_type") {
      if (e.target.value) {
        var contentTypeValue = e.target.value;
        var modifiedContentTypeValue = '';
        if (contentTypeValue !== '') {
          var originalContentType = contentTypeValue.split('\n');
          for (var i = 0; i < originalContentType.length; i++) {
            if (i === originalContentType.length - 1) {
              if (originalContentType[i] !== '') {
                if (originalContentType[i].indexOf("/") === -1) {
                  modifiedContentTypeValue += originalContentType[i] + '/*';
                } else {
                  modifiedContentTypeValue += originalContentType[i];
                }
              }
            } else {
              if (originalContentType[i] !== '') {
                if (originalContentType[i].indexOf("/") === -1) {
                  modifiedContentTypeValue += originalContentType[i] + '/*' + '\n';
                } else {
                  modifiedContentTypeValue += originalContentType[i] + '\n';
                }
              }
            }
          }

        }

        if (mimeReg.test(modifiedContentTypeValue) === false) {
          setReachError({
            ...reachError,
            content_type: true,
            mimeMsg: "Please enter a valid content type."
          });
        }
        else {
          setReachError({
            ...reachError,
            content_type: false,
            mimeMsg: ""
          });
          e.target.value = modifiedContentTypeValue;
          newdata[e.target.id] = e.target.value;
        }

      }
      else {
        setReachError({
          ...reachError,
          content_type: false,
          mimeMsg: ""
        })
      }
    }

    if (e.target.id === "max_bytes_content") {
      if (e.target.value) {
        if (contentReg.test(e.target.value) === false) {
          setReachError({
            ...reachError,
            max_bytes_content: true,
            contentMsg: "Please enter only digits."
          });
        }
        else {
          setReachError({
            ...reachError,
            max_bytes_content: false,
            contentMsg: ""
          })
        }

      }
      else {
        setReachError({
          ...reachError,
          max_bytes_content: false,
          contentMsg: ""
        })
      }
    }

    setReachData(newdata);
  }

  const [securityEdit, setSecurityEdit] = useState({
    max_bytes_content: "",
    max_bytes_per_content_hidden: "",
    max_bytes_per_content_changed: "",
    content_type: "",
    application_id: ""
  })
  const [error, setError] = useState({
    max_bytes_content: false,
    content_type: false,
    contentMsg: "",
    mimeMsg: ""
  });
  const contentReg = /^[0-9]*$/;
  const mimeReg = /^[a-z]+\/(\*|[a-z]+[-+.a-z0-9]*)$/m;

  const contentChange = (e) => {

    const newdata = { ...securityEdit, [e.target.id]: e.target.value };

    if (e.target.id === "max_bytes_content") {
      if (e.target.value) {
        newdata['max_bytes_per_content_changed'] = 'yes'
        newdata['max_bytes_per_content_hidden'] = e.target.value
        if (contentReg.test(e.target.value) === false) {
          setError({
            ...error,
            max_bytes_content: true,
            contentMsg: "Invalid Format."
          });
        }
        else {
          setError({
            ...error,
            max_bytes_content: false,
            contentMsg: ""
          })
        }
      }
      else {
        newdata['max_bytes_per_content_changed'] = 'no'
        newdata['max_bytes_per_content_hidden'] = securityEdit?.max_bytes_content
        setError({
          ...error,
          max_bytes_content: false,
          contentMsg: ""
        })
      }
    }
    if (e.target.id === "content_type") {
      if (e.target.value) {
        var contentTypeValue = e.target.value;
        var modifiedContentTypeValue = '';
        if (contentTypeValue !== '') {
          var originalContentType = contentTypeValue.split('\n');
          for (var i = 0; i < originalContentType.length; i++) {
            if (i === originalContentType.length - 1) {
              if (originalContentType[i] !== '') {
                if (originalContentType[i].indexOf("/") === -1) {
                  modifiedContentTypeValue += originalContentType[i] + '/*';
                } else {
                  modifiedContentTypeValue += originalContentType[i];
                }
              }
            } else {
              if (originalContentType[i] !== '') {
                if (originalContentType[i].indexOf("/") === -1) {
                  modifiedContentTypeValue += originalContentType[i] + '/*' + '\n';
                } else {
                  modifiedContentTypeValue += originalContentType[i] + '\n';
                }
              }
            }
          }

        }

        if (mimeReg.test(modifiedContentTypeValue) === false) {
          setError({
            ...error,
            content_type: true,
            mimeMsg: "Please enter a valid content type."
          });
        }
        else {
          setError({
            ...error,
            content_type: false,
            mimeMsg: ""
          });
          e.target.value = modifiedContentTypeValue;
          newdata[e.target.id] = e.target.value;
        }

      }
      else {
        setError({
          ...error,
          content_type: false,
          mimeMsg: ""
        })
      }
    }

    setSecurityEdit(newdata);
  }

  const validateReachEdit = (securityEdit) => {
    let rCount = 0;
    let nwerror = { ...error }
    
    if (securityEdit.max_bytes_content === "") {
      nwerror.max_bytes_content = false;
      nwerror.contentMsg = "";
    }
    else {
      if (contentReg.test(securityEdit.max_bytes_content) === false) {
        nwerror.max_bytes_content = true;
        nwerror.contentMsg = "Please enter only digits.";
        rCount++;
      }
      else {
        nwerror.max_bytes_content = false;
        nwerror.contentMsg = ""
      }
    }

    if (securityEdit.content_type === "") {
      nwerror.content_type = false;
      nwerror.mimeMsg = "";
    }
    else {

      if (mimeReg.test(securityEdit.content_type) === false) {
        nwerror.content_type = true;
        nwerror.mimeMsg = "Please enter a valid content type.";
        rCount++;
      }
      else {
        nwerror.content_type = false;
        nwerror.mimeMsg = ""
      }
    }

    setError(nwerror);
    return rCount;
  }
  //secutiy Edit
  const saveReachSecurity = () => {

    const reachCount = validateReachEdit(securityEdit);

    if (reachCount === 0) {
      securityEdit['application_id'] = props?.applicationId;
      service.ReachSecuritySave(securityEdit).then((res) => {
        if (res?.status === 200) {
          getApplicationDetails();
          setEditInfo(false);
        }
      })
    }
  }

  const launchUrl = (appId) => {

    let baseAPI_URL = process.env.REACT_APP_BASE_URL;
    let subbaseUrl = baseAPI_URL.toString().split("/");
    let hostName = subbaseUrl[2].toString().split(".");
    let path = baseAPI_URL.split(hostName[0]).join("qamonitor");
    window.open(path + "/login/" + appId);
  }
  const launchVisualizerUrl = () => {

    let baseAPI_URL = process.env.REACT_APP_BASE_URL;
    let subbaseUrl = baseAPI_URL.toString().split("/");
    let hostName = subbaseUrl[2].toString().split(".");
    let path = baseAPI_URL.split(hostName[0]).join("qamonitor")

    window.open(path + "/run");
  }
  const deleteVersion = () => {
    service.deleteAppReg({ applicationId: props.applicationId }, 'version').then((res) => {
      handleClose();
      getApplicationDetails();
      getGenerateApp();
    }).catch((err) => {

    })
  }
  useEffect(() => {
    document.title = "Datafree Portal";
    getApplicationDetails();
    getGenerateApp();
  }, []);
  return (
    <div>
      <div className="dialoguRow">
        <div className="devHead"></div>
        <div className="devmainHead">Development #datafree Website</div>
        <div className="devHead"></div>
      </div>
      {loading ? <div className="loaderContainer"><Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
      {!loading && hasApp ? (
        <div className="developmentTab">
          <div className="developTabContent">

            <div className="developTabContenthead">
              biNu ID :{" "}
              <span>
                {applicationData ? applicationData?.dev?.appId : ""}
              </span>
            </div>
            <div className="developTabContenthead">
              Current (PAID) Start URL :{" "}
              <br />
              <span>
                {applicationData
                  ? applicationData?.dev?.reachStartUrl
                  : ""}
              </span>
            </div>
            <div className="developTabContenthead">
              #datafree Domain :{" "}
              <br />
              <span>
                {applicationData
                  ? applicationData?.dev?.applicationId?.reachSubDomain +
                  ".sbox." +
                  applicationData?.dev?.applicationId?.reachGateway
                  : ""}
              </span>
            </div>

            <div className="developToolsTabContenthead">Tools:</div>

            <div style={{ textAlign: "left", marginLeft: "10px", marginTop: '10px' }}>
              <button className="devdtbtn1" style={{ width: '97%', height: '55px', cursor: 'pointer' }}
                onClick={() => launchUrl(applicationData?.dev?.appId)}
              >

                Launch Website #datafree Workbench

              </button>
            </div>
            <div style={{ textAlign: "left", marginLeft: "10px" }}>
              <button className="devdtbtn1" style={{ width: '97%', height: '55px', cursor: 'pointer' }}
                onClick={() => launchVisualizerUrl()}>

                Launch Website #datafree Visualiser

              </button>
            </div>

            <div style={{ textAlign: "left", marginLeft: "10px" }}>
              <button className="devdtbtn2" onClick={handleopendelete}
                style={{ width: '97%', height: '39px',  cursor: 'pointer' }}>
                Delete Website Version Definition
              </button>
            </div>
          </div>

          <div className="developMaxTabContent">
            <AntTabs
              value={value}
              onChange={handleChange}
              
              className="tabChange tabViewMenu"
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
            >
              <AntTab
                label="Security"
                title="Click here to view Security Tab"
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
              />
            </AntTabs>

            <TabPanel value={value} index={0}>
              <div className="developmentSecurityTab">
                <button className={!editInfo ? "editbtn" : "NotEdit"} onClick={edit}>
                  Edit
                </button>

                <button className={editInfo ? "editbtn" : "NotEdit"} onClick={handleClose}>
                  Cancel
                </button>

                <button className={editInfo ? "editbtn" : "NotEdit"} onClick={saveReachSecurity}>Save</button>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <div className="companyLabel">
                    Max Bytes per content object
                  </div>

                  <div>
                    {!editInfo ? (
                      <input
                        type="text"
                        className="inputField"
                        name="name"
                        disabled="disabled"
                        value={
                          applicationData ? applicationData?.dev?.securityRules?.contentSize : ""
                        }
                      />
                    ) : (
                      <input
                        type="text"
                        className={!error.max_bytes_content ? "dialoginput" : "errdialoginput"}
                        name="max_bytes_content"
                        id="max_bytes_content"
                        onChange={(e) => contentChange(e)}
                        onBlur={(e) => contentChange(e)}
                        defaultValue={
                          applicationData ? applicationData?.dev?.securityRules?.contentSize : ""
                        }
                      />

                    )}
                    {error.max_bytes_content ? (
                      <div className="errorClass">{error.contentMsg}</div>
                    ) : null}
                  </div>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <div className="companyLabel">
                    Content Types blocked (Mime types)
                  </div>
                  <div>
                    {!editInfo ? (
                      <textarea
                        type="text"
                        className="inputField"
                        name="Description"
                        style={{ height: '70px' }}
                        disabled="disabled"
                        value={applicationData?.dev?.securityRules?.blockedContent?.mime?.map((item, index) => (
                          item ? item : ""
                        ))}
                        placeholder="Enter the list of Mime Types to be blocked from #datafree delivery"
                      />
                    ) : (
                      <textarea
                        type="text"
                        className={!error.content_type ? "dialoginput" : "errdialoginput"}
                        placeholder="Enter the list of Mime Types to be blocked from #datafree delivery"
                        name="content_type"
                        id="content_type"
                        defaultValue={applicationData?.dev?.securityRules?.blockedContent?.mime?.map((item, index) => (
                          item ? item : ""
                        ))}
                        style={{ height: '70px' }}
                        onBlur={(e) => contentChange(e)}
                      />
                    )}
                    {error.content_type ? (
                      <div className="errorClass">{error.mimeMsg}</div>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
            </TabPanel>


          </div>
        </div>
      ) : (
        !loading && !hasApp ? <div className="configDefinebtn" onClick={() => handleClickOpen('body')}>
          {" "}
          + Create Development App Configuration and Define Entry Point
        </div> : null
      )}

      <Dialog open={deleteopen} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" name="popup" onClick={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle  border">
          <div className="FlexRow border" >
            <div className="cmpdialogtitle"> </div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={handleClose} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="ContentHead">
            <div className="warningIcon"><MdWarning /></div>
            <div className="warningText">WARNING !</div>

          </div>
          <div className="logoConfirmtext"> Are you sure you want to delete this #datafree App Version ?
            This cannot be undone.</div>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv" style={{ textAlign: 'center' }}>
            <button className="continueBtn" style={{ width: '43%' }} name="popup" onClick={handleClose} color="primary">
              Cancel
            </button>

            <button className="continueBtn" style={{ width: '43%', marginLeft: '25px' }} 
            disabled= {clickSave ? "disabled" : false}
            onClick={deleteVersion}
            name="popup" color="primary">
              Confirm Delete
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        scroll={scroll}
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="dialogtitle">

          <div className="FlexRow border" >
            <div className="cmpdialogtitle"> Register Development Website </div>
            <div className="cmpdialogtitle" name="popup">
              <IoMdCloseCircleOutline className="modalClose" name="popup" onClick={() => setOpen(false)} />
            </div>
          </div>

        </DialogTitle>
        <DialogContent>
          <div className="dialoginputtext">The website’s current start URL</div>

          <input
            type="text"
            id="website_url"
            className={!reachError.website_url ? "dialoginput" : "errdialoginput"}
            placeholder="Enter the website start url"
            fullwidth="true"
            onChange={handleData}
          />
          {reachError.website_url ? (
            <div className="errorClass">{reachError.webMsg}</div>
          ) : null}

          <div className="dialoginputtext" >Content access details - OPTIONAL (can be defined later)</div>

          <div className="dialoginputtext">Max Bytes per content object</div>
          <input
            type="text"
            id="max_bytes_content"
            placeholder="Enter the maxbytes per content object to be delivered #datafree"
            className={!reachError.max_bytes_content ? "dialoginput" : "errdialoginput"}
            onBlur={handleData}
          />
          {reachError.max_bytes_content ? (
            <div className="errorClass">{reachError.contentMsg}</div>
          ) : null}

          <div className="dialoginputtext">
            Content Type blocked (Mime types)
          </div>
          <input
            type="text"
            id="content_type"
            placeholder="Enter the list of Mime Types to be blocked from #datafree delivery"
            className={!reachError.content_type ? "dialoginput" : "errdialoginput"}
            onBlur={handleData}
          />
          {reachError.content_type ? (
            <div className="errorClass">{reachError.mimeMsg}</div>
          ) : null}


        </DialogContent>
        <DialogActions>
          {loadings ? <div className="loaderContainer"><Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="ivitediv">
            <button
              className="inviteButton"
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </button>
            <button className="inviteButton" color="primary" 
            disabled={clickSave ? "disabled" : false}
            onClick={SaveReach}>
              Save and Return
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default ReachDevelopment;
