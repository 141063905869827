import React , { useState, useEffect,useContext } from 'react'
import "./sidebarUser.css";
import { MdMenu } from "react-icons/md";
import { SideNavMenu } from "./sideNavUser";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withRouter } from "react-router-dom";
import datafreelogo from "../../../assets/img/data_free_inner.png";
import datafreeText from "../../../assets/img/datafree_logo_white.png";
import logoutIcon from "../../../assets/img/logout-icon.png";
import { useHistory } from "react-router-dom";
import service from "../../../api/service";
import AdminService from "../../../api/adminService";
import jwt from "jwt-decode";
import Style from "../../login/style";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import {
  ToggleDispatchContext
 } from "../../context/toggleContext"
 
const useStyles = makeStyles(Style);
function SideNav(props) {
  const { sidebarOpen, mobileviewOpen } = props;
  let stateName = props?.location?.state;
  const ToggleDispatchContextVal = useContext(ToggleDispatchContext)
  const sidebarCollapsed = localStorage.getItem("sidebar-Collapsed");
  const [isExpanded, setIsExpanded] = useState(sidebarCollapsed ? true : false);
  let history = useHistory();
   const classes = useStyles();
   const [isMobile, setIsMobile] = useState(window.innerWidth < 990 ?true:false);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [userData, setUserData] = useState()
  const handleResize = () => {
    if (window.innerWidth < 949) {
      setIsMobile(true);
      mobileviewOpen(true);
      setToggleOpen(true);
    } else {
      setIsMobile(false);
      mobileviewOpen(false);
      setToggleOpen(false);
    }
  };
  const [open, setOpen] = React.useState(false);
  const [companies, setCompanies] = useState();
  const handleClose = () => {
    setOpen(false)
  }
 const getCompanyData =()=>{
    service.ChangeAccount().then((res) => {

      setCompanies(res.data);
    })
  }
  const handleClickOpen = () => {
    setOpen(true);
    
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    getData();
    getCompanyData();
  }, []);

  const handleToggler = () => {
    if (isExpanded) {
      setIsExpanded(false);
      sidebarOpen(false)
      localStorage.setItem("sidebar-Collapsed", true);
      ToggleDispatchContextVal({event: false })
      // return;
    } else {
      setIsExpanded(true);
      sidebarOpen(true)
      localStorage.setItem("sidebar-Collapsed", false);
       ToggleDispatchContextVal({event: true })
    }
  };
  const getData = () => {
    try {

      service.IndexData().then((res) => {

        setUserData(res.data);
      })

    } catch (err) {

    }
  };
  
  const redirectPath = (path, title) => {

    if (path !== "") {
      history.push(path);
      setToggleOpen(window.innerWidth < 990 ? true : false)
      ToggleDispatchContextVal({ event: true })
    }

    if (title === 'BiNu Knowledge Base') {
      window.open("https://sites.google.com/bi.nu/datafree-knowledgebase");
      setToggleOpen(window.innerWidth < 990 ? true : false)
      ToggleDispatchContextVal({ event: true })
    }
    if (title === '#datafree MAX Tutorial') {
      let baseAPI_URL = process.env.REACT_APP_BASE_URL;
      let subbaseUrl = baseAPI_URL.toString().split("/");
      let hostName = subbaseUrl[2].toString().split(".");
      let path = baseAPI_URL.split(hostName[0]).join("tutorial");
      window.open(path);
      setToggleOpen(window.innerWidth < 990 ? true : false)
      ToggleDispatchContextVal({ event: true })
    }
    if (title === 'Support') {
      window.open("https://datafr.ee/contact/");
      setToggleOpen(window.innerWidth < 990 ? true : false)
      ToggleDispatchContextVal({ event: true })
    }
  };
  const redirectUser = () => {
    history.push("/user/details/view");
  }
  const getAccordion = () => {
    return (
      <div>
        {SideNavMenu.map((item, index) => (
          <div key={index}>
            <Accordion
              className="accordionDiv"
              defaultExpanded={true}
            >
              <AccordionSummary
                expandIcon={
                  isExpanded ? (
                    item.subMenu ? (
                      <ExpandMoreIcon className="panela-header" />
                    ) : null
                  ) : null
                }
                aria-controls="panel1a-content"
              >
                <span className="navLinkText">
                  {isExpanded ? item.title : item.collapsedIcon}
                </span>
              </AccordionSummary>
              {item.subMenu
                ? item.subMenu.map((subItem, index) => (
                  <div
                    style={{ textDecoration: "none" }}
                    onClick={() => redirectPath(subItem.path, subItem.title)}
                    key={index}
                  >
                    <AccordionDetails
                      className={
                        window.location.pathname === subItem.path
                          ? "dataRow"
                          : "accodiondetails"
                      }
                    >
                      <div className="subIcon">
                        {subItem.imageIcon ? (
                          <img
                            alt=""
                            src={subItem.imageIcon}
                            className="imgstyle"
                          ></img>
                        ) : (
                          subItem.icon
                        )}
                      </div>

                      <span className="subMenu" title={isExpanded ? subItem.titles : null}>
                        {isExpanded ? subItem.title : null}
                      </span>
                    </AccordionDetails>
                  </div>
                ))
                : null}
            </Accordion>
          </div>
        ))}
      </div>
    );
  };

  const iconMobile = () => {
    if(toggleOpen){
      setToggleOpen(false)
      ToggleDispatchContextVal({event: false })
    }else{
      setToggleOpen(true);
      ToggleDispatchContextVal({event: true})
    }
  };
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshtoken");
    props.history.push('/');
  };
  const routeNotify = () => {
    history.push("/user/notification/details");
  }
  const exitImpersonate = () => {
    try {

      AdminService.exitImpersonate().then((res) => {
        if (res.status === 200 && res.statusText === "OK") {

          localStorage.setItem("token", res.data);
          localStorage.removeItem("Impersonation");

          var decode1 = jwt(res.data);

          if (decode1.roles[0] === "ROLE_USER")
            history.push("/user/datafree");
          else if (decode1.roles[0] === "ROLE_ADMIN")
            history.push("/admin/company/index");
          else if (decode1.roles[0] === "ROLE_LICENSEE")
            history.push("/licensee/index/action");
          else if (decode1.roles[0] === "ROLE_CHANNEL")
            history.push("/channel/publisher/index");
          else if (decode1.roles[0] === "ROLE_ACCOUNTMANAGER")
            history.push("/accountmanager/applications/index");
        } else {
          alert("!please try again");
        }


      })

    } catch (err) {

    }
  };
  const loginUser = (role, Id,cname,ctype) => {
    localStorage.setItem("companyname",cname);
    localStorage.setItem("companytype",ctype);
    localStorage.setItem("companyrole",role);
    service
      .UserLogin({ "role": role, "companyId": Id })
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem("token");
          localStorage.setItem("token", res?.data);
          var decode1 = jwt(res?.data);
          localStorage.setItem("role", decode1?.roles[0]);
          if (decode1.roles[0] === "ROLE_USER") {
            history.push("/user/datafree",);
            setOpen(false)
          } else if (decode1.roles[0] === "ROLE_ADMIN") {
            history.push("/admin/company/index");
            setOpen(false)
          } else if (decode1.roles[0] === "ROLE_LICENSEE") {
            history.push("/licensee/index/action");
            setOpen(false)
          } else if (decode1.roles[0] === "ROLE_CHANNEL") {
            history.push("/channel/applications/index");
            setOpen(false)
          }
          else if (decode1.roles[0] === "ROLE_ACCOUNTMANAGER") {
            history.push("/accountmanager/applications/index");
            setOpen(false)
          }
        }

      })
  }
  return (
    <>
      <div
        className={
          isMobile
            ? "sidebarMobile"
            : isExpanded
              ? "sideBar"
              : "SidebarCollapsed"
        }
      >
        <div className="mainhead">
          {isMobile ? (
            <div className="flexRow">
              <div className="logoFlex">
                <div>
                  <img src={datafreeText} alt="" className="logoText"></img>
                </div>
                <div>
                  <MdMenu onClick={iconMobile} className="hamenuIcon" />
                </div>
              </div>
              <div className="subspanTitle">
                {userData ? userData.version : ""}
              </div>
            </div>
          ) : (
            <div className={isExpanded ? "sidebarHead" : null}>
              {isExpanded ? (
                <div className="sidebarHeadItem">
                  <img src={datafreelogo} alt="" className="sidebarTopIcon"></img>
                </div>
              ) : null}
              <div
                title="Dashboard"
                className={
                  isExpanded ? "sidebarHeadItem1" : "sidebarHeadItem1Collapsed"
                }
              >
                <MdMenu onClick={handleToggler} id="sidenavToggler" />
              </div>
            </div>
          )}

          {isExpanded ? (
            !isMobile ? (
              <div className="mainaccordionDiv">
                  <div className="mainNavSubtext">  {isExpanded ? "Datafree Portal" : null}</div>
                <div className={isExpanded ? "mainnavLinkText" : null}>
                  {isExpanded ? "Version :" + userData?.version : null}
                </div>
                {/* <div className={isExpanded ? "mainnavLinkText" : null}>
                  {isExpanded ? "ga4b6b595" : null}
                </div> */}
              </div>
            ) : null
          ) : null}
        </div>
        {toggleOpen ? (
          <div></div>
        ) : (
          <div>
            <div className="accordionDiv">
              <div className={isExpanded ? "mainprofileText" : null} title="Dashboard">
                {isExpanded ? userData?.name : null}
              </div>
              <div className={isExpanded ? "submainprofileText" : null} onClick={redirectUser}
                title="Click here to view admin user details">
                {isExpanded ? userData?.email : null}
              </div>
            </div>


            {isExpanded && companies?.length >1 ?
            <div className="accordionDiv  userNameDiv">
              <div className={isExpanded ? "mainprofileText" : null}
                style={{ fontSize: '14px', fontWeight: '400' }}
              >
                {isExpanded && companies?.length >1 ?
                  <div><div>
                   {localStorage.getItem("companyname")}
                  </div><div>  {localStorage.getItem("companytype")}</div>
                    <div> {localStorage.getItem("companyrole")}</div></div>
                  : null}
              </div>
              <div>  {isExpanded ?
                <button className="btnChange" onClick={handleClickOpen}>
                  Change Account
                </button> : null}</div>
            </div>:""}

            {userData?.notification > 0 ?
              <div className="accordionDiv">
                <div className={isExpanded ? "mainprofileText" : null}
                  style={{ fontSize: '14px', fontWeight: '400' }}
                  title="Click here to view account details " onClick={routeNotify}>
                  {isExpanded ? "Notification" : null}
                </div>
              </div> :
              null
            }
            {getAccordion()}
            <div className="accordionDivRow">
              <div className="itemIcon">
                <img src={logoutIcon} alt=""></img>
              </div>

              {localStorage.getItem("Impersonation") ? (
                <div
                  className={isExpanded ? "iconNavText" : null}
                  onClick={exitImpersonate}
                >
                  {isExpanded ? "Exit Impersonation" : null}
                </div>
              ) : (
                <div
                  className={isExpanded ? "iconNavText" : null}
                  onClick={logout}
                  title="Click here to logout"
                >
                  {isExpanded ? "Logout" : null}
                </div>

              )}
            </div>
          </div>
        )}
      </div>
      <Dialog open={open} classes={{ scrollPaper: classes.scrollPaper }}

        fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <div className="FlexRow">
            <div className="cmpdialogtitle"> Please choose the Account you wish to Log in to</div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline style={{ cursor: "pointer" }}
                className="modalClose"
                onClick={handleClose} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
            <table className={classes.popupEnter}>
              <thead>
                <tr className={classes.popupRow}>
                  <th className={classes.popupHead}> Company</th>
                  <th className={classes.popupHead}>Type</th>
                  <th className={classes.popupHead}>Your Role</th>
                  <th></th>
                </tr>
              </thead>
              {companies?.length >= 0 ?
                companies?.map((item, index) => (
                  <tbody key={index}>
                    <tr className={classes.popupRow}>
                      <td className={classes.popupTitle}>{item?.company_name}</td>
                      <td className={classes.popupTitle}>{item?.company_type}</td>
                      <td className={classes.popupTitle}>{item?.role}</td>
                      <td ><button className={classes.button} onClick={() => loginUser(item?.role, item?.company_id?.companyId ,item?.company_name,item?.company_type)}> Enter</button></td>
                    </tr>

                  </tbody>
                )) : <tbody><tr className={classes.popupRow}><td>No Data found</td></tr></tbody>}
            </table>
           <div className="btnRight"><button className="defultbtn btnRight" onClick={handleClose}>
             Close
           </button></div> 
          </div>
        </DialogContent>

      </Dialog>
    </>
  );
}
export default withRouter(SideNav);
