import React , { useState, useEffect }from "react";
import { FiSettings } from "react-icons/fi";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {trackPromise } from "react-promise-tracker";
import services from "../../../api/service";



function Utilities() {
  const [channelData, setChannelData] = useState();

  async function ChannelDetail() {
    try {
      trackPromise(
        services.utilityData().then((res) => {
          setChannelData(res.data);
        })
      );
    } catch (err) {

    }
  }
  useEffect(() => {
    ChannelDetail();
  }, []);
  
  return (
    <div className="utilityContent">
      <div className="utilityView">
        <div className="utilityhead">
          <div>
            <FiSettings className="utilityheadIcon" />
          </div>
          <div className="utilToptext">Utilities</div>
        </div>
        <div className="utilitytable">
          <TableContainer component={Paper}>
            <Table className="tablestyle" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Utility Name</TableCell>
                  <TableCell>Utility Description</TableCell>
                </TableRow>
              </TableHead>
              {channelData?.utilityUserAccessDetail.length>0?<TableBody>
                {channelData.utilityUserAccessDetail.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ color: "#337ab7" }}
                    >
                     {row.utility_name}
                    </TableCell>
                    <TableCell>{row.utility_desc}</TableCell>
                   
                  </TableRow>
                ))}
              </TableBody>:(<TableBody>
                
                <TableRow >
                  <TableCell
                  
                  >No additional utilities are available to you at this time
                   
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell> </TableCell>
                </TableRow>
             
            </TableBody>)}
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
export default Utilities;
