import React from "react";
import { Route, Switch} from "react-router-dom";
import ChannelList from "./licenseePages/channelList";
import LicenseeChannelDetailView from "../licensee/licenseePages/licenseeChannelDetailView";

import LicenseeCustomerDetailView from "../licensee/licenseePages/licenseeCustomeDetailView"; 
import PublisherList from "./licenseePages/publisherList";
import UserList from "./licenseePages/userList";
import Utilities from "./licenseePages/utilities";
import ApplicationList from "./licenseePages/applicationList";
import AccountDetailView from "./licenseePages/licenseeAccountDetailView";
import UserDetails from "../user/userPages/userDetails";
import LicenseeAccountDetailView from "./licenseePages/licenseeServiceDetail";
import AdminDeployedIpDetails from "./licenseePages/licenseeServiceDeployedIpDetails";
import NotificationDetails from "../channel/channelPages/notificationDetails";
import LicenseeRevenue from "../licensee/licenseePages/licenseeRevenue";
export default function Licensee() {
  return (
    <div>
      <Switch>
        <Route exact path="/licensee/index/action" component={ChannelList} /> 
        <Route exact path="/licensee/channel/:channelId/detail/view" component={LicenseeChannelDetailView} />
        <Route exact path="/licensee/publisher/index" component={PublisherList} />
        <Route exact path="/licensee/user/index" component={UserList} />
        <Route exact path="/licensee/application/index" component={ApplicationList} />
        <Route exact path="/licensee/utilities/option" component={Utilities} />
        <Route exact path="/licensee/account/detail/view" component={AccountDetailView} />
        <Route exact path="/licensee/customer/:licenseeid/detail/view" component={LicenseeCustomerDetailView} />
        <Route exact path="/licensee/user/details/view" component={UserDetails}/>
        <Route exact path="/licensee/account/view/:serviceid/service/details" component={LicenseeAccountDetailView} />
        <Route exact path="/licensee/view/deployed/:id/:serviceid/ips/details" component={AdminDeployedIpDetails} />
        <Route exact path="/licensee/notification/details" component={NotificationDetails}/>
        <Route exact path="/licensee/revenue/maintenance/index" component={LicenseeRevenue}/>
      </Switch>
    </div>
  );
}
