import React, { useState, useEffect, useRef,useContext } from "react";
import { Tabs, Tab, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, withStyles, Grid } from "@material-ui/core";
import jwt from "jwt-decode";
import Service from "../../../api/adminService";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useHistory, useParams } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import Snackbar from "@material-ui/core/Snackbar";
import { IoMdCloseCircleOutline } from "react-icons/io";
import MuiAlert from "@material-ui/lab/Alert";
import VMCTable from "../../../Recomponent/Table";
import EmptyTable from "../../../Recomponent/EmptyTable";
import Loader from "react-loading";
//import Loader from "react-loader";
import { usePromiseTracker } from "react-promise-tracker";
import ChannelService from "../../../api/channelService";
import {
  ToggleContext
} from "../../context/toggleContext"
function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div>
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AntTabs = Tabs;

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    borderBottom: "1px solid #ddd ",
    minWidth: "80px",

    textShadow: "none",
    color: "#0059b6",

    "&$selected": {
      color: "#555",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderBottomColor: "transparent",
      borderBottom: "none",
      cursor: "default",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
const useStyles = makeStyles((theme) => ({
  scrollPaper: {
    alignItems: "baseline",

    // default center
  },
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};

function PublisherDetailView(props) {
  let history = useHistory();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const { customerid } = useParams();
  const [successRequest, setsuccessRequest] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [discount, setDiscount] = useState("");
  const [editInfo, setEditInfo] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [userOpen, setUserOpen] = useState(false);
  const [userDetails, setuserDetails] = useState();
  const [customerData, setcustomerData] = useState();
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [user_id, setUser_id] = useState();
  const [changeOwnerOpen, setChangeOwnerOpen] = useState(false);
  const [lodervisible, setlodervisible] = useState(false);
  const toggleContextVal = useContext(ToggleContext)
  const [tablecols, setTableCols] = useState([
    {
      colName: "User’s Name",
      colMap: "name",
      linkName: true,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: true,
    },
    {
      colName: "Email Address",
      colMap: "email",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Role",
      colMap: "role",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Type",
      colMap: "userType",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Verified",
      colMap: "verified",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Status",
      colMap: "status",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Created",
      colMap: "created",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: true,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Impersonate",
      colMap: "Impersonate",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: true,
      userTab: false,
    },
  ]);

  const [tablecolspublisher, setTableColsPublisher] = useState([
    {
      colName: "biNu Id",
      colMap: "applicationId",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "App Name",
      colMap: "name",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Product Type",
      colMap: "productIdproductType",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Free Domain",
      colMap: "reachSubDomain",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Google App Id",
      colMap: "googleAppId",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Type",
      colMap: "applicationType",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Created",
      colMap: "created",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: true,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Max Bytes / Day(MB)",
      colMap: "maxBytesPerDayAction",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Production Health Check Required",
      colMap: "appln",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Status",
      colMap: "status",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Revenue Type",
      colMap: "revenueType",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
  ]);

  const [editcustomer, seteditcustomer] = useState({
    company_name: "",
    monthly_data: "",
    companyId: "",
    channel_cust_id: "",
    account_manager_email: "",
    primary_cust_email: "",
    pricing_discount: "",
    data_credit_limit: "",
    status: "",
    contract_term: "",
    company_id: "",
  });
  const [invite, setInvite] = useState({
    name: "",
    email_address: "",
    companyId: "",
  });
  const [inviteError, setInviteError] = useState({
    name: false,
    email_address: false,

    companyId: false,
    nameerrmsg: "",
    emailerrmsg: "",
    errResponse: "",
  });
  const [userdata, setUserdata] = useState({
    username: "",
    user_role: "User",
    user_type: "Internal",
    user_verified: "No",
    user_status: "Active",
    user_id: "",
    company_id: "",
  });
  const [userid, setUserId] = useState({
    userId: "",
  });

  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const ChangeOwnerOpen = () => {
    setChangeOwnerOpen(true);
  };

  const handleOwnerClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setChangeOwnerOpen(false);
    }

  }
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setUserOpen(false);
      setOpen(false);

    }
    else {
      setUserOpen(true);
    }

    setInvite({
      name: "",
      email_address: "",
      companyId: "",
    });
    setErrorField({
      changed_channel_id: false,
    });
    setuserDetails({
      username: "",
      user_role: "User",
      user_type: "Internal",
      user_verified: "Yes",
      user_status: "Active",
      user_id: "",
      company_id: "",
    });
    setUserUpdate({
      username: false,
    });
    setUser_id({});
    setInviteError({
      name: false,
      email_address: false,
      companyId: false,
      errmsg: "",
    });
    setTransfer(false);
    seteditcustomer({
      company_name: customerData?.publisherDetail.name,
      monthly_data: customerData?.dataUsageAlert,
      companyId: customerData?.publisherDetail.companyId,
      channel_cust_id: customerData?.publisherDetail.channelCustId,
      account_manager_email: customerData?.publisherDetail.accMngrEmail,
      primary_cust_email: customerData?.publisherDetail.primaryCustEmail,
      pricing_discount: customerData?.publisherDetail.channelDiscountPrice,
      data_credit_limit: customerData?.publisherDetail?.accDataCreditLimitId?.creditLimit ? customerData?.publisherDetail.accDataCreditLimitId.creditLimit : "",
      status: customerData?.publisherDetail.status,
      contract_term: customerData?.publisherDetail.contractTerm,
      company_id: customerid,
    });
    setChangeOwnerData({
      publisher_id: customerid,
      changed_channel_id: "",
    });
    setEditInfo(false);
    setError({
      company_name: false,
      monthly_data: false,
      channel_cust_id: false,
      account_manager_email: false,
      primary_cust_email: false,
      pricing_discount: false,
      msg: "",
      dataMsg: "",
      channelIdMsg: "",
      emailMsg: "",
      pimayEmailMsg: "",
      discountPriceMsg: "",
    });
  };
  const [empty, setEmpty] = useState(false);
  const [message, setMessage] = useState();
  const [transfer, setTransfer] = useState(false);
  const clickSave = () => {
    if (changeOwnerData.changed_channel_id.length === 0) {
      setErrorField({
        changed_channel_id: true,
        msg: "This field is required",
      });
    } else {
      setTransfer(true);
    }
  };

  const edit = () => {
    setEditInfo(true);
    localStorage.setItem("EditOpen", true);
  };
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
    setsuccessRequest(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
    setsuccessRequest(false);

  };

  const [userUpdate, setUserUpdate] = useState({
    username: false,
    msg: "",
  });
  const cancelTansfer = () => {
    setTransfer(false);
    setErrorField({
      changed_channel_id: "",
    });
    setChangeOwnerData({
      publisher_id: customerid,
      changed_channel_id: "",
    });
  };
  //passwordReset
  async function resetPassword(EMAIL) {

    try {
      trackPromise(
        Service.passWordReset({ email: EMAIL, admin: "admin" }).then((res) => {
          if (res.status === 200) {
            setsuccessRequest(true);
            setMessage(res.data.message);
            handleClose();
          }
        })
      );
    } catch (err) {

    }
  }
  const [changeOwnerData, setChangeOwnerData] = useState({
    publisher_id: "",
    changed_channel_id: "",
  });

  const changeOwner = () => {
    trackPromise(
      Service.ChangeOwner(changeOwnerData)
        .then((res) => {

          if (res.status === 200) {
            setEditInfo(false);
            handleClose();
            cancelTansfer();
            setOpen(false);
            setChangeOwnerOpen(false)
            localStorage.removeItem("EditOpen");
          } else {
            seterrorOpen(true);
            setEditInfo(true);
          }
          getCustomerList();
        })
        .catch((error) => {
          setResMessage("Something went wrong. Please reload the page");
          seterrorOpen(true);
        })
    );
  };
  const [errorField, setErrorField] = useState({
    changed_channel_id: false,
    msg: "",
  });
  const channelId = (e) => {
    e.preventDefault();
    const newchangeOwnerData = { ...changeOwnerData };
    newchangeOwnerData[e.target.id] = e.target.value;
    if (e.target.id === "changed_channel_id") {
      setErrorField({
        ...error,
        changed_channel_id: false,
      });
    }
    setChangeOwnerData(newchangeOwnerData);
  };

  const redirectRoute = () => {
    history.push("/admin/company/index");
  };

  const reg = /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/;

  const handleUser = (e) => {
    e.preventDefault();
    const invitedata = { ...invite };
    invitedata[e.target.id] = e.target.value;
    invitedata["companyId"] = customerid;
    if (e.target.id === "name") {
      setInviteError({
        ...inviteError,
        name: false,
      });
    }
    if (e.target.id === "email_address") {
      if (e.target.value) {
        EmailCheck(e.target.value);
      } else {
        setInviteError({
          ...inviteError,
          email_address: true,
          emailerrmsg: "This field is required",
        });
      }
    }

    setInvite(invitedata);
  };

  ////Check Email
  async function EmailCheck(value) {
    trackPromise(
      Service.EmailCheck({ email_address: value })
        .then((res) => {
          if (res.data === false) {
            setInviteError({
              ...inviteError,
              email_address: true,
              emailerrmsg: "This​ email address already exists please use admin utilities to check current user access",
            });
          } else if (value && reg.test(value) === false) {
            setInviteError({
              ...inviteError,
              email_address: true,
              emailerrmsg: "Please enter a valid email address.",
            });
          } else {
            setInviteError({
              ...inviteError,
              email_address: false,
            });
          }
        })
        .catch((error) => {

        })
    );
  }
  const [invitedSuccess, setInvitedSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clickInvite, setClickInvite] = useState(false);
  //invite New
  async function InviteNew() {
    if (invite.name.length == 0 && invite.email_address.length == 0) {
      setInviteError({
        ...inviteError,
        name: true,
        email_address: true,
      });
    } else if (inviteError.name) {
      setInviteError({
        ...inviteError,
        name: true,
      });
    } else if (inviteError.email_address) {
      setInviteError({
        ...inviteError,
        email_address: true,
      });
    } else {
      setLoading(true);
      setClickInvite(true);
      trackPromise(
        Service.invitechannelUser(invite)
          .then((res) => {

            if (res.status === 200) {
              //setEditInfo(false);
              handleClose();
              setOpen(false);
              setInvitedSuccess(true);
              //setsuccessOpen(true);
              setLoading(false);
            } else {
              seterrorOpen(true);
              setEditInfo(true);
            }

            setClickInvite(false);
            getCustomerList();
          })
          .catch((error) => {
            setResMessage("Something went wrong. Please reload the page");
            seterrorOpen(true);
          })
      );
    }
  }
  const [resMessage, setResMessage] = useState();

  const EditUser = (e) => {
    e.preventDefault();

    const newdata = { ...userdata };
    newdata[e.target.id] = e.target.value;
    if (e.target.id === "username") {
      if (e.target.value) {
        setUserUpdate({
          username: false,
        });
      } else {
        setUserUpdate({
          username: true,
          msg: "This field is required",
        });
      }
    }
    setUserdata(newdata);
  };

  const [toastMsg, setToastMsg] = useState();
  //editUser Detail
  const userNameRef = React.useRef();
  async function EditUserData() {
    setlodervisible(true);
    if (userUpdate.username) {
      setUserUpdate({
        username: true,
      });
      userNameRef.current.focus();
    } else {
      trackPromise(
        Service.editUser(userdata)
          .then((res) => {
            setlodervisible(false);
            if (res.status === 200) {
              setToastMsg("User Details Updated Successfully!")
              handleClose();
              setEditInfo(false);
              getCustomerList();
              setUserOpen(false);
              setsuccessOpen(true);
            } else {
              seterrorOpen(true);
              getCustomerList();
              setUserOpen(true);
              setEditInfo(true);
            }

          })
          .catch((err) => {
            setResMessage(err.response.data);
            seterrorOpen(true);
          })
      );
    }
  }

  //getUser

  async function getUserDetail(value,Id) {

    setuserDetails({});
    trackPromise(
      Service.getUserById({ userId: value,companyId:Id})
        .then((res) => {
          setuserDetails(res.data);
          setUserdata({
            username: res.data.userName,
            user_role: res.data.role,
            user_type: res.data.type,
            user_verified: res.data.verified && res.data.verified == 1 ? "Yes" : "No",
            user_status: res.data.status,
            user_id: res.data.userId,
            company_id: res.data.companyId,
          });


        })
        .catch((err) => {

        })
    );
  }

  const [error, setError] = useState({
    company_name: false,
    monthly_data: false,
    channel_cust_id: false,
    account_manager_email: false,
    primary_cust_email: false,
    pricing_discount: false,
    msg: "",
    dataMsg: "",
    channelIdMsg: "",
    emailMsg: "",
    pimayEmailMsg: "",
    discountPriceMsg: "",
  });

  const regexMonthlyData =
    /(?!0+$)(^[0-9]+$)|(^[0-9]+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\.[0-9]{1,2})+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\,[0-9]{2,4})+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K))$/i;
  const regex_channel_customer_identifier = /^[a-zA-Z0-9-]*$/i;
  const emailreg = /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/;
  const numberRegex = /^\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*$/;

  const handleCustomer = (e) => {
    e.preventDefault();
    const newdata = { ...editcustomer };
    newdata[e.target.id] = e.target.value;

    newdata["companyId"] = companyId;

    //newdata["pricing_discount"] = discount;

    if (e.target.id === "company_name") {
      if (e.target.value) {
        companyCheck(e.target.value);
      } else {
        setError({
          ...error,
          company_name: true,
          msg: "This field is required",
        });
      }
    }
    if (e.target.id === "monthly_data") {
      setError({
        ...error,
        monthly_data: true,
      });
      if (regexMonthlyData.test(e.target.value) === false && e.target.value != "") {
        setError({
          ...error,
          monthly_data: true,
          dataMsg: "An Alert Email will be sent to your company contacts if the Monthly Forecast Data Usage exceeds this",
        });
      } else {
        setError({
          ...error,
          monthly_data: false,
        });
      }
    }
    if (e.target.id === "channel_cust_id") {
      setError({
        ...error,
        channel_cust_id: true,
      });
      if (regex_channel_customer_identifier.test(e.target.value) === false && e.target.value != "") {
        setError({
          ...error,
          channel_cust_id: true,
          channelIdMsg: "MTN ID format is 1-2A34BC",
        });
      } else {
        setError({
          ...error,
          channel_cust_id: false,
        });
      }
    }
    if (e.target.id === "primary_cust_email") {
      setError({
        ...error,
        primary_cust_email: true,
      });
      if (emailreg.test(e.target.value) === false && e.target.value != "") {
        setError({
          ...error,
          primary_cust_email: true,
          pimayEmailMsg: "Please enter a valid email address.",
        });
      } else {
        setError({
          ...error,
          primary_cust_email: false,
        });
      }
    }
    if (e.target.id === "account_manager_email") {
      setError({
        ...error,
        account_manager_email: true,
      });
      if (emailreg.test(e.target.value) === false && e.target.value != "") {
        setError({
          ...error,
          account_manager_email: true,
          emailMsg: "Please enter a valid email address.",
        });
      } else {
        setError({
          ...error,
          account_manager_email: false,
        });
      }
    }
    if (e.target.id === "pricing_discount") {
      setError({
        ...error,
        pricing_discount: false,
      });
      if (numberRegex.test(e.target.value) == false && e.target.value !== "") {
        setError({
          ...error,
          pricing_discount: true,
          discountPriceMsg: "Please enter a valid number.",
        });
      } else if (e.target.value > 99) {
        setError({
          ...error,
          pricing_discount: true,
          discountPriceMsg: "Please enter a value less than or equal to 99.9.",
        });
      } else {
        setError({
          ...error,
          pricing_discount: false,
        });
      }
    }
    seteditcustomer(newdata);
  };

  async function EditCustomerData() {

    editcustomer["company_id"] = customerid;
    if (error.company_name) {
      setError({
        ...error,
        company_name: true,
      });
    } else if (error.monthly_data) {
      setError({
        ...error,
        monthly_data: true,
      });
    } else if (error.channel_cust_id) {
      setError({
        ...error,
        channel_cust_id: true,
      });
    } else if (error.account_manager_email) {
      setError({
        ...error,
        account_manager_email: true,
      });
    } else if (error.primary_cust_email) {
      setError({
        ...error,
        primary_cust_email: true,
      });
    } else if (error.pricing_discount) {
      setError({
        ...error,
        pricing_discount: true,
      });
    } else {
      setlodervisible(true);
      Service.editcustomerList(editcustomer)
        .then((res) => {
          if (res.status === 200) {
            setlodervisible(false);
            setToastMsg("Customer Details Updated Successfully!")
            setEditInfo(false);
            handleClose();
            setsuccessOpen(true);
            localStorage.removeItem("EditOpen");
          } else {
            seterrorOpen(true);
            setEditInfo(true);
          }

          getCustomerList();
        })
        .catch((error) => {

        })

    }
  }

  const [addData, setAddData] = useState();
  const getCustomerList = () => {
    setlodervisible(true);
    setcustomerData({});
    setEmpty(false);

    trackPromise(
      Service.getCustomerList(customerid)
        .then((res) => {
          setlodervisible(false);
          setCompanyId(res.data.publisherDetail.parentCompanyId.parentCompanyId.companyId);
          setDiscount(res.data.publisherDetail.channelDiscountPrice);

          setAddData(res.data);
          setcustomerData(res?.data);
        
          seteditcustomer({
            company_name: res?.data?.publisherDetail.name,
            monthly_data: res?.data?.dataUsageAlert,
            companyId: res?.data?.publisherDetail.companyId,
            channel_cust_id: res?.data?.publisherDetail?.channelCustId,
            account_manager_email: res?.data?.publisherDetail?.accMngrEmail,
            primary_cust_email: res?.data?.publisherDetail?.primaryCustEmail,
            pricing_discount: res?.data?.publisherDetail?.channelDiscountPrice,
            data_credit_limit: res?.data?.publisherDetail?.accDataCreditLimitId?.creditLimit ? res.data.publisherDetail.accDataCreditLimitId.creditLimit : "",
            status: res?.data?.publisherDetail?.status,
            contract_term: res?.data.publisherDetail?.contractTerm,
            company_id: customerid,
          });

          changeOwnerData["publisher_id"] = customerid;

          if ((res.status == 200 && res?.data?.usersListDetail.length === 0) || (res.status == 200 && res.data.applicationsListDetail.length === 0)) {

            setEmpty(true);
          } else {
            setEmpty(false);
          }
        })
        .catch((error) => {

        })
    );
  }
 const cmpyReg = /[a-zA-Z]/;
  ////CompanyCheck
  async function companyCheck(cname) {
    try {
      trackPromise(
        Service.companyCheck({ company_name: cname, companyid: customerid }).then((res) => {
          if (res.data === false) {
            setError({
              ...error,
              company_name: true,
              msg: "This​ Company ​name ​already​ exist",
            });
          } else {
          if (cname.includes(",")) {
            setError({
              ...error,
              company_name: true,
              msg: "Please enter a name without comma",
            });
          } 
          else if (cmpyReg.test(cname) === false){
            setError({
              ...error,
              company_name: true,
              msg: "Field must have at least 1 character a-z or A-Z",
            });
          }
          else {
            setError({
              ...error,
              company_name: false,
            });
          }
         }
        })
      );
    } catch (err) {

    }
  }

  const OpenUserTab = (scrollType, Id,cId) => {
    getUserDetail(Id,cId);

    setUserOpen(true);
    setScroll(scrollType);
  };

  const [emailId, setEmailId] = useState({
    email: "",
    role:"",
    companyId:""
  });
  const editRevType = (e, ids) =>{
   
    e.preventDefault();

    try {
      trackPromise(
        Service.revenueChange({ applicationId: ids, rtId: e.target.value }).then((res) => {

          getCustomerList();
        })
      );
    } catch (err) {

    }
  }
  const editStatus = (e, id) => {

    e.preventDefault();

    try {
      trackPromise(
        ChannelService.ChangeStatus({ applicationId: id, status: e.target.value }).then((res) => {

          getCustomerList();
        })
      );
    } catch (err) {

    }
  }



  const ChangeHealth = (e, healthon, id) => {
    let health;

    e.target.checked = !healthon
    if (e.target.checked === false) {
      health = "off";
    }
    else {
      health = "on";
    }
    try {
      trackPromise(
        ChannelService.ChangeHealth({ deploymentId: id, health: health }).then((res) => {

          getCustomerList();
        })
      );
    } catch (err) {

    }
  }
  const impersonate = (emailid,role,Id,type, name) => {
    emailId["email"] = emailid;
    emailId["role"] = role;
    emailId["companyId"] = Id;
    try {
      trackPromise(
        Service.impersonateUser(emailId).then((res) => {

          if (res.status === 200 && res.statusText === "OK") {
            localStorage.setItem("token", res.data);
            localStorage.setItem("Impersonation", true);
            localStorage.setItem("companyname", name)
            localStorage.setItem("companytype", type)
            localStorage.setItem("companyrole", role)
            var decode1 = jwt(res.data);

            if (decode1.roles[0] === "ROLE_USER") props.history.push("/user/datafree");

            else if (decode1.roles[0] === "ROLE_RESELLER") props.history.push("/user/datafree");
            else if (decode1.roles[0] === "ROLE_CUSTOMER") props.history.push("/user/datafree");
            else if (decode1.roles[0] === "ROLE_LICENSEE") props.history.push("/licensee/index/action");
            else if (decode1.roles[0] === "ROLE_CHANNEL") props.history.push("/channel/publisher/index");
            else if (decode1.roles[0] === "ROLE_ACCOUNTMANAGER") props.history.push("/accountmanager/applications/index");

          } else {
            alert("!please try again");
          }
        })
      );
    } catch (err) {

    }
  };



  const [openPopup, setOpenPopup] = useState(false);

  document.addEventListener("click", e => {
    let editNot = Boolean(localStorage.getItem("EditOpen"));

    if (editNot === true) {

      if (e.target.id === "Edit" || e.target.id === "Cancel" || e.target.id === "Save" ||
        e.target.name === "company_name" || e.target.name === "popup" || e.target.id === "status" || e.target.id === "Remove") {

        if (e.target.id === "Cancel" || e.target.id === "Save") {
          localStorage.removeItem("EditOpen")

        }
        setOpenPopup(false);
      }
      else {
        //setOpenPopup(true); 
        setOpenPopup(value => !value);
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();
      }
    }
    else {
      setOpenPopup(false);
    }

  }, true);


  useEffect(() => {

    getCustomerList();
    setlodervisible(true);
    localStorage.removeItem("EditOpen");
  }, []);

  return (
    <div className="accountContent" name="popup">
      <div className={toggleContextVal.event ?"accountMenuView" :"accountView"}>
        <div className="accounthead">
          <div className="accountNavtext">Company Details - Admin View</div>
        </div>
        <AntTabs
          value={value}
          className="tabViewMenu"
          onChange={handleChange}
          aria-label="ant example"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          <AntTab label="Company" title="Click here to view Company Tab" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
          <AntTab label="Users" title="Click here to view Users Tab" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
          <AntTab label="Applications" title="Click here to view Applications Tab" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
        </AntTabs>
        <Typography className={classes.padding} />

        <TabPanel value={value} index={0}>
          {
            lodervisible &&
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: '0px',
              right: '0px',
              margin: 'auto',
              position: 'absolute',
              left: '0px',
              bottom: '0px',
              zIndex: '3000',
              backgroundColor: "#ffffff",
             
              paddingTop: '8px',
              paddingBottom: '8px',
              borderRadius: '5px',
              flexDirection: 'column',
              width: '70px',
              height: '60px'
            }} >
              <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
              <span style={{ fontSize: '10px', display: "block" }}></span>
            </div>
          }
          {customerData?.publisherDetail ?
            <div className="companyForm" name="popup">
              <button type="hidden" id="pops" style={{ display: 'none' }} onClick={() => setOpenPopup(true)}></button>
              <div className="row">
                <div className="col-md-4 form-group">
                  <div className="companyLabel">Company Name</div>

                  <div>
                    {!editInfo ? (
                      <input type="text" className="form-control" disabled="disabled" style={{ cursor: "not-allowed" }}
                        value={customerData?.publisherDetail?.name} />
                    ) : (
                      <input
                        type="text"
                        className={error.company_name ? "errorField form-control" : "form-control"}
                        name="popup"
                        id="company_name"
                        defaultValue={customerData?.publisherDetail?.name}
                        onBlur={(e) => handleCustomer(e)}
                        onChange={(e) => handleCustomer(e)}
                      />
                    )}
                  </div>
                  {error.company_name ? <a className="errorClass">{error.msg ? error.msg : "This field is required"}</a> : null}
                </div>
                <div className="col-md-4 form-group">
                  <div className="companyLabel">Channel Owner</div>
                  <div>
                    <input type="text" className="form-control" name="popup" disabled="disabled"
                      style={{ cursor: "not-allowed" }} value={customerData?.publisherDetail?.parentCompanyId?.name} />
                  </div>
                </div>
                <div className="col-md-4 form-group">
                  {!editInfo ? (
                    <button className="inviteButton" onClick={edit}
                      id="Edit"
                      title="Click here to edit publisher details" name="popup">
                      Edit Details
                    </button>
                  ) : (
                    <button className="changeOwnButton" onClick={ChangeOwnerOpen} name="popup">
                      Change Owner
                    </button>
                  )}
                </div>
              </div>
              {editInfo ? (
                <div className="row">
                  <div className="customerListflex"></div>
                </div>
              ) : null}
              <div className="row">
                <div className="col-md-4 form-group">
                  <div className="companyLabel">Monthly Account #datafree Usage Alert (bytes)</div>
                  <div>
                    {!editInfo ? (
                      <input type="text" className="form-control" disabled="disabled" style={{ cursor: "not-allowed" }} value={customerData?.dataUsageAlert} />
                    ) : (
                      <input
                        type="text"
                        className={error.monthly_data ? "errorField form-control" : "form-control"}
                        name="popup"
                        id="monthly_data"
                        defaultValue={customerData?.dataUsageAlert}
                        onChange={(e) => handleCustomer(e)}
                        onBlur={(e) => handleCustomer(e)}
                      />
                    )}
                  </div>
                  {error.monthly_data ? <a className="errorClass">{error.dataMsg ? error.dataMsg : ""}</a> : null}
                </div>
                {/* <div style={{ width: "8%" }}></div> */}
                <div className="col-md-4 form-group">
                  <div className="companyLabel">Channel Customer Identifier</div>
                  <div>
                    {!editInfo ? (
                      <input type="text" className="form-control" disabled="disabled" style={{ cursor: "not-allowed" }} value={customerData?.publisherDetail?.channelCustId} />
                    ) : (
                      <input
                        type="text"
                        className={error.channel_cust_id ? "errorField form-control" : "form-control"}
                        name="popup"
                        id="channel_cust_id"
                        defaultValue={customerData?.publisherDetail?.channelCustId}
                        onChange={(e) => handleCustomer(e)}
                        onBlur={(e) => handleCustomer(e)}
                      />
                    )}
                  </div>
                  {error.channel_cust_id ? <a className="errorClass">{error.channelIdMsg ? error.channelIdMsg : ""}</a> : null}
                </div>
                <div className="ivitecustdiv"></div>
              </div>
              <div className="row">
                <div className="col-md-4 form-group">
                  <div className="companyLabel">Account Manager Email</div>
                  <div>
                    {!editInfo ? (
                      <input type="text" className="form-control" disabled="disabled" style={{ cursor: "not-allowed" }} value={customerData?.publisherDetail?.accMngrEmail} />
                    ) : (
                      <input
                        type="text"
                        className={error.account_manager_email ? "errorField form-control" : "form-control"}
                        name="popup"
                        id="account_manager_email"
                        defaultValue={customerData?.publisherDetail?.accMngrEmail}
                        onChange={(e) => handleCustomer(e)}
                        onBlur={(e) => handleCustomer(e)}
                      />
                    )}
                  </div>
                  {error.account_manager_email ? <a className="errorClass">{error.emailMsg ? error.emailMsg : ""}</a> : null}
                </div>
                {/* <div style={{ width: "8%" }}></div> */}
                <div className="col-md-4 form-group">
                  <div className="companyLabel">Usage Alert Email</div>
                  <div>
                    {!editInfo ? (
                      <input type="text" className="form-control" disabled="disabled" style={{ cursor: "not-allowed" }} value={customerData?.publisherDetail?.primaryCustEmail} />
                    ) : (
                      <input
                        type="text"
                        className={error.primary_cust_email ? "errorField form-control" : "form-control"}
                        name="popup"
                        id="primary_cust_email"
                        defaultValue={customerData?.publisherDetail?.primaryCustEmail}
                        onChange={(e) => handleCustomer(e)}
                        onBlur={(e) => handleCustomer(e)}
                      />
                    )}
                  </div>
                  {error.primary_cust_email ? <a className="errorClass">{error.pimayEmailMsg ? error.pimayEmailMsg : ""}</a> : null}
                </div>

                <div className="ivitecustdiv"></div>
              </div>
              <div className="row">
                <div className="col-md-4 form-group">
                  <div className="companyLabel">Pricing Discount %</div>
                  <div>
                    {!editInfo ? (
                      <input type="text" className="form-control" disabled="disabled" style={{ cursor: "not-allowed" }} value={customerData?.publisherDetail?.channelDiscountPrice} />
                    ) : (
                      <input
                        type="text"
                        className={error.pricing_discount ? "errorField form-control" : "form-control"}
                        name="popup"
                        id="pricing_discount"
                        defaultValue={customerData?.publisherDetail?.channelDiscountPrice}
                        onChange={(e) => handleCustomer(e)}
                        onBlur={(e) => handleCustomer(e)}
                      />
                    )}
                  </div>
                  {error.pricing_discount ? <a className="errorClass">{error.discountPriceMsg ? error.discountPriceMsg : ""}</a> : null}
                </div>
                {/* <div style={{ width: "8%" }}></div> */}
                <div className="col-md-4 form-group">
                  <div className="companyLabel">Monthly #datafree Data Credit Limit (bytes)</div>
                  <div>
                    {!editInfo ? (
                      <input type="text" className="form-control" disabled="disabled" style={{ cursor: "not-allowed" }} value={customerData?.publisherDetail?.accDataCreditLimitId?.creditLimit} />
                    ) : (
                      <select
                        type="text"
                        className="form-control form-select"
                        name="popup"
                        id="data_credit_limit"
                        onChange={(e) => handleCustomer(e)}
                        onBlur={(e) => handleCustomer(e)}
                      >
                        {customerData && customerData?.dataCreditLimit?.length > 0
                          ? customerData.dataCreditLimit.map((post) => (
                            <option key={post.creditLimitId} value={post.creditLimitId} selected={customerData?.publisherDetail?.accDataCreditLimitId?.creditLimitId === post.creditLimitId && true}>
                              {post.creditLimit}
                            </option>
                          ))
                          : null}
                      </select>
                    )}
                  </div>
                </div>
                <div className="ivitecustdiv"></div>
              </div>
              <div className="row">
                <div className="col-md-4 form-group">
                  <div className="companyLabel">Status</div>
                  <div>

                    {!editInfo ? (
                      <input type="text" className="form-control" name="status" id="status" disabled="disabled" style={{ cursor: "not-allowed" }} value={customerData?.publisherDetail?.status} />
                    ) : (
                      <select type="text" className="form-control form-select" name="popup" id="status" onChange={(e) => handleCustomer(e)} onBlur={(e) => handleCustomer(e)}>

                        {customerData?.publisherDetail?.status === "Approved" ? (<>
                          <option value="Approved" selected>Approved</option>
                          <option value="Suspended">Suspended</option>
                          <option value="Archived">Archived</option>

                        </>) :

                          customerData?.publisherDetail?.status === "New" ? (<>
                            <option value="New" selected>New</option>
                            <option value="Approved">Approved</option>
                            <option value="NoAgreement">NoAgreement</option>

                          </>) :
                            customerData?.publisherDetail?.status === "NoAgreement" ? (<>
                              <option value="NoAgreement" selected>NoAgreement</option>
                              <option value="Approved">Approved</option>
                              <option value="Archived">Archived</option>

                            </>) :
                              customerData?.publisherDetail?.status === "Suspended" ? (<>
                                <option value="Suspended" selected>Suspended</option>
                                <option value="Approved">Approved</option>
                                <option value="Archived">Archived</option>

                              </>) :
                                customerData?.publisherDetail?.status === "Archived" ? (<>
                                  <option value="Archived" selected>Archived</option>

                                </>) :
                                  null}
                      </select>
                    )}
                  </div>
                </div>
                {/* <div style={{ width: "8%" }}></div> */}
                <div className="col-md-4 form-group">
                  <div className="companyLabel">Contract Term</div>
                  <div>
                    {!editInfo ? (
                      <input type="text" className="form-control" disabled="disabled" style={{ cursor: "not-allowed" }} value={customerData?.publisherDetail?.contractTerm} />
                    ) : (
                      <select type="text" className="form-control form-select" name="popup" id="contract_term" onChange={(e) => handleCustomer(e)} onBlur={(e) => handleCustomer(e)}>
                        <option value="Ad Hoc" selected={customerData?.publisherDetail?.contractTerm === "Ad Hoc" && true}>
                          Ad Hoc
                        </option>
                        <option value="12 Months" selected={customerData?.publisherDetail?.contractTerm === "12 Months" && true}>12 Months</option>
                        <option value="24 Months" selected={customerData?.publisherDetail?.contractTerm === "24 Months" && true}>24 Months</option>
                        <option value="36 Months" selected={customerData?.publisherDetail?.contractTerm === "36 Months" && true}>36 Months</option>
                        <option value="Special" selected={customerData?.publisherDetail?.contractTerm === "Special" && true}>Special</option>
                      </select>
                    )}
                  </div>
                </div>
                <div className="ivitecustdiv"></div>
              </div>
              <div className="row">
                <div className="col-md-4 form-group">
                  <div className="companyLabel">Company Id</div>
                  <div>
                    <input type="text" className="form-control" name="popup" id="companyId" disabled="disabled" style={{ cursor: "not-allowed" }} value={customerData?.publisherDetail?.companyId} />
                  </div>
                </div>
                {/* <div style={{ width: "8%" }}></div> */}
                <div className="col-md-4 form-group"></div>
                <div className="ivitecustdiv"></div>
              </div>
              <div className="row">
                <div className="col-md-4 form-group">
                  <div className="companyLabel">Company Type</div>
                  <div>
                    <input type="text" className="form-control" name="popup" disabled="disabled" value={customerData?.publisherDetail?.companyType} />
                  </div>
                </div>
                {/* <div style={{ width: "8%" }}></div> */}
                <div className="col-md-4 form-group">
                  <div className="companyLabel">Created</div>
                  <div>
                    <input type="text" className="form-control" name="popup" disabled="disabled"
                      style={{ cursor: "not-allowed" }} value={moment(customerData?.publisherDetail?.created).format("YYYY-MM-DD ") + " GMT"} />
                  </div>
                </div>
                <div className="ivitecustdiv"></div>
              </div>
            </div> : null}

          <div className="ivitecustdiv">
            {editInfo ? (
              <button className="inviteButton" onClick={handleClose} color="primary" id="Cancel">
                Cancel
              </button>
            ) : null}
            {editInfo ? (
              <button className="inviteButton" color="primary" onClick={EditCustomerData} id="Save">
                Save
              </button>
            ) : null}
          </div>
          <div className="ivitecustdiv">
            <button className="inviteButton" onClick={redirectRoute} id="Return">
              Return to Company List
            </button>
          </div>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <div className="ivitediv">
            <button className="inviteButton" onClick={handleClickOpen}>
              + Invite New User
            </button>
          </div>
          {customerData?.usersListDetail?.length >0 ? (
           
            <VMCTable
              key={customerData?.usersListDetail}
              data={customerData?.usersListDetail}
              col={tablecols}
              tabNavigation={OpenUserTab}
              imprNavigation={impersonate}
              tableName="Admin Customer Details View"
            />
          ) : (
            <div>
              <LoadingIndicator />
              {empty ? <EmptyTable data="No Users Found" col={tablecols} tableName="Admin Customer Details View" /> : null}
            </div>
          )}

          <div className="ivitediv">
            <button className="inviteButton" onClick={redirectRoute}>
              Return To Company List
            </button>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          {customerData?.applicationsListDetail?.length > 0 ? (
            <VMCTable key={customerData.applicationsListDetail} data={customerData.applicationsListDetail}
              additionalData1={addData} col={tablecolspublisher}
              additionData2={customerData?.revenueType}
              navigateFunction={editStatus}
              CheckFunction={ChangeHealth}
              changeRevType={editRevType}
              tableName="Admin Customer Details Publisher" />
          ) : (
            <div>
              <LoadingIndicator />
              {empty ? <EmptyTable data="No Applications Found" col={tablecolspublisher} tableName="Admin Customer Details Publisher" /> : null}
            </div>
          )}
          <div className="ivitediv">
            <button className="inviteButton" onClick={redirectRoute}>
              Return To Company List
            </button>
          </div>
        </TabPanel>
      </div>

      {/* ///////////////////////////////////////////// INVITE USER ///////////////////////////////// */}
      <Dialog open={open} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle">
          <div className="FlexRow">
            <div className="cmpdialogtitle"> Invite User - Admin View</div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} />
            </div>
          </div>

        </DialogTitle>
        <DialogContent>

          <div className="dialoginputtext">Name:</div>
          <input type="text" id="name" label="name" className={inviteError.name ? "errorField form-control" : "form-control"} fullWidth onChange={(e) => handleUser(e)} />
          {inviteError.name ? <a className="errorClass">{inviteError.nameerrmsg ? inviteError.nameerrmsg : "This field is required"}</a> : null}
          <div className="dialoginputtext">Email:</div>
          <input
            type="text"
            id="email_address"
            name="email_address"
            label="email"
            className={inviteError.email_address ? "errorField form-control" : "form-control"}
            onChange={(e) => handleUser(e)}
            fullWidth
          />
          {inviteError.email_address ? <a className="errorClass">{inviteError.emailerrmsg ? inviteError.emailerrmsg : "This field is required"}</a> : null}
        </DialogContent>
        <DialogActions>
          {loading ? <div className="loaderContainer">
            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="ivitediv">
            <button className="inviteButton" onClick={handleClose} color="primary">
              Cancel
            </button>
            <button className="inviteButton"
              disabled={clickInvite ? "disabled" : false}
              color="primary" onClick={InviteNew}>
              Invite User
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar open={successopen} autoHideDuration={1500} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
          {toastMsg ? toastMsg : "Updated Successfully!"}
        </Alert>
      </Snackbar>

      <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {resMessage ? resMessage : "An Error Occured!"}
        </Alert>
      </Snackbar>
      {/* //user */}

      {/* /////////////////////////////////////// UPDATE USERDETAILS ////////////////////////// */}
      <Dialog open={userOpen} classes={{ scrollPaper: classes.scrollPaper }} fullWidth scroll={scroll} maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <div className="FlexRow">
            <div className="cmpdialogtitle">User Details - Admin View</div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Company Name</div>
              <input type="text" disabled="disabled" label="email" defaultValue={userDetails ? userDetails.companyName : ""} 
              className="form-control" fullWidth onChange={(e) => EditUser(e)} />
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Company Type</div>
              <input
                type="text"
                id="credit_limit"
                label="credit_limit"
                disabled="disabled"
                defaultValue={userDetails ? userDetails.companyType : ""}
              
                className="form-control"
                fullWidth
                onChange={(e) => EditUser(e)}
              />
            </Grid>

            <Grid item xs={12}>
              <div className="cmpdialoginputtext">User’s Name</div>
              <input
                type="text"
                id="username"
                name="username"
                defaultValue={userDetails ? userDetails.userName : ""}
                onInput={(e) => EditUser(e)}
                //onChange={(e) => EditUser(e)}
                className={userUpdate.username ? "errorField form-control" : "form-control"}
                fullWidth
                ref={userNameRef}
              />
              {userUpdate.username ? <a className="errorClass">{userUpdate.msg ? userUpdate.msg : "This field is required"}</a> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Email Address</div>
              <input
                type="text"
                id="reg_address"
                name="reg_address"
                label="email"
                defaultValue={userDetails ? userDetails.email : ""}
                disabled="disabled"
                onChange={(e) => EditUser(e)}
                className="form-control"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Role</div>
              <select
                type="text"
                id="user_role"
                name="user_role"
                label="email"
                className="form-control form-select"
                fullWidth
                onChange={(e) => EditUser(e)}
              >
                {userDetails?.role == "user" ?
                  <option value="user" selected={userDetails?.role == "user" && true}>user</option>
                  :
                  <option value="user" selected={userDetails?.role == "reseller" && true}>reseller</option>
                }
              </select>
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Type</div>
              <select id="user_type" name="user_type" className="form-control form-select" fullWidth onChange={(e) => EditUser(e)}>
                <option selected={userDetails?.type == "INTERNAL" && true} value="INTERNAL">
                  INTERNAL
                </option>
                <option selected={userDetails?.type == "PUBLISHER" && true} value="PUBLISHER">
                  PUBLISHER
                </option>
              </select>
            </Grid>

            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Verified</div>
              <select type="text" id="user_verified" name="user_verified" label="email" className="form-control form-select" fullWidth onChange={(e) => EditUser(e)}>
                <option selected={userDetails?.verified == 1 && true} value="Yes">
                  YES
                </option>
                <option selected={userDetails?.verified == 0 && true} value="No">
                  NO
                </option>
              </select>
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Status</div>
              <select type="text" id="user_status" name="user_status" label="email" className="form-control form-select" fullWidth onChange={(e) => EditUser(e)}>
                <option selected={userDetails?.status == "Active" && true} value="Active">
                  Active
                </option>
                <option selected={userDetails?.status == "Deleted" && true} value="Deleted">
                  Deleted
                </option>
              </select>
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Created</div>
              <input type="text" label="email" disabled="disabled" className="form-control" value={moment(userDetails ? userDetails.created : "").format("YYYY-MM-DD HH:mm") + " GMT"} fullWidth />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {lodervisible ? <LoadingIndicator /> : null}
          <div className="buttonRow">
            <div>
              <button className="inviteButton" onClick={() => resetPassword(userDetails.email)} color="primary">
                Request Password Reset
              </button>
            </div>
            <div>
              <button className="inviteButton" onClick={handleClose} color="primary">
                Cancel
              </button>

              <button className="inviteButton" color="primary" onClick={EditUserData}>
                Update User Details
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
      {/* ///////////////////////////////Change Owner//////////////////////////////// */}
      <Dialog
        open={changeOwnerOpen}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth

        scroll={scroll}
        maxWidth="md"
        name="popup"
        onClose={handleOwnerClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <div className="NavTopText">Warning !</div>
          <div className="lineBottom"></div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="labelHead">
                You have requested to transfer the ownership of Publisher :<span style={{ fontWeight: "bold" }}>{customerData?.publisherDetail?.name}</span>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="cmpdialoginputtext">The Publisher is currently managed by Channel</div>
              <input
                type="text"
                id="publisher_id"
                name="popup"
                label="publisher_id"
                disabled="disabled"
                className="form-control"
                defaultValue={customerData?.publisherDetail?.parentCompanyId?.name}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <div className="cmpdialoginputtext">You wish to transfer ownership to the Channel</div>
              <select
                type="text"
                id="changed_channel_id"
                name="popup"
                label="changed_channel_id"
                className={errorField.changed_channel_id ? "form-control form-select errorField" : "form-control form-select"}
                onChange={(e) => channelId(e)}
                fullWidth
              >
                <option value=""></option>
                {customerData && customerData?.publisherCompanyName?.length > 0
                  ? customerData.publisherCompanyName.map((post) =>
                    customerData?.publisherDetail?.parentCompanyId.name != post.name ? (
                      <option key={post.companyId} value={post.companyId}>
                        {post.name}
                      </option>
                    ) : (
                      ""
                    )
                  )
                  : null}
              </select>
              {errorField.changed_channel_id ? <a className="errorClass">{errorField.msg}.</a> : null}
            </Grid>
            {transfer ? (
              <>
                <Grid item xs={7}>
                  <div className="cmpdialoginputtext">Please confirm that you wish to proceed with the Ownership Transfer</div>
                </Grid>
                <Grid item xs={5}>
                  <div className="ivitediv">
                    <button className="continueBtn" onClick={cancelTansfer} color="primary" name="popup">
                      Cancel
                    </button>

                    <button className="inviteButton" color="primary" onClick={changeOwner} name="popup">
                      Yes Transfer Ownership
                    </button>
                  </div>
                </Grid>
              </>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          {!transfer ? (
            <div className="ivitediv">
              <button className="inviteButton" onClick={handleOwnerClose} color="primary" name="popup">
                Cancel
              </button>

              <button className="inviteButton" color="primary" onClick={clickSave} name="popup">
                Save
              </button>
            </div>
          ) : null}
        </DialogActions>
      </Dialog>


      <Dialog open={openPopup} id="OpenDialog"
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth maxWidth="sm"
        style={{ minHeight: '50px !important' }}
        aria-labelledby="form-dialog-title">

        <DialogContent className="diaContent">
          <div className="logoConfirmtext">Please Save or Cancel the changes before proceeding</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" name="popup" color="primary">
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog open={invitedSuccess} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Success Message</div>
        </DialogTitle>
        <DialogContent className="diaContent">
          <div className="logoConfirmtext" style={{ fontSize: '17px' }}>Successfully added new user. Invitation link sent to user's E-mail id.</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" style={{ width: '11%' }} name="popup" color="primary" onClick={() => setInvitedSuccess(false)}>
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar open={successRequest} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success">
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default PublisherDetailView;
