import React , { useState, useEffect,useContext } from 'react'
import "../user/sidebarUser.css";
import { MdMenu } from "react-icons/md";
import { SideNavLicensee } from "./sideNavLIcensee";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useHistory } from "react-router-dom";
import datafreelogo from "../../../assets/img/data_free_inner.png";
import datafreeText from "../../../assets/img/datafree_logo_white.png";
import logoutIcon from "../../../assets/img/logout-icon.png";
import AdminService from "../../../api/adminService";
import LicenseeService from "../../../api/licenseeService";
import service from "../../../api/service";
import Style from "../../login/style";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import {
  ToggleDispatchContext
 } from "../../context/toggleContext"
import jwt from "jwt-decode";

const useStyles = makeStyles(Style);
function SidebarLicensee(props) {
  const [userData, setUserData] = useState();
  const classes = useStyles();
  const { sidebarOpen, mobileviewOpen } = props;
  const sidebarCollapsed = localStorage.getItem("sidebar-Collapsed");
  const [isExpanded, setIsExpanded] = useState(sidebarCollapsed ? true : false);
  let history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 990 ?true:false);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [companies, setCompanies] = useState();
  const ToggleDispatchContextVal = useContext(ToggleDispatchContext)
  const handleResize = () => {
    if (window.innerWidth < 949) {
      setIsMobile(true);
      mobileviewOpen(true);
      setToggleOpen(true);
    } else {
      setIsMobile(false);
      mobileviewOpen(false);
      setToggleOpen(false);
    }
  };
  const getData = () => {
    
    try {
      LicenseeService.licenseeVersion().then((res) => {
        setUserData(res.data);
        localStorage?.setItem("licenseeId" ,res?.data?.licenseeId)
      });
    } catch (err) {

    }
  };
  const redirectUser = () => {
    history.push("/licensee/user/details/view");
  }
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false)
  }
  const handleClickOpen = () => {
    setOpen(true);
    
  };
  const getCompanyData =()=>{
    service.ChangeAccount().then((res) => {

      setCompanies(res.data);
    })
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    getData();
    getCompanyData();
  }, []);

  const handleToggler = () => {
    if (isExpanded) {
      setIsExpanded(false);
      sidebarOpen(true);
      localStorage.setItem("sidebar-Collapsed", false);
      ToggleDispatchContextVal({event: false })
      // return;
    } else {
      setIsExpanded(true);
      sidebarOpen(false);
      localStorage.setItem("sidebar-Collapsed", true);
      ToggleDispatchContextVal({event: true })
    }
  };
  const loginUser = (role, Id,cname,ctype) => {
    localStorage.setItem("companyname",cname);
    localStorage.setItem("companytype",ctype);
    localStorage.setItem("companyrole",role);
    localStorage.setItem("companyId",Id);
    service
      .UserLogin({ "role": role, "companyId": Id })
      .then((res) => {
        if (res.status == 200) {
          localStorage.removeItem("token");
          localStorage.setItem("token", res?.data);
          var decode1 = jwt(res?.data);
          localStorage.setItem("role", decode1?.roles[0]);
          if (decode1.roles[0] === "ROLE_USER") {
            history.push("/user/datafree");
            setOpen(false)
            
          } else if (decode1.roles[0] === "ROLE_ADMIN") {
            history.push("/admin/company/index" );
            setOpen(false)
          } else if (decode1.roles[0] === "ROLE_LICENSEE") {
            history.push("/licensee/index/action");
            setOpen(false)
          } else if (decode1.roles[0] === "ROLE_CHANNEL") {
            history.push("/channel/applications/index");
            setOpen(false)
          }
          else if (decode1.roles[0] === "ROLE_ACCOUNTMANAGER") {
            history.push("/accountmanager/applications/index");
            setOpen(false)
          }
        }

      })
  }
  const redirectPath = (path, title) => {
    if (title === "Reporting" || title === "Support" || title === "Datafree Knowledge Base") {
      window.open(path);
      setToggleOpen(window.innerWidth < 990 ? true : false)
      ToggleDispatchContextVal({ event: true })
    }
    else {
      history.push(path);
      setToggleOpen(window.innerWidth < 990 ? true : false)
      ToggleDispatchContextVal({ event: true })
    }
  };

  const getAccordion = () => {
    return (
      <div>
        {SideNavLicensee.map((item, index) => (
          <div key={index}>
            <Accordion
              className="accordionDiv"
              defaultExpanded={true}
            >
              <AccordionSummary
                expandIcon={
                  isExpanded ? (
                    item.subMenu ? (
                      <ExpandMoreIcon className="panela-header" />
                    ) : null
                  ) : null
                }
                aria-controls="panel1a-content"
              >
                <span className="navLinkText">
                  {isExpanded ? item.title : item.collapsedIcon}
                </span>
              </AccordionSummary>
              {item.subMenu
                ? item.subMenu.map((subItem, index) => (
                  <div
                    style={{ textDecoration: "none" }}
                    onClick={() => redirectPath(subItem.path, subItem.title)}
                    key={index}
                  >
                    <AccordionDetails
                      key={index}
                      className={
                        window.location.pathname === subItem.path
                          ? "dataRow"
                          : "accodiondetails"
                      }
                    >
                      <div className="subIcon">
                        {subItem.imageIcon ? (
                          <img
                            src={subItem.imageIcon}
                            alt=""
                            className="imgstyle"
                          ></img>
                        ) : (
                          subItem.icon
                        )}
                      </div>

                      <span className="subMenu" title={subItem.titles}>
                        {isExpanded ? subItem.title : null}
                      </span>
                    </AccordionDetails>
                  </div>
                ))
                : null}
            </Accordion>
          </div>
        ))}
      </div>
    );
  };

  const iconMobile = () => {
    if(toggleOpen){
      setToggleOpen(false)
      ToggleDispatchContextVal({event: false })
    }else{
      setToggleOpen(true);
      ToggleDispatchContextVal({event: true})
    }
  };
  const logout = () => {
    localStorage.removeItem("token");
    history.push("/");
  };
  const routeNotify = () => {
    history.push("/licensee/notification/details");
  }
  const exitImpersonate = () => {
    try {
      AdminService.exitImpersonate().then((res) => {
        if (res.status === 200 && res.statusText === "OK") {
          localStorage.setItem("token", res.data);
          localStorage.removeItem("Impersonation");

          var decode1 = jwt(res.data);

          if (decode1.roles[0] === "ROLE_USER") history.push("/user/datafree");
          else if (decode1.roles[0] === "ROLE_ADMIN")
            history.push("/admin/company/index");
          else if (decode1.roles[0] === "ROLE_LICENSEE")
            history.push("/licensee/index/action");
          else if (decode1.roles[0] === "ROLE_CHANNEL")
            history.push("/channel/publisher/index");
          else if (decode1.roles[0] === "ROLE_ACCOUNTMANAGER")
            history.push("/accountmanager/applications/index");
        } else {
          alert("!please try again");
        }
      });
    } catch (err) {

    }
  };
  return (
    <>
      <div
        className={
          isMobile
            ? "sidebar"
            : isExpanded
            ? "sideBar"
            : "SidebarCollapsed"
        }
      >
        <div className="mainhead">
          {isMobile ? (
            <div className="flexRow">
              <div className="logoFlex">
                <div>
                  <img src={datafreeText} alt="" className="logoText"></img>
                </div>
                <div>
                  <MdMenu onClick={iconMobile} className="hamenuIcon" />
                </div>
              </div>
              <div className="subspanTitle">
                Version : 1.0.170-162-g94ca40bd
              </div>
            </div>
          ) : (
            <div className={isExpanded ? "sidebarHead" : null}>
              {isExpanded ? (
                <div className="sidebarHeadItem">
                  <img src={datafreelogo} alt="" className="imagelogo"></img>
                </div>
              ) : null}
              <div
                className={
                  isExpanded ? "sidebarHeadItem1" : "sidebarHeadItem1Collapsed"
                }
                title="Dashboard"
              >
                <MdMenu onClick={handleToggler} id="sidenavToggler" />
              </div>
            </div>
          )}

          {isExpanded ? (
            !isMobile ? (
              <div className="mainaccordionDiv">
                 <div className="mainNavSubtext">  {isExpanded ? "Datafree Portal" : null}</div>
                <div className={isExpanded ? "mainnavLinkText" : null}>
                  {isExpanded ? "Version :" + userData?.version : null}
                </div>
               
              </div>
            ) : null
          ) : null}
        </div>
        {toggleOpen ? (
          <div></div>
        ) : (
          <div>
           {isExpanded && companies?.length>1 ? <div className="accordionDiv  userNameDiv">
              <div className={isExpanded ? "mainprofileText" : null}
                style={{ fontSize: '14px', fontWeight: '400' }}
              >
                {isExpanded && companies?.length>1 ?
                   <div><div>
                   {localStorage.getItem("companyname")}
                  </div><div>  {localStorage.getItem("companytype")}</div>
                    <div> {localStorage.getItem("companyrole")}</div></div>
                  : null}
              </div>
              <div>  {isExpanded ?
                <button className="btnChange" onClick={handleClickOpen}>
                  Change Account
                </button> : null}</div>
            </div>:""}
           
            {userData?.notification > 0 ?
              <div className="accordionDiv">
                <div className={isExpanded ? "mainprofileText" : null}
                  style={{ fontSize: '14px', fontWeight: '400' }}
                  title="Click here to view account details " onClick={routeNotify}>
                  {isExpanded ? "Notification" : null}
                </div>
              </div> :
              null
            }
            {getAccordion()}
            <div className="accordionDivRow">
              <div className="itemIcon">
                <img src={logoutIcon} alt=""></img>
              </div>

              {localStorage.getItem("Impersonation") ? (
                <div
                  className={isExpanded ? "iconNavText" : null}
                  onClick={exitImpersonate}
                  title="Click here to logout"
                >
                  {isExpanded ? "Exit Impersonation" : null}
                </div>
              ) : (
                <div
                  className={isExpanded ? "iconNavText" : null}
                  onClick={logout}
                  title="Click here to logout"
                >
                  {isExpanded ? "Logout" : null}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Dialog open={open} classes={{ scrollPaper: classes.scrollPaper }}

        fullWidth maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <div className="FlexRow">
            <div className="cmpdialogtitle"> Please choose the Account you wish to Log in to</div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline style={{ cursor: "pointer" }}
                className="modalClose"
                onClick={handleClose} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
            <table className={classes.popupEnter}>
              <thead>
                <tr className={classes.popupRow}>
                  <th className={classes.popupHead}> Company</th>
                  <th className={classes.popupHead}>Type</th>
                  <th className={classes.popupHead}>Your Role</th>
                  <th></th>
                </tr>
              </thead>
              {companies?.length >= 0 ?
                companies?.map((item, index) => (
                  <tbody key={index}>
                    <tr className={classes.popupRow}>
                      <td className={classes.popupTitle}>{item?.company_name}</td>
                      <td className={classes.popupTitle}>{item?.company_type}</td>
                      <td className={classes.popupTitle}>{item?.role}</td>
                      <td><button className={classes.button} onClick={() => loginUser(item?.role, item?.company_id?.companyId ,item?.company_name,item?.company_type)}> Enter</button></td>
                    </tr>

                  </tbody>
                )) : <tbody><tr className={classes.popupRow}><td>No Data found</td></tr></tbody>}
            </table>
          </div>
          <div className="btnRight"><button className="defultbtn btnRight" onClick={handleClose}>
            Close
          </button></div>

        </DialogContent>

      </Dialog>
    </>
  );
}
export default SidebarLicensee;
