import React, { useState, useEffect,useContext } from "react";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Grid,
  withStyles,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "./account.css";
import accountIcon from "../../../../assets/img/account-icon-black.png";
import { trackPromise } from "react-promise-tracker";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import service from "../../../../api/service";
import adminService from "../../../../api/adminService";
import moment from 'moment'
import Loader from "react-loading";
import { IoMdCloseCircleOutline } from "react-icons/io";
import {
  ToggleContext
} from "../../../context/toggleContext"

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const AntTabs = Tabs;

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    borderBottom: "1px solid #ddd ",
    minWidth: "80px",

    textShadow: "none",
    color: "#0059b6",

    "&$selected": {
      color: "#555",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderBottomColor: "transparent",
      borderBottom: "none",
      cursor: "default",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",

    // default center
  },
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Account() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [editInfo, setEditInfo] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [open, setOpen] = useState(false);
  const [accountData, setAccountData] = useState(false);
  const [companyId, setCompanyid] = useState("");
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [lodervisible, setlodervisible] = useState(false);
  const [data, setData] = useState({
    companyname: "",
    monthly_data: "",
    primary_cust_email: "",
    companyid: "",
  });
  const [error, setError] = useState({
    companyname: false,
    monthly_data: false,
    primary_cust_email: false,
    nameMsg: "",
    emailMsg: "",
    monthlyMsg:""
  });
  const [userInfo, setuserInfo] = useState({
    name: "",
    email_address: "",
    companyId: companyId
  });

  const [errorInfo, setErrorInfo] = useState({
    name: false,
    email_address: false,
    mailMsg: "",
  });
  const toggleContextVal = useContext(ToggleContext)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [openPopup, setOpenPopup] = useState(false);

  document.addEventListener("click", e => {

    let editNot = localStorage.getItem("EditOpen");
    if (editNot) {
      if (e.target.id === "Edit" || e.target.id === "credit_control_email" || e.target.id === "root" || e.target.id === "Cancel" || e.target.id === "Save" || e.target.id === "Return" ||
        e.target.type === "file" || e.target.name === "company_name" || e.target.name === "popup" || e.target.id === "status" || e.target.id === "Remove") {

        if (e.target.id === "Cancel" || e.target.id === "Save" || e.target.id === "Return") {
          localStorage.removeItem("EditOpen")

        }
        setOpenPopup(false);
      }
      else {
        setOpenPopup(true);
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();

      }
    }
    else {
      setOpenPopup(false);

    }

  }, true);


  const handleClose = () => {
    setOpen(false);
    setEditInfo(false);
    setError({
      companyname: false,
      monthly_data: false,
      primary_cust_email: false,
      nameMsg: "",
      emailMsg: "",
      monthlyMsg:""
    });



    setuserInfo({
      ...userInfo,
      name: "",
      email_address: ""
    });
    setErrorInfo({
      name: false,
      email_address: false,
      mailMsg: "",
    })

  };

  const edit = () => {
    setEditInfo(true);
    localStorage.setItem("EditOpen", true)
  };
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
  };

  const accountdata = () => {
    setlodervisible(true);
    try {
      trackPromise(
        service.accountData().then((res) => {

          setlodervisible(false);
          setAccountData(res.data);
          setData({
            companyname: res.data?.publisherDetail?.name ? res.data?.publisherDetail?.name : "",
            monthly_data: res.data?.dataUsageAlert ? res.data?.dataUsageAlert : "",
            primary_cust_email: res.data?.publisherDetail?.primaryCustEmail ? res.data?.publisherDetail?.primaryCustEmail : "",
            companyid: res.data?.publisherDetail?.companyId ? res.data?.publisherDetail?.companyId : "",
          })
          setCompanyid(res.data.publisherDetail.companyId);
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  };
  const cmpyReg = /[a-zA-Z]/;
  const ValidateAccount = (data) => {

    let count = 0;
    const nwerror = { ...error }
    if (data.companyname === "") {
      nwerror.companyname = true;
      nwerror.nameMsg = "This field is required."
      count++;
    }
    else {
      if (resVal === false) {
        nwerror.companyname = true;
        nwerror.nameMsg = "This​ Company ​name ​already​ exist"
        count++;
      }
      else if ((data.companyname).includes(",")) {
        nwerror.companyname = true;
        nwerror.nameMsg = "Please enter a name without comma"
        count++;
      }
      else if (cmpyReg.test(data.companyname) === false){
        nwerror.companyname = true;
        nwerror.nameMsg = "Field must have at least 1 character a-z or A-Z"
        count++;
      }
      else {
        nwerror.companyname = false;
        nwerror.nameMsg = ""
      }
    }

    if (data.primary_cust_email === "") {
      nwerror.primary_cust_email = true;
      nwerror.emailMsg = "This field is required."
      count++;
    }
    else {
      if (emailReg.test(data.primary_cust_email) === false) {
        nwerror.primary_cust_email = true;
        nwerror.emailMsg = "Please enter a valid email address."
        count++;
      }
      else {
        nwerror.primary_cust_email = false;
        nwerror.emailMsg = ""
      }
    }

    if (data.monthly_data === "") {
      nwerror.monthly_data = false;
      nwerror.monthlyMsg = ""
    }
    else {
      if (monthlyReg.test(data.monthly_data) === false) {
        nwerror.monthly_data = true;
        nwerror.monthlyMsg = "An Alert Email will be sent to your company contacts if the Monthly Forecast Data Usage exceeds this"
        count++;
      }
      else {
        nwerror.monthly_data = false;
        nwerror.monthlyMsg = ""
      }
    }
    setError(nwerror);
    return count;
  }

  const ValidateUser = (userInfo) => {

    let count = 0;
    const nwerror = { ...errorInfo }
    if (userInfo.email_address === "") {
      nwerror.email_address = true;
      nwerror.mailMsg = "This field is required."
      count++;
    }
    else {
      if (resValue === false) {
        nwerror.email_address = true;
        nwerror.mailMsg = "This​ Email address ​already​ exist"
        count++;
      }
      else if (emailReg.test(userInfo.email_address) === false) {
        nwerror.email_address = true;
        nwerror.mailMsg = "Please enter a valid email address."
        count++;
      }
      else {
        nwerror.email_address = false;
        nwerror.mailMsg = ""
      }
    }


    if (userInfo.name === "") {
      nwerror.name = true;
      count++;
    }
    else {
      nwerror.name = false;
    }
    setErrorInfo(nwerror);
    return count;
  }
  const [clickSave, setClickSave] = useState();
  const [errMsg, setErrMsg] = useState();
  const [toastMsg, setToastMsg] = useState();
  const Editaccountdata = () => {

    const nwcount = ValidateAccount(data);

    if (nwcount === 0) {
      setlodervisible(true);
      setClickSave(true)
      try {

        trackPromise(
          service.EditAccount(data).then((res) => {
            setlodervisible(true);
            setClickSave(false)
            if (res.status === 200) {
              setToastMsg("User Details Updated!!")
              setEditInfo(false);
              setsuccessOpen(true);
            } else {
              setErrMsg("Some error occured");
              seterrorOpen(true);
              setEditInfo(true);
            }

            accountdata();
          })
        );
      } catch (err) {
        setErrMsg("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      }
    }

  };
  const [invitedSuccess, setInvitedSuccess] = useState(false);
  const [clickInvite, setClickInvite] = useState(false);
  const [loading, setLoading] = useState(false);
  const InviteUser = () => {
    const userCount = ValidateUser(userInfo);

    if (userCount === 0) {
      setLoading(true);
      setClickInvite(true);

      try {
        trackPromise(
          service.inviteUser(userInfo).then((res) => {
            if (res.status === 200) {
              setOpen(false);
              handleClose();
              setInvitedSuccess(true);
              setuserInfo({
                ...userInfo,
                name: "",
                email_address: ""
              });
              setLoading(false);
            } else {
              seterrorOpen(true);
              setEditInfo(true);
            }

            setClickInvite(false);
            accountdata();
          })
        );
      } catch (err) {
        setErrMsg("Something went wrong. Please reload the page!");
        seterrorOpen(true);
      }
    }

  };
  const [resVal, setResVal] = useState();
  const [resValue, setResValue] = useState();
  async function CheckCompany(name, id) {
      if (name.includes(",")) {
        setError({
          ...error,
          companyname: true,
          nameMsg: "Please enter a name without comma",
        });
      }
      else if (cmpyReg.test(name) === false){
        setError({
          ...error,
          companyname: true,
          nameMsg: "Field must have at least 1 character a-z or A-Z",
        });
      }
      else {
        try {
        trackPromise(
          adminService.CheckCompany({ companyname: name, companyid: id }).then((res) => {

            setResVal(res.data);
            if (res.data === true) {
              setError({
                ...error,
                companyname: false,
                nameMsg: ""
              })
            }
            else if (name.includes(",")) {
              setError({
                ...error,
                companyname: true,
                nameMsg: "Please enter a name without comma",
              });
            }
            else if (cmpyReg.test(name) === false){
              setError({
                ...error,
                companyname: true,
                nameMsg: "Field must have at least 1 character a-z or A-Z",
              });
            }
            else {
              setError({
                ...error,
                companyname: true,
                nameMsg: "This​ Company ​name ​already​ exist"
              })
            }

          })
        );
        } catch (err) {
          setErrMsg("Something went wrong. Please reload the page!");
          seterrorOpen(true);
        }
      }

    
  }
  async function CheckEmail(email) {
    try {
      trackPromise(
        adminService.CheckEmail({ email_address: email }).then((res) => {

          setResValue(res.data);
          if (res.data === true) {
            setErrorInfo({
              ...errorInfo,
              email_address: false,
              mailMsg: ""
            })
          }

          else {
            setErrorInfo({
              ...errorInfo,
              email_address: true,
              mailMsg: "This​ email address already exists please use admin utilities to check current user access"
            })
          }

        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page!");
      seterrorOpen(true);
    }
  }
  const emailReg = /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/;
  const monthlyReg = /(?!0+$)(^[0-9]+$)|(^[0-9]+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\.[0-9]{1,2})+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\,[0-9]{2,4})+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K))$/i;
  const handleditChange = (e) => {

    const newdata = { ...data };

    newdata[e.target.id] = e.target.value;
    newdata["companyid"] = companyId;
    if (e.target.id === "companyname") {
      if (e.target.value) {
        CheckCompany(e.target.value, companyId)

      }

      else {
        setError({
          ...error,
          companyname: true,
          nameMsg: "This field is required."
        })
      }

    }


    if (e.target.id === "primary_cust_email") {
      if (e.target.value) {
        // CheckEmail(e.target.value)
        if (emailReg.test(e.target.value) === false) {
          setError({
            ...error,
            primary_cust_email: true,
            emailMsg: "Please enter a valid email address."
          })
        }
        else {
          setError({
            ...error,
            primary_cust_email: false,
            emailMsg: ""
          })
        }

      }
      else {
        setError({
          ...error,
          primary_cust_email: true,
          emailMsg: "This field is required."
        })
      }

    }


    if (e.target.id === "monthly_data") {
      if (e.target.value !== "") {
        if (monthlyReg.test(e.target.value) === false) {
          setError({
            ...error,
            monthly_data: true,
            monthlyMsg: "An Alert Email will be sent to your company contacts if the Monthly Forecast Data Usage exceeds this"
          })
        }
        else {
          setError({
            ...error,
            monthly_data: false,
            monthlyMsg: ""
          })
        }

      }
      else {
        setError({
          ...error,
          monthly_data: false,
          monthlyMsg: ""
        })
      }

    }
    setData(newdata);
  };
  const userAdd = (e) => {

    const newuserInfo = { ...userInfo };

    newuserInfo[e.target.id] = e.target.value;
    newuserInfo["companyId"] = companyId;
    if (e.target.id === "email_address") {
      if (e.target.value) {
        CheckEmail(e.target.value);
        if (emailReg.test(e.target.value) === false) {
          setErrorInfo({
            ...errorInfo,
            email_address: true,
            mailMsg: "Please enter a valid email address."
          })
        }
        else {
          setErrorInfo({
            ...errorInfo,
            email_address: false,
            mailMsg: ""
          })
        }
      }
      else {
        setErrorInfo({
          ...errorInfo,
          email_address: true,
          mailMsg: "This field is required."
        })
      }
    }


    if (e.target.id === "name") {
      if (e.target.value) {
        setErrorInfo({
          ...errorInfo,
          name: false,

        })
      }
      else {
        setErrorInfo({
          ...errorInfo,
          name: true,
        })
      }
    }

    setuserInfo(newuserInfo);
  };

  useEffect(() => {
    setlodervisible(true);
    accountdata();
    localStorage.removeItem("EditOpen")
  }, []);


  return (
    <div className="accountContent">
      <div className={toggleContextVal.event ?"accountMenuView" :"accountView"}>
        <div className="accounthead">
          <div>
            <img src={accountIcon} alt=""></img>
          </div>
          <div className="accountToptext">
            Accounts Details - Publisher View
          </div>
        </div>
        <AntTabs
          value={value}
     
          onChange={handleChange}
          className="tabChange tabViewMenu"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          <AntTab
            label="Company"
            title="Click here to view Comapany Tab"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          />
          <AntTab
            label="Users"
            title="Click here to view Users Tab"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          />
          <AntTab
            label="Applications"
            title="Click here to view Applications Tab"
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          />
        </AntTabs>

        <TabPanel value={value} index={0}>
          {
            lodervisible &&
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: '0px',
              right: '0px',
              margin: 'auto',
              position: 'absolute',
              left: '0px',
              bottom: '0px',
              zIndex: '3000',
              backgroundColor: "#ffffff",
              //boxShadow: '0px 0px 5px -1px rgba(0,0,0,0.75)',
              paddingLeft: '2px',
              paddingRight: '2px',
              paddingTop: '8px',
              paddingBottom: '8px',
              borderRadius: '5px',
              flexDirection: 'column',
              width: '60px',
              height: '60px'
            }} >
              <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
            </div>
          }
          {accountData.publisherDetail ? (
            <div className="companyForm">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4} md={4}>
                  <div className="companyLabel">Publisher Name</div>
                  <div>
                    {!editInfo ? (
                      <input
                        type="text"
                        className="inputField"
                        name="Publishername"
                        disabled="disabled"
                        style={{ cursor: 'not-allowed' }}
                        value={accountData.publisherDetail.name}
                      />
                    ) : (
                      <input
                        type="text"
                        className={!error.companyname ? "editinputField" : "editinputFielderr"}
                        onInput={(e) => handleditChange(e)}
                        onBlur={(e) => handleditChange(e)}
                        id="companyname"
                        name="popup"
                        defaultValue={accountData.publisherDetail.name}
                      />

                    )}
                    {error.companyname ? <p className="errorClass">{error.nameMsg}</p> : null}
                  </div>
                </Grid>
                <Grid item xs={12} sm={1} md={1}></Grid>
                <Grid item  xs={12} sm={4} md={4}>
                  <div className="companyLabel">Company ID</div>
                  <div>
                    <input
                      type="text"
                      className="inputField"
                      id="companyid"
                      name="popup"
                      disabled="disabled"
                      style={{ cursor: 'not-allowed' }}
                      value={accountData?.publisherDetail.companyId}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  {!editInfo ? (
                    <div className="ivitediv">
                      <button className="inviteButton" id="Edit" onClick={edit}>
                        Edit Details
                      </button>
                    </div>
                  ) : null}
                </Grid>
                <Grid item  xs={12} sm={4} md={4}>
                  <div className="companyLabel">Customer Identifier</div>
                  <div>
                    <input
                      type="text"
                      className="inputField"
                      name="popup"
                      disabled="disabled"
                      style={{ cursor: 'not-allowed' }}
                      value={accountData?.publisherDetail.channelCustId}

                    />
                  </div>
                </Grid>
                <Grid item   xs={12} sm={1} md={1}></Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <div className="companyLabel">
                    Monthly Account #datafree Usage Alert (bytes)
                  </div>
                  <div>
                    {!editInfo ? (
                      <input
                        type="text"
                        className="inputField"
                        name="monthly_data"
                        disabled="disabled"
                        style={{ cursor: 'not-allowed' }}
                        value={accountData?.dataUsageAlert}
                      />
                    ) : (
                      <input
                        type="text"
                        className={!error.monthly_data ? "editinputField" : "editinputFielderr"}
                        name="popup"
                        id="monthly_data"
                        defaultValue={accountData?.dataUsageAlert}
                        onChange={(e) => handleditChange(e)}
                        onBlur={(e) => handleditChange(e)}
                      />
                    )}
                     {error.monthly_data ? <p className="errorClass">{error.monthlyMsg}</p> : null}
                  </div>
                </Grid>
                <Grid item  xs={12} sm={4} md={4}>
                  <div className="companyLabel">Usage Alert Email</div>
                  <div>
                    {!editInfo ? (
                      <input
                        type="text"
                        className="inputField"
                        name="UsageAlert"
                        disabled="disabled"
                        style={{ cursor: 'not-allowed' }}
                        value={accountData.publisherDetail.primaryCustEmail}
                      />
                    ) : (
                      <input
                        type="text"
                        className={!error.primary_cust_email ? "editinputField" : "editinputFielderr"}
                        name="popup"
                        defaultValue={accountData.publisherDetail.primaryCustEmail}
                        onChange={(e) => handleditChange(e)}
                        onBlur={(e) => handleditChange(e)}
                        id="primary_cust_email"
                      />
                    )}
                    {error.primary_cust_email ? <p className="errorClass">{error.emailMsg}</p> : null}
                  </div>
                </Grid>
                <Grid item   xs={12} sm={1} md={1}></Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <div className="companyLabel">Account Manager Email</div>
                  <div>
                    <input
                      type="text"
                      className="inputField"
                      name="popup"
                      disabled="disabled"
                      style={{ cursor: 'not-allowed' }}
                      value={accountData?.publisherDetail.accMngrEmail}
                    />
                  </div>
                </Grid>
                <Grid item  xs={12} sm={4} md={4}>
                  <div className="companyLabel">Status</div>
                  <div>
                    <input
                      type="text"
                      className="inputField"
                      name="popup"
                      disabled="disabled"
                      style={{ cursor: 'not-allowed' }}
                      value={accountData.publisherDetail.status}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={1} md={1}></Grid>
                <Grid item  xs={12} sm={4} md={4}>
                  <div className="companyLabel">Contract Term</div>
                  <div>
                    <input
                      type="text"
                      className="inputField"
                      name="popup"
                      disabled="disabled"
                      style={{ cursor: 'not-allowed' }}
                      value={accountData.publisherDetail.contractTerm}
                    />
                  </div>
                </Grid>
                <Grid item  xs={12} sm={4} md={4}>
                  <div className="companyLabel">Company Type</div>
                  <div>
                    <input
                      type="text"
                      className="inputField"
                      name="popup"
                      disabled="disabled"
                      style={{ cursor: 'not-allowed' }}
                      value={accountData.publisherDetail.companyType}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={1} md={1}></Grid>
                <Grid item  xs={12} sm={4} md={4}>
                  <div className="companyLabel">Created</div>
                  <div>
                    <input
                      type="text"
                      className="inputField"
                      name="popup"
                      disabled="disabled"
                      style={{ cursor: 'not-allowed' }}
                      value={moment(accountData.publisherDetail.created).format("YYYY-MM-DD") + " GMT"}
                    />
                  </div>
                </Grid>
              </Grid>

              {editInfo ? (
                <div className="ivitediv">
                  <button
                    className="inviteButton"
                    onClick={handleClose}
                    color="primary"
                    id="Cancel"
                  >
                    Cancel
                  </button>
                  <button
                    className="inviteButton"
                    color="primary"
                    id="Save"
                    disabled = {clickSave ? "disabled" : false}
                    onClick={Editaccountdata}
                  >
                    Save
                  </button>
                </div>
              ) : null}
            </div>
          ) : (
            <div></div>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TableContainer>
            <Table className="accouttablestyle" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableHead">User’s Name</TableCell>
                  <TableCell className="tableHead">Email Address</TableCell>
                  <TableCell className="tableHead">Role</TableCell>
                  <TableCell className="tableHead">Type</TableCell>
                  <TableCell className="tableHead">Verified</TableCell>
                  <TableCell className="tableHead">Status</TableCell>
                  <TableCell className="tableHead">Created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountData.usersListDetail &&
                  accountData.usersListDetail.length > 0
                  ? accountData.usersListDetail.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {item?.userIdObj?.name}
                      </TableCell>
                      <TableCell> {item?.userIdObj?.email}</TableCell>
                      <TableCell>{item.role}</TableCell>

                      <TableCell>{item?.userIdObj?.userType}</TableCell>
                      <TableCell>{item?.userIdObj?.verified === 1 ? "Yes" : "No"}</TableCell>
                      <TableCell>{item.status}</TableCell>
                      <TableCell>{moment(item.created).format('Do MMM  YYYY')} GMT</TableCell>
                    </TableRow>
                  ))
                  : <TableRow>
                    No users Found
                  </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="ivitediv">
            <button className="inviteButton" onClick={handleClickOpen}>
              + Invite New
            </button>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TableContainer>
            <Table className="accouttablestyle" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableHead">biNu Id</TableCell>
                  <TableCell className="tableHead">App Name</TableCell>
                  <TableCell className="tableHead">Google App Id</TableCell>
                  <TableCell className="tableHead">Type</TableCell>
                  <TableCell className="tableHead">Created</TableCell>
                  <TableCell className="tableHead">
                    Max Bytes / Day(MB)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountData.applicationsListDetail &&
                  accountData.applicationsListDetail.length > 0
                  ? accountData.applicationsListDetail.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {item.applicationId}
                      </TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.googleAppId}</TableCell>
                      <TableCell>{item.applicationType}</TableCell>
                      <TableCell>{moment(item.created).format('Do MMM  YYYY')} GMT</TableCell>
                      <TableCell>{item.maxBytesPerDay}</TableCell>
                    </TableRow>
                  ))
                  : <TableRow>
                     No applications Found
                    </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </div>
      <Dialog
        open={open}
        classes={{ scrollPaper: classes.scrollPaper }}
        scroll={scroll}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="dialogtitle">
          <div className="FlexRow">
            <div className="cmpdialogtitle">  Invite Publisher User </div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline style={{ cursor: "pointer" }} className="modalClose" onClick={handleClose} />
            </div>
          </div>

        </DialogTitle>
        <DialogContent>
          <div className="dialoginputtext">Name:</div>
          <input
            type="text"
            id="name"
            name="name"
            onChange={(e) => userAdd(e)}
            label="name"
            className={!errorInfo.name ? "dialoginput" : "errdialoginput"}
            fullWidth
          />
          {errorInfo.name ? <a className="errorClass">This field is required</a> : null}
          <div className="dialoginputtext">Email:</div>
          <input
            type="text"
            id="email_address"
            name="email_address"
            onChange={(e) => userAdd(e)}
            label="email"
            className={!errorInfo.email_address ? "dialoginput" : "errdialoginput"}
            fullWidth
          />
          {errorInfo.email_address ? <a className="errorClass">{errorInfo.mailMsg}</a> : null}

        </DialogContent>
        <DialogActions>
          {loading ? <div className="loaderContainer">
            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="ivitediv">
            <button
              className="inviteButton"
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </button>
            <button
              className="inviteButton"
              disabled={clickInvite ? "disabled" : false}
              onClick={InviteUser}
              color="primary"

            >
              Invite User
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog open={openPopup} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }} aria-labelledby="form-dialog-title">

        <DialogContent className="diaContent">
          <div className="logoConfirmtext">Please Save or Cancel the changes before proceeding</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" name="popup" color="primary">
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>


      <Dialog open={invitedSuccess} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Success Message</div>

        </DialogTitle>
        <DialogContent className="diaContent">
          <div className="logoConfirmtext" style={{ fontSize: '17px' }}>Successfully added new user. Invitation link sent to user's E-mail id.</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" style={{ width: '11%' }} name="popup" color="primary" onClick={() => setInvitedSuccess(false)}>
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={successopen}
        autoHideDuration={1500}
        onClose={handlesuccessAlert}
      >
        <Alert onClose={handlesuccessAlert} severity="success">
          {toastMsg ? toastMsg : "Updated Successfully!"}
        </Alert>
      </Snackbar>
      <Snackbar
        open={erroropen}
        autoHideDuration={1500}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {errMsg ? errMsg : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default Account;
