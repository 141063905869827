import React, { useState, useEffect,useContext } from "react";
import { Tabs, Tab, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, withStyles, Grid } from "@material-ui/core";
import jwt from "jwt-decode";
import { trackPromise } from "react-promise-tracker";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Loader from "react-loading";
import { usePromiseTracker } from "react-promise-tracker";
import { useHistory, useParams } from "react-router-dom";
import Service from "../../../api/adminService";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";
import VMCTable from "../../../Recomponent/Table";
import EmptyTable from "../../../Recomponent/EmptyTable";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { MdWarning } from "react-icons/md";
import alpha from "../../../assets/img/blank1.png";
import {
  ToggleContext
} from "../../context/toggleContext"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div>
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AntTabs = Tabs;

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    borderBottom: "1px solid #ddd ",
    minWidth: "80px",

    textShadow: "none",
    color: "#0059b6",

    "&$selected": {
      color: "#555",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderBottomColor: "transparent",
      borderBottom: "none",
      cursor: "default",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
const useStyles = makeStyles((theme) => ({
  scrollPaper: {
    alignItems: "baseline",
  },
}));

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};

function ChannelDetailView(props) {
  let file = null;
  let history = useHistory();
  const [value, setValue] = React.useState(0);
  const [lodervisible, setlodervisible] = useState(false);
  const classes = useStyles();
  const { companyId } = useParams();
  const toggleContextVal = useContext(ToggleContext)
  const [error, setError] = useState({
    company_name: false,
    logo_icon_image: false,
    credit_control_email: false,
    imgValidmsg: "",
    emailMsg: "",
  });
  const [nameCheck, setNameCheck] = useState({
    company_name: "",
    companyid: "",
  });
  const [empty, setEmpty] = useState(false);
  ////CompanyCheck
  const cmpyReg = /[a-zA-Z]/;
  async function companyCheck(cname) {
    nameCheck["company_name"] = cname;
    nameCheck["companyid"] = companyId;
    try {
      trackPromise(
        Service.companyCheck(nameCheck).then((res) => {
          if (res.data === false) {
            setError({
              ...error,
              company_name: true,
              msg: "This​ Company ​name ​already​ exist",
            });
          } else {
            
            if (cname.includes(",")) {
              setError({
                ...error,
                company_name: true,
                msg: "Please enter a name without comma",
              });
            } 
            else if (cmpyReg.test(cname) === false){
              setError({
                ...error,
                company_name: true,
                msg: "Field must have at least 1 character a-z or A-Z",
              });
            }
            else {
              setError({
                ...error,
                company_name: false,
              });
            }
          }
        })
      );
    } catch (err) {

    }
  }

  // let companyId = props.location.state;
  const [scroll, setScroll] = useState("paper");
  const [userOpen, setUserOpen] = useState(false);
  const [editInfo, setEditInfo] = useState(false);
  const [userDetails, setuserDetails] = useState();
  const [userid, setUserId] = useState({
    userId: "",
  });
  const [successRequest, setsuccessRequest] = useState(false);
  const [inviteUser, setinviteUser] = useState(false);
  const [channelData, setChannelData] = useState();
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [user_id, setUser_id] = useState();
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [editdata, seteditdata] = useState({
    company_name: "",
    logo_icon_image: "",
    credit_control_email: "",
    status: "Approved",
    company_type: "",
    company_id: "",
    removeCompanyLogo: "",
  });
  const [invite, setInvite] = useState({
    name: "",
    email_address: "",
    user_role: "",
    companyId: "",
  });

  const [tablecols, setTableCols] = useState([
    {
      colName: "User’s Name",
      colMap: "name",
      linkName: true,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: true,
      userTab: true,
    },
    {
      colName: "Email Address",
      colMap: "email",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: true,
      userTab: false,
    },
    {
      colName: "Role",
      colMap: "role",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: true,
      userTab: false,
    },
    {
      colName: "Type",
      colMap: "userType",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: true,
      userTab: false,
    },
    {
      colName: "Verified",
      colMap: "verified",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: true,
      userTab: false,
    },
    {
      colName: "Status",
      colMap: "status",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: true,
      userTab: false,
    },
    {
      colName: "Created",
      colMap: "created",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: true,
      imprRoute: true,
      userTab: false,
    },
    {
      colName: "Impersonate",
      colMap: "Impersonate",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: true,
      userTab: false,
    },
  ]);

  const [pubTablecols, setPubTablecols] = useState([
    {
      colName: "Publisher Id",
      colMap: "companyId",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: true,
      userTab: true,
    },
    {
      colName: "Publisher Name",
      colMap: "name",
      linkName: true,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false,
    },
    {
      colName: "Company Type",
      colMap: "companyType",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: true,
      userTab: true,
    },
    {
      colName: "Account Level",
      colMap: "accountLevel",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: true,
      userTab: true,
    },
    {
      colName: "#datafree Data Credit Limit",
      colMap: "datafreeDataCreditLimit",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: true,
      userTab: true,
    },
    {
      colName: "Account #datafree Usage Alert",
      colMap: "pubDataUsageAlert",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: true,
      userTab: true,
    },
    {
      colName: "Status",
      colMap: "status",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: true,
      userTab: true,
    },
    {
      colName: "Created",
      colMap: "created",
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: true,
      imprRoute: true,
      userTab: true,
    },
  ]);
  const [message, setMessage] = useState();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let API_URL = process.env.REACT_APP_API_URL;
  const handleClose = () => {
    setEditInfo(false);
    setUserOpen(false);
    setinviteUser(false);
    setRemoveDialogue(false);
    setImgRemoved(false);
    setInvite({
      name: "",
      email_address: "",
      user_role: "",
      companyId: "",
    });
    setError({
      company_name: false,
      logo_icon_image: false,
      credit_control_email: false,
      imgValidmsg: "",
      emailMsg: "",
    })
    setInviteError({
      name: false,
      email_address: false,
      user_role: false,
      companyId: false,
      errmsg: "",
    });
    setUserNameError({
      username: false
    })
    setError({
      company_name: false,
      logo_icon_image: false,
      credit_control_email: false,
      imgValidmsg: "",
      emailMsg: "",
    });
  };

  //  if(document.getElementById('button').clicked == true)
  //  {
  //     alert("button was clicked");
  //  }


  const edit = () => {
    setEditInfo(true);
    localStorage.setItem("EditOpen", true);
  };
  const inviteNew = () => {
    setinviteUser(true);
    setScroll('body');
  };
  const [removeDialogue, setRemoveDialogue] = useState(false);
  const [ImgRemoved, setImgRemoved] = useState(false);
  const removeLogo = () => {

    setImgRemoved(true);

    setRemoveDialogue(false);

    seteditdata({
      company_name: channelData.publisherDetail.name,
      logo_icon_image: null,
      credit_control_email: channelData.publisherDetail.credit_control_email,
      status: channelData.publisherDetail.status,
      company_type: channelData.publisherDetail.companyType,
      company_id: channelData.publisherDetail.companyId,
      removeCompanyLogo: "yes",
    });

    setImage({ preview: "", raw: "" });
    localStorage.removeItem("EditOpen")
  };
  const DialogClose = () => {

    setImgRemoved(false);
    setRemoveDialogue(false);
  }
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
    setsuccessRequest(false);
  };
  const handleAlertClose = () => {
    seterrorOpen(false);
    setsuccessRequest(false);

  };
  const licenseeDetail = () => {
    setlodervisible(true);
    setChannelData({});
    setEmpty(false);
    trackPromise(
      Service.channeldetail(companyId)
        .then((res) => {

          setlodervisible(false);
          setChannelData(res.data);
          seteditdata({
            company_name: res.data.publisherDetail.name,
            logo_icon_image: res.data.publisherDetail.logoFile,
            credit_control_email: res.data.publisherDetail.creditControlEmail,
            status: res.data.publisherDetail.status,
            company_type: res.data.publisherDetail.companyType,
            company_id: res.data.publisherDetail.companyId,
            removeCompanyLogo: "",
          });

          if ((res.status == 200 && res.data.usersListDetail.length === 0) || res.data.publisherListResult.length === 0) {
            setEmpty(true);
          } else {
            setEmpty(false);
          }
        })
        .catch((err) => {

        })
    );
  }

  //passwordReset
  async function resetPassword(EMAIL) {

    try {
      trackPromise(
        Service.passWordReset({ email: EMAIL, admin: "admin" }).then((res) => {
          if (res.status === 200) {
            setsuccessRequest(true);
            setMessage(res.data.message);
            handleClose();
          }
        })
      );
    } catch (err) {

    }
  }
  const [errMessage, setErrMessage] = useState();
  //edit Image
  async function EditImageData() {

    if (error.company_name) {
      setError({
        ...error,
        company_name: true,
      });
    }
    else if (error.logo_icon_image) {
      setError({
        ...error,
        logo_icon_image: true,
      });
    }
    else if (error.credit_control_email) {
      setError({
        ...error,
        credit_control_email: true,
      });
    }
    else {
      editdata["company_id"] = companyId;
      let file = document.getElementById("logo_icon_image").files[0];


      if (error.logo_icon_image === false) {
        let values = file != undefined ? new FormData() : "";
        if (file != undefined) {
          values.append("logo_icon_image", file ? file : "");
        }

        if (values.toString().trim().length > 0) {

          Service.editImage(values).then((res) => {

            if (res.status === 200) {

              editdata["logo_icon_image"] = res.data;
              EditChannelData(editdata);
            }
            //
          }).catch((err) => {
            if (err?.response?.status === 500)
              setErrMessage(err?.response?.data?.data);
            seterrorOpen(true);

          })

        } else {
          EditChannelData(editdata);

        }

      }
    }
  }

  async function EditChannelData() {
    if (error.company_name) {
      setError({
        ...error,
        company_name: true,
      });
    }
    else if (error.logo_icon_image) {
      setError({
        ...error,
        logo_icon_image: true,
      });
    }
    else if (error.credit_control_email) {
      setError({
        ...error,
        credit_control_email: true,
      });
    }
    else {

      Service.editChannel(editdata).then((res) => {
        if (res.status === 200) {
          setlodervisible(false);
          setToastMsg("Channel Details Edited Successfully!");
          setEditInfo(false);
          setsuccessOpen(true);
        } else {
          seterrorOpen(true);
          setEditInfo(true);
        }

        licenseeDetail();
      }).catch((err) => {
        if (err?.response?.status === 500)
          setlodervisible(false);
        setErrMessage(err?.response?.data?.data);
        seterrorOpen(true);
      })



    }
  }
  const [invitedSuccess, setInvitedSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clickInvite, setClickInvite] = useState(false);
  const [toastMsg, setToastMsg] = useState();
  //invite New
  async function InviteNew() {
    if (invite.name.length == 0 && invite.email_address.length == 0 && invite.user_role.length == 0) {
      setInviteError({
        name: true,
        email_address: true,
        user_role: true,
      });
    } else if (inviteError.name) {
      setInviteError({
        ...inviteError,
        name: true,
      });
    } else if (inviteError.email_address) {
      setInviteError({
        ...inviteError,
        email_address: true,
      });
    } else if (inviteError.user_role) {
      setInviteError({
        ...inviteError,
        user_role: true,
      });
    } else {
      setLoading(true);
      setClickInvite(true);
      try {
        trackPromise(
          Service.invitechannelUser(invite).then((res) => {

            if (res.status === 200) {
              //setEditInfo(false);
              handleClose();
              setinviteUser(false);
              setInvitedSuccess(true)
              setLoading(false);
              //setsuccessOpen(true);
            } else {
              seterrorOpen(true);
              setEditInfo(true);
              setinviteUser(true);
            }
            setClickInvite(false);
            licenseeDetail();
          })
        );
      } catch (err) {
        setErrMessage("Something went wrong. Please reload the page.");
        seterrorOpen(true);
      }
    }
  }

  ////Check Email
  async function EmailCheck(value) {
    trackPromise(
      Service.EmailCheck({ email_address: value })
        .then((res) => {
          if (res.data === false) {
            setInviteError({
              ...inviteError,
              email_address: true,
              emailErrMsg: "This​ email address already exists please use admin utilities to check current user access",
            });
          } else if (value && reg.test(value) === false) {
            setInviteError({
              ...inviteError,
              email_address: true,
              emailErrMsg: "Please enter a valid email address.",
            });
          } else {
            setInviteError({
              ...inviteError,
              email_address: false,
            });
          }
        })
        .catch((error) => {

        })
    );
  }

  const [emailId, setEmailId] = useState({
    email: "",
    role: "",
    companyId: ""
  });
  const impersonate = (emailid, role, Id, type, name) => {
    emailId["email"] = emailid;
    emailId["role"] = role;
    emailId["companyId"] = Id
    try {
      trackPromise(
        Service.impersonateUser(emailId).then((res) => {

          if (res.status === 200 && res.statusText === "OK") {
            localStorage.setItem("token", res.data);
            localStorage.setItem("Impersonation", true);
            localStorage.setItem("companyname", name)
            localStorage.setItem("companytype", type)
            localStorage.setItem("companyrole", role)
            var decode1 = jwt(res.data);

            if (decode1.roles[0] === "ROLE_USER") props.history.push("/user/datafree");
            else if (decode1.roles[0] === "ROLE_RESELLER") props.history.push("/user/datafree");
            else if (decode1.roles[0] === "ROLE_CUSTOMER") props.history.push("/user/datafree");
            // else if (decode1.roles[0] === "ROLE_ADMIN")
            //   props.history.push("/admin/company/index");
            else if (decode1.roles[0] === "ROLE_LICENSEE") props.history.push("/licensee/index/action");
            else if (decode1.roles[0] === "ROLE_CHANNEL") props.history.push("/channel/publisher/index");
            else if (decode1.roles[0] === "ROLE_ACCOUNTMANAGER") props.history.push("/accountmanager/applications/index");

          } else {
            alert("!please try again");
          }
        })
      );
    } catch (err) {

    }
  };
  const emailreg = /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/;
  const dataChange = (e) => {
    e.preventDefault();
    const newdata = { ...editdata };
    newdata[e.target.id] = e.target.value;
    if (e.target.id === "logo_icon_image") {
      if (e.target.files.length !== 0) {

        let img = new Image();
        const _URL = (window.URL || window.webkitURL);
        img.src = _URL.createObjectURL(e.target.files[0]);
        var filePath = e.target.files[0].name;
        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        if (!allowedExtensions.exec(filePath)) {
          setImage({
            preview: "",
            raw: "",
          });
          setError({
            ...error,
            logo_icon_image: true,
            imgValidmsg: "Selected file is not an image",
          });
        }
        else {
          img.onload = () => {
            if (img.width < 180) {
              setImage({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                logo_icon_image: true,
                imgValidmsg: "Minimum width must be 180px",
              });
            }
            else if (img.height < 65) {
              setImage({
                preview: "",
                raw: "",
              });
              setError({
                ...error,
                logo_icon_image: true,
                imgValidmsg: "Minimum height must be 65px",
              });
            }
            else {
              setImage({
                preview: _URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
              });
              setError({
                ...error,
                logo_icon_image: false,
                imgValidmsg: "",
              });
            }
          };
        }

      }
    }
    if (e.target.id === "company_name") {
      if (e.target.value) {
        companyCheck(e.target.value);

      } else {
        setError({
          ...error,
          company_name: true,
          msg: "This field is required",
        });
      }
    }

    if (e.target.id === "credit_control_email") {
      if (e.target.value) {
        if (emailreg.test(e.target.value) === false) {
          setError({
            ...error,
            credit_control_email: true,
            emailMsg: "Please enter a valid email address.",
          });
        } else {
          setError({
            ...error,
            credit_control_email: false,
          });
        }
      }
      else {
        setError({
          ...error,
          credit_control_email: false,
        });
      }
    }
    seteditdata(newdata);
  };

  const [inviteError, setInviteError] = useState({
    name: false,
    email_address: false,
    user_role: false,
    companyId: false,

    nameErMsg: "",
    emailErrMsg: "",
    roleErrMsg: "",
  });
  const [userdata, setUserdata] = useState({
    username: "",
    user_role: "User",
    user_type: "Internal",
    user_verified: "No",
    user_status: "Active",
    user_id: "",
    company_id: "",
  });
  const reg = /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/;

  const handleUser = (e) => {
    e.preventDefault();
    const invitedata = { ...invite };
    invitedata[e.target.id] = e.target.value;
    invitedata["companyId"] = companyId;
    if (e.target.id === "name") {
      setInviteError({
        ...inviteError,
        name: false,
      });
    }
    if (e.target.id === "email_address") {
      if (e.target.value) {
        EmailCheck(e.target.value);
      } else {
        setInviteError({
          ...inviteError,
          email_address: true,
          emailErrMsg: "This field is required",
        });
      }
    }

    if (e.target.id === "user_role") {
      setInviteError({
        ...inviteError,
        user_role: false,
      });
    }
    setInvite(invitedata);
  };

  const redirectRoute = () => {
    history.push("/admin/company/index");
  };
  const publisherRoute = (companyType, companyId) => {
    if (companyType === "CUSTOMER" || "RESLR CLNT") {
      history.push(`/admin/customer/${companyId}/detail/view`);
    }
    if (companyType === "CHANNEL") {
      history.push(`/admin/Channel/${companyId}/detail/view`);
    }
    if (companyType === "LICENSEE") {
      history.push(`/admin/licensee/${companyId}/detail/view`);
    }
  };
  //getUser
  async function getUserDetail(value, Id) {

    setuserDetails({});
    setUser_id({});
    try {
      trackPromise(
        Service.getUserById({ userId: value, companyId: Id }).then((res) => {
          setuserDetails(res.data);
          setUserdata({
            username: res.data.userName,
            user_role: res.data.role,
            user_type: res.data.type,
            user_verified: res.data.verified && res.data.verified == 1 ? "Yes" : "No",
            user_status: res.data.status,
            user_id: res.data.userId,
            company_id: res.data.companyId,
          });
          setUser_id(res.data.userId);
        })
      );
    } catch (err) {

    }
  }
  const [userNameError, setUserNameError] = useState({
    username: false,
    msg: "",
  });
  //editUser Detail
  async function EditUserData() {
    setlodervisible(true);
    if (userNameError.username) {
      setUserNameError({
        ...userNameError,
        username: true,
      });
    }
    else {
      trackPromise(
        Service.editUser(userdata).then((res) => {
          setlodervisible(false);
          if (res.status === 200) {
            setEditInfo(false);
            licenseeDetail();
            setUserOpen(false);
            setsuccessOpen(true);
          } else {
            seterrorOpen(true);

            setUserOpen(true);
            setEditInfo(true);
          }

        }).catch((error) => {
          setErrMessage("Something went wrong. Please reload the page!");
          seterrorOpen(true);

        })
      );

    }


  }

  const OpenUserTab = (scrollType, Id, cId) => {
    userid["userId"] = Id;
    setUserId(userid);

    getUserDetail(Id, cId);
    setUserOpen(true);
    setScroll(scrollType);
  };
  const EditUser = (e) => {
    e.preventDefault();
    const newdata = { ...userdata };
    newdata[e.target.id] = e.target.value;
    newdata["user_id"] = user_id;
    newdata["company_id"] = companyId;
    if (e.target.id === "username") {
      if (e.target.value === "") {
        setUserNameError({
          ...userNameError,
          username: true,
          msg: "This field is required",
        });
      } else {
        setUserNameError({
          ...userNameError,
          username: false,
        });
      }
    }
    setUserdata(newdata);
  };




  const [openPopup, setOpenPopup] = useState(false);

  document.addEventListener("click", e => {
    let editNot = localStorage.getItem("EditOpen");
    if(editNot){
      if(e.target.id === "Edit" || e.target.id === "credit_control_email" || e.target.id === "Cancel" || e.target.id === "Save" || e.target.name === "logo_icon_image" || e.target.id === "logo_icon_image"  || 
      e.target.type === "file" || e.target.name === "company_name"|| e.target.name === "popup"|| e.target.id === "status" || e.target.id === "Remove"){

        if (e.target.id === "Cancel" || e.target.id === "Save") {
          localStorage.removeItem("EditOpen")

        }
        setOpenPopup(false);
      }
      else {
        setOpenPopup(true);
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();

      }
    }
    else {
      setOpenPopup(false);
    }

  }, true);


  useEffect(() => {
    licenseeDetail();
    setlodervisible(true);
    localStorage.removeItem("EditOpen");
  }, []);

  return (
    <div className="accountContent">
      <div className={toggleContextVal.event ?"accountMenuView" :"accountView"}>
        <div className="accounthead">
          <div className="accountNavtext">Company Details - Admin View</div>
        </div>
        <AntTabs
          value={value}
          onChange={handleChange}
          className="tabViewMenu"
          aria-label="ant example"
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          <AntTab label="Company" title="Click here to view Company Tab" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
          <AntTab label="Users" title="Click here to view Users Tab" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
          <AntTab label="Publishers" title="Click here to view Publishers Tab" style={{ paddingLeft: "10px", paddingRight: "10px" }} />
        </AntTabs>
        <Typography className={classes.padding} />
        {channelData ? (
          <div>
            <TabPanel value={value} index={0} >
              {
                lodervisible &&
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  top: '0px',
                  right: '0px',
                  margin: 'auto',
                  position: 'absolute',
                  left: '0px',
                  bottom: '0px',
                  zIndex: '3000',
                  backgroundColor: "#ffffff",
                 
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  borderRadius: '5px',
                  flexDirection: 'column',
                  width: '70px',
                  height: '60px'
                }} >
                  <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />

                </div>
              }
              {channelData?.publisherDetail ? 
              <Grid container spacing={2}>
                <Grid item sm={4} md={4} xs={12}>
                  <div className="companyLabel">Company Name</div>

                  <div>
                    {!editInfo ? (
                      <input type="text" className="form-control" name="company_name" disabled="disabled"
                        style={{ cursor: "not-allowed" }} value={channelData?.publisherDetail?.name} />
                    ) : (
                      <input type="text" className={error.company_name ? "form-control errorField" : "form-control"}
                        name="company_name" id="company_name" onChange={(e) => dataChange(e)} onBlur={(e) => dataChange(e)} />
                    )}
                  </div>
                  {error.company_name ? <a className="errorClass">{error.msg ? error.msg : "This field is required"}</a> : null}
                </Grid>
                <Grid item sm={1} md={1} xs={12}></Grid>
                <Grid item  sm={5} md={5} xs={12}>
                  {!editInfo ? <div className="companyLabel">Company Logo</div> : ""}


                  {editInfo || channelData?.publisherDetail?.logoFile !== null ?
                    <div className="fileIcon">
                      <img src={
                        image.preview
                          ? image.preview :
                          !ImgRemoved && channelData?.publisherDetail.logoFile !== null ?
                            API_URL + channelData?.logoFile + channelData?.publisherDetail.logoFile
                            : alpha}
                        onError={(event) => event.target.src = alpha}
                        alt=""
                        className="imgStyle"
                      ></img>
                    </div> : ""}

                  {editInfo ? (
                    <div className="imgFileRow">
                      <div className="companyLabel">Upload Company Logo </div>
                      <div className="filesubText">(Min. dimensions of 180px X 65px)</div>
                      <input id="logo_icon_image" type="file" name="logo_icon_image" onChange={(e) => dataChange(e)} />
                      <label htmlFor="file">{file}</label>
                      {error.logo_icon_image ? <a className="errorClass">{error.imgValidmsg ? error.imgValidmsg : ""}</a> : null}

                    </div>
                  ) : null}
                </Grid>
                <Grid item  sm={2} md={2} xs={12}>
                  {!editInfo ? (
                    <button className="inviteButton" id="Edit" title="Click here to edit publisher details" onClick={edit}>
                      Edit Details
                    </button>
                  ) : null}
                </Grid>

                {editInfo && !ImgRemoved && channelData?.publisherDetail?.logoFile ? (
                  <Grid item  sm={12} md={12} xs={12}>
                    <div className="ivitediv">
                      <button className="changeOwnButton spaceTop" id="Remove" onClick={() => setRemoveDialogue(true)}>
                        Remove Company Logo
                      </button>
                    </div>
                  </Grid>
                ) : null}
                <Grid item  sm={4} md={4} xs={12}>
                  <div className="companyLabel">Licensee Owner</div>
                  <div>
                    <input type="text" className="form-control" name="CustomerIdentifier" style={{ cursor: "not-allowed" }}
                      disabled="disabled" value={channelData?.publisherDetail?.parentCompanyId?.name} />
                  </div>
                </Grid>
                <Grid item  sm={1} md={1} xs={12}></Grid>
                <Grid item sm={4} md={4} xs={12}>
                  <div className="companyLabel">Credit Controller Email</div>
                  <div>
                    {!editInfo ? (
                      <input type="text" className="form-control" name="credit_control_email" id="credit_control_email" style={{ cursor: "not-allowed" }} disabled="disabled" value={channelData?.publisherDetail?.creditControlEmail} />
                    ) : (
                      <input type="text"
                        className={error.credit_control_email ? "errorField form-control" : "form-control"}
                        name="credit_control_email" id="credit_control_email" onChange={(e) => dataChange(e)} onBlur={(e) => dataChange(e)} />
                    )}
                    {error.credit_control_email ? <a className="errorClass">{error.emailMsg ? error.emailMsg : ""}</a> : null}

                  </div>
                </Grid>
                <Grid item  sm={3} md={3} xs={12}>
                  {" "}
                </Grid>
                <Grid item  sm={4} md={4} xs={12}>
                  {" "}
                  <div className="companyLabel">Company Id</div>
                  <div>
                    <input type="text" className="form-control" name="company_id" id="company_id"
                      style={{ cursor: "not-allowed" }} disabled="disabled" value={channelData?.publisherDetail?.companyId} />
                  </div>
                </Grid>
                <Grid item  sm={1} md={1} xs={12}></Grid>
                <Grid item  sm={4} md={4} xs={12}>
                  <div className="companyLabel">Status</div>
                  <div>
                    {!editInfo ? (
                      <input type="text" className="form-control" name="status" id="status" disabled="disabled" style={{ cursor: "not-allowed" }} value={channelData?.publisherDetail?.status} />
                    ) : (
                      <select type="text" className="form-control form-select" name="status" id="status" onChange={(e) => dataChange(e)}
                        onBlur={(e) => dataChange(e)}>

                        {channelData?.publisherDetail?.status === "Approved" ? (<>
                          <option value="Approved" selected>Approved</option>
                          <option value="Suspended">Suspended</option>
                          <option value="Archived">Archived</option>

                        </>) :

                          channelData?.publisherDetail?.status === "New" ? (<>
                            <option value="New" selected>New</option>
                            <option value="Approved">Approved</option>
                            <option value="NoAgreement">NoAgreement</option>

                          </>) :
                            channelData?.publisherDetail?.status === "NoAgreement" ? (<>
                              <option value="NoAgreement" selected>NoAgreement</option>
                              <option value="Approved">Approved</option>
                              <option value="Archived">Archived</option>

                            </>) :
                              channelData?.publisherDetail?.status === "Suspended" ? (<>
                                <option value="Suspended" selected>Suspended</option>
                                <option value="Approved">Approved</option>
                                <option value="Archived">Archived</option>

                              </>) :
                                channelData?.publisherDetail?.status === "Archived" ? (<>
                                  <option value="Archived" selected>Archived</option>

                                </>) :
                                  null}
                      </select>
                    )}
                  </div>
                </Grid>
                <Grid item  sm={3} md={3} xs={12}>
                  {" "}
                </Grid>
                <Grid item  sm={4} md={4} xs={12}>
                  <div className="companyLabel">Company Type</div>
                  <div>
                    <input type="text" className="form-control" name="company_type" id="company_type" disabled="disabled"
                      style={{ cursor: "not-allowed" }} value={channelData?.publisherDetail?.companyType} />
                  </div>
                </Grid>
                <Grid item  sm={1} md={1} xs={12}>
                  {" "}
                </Grid>
                <Grid item  sm={4} md={4} xs={12}>
                  <div className="companyLabel">Created</div>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      name="MonthlyAccount"
                      disabled="disabled"
                      style={{ cursor: "not-allowed" }}
                      value={moment(channelData?.currentUserDetail?.created).format("Do MMM YYYY") + " GMT"}
                    />
                  </div>
                </Grid>
                <Grid item  sm={3} md={3} xs={12}>
                  {" "}
                </Grid>
              </Grid> : null}

              <div className="ivitediv">

                {editInfo ? (
                  <button className="inviteButton" onClick={handleClose} id="Cancel" color="primary">
                    Cancel
                  </button>
                ) : null}
                {editInfo ? (
                  <button className="inviteButton" color="primary" id="Save" onClick={EditImageData}>
                    Save
                  </button>
                ) : null}
              </div>
              <div className="ivitediv">
                <button className="inviteButton" id="Return" onClick={redirectRoute}>
                  Return to Company List
                </button>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>

              <div className="ivitediv">
                <button className="inviteButton" onClick={inviteNew}>
                  + Invite New Users
                </button>
              </div>

              {channelData?.usersListDetail?.length > 0 ? (
                <VMCTable data={channelData.usersListDetail} col={tablecols} tabNavigation={OpenUserTab} imprNavigation={impersonate} tableName="Company  Details Users" />
              ) : (
                <div>
                  <LoadingIndicator />
                  {empty ? <EmptyTable data="No Users Found" col={tablecols} tableName="Company  Details Users" /> : null}
                </div>
              )}
              <div className="ivitediv">
                <button className="inviteButton" onClick={redirectRoute}>
                  Return To Company List
                </button>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              {/** Publisher Data table */}
              {channelData?.publisherListResult?.length > 0 ? (
                <VMCTable
                  data={channelData.publisherListResult}
                  col={pubTablecols}
                  navigateFunction={publisherRoute}
                  //tabNavigation={OpenUserTab}
                  imprNavigation={impersonate}
                  tableName="Company  Details Users"
                />
              ) : (
                <div>
                  <LoadingIndicator />
                  {empty ? <EmptyTable data="No Publishers Found" col={pubTablecols} tableName="Company  Details Users" /> : null}
                </div>
              )}
              <div className="ivitediv">
                <button className="inviteButton" onClick={redirectRoute}>Return To Company List</button>
              </div>
            </TabPanel>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* //UserEDit */}
      <Dialog open={userOpen} classes={{ scrollPaper: classes.scrollPaper }} fullWidth scroll={scroll}
        maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <div className="FlexRow">
            <div className="cmpdialogtitle">User Details - Admin View</div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Company Name</div>
              <input type="text" disabled="disabled" label="email" value={userDetails ? userDetails.companyName : ""} 
              className="form-control" fullWidth onChange={(e) => EditUser(e)} />
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Company Type</div>
              <input
                type="text"
                id="credit_limit"
                label="credit_limit"
                disabled="disabled"
                value={userDetails ? userDetails.companyType : ""}

                className="form-control"
                fullWidth
                onChange={(e) => EditUser(e)}
              />
            </Grid>

            <Grid item xs={12}>
              <div className="cmpdialoginputtext">User’s Name</div>
              <input type="text" id="username" name="username" defaultValue={userDetails ? userDetails.userName : ""} onInput={(e) => EditUser(e)}

                className={userNameError.username ? "errorField form-control" : "form-control"} fullWidth />
              {userNameError.username ? <a className="errorClass">{userNameError.msg ? userNameError.msg : "This field required"}</a> : null}
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Email Address</div>
              <input
                type="text"
                id="reg_address"
                name="reg_address"
                label="email"
                value={userDetails ? userDetails.email : ""}
                disabled="disabled"
                onChange={(e) => EditUser(e)}
                className="form-control"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Role</div>
              <select
                type="text"
                id="user_role"
                name="user_role"
                label="email"
                className="form-control form-select"
                fullWidth
                onChange={(e) => EditUser(e)}
              >
                <option value="channel" selected={userDetails?.role == "channel" && true}>channel</option>
                <option value="account manager" selected={userDetails?.role == "account manager" && true}>account manager</option>
              </select>
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Type</div>
              <select
                type="text"
                id="user_type"
                name="user_type"
                label="email"
                className="form-control form-select"
                fullWidth
                onChange={(e) => EditUser(e)}
              >
                <option selected={userDetails?.type == "INTERNAL" && true} value="INTERNAL">
                  INTERNAL
                </option>
                <option selected={userDetails?.type == "PUBLISHER" && true} value="PUBLISHER">
                  PUBLISHER
                </option>
              </select>
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Verified</div>
              <select
                type="text"
                id="user_verified"
                name="user_verified"
                label="email"
                defaultValue={userDetails ? userDetails.verified : ""}
                className="form-control form-select"
                fullWidth
                onChange={(e) => EditUser(e)}
              >
                <option selected={userDetails?.verified == 1 && true} value="Yes">
                  YES
                </option>
                <option selected={userDetails?.verified == 0 && true} value="No">
                  NO
                </option>
              </select>
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Status</div>
              <select
                type="text"
                id="user_status"
                name="user_status"
                label="email"
                className="form-control form-select"
                defaultValue={userDetails ? userDetails.status : ""}
                fullWidth
                onChange={(e) => EditUser(e)}
              >
                <option selected={userDetails?.status == "Active" && true} value="Active">
                  Active
                </option>
                <option selected={userDetails?.status == "Deleted" && true} value="Deleted">
                  Deleted
                </option>
              </select>
            </Grid>
            <Grid item xs={12}>
              <div className="cmpdialoginputtext">Created</div>
              <input type="text" label="email" disabled="disabled" className="form-control" value={moment(userDetails ? userDetails.created : "").format("Do MMM  YYYY") + " GMT"} fullWidth />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {lodervisible ? <LoadingIndicator /> : null}
          <div className="buttonRow">
            <div>
              <button className="inviteButton" onClick={() => resetPassword(userDetails.email)} color="primary">
                Request Password Reset
              </button>
            </div>
            <div>
              <button className="inviteButton" onClick={handleClose} color="primary">
                Cancel
              </button>

              <button className="inviteButton" color="primary" onClick={EditUserData}>
                Update User Details
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
      {/* //INVITE uSER */}
      <Dialog open={inviteUser} classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth scroll={scroll} maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle">
          <div className="FlexRow">
            <div className="cmpdialogtitle"> Invite User - Admin View</div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} />
            </div>
          </div>

        </DialogTitle>
        <DialogContent>

          <div className="dialoginputtext">Name:</div>
          <input type="text" id="name" label="name" className={inviteError.name ? "errorField form-control" : "form-control"} onChange={(e) => handleUser(e)} fullWidth />
          {inviteError.name ? <a className="errorClass">{inviteError.nameErMsg ? inviteError.nameErMsg : "This field is required"}</a> : null}
          <div className="dialoginputtext">Email:</div>
          <input
            type="text"
            id="email_address"
            name="email_address"
            label="email"
            className={inviteError.email_address ? "errorField form-control" : "form-control"}
            onChange={(e) => handleUser(e)}
            fullWidth
          />
          {inviteError.email_address ? <a className="errorClass">{inviteError.emailErrMsg ? inviteError.emailErrMsg : "This field is required"}</a> : null}
          <div className="dialoginputtext">Role:</div>
          <select type="text" id="user_role" name="user_role" label="email" className={inviteError.user_role ? "form-control form-select errorField" : "form-control form-select"} onChange={(e) => handleUser(e)} fullWidth>
            <option value=""></option>
            <option value="channel">channel</option>
            <option value="account manager">account manager</option>
          </select>
          {inviteError.user_role ? <a className="errorClass">{inviteError.roleErrMsg ? inviteError.roleErrMsg : "This field is required"}</a> : null}
        </DialogContent>
        <DialogActions>
          {loading ? <div className="loaderContainer">
            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="ivitediv">
            <button className="inviteButton" onClick={handleClose} color="primary">
              Cancel
            </button>

            <button className="inviteButton"
              disabled={clickInvite ? "disabled" : false}
              onClick={InviteNew} color="primary">
              Invite User
            </button>
          </div>
        </DialogActions>
      </Dialog>

      {/* ////////////////// Remove Company Logo ///////////////// */}
      <Dialog open={removeDialogue} classes={{ scrollPaper: classes.scrollPaper }} name="popup" fullWidth maxWidth="sm" 
      onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialogtitle  border">
          <div className="FlexRow border" >
            <div className="cmpdialogtitle"> </div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline style={{ cursor: "pointer" }} onClick={handleClose} />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="ContentHead">
            <div className="warningIcon"><MdWarning /></div>
            <div className="warningText">WARNING !</div>

          </div>
          <div className="logoConfirmtext">Are you sure, do you want to delete Company Logo?</div>
        </DialogContent>
        <DialogActions>
          <div className="ivitediv">
            <button className="continueBtn" name="popup" onClick={DialogClose} color="primary">
              Cancel
            </button>

            <button className="continueBtn" name="popup" onClick={removeLogo} color="primary">
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>
      {/*///////////////////////Popup//////////////////////////////*/}
      <Dialog open={openPopup} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }} aria-labelledby="form-dialog-title">

        <DialogContent className="diaContent">
          <div className="logoConfirmtext">Please Save or Cancel the changes before proceeding</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" name="popup" color="primary">
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>


      <Dialog open={invitedSuccess} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Success Message</div>
        </DialogTitle>
        <DialogContent className="diaContent">
          <div className="logoConfirmtext" style={{ fontSize: '17px' }}>Successfully added new user. Invitation link sent to user's E-mail id.</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" style={{ width: '11%' }} name="popup" color="primary" onClick={() => setInvitedSuccess(false)}>
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <Snackbar open={successopen} autoHideDuration={1500} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
          {toastMsg ? toastMsg : "Updated Successfully!"}
        </Alert>
      </Snackbar>

      <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {errMessage ? errMessage : "An Error Occured!"}
        </Alert>
      </Snackbar>
      <Snackbar open={successRequest} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success">
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default ChannelDetailView;
