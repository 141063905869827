import {FiSettings} from "react-icons/fi";
import {BsHash} from "react-icons/bs";
import {FaHashtag} from "react-icons/fa";
import applicationIcon from "../../../assets/img/white-app.png";
import reportingIcon from "../../../assets/img/reporting-icon.png";
import docIcon from "../../../assets/img/doc-icon.png";
import tutoialWhite from "../../../assets/img/tutorial_white.png";
import accountIcon from "../../../assets/img/account-icon.png";
import supportIcon from "../../../assets/img/support-icon.png";

export const SideNavMenu = [
    //Development
    /*{
      id:0,
      title: "Notification",
      collapsedIcon:<BsHash/>
    },*/
    {
      id:1,
      title: "Development",
      collapsedIcon:<BsHash/>,
      subMenu: [
        {
          id:1,
          title: "Getting Started",
          titles: "Components",
          imageIcon : applicationIcon,
          path: "/user/gettingStarted",
        },
        {
            id:2,
            title: "datafree Publication List",
            titles: "Click here to view applications list",
            icon :<FaHashtag/>,
            path: "/user/datafree",
          },
          {
            id:3,
            title: "Reporting",
            titles: "Click here to view Reporting",
            imageIcon : reportingIcon,
            path: "/user/reporting",
          },
          {
            id:4,
            title: "Utilities",
            titles: "Click here to view Utilities",
            icon : <FiSettings/>,
            path: "/user/utilities",
          },
          {
            id:5,
            title: "BiNu Knowledge Base",
            titles:"Click here to view biNu Knowledge Base",
            imageIcon : docIcon,
            path: "",
          },
          {
            id:6,
            title: "#datafree MAX Tutorial",
            titles:"Click here to view #datafree MAX Tutorial",
            imageIcon : tutoialWhite,
            path: "",
          },
      ],
      
    },
    //Settings
    {
      id:2,
      title: "Settings",
      collapsedIcon:<BsHash/>,
      subMenu: [
        { id:1,  imageIcon: accountIcon,title: "Account", titles:"Click here to view account details", path: "/user/account" },
        { id:2, imageIcon : supportIcon,title: "Support", titles:"Example Pages", path: "" },
       
      ],
     
    },
   
  ];
  