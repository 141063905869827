import React, { useEffect, useState,useContext } from "react";
import { useHistory } from "react-router-dom";
import applicationIcon from "../../../assets/img/application-icon.png";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { trackPromise } from "react-promise-tracker";
import Service from "../../../api/adminService";
import licenseeService from "../../../api/licenseeService";
import Loader from "react-loading";
import { usePromiseTracker } from "react-promise-tracker";
import VMCTable from '../../../Recomponent/Table';
import EmptyTable from "../../../Recomponent/EmptyTable";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {
  ToggleContext
} from "../../context/toggleContext"
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});
const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};


function ApplicationList() {
  const [search, setSearch] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [userNameMenu, setuserNameMenu] = useState(false);
  const toggleContextVal = useContext(ToggleContext)
  const [changedropdown, setChangedropdown] = useState(false);
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  let history = useHistory();
  const [erroropen, seterrorOpen] = useState(false);
  const handleErrorClose = () => {
    seterrorOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
    setuserNameMenu(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleuserMenu = (companytype, companyId) => {

    if (companytype === "CUSTOMER" || "RESLR CLNT") {
      history.push(`/licensee/customer/${companyId}/detail/view`);
    }
    if (companytype === "CHANNEL") {
      history.push(`/licensee/channel/${companyId}/detail/view`, {
        companyId: companyId,
      });
    }

  };
  const [responseData, setResponseData] = useState()
  /*const handleRouteLink = () => {
    history.push("/licensee/customer/id/detail/view");
  };*/
  const [data, setData] = useState({
    searchLicensee: "",
    searchDeploymentType: "",
    searchProductType: "",
    searchBinuId: "",
    searchApplicationName: "",
    searchFreeDomain: "",
    searchPublisherName: "",
    searchChannel: "",
  });
  const [searchDetails, setSearchDetails] = useState();
  const lifecycleChange = (e) => {
    e.preventDefault();
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  };
  const [tablecols, setTableCols] = useState([
    {
      colName: 'Channel',
      colMap: 'publisherIdparentCompanyIdname',
      linkName: true,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'App Name',
      colMap: 'name',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Product Type',
      colMap: 'productIdproductType',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Free Domain',
      colMap: 'reachSubDomain',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Google App Id',
      colMap: 'googleAppId',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Type',
      colMap: 'applicationType',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Dev biNu Id',
      colMap: 'devBinuId',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Prod biNu Id',
      colMap: 'ProdBinuId',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Production Data Usage Mb (28 days)',
      colMap: 'publisherIdpubDataUsageAlert',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Revenue Type',
      colMap: 'revenueType',
      linkName: false,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Publisher',
      colMap: 'publisherIdname',
      linkName: true,
      cModel: false,
      cRoute: true,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Company Type',
      colMap: 'publisherIdcompanyType',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Status',
      colMap: 'publisherIdstatus',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Production Health Check Required',
      colMap: 'ProductionHealth',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Status',
      colMap: 'status',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
  ]);
  const [errMsg, setErrMsg] = useState();
  const [empty, setEmpty] = useState(false)
  async function applicaitonListdata() {
    setSearch(true);
    setChangedropdown(true);
    setEmpty(false);
    setSearchDetails({});
    try {
      trackPromise(
        Service.ApplicationSearch(data).then((res) => {
          setResponseData(res.data);
          setSearchDetails(res.data);
          if (res.status == 200 && res.data.applicationListResult.length > 0) {

            setEmpty(false);
            setChangedropdown(false);
          } else if (res.status == 200 && res.data.applicationListResult.length == 0) {
            setEmpty(true);
            setChangedropdown(false);
          } else {
            setChangedropdown(true);
            setEmpty(false);
          }
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page.");
      seterrorOpen(true);
    }
  }
  const [channelDropdown, setchanneldropdown] = useState();
  //get publiserIndex
  async function publisherIndex() {
    try {
      trackPromise(
        licenseeService.licenseePublisherIndex().then((res) => {

          setchanneldropdown(res.data.publisherListResults);
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page.");
      seterrorOpen(true);
    }
  }
  useEffect(() => {
    publisherIndex();
  }, []);
  return (
    <div className={toggleContextVal.event ?"mainMobView" :"mainBoxView"}>
      <div className="NavBoxDiv">
        <div className="NavBoxRow col-lg-6 col-md-6 col-sm-6 col-xs-5">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">Application List - Licensee View</div>
        </div>
        <div className="NavBoxRowFilter table-responsive">
          <table className="table-sm">
            <tbody>
              <tr>
                <td className="col-md-2">Channel</td>
                <td className="col-md-1">Deployment Type</td>
                <td className="col-md-2">Product Type</td>
                <td className="col-md-1">biNu Id</td>
                <td className="col-md-2">Application Name</td>
                <td className="col-md-2">Free Domain</td>
                <td className="col-md-2">Publisher Name</td>
                <td className="col-md-2"></td>
              </tr>
              <tr>
                <td>
                  <select
                    className="form-control form-select"
                    id="searchChannel"
                    name="searchChannel"
                    onChange={(e) => lifecycleChange(e)}
                  >
                    <option value=""></option>
                    {channelDropdown && channelDropdown.length > 0
                      ? channelDropdown.map((post) => (
                        <option key={post.companyId} value={post.companyId}>
                          {post.name}
                        </option>
                      ))
                      : null}
                  </select>
                </td>

                <td className="">
                  <select
                    className="form-control form-select"
                    id="searchDeploymentType"
                    name="searchDeploymentType"
                    onChange={(e) => lifecycleChange(e)}
                  >
                    <option value=""></option>
                    <option value="PROD">PROD</option>
                    <option value="DEV">DEV</option>
                  </select>
                </td>
                <td className="">
                  <select
                    className="form-control form-select"
                    id="searchProductType"
                    name="searchProductType"
                    onChange={(e) => lifecycleChange(e)}
                  >
                    <option value=""></option>
                    <option value="1">#datafree WRAP</option>
                    <option value="2">#datafree MAX</option>
                    <option value="3">#datafree SWITCH</option>
                    <option value="4">#datafree REACH</option>
                    <option value="5">#datafree DIRECT</option>
                    <option value="6">#datafree CONNECT</option>
                  </select>
                </td>
                <td className="">
                  <input
                    type="text"
                    className="form-control"
                    id="searchBinuId"
                    name="searchBinuId"
                    onChange={(e) => lifecycleChange(e)}
                  />
                </td>
                <td className="">
                  <input
                    type="text"
                    className="form-control"
                    id="searchApplicationName"
                    name="searchApplicationName"
                    onChange={(e) => lifecycleChange(e)}
                  />
                </td>
                <td className="">
                  <input
                    type="text"
                    className="form-control"
                    id="searchFreeDomain"
                    name="searchFreeDomain"
                    onChange={(e) => lifecycleChange(e)}
                  />
                </td>
                <td className="">
                  <input
                    type="text"
                    className="form-control"
                    id="searchPublisherName"
                    name="searchPublisherName"
                    onChange={(e) => lifecycleChange(e)}
                  />
                </td>
                <td className="">
                  <button
                    onClick={applicaitonListdata}
                    disabled={changedropdown
                      ? "disabled"
                      : false}
                    className={
                      changedropdown
                        ? "defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"}
                  >
                    Search
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div><hr className="applications-list-hr"></hr></div>
        {search ? (
          <div>
            {searchDetails?.applicationListResult?.length > 0 ? (
              <VMCTable key={searchDetails.applicationListResult} data={searchDetails.applicationListResult} additionalData1={responseData} col={tablecols} navigateFunction={handleuserMenu} tableName="Licensee Application" />
            ) :
              <div>
                <LoadingIndicator />
                {empty ? <EmptyTable data="No Applications Found" col={tablecols} tableName="Connect Utility Table" /> : null}
              </div>
            }
          </div>
        ) : (
          ""
        )}
      </div>
      <Dialog
        open={open}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <div className="cmpdialogtitle">Create Company - Admin View</div>
        </DialogTitle>
        <DialogContent>
          <div className="cmpdialoginputtext">Company Type</div>
          <select
            type="text"
            id="name"
            label="name"
            className="form-control"
            fullWidth
          />
          <div className="cmpdialoginputtext">Company Name</div>
          <input
            type="text"
            id="email"
            label="email"
            className="form-control"
            fullWidth
          />
          <div className="cmpdialoginputtext">Status</div>
          <input
            type="text"
            id="email"
            label="email"
            className="form-control"
            fullWidth
          />
          <div className="cmpdialoginputtext">Registered Company Name</div>
          <input
            type="text"
            id="email"
            label="email"
            className="form-control"
            fullWidth
          />
          <div className="cmpdialoginputtext">
            Registered Business Number (if appropriate)
          </div>
          <input
            type="text"
            id="email"
            label="email"
            className="form-control"
            fullWidth
          />
          <div className="cmpdialoginputtext">Registered Address</div>
          <input
            type="text"
            id="email"
            label="email"
            className="form-control"
            fullWidth
          />
          <div className="cmpdialoginputtext">Company Phone Number</div>
          <input
            type="text"
            id="email"
            label="email"
            className="form-control"
            fullWidth
          />
          <div>
            <input
              type="checkbox"
              name="websiteName"
              id="websiteName"
              onChange={() => setChecked(!checked)}
              checked={checked}
            />
            <label for="websiteName" className="cmpdialoginputtext">
              Optionally Create User ?
            </label>
          </div>
          {checked ? (
            <div>
              <div className="cmpdialoginputtext">User’s Name</div>
              <input
                type="text"
                id="email"
                label="email"
                className="form-control"
                fullWidth
              />
              <div className="cmpdialoginputtext">Email Address (username)</div>
              <input
                type="text"
                id="email"
                label="email"
                className="form-control"
                fullWidth
              />
            </div>
          ) : (
            <div></div>
          )}
        </DialogContent>
        <DialogActions>
          <button
            className="inviteButton"
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </button>
          <button
            className="inviteButton"
            onClick={handleClose}
            color="primary"
          >
            Invite User
          </button>
        </DialogActions>
      </Dialog>
      {/* //userName */}

      <Snackbar
        open={erroropen}
        autoHideDuration={2500}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {errMsg ? errMsg : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default ApplicationList;
