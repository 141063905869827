import React, { useState, useEffect,useContext } from "react";
import "./adminStyle.css";
import applicationIcon from "../../../assets/img/account-icon-black.png";
import AdminService from "../../../api/adminService";
import { trackPromise } from "react-promise-tracker";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loading";
import {
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import VMCTable from '../../../Recomponent/Table'
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { IoMdCloseCircleOutline } from "react-icons/io";
import {
  ToggleContext
} from "../../context/toggleContext"

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};

function AdminAccount() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [accountState, setAccountState] = useState([]);
  const toggleContextVal = useContext(ToggleContext)
  const [tablecols, setTableCols] = useState([
    {
      colName: 'User’s Name',
      colMap: 'name',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Email Address',
      colMap: 'email',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Role',
      colMap: 'role',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Type',
      colMap: 'userType',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Verified',
      colMap: 'verified',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Status',
      colMap: 'status',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Created',
      colMap: 'created',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: true,
      imprRoute: false,
      userTab: false
    },
  ])



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setError({
      name: false,
      email_address: false,
      email_addressmsg: ""
    })
  };
  const handlesuccessAlert = () => {
    setsuccessOpen(false);

  };
  const handleAlertClose = () => {
    seterrorOpen(false);

  };
  async function accountListdata() {
    try {
      trackPromise(
        AdminService.accountListData().then((res) => {
          setAccountState(res.data.application_list);
          invite["companyId"] = res.data.currentUserDetail.companyId;
        })
      );
    } catch (err) {
      seterrorOpen(true);
    }
  }

  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [invite, setInvite] = useState({
    name: "",
    email_address: "",
    companyId: "",
  });
  const [error, setError] = useState({
    name: false,
    email_address: false,
    email_addressmsg: ""
  })
  const reg = /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/;

  async function EmailCheck(value) {
    trackPromise(
      AdminService.EmailCheck({ email_address: value })
        .then((res) => {
          if (res.data === false) {
            setError({
              ...error,
              email_address: true,
              email_addressmsg: "This​ email address already exists please use admin utilities to check current user access",
            });
          } else if (value && reg.test(value) === false) {
            setError({
              ...error,
              email_address: true,
              email_addressmsg: "Please enter a valid email address.",
            });
          } else {
            setError({
              ...error,
              email_address: false,
            });
          }
        })
        .catch((error) => {
       
          seterrorOpen(true);
        })
    );
  }
  const handleUser = (e) => {
    e.preventDefault();
    const invitedata = { ...invite };
    invitedata[e.target.id] = e.target.value;
    if (e.target.id === "email_address") {
      if (e.target.value) {
        EmailCheck(e.target.value);
      } else {
        setError({
          ...error,
          email_address: true,
          email_addressmsg: "This field is required",
        });
      }
    }
    if (e.target.id === 'name') {
      setError({
        ...error,
        name: false,
      });
    }
    setInvite(invitedata);
  };
  
  const [loading, setLoading] = useState(false);
  const [clickInvite, setClickInvite] = useState(false);
  const [invitedSuccess, setInvitedSuccess] = useState(false);
  async function InviteNew() {
    //invite["companyId"] = companyId;
    //const countInvite = ValidateInvite(invite);
    if (invite.name.length === 0 && invite.email_address.length === 0) {
      setError({
        name: invite.name.length === 0 ? true : false,
        email_address: invite.email_address.length === 0 ? true : false
      })
    }
    else if (error.email_address) {
      setError({
        ...error,
        email_address: true,
      });
    }
    else {
      setLoading(true)
      setClickInvite(true);
      trackPromise(
        AdminService.inviteAccount(invite).then((res) => {
         
          if (res.status === 200) {
            // setsuccessOpen(true);
            setLoading(false)
            setClickInvite(false);
            handleClose();
            setOpen(false);
            setInvitedSuccess(true);

          } else {
            seterrorOpen(true);
          }
          accountListdata();
        }).catch((error => {

          seterrorOpen(true);
        }))
      );
    }

  }
  
  useEffect(() => {
    accountListdata();
  }, []);

  return (
    <div className={toggleContextVal.event ?"mainMobView" :"mainBoxView"}>
      <div className="accountBoxDiv">
        <div className="NavBoxRow">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo spaceRight"></img>
          </div>
          <div className="NavBoxRowText">Account Details</div>
        </div>
        <div className="subHead spaceRight">Admin Users</div>
        <div className="divfieldSpace"></div>
        <div className="tableMargin">
          {
            accountState?.length > 0 ? <VMCTable key={accountState} data={accountState} col={tablecols} tableName="Admin Account" />
              : <LoadingIndicator />
          }

        </div>
        <div className="ivitediv">
          <button className="inviteButton"
            onClick={handleClickOpen}>
            + Invite New
          </button>
        </div>
      </div>
      <Dialog
        open={open}
        classes={{ scrollPaper: classes.scrollPaper }}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className="dialogtitle">
          <div className="FlexRow">
            <div className="cmpdialogtitle">  Invite Publisher User</div>
            <div className="cmpdialogtitle">
              <IoMdCloseCircleOutline style={{ cursor: "pointer" }} className="modalClose" onClick={handleClose} />
            </div>
          </div>

        </DialogTitle>
        <DialogContent>

          <div className="dialoginputtext">Name:</div>
          <input
            type="text"
            id="name"
            label="name"
            className={error.name ? "errcmpnydialoginput" : "cmpnydialoginput"}
            onChange={(e) => handleUser(e)}
            fullWidth
          />
          {error.name ? <a className="errorClass">This field is required</a> : null}
          <div className="dialoginputtext">Email:</div>
          <input
            type="text"
            id="email_address"
            label="email_address"
            className={error.email_address ? "errcmpnydialoginput" : "cmpnydialoginput"}
            onChange={(e) => handleUser(e)}
            fullWidth
          /> {error.email_address ? <a className="errorClass">{error.email_addressmsg ? error.email_addressmsg : "This field is required"}</a> : null}
        </DialogContent>
        <DialogActions>
          {loading ? <div className="loaderContainer">
            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="ivitediv">
            <button
              className="inviteButton"
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </button>
            <button
              className="inviteButton"
              onClick={InviteNew}
              disabled={clickInvite ? "disabled" : false}
              color="primary"
            >
              Invite User
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog open={invitedSuccess} classes={{ scrollPaper: classes.scrollPaper }} fullWidth maxWidth="sm" style={{ minHeight: '50px !important' }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">Success Message</div>
        </DialogTitle>
        <DialogContent className="diaContent">
          <div className="logoConfirmtext" style={{ fontSize: '17px' }}>Successfully added new user. Invitation link sent to user's E-mail id.</div>
        </DialogContent>
        <DialogActions>
          <div className="popupbutton">
            <button className="popupOk" style={{ width: '11%' }} name="popup" color="primary" onClick={() => setInvitedSuccess(false)}>
              OK
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={successopen}
        autoHideDuration={6000}
        onClose={handlesuccessAlert}
      >
        <Alert onClose={handlesuccessAlert} severity="success">
          "Updated Successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={erroropen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          An Error Occured!
        </Alert>
      </Snackbar>
    </div>
  );
}
export default AdminAccount;
