import React, { useEffect, useState,useContext } from "react";
import { useHistory } from "react-router-dom";
import applicationIcon from "../../../assets/img/application-icon.png";
import Service from "../../../api/licenseeService";
import AdminService from "../../../api/adminService";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";
import VMCTable from '../../../Recomponent/Table';
import { usePromiseTracker } from "react-promise-tracker";
import EmptyTable from "../../../Recomponent/EmptyTable";
import { IoMdCloseCircleOutline } from "react-icons/io";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles
} from "@material-ui/core";
import licenseeService from "../../../api/licenseeService";
import {
  ToggleContext
} from "../../context/toggleContext"

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "baseline",
  },
});
const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function UserList() {
  const [search, setSearch] = useState(false);
  const [channelList, setChannelList] = useState();
  const [companyId, setCompanyId] = useState("");
  const [userNameMenu, setuserNameMenu] = useState(false);
  const classes = useStyles();
  const [scroll, setScroll] = useState("paper");
  const toggleContextVal = useContext(ToggleContext)
  const [successopen, setsuccessOpen] = useState(false);
  const [changedropdown, setChangedropdown] = useState(false);
  let history = useHistory();
  const handlesuccessAlert = () => {
    setsuccessOpen(false);
  };
  const [erroropen, seterrorOpen] = useState(false);
  const handleErrorClose = () => {
    seterrorOpen(false);
  };
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setuserNameMenu(false);
    }

    setUserNameError({
      username: false,
      msg: ""
    })
  };

  const handleuserMenu = (scrollType, Id, cId) => {

    getUserDetail(Id, cId);
    setuserNameMenu(true);
    setScroll(scrollType);
  };
  const handleRouteLink = (companyType, companyId) => {
    if (companyType === "CUSTOMER") {
      history.push(`/licensee/customer/${companyId}/detail/view`);
    }
    if (companyType === "CHANNEL") {
      history.push(`/licensee/channel/${companyId}/detail/view`);
    }
    if (companyType === "RESELLER") {
      history.push(`/licensee/customer/${companyId}/detail/view`);
    }
  };
  async function Userdata() {
    try {
      trackPromise(
        Service.licenseeUserRequest().then((res) => {
          setCompanyId(res.data.userDetails.companyId.companyId);
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page.");
      seterrorOpen(true);
    }
  }
  const [user_id, setUser_id] = useState();
  const [company_id, setcompany_id] = useState();
  const [userDetails, setuserDetails] = useState();

  //getUser
  async function getUserDetail(value, Id) {
    try {
      setuserDetails({});
      trackPromise(
        AdminService.getUserById({ userId: value, companyId: Id }).then((res) => {
          setuserDetails(res.data);
          setUserdata({
            username: res.data?.userName,
            user_role: res.data?.role,
            user_type: res.data?.type,
            user_verified: res.data?.verified && res.data?.verified == 1 ? "Yes" : "No",
            user_status: res.data?.status,
            user_id: res.data?.userId,
            company_id: res.data?.companyId,
          });
          setUser_id(res.data.userId);
          setcompany_id(res.data.companyId);

        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page.");
      seterrorOpen(true);
    }
  }
  const [tablecols, setTableCols] = useState([
    {
      colName: 'User’s Name',
      colMap: 'username',
      linkName: true,
      cModel: true,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: true,
    },
    {
      colName: 'Email Address',
      colMap: 'email',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Role',
      colMap: 'role',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Type',
      colMap: 'userType',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Verified',
      colMap: 'verified',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: false,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Status',
      colMap: 'status',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: true,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Managed By',
      colMap: 'companyIdparentCompanyIdname',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: true,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Company',
      colMap: 'companyIdname',
      linkName: true,
      cModel: false,
      cRoute: true,
      fDate: true,
      imprRoute: false,
      userTab: false
    },
    {
      colName: 'Company Type',
      colMap: 'companyIdcompanyType',
      linkName: false,
      cModel: false,
      cRoute: false,
      fDate: true,
      imprRoute: false,
      userTab: false
    },
  ])


  const [userdata, setUserdata] = useState({
    username: "",
    user_role: "",
    user_type: "",
    user_verified: "",
    user_status: "",
    user_id: "",
    company_id: "",
  });
  const [userNameError, setUserNameError] = useState({
    username: false,
    msg: "",
  });
  const [successRequest, setsuccessRequest] = useState(false);
  const [message, setMessage] = useState();
  //passwordReset
  async function resetPassword(EMAIL) {

    try {
      trackPromise(
        licenseeService.passwordRequest({ email: EMAIL, admin: "licensee" }).then((res) => {
          if (res.data.status === "success") {
            setsuccessRequest(true);
            setMessage(res.data.message);
            handleClose();
          }
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page.");
      seterrorOpen(true);
    }
  }

  const EditUser = (e) => {
    const newdata = { ...userdata };
    newdata[e.target.id] = e.target.value;
    newdata["user_id"] = user_id;
    newdata["company_id"] = company_id;
    if (e.target.id === "username") {
      if (e.target.value) {
        setUserNameError({
          username: false,

        });
      } else {
        setUserNameError({
          username: true,
          msg: "This field is required",
        });
      }
    }
    setUserdata(newdata);
  };
  const [toastMsg, setToastMsg] = useState();
  const [errMsg, setErrMsg] = useState();
  const [loadings, setLoadings] = useState(false)
  //edit user
  const userNameRef = React.useRef();
  async function EditUserData() {

    if (userNameError.username) {
      setUserNameError({
        username: true,
      });
      userNameRef.current.focus();
    } else {
      setLoadings(true);
      try {
        trackPromise(
          AdminService.editUser(userdata).then((res) => {
            if (res.status === 200) {
              setToastMsg("User Details Updated Successfully!");
              setuserNameMenu(false)
              setsuccessOpen(true);
              handleClose();
            } else {
              seterrorOpen(true);
              setuserNameMenu(true);

            }
            setLoadings(false);
            Userdata();

          })
        );
      } catch (err) {
        setErrMsg("Something went wrong. Please reload the page.");
        seterrorOpen(true);
      }
    }
  }

  const [data, setData] = useState({
    searchCompanyType: "",
    searchCompanyName: "",
    searchUserName: "",
    searchEmail: "",
    searchVerified: "",
    searchStatus: "",
  });
  const [empty, setEmpty] = useState(false);
  async function LicenseeIndexdata() {
    setSearch(true);
    setChangedropdown(true);
    setEmpty(false);
    setChannelList({});
    try {
      trackPromise(
        Service.licenseeUserListRequest(data).then((res) => {
          setChannelList(res.data.userListResult);
          if (res.status === 200 && res.data.userListResult.length > 0) {
            setEmpty(false);
            setChangedropdown(false);
          } else if (res.status === 200 && res.data.userListResult.length === 0) {
            setEmpty(true);
            setChangedropdown(false);
          } else {
            setChangedropdown(true);
            setEmpty(false);
          }
        })
      );
    } catch (err) {
      setErrMsg("Something went wrong. Please reload the page.");
      seterrorOpen(true);
    }
  }
  const dataChange = (e) => {
    changedropdown ? setChangedropdown(false) : setChangedropdown(false);
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    // newdata["searchLicenseeId"] = companyId;
    setData(newdata);
  };

  useEffect(() => {
    Userdata();
  }, []);

  const handleAlertClose = () => {
    setsuccessRequest(false);
    setuserNameMenu(false);
  };
  return (
    <div className={toggleContextVal.event ?"mainMobView" :"mainBoxView"}>
      <div className="NavBoxDiv">
        <div className="NavBoxRow col-lg-6 col-md-6 col-sm-6 col-xs-5">
          <div>
            <img src={applicationIcon} alt="" className="NavBoxRowLogo"></img>
          </div>
          <div className="NavBoxRowText">User List - Licensee View</div>
        </div>
        <div className="NavBoxRowFilter table-responsive">
          <table className="table-sm">
            <tbody>
              <tr>
                <td className="col-md-2">
                  Company Type
                </td>
                <td className="col-md-3">
                  Company
                </td>
                <td className="col-md-2">
                  User's Name
                </td>
                <td className="col-md-3">
                  Email Address
                </td>
                <td className="col-md-1">
                  Verified
                </td>
                <td className="col-md-2">
                  Status
                </td>
                <td className="col-md-1">

                </td>
               
              </tr>
              <tr>
                <td className="col-md-2">
                  <select
                    type="text"
                    className="form-control form-select"
                    id="searchCompanyType"
                    name="searchCompanyType"
                    onChange={(e) => dataChange(e)}
                  >
                    <option value=""></option>
                    <option value="channel">CHANNEL</option>
                    <option value="customer">CUSTOMER</option>
                    <option value="NoAgreement">RESELLER</option>
                  </select>
                </td>
                <td className="col-md-3">
                  <input
                    type="text"
                    className="form-control"
                    id="searchCompanyName"
                    name="searchCompanyName"
                    onChange={(e) => dataChange(e)}
                  ></input>
                </td>
                <td className="col-md-2">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => dataChange(e)}
                    id="searchUserName"
                    name="searchUserName"
                  ></input>

                </td>
                <td className="col-md-3">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => dataChange(e)}
                    id="searchEmail"
                    name="searchEmail"
                  ></input>
                </td>
                <td className="col-md-1">
                  <select
                    type="text"
                    className="form-control form-select"
                    onChange={(e) => dataChange(e)}
                    id="searchVerified"
                    name="searchVerified"
                  >

                    <option value=""></option>
                    <option value="no">no</option>
                    <option value="yes">yes</option>
                  </select>
                </td>
                <td className="col-md-2">
                  <select
                    
                    type="text"
                    className="form-control form-select"
                    onChange={(e) => dataChange(e)}
                    id="searchStatus"
                    name="searchStatus"
                  >

                    <option value=""></option>
                    <option value="New">New</option>

                  </select>
                </td>
                <td className="col-md-1">
                  <button
                 
                    className={changedropdown ? "defultbtnafterclick btn btn-default application-search-btn img-rounded" : "btn btn-default application-search-btn img-rounded btn-green defultbtn"}
                    disabled={changedropdown ? "disabled" : false}
                    onClick={LicenseeIndexdata}>
                    Search
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div><hr className="applications-list-hr"></hr></div>

        {search ? (
          <div>
            {channelList && channelList.length > 0 ? (
              <VMCTable key={channelList} data={channelList} col={tablecols}
                tabNavigation={handleuserMenu} navigateFunction={handleRouteLink} tableName="Licensee UserList" />
            ) :
              <div>
                <LoadingIndicator />
                {empty ? <EmptyTable data="No Users Found" col={tablecols} tableName="Connect Utility Table" /> : null}
              </div>
            }
          </div>
        ) : (
          ""
        )}
      </div>

      <Dialog open={userNameMenu} classes={{ scrollPaper: classes.scrollPaper }} fullWidth scroll={scroll} maxWidth="sm" onClose={handleClose} aria-labelledby="form-dialog-title">

        <DialogTitle id="form-dialog-title" style={{ borderBottom: '1px solid #e5e5e5' }}>
          <div className="cmpdialogtitle">User Details - Licensee View
            <IoMdCloseCircleOutline className="modalClose" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="dialoginputtext">Company Name:</div>
          <input type="text" id="name" label="name" disabled="disabled"
            value={userDetails ? userDetails.companyName : ""} className="dialoginputValue" fullWidth />
          <div className="dialoginputtext">Company Type:</div>
          <input type="text" id="email" label="email" disabled="disabled" className="dialoginputValue"
            value={userDetails ? userDetails.companyType : ""} fullWidth />
          <div className="dialoginputtext">User’s Name:</div>
          <input
            type="text"
            id="username"
            label="email"
            name="username"
            style={{ width: '99%' }}
            className={userNameError.username ? "errcmpnydialoginput" : "cmpnydialoginput"}
            defaultValue={userDetails ? userDetails.userName : ""}
            // onChange={(e) => EditUser(e)}
            onInput={(e) => EditUser(e)}
            ref={userNameRef}
            fullWidth
          />
          {userNameError.username ? <a className="errorClass">{userNameError.msg ? userNameError.msg : "This field is required"}</a> : null}
          <div className="dialoginputtext">Email Address:</div>
          <input type="text" id="email" label="email" disabled="disabled" className="dialoginputValue" value={userDetails ? userDetails.email : ""} fullWidth />
          <div className="dialoginputtext">Role:</div>
          <select type="text" id="user_role" label="email" style={{ width: '101%', height: '34px' }} name="user_role" className="dialoginput" onChange={(e) => EditUser(e)} fullWidth>
            <option value={userDetails?.role}>{userDetails?.role}</option>
          </select>
          <div className="dialoginputtext">Type:</div>
          <select type="text" id="user_type" label="email" style={{ width: '101%', height: '34px' }} name="user_type" className="dialoginput" onChange={(e) => EditUser(e)} fullWidth>
            <option value="INTERNAL" selected={userDetails?.type === "INTERNAL" && true}>INTERNAL</option>
            <option value="PUBLISHER" selected={userDetails?.type === "PUBLISHER" && true}>PUBLISHER</option>
          </select>
          <div className="dialoginputtext">Verified:</div>
          <select type="text" id="user_verified" style={{ width: '101%', height: '34px' }} label="email" name="user_verified" className="dialoginput" onChange={(e) => EditUser(e)} fullWidth>
            <option value="Yes" selected={userDetails?.verified === 1 && true}>Yes</option>
            <option value="No" selected={userDetails?.verified === 0 && true}>No</option>
          </select>
          <div className="dialoginputtext">Status:</div>
          <select type="text" id="user_status" style={{ width: '101%', height: '34px' }} label="email" name="user_status" className="dialoginput" onChange={(e) => EditUser(e)} fullWidth>
            <option value="Active" selected={userDetails?.status === "Active" && true}>Active</option>
            <option value="Deleted" selected={userDetails?.status === "Deleted" && true}>Deleted</option>
          </select>
          <div className="dialoginputtext">Created:</div>
          <input type="text" id="email" label="email" disabled="disabled" className="dialoginputValue"
            value={moment(userDetails ? userDetails.created : "").format("YYYY-MM-DD HH:mm") + " GMT"}
            fullWidth />
        </DialogContent>
        <DialogActions>
          {loadings ? <div className="loaderContainer">
            <Loader type="spokes" color="#333333" delay={0} height={30} width={30} /></div> : null}
          <div className="buttonRow">
            <div>
              <button className="inviteButton" onClick={() => resetPassword(userDetails.email)} color="primary">
                Request Password Reset
              </button>
            </div>
            <div>
              <button className="inviteButton" onClick={handleClose} color="primary">
                Cancel
              </button>

              <button className="inviteButton" onClick={EditUserData} color="primary">
                Update User Details
              </button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
      <Snackbar open={successRequest} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="success">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar open={successopen} autoHideDuration={1500} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
          {toastMsg ? toastMsg : "Updated Successfully!"}
        </Alert>
      </Snackbar>
      <Snackbar
        open={erroropen}
        autoHideDuration={2500}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          {errMsg ? errMsg : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default UserList;
