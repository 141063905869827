import React,{useState,useEffect} from "react";
import { FiSettings } from "react-icons/fi";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import services from "../../../api/service";
import { trackPromise } from "react-promise-tracker";
import Loader from "react-loading";
import { usePromiseTracker } from "react-promise-tracker";
import { useHistory } from "react-router-dom";

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
         <Loader   type ="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};
 function Utilities() {

  const [utilityData, setUtilityData] = useState(false);
  let history = useHistory();
  const utilitydata = () => {
    try {
      trackPromise(
        services.utilityData().then((res) => {
      
          setUtilityData(res.data.utilityUserAccessDetail);
        })
      );
    } catch (err) {
   
    }
  };

  const utiltyRouteLink = (name) =>{
    if(name === "Maintain Licensee Revenue Types"){
      history.push("/licensee/revenue/maintenance/index");
      
    }
  }
  useEffect(() => {
    utilitydata();
  }, []);

  
    return (
        <div className="utilityContent">
        <div className="utilityView">
          <div className="utilityhead">
            <div>
              <FiSettings className="utilityheadIcon" />
            </div>
            <div className="utilToptext">Utilities</div>
          </div>
          <div className="utilitytable">
          <LoadingIndicator />
         
            <TableContainer component={Paper}>
              <Table className="tablestyle" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell >Utility Name</TableCell>
                    <TableCell >Utility Description</TableCell>
                  </TableRow>
                </TableHead>
                {utilityData?.length > 0 ? ( <TableBody>
                {utilityData.map((row, i) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row" style={{color: "#337ab7"}} onClick={()=>utiltyRouteLink(row.utility_name)}>
                      {row.utility_name}
                      </TableCell>
                      <TableCell >{row.utility_desc}</TableCell>
                     
                    </TableRow>
                  ))}
                </TableBody>):(<TableBody>
                
                <TableRow >
                  <TableCell
                  
                  >No additional utilities are available to you at this time
                   
                  </TableCell>
                  <TableCell></TableCell>
                 
                </TableRow>
             
            </TableBody>)}
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    )
}
export default Utilities