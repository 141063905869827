import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import AdminService from "../../../api/adminService";
import { trackPromise } from "react-promise-tracker";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import adminService from "../../../api/adminService";
import defaultLogo from "../../../assets/img/datafree_defaulticon.png";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import defaultsplashLogo from "../../../assets/img/default-app-splash.jpg";
import Loader from "react-loading";
import { usePromiseTracker } from "react-promise-tracker";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",

        }}
      >
        <Loader type="spokes" color="#333333" delay={0} height={30} width={30} />
      </div>
    )
  );
};
function AdminDatafreeAppCreate() {
  let history = useHistory();
  const { appId } = useParams();
  const [data, setData] = useState();
  const [checked, setChecked] = useState(true);
  const [noOfRows, setNoOfRows] = useState(1);
  const [publisherData, setPublisherData] = useState();
  const [successopen, setsuccessOpen] = useState(false);
  const [erroropen, seterrorOpen] = useState(false);
  const [providerList, setProviderList] = useState();
  const [region, setRegion] = useState();
  const [status, setStatus] = useState();
  const [account, setAccount] = useState();
  const appReg = /[,]/;
  const emailReg = /[a-zA-Z0-9_\-\.]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$)/;
  const forwardReg = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  const high_data_thresholdReg = /(?!0+$)(^[0-9]+$)|(^[0-9]+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\.[0-9]{1,2})+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\,[0-9]{2,4})+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K))$/i;
  const max_byte_per_dayReg = /(?!0+$)(^[0-9]+$)|(^[0-9]+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\.[0-9]{1,2})+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\,[0-9]{2,4})+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K))$/i;
  const max_byte_per_userReg = /(?!0+$)(^[0-9]+$)|(^[0-9]+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\.[0-9]{1,2})+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\,[0-9]{2,4})+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K))$/i;
  const monthly_data_alert_limitReg = /(?!0+$)(^[0-9]+$)|(^[0-9]+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\.[0-9]{1,2})+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(M|G|Mb|Gb|Kb|K)$)|(^[0-9]+ (M|G|Mb|Gb|Kb|K)$)|(^[0-9]+(\,[0-9]{2,4})+(\.[0-9]{1,2})+(M|G|Mb|Gb|Kb|K))$/i;
  const portRange = /^(0|[1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/;
  const [selectedChannel] = useState({
    channelId: "",
  });
  const [selectedPublisher] = useState({
    companyId: "",
    productId: "5"
  });
  const [selectedDepType] = useState({
    app_type: ""
  });
  const [selectedProvider] = useState({
    provider: "",
    app_type: ""
  });
  const [selectedRegion] = useState({
    provider: "",
    region: "",
    app_type: ""
  });
  const handlesuccessAlert = () => {
    setsuccessOpen(false);

  };
  const handleAlertClose = () => {
    seterrorOpen(false);


  };

  const [error, setError] = useState({
    channel: false,
    msg: "",
    appmsg: "",

    publisher: false,
    deploymentType: false,
    app_name: false,
    account: false,
    region: false,
    provider: false,
    gateway: false,
    reserved_ip: false,
    forward_address: false,

    alert_email_address: false,
    emailmsg: "",
    forwardmsg: "",
    high_data_threshold: false,
    max_byte_per_day: false,
    max_byte_per_user: false,
    monthly_data_alert_limit: false,
    monthlymsg: "",
    bytedaymsg: "",
    byteusermsg: "",
    thresholdmsg: "",

    rangeEndMsg: "",
    rangeMsg: "",
  })

  const [create, setCreate] = useState({
    company_id: "",//need to change name
    company_list: "",//need to change name
    deployment_type: "",//need to change name
    data_free_app_name: "",
    app_name: "",
    company_name: "",
    report_aggregation_tag: "",
    high_data_threshold: "",
    monthly_data_alert_limit: "",
    alert_email_address: "",
    max_byte_per_user: "",
    include_daily_dataFree_limit_connect_direct: "",
    max_byte_per_day: "",
    action_required: "",
    forward_address: "",
    port: [{
      index: Math.random(), protocol: "", start_port_range: "", end_port_range: ""
    }],
    protocol_row_count: "",
    protocol_row_count_disable: "",
    protocol_row_count_cancel: "",
    gateway_domain: "",
    reserved_ip: "",
    account: "",
    region: "",
    provider: "",
    icon_url: "",
    splash_image: ""

  })
  const handleclose = () => {
    setCreate({
      company_id: "",//need to change name
      company_list: "",//need to change name
      deployment_type: "",//need to change name
      data_free_app_name: "",
      company_name: "",
      report_aggregation_tag: "",
      high_data_threshold: "",
      monthly_data_alert_limit: "",
      alert_email_address: "",
      max_byte_per_user: "",
      include_daily_dataFree_limit_connect_direct: "",
      max_byte_per_day: "",
      action_required: "",
      forward_address: "",
      port: [{
        index: Math.random(), protocol: "", start_port_range: "", end_port_range: ""
      }],
      protocol_row_count: "",
      protocol_row_count_disable: "",
      protocol_row_count_cancel: "",
      gateway_domain: "",
      reserved_ip: "",
      account: "",
      region: "",
      provider: "",
      icon_url: "",
      splash_image: ""

    })
    history.push("/admin/datafreedirect/application/index")
  }
  //Get Publisher
  async function getPublisher(channelId) {
    selectedChannel['channelId'] = channelId;
    ipData['companyId'] = channelId;
    try {
      trackPromise(
        AdminService.datafreePublisherByChannel(selectedChannel).then((res) => {
          setPublisherData(res.data)
        })
      );
    } catch (err) {

    }
  }

  //Get DeploymentType
  const [gatewayList, setGatewayList] = useState();
  async function getDeploymentType(publisherId) {

    selectedPublisher['companyId'] = publisherId;

    try {
      trackPromise(
        AdminService.datafreeDeploymentTypeByPublisherId(selectedPublisher).then((res) => {
          setStatus(res.data);
          setGatewayList(res.data?.gateway);
        })
      );
    } catch (err) {

    }
  }
  //Get Provider
  async function getProvider(appType) {
    selectedDepType['app_type'] = appType;
    setProviderList({});
    setRegion({});
    setAccount({});
    setReservedIp({});
    try {
      trackPromise(
        AdminService.datafreeProviderByDepType(selectedDepType).then((res) => {
          setProviderList(res.data)
        })
      );
    } catch (err) {

    }
  }
  //Get Region
  async function getRegion(Provider) {
    selectedProvider['provider'] = Provider;
    selectedProvider['app_type'] = selectedDepType.app_type;
    setRegion({});
    setAccount({});
    setReservedIp({});
    try {
      trackPromise(
        AdminService.datafreeRegionByProvider(selectedProvider).then((res) => {
          setRegion(res.data)
        })
      );
    } catch (err) {

    }
  }
  //Get Account
  async function getAccount(Region) {
    selectedRegion['region'] = Region;
    selectedRegion['provider'] = selectedProvider.provider;
    selectedRegion['app_type'] = selectedProvider.app_type;
    setAccount({});
    setReservedIp({});
    try {
      trackPromise(
        AdminService.datafreeAccountByRegion(selectedRegion).then((res) => {
          setAccount(res.data);
        })
      );
    } catch (err) {

    }
  }


  const [ipData, setIpData] = useState({
    developmentType: "",
    account: "",
    provider: "",
    region: "",
    companyId: "",
    productId: "5"
  })
  const [reservedIp, setReservedIp] = useState()
  //getReserved ip

  async function getReservedIp(account) {
    ipData['developmentType'] = selectedDepType.app_type;
    ipData['provider'] = selectedRegion.provider;
    ipData['account'] = account;
    ipData['region'] = selectedRegion.region;
    setReservedIp({});

    try {
      trackPromise(
        AdminService.getReservedIPBYAccount(ipData).then((res) => {

          setReservedIp(res?.data);

        })
      );
    } catch (err) {

    }
  }
  async function getDetail() {
    try {
      trackPromise(
        AdminService.datafreeAppCreate().then((res) => {
          setData(res.data);
        })
      );
    } catch (err) {

    }
  }
  const changeField = (e) => {
    e.preventDefault();
    const newdata = { ...create };
    newdata[e.target.id] = e.target.value;
    if (e.target.id == "company_id") {
      getPublisher(e.target.value);
      if (e.target.value) {
        setError({
          ...error,
          channel: false,
          msg: ""
        });
      }
      else {
        setError({
          ...error,
          channel: true,
          msg: "This field is required."
        });
      }
    }

    if (e.target.id == "company_list") {
      getDeploymentType(e.target.value);
      if (e.target.value) {
        setError({
          ...error,
          publisher: false,
          msg: ""
        });
      }
      else {
        setError({
          ...error,
          publisher: true,
          msg: "This field is required."
        });
      }
    }

    if (e.target.id == "deployment_type") {
      getProvider(e.target.value);
      if (e.target.value) {
        setError({
          ...error,
          deploymentType: false,
          msg: ""
        });
      }
      else {
        setError({
          ...error,
          deploymentType: true,
          msg: "This field is required."
        });
      }
    }

    if (e.target.id == "data_free_app_name") {

      if (e.target.value) {
        if (appReg.test(e.target.value) === true) {
          setError({
            ...error,
            app_name: true,
            appmsg: "Please enter a name without comma"
          });
        }
        else {
          setError({
            ...error,
            app_name: false,
            appmsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          app_name: true,
          appmsg: "This field is required."
        });
      }
    }


    if (e.target.id == "provider") {
      getRegion(e.target.value);
      if (e.target.value) {
        setError({
          ...error,
          provider: false,
          msg: ""
        });
      }
      else {
        newdata['region'] = "";
        newdata['account'] = "";
        newdata['reserved_ip'] = "";
        setError({
          ...error,
          provider: true,
          msg: "This field is required."
        });
      }
    }

    if (e.target.id == "region") {
      getAccount(e.target.value);
      if (e.target.value) {
        setError({
          ...error,
          region: false,
          msg: ""
        });
      }
      else {
        newdata['account'] = "";
        newdata['reserved_ip'] = "";
        setError({
          ...error,
          region: true,
          msg: "This field is required."
        });
      }
    }



    if (e.target.id == "account") {
      getReservedIp(e.target.value)
      if (e.target.value) {
        setError({
          ...error,
          account: false,
          msg: ""
        });
      }
      else {
        newdata['reserved_ip'] = "";
        setError({
          ...error,
          account: true,
          msg: "This field is required."
        });
      }
    }


    if (e.target.id == "reserved_ip") {

      if (e.target.value) {
        setError({
          ...error,
          reserved_ip: false,
          msg: ""
        });
      }
      else {
        setError({
          ...error,
          reserved_ip: true,
          msg: "This field is required."
        });
      }
    }


    if (e.target.id == "forward_address") {

      if (e.target.value) {
        if (forwardReg.test(e.target.value) === false) {
          setError({
            ...error,
            forward_address: true,
            forwardmsg: "Invalid IP Address"
          });
        }
        else {
          setError({
            ...error,
            forward_address: false,
            forwardmsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          forward_address: true,
          forwardmsg: "This field is required."
        });
      }
    }


    if (e.target.id == "gateway_domain") {

      if (e.target.value) {
        setError({
          ...error,
          gateway: false,
          msg: ""
        });
      }
      else {
        setError({
          ...error,
          gateway: true,
          msg: "This field is required."
        });
      }
    }

    if (e.target.id == "alert_email_address") {

      if (e.target.value) {
        if (emailReg.test(e.target.value) === false) {
          setError({
            ...error,
            alert_email_address: true,
            emailmsg: "Please enter a valid email address."
          });
        }
        else {
          setError({
            ...error,
            alert_email_address: false,
            emailmsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          alert_email_address: false,
          emailmsg: ""
        });
      }
    }


    if (e.target.id == "high_data_threshold") {

      if (e.target.value) {
        if (high_data_thresholdReg.test(e.target.value) === false) {
          setError({
            ...error,
            high_data_threshold: true,
            thresholdmsg: "Invalid Format."
          });
        }
        else {
          setError({
            ...error,
            high_data_threshold: false,
            thresholdmsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          high_data_threshold: false,
          thresholdmsg: ""
        });
      }
    }

    if (e.target.id == "monthly_data_alert_limit") {

      if (e.target.value) {
        if (monthly_data_alert_limitReg.test(e.target.value) === false) {
          setError({
            ...error,
            monthly_data_alert_limit: true,
            monthlymsg: "Invalid Format."
          });
        }
        else {
          setError({
            ...error,
            monthly_data_alert_limit: false,
            monthlymsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          monthly_data_alert_limit: false,
          monthlymsg: ""
        });
      }
    }


    if (e.target.id == "max_byte_per_user") {

      if (e.target.value) {
        if (max_byte_per_userReg.test(e.target.value) === false) {
          setError({
            ...error,
            max_byte_per_user: true,
            byteusermsg: "Invalid Format."
          });
        }
        else {
          setError({
            ...error,
            max_byte_per_user: false,
            byteusermsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          max_byte_per_user: false,
          byteusermsg: ""
        });
      }
    }

    if (e.target.id == "max_byte_per_day") {

      if (e.target.value) {
        if (max_byte_per_dayReg.test(e.target.value) === false) {
          setError({
            ...error,
            max_byte_per_day: true,
            bytedaymsg: "Invalid Format."
          });
        }
        else {
          setError({
            ...error,
            max_byte_per_day: false,
            bytedaymsg: ""
          });
        }
      }
      else {
        setError({
          ...error,
          max_byte_per_day: false,
          bytedaymsg: ""
        });
      }
    }


    setCreate(newdata);
  }
  const [portError, setPortError] = useState([{
    index: Math.random(),
    protocol: false,
    start_port_range: false,
    end_port_range: false,
    disable: false,
    endRange: "",
    startRange: "",
  }])
  const addRow = e => {

    e.preventDefault();
    setCreate((prevState) => ({
      ...prevState,
      port: [...prevState.port, {
        index: Math.random(), protocol: "", start_port_range: "", end_port_range: ""

      }],
    }))

    setPortError((prevState) => (
      [...prevState, {

        protocol: false,
        start_port_range: false,
        end_port_range: false,
        disable: false,
        endRange: "",
        startRange: "",
      }]
    ))
  };
  const deleteRow = (record, idx) => {

    setCreate({
      port: create.port.filter(r => r !== record)

    });

    const temp = [...portError];
    temp.splice(idx, 1);
    setPortError(temp);
  }
  const [images, SetImages] = useState({});
  //EditImage 
  const [message, setMessage] = useState();
  async function UploadLogoDefault() {
    fetch(defaultLogo)
      .then((res) => res.blob())
      .then((myBlob) => {
        myBlob.name = "datafree_defaulticon.png";
        myBlob.lastModified = new Date();
        const myFile = new File([myBlob], "datafree_defaulticon.png", {
          type: myBlob.type,
        });

        let values = myFile != undefined ? new FormData() : "";
        if (myFile != undefined) {
          values.append("logo_icon_image", myFile ? myFile : "");
        }
        trackPromise(
          adminService.editImage(values).then((res) => {

            create['icon_url'] = res.data;
            SetImages({
              ...images,
              logo_image: res.data,
            })
          }).catch((error) => {
            setMessage("Something went wrong. Please reload the page!");
            seterrorOpen(true);
          })

        );
      });


  }

  //Edit Splash

  async function UploadSplashDefault() {
    fetch(defaultsplashLogo)
      .then((res) => res.blob())
      .then((myBlob) => {
        myBlob.name = "default-app-splash.jpg";
        myBlob.lastModified = new Date();
        const myFile = new File([myBlob], "default-app-splash.jpg", {
          type: myBlob.type,
        });
        let values = myFile != undefined ? new FormData() : "";
        if (myFile != undefined) {
          values.append("splash_image", myFile ? myFile : "");
        }
        trackPromise(
          adminService.uploadSplash(values).then((res) => {


            create['splash_image'] = res.data;
            SetImages({
              ...images,
              logo_image: res.data,
            })
          }).catch((error) => {


          })

        );
      });

  }

  const multiFormHandler = (e, index) => {

    if (["protocol", "start_port_range", "end_port_range"].includes(e.target.name)) {
      let taskList = [...create.port]
      taskList[e.target.dataset.id][e.target.name] = e.target.value
      setCreate({ ...create, [e.target.name]: e.target.value })

      checkError();
    }
    else {
      setCreate({ ...create, [e.target.name]: e.target.value })
    }

  }


  const checkError = () => {
    for (let i = 0; i <= create.port.length; i++) {
      setPortError(portError.filter((newError, index) => {
        if (index == i) {

          if (create.port[i]?.protocol !== "") {
            newError.protocol = false;
          }
          else {
            newError.protocol = true;
          }

          if (create.port[i]?.start_port_range != "") {

            if (portRange.test(create.port[i]?.start_port_range) === false) {
              newError.start_port_range = true;
              newError.startRange = "Please enter a value between 1 and 65535.";
            }
            else {
              newError.start_port_range = false;
              newError.startRange = "";
            }
          }
          else {
            if (create.port[i]?.protocol === "GRE") {
              newError.start_port_range = false;
              newError.startRange = "";
            }
            else {
              newError.start_port_range = true;
              newError.startRange = "This field is required.";
            }
          }

          if (create.port[i]?.end_port_range != "") {

            if (portRange.test(create.port[i]?.end_port_range) === false) {
              newError.end_port_range = true;
              newError.endRange = "Please enter a value between 1 and 65535.";
            } else {
              newError.end_port_range = false;
              newError.endRange = "";
            }
          }
          else {
            if (create.port[i]?.protocol === "GRE") {
              newError.end_port_range = false;
              newError.endRange = "";
            }
            else {
              newError.end_port_range = true;
              newError.endRange = "This field is required.";
            }
          }

        }
        return newError;
      }))


    }

  }
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [clickSave, setClickSave] = useState(false);

  const SaveApp = () => {
    setLoaderVisible(true);
    create['company_name'] = document.getElementById("data_free_app_name").value;
    create['app_name'] = document.getElementById("data_free_app_name").value;
    let nwcount = validateData(create);


    if (nwcount == 0) {
      setClickSave(true)
      trackPromise(
        AdminService.dataFreeAppCreateRoute(create).then((res) => {
          setLoaderVisible(false);
          setClickSave(false)
          if (res.status === 200) {
            setsuccessOpen(true);
            window.location.reload();
          }
        })
      );
    }

  }
  const nameRef = React.useRef();
  const companyRef = React.useRef();
  const pubRef = React.useRef();
  const deployRef = React.useRef();
  const emailRef = React.useRef();
  const thresholdRef = React.useRef();
  const byteUserRef = React.useRef();
  const byteDayRef = React.useRef();
  const monthlyRef = React.useRef();
  const validateData = (create) => {
    let error = {};
    let msg = "This field is required."
    let count = 0;
    for (let i = 0; i <= create.port.length; i++) {
      setPortError(portError.filter((newError, index) => {
        if (index == i) {

          if (create.port[i]?.protocol !== "") {
            newError.protocol = false;
          }
          else {
            newError.protocol = true;
            count++;
          }

          if (create.port[i]?.start_port_range != "") {

            if (portRange.test(create.port[i]?.start_port_range) === false) {
              newError.start_port_range = true;
              newError.startRange = "Please enter a value between 1 and 65535.";
              count++;
            }
            else {
              newError.start_port_range = false;
              newError.startRange = "";
            }
          }
          else {
            if (create.port[i]?.protocol === "GRE") {
              newError.start_port_range = false;
              newError.startRange = "";
            }
            else {
              newError.start_port_range = true;
              newError.startRange = "This field is required.";
              count++;
            }
          }

          if (create.port[i]?.end_port_range != "") {

            if (portRange.test(create.port[i]?.end_port_range) === false) {
              newError.end_port_range = true;
              newError.endRange = "Please enter a value between 1 and 65535.";
              count++;
            } else {
              newError.end_port_range = false;
              newError.endRange = "";
            }


          }
          else {
            if (create.port[i]?.protocol === "GRE") {
              newError.end_port_range = false;
              newError.endRange = "";
            }
            else {
              newError.end_port_range = true;
              newError.endRange = "This field is required.";
              count++;
            }

          }

        }
        return newError;
      }))

    }
    if (create.company_id === "") {
      error.channel = true;
      error.msg = msg;
      count++;
      companyRef.current.focus();
    }
    else {
      error.channel = false;
      error.msg = "";
    }

    if (create.company_list === "") {
      error.publisher = true;
      error.msg = msg;
      count++;
      pubRef.current.focus();
    }
    else {
      error.publisher = false;
      error.msg = "";
    }

    if (create.data_free_app_name === "") {
      error.app_name = true;
      error.appmsg = msg;
      count++;
      nameRef.current.focus();
    }
    else if (appReg.test(create.data_free_app_name) === true) {
      error.app_name = true;
      error.appmsg = "Please enter a name without comma";
      count++;
      nameRef.current.focus();
    }
    else {
      error.app_name = false;
      error.appmsg = "";
    }


    if (create.deployment_type === "") {
      error.deploymentType = true;
      error.msg = msg;
      count++;
      deployRef.current.focus();
    }
    else {
      error.deploymentType = false;
      error.msg = "";
    }


    if (create.provider === "") {
      error.provider = true;
      error.msg = msg;
      count++;
    }
    else {
      error.provider = false;
      error.msg = "";
    }

    if (create.region === "") {
      error.region = true;
      error.msg = msg;
      count++;
    }
    else {
      error.region = false;
      error.msg = "";
    }

    if (create.account === "") {
      error.account = true;
      error.msg = msg;
      count++;
    }
    else {
      error.account = false;
      error.msg = "";
    }

    if (create.reserved_ip === "") {
      error.reserved_ip = true;
      error.msg = msg;
      count++;
    }
    else {
      error.reserved_ip = false;
      error.msg = "";
    }

    if (create.gateway_domain === "") {
      error.gateway = true;
      error.msg = msg;
      count++;
    }
    else {
      error.gateway = false;
      error.msg = "";
    }

    if (create.forward_address === "") {
      error.forward_address = true;
      error.forwardmsg = msg;
      count++;
    }
    else {
      if (forwardReg.test(create.forward_address) === false) {
        error.forward_address = true;
        error.forwardmsg = "Invalid IP address";
        count++;
      }
      else {
        error.forward_address = false;
        error.forwardmsg = "";
      }
    }

    if (create.protocol === "") {
      error.protocol = true;
      error.msg = msg;
      count++;
    }
    else {
      error.protocol = false;
      error.msg = "";
    }

    if (create.alert_email_address !== "") {
      if (emailReg.test(create.alert_email_address) === false) {
        error.alert_email_address = true;
        error.emailmsg = "Please enter a valid email address.";
        count++;
        emailRef.current.focus();
      }
      else {
        error.alert_email_address = false;
        error.emailmsg = "";
      }
    }
    else {
      error.alert_email_address = false;
      error.emailmsg = "";
    }

    if (create.high_data_threshold !== "") {
      if (high_data_thresholdReg.test(create.high_data_threshold) === false) {
        error.high_data_threshold = true;
        error.thresholdmsg = "Invalid Format.";
        count++;
        thresholdRef.current.focus();
      }
      else {
        error.high_data_threshold = false;
        error.thresholdmsg = "";
      }
    }
    else {
      error.high_data_threshold = false;
      error.thresholdmsg = "";
    }

    if (create.max_byte_per_user !== "") {
      if (max_byte_per_userReg.test(create.max_byte_per_user) === false) {
        error.max_byte_per_user = true;
        error.byteusermsg = "Invalid Format.";
        count++;
        byteUserRef.current.focus();
      }
      else {
        error.max_byte_per_user = false;
        error.byteusermsg = "";
      }
    }
    else {
      error.max_byte_per_user = false;
      error.byteusermsg = "";
    }

    if (create.monthly_data_alert_limit !== "") {
      if (monthly_data_alert_limitReg.test(create.monthly_data_alert_limit) === false) {
        error.monthly_data_alert_limit = true;
        error.monthlymsg = "Invalid Format.";
        count++;
        monthlyRef.current.focus();
      }
      else {
        error.monthly_data_alert_limit = false;
        error.monthlymsg = "";
      }
    }
    else {
      error.monthly_data_alert_limit = false;
      error.monthlymsg = "";
    }

    if (create.max_byte_per_day !== "") {
      if (max_byte_per_dayReg.test(create.max_byte_per_day) === false) {
        error.max_byte_per_day = true;
        error.bytedaymsg = "Invalid Format.";
        count++;
        byteDayRef.current.focus();
      }
      else {
        error.max_byte_per_day = false;
        error.bytedaymsg = "";
      }
    }
    else {
      error.max_byte_per_day = false;
      error.bytedaymsg = "";
    }


    setError(error);
    return count;
  }

  useEffect(() => {
    getDetail();
    UploadLogoDefault();
    UploadSplashDefault();
  }, []);

  return (
    <div>
      <div className="NavBoxRow">
        <div className="NavBoxRowText">datafree DIRECT Application Creation</div>
      </div>
      <div className="directapplication">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <div className="gridHead">App Owner and Basic Details</div>
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <div className="label_Direct">Channel Owner</div>
            <div>
              <select type="text"
              
                className={
                  !error.channel ? "form-control form-select" : "errorField form-control form-select"
                }
                id="company_id" name="company_id"
                ref={companyRef}
                onChange={(e) => changeField(e)}>
                <option></option>

                {data?.channel && data?.channel?.length > 0
                  ? data?.channel.map((post) => (
                    <option key={post.companyId} value={post.companyId}>
                      {post.name}
                    </option>
                  ))
                  : null}
              </select>
              {error.channel ? (
                <span style={{ float: 'left' }} className="errorClass topALign">{error.msg ? error.msg : "This field is required"}</span>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item sm={4} xs={12} md={4}>
            <div className="label_Direct" >Publisher</div>
            <div>
              <select type="text"
                className={
                  !error.publisher ? "form-control form-select" : "form-control form-select errorField"
                }
                id="company_list" name="company_list"
                ref={pubRef}
                onChange={(e) => changeField(e)}>
                <option></option>

                {publisherData && publisherData?.length > 0
                  ? publisherData.map((post) => (
                    <option key={post.id} value={post.id}>
                      {post.name}
                    </option>
                  ))
                  : null}
              </select>
              {error.publisher ? (
                <span style={{ float: 'left' }} className="errorClass topALign">{error.msg ? error.msg
                  : "This field is required"}</span>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={12}></Grid>

          <Grid item xs={12} sm={4} md={4}>
            <div className="label_Direct">App Name</div>
            <div>
              <input type="text"
                className={
                  !error.app_name ? "form-control" : "form-control errorField"
                }
                id="data_free_app_name" name="data_free_app_name"
                ref={nameRef}
                onChange={(e) => changeField(e)} />
              {error.app_name ? (
                <span style={{ float: 'left' }} className="errorClass topALign">{error.appmsg
                  ? error.appmsg : "This field is required"}</span>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <div className="label_Direct">Deployment Type</div>
            <div>
              <select type="text"
                className={
                  !error.deploymentType ? "form-control form-select" : "form-control form-select errorField"
                }
                id="deployment_type" name="deployment_type"
                ref={deployRef}
                onChange={(e) => changeField(e)}>
                <option value=""></option>
                {status?.status && status?.status === "approved" ? <option>DEV</option> : null}
                {status?.status ? <option>{status?.status === "approved" ? "PROD" : "DEV"}</option> : null}

              </select>
              {error.deploymentType ? (
                <span style={{ float: 'left' }} className="errorClass topALign">{error.msg
                  ? error.msg : "This field is required"}</span>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <div className="label_Direct">Report Aggregation Tag</div>
            <div>
              <input type="text" className="directField_Input" id="report_aggregation_tag" name="report_aggregation_tag" onChange={(e) => changeField(e)} />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className="gridHead">Data Usage Limits</div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div className="label_Direct" style={{ fontSize: '13px' }}>
              <p>High Data Threshold - Operational alarms raised when threshold reached in 5 minute period</p>
            </div>
            <div>
              <input type="text"
                className={
                  !error.high_data_threshold ? "form-control" : "form-control errorField"
                }
                id="high_data_threshold" name="high_data_threshold"
                ref={thresholdRef}
                onChange={(e) => changeField(e)} />

              {error.high_data_threshold ? (
                <span style={{ float: 'left' }} className="errorClass topALign">{error.thresholdmsg}</span>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}></Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className="label_Direct">Monthly App #datafree Data Alert Limit (bytes)</div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div>
              <input type="text"
                className={
                  !error.monthly_data_alert_limit ? "form-control" : "form-control errorField"
                }
                id="monthly_data_alert_limit" name="monthly_data_alert_limit"
                ref={monthlyRef}
                onChange={(e) => changeField(e)} />

              {error.monthly_data_alert_limit ? (
                <span style={{ float: 'left' }} className="errorClass topALign">{error.monthlymsg}</span>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className="label_Direct">Alert Email Addresses (comma separated)</div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div>
              <input type="text"
                className={
                  !error.alert_email_address ? "form-control" : "form-control errorField"
                }
                id="alert_email_address" name="alert_email_address"
                ref={emailRef}
                onChange={(e) => changeField(e)} />

              {error.alert_email_address ? (
                <span style={{ float: 'left' }} className="errorClass topALign">{error.emailmsg}</span>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className="label_Direct">Maximum Bytes per User</div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div>
              <input type="text"
                className={
                  !error.max_byte_per_user ? "form-control" : "form-control errorField"
                }
                id="max_byte_per_user" name="max_byte_per_user"
                ref={byteUserRef}
                onChange={(e) => changeField(e)} />
              {error.max_byte_per_user ? (
                <span style={{ float: 'left' }} className="errorClass topALign">{error.byteusermsg}</span>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}></Grid>

          <Grid item xs={12} sm={3} md={3}>
            <div className="label_Direct">Include Daily #datafree Data Limit Alerting ?</div>
          </Grid>
          <Grid item xs={1}>
            <div>
              <input type="checkbox" onChange={() => setChecked(!checked)}
                checked={checked} id="include_daily_dataFree_limit_connect_direct" name="include_daily_dataFree_limit_connect_direct" />
            </div>
          </Grid>

          <Grid item xs={12} sm={8} md={8}>
            {checked ? <div className="rowClass">


              <div container className="datafeeBox">
                <div className="label_Direct">Daily #datafree data limit (bytes)</div>

                <div>
                  <input type="text"
                    className={
                      !error.max_byte_per_day ? "BoxsubFieldInput" : "BoxsubFieldInputerr"
                    }
                    id="max_byte_per_day" name="max_byte_per_day"
                    ref={byteDayRef}
                    onChange={(e) => changeField(e)} />

                  {error.max_byte_per_day ? (
                    <span style={{ float: 'left' }} className="errorClass topALign">{error.bytedaymsg}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="label_Direct">Required Action on Daily Limit</div>

                <div>
                  <select type="text" className="BoxFieldInput" id="action_required" name="action_required" onChange={(e) => changeField(e)}>
                    <option value="0">Send Alerts Only</option>
                    <option value="1">Send Alerts And Stop App</option>
                  </select>
                </div>
              </div>
            </div> : null}
          </Grid>
          <Grid item xs={12}  sm={12} md={12}>
            <div className="gridHead">biNu Server Location / Assignment</div>
          </Grid>

          <Grid item xs={12}  sm={2} md={2}>
            <div className="label_Direct">Provider</div>
            <div>
              <select type="text"
                className={
                  !error.provider ? "form-control form-select" : "form-control form-select errorField"
                }
                id="provider" name="provider" onChange={(e) => changeField(e)} >
                <option value=""></option>
                {providerList && providerList.cloud?.length > 0
                  ? providerList.cloud.map((post) => (
                    <option key={post.cloud} value={post.cloud}>
                      {post.cloud}
                    </option>
                  ))
                  : null}
              </select>
              {error.provider ? (
                <span style={{ float: 'left' }} className="errorClass topALign">{error.msg ? error.msg : "This field is required"}</span>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item xs={12}  sm={2} md={2}>
            <div className="label_Direct">Region</div>
            <div>
              <select type="text"
                className={
                  !error.region ? "form-control form-select" : "form-control form-select errorField"
                }
                id="region" name="region" onChange={(e) => changeField(e)}>
                <option value=""></option>
                {region && region?.length > 0
                  ? region.map((post) => (
                    <option key={post.region} value={post.region}>
                      {post.region}
                    </option>
                  ))
                  : null}
              </select>
              {error.region ? (
                <span style={{ float: 'left' }} className="errorClass topALign">{error.msg
                  ? error.msg : "This field is required"}</span>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item xs={12}  sm={2} md={2}>
            <div className="label_Direct">Account</div>
            <div>
              <select type="text"
                className={
                  !error.account ? "form-control form-select" : "form-control form-select errorField"
                }
                id="account" name="account" onChange={(e) => changeField(e)}>
                <option value=""></option>
                {account && account.length > 0
                  ? account.map((post) => (
                    <option key={post.account} value={post.account}>
                      {post.account}
                    </option>
                  ))
                  : null}
              </select>
              {error.account ? (
                <span style={{ float: 'left' }} className="errorClass topALign">{error.msg ?
                  error.msg : "This field is required"}</span>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item xs={12}  sm={5} md={5}>
            <div className="label_Direct">Reserved IP</div>
            <div>
              <select type="text"
                className={
                  !error.reserved_ip ? "form-control form-select" : "form-control form-select errorField"
                }
                id="reserved_ip" name="reserved_ip" onChange={(e) => changeField(e)}>
                <option value=""></option>
                {reservedIp && reservedIp?.length > 0
                  ? reservedIp?.map((post) => (
                    <option key={post?.ip_id} value={post?.ip_id}>
                      {post?.ip_id}
                    </option>
                  ))
                  : null}
              </select>
              {error.reserved_ip ? (
                <span style={{ float: 'left' }} className="errorClass topALign">{error.msg ? error.msg : "This field is required"}</span>
              ) : (
                ""
              )}
            </div>
          </Grid>

          <Grid item xs={12}  sm={12} md={12}>
            <div className="gridHead">Customer Server Specifics</div>
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <div className="label_Direct">IPv4 Address - Customer Endpoint</div>
            <div>
              <input type="text"
                className={
                  !error.forward_address ? "directField_Input" : "directField_Inputerr"
                }
                id="forward_address" name="forward_address"
                onChange={(e) => changeField(e)} />
              {error.forward_address ? (
                <span style={{ float: 'left' }} className="errorClass topALign">{error.forwardmsg ?
                  error.forwardmsg : "This field is required"}</span>
              ) : (
                ""
              )}
            </div>
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <div className="label_Direct">#datafree Gateway</div>
            <div>
              <select type="text"
                className={
                  !error.gateway ? "form-control form-select" : "form-control form-select errorField"
                }
                id="gateway_domain" name="gateway_domain" onChange={(e) => changeField(e)}>
                <option></option>

                {gatewayList && gatewayList.length > 0
                  ? gatewayList.map((post) => (
                    <option key={post} value={post}>
                      {post}
                    </option>
                  ))
                  : null}
              </select>
              {error.gateway ? (
                <span style={{ float: 'left' }} className="errorClass topALign">{error.msg
                  ? error.msg : "This field is required"}</span>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={4} md={4}></Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className="gridHead">Protocol and Port Requirement</div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className="label_Direct">Protocol</div>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <div className="label_Direct">Start of Port Range</div>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <div className="label_Direct" style={{ float: 'right' }}>End of Port Range</div>
          </Grid>
          <Grid item xs={12} sm={10} md={10}>
            <div className="datafeeSubBox">
              {create.port.map((val, idx) => {
                let protocol = `protocol-${idx}`, startportrange = `start_port_range-${idx}`, endportrange = `end_port_range-${idx}`
                return (
                  <div className="boxRow" key={val.index}>
                    <div className="flex1">
                      <select type="text"
                        className={

                          !portError[idx]?.protocol ? "form-control form-select" : "form-control form-select errorField"
                        }
                        defaultValue={create?.port[idx]?.protocol}
                        id={protocol} name="protocol" data-id={idx} onChange={(e) => multiFormHandler(e, idx)} >
                        <option value=""></option>

                        {providerList && providerList.protocol?.length > 0
                          ? providerList.protocol.map((post) => (
                            <option key={post.protocol} value={post.protocol}>
                              {post.protocol}
                            </option>
                          ))
                          : null}
                      </select>

                      {portError[idx]?.protocol ? (
                        <span style={{ float: 'left' }} className="errorClass topALign">This field is required</span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="flex1">
                      <input type="text"
                        className={
                          !portError[idx]?.start_port_range ? "inputBox_Row" : "inputBox_Rowerr"
                        }
                        defaultValue={create?.port[idx]?.start_port_range}
                        id={startportrange} disabled={val.protocol == "GRE" || val.protocol == "" ? "disabled" : false} name="start_port_range" data-id={idx} onChange={(e) => multiFormHandler(e, idx)}
                      />

                      {portError[idx]?.start_port_range ? (
                        <p style={{ float: 'left', marginLeft: '37px', marginTop: '0px', textAlign: 'left' }} className="errorClass topALign">{portError[idx]?.startRange}</p>
                      ) : (
                        ""
                      )
                      }
                    </div>
                    <div className="flex1">
                      <input type="text"

                        className={
                          !portError[idx]?.end_port_range ? "inputBox_Row" : "inputBox_Rowerr"
                        }
                        defaultValue={create?.port[idx]?.end_port_range}
                        id={endportrange} name="end_port_range" data-id={idx} onChange={(e) => multiFormHandler(e, idx)} disabled={val.protocol == "GRE" || val.protocol == "" ? "disabled" : false}
                      />
                      {portError[idx]?.end_port_range ? (
                        <p style={{ float: 'left', marginLeft: '37px', marginTop: '0px', textAlign: 'left' }} className="errorClass topALign">{portError[idx]?.endRange}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="flex1">
                      <button className="closeIcon" disabled={idx == 0 ? "disabled" : false} onClick={() => deleteRow(val, idx)}>
                        X
                      </button>
                    </div>
                  </div>
                );
              })}
              <div style={{ textAlign: "left" }}>
                <button className="defultbtn" onClick={addRow}>
                  Add
                </button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={2} md={2}></Grid>
          {loaderVisible ? <Grid><LoadingIndicator /></Grid> : null}
          <Grid item xs={12} sm={6} md={6}></Grid>
          <Grid item xs={12} sm={6} md={6}>
            {" "}
            <div className="ivitediv">
              <button className="inviteButton" color="primary"
                onClick={handleclose}>
                Cancel
              </button>
              <button className="inviteButton"
                disabled={clickSave ? "disabled" : false}
                color="primary"
                onClick={SaveApp}>
                Save
              </button>
            </div>
          </Grid>
        </Grid>

        <div className="divSpace"></div>
      </div>
      <Snackbar open={successopen} autoHideDuration={2500} onClose={handlesuccessAlert}>
        <Alert onClose={handlesuccessAlert} severity="success">
          #datafree Direct App Created Successfully
        </Alert>
      </Snackbar>

      <Snackbar open={erroropen} autoHideDuration={1500} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error">
          {message ? message : "An Error Occured!"}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default AdminDatafreeAppCreate;
